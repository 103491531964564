import React, { useCallback } from 'react'
import colors from '../../../Utils/colors';
import { Handle, Position } from 'reactflow';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import images from '../../../Utils/images';

const handleStyle = { backgroundColor: '#FF6666', height: 15, width: 15, borderRadius: "100%" };

const InvokeNewFlow = ({ data, isConnectable }) => {
    return (
        <>
            <div className='d-flex flex-column justify-content-between align-items-center' style={{ border: `1px solid ${colors.borderwhite}`, borderRadius: 16, backgroundColor: colors.white01, width: 250 }}>
                <Handle type='target' position={Position.Left} isConnectable={isConnectable} style={handleStyle} />
                <div className='w-100 d-flex flex-column justify-content-start align-items-center' style={{ paddingInline: 16, paddingBlock: 8, backgroundColor: '#FF6666', borderTopLeftRadius: 16, borderTopRightRadius: 16 }}>
                    <div style={{ height: 4, width: '20%', borderRadius: 2, backgroundColor: colors.white, marginBlockEnd: 4 }}></div>
                    <div className='w-100 d-flex flex-row justify-content-between align-items-center' style={{}}>
                        <p style={{ padding: 0, margin: 0, fontSize: 16, fontWeight: 700, color: colors.white }}>
                            {'Invoke new flow'}
                        </p>
                        <img src={images.Edit} style={{ height: 20, width: 20, objectFit: 'contain' }}></img>
                    </div>
                </div>
                {/* <div className='w-100 d-flex flex-row justify-content-between align-items-center' style={{paddingInline:16,paddingBlock:8,backgroundColor:'#FF6666',borderTopLeftRadius:16,borderTopRightRadius:16}}>
            <p style={{padding:0,margin:0,fontSize:16,fontWeight:700,color:colors.white}}>
                {'Invoke new flow'}
            </p>
            <img src={images.Edit} style={{height:20,width:20,objectFit:'contain'}}></img>
        </div> */}
                <div className='w-100 d-flex flex-column justify-content-start align-items-center' style={{ paddingInline: 16, paddingBlockStart: 8, paddingBlockEnd: 16 }}>
                    {/* <p style={{padding:0,margin:0,marginBlock:4,fontSize:10,fontWeight:600,color:colors.black,width:'100%'}}>
                {'Add body text'}
            </p> */}
                    <InputGroup className="my-2">
                        <Form.Control type='text' placeholder={'Select Flow'} className='w-100 btncustom'
                            style={{ borderRadius: 8, fontSize: 12, fontWeight: 400, color: colors.black }}
                            // isInvalid={(renewalText.length >= 640)}
                            maxLength={640}
                            value={data?.flowName ? data.flowName : ''}
                        // onChange={onChangeEmail}
                        />
                    </InputGroup>
                </div>
            </div>
        </>
    )
}

export default InvokeNewFlow