import React, { useState } from 'react'
import images from '../../../Utils/images'
import { useNavigate } from 'react-router-dom'
import colors from '../../../Utils/colors';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import CheerioButton from '../../../Components/CheerioButton';
import { useSelector } from 'react-redux';
import { saveSMTPSettings } from '../../../Services';
import { toast } from 'react-toastify';
import { Switch } from '@mui/material';

const SettingsPage = ({ SenderData, onSettingsUpdate }) => {

    const navigate = useNavigate();
    const authToken = useSelector((state) => state.main.auth_token);
    const [messagesPerDay, setMessagesPerDay] = useState(SenderData?.settings?.messagePerDay ?? '250')
    const [minGapTime, setMinGapTime] = useState(SenderData?.settings?.minGapTime ?? '5')
    const [replyTo, setReplyTo] = useState(SenderData?.settings?.replyTo ?? SenderData?.from?.email)
    const [senderName, setSenderName] = useState(SenderData?.settings?.senderName ?? SenderData?.from?.name)
    const [emailSignature, setEmailSignature] = useState(SenderData?.settings?.emailSignature ?? '')
    const [currentPage, setCurrentPage] = useState('General');
    const [EWisActive, setEWisActive] = useState(SenderData?.settings?.emailWarmup?.isActive ?? false)
    const [EWemailPerDay, setEWemailPerDay] = useState(SenderData?.settings?.emailWarmup?.emailPerDay ?? '')
    const [EWdropRate, setEWdropRate] = useState(SenderData?.settings?.emailWarmup?.dropRate ?? '')

    const saveSettingsAction = () => {
        let token = authToken;
        let data = {
            email: SenderData?.from?.email,
            setting: {
                messagePerDay: messagesPerDay,
                minGapTime: minGapTime,
                replyTo: replyTo,
                senderName: senderName,
                emailSignature: emailSignature,
                emailWarmup: {
                    emailPerDay: EWemailPerDay,
                    dropRate: EWdropRate,
                    isActive: EWisActive,
                },
            }
        };
        saveSMTPSettings(data, token).then((res) => {
            if (res?.flag) {
                toast.success('Successfully saved settings', { position: 'top-right', autoClose: true })
                onSettingsUpdate();
            } else {
                toast.error(res?.message ?? 'Unexpected error occurred, please try again later', { position: 'top-right', autoClose: true })
            }
        })
    }

    return (
        <>
            <div className='d-flex flex-row justify-content-start align-items-center mb-3'>
                <img
                    src={images.BackArrowBlack}
                    style={{ height: 20, width: 20, objectFit: 'contain', marginInlineEnd: 16, cursor: 'pointer' }}
                    onClick={() => { navigate('/creator/manageSender') }}
                >
                </img>
                <h3 style={{ fontSize: 20, fontWeight: 700 }}>
                    {SenderData?.from?.name}
                </h3>
                <p style={{ marginInline: 16, color: colors.black04 }}>
                    {`(${SenderData?.from?.email})`}
                </p>
            </div>
            <div className='d-flex flex-row justify-content-between align-items-center my-3 w-100' style={{ borderBottom: `1px solid ${colors.black}` }}>
                <div className='Row justify-content-start' style={{height:50}}>
                    <div className='h-100 Column px-4 justify-content-center' style={{
                        borderBottom: currentPage === 'General' ? `2px solid ${colors.primary03}`:'',
                    }}>
                        <p style={{ 
                            fontWeight: 600,
                            color: currentPage === 'General' ? colors.primary03 : colors.black, 
                            cursor:'pointer'
                        }} onClick={()=>{
                            setCurrentPage('General');
                        }}>
                            {`General`}
                        </p>
                    </div>
                    <div className='h-100 Column px-4 justify-content-center' style={{
                        borderBottom: currentPage === 'Warmup' ? `2px solid ${colors.primary03}`:'',
                    }}>
                        <p style={{ 
                            fontWeight: 600,
                            color: currentPage === 'Warmup' ? colors.primary03 : colors.black, 
                            cursor:'pointer'
                        }} onClick={()=>{
                            setCurrentPage('Warmup');
                        }}>
                            {`Warmup`}
                        </p>
                    </div>
                </div>
                
                <CheerioButton
                    className="btncustom"
                    borderStyle={{
                        paddingInline: 24,
                        borderColor: colors.primary03,
                        // marginBlockEnd:16,
                    }}
                    textStyle={{ color: colors.white01, fontSize: 16, fontWeight: 600 }}
                    btnText={'Save Settings'}
                    backColor={colors.primary03}
                    onclick={() => { saveSettingsAction() }}
                />
            </div>
            <div className='d-flex flex-column justify-content-start align-items-start w-100' style={{
                backgroundColor: colors.white01,
                borderRadius: 16,
                paddingInline: 24,
                paddingBlock: 20,
                marginBlock: 24,
            }}>
                {currentPage === 'General' ? (
                    <>
                    <h4 className='mb-3' style={{ fontWeight: 700 }}>
                        {'SMTP Settings'}
                    </h4>
                    <div className='d-flex flex-row justify-content-start align-items-center w-100 my-3'>
                        <InputGroup className="mb-2" style={{ width: '40%' }}>
                            <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
                                {'Messages per day'}
                            </Form.Label>
                            <Form.Control
                                type="number"
                                className="w-100 btncustom"
                                style={{ borderRadius: 8 }}
                                value={messagesPerDay}
                                onChange={(e) => {
                                    setMessagesPerDay(e.target.value);
                                }}
                            />
                        </InputGroup>
                        <InputGroup className="mb-2 ms-3" style={{ width: '40%' }}>
                            <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
                                {'Minimum gap time (min)'}
                            </Form.Label>
                            <Form.Control
                                type="number"
                                className="w-100 btncustom"
                                style={{ borderRadius: 8 }}
                                value={minGapTime}
                                onChange={(e) => {
                                    setMinGapTime(e.target.value);
                                }}
                            />
                        </InputGroup>
                    </div>
                    <div className='d-flex flex-row justify-content-start align-items-center w-100 my-3'>
                        <InputGroup className="mb-2" style={{ width: '40%' }}>
                            <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
                                {'Reply to email address'}
                            </Form.Label>
                            <Form.Control
                                type="text"
                                className="w-100 btncustom"
                                style={{ borderRadius: 8 }}
                                value={replyTo}
                                onChange={(e) => {
                                    setReplyTo(e.target.value);
                                }}
                            />
                        </InputGroup>
                        <InputGroup className="mb-2 ms-3" style={{ width: '40%' }}>
                            <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
                                {'Sender Name'}
                            </Form.Label>
                            <Form.Control
                                type="text"
                                className="w-100 btncustom"
                                style={{ borderRadius: 8 }}
                                value={senderName}
                                onChange={(e) => {
                                    setSenderName(e.target.value);
                                }}
                            />
                        </InputGroup>
                    </div>
                    <div className='d-flex flex-row justify-content-start align-items-center w-100 my-3'>
                        <InputGroup className="mb-2" style={{ width: '82%' }}>
                            <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
                                {'Email Signature'}<br />
                                <span style={{ fontWeight: 400 }}>
                                    {'Enter your email signature below (manually or by copy-pasting it from your email provider)'}
                                </span>
                            </Form.Label>
                            <Form.Control
                                as={'textarea'}
                                rows={3}
                                // type="text"
                                className="w-100 btncustom"
                                style={{ borderRadius: 8 }}
                                value={emailSignature}
                                onChange={(e) => {
                                    setEmailSignature(e.target.value);
                                }}
                            />
                        </InputGroup>
                    </div>
                    </>
                ): currentPage === 'Warmup' && (
                    <>
                    <h4 className='mb-3' style={{ fontWeight: 700 }}>
                        {'Email Warmup'}
                    </h4>
                    <p className='mb-2'>
                        {'Warming up an IP address involves sending low volumes of email on your dedicated IP and then systematically increasing your email volume over a period of time'}
                    </p>
                    <div className='Row justify-content-start my-3'>
                        <Switch checked={EWisActive} onChange={(event)=>{setEWisActive(event.target.checked)}}/>
                        <p>
                            {`Email Warmup ${EWisActive ? 'enabled' : 'disabled'}`}
                        </p>
                    </div>
                    <div className='d-flex flex-row justify-content-start align-items-center w-100 my-3'>
                        <InputGroup className="mb-2" style={{ width: '40%' }}>
                            <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
                                {'Total no. of warmup emails per day'}
                            </Form.Label>
                            <Form.Control
                                type="number"
                                className="w-100 btncustom"
                                style={{ borderRadius: 8 }}
                                value={EWemailPerDay}
                                onChange={(e) => {
                                    setEWemailPerDay(e.target.value);
                                }}
                            />
                        </InputGroup>
                    </div>
                    <div className='d-flex flex-row justify-content-start align-items-center w-100 my-3'>
                        <InputGroup className="mb-2" style={{ width: '40%' }}>
                            <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
                                {'Reply Rate (%)'}
                            </Form.Label>
                            <Form.Control
                                type="number"
                                className="w-100 btncustom"
                                style={{ borderRadius: 8 }}
                                value={EWdropRate}
                                onChange={(e) => {
                                    const clampedValue = Math.max(0, Math.min(100, Number(e.target.value)))
                                    setEWdropRate(clampedValue);
                                }}
                            />
                            <p style={{color:colors.greys04}}>
                                {'Suggested: 20, Max-100'}
                            </p>
                        </InputGroup>
                    </div>
                    </>
                )}
            </div>
        </>
    )
}

export default SettingsPage