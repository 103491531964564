import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import colors from '../../../Utils/colors';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import SpinnerLoader from '../../../Components/SpinnerLoader';
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const GraphChartComponent = ({ graphTimeframe, setGraphTimeframe, weeklyBreakdown, graphIsLoading, selectedChannel }) => {
    const [labels, setLabels] = useState([]);
    const [dataSets, setDataSets] = useState([]);

    useEffect(() => {
        if (weeklyBreakdown?.length > 0) {
            setLabels(weeklyBreakdown?.map(item => item.week));

            if (selectedChannel === 'whatsapp') {
                setDataSets([
                    {
                        label: 'Sent',
                        data: weeklyBreakdown.map(item => item.whatsapp.sent) ?? "--",
                        backgroundColor: '#8E53F7',
                    },
                    {
                        label: 'Delivered',
                        data: weeklyBreakdown.map(item => item.whatsapp.delivery) ?? "--",
                        backgroundColor: '#BF51C1',
                    },
                    {
                        label: 'Opened',
                        data: weeklyBreakdown?.map(item => item.whatsapp.opened) ?? "--",
                        backgroundColor: '#BF51C1',
                    },
                    {
                        label: 'Clicked',
                        data: weeklyBreakdown.map(item => item.whatsapp.clicked) ?? "--",
                        backgroundColor: '#EEB728',
                    },
                    {
                        label: 'Replied',
                        data: weeklyBreakdown.map(item => item.whatsapp.replied) ?? "--",
                        backgroundColor: '#51C1C1',
                    },
                    {
                        label: 'Failed',
                        data: weeklyBreakdown.map(item => item.whatsapp.failed) ?? "--",
                        backgroundColor: '#E9758A',
                    }
                ]);
            } else if (selectedChannel === 'email') {
                setDataSets([
                    {
                        label: 'Sent',
                        data: weeklyBreakdown.map(item => item.email.sent) ?? "--",
                        backgroundColor: '#8E53F7',
                    },
                    {
                        label: 'Delivered',
                        data: weeklyBreakdown.map(item => item.email.delivery) ?? "--",
                        backgroundColor: '#BF51C1',
                    },
                    {
                        label: 'Opened',
                        data: weeklyBreakdown.map(item => item.email.opened) ?? "--",
                        backgroundColor: '#BF51C1',
                    },
                    {
                        label: 'Clicked',
                        data: weeklyBreakdown.map(item => item.email.clicked) ?? "--",
                        backgroundColor: '#EEB728',
                    },
                    {
                        label: 'Replied',
                        data: weeklyBreakdown.map(item => item.email.replied) ?? "--",
                        backgroundColor: '#51C1C1',
                    },
                    {
                        label: 'Failed',
                        data: weeklyBreakdown.map(item => item.email.failed) ?? "--",
                        backgroundColor: '#E9758A',
                    }
                ]);
            } else if (selectedChannel === 'sms') {
                setDataSets([
                    {
                        label: 'Sent',
                        data: weeklyBreakdown.map(item => item.sms.sent) ?? "--",
                        backgroundColor: '#8E53F7',
                    },
                    {
                        label: 'Delivered',
                        data: weeklyBreakdown.map(item => item.sms.delivery) ?? "--",
                        backgroundColor: '#BF51C1',
                    },
                    {
                        label: 'Opened',
                        data: weeklyBreakdown.map(item => item.sms.opened) ?? "--",
                        backgroundColor: '#BF51C1',
                    },
                    {
                        label: 'Clicked',
                        data: weeklyBreakdown.map(item => item.sms.clicked) ?? "--",
                        backgroundColor: '#EEB728',
                    },
                    {
                        label: 'Replied',
                        data: weeklyBreakdown.map(item => item.sms.replied) ?? "--",
                        backgroundColor: '#51C1C1',
                    },
                    {
                        label: 'Failed',
                        data: weeklyBreakdown.map(item => item.sms.failed) ?? "--",
                        backgroundColor: '#E9758A',
                    }
                ]);
            }
        }
    }, [weeklyBreakdown, selectedChannel]);

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'bottom',
            },
        },
    };

    const data = {
        labels,
        datasets: dataSets,
    };

    return (
        <div className='graph_component_container' style={{ width: '100%', paddingInline: 10 }}>
            <div className='graph_component_div shadow_small'>
                <div className='graph_component_div__row'>
                    <h3>
                        {selectedChannel === 'sms' ? "SMS" : selectedChannel.charAt(0).toUpperCase() + selectedChannel.slice(1)} Performance
                    </h3>
                </div>
                <div className='graph_component_div__graph'>
                    {graphIsLoading ? (
                        <SpinnerLoader />
                    ) : (
                        <Bar options={options} data={data} height={'300px'} />
                    )}
                </div>
            </div>
        </div>
    );
};

export default GraphChartComponent;
