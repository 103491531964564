import { Modal } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import images from '../../../Utils/images';
import CheerioButton from '../../../Components/CheerioButton';
import colors from '../../../Utils/colors';
import Button from 'react-bootstrap/Button';
import SuccessModal from './SuccessModal';
import '../../../../src/Pages/Templates/styles.css';
import {
  paymentOrder,
  orderPayment,
  getUserApi,
  partnerCreatePaymentOrder,
} from '../../../Services';
import { toast } from 'react-toastify';
import { eventsend } from '../../../Config/analyticsFunctions';

const AddTopUpModal = ({
  setIsAddTopUpModalOpen,
  isAddTopUpModalOpen,
  partnerToken,
  isPartner = false,
  updatePartnerDashboard,
}) => {
  const handleClose = () => {
    setIsAddTopUpModalOpen(false);
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authtoken = useSelector((state) => state.main.auth_token);
  const localdata = useSelector((state) => state.main.localdata);
  const [clicked, setClicked] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [isSuccessModal, setIsSuccessModal] = useState(false);
  const [isSubscription, setIsSubscription] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const handleChange = () => {
    setIsChecked(!isChecked);
    if(isChecked){
      eventsend("Top_up_amount_wallet_setup_pop_up_clicked");
    }
  };

  const getuserapicall = () => {
    let token = authtoken;
    getUserApi(token).then((res) => {
      // console.log('get user api response ==> ', res);
      if (res?.flag) {
        dispatch({ type: 'addLocaldata', payload: res?.data });
      } else {
        dispatch({ type: 'redirectToLogin', payload: true });
        dispatch({ type: 'logout' });
        localStorage.removeItem('token');
        console.log('triggered');

        navigate('/');
        toast.error(res?.message);
      }
    });
  };

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function displayRazorpaySubscription(planName) {
    const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');

    if (!res) {
      alert('Razorpay SDK failed to load. Are you online?');
      return;
    }
    const jsonPlan = {
      plan: '',
      planId: '',
    };
    if (planName === '$1') {
      jsonPlan.plan = '$1';
    } else if (planName === '$10') {
      jsonPlan.plan = '$10';
    } else if (planName === '$50') {
      jsonPlan.plan = '$50';
    } else if (planName === '$100') {
      jsonPlan.plan = '$100';
    } else if (planName === '$500') {
      jsonPlan.plan = '$500';
    } else if (planName === '$1000') {
      jsonPlan.plan = '$1000';
    }

    const data = await paymentOrder(jsonPlan, authtoken);

    console.log(data);

    const options = {
      key: 'rzp_live_1IEjlHGI3q0yMR',
      subscription_id: data?.response?.subscriptionId,
      name: 'Subscription',
      description: 'Subscription',
      handler: function (response) {
        toast.success(
          'Your subscription has been done. Kindly wait for a couple of minutes and refresh the website to see updated balance.',
          { position: 'top-center' }
        );
        // window.location.reload();
      },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
    if (data?.flag && !isPartner) {
      getuserapicall();
    }
  }

  async function displayRazorpayOrder(amount) {
    const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');

    if (!res) {
      alert('Razorpay SDK failed to load. Are you online?');
      return;
    }
    const numericAmount = parseInt(amount, 10);
    const jsonPlan = {
      amount: numericAmount,
    };
    if (amount > 0) {
      const data = await orderPayment(jsonPlan, authtoken);
      console.log(data);
      const options = {
        key: 'rzp_live_1IEjlHGI3q0yMR',
        order_id: data?.response?.orderId,
        amount: data?.response?.amount,
        currency: data?.response?.currency,
        name: 'Order',
        description: 'Order',
        handler: function (response) {
          toast.success('Your manual recharge is done successfully.', { position: 'top-center' });
          window.location.reload();
        },
      };
      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } else {
      toast.error('Amount has to be greater than 0');
    }
  }

  async function partnerCreateOrderCall(amount) {
    const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');

    if (!res) {
      alert('Razorpay SDK failed to load. Are you online?');
      return;
    }
    const numericAmount = parseInt(amount, 10);
    const jsonPlan = {
      creditsAdded: numericAmount,
    };
    if (amount > 0) {
      const data = await partnerCreatePaymentOrder(jsonPlan, partnerToken);
      console.log(data);
      const options = {
        key: 'rzp_live_1IEjlHGI3q0yMR',
        order_id: data?.data?.orderId,
        amount: data?.data?.amount,
        currency: data?.data?.currency,
        name: 'Order',
        description: 'Thank you for choosing Cheerio.',
        handler: function (response) {
          alert(response.razorpay_payment_id);
          alert(response.razorpay_order_id);
          alert(response.razorpay_signature);
          console.log(response);
          updatePartnerDashboard();
          // window.location.reload();
        },
      };
      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } else {
      toast.error('Amount has to be greater than 0');
    }
  }

  const handleClick = (amount) => {
    const numericValue = parseInt(amount.replace('$', ''), 10);
    if (clicked === amount) {
      setInputValue('');
      setClicked(null);
    } else {
      setInputValue(numericValue);
      setClicked(amount);
      setIsSubscription(true);
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    setClicked(null);
    setIsSubscription(false);
  };

  const handleButtonClick = () => {
    console.log(clicked);
    if (isPartner) {
      partnerCreateOrderCall(inputValue);
    } else if (isSubscription) {
      displayRazorpaySubscription(clicked);
      setIsAddTopUpModalOpen(false);
    } else {
      displayRazorpayOrder(inputValue);
    }
  };
  const MobileWidth = window.innerWidth < 700 ? true : false;
  useEffect(() => {
    if (inputValue === '') {
      setClicked(null);
    }
  }, [inputValue]);

  return (
    <div>
      {isSuccessModal && (
        <SuccessModal setIsSuccessModal={setIsSuccessModal} isSuccessModal={isSuccessModal} />
      )}

      <Modal show={isAddTopUpModalOpen} onHide={handleClose}  className="wallet-topup-modal"  centered >
        <Modal.Body
          style={{
            padding: '2%',
            maxHeight: '85vh', 
            overflowY: 'auto',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: MobileWidth ? 'column' : 'row',
                alignItems: 'center',
              }}
            >
              <img
                src={images.WalletIcon}
                style={{
                  padding: '2%',
                  height: MobileWidth ? '45%' : 'defailt',
                  width: MobileWidth ? '40%' : 'default',
                }}
              ></img>
              <p
                style={{
                  fontWeight: 700,
                  fontSize: '20px',
                  lineHeight: '25px',
                  padding: '4%',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                Never run out of credits
                <span
                  style={{
                    fontWeight: 400,
                    fontSize: '16px',
                    lineHeight: '20px',
                    color: '#666666',
                    paddingTop: '2%',
                  }}
                >
                  {isPartner ? 'Add balance to your wallet' : 'Add money to your wallet'}
                </span>
              </p>
            </div>
            <img
              src={images.CMClose}
              style={{
                cursor: 'pointer',
                height: 22,
                width: 22,
                marginLeft: '33%',
                marginTop:8
              }}
              onClick={handleClose}
            ></img>
          </div>
          <div>
            <p
              style={{
                fontWeight: 600,
                fontSize: '16px',
                lineHeight: '20px',
                paddingTop: '2%',
                paddingLeft: '2%',
              }}
            >
              Description
            </p>
            <p
              style={{
                fontWeight: 400,
                fontSize: '16px',
                lineHeight: '20px',
                color: '#666666',
                paddingTop: '2%',
                paddingBottom: '4%',
                paddingLeft: '2%',
              }}
            >
              Your wallet will top up automatically every month with the amount you will select to
              proceed with. This will be a recurring subscription cycle.
            </p>
            <ul
              style={{
                fontWeight: 400,
                fontSize: '16px',
                lineHeight: '20px',
                color: '#666666',
              }}
            >
              <li
                style={{
                  paddingRight: '3%',
                  paddingBottom: '4%',
                }}
              >
                {isPartner
                  ? 'The added amount will only be usable in partner dashboard.  You can distribute it amongst your workspaces.'
                  : 'The added amount will only be usable in this workspace. If you wish to distribute the amount among different workspaces, recharge on your partner dashboard, not here.'}
                {/* The added amount will only be usable in this workspace. To distribute the amount among different workspaces, switch to the partner dashboard. */}
              </li>
              <li
                style={{
                  paddingRight: '3%',
                  paddingBottom: '4%',
                }}
              >
                This balance will be used as your WhatsApp Conversations & SMS Balance
              </li>
              <li
                style={{
                  paddingRight: '3%',
                  paddingBottom: '4%',
                }}
              >
                The amount will be deducted from your wallet as per the conversation & SMS usage
              </li>
              <li
                style={{
                  paddingRight: '3%',
                  paddingBottom: '4%',
                }}
              >
                Per conversation & SMS pricing will vary as per the country terms.
              </li>
              <li
                style={{
                  paddingRight: '3%',
                  paddingBottom: '4%',
                }}
              >
                You will be able to track & monitor each transaction by accessing your wallet
                history
              </li>
              {!isPartner && (
                <li
                  style={{
                    paddingRight: '3%',
                    paddingBottom: '4%',
                  }}
                >
                  You can cancel your subscription anytime you want
                </li>
              )}
            </ul>
            <p
              style={{
                fontWeight: 700,
                fontSize: 16,
                lineHeight: '20px',
                paddingLeft: '2%',
                display: localdata?.isSubscribed ? 'none' : 'block',
              }}
            >
              Choose for monthly subscription
            </p>
            <div
              style={{
                display: localdata?.isSubscribed ? 'none' : 'flex',
                justifyContent: 'flex-start',
                paddingTop: '2%',
                paddingBottom: '5%',
                paddingRight: '15%',
              }}
            >
              {/* <div style={{ paddingLeft: '2%', paddingTop: '2%' }}>
                                <Button
                                    variant="outline-secondary"
                                    style={{
                                        borderRadius: '8px',
                                        cursor: 'pointer',
                                        color: clicked === '$1' ? '#8E53F7' : '#666666',
                                        borderColor: clicked === '$1' ? '#8E53F7' : '#666666',
                                        backgroundColor: clicked === '$1' ? '#ebd6fd' : 'white',
                                        width: '67px',
                                        height: '25px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                    onClick={() => handleClick('$1')}
                                >
                                    <div
                                        style={
                                            {
                                                paddingBottom: '2%'
                                            }
                                        }
                                    >$1</div>
                                </Button>
                            </div> */}
              <div style={{ paddingLeft: '2%', paddingTop: '2%' }}>
                <Button
                  variant="outline-secondary"
                  style={{
                    borderRadius: '8px',
                    cursor: 'pointer',
                    color: clicked === '$10' ? '#8E53F7' : '#666666',
                    borderColor: clicked === '$10' ? '#8E53F7' : '#666666',
                    backgroundColor: clicked === '$10' ? '#ebd6fd' : 'white',
                    width: '67px',
                    height: '25px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  onClick={() => handleClick('$10')}
                >
                  <div
                    style={{
                      paddingBottom: '2%',
                    }}
                  >
                    $10
                  </div>
                </Button>
              </div>
              <div style={{ paddingLeft: '2%', paddingTop: '2%' }}>
                <Button
                  variant="outline-secondary"
                  style={{
                    borderRadius: '8px',
                    cursor: 'pointer',
                    color: clicked === '$50' ? '#8E53F7' : '#666666',
                    borderColor: clicked === '$50' ? '#8E53F7' : '#666666',
                    backgroundColor: clicked === '$50' ? '#ebd6fd' : 'white',
                    width: '67px',
                    height: '25px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  onClick={() => handleClick('$50')}
                >
                  <div
                    style={{
                      paddingBottom: '2%',
                    }}
                  >
                    $50
                  </div>
                </Button>
              </div>
              <div style={{ paddingLeft: '2%', paddingTop: '2%' }}>
                <Button
                  variant="outline-secondary"
                  style={{
                    borderRadius: '8px',
                    cursor: 'pointer',
                    color: clicked === '$100' ? '#8E53F7' : '#666666',
                    borderColor: clicked === '$100' ? '#8E53F7' : '#666666',
                    backgroundColor: clicked === '$100' ? '#ebd6fd' : 'white',
                    width: '67px',
                    height: '25px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  onClick={() => handleClick('$100')}
                >
                  <div
                    style={{
                      paddingBottom: '2%',
                    }}
                  >
                    $100
                  </div>
                </Button>
              </div>
              <div style={{ paddingLeft: '2%', paddingTop: '2%' }}>
                <Button
                  variant="outline-secondary"
                  style={{
                    borderRadius: '8px',
                    cursor: 'pointer',
                    color: clicked === '$500' ? '#8E53F7' : '#666666',
                    borderColor: clicked === '$500' ? '#8E53F7' : '#666666',
                    backgroundColor: clicked === '$500' ? '#ebd6fd' : 'white',
                    width: '67px',
                    height: '25px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  onClick={() => handleClick('$500')}
                >
                  <div
                    style={{
                      paddingBottom: '2%',
                    }}
                  >
                    $500
                  </div>
                </Button>
              </div>

              <div style={{ paddingLeft: '2%', paddingTop: '2%' }}>
                <Button
                  variant="outline-secondary"
                  style={{
                    borderRadius: '8px',
                    cursor: 'pointer',
                    color: clicked === '$1000' ? '#8E53F7' : '#666666',
                    borderColor: clicked === '$1000' ? '#8E53F7' : '#666666',
                    backgroundColor: clicked === '$1000' ? '#ebd6fd' : 'white',

                    width: '67px',
                    height: '25px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  onClick={() => handleClick('$1000')}
                >
                  <div
                    style={{
                      paddingBottom: '2%',
                    }}
                  >
                    $1000
                  </div>
                </Button>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '2%',
              }}
            >
              <input
                type="checkbox"
                checked={isChecked}
                onChange={handleChange}
                style={{
                  width: '16px',
                  height: '16px',
                }}
              />
              <p
                style={{
                  marginTop: '-1%',
                  paddingLeft: '1%',
                }}
              >
                One-time top up
              </p>
            </div>
            {isChecked && (
              <div>
                <p
                  style={{
                    fontWeight: 700,
                    fontSize: '16px',
                    lineHeight: '20px',
                    paddingTop: '2%',
                    paddingLeft: '2%',
                    paddingBottom: '2%',
                  }}
                >
                  Top up amount
                </p>
                <input
                  type="number"
                  style={{
                    marginLeft: '2%',
                    width: '93%',
                  }}
                  className="form-control shadow-none"
                  placeholder="$ Enter manually"
                  inputMode="numeric"
                  value={inputValue}
                  onChange={handleInputChange}
                  min="0"
                //   onChange={(e) => setEmail(e.target.value)}
                //   autoComplete={true}
                />
              </div>
            )}
            <div
              style={{
                paddingTop: '7%',
                display: 'flex',
                justifyContent: 'flex-start',
                gap: 30,
                paddingLeft: '1.5%',
              }}
            >
              <CheerioButton
                borderStyle={{ borderColor: colors.black, width: '45%' }}
                textStyle={{ color: colors.black }}
                btnText={'Cancel'}
                backColor={colors.white01}
                onclick={handleClose}
              />
              <CheerioButton
                borderStyle={{ borderColor: colors.primary03, width: '45%' }}
                textStyle={{ color: colors.white01 }}
                btnText={isPartner || isChecked ? 'Recharge now' : 'Setup auto pay'}
                backColor={colors.primary03}
                onclick={handleButtonClick}
                disabled={!inputValue}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddTopUpModal;
