import React from 'react';
import colors from '../../../Utils/colors';
import CheerioButton from '../../../Components/CheerioButton';
import images from '../../../Utils/images';
import { BsFileEarmark } from 'react-icons/bs';
import { getImagePath } from '../../../Utils';
import { CardImg } from 'react-bootstrap';
import { PiArrowCircleDown, PiFileAudioThin } from 'react-icons/pi';

export const ReplyToOption = ({ setShowReplyToOption, _msg, setReplyToMsg }) => {
  const MobileWidth = window.innerWidth < 900 ? true : false;
  console.log('repmsg', _msg);
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: colors.white,
        flexDirection: MobileWidth ? 'column' : 'row',
      }}
    >
      <div
        style={{
          width: '100%',
          alignItems: 'center',
        }}
      >
        <p
          style={{
            fontSize: 12,
            fontWeight: 400,
            color: '#666666',
            fontStyle: 'italic',
            marginBottom: '2px',
          }}
        >
          {_msg?.isSend ? 'Replying to yourself' : 'Replying to ' + _msg?.name}
        </p>
        <div
          style={{
            backgroundColor: '#FAFAFA',
            width: '100%',
            marginBlockEnd: '10px',
            paddingInline: '8px',
            paddingBlock: '8px',
            borderRadius: '8px',
            display: 'flex',
            position: 'relative', // To position the white stripe
          }}
        >
          <div
            style={{
              backgroundColor: _msg?.isSend
                ? '#89A3FF78' // Blue background if condition is true
                : '#808080',
              width: '5px', // Adjust the width for the highlight
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 0,
              borderTopLeftRadius: '8px',
              borderBottomLeftRadius: '8px',
            }}
          />

          <p
            style={{
              fontSize: '12px',
              color: '#666666',
              paddingLeft: '12px', // Adjust padding to accommodate white stripe
            }}
          >
            {(_msg?.type === 'video' || _msg?.type === 'Video') && _msg?.fileURL ? (
              <div>
                <video
                  src={getImagePath(_msg.fileURL)}
                  controls={false}
                  style={{
                    maxHeight: 200,
                    width: '100%',
                    objectFit: 'cover',
                    marginBottom: 8,
                  }}
                ></video>
                <p style={{ fontSize: '12px' }}>{_msg?.msg ?? ''}</p>
              </div>
            ) : (_msg?.type === 'image' || _msg?.type === 'Image') &&
              (_msg?.image || _msg?.fileURL) ? (
              <div>
                <CardImg
                  src={getImagePath(_msg.image)}
                  style={{
                    width: '40px',
                    objectFit: 'cover',
                    borderRadius: 8,
                    marginBottom: 8,
                  }}
                />
                <p style={{ fontSize: '12px' }}>{_msg?.msg ?? ''}</p>
              </div>
            ) : (_msg?.type === 'audio' || _msg?.type === 'Audio') && _msg?.fileURL ? (
              <div>
                <PiFileAudioThin size={14} style={{ marginRight: '4px' }} />
                {_msg?.msg ?? ''}
              </div>
            ) : (_msg?.type === 'document' || _msg?.type === 'Document') && _msg?.fileURL ? (
              <div>
                <BsFileEarmark size={14} />
                <p style={{ fontSize: '12px' }}>{_msg?.msg ?? ''}</p>
              </div>
            ) : (
              _msg?.msg ?? ''
            )}
          </p>

          <img
            onClick={() => {
              setReplyToMsg(null);
              setShowReplyToOption(false);
            }}
            src={images.CMClose}
            style={{
              height: 18,
              width: 18,
              marginLeft: 'auto',
              objectFit: 'contain',
              cursor: 'pointer',
            }}
          />
        </div>
      </div>
    </div>
  );
};
