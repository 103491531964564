import React, { useEffect, useState } from 'react';
import colors from '../../Utils/colors';
import CheerioButton from '../../Components/CheerioButton';
import images from '../../Utils/images';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import VerificationStartModal from './Components/VerificationStartModal';
import ImportSettingsModal from '../Sequences/Components/ImportSettingsModal';
import VerifyEmailModal from '../Sequences/Components/VerifyEmailModal';
import { useSelector } from 'react-redux';
import { getAllVerificationList, verifyEmailSequences, downloadSequenceLabelData } from '../../Services';
import ToastModal from '../Sequences/Components/ToastModal';
import VerificationSuccessModal from '../Sequences/Components/VerificationSuccessModal';
import { toast } from 'react-toastify';
import { getLocaltime } from '../../Utils/localdate';
import { Tooltip } from '@mui/material';

const EmailVerifier = () => {
  const auth_token = useSelector((state) => state.main.auth_token);
  const localdata = useSelector((state) => state.main.localdata);
  const [tableData, setTableData] = useState();
  const [startModalOpen, setStartModalOpen] = useState(false);
  const [settingsModalOpen, setSettingsModalOpen] = useState(false);
  const [verifyModalOpen, setVerifyModalOpen] = useState(false);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [verificationName, setVerificationName] = useState('');
  const [selectedLabel, setSelectedLabel] = useState();
  const [verificationData, setVerificationData] = useState();
  const [VStatus, setVStatus] = useState();
  const [isToastOpen, setIsToastOpen] = useState(false);
  const columns = [
    {
      id: 'taskName',
      label: 'Verification Name',
      minWidth: 225,
      width: '25%',
      align: 'start',
    },
    {
      id: 'updatedAt',
      label: 'Verified On',
      minWidth: 162,
      width: '18%',
      align: 'center',
    },
    {
      id: 'status',
      label: 'Status',
      minWidth: 153,
      width: '17%',
      align: 'center',
    },
    {
      id: 'labelName',
      label: 'Label',
      minWidth: 225,
      width: '25%',
      align: 'center',
    },
    {
      id: 'action',
      label: 'Actions',
      minWidth: 135,
      width: '15%',
      align: 'center',
    },
  ];

  const downloadCsvApiCall = () => {
    let token = auth_token;
    let data = {
      labelId: selectedLabel?._id,
      email: localdata?.email,
    };
    downloadSequenceLabelData(data, token).then((res) => {
      if (res?.flag) {
        toast.success("CSV sent to your registered email id", {
          autoClose: true,
          position: 'top-right',
        });
      } else {
        toast.error('CSV download failed, please try again later', {
          autoClose: true,
          position: 'top-right',
        });
      }
    });
  };

  const checkStatus = () => {
    let token = auth_token;
    let data = {
      labelId: selectedLabel?._id,
      taskName: verificationName,
    };
    verifyEmailSequences(data, token).then((res) => {
      if (res?.flag) {
        setVStatus(res?.data?.status);
        if (res?.data?.status === 'Complete') {
          setVerificationData(res?.data);
        }
      } else {
        toast.error('Email verification failed, please try again later', {
          autoClose: true,
          position: 'top-right',
        });
        setTimeout(() => {
          setVerifyModalOpen(false);
          // setActiveStep(1)
        }, 2000);
      }
    });
  };

  useEffect(()=>{
    getAllVerificationsListAPIcall();
  },[])

  const getAllVerificationsListAPIcall = () => {
    let token = auth_token;
    let data = {
      page: 1,
      limit: 15,
    };
    getAllVerificationList(data,token).then((res)=>{
      if(res?.flag){
        setTableData(res?.data)
        console.log("success ---> ",res);
      }else{
        console.log("failure ---> ",res);
      }
    })
  }

  useEffect(() => {
    if (VStatus) {
      let interval;
      if (VStatus === 'Pending') {
        interval = setInterval(() => {
          checkStatus();
        }, 30000); // 30 seconds interval
      }

      if (VStatus === 'Complete') {
        clearInterval(interval);
      }

      return () => clearInterval(interval);
    }
  }, [VStatus]);

  useEffect(() => {
    if (VStatus === 'Complete' && verificationData && verifyModalOpen) {
      setVerifyModalOpen(false);
      setSuccessModalOpen(true);
      getAllVerificationsListAPIcall();
    } else if (VStatus && !verifyModalOpen) {
      setIsToastOpen(true);
      getAllVerificationsListAPIcall();
    }
  }, [VStatus, verificationData]);

  return (
    <>
      <div className="w-100 Column" style={{ height: '100dvh', overflow: 'hidden' }}>
        {startModalOpen && (
          <VerificationStartModal
            setIsModalOpen={setStartModalOpen}
            verificationName={verificationName}
            setVerificationName={setVerificationName}
            selectedLabel={selectedLabel}
            setSelectedLabel={setSelectedLabel}
            onclicknextaction={() => {
              setStartModalOpen(false);
              setSettingsModalOpen(true);
            }}
          />
        )}
        {settingsModalOpen && (
          <ImportSettingsModal
            setIsImportSettingsModalOpen={setSettingsModalOpen}
            selectedLabel={selectedLabel}
            EV={true}
            verifyaction={() => {
              setSettingsModalOpen(false);
              setVerifyModalOpen(true);
            }}
          />
        )}
        {verifyModalOpen && (
          <VerifyEmailModal
            labelId={selectedLabel?._id}
            onclickproceedaction={() => {
              console.log('close and refresh');
              setVerifyModalOpen(false);
              setIsToastOpen(true);
            }}
            checkStatus={checkStatus}
            status={VStatus}
            EV={true}
          />
        )}
        {successModalOpen && 
          <VerificationSuccessModal 
            setIsVSModalOpen={setSuccessModalOpen} 
            verificationData={verificationData} 
            EV={true}
          />
        }
        {isToastOpen && (
          <ToastModal
            status={VStatus}
            refreshStatusAction={checkStatus}
            openVSModalAction={() => {
              setSuccessModalOpen(true);
            }}
            setIsToastOpen={setIsToastOpen}
          />
        )}
        <div
          className="w-100 Row align-items-start"
          style={{ paddingInline: 24, paddingBlock: 20 }}
        >
          <div className="Column align-items-start" style={{ flex: 1 }}>
            <h3>{'Email Verifier'}</h3>
            <p className="my-2" style={{ color: colors.greys04 }}>
              {'Bulk Verify thousands of email addresses in just few clicks'}
            </p>
          </div>
          <CheerioButton
            btnText={'New Verification'}
            textStyle={{ color: colors.white01 }}
            icon={images.AddIcon}
            backColor={colors.primary03}
            borderStyle={{ paddingInline: 20, paddingBlock: 8, borderColor: colors.primary03 }} 
            onclick={()=>{
              setVerificationName('');
              setSelectedLabel();
              setStartModalOpen(true);
            }}
          />
        </div>
        <div className="w-100" style={{ flex: 1, paddingBlock: 20, paddingInline: 24 }}>
          {tableData?.length > 0 ? (
            <>
              <div className="w-100 h-100 Column justify-content-start">
                <TableContainer sx={{ flex: 1 }}>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{
                              minWidth: column.minWidth,
                              width: column.width,
                              fontSize: 16,
                              fontWeight: 500,
                              color: colors.black,
                              backgroundColor: colors.white04,
                            }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tableData.map((row, index) => {
                        return (
                          <TableRow key={index}>
                            {columns.map((column) => {
                              const value = row[column.id];
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  {column.id === 'updatedAt' ? (
                                    <p style={{ fontSize: 14, color: colors.black }}>
                                      {getLocaltime(row.createdAt)}
                                    </p>
                                  ) : column.id === 'action' ? (
                                    <>
                                      <div className="Row justify-content-center">
                                        
                                          
                                            <Tooltip title={'Download'}>
                                              <img
                                                src={images.DownloadIcon}
                                                height={18}
                                                style={{
                                                  cursor: 'pointer',
                                                  marginInline: 6,
                                                }}
                                                onClick={() => {
                                                  downloadCsvApiCall();
                                                }}
                                              />
                                            </Tooltip>
                                      </div>
                                    </>
                                  ) : (
                                    <p style={{ fontSize: 14, color: colors.black }}>{value}</p>
                                  )}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                  {/* <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={tableData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  /> */}
                </TableContainer>
              </div>
            </>
          ) : (
            <>
              <div className="w-100 h-100 Column justify-content-center">
                <img
                  src={images.EVEmptyImage}
                  style={{
                    height: 270,
                    width: 270,
                    objectFit: 'contain',
                    marginBlock: 16,
                  }}
                />
                <p style={{ fontWeight: 500 }}>{'Select label to get started'}</p>
                <p className="w-50 my-2" style={{ textAlign: 'center', color: colors.greys04 }}>
                  {
                    'The supported file formats are TXT and CSV. The TXT file can contain only email addresses and the CSV file can contain multiple data columns.'
                  }
                </p>
                <CheerioButton
                  btnText={'Start Verification'}
                  textStyle={{ color: colors.white01 }}
                  backColor={colors.primary03}
                  borderStyle={{
                    paddingInline: 20,
                    paddingBlock: 8,
                    borderColor: colors.primary03,
                    marginBlock: 16,
                  }}
                  onclick={() => {
                    setVerificationName('');
                    setSelectedLabel();
                    setStartModalOpen(true);
                  }}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default EmailVerifier;
