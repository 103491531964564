import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { getLinkedinSequenceAnalytics, getLinkedinSequenceIndividualAnalytics } from '../../../Services';
import images from '../../../Utils/images';
import colors from '../../../Utils/colors';
import { getLocaltime } from '../../../Utils/localdate';
import { LinearProgress } from '@mui/material';
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import LinkedinGraphComponent from './LinkedinGraphComponent';
import { DataGrid } from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';


const RowItem = ({ itemText, itemNumber }) => {
    return (
        <>
            <div className='Row justify-content-between w-100 my-2'>
                <p style={{ fontSize: 16, fontWeight: 400 }}>
                    {itemText}
                </p>
                <p style={{ fontSize: 16, fontWeight: 400 }}>
                    {itemNumber}
                </p>
            </div>
        </>
    )
}

const ColumnItem = ({ itemNumber, itemText, itemColor }) => {
    return (
        <>
            <div className='Column justify-content-start align-items-start' style={{ flex: 1 }}>
                <p style={{ fontSize: 20, fontWeight: 600 }}>
                    {itemNumber}
                </p>
                <div className='Row justify-content-start'>
                    <div style={{
                        height: 12,
                        width: 12,
                        backgroundColor: itemColor,
                        borderRadius: '50%',
                        marginInlineEnd: 8,
                    }}></div>
                    <p style={{ fontSize: 16, fontWeight: 400 }}>
                        {itemText}
                    </p>
                </div>
            </div>
        </>
    )
}

const LinkedinSequenceAnalytics = () => {

    const { id } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const authToken = useSelector((state) => state.main.auth_token);
    const sequenceData = location.state;
    const [analyticsData, setAnalyticsData] = useState([])
    const [stepAnalyticsData, setStepAnalyticsData] = useState([])
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getLinkedinSequenceAnalyticsAPICall();
        getLinkedinSequenceIndividualAnalyticsAPICall();
        console.log("22222 ", id)
        console.log("22222 ", sequenceData)
    }, [])

    const getLinkedinSequenceAnalyticsAPICall = () => {
        let token = authToken;
        let data = {
            _id: id,
        };
        getLinkedinSequenceAnalytics(data, token).then((res) => {
            if (res?.flag) {
                console.log("success ---> ", res)
                setAnalyticsData(res?.data)

            } else {
                console.log("failure ---> ", res)
            }
        })
    }

    const getLinkedinSequenceIndividualAnalyticsAPICall = () => {
        let token = authToken;
        let data = {
            _id: id,
        };
        getLinkedinSequenceIndividualAnalytics(data, token).then((res) => {
            if (res?.flag) {
                console.log("success ---> ", res)
                setStepAnalyticsData(res?.data);
                setLoading(false);
            } else {
                console.log("failure ---> ", res)
            }
        })
        setLoading(false);
    }

    const columns = [
        {
            field: 'linkedinURL',
            headerName: 'LinkedIn URL',
            width: 250,
            renderCell: (params) => (
                <a href={params.value} target="_blank" rel="noopener noreferrer">
                    {params.value.replace('https://www.linkedin.com/in/', '')}
                </a>
            )
        },
        {
            field: 'createdAt',
            headerName: 'Timestamp',
            width: 180,
            valueFormatter: (params) => new Date(params.value).toLocaleString()
        },
        { field: 'status', headerName: 'Status', width: 120 },
        {
            field: 'invitationSent',
            headerName: 'Invitation Sent',
            width: 140,
            type: 'boolean'
        },
        {
            field: 'messageSent',
            headerName: 'Message Sent',
            width: 140,
            type: 'boolean'
        },
        {
            field: 'messageText',
            headerName: 'Message Text',
            width: 140,
            renderCell: (params) => (
                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    {params?.value?.length ? params.value : ""}
                </div>
            ),
            editable: false,
        },
        {
            field: 'additionalData',
            headerName: 'Additional Data',
            width: 140,
            renderCell: (params) => (
                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    {params?.value?.length ? params.value : ""}
                </div>
            ),
            editable: false,
        },

    ];


    ChartJS.register(ArcElement, Tooltip, Legend);

    const data = {
        labels: ["Leads Completed", "Leads Triggered"],
        datasets: [
            {
                data: [(Number(analyticsData?.completed) / (Number(analyticsData?.totalLeads))) * 100, ((Number(analyticsData?.totalLeads) - Number(analyticsData?.completed)) / (Number(analyticsData?.totalLeads))) * 100], // 87% completed, 13% remaining
                backgroundColor: ["#4653F3", "#9BA3FF"], // Colors for sections
                hoverBackgroundColor: ["#4653F3", "#9BA3FF"], // Hover effect
                borderWidth: 0, // Remove border
            },
        ],
    };

    const options = {
        cutout: "70%", // Adjust the inner radius for the donut effect
        responsive: true,
        plugins: {
            tooltip: {
                enabled: true, // Disable tooltips if not needed
            },
            legend: {
                display: false, // Hide the legend
            },
        },
    };

    return (
        <>
            <div className='Column justify-content-start align-items-center w-100 h-100' style={{
                overflow: 'hidden',
                paddingBlock: 24,
                paddingInline: 30,
            }}>
                <div className='Row w-100 justify-content-between align-items-start' style={{
                    marginBlockEnd: 20,
                }}>
                    <img
                        src={images.BackArrowBlack}
                        style={{ height: 24, width: 24, objectFit: 'contain', cursor: 'pointer' }}
                        onClick={() => { navigate('/creator/linkedinsequences') }}
                    />
                    <div className='Column justify-content-start align-items-start' style={{
                        paddingInline: 16,
                        flex: 1,
                    }}>
                        <h3 style={{ fontSize: 24, fontWeight: 700 }}>
                            {sequenceData?.name}
                        </h3>
                        <p className='my-1' style={{
                            fontSize: 14, fontWeight: 400, color: colors.greys04
                        }}>
                            {`Created at ${getLocaltime(sequenceData?.createdAt)} | ${sequenceData?.sequenceCount} Sequences`}
                        </p>
                    </div>
                </div>

                <div className='Column w-100 justify-content-start' style={{ flex: 1, overflowY: 'auto' }}>
                    <div className='Row w-100' style={{
                        marginBlock: 16,
                        alignItems: 'stretch',
                        height: 'auto',
                    }}>
                        <div className='w-50 pe-2' style={{}}>
                            <div className='Column justify-content-between align-items-start py-2 px-3' style={{
                                border: `1px solid ${colors.borderwhite}`,
                                borderRadius: 16,
                                backgroundColor: colors.white01,
                                height: '100%'
                            }}>
                                <p style={{ fontSize: 20, fontWeight: 700 }} className='mb-2'>
                                    {`${((Number(analyticsData?.completed) / Number(analyticsData?.totalLeads)) * 100).toFixed(0)}%`}
                                    <span style={{ fontSize: 16, fontWeight: 400 }}>
                                        {' of campaign completed'}
                                    </span>
                                </p>
                                <div className='w-100 my-2'>
                                    <LinearProgress variant="determinate" value={Number(analyticsData?.completed) / Number(analyticsData?.totalLeads).toFixed(2) * 100} />
                                </div>
                                <RowItem
                                    itemText={'Total Leads'}
                                    itemNumber={analyticsData?.totalLeads}
                                />
                                <RowItem
                                    itemText={'Leads in progress'}
                                    itemNumber={analyticsData?.totalLeads - analyticsData?.completed}
                                />
                                <RowItem
                                    itemText={'Leads completed'}
                                    itemNumber={analyticsData?.completed}
                                />
                                <RowItem
                                    itemText={'Dropped Leads'}
                                    itemNumber={analyticsData?.aborted}
                                />
                            </div>
                        </div>
                        <div className='w-50 ps-2' style={{}}>
                            <div className='Column justify-content-between align-items-start py-2 px-3' style={{
                                border: `1px solid ${colors.borderwhite}`,
                                borderRadius: 16,
                                backgroundColor: colors.white01,
                                height: '100%',
                            }}>
                                <p style={{ fontSize: 20, fontWeight: 700 }} className='mb-2'>
                                    {`Lead Quality`}
                                </p>
                                <div className='w-100 my-2 Row justify-content-center' style={{ height: 200, position: 'relative' }}>
                                    <Doughnut data={data} options={options} />
                                    <p style={{ fontSize: 30, fontWeight: 600, position: 'absolute' }}>
                                        {`${((Number(analyticsData?.completed) / Number(analyticsData?.totalLeads)) * 100).toFixed(0)}%`}
                                    </p>
                                </div>
                                <div className='Row w-100 align-items-start'>
                                    <ColumnItem
                                        itemNumber={analyticsData?.totalLeads}
                                        itemText={'Total Leads'}
                                        itemColor={'#4653F3'}
                                    />
                                    <ColumnItem
                                        itemNumber={analyticsData?.completed}
                                        itemText={'Leads Completed'}
                                        itemColor={'#4653F3'}
                                    />
                                    <ColumnItem
                                        itemNumber={(analyticsData?.totalLeads - analyticsData?.completed)}
                                        itemText={'Leads Triggered'}
                                        itemColor={'#9BA3FF'}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {analyticsData?.linkedinQueues?.length > 0 &&
                        <LinkedinGraphComponent
                            graphData={analyticsData}
                        />
                    }

                    {
                        stepAnalyticsData?.length > 0 &&
                        <div style={{ height: 600, width: '100%', marginTop: "2rem" }}>
                            <DataGrid
                                rows={stepAnalyticsData}
                                columns={columns}
                                pageSize={10}
                                rowsPerPageOptions={[5, 10, 20, 50]}
                                checkboxSelection
                                disableSelectionOnClick
                                loading={loading}
                                getRowId={(row) => row._id}
                            />
                        </div>
                    }


                    {/* <div className='Column w-100 py-2 px-3' style={{
                        border: `1px solid ${colors.borderwhite}`,
                        marginBlock: 16,
                        borderRadius: 16,
                        backgroundColor: colors.white01,
                    }}>
                        <p style={{ fontSize: 20, fontWeight: 700 }} className='mb-2 w-100'>
                            {`Sequence Breakup`}
                        </p>
                        {analyticsData?.seqBreakup?.length > 0 && analyticsData?.seqBreakup.map((item, index) => {
                            return (
                                <>
                                    <div className='Row w-100 py-2 justify-content-around' style={{
                                        borderBottom: `1px solid ${colors.borderwhite}`
                                    }}>
                                        <p style={{ fontSize: 18, fontWeight: 600 }}>
                                            {`Step ${index + 1} `}
                                        </p>
                                        <div className='Column justify-content-start align-items-start'>
                                            <p style={{ fontSize: 16, fontWeight: 400 }}>
                                                {item?.sent}<br />
                                                <span style={{ color: colors.black04 }}>
                                                    {'Sent'}
                                                </span>
                                            </p>
                                        </div>
                                        <div className='Column justify-content-start align-items-start'>
                                            <p style={{ fontSize: 16, fontWeight: 400 }}>
                                                {item?.opened}<br />
                                                <span style={{ color: colors.black04 }}>
                                                    {'Opened'}
                                                </span>
                                            </p>
                                        </div>
                                        <div className='Column justify-content-start align-items-start'>
                                            <p style={{ fontSize: 16, fontWeight: 400 }}>
                                                {item?.clicked}<br />
                                                <span style={{ color: colors.black04 }}>
                                                    {'Clicked'}
                                                </span>
                                            </p>
                                        </div>
                                        <div className='Column justify-content-start align-items-start'>
                                            <p style={{ fontSize: 16, fontWeight: 400 }}>
                                                {item?.replied}<br />
                                                <span style={{ color: colors.black04 }}>
                                                    {'Replied'}
                                                </span>
                                            </p>
                                        </div>
                                        <div className='Column justify-content-start align-items-start'>
                                            <p style={{ fontSize: 16, fontWeight: 400 }}>
                                                {item?.bounced}<br />
                                                <span style={{ color: colors.black04 }}>
                                                    {'Bounced'}
                                                </span>
                                            </p>
                                        </div>
                                        <div className='Column justify-content-start align-items-start'>
                                            <p style={{ fontSize: 16, fontWeight: 400 }}>
                                                {item?.spam}<br />
                                                <span style={{ color: colors.black04 }}>
                                                    {'Spam'}
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </>
                            )
                        })}
                    </div> */}
                </div>

            </div>
        </>
    )
}

export default LinkedinSequenceAnalytics