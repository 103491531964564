// SequencesTable.jsx
import React, { useEffect, useState } from 'react';
import { FiEdit2 } from 'react-icons/fi';
import { BiCopy } from 'react-icons/bi';
import { BsTrash } from 'react-icons/bs';
import { BsPause, BsPlay, BsEye } from 'react-icons/bs';
import { FaRegStopCircle } from 'react-icons/fa';
import { fetchAllLinkedinSequences, updateLinkedinSequenceAction, deleteLinkedinSequence, getLinkedinSequence } from '../../../Services';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import images from '../../../Utils/images';
import { eventsend } from '../../../Config/analyticsFunctions';
import { TablePagination, Box } from '@mui/material';

const SequencesTable = ({ hasSequences, setHasSequences }) => {
    const [sequences, setSequences] = useState([]);
    const [paginationData, setPaginationData] = useState({
        totalItems: 0,
        currentPage: 0,
        rowsPerPage: 10
    });
    const navigate = useNavigate();

    useEffect(() => {
        fetchAllLinkedinSequencesAPI(1, paginationData.rowsPerPage);
    }, []);

    useEffect(() => {
        setHasSequences(sequences.length > 0);
    }, [sequences, setHasSequences]);

    const localdata = useSelector((state) => state.main.localdata);
    const authtoken = useSelector((state) => state.main.auth_token);

    const fetchAllLinkedinSequencesAPI = (page, limit) => {
        let token = authtoken;
        try {
            fetchAllLinkedinSequences(token, page, limit).then((res) => {
                if (res?.flag && res?.data?.docs) {
                    const formattedSequences = res.data.docs.map(sequence => ({
                        id: sequence?._id,
                        name: sequence?.name,
                        createdAt: new Date(sequence.createdAt).toLocaleString("en-US", {
                            day: "2-digit",
                            month: "short",
                            year: "numeric",
                            hour: "2-digit",
                            minute: "2-digit"
                        }),
                        sequenceCount: sequence?.nodes?.length,
                        leads: sequence?.profileUrls?.length,
                        label: sequence?.label?.name || '-',
                        status: sequence?.status || '-',
                        triggered: 0,
                        completed: 0,
                        acceptanceRate: 0,
                        replyRate: 0
                    }));
                    setSequences(formattedSequences);

                    setPaginationData({
                        totalItems: res.data.totalDocs,
                        currentPage: res.data.page - 1,
                        rowsPerPage: limit
                    });
                } else if (!res?.flag) {
                    // toast.error(res?.message);
                    setSequences([]);
                } else {
                    setSequences([]);
                }
            }).catch((err) => {
                // toast.error(err?.message || "Failed to fetch sequences");
                setSequences([]);
            });
        } catch (error) {
            // toast.error(error?.message || "An error occurred");
            setSequences([]);
        }
    };

    const handlePageChange = (event, newPage) => {
        fetchAllLinkedinSequencesAPI(newPage + 1, paginationData.rowsPerPage);
    };

    const handleChangeRowsPerPage = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setPaginationData({
            ...paginationData,
            rowsPerPage: newRowsPerPage,
            currentPage: 0
        });
        fetchAllLinkedinSequencesAPI(1, newRowsPerPage);
    };

    const handleAction = (sequence, action) => {
        const data = {
            _id: sequence.id,
            action: action
        };
        let token = authtoken;
        updateLinkedinSequenceAction(data, token)
            .then((res) => {
                if (res?.flag) {
                    toast.success(`Successfully ${action}d sequence`);
                    fetchAllLinkedinSequencesAPI(paginationData.currentPage + 1, paginationData.rowsPerPage);
                } else {
                    toast.error(res?.message);
                }
            })
            .catch((err) => {
                toast.error(err?.response?.data?.message || err?.message || 'Failed to perform action');
            });
    };

    const handleDelete = (sequence) => {
        deleteLinkedinSequence(authtoken, sequence.id)
            .then((res) => {
                if (res?.data?.flag) {
                    toast.success('Sequence deleted successfully');
                    fetchAllLinkedinSequencesAPI(paginationData.currentPage + 1, paginationData.rowsPerPage);
                } else {
                    toast.error(res?.data?.message || 'Failed to delete sequence');
                }
            })
            .catch((err) => {
                toast.error('Failed to delete sequence');
            });
    };

    const actionIconStyle = {
        cursor: 'pointer',
        '&:hover': {
            opacity: 0.7
        }
    };

    if (sequences?.length > 0) {
        return (
            <div style={{ padding: '20px' }}>
                <table style={{ width: '100%', borderCollapse: 'separate', borderSpacing: '0 1px' }}>
                    <thead>
                        <tr style={{ backgroundColor: '#F9FAFB' }}>
                            <th style={{ padding: '16px', width: '30%', textAlign: 'left', fontWeight: '500' }}>Campaign Name</th>
                            <th style={{ padding: '16px', width: '15%', textAlign: 'left', fontWeight: '500' }}>Label</th>
                            <th style={{ padding: '16px', textAlign: 'left', fontWeight: '500' }}>Created At</th>
                            <th style={{ padding: '16px', textAlign: 'left', fontWeight: '500' }}>Nodes</th>
                            <th style={{ padding: '16px', textAlign: 'left', fontWeight: '500' }}>Leads</th>
                            <th style={{ padding: '16px', textAlign: 'left', fontWeight: '500' }}>Status</th>
                            <th style={{ padding: '16px', width: '10%', textAlign: 'center', fontWeight: '500' }}>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sequences.map((sequence) => (
                            <tr key={sequence.id} style={{ backgroundColor: 'white' }}>
                                <td style={{ padding: '16px' }}>
                                    <div style={{ fontWeight: '500' }}>{sequence.name}</div>
                                </td>
                                <td style={{ padding: '16px' }}>{sequence.label}</td>
                                <td style={{ padding: '16px' }}>{sequence.createdAt}</td>
                                <td style={{ padding: '16px' }}>{sequence.sequenceCount}</td>
                                <td style={{ padding: '16px' }}>{sequence.leads}</td>
                                <td style={{ padding: '16px' }}>{sequence.status}</td>
                                <td style={{ padding: '16px', textAlign: 'center' }}>
                                    <div style={{ display: 'flex', justifyContent: 'center', gap: '12px' }}>
                                        {sequence.status !== 'Completed' && (
                                            <>
                                                {sequence.status !== 'Stopped' && sequence.status !== 'Queued' && (
                                                    sequence.status === 'Active' ? (
                                                        <BsPause
                                                            size={18}
                                                            style={{
                                                                ...actionIconStyle,
                                                                cursor: 'pointer'
                                                            }}
                                                            onClick={() => handleAction(sequence, 'pause')}
                                                            title="Pause"
                                                        />
                                                    ) : (
                                                        <BsPlay
                                                            size={18}
                                                            style={{
                                                                ...actionIconStyle,
                                                                cursor: 'pointer'
                                                            }}
                                                            onClick={() => handleAction(sequence, 'resume')}
                                                            title="Resume"
                                                        />
                                                    )
                                                )}
                                                {sequence.status !== 'Queued' && (
                                                    <img src={images.Stop} width={18} height={18}
                                                        style={{
                                                            ...actionIconStyle,
                                                            cursor: sequence.status === 'Stopped' ? 'not-allowed' : 'pointer',
                                                            opacity: sequence.status === 'Stopped' ? 0.5 : 1
                                                        }}
                                                        onClick={() => sequence.status !== 'Stopped' && handleAction(sequence, 'stop')}
                                                        title="Stop"
                                                    />
                                                )}
                                            </>
                                        )}
                                        <BsTrash
                                            size={18}
                                            style={{
                                                ...actionIconStyle,
                                            }}
                                            onClick={() => handleDelete(sequence)}
                                            title="Delete"
                                        />
                                        <img
                                            src={images.BarChart}
                                            height={18}
                                            style={{
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => {
                                                eventsend("Analytics-icon-button_LinkedIn-sequences_clicked");
                                                navigate(`/creator/linkedinsequences/analytics/${sequence.id}`, {
                                                    state: sequence
                                                });
                                            }}
                                        />
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <TablePagination
                    component="div"
                    count={paginationData.totalItems}
                    page={paginationData.currentPage}
                    onPageChange={handlePageChange}
                    rowsPerPage={paginationData.rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    rowsPerPageOptions={[10, 25, 50]}
                    labelRowsPerPage="Rows per page:"
                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} of ${count}`}
                    sx={{
                        '.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows': {
                            margin: 0,
                        }
                    }}
                />
            </div>
        );
    }
    return null;
};

export default SequencesTable;