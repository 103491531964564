import React, { useEffect, useState } from 'react';
import colors from '../../../Utils/colors';
import images from '../../../Utils/images';

const AddressButton = ({PreviewText}) => {
  return (
    <div
      className="d-flex flex-column justify-content-start align-items-center"
      style={{
        width: '70%',
        borderRadius: 8,
        paddingBlock: 6,
        backgroundColor: '#1F2C34',
        marginTop: 10,
        fontSize: 12,
        height: 30,
        paddingInline: 5,
      }}
    >
      <p
        style={{
          padding: 0,
          margin: 0,
          fontWeight: 400,
          color: colors.white,
        }}
      >
        {PreviewText}
      </p>
    </div>
  );
};

export const SendAddressPreview = ({ body, header,text }) => {
  const formatText = (text, arr) => {
    let formattedText = text;

    // formattedText = formattedText.replace(/\*(.*?)\*/g, '<b>$1</b>'); // Bold
    formattedText = formattedText.replace(/_(.*?)_/g, '<i>$1</i>'); // Italics
    formattedText = formattedText.replace(/~(.*?)~/g, '<s>$1</s>'); // Strike-through
    formattedText = formattedText.replace(/\n/g, '<br>');
    const regex = formattedText.match(/\{\{(\d+)\}\}/g) ?? [];
    regex.map((reg, index) => {
      formattedText = formattedText.replace(reg, function ($0) {
        //if (count === newArr.length) count = 0;

        return arr?.[index] ?? reg;
      });
    });
    return formattedText;
  };

  const PreviewText = text === 'Request Address' ? 'Share Address' : 'Share Location';
  return (
    <>
      <div
        className="d-flex flex-column justify-content-start w-100 px-4 py-4 h-100"
        style={{ marginBlockStart: 20, overflow: 'auto', alignItems: 'center' }}
      >
        <div
          className="d-flex flex-column justify-content-start align-items-center"
          style={{
            width: '70%',
            borderRadius: 10,
            padding: 16,
            backgroundColor: '#1F2C34',
            position: 'relative',
          }}
        >
          <img
            alt="WA_logo"
            src={images.CreatorWA}
            style={{
              height: 32,
              width: 32,
              objectFit: 'contain',
              position: 'absolute',
              top: -14,
              left: -14,
            }}
          ></img>
          {header.format !== 'TEXT' && header.format !== 'NONE' && (
            <div
              className=""
              style={{
                minHeight: '150px',
                height: '100%',
                width: '100%',
                backgroundColor: colors.borderwhite,
                borderRadius: 8,
              }}
            >
            </div>
          )}
          <>
            {header.format === 'TEXT' && (
              // <span style={{ padding: 0, margin: 0, paddingBlockStart: 10, fontSize: 14, fontWeight: 'bold', color: colors.white, width: '100%', wordWrap: 'break-word' }}>{headerText}</span>
              <span
                style={{
                  padding: 0,
                  margin: 0,
                  paddingBlockStart: 10,
                  fontSize: 14,
                  fontWeight: 'bold',
                  color: colors.white,
                  width: '100%',
                  wordWrap: 'break-word',
                }}
              >
                {formatText(header.text, header.example.header_text)}
              </span>
            )}

            <p
              style={{
                color: colors.white,

                width: '100%',
              }}
              dangerouslySetInnerHTML={{ __html: formatText(body?.text, body?.example?.body_text) }}
            />

          </>
      
                  
        </div>
        <AddressButton PreviewText = {PreviewText}/>
      </div>
    </>
  );
};
