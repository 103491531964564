import React from 'react';
import colors from '../../../Utils/colors';
import { IoIosArrowForward } from 'react-icons/io';
import images from '../../../Utils/images';
import { CiClock2 } from 'react-icons/ci';
import { Tooltip } from '@mui/material';

const AccordianItems = ({
  ItemImage,
  ItemHeader,
  ItemText,
  ActionText,
  onclickAction,
  completed = false,
  InfoTooltip = false,
}) => {
  const isMobile = window.innerWidth < 700;

  return (
    <div
      className="hoverShadow me-3 d-flex flex-row justify-content-between align-items-stretch"
      style={{
        width: isMobile ? '100%' : 250, // Full width on mobile, fixed width on larger screens
        minWidth: isMobile ? 220 : 250, // Ensures minimum width
        maxWidth: isMobile ? 300 : 'auto', // Prevents it from being too wide
        border: `1px solid ${colors.borderwhite}`,
        borderRadius: 10,
        backgroundColor: colors.white01,
        padding: 12,
        marginBlockEnd: isMobile ? 12 : 8,
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      <img
        className="me-2"
        src={ItemImage}
        alt={ItemHeader}
        style={{ height: 30, width: 30, objectFit: 'contain' }}
      />
      <div
        className="d-flex flex-column justify-content-start align-items-start"
        style={{ flex: 1, minWidth: 0 }} // Ensures text does not overflow
      >
        <h4
          style={{
            color: colors.black,
            fontSize: '16px',
            wordWrap: 'break-word',
            overflowWrap: 'break-word',
          }}
        >
          {ItemHeader}
        </h4>
        <p
          className="mt-1 mb-2"
          style={{
            color: colors.greys04,
            fontSize: '14px',
            wordWrap: 'break-word',
            overflowWrap: 'break-word',
            whiteSpace: 'normal',
          }}
        >
          {ItemText}
        </p>
        <div
          className="mt-auto d-flex flex-row justify-content-between align-items-center w-100 mb-0"
          style={{ cursor: completed ? 'default' : 'pointer' }}
          onClick={() => {
            if (!completed) {
              onclickAction();
            }
          }}
        >
          {ItemHeader === 'SMS' ? (
            completed === 'pending' ? (
              <div className="d-flex">
                <CiClock2
                  style={{
                    height: 20,
                    width: 20,
                    color: '#FEAF52',
                    marginRight: '5px',
                  }}
                />
                <p style={{ color: '#FEAF52' }}>{'Request processing'}</p>
              </div>
            ) : completed === 'completed' ? (
              <>
                <p style={{ color: colors.success03 }}>{'Completed'}</p>
                <img
                  src={images.SUCCESSFULL}
                  alt="Success"
                  style={{ height: 20, width: 20, objectFit: 'contain' }}
                />
              </>
            ) : (
              <>
                <p style={{ color: colors.themeDarkBlue }}>{ActionText}</p>
                <IoIosArrowForward color={colors.themeDarkBlue} />
              </>
            )
          ) : completed ? (
            <>
              <p style={{ color: colors.success03 }}>{'Completed'}</p>
              <img
                src={images.SUCCESSFULL}
                alt="Success"
                style={{ height: 20, width: 20, objectFit: 'contain' }}
              />
            </>
          ) : (
            <>
              <p style={{ color: colors.themeDarkBlue }}>{ActionText}</p>
              <IoIosArrowForward color={colors.themeDarkBlue} />
            </>
          )}
        </div>
      </div>
      {InfoTooltip && (
        <Tooltip title={'Onboarding support call recommended'}>
          <img
            className="ms-2"
            src={images.InfoCircleGrey}
            alt="Info"
            style={{
              height: 20,
              width: 20,
              objectFit: 'contain',
              cursor: 'pointer',
            }}
          />
        </Tooltip>
      )}
    </div>
  );
};

export default AccordianItems;
