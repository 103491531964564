import React, { useEffect, useState } from 'react';
import colors from '../../../Utils/colors';
import images from '../../../Utils/images';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import CheerioButton from '../../../Components/CheerioButton';
import { useSelector } from 'react-redux';
import { EmbeddedSignupApi, getCountryCode, getStatus } from '../../../Services';
import SpinnerLoader from '../../../Components/SpinnerLoader';
import Alert from 'react-bootstrap/Alert';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PhoneInput from 'react-phone-input-2';
import { eventsend } from '../../../Config/analyticsFunctions';

const EmbeddedModal = ({
  setIsEmbeddedModalOpen,
  openEmbeddedAction = false,
  checkWAStatusAction,
}) => {
  useEffect(() => {
    getcountrycodeapicall();
    setTimezone('Asia/Calcutta GMT+05:30');
    if (openEmbeddedAction) {
      setEmbeddedOpen(true);
    }
  }, []);

  const getcountrycodeapicall = () => {
    getCountryCode().then((res) => {
      if (res) {
        setBillingCurrency(res?.currency);
      } else {
        console.log('error coun code', res);
      }
    });
  };

  const authtoken = useSelector((state) => state.main.auth_token);
  const localdata = useSelector((state) => state.main.localdata);
  const tempFBToken = useSelector((state) => state.main.tempFBToken);
  const [fullName, setFullName] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [companySize, setCompanySize] = useState('');
  const [companyIndustry, setCompanyIndustry] = useState('');
  const [billingCurrency, setBillingCurrency] = useState('');
  const [timezone, setTimezone] = useState('');
  const [embeddedURL, setEmbeddedURL] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [embeddedOpen, setEmbeddedOpen] = useState(false);
  const [emailInUse, setEmailInUse] = useState(false);
  const [Error, setError] = useState(false);
  const toastId = React.useRef(null);
  // const [ErrorMessage, setErrorMessage] = useState('');

  const EnableButton = fullName && phone && email && companyName && companySize && companyIndustry;

  // const [proceedNext, setProceedNext] = useState(false);

  const IndustryData = [
    { id: '1', value: 'Ecommerce' },
    { id: '2', value: 'Education' },
    { id: '3', value: 'Automotive' },
    { id: '4', value: 'IT Services' },
    { id: '5', value: 'Real Estate' },
    { id: '6', value: 'SAAS/Apps' },
    { id: '7', value: 'Gaming' },
    { id: '8', value: 'Entertainment' },
    { id: '9', value: 'Finance and Banking' },
    { id: '10', value: 'Medical and Health' },
    { id: '11', value: 'Hotel and Lodging' },
    { id: '12', value: 'Beauty, Spa and Salon' },
    { id: '13', value: 'Clothing and Apparel' },
    { id: '14', value: 'Event Planning and Services' },
    { id: '15', value: 'Food and Grocery' },
    { id: '16', value: 'Professional Services' },
    { id: '17', value: 'Public Service' },
    { id: '18', value: 'Non-profit' },
    { id: '19', value: 'Shopping and Retail' },
    { id: '20', value: 'Travel and Transportation' },
    { id: '21', value: 'Restaurant' },
    { id: '22', value: 'Other' },
  ];

  useEffect(() => {
    if (localdata?._id) {
      setFullName(localdata?.name ? localdata?.name : localdata?.nickname);
      setPhone('91' + localdata?.mobile);
      setEmail(localdata?.email);
      setCompanyName(localdata?.company_name);
    }
  }, [localdata]);

  const getStatusAPI = async () => {
    try {
      const res = await getStatus(authtoken);
      if (res?.flag) {
        return res?.data?.result?.status;
      }
    } catch (err) {
      console.log('Error in get status API', err);
    }
  };

  const EmbeddedSignupAPIcall = () => {
    setIsLoading(true);
    // setProceedNext(true);
    eventsend("Application_form_Next_WhatsApp_setup_pop_up_Clicked");

    let data = {
      user_id: localdata?._id,
      display_name: fullName,
      email: email,
      company: companyName,
      contact: phone,
      timezone: timezone,
      companySize: companySize,
      tempCode: tempFBToken,
    };

    let token = authtoken;
    EmbeddedSignupApi(data, token)
      .then(async (res) => {
        if (res?.flag) {
          try {
            setIsLoading(false);
            const status = await getStatusAPI();
            toast.success(`Project created successfully. Status: ${status}`);
          } catch (err) {
            setIsLoading(false);
            toast.error('Project created but failed to fetch status');
          }
        } else {
          setError(true);
          console.log('Error EmbeddedSignup response ===> ' + res);
          // setProceedNext(false);
          setTimeout(() => {
            setError(false);
          }, 5000);
          const message = res?.message;
          if (message?.toLowerCase().includes("email already")) {
            setEmailInUse(true);
          } else {
            toast.error(res?.message, { position: 'top-center', toastId: 1 });
          }
        }
      })
      .catch((e) => {
        setIsLoading(false);
        if (e?.message?.toLowerCase().includes("email already")) {
          setEmailInUse(true);
        } else {
          toast.error(e?.message, { position: 'top-center', toastId: 1 });
        }
      });
  };

  // useEffect(() => {
  //   if (embeddedURL) {
  //     setIsLoading(false);
  //   }
  // }, [embeddedURL]);

  const onchangePhone = (e, d, rv, fv) => {
    // console.log('no  ', e);
    setPhone(e);
  };

  return (
    <>
      <ToastContainer></ToastContainer>
      <div className="DarkBg" onClick={() => { }}>
        <div className="Center">
          <div
            className="d-flex flex-column justify-content-start align-items-center"
            style={{
              width: 500,
              maxHeight: '70vh',
              backgroundColor: colors.white01,
              borderRadius: 16,
              zIndex: 1,
              border: `1px solid ${colors.borderwhite}`,
              paddingBlock: 20,
              paddingInline: 30,
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {isLoading ? (
                  <>
                    <div
                      className="d-flex flex-column justify-content-center align-items-center w-100"
                      style={{ height: 400 }}
                    >
                      <SpinnerLoader />
                    </div>
                  </>
                ) : embeddedOpen ? (
              <>
                <div
                  className="d-flex flex-row justify-content-end align-items-center w-100"
                  style={{ marginBlockEnd: 16 }}
                >
                  <img
                    src={images.CMClose}
                    style={{ height: 24, width: 24, objectFit: 'contain', cursor: 'pointer' }}
                    onClick={() => {
                      checkWAStatusAction();
                      setIsEmbeddedModalOpen(false);
                    }}
                  />
                </div>
                <embed
                  src={openEmbeddedAction ? localdata?.embeddedSignupUrl : embeddedURL}
                  width="100%"
                  height={700}
                />
              </>
            ) : /* proceedNext ? (
              <>
                {isLoading ? (
                  <>
                    <div
                      className="d-flex flex-column justify-content-center align-items-center w-100"
                      style={{ height: 400 }}
                    >
                      <SpinnerLoader />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="d-flex flex-row justify-content-between align-items-center w-100">
                      <h2
                        className=""
                        style={{
                          margin: 0,
                          padding: 0,
                          fontSize: 20,
                          fontWeight: 700,
                          color: colors.black,
                        }}
                      >
                        {'Apply for WhatsApp business API'}
                      </h2>
                      <img
                        src={images.CMClose}
                        style={{ height: 24, width: 24, objectFit: 'contain', cursor: 'pointer' }}
                        onClick={() => {
                          setIsEmbeddedModalOpen(false);
                        }}
                      />
                    </div>
                    <p
                      style={{
                        padding: 0,
                        margin: 0,
                        marginBlockStart: 24,
                        marginBlockEnd: 16,
                        fontSize: 16,
                        fontWeight: 400,
                        color: colors.black,
                        width: '100%',
                      }}
                    >
                      {'Click on Continue With Facebook to apply for WhatsApp Business API'}
                    </p>
                    <h4
                      style={{
                        padding: 0,
                        margin: 0,
                        marginBlock: 16,
                        fontSize: 16,
                        fontWeight: 600,
                        color: colors.black,
                        width: '100%',
                      }}
                    >
                      {'Requirements to apply for WhatsApp Business API'}
                    </h4>
                    <ul style={{ marginBlock: 16, width: '100%', paddingInlineStart: 18 }}>
                      <li
                        style={{
                          padding: 0,
                          margin: 0,
                          fontSize: 16,
                          fontWeight: 400,
                          color: colors.black,
                        }}
                      >
                        {`A Phone number that doesn't have WhatsApp App or WhatsApp Business App installed (Please DELETE if installed or you can buy a new number )`}
                      </li>
                      <li
                        style={{
                          padding: 0,
                          margin: 0,
                          fontSize: 16,
                          fontWeight: 400,
                          color: colors.black,
                        }}
                      >
                        {`A Registered Business & Working Website.`}
                      </li>
                    </ul>
                    <CheerioButton
                      borderStyle={{
                        marginBlockStart: 20,
                        borderColor: colors.linkblue,
                        paddingBlock: 6,
                        paddingInline: 40,
                        marginInline: 'auto',
                      }}
                      textStyle={{ fontSize: 14, fontWeight: 600, color: colors.white }}
                      btnText={'Continue with Facebook'}
                      backColor={colors.linkblue}
                      icon={images.FacebookWhite}
                      // disabled={WARefreshToken ? false : true}
                      onclick={() => {
                        console.log('continue with facebook clicked . . .');
                        setEmbeddedOpen(true);
                        // openEmbeddedAction(embeddedURL);
                      }}
                    />
                  </>
                )}
              </>
            )  : */ (
              <>
                <div className="d-flex flex-row justify-content-between align-items-center w-100">
                  <h2
                    className=""
                    style={{
                      margin: 0,
                      padding: 0,
                      fontSize: 20,
                      fontWeight: 700,
                      color: colors.black,
                    }}
                  >
                    {'Create your business on Cheerio'}
                  </h2>
                  <img
                    src={images.CMClose}
                    style={{ height: 24, width: 24, objectFit: 'contain', cursor: 'pointer' }}
                    onClick={() => {
                      setIsEmbeddedModalOpen(false);
                    }}
                  />
                </div>
                <div
                  className="verythinscrollnew d-flex flex-column justify-content-start align-items-center w-100"
                  style={{ overflowY: 'scroll' }}
                >
                  <Form className="w-100 px-2 py-4">
                    <InputGroup className="my-2">
                      <Form.Label style={{ fontSize: 14, fontWeight: 700, color: colors.black }}>
                        {'Full Name'}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter here"
                        className="w-100 btncustom"
                        style={{ borderRadius: 8 }}
                        value={fullName}
                        onChange={(e) => {
                          setFullName(e.target.value);
                        }}
                      />
                    </InputGroup>
                    <InputGroup className="my-2">
                      <Form.Label style={{ fontSize: 14, fontWeight: 700, color: colors.black }}>
                        {'Phone'}
                      </Form.Label>
                      <PhoneInput
                        containerClass="my-2"
                        inputStyle={{ width: '100%' }}
                        country={'in'}
                        value={phone}
                        onChange={onchangePhone}
                      />
                      {/* <Form.Control
                        type="text"
                        placeholder="Enter here"
                        className="w-100 btncustom"
                        style={{ borderRadius: 8 }}
                        value={phone}
                        onChange={(e) => {
                          setPhone(e.target.value);
                        }}
                      /> */}
                    </InputGroup>
                    <InputGroup className="my-2">
                      <Form.Label style={{ fontSize: 14, fontWeight: 700, color: colors.black }}>
                        {'Email'}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter here"
                        className="w-100 btncustom"
                        style={{ borderRadius: 8, borderColor: emailInUse ? "red" : "black" }}
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                          setEmailInUse(false);
                        }}
                      />
                      {emailInUse && (
                        <span style={{ color: "red", fontSize: 10 }}>
                          Email Already In Use
                        </span>
                      )}
                    </InputGroup>
                    <InputGroup className="my-2">
                      <Form.Label style={{ fontSize: 14, fontWeight: 700, color: colors.black }}>
                        {'Company Name'}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter here"
                        className="w-100 btncustom"
                        style={{ borderRadius: 8 }}
                        value={companyName}
                        onChange={(e) => {
                          setCompanyName(e.target.value);
                        }}
                      />
                    </InputGroup>
                    <InputGroup className="my-2">
                      <Form.Label style={{ fontSize: 14, fontWeight: 700, color: colors.black }}>
                        {'Company Size'}
                      </Form.Label>
                      {/* <Form.Control type='text' placeholder="Enter here" className='w-100 btncustom'
                    style={{borderRadius:8}}
                    value={companySize}
                    onChange={(e)=>{setCompanySize(e.target.value)}}
                  /> */}
                      <Form.Select
                        className="w-100 btncustom"
                        style={{ borderRadius: 8 }}
                        value={companySize}
                        onChange={(e) => {
                          setCompanySize(e.target.value);
                        }}
                      >
                        <option>Size</option>
                        <option value="1">{'1-10 Employees'}</option>
                        <option value="2">{'10-20 Employees'}</option>
                        <option value="3">{'20-50 Employees'}</option>
                        <option value="4">{'50-200 Employees'}</option>
                        <option value="5">{'200+ Employees'}</option>
                      </Form.Select>
                    </InputGroup>
                    <InputGroup className="my-2">
                      <Form.Label style={{ fontSize: 14, fontWeight: 700, color: colors.black }}>
                        {'Company Industry'}
                      </Form.Label>
                      {/* <Form.Control type='text' placeholder="Enter here" className='w-100 btncustom'
                    style={{borderRadius:8}}
                    value={companyIndustry}
                    onChange={(e)=>{setCompanyIndustry(e.target.value)}}
                  /> */}
                      <Form.Select
                        className="w-100 btncustom"
                        style={{ borderRadius: 8 }}
                        value={companyIndustry}
                        onChange={(e) => {
                          setCompanyIndustry(e.target.value);
                        }}
                      >
                        <option>Select Industry</option>
                        {IndustryData.map((item) => {
                          return (
                            <option key={item?.id} value={item?.value}>
                              {item?.value}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </InputGroup>
                    <InputGroup className="my-2">
                      <Form.Label style={{ fontSize: 14, fontWeight: 700, color: colors.black }}>
                        {'Preferred Billing Currency'}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter here"
                        className="w-100 btncustom"
                        style={{ borderRadius: 8 }}
                        value={billingCurrency}
                        disabled={billingCurrency ? true : false}
                        onChange={(e) => {
                          setBillingCurrency(e.target.value);
                        }}
                      />
                    </InputGroup>
                    <InputGroup className="my-2">
                      <Form.Label style={{ fontSize: 14, fontWeight: 700, color: colors.black }}>
                        {'Time Zone'}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter here"
                        className="w-100 btncustom"
                        style={{ borderRadius: 8 }}
                        value={timezone}
                        disabled={timezone.length > 0 ? true : false}
                        onChange={(e) => {
                          setTimezone(e.target.value);
                        }}
                      />
                    </InputGroup>
                    <CheerioButton
                      borderStyle={{
                        marginBlockStart: 40,
                        width: '60%',
                        borderColor: colors.black,
                        paddingBlock: 10,
                        marginInline: 'auto',
                      }}
                      textStyle={{ fontSize: 14, fontWeight: 600, color: colors.white }}
                      btnText={'Next'}
                      backColor={colors.black}
                      disabled={EnableButton ? false : true}
                      onclick={() => {
                        console.log('next clicked . . .');
                        EmbeddedSignupAPIcall();
                        // setProceedNext(true);
                      }}
                    />
                  </Form>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default EmbeddedModal;
