import React, { useCallback, useEffect, useState } from 'react'
import colors from '../../../Utils/colors';
import { Handle, Position } from 'reactflow';
import CustomHandle from './CustomHandle';
import images from '../../../Utils/images';
import { eventsend } from '../../../Config/analyticsFunctions';

const handleStyle = { backgroundColor: colors.success03, height: 15, width: 15, borderRadius: "100%", top: '60%' };

const IncomingWANode = ({ data, isConnectable }) => {

    const onChange = useCallback((evt) => {
        console.log(evt.target.value)
    }, []);
    const [optionsArr, setOptionsArr] = useState([])

    useEffect(() => {
        eventsend("Incoming_whatsapp_WF_clicked");
    }, [])

    useEffect(() => {
        if (data?.options?.length > 0) {
            setOptionsArr(data?.options)
        } else {
            setOptionsArr([]);
        }
    }, [data?.options])

    return (
        <>
            <div className='d-flex flex-column justify-content-between align-items-center' style={{ border: `1px solid ${colors.borderwhite}`, borderRadius: 16, backgroundColor: colors.white01, width: 250 }}>
                {/* <Handle type='target' position={Position.Left} isConnectable={isConnectable} /> */}
                <div className='w-100 d-flex flex-row justify-content-between align-items-center' style={{ paddingInline: 16, paddingBlock: 8, backgroundColor: '#04B100', borderTopLeftRadius: 16, borderTopRightRadius: 16 }}>
                    <p style={{ padding: 0, margin: 0, fontSize: 16, fontWeight: 700, color: colors.white }}>
                        {'Event'}
                    </p>
                    <img src={images.Edit} style={{ height: 20, width: 20, objectFit: 'contain', cursor: 'pointer' }}></img>
                </div>
                <div className='w-100 d-flex flex-column justify-content-start align-items-center' style={{ paddingInline: 16, paddingBlockStart: 8, paddingBlockEnd: 16 }}>
                    <p style={{ padding: 0, margin: 0, fontSize: 12, fontWeight: 400, color: colors.black }}>
                        {'On Incoming WhatsApp'}
                    </p>
                </div>
                {!data?.options &&
                    <CustomHandle type='source' position={Position.Right} id='Event_UserInit' style={handleStyle} isConnectable={1} />
                }
                {optionsArr && optionsArr.map((item, index) => {
                    return (
                        <>
                            <div key={`key=${index + 1}`} className='d-flex flex-row justify-content-start align-items-center' style={{ borderRadius: 8, backgroundColor: colors.white03, marginBlock: 4, paddingBlock: 6, paddingInline: 16, width: '90%' }}>
                                <p style={{ padding: 0, margin: 0, fontSize: 12, fontWeight: 600, color: colors.black, textOverflow: 'ellipsis', wordBreak: 'break-all', textWrap: 'nowrap', whiteSpace: 'pre-wrap' }}>
                                    {`${item?.keywords === 'Default' ? 'Default' : item?.keywords.join(' , ')}`}
                                </p>
                                <CustomHandle
                                    type="source"
                                    // position={Position.Right}
                                    position={Position.Right}
                                    id={`child_node_${index}`}
                                    style={{ ...handleStyle, top: 'auto', bottom: `calc( ${index}*16 )` }}
                                    isConnectable={1}
                                />
                            </div>

                        </>
                    )
                })}
            </div>
        </>
    )
}

export default IncomingWANode