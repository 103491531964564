import React, { useEffect, useState } from 'react';
import colors from '../../../Utils/colors';
import CheerioButton from '../../../Components/CheerioButton';
import images from '../../../Utils/images';
import { useDispatch, useSelector } from 'react-redux';
import {
  GetTemplatesV2API,
  getUserApi,
  saveReinitiateTemplate,
  sendWhatsAppTemplate,
} from '../../../Services';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import SendTemplateModal from './SendTemplateModal';
import { eventsend } from '../../../Config/analyticsFunctions';
const MobileWidth = window.innerWidth < 900 ? true : false;

const SaveTemplateMenu = ({ templateData, setSelectTemplate, templateName }) => {
  const [data, setData] = useState(templateName);
  const authtoken = useSelector((state) => state.main.auth_token);
  const navigate = useNavigate();
  const saveTemplateApiCall = () => {
    const newArr = templateData.filter((template) => template.name === data);

    const apiData = {
      template_name: newArr[0].name,
      language: newArr[0].language,
      template_body: newArr[0].components[0].text,
    };
    saveReinitiateTemplate(apiData, authtoken).then((res) => {
      if (res?.flag) {
        setSelectTemplate(false);
      } else {
        toast.error(res, { position: 'top-center' });
      }
    });
  };

  return (
    <div style={{ backgroundColor: 'white', padding: '10px' }}>
      <div className="d-flex justify-content-between">
        <p style={{ fontWeight: '600', fontSize: '13px' }}>
          Note: It supports text only template, incase you don’t find any template here or want to
          create a new one.
        </p>
        <CheerioButton
          btnText={'Create Template'}
          textStyle={{ color: colors.black, fontSize: '14px' }}
          backColor={'white'}
          borderStyle={{
            width: '11vw',
            border: '1px solid black',
            borderRadius: '8px',
            marginRight: '20px',
          }}
          onclick={() => {
            eventsend("WhatsApp_template_ Create_template_list_Template_library_clicked");
            navigate('/creator/templates/create');
          }}
        />
      </div>
      <p style={{ fontWeight: '700', fontSize: '14px', marginBottom: '-1px' }}>
        Set a template to reinitate conversation
      </p>
      <div style={{ width: '70%', marginBottom: '2px' }}>
        <select
          onChange={(e) => {
            setData(e.target.value);
          }}
          class="form-select"
          aria-label="Default select example"
          style={{ boxShadow: 'none' }}
        >
          <option selected={templateName ? false : true} hidden>
            Select Template
          </option>

          {templateData.length > 0 &&
            templateData.map((template) => {
              return <option selected={templateName ? true : false}>{template.name}</option>;
            })}
        </select>
      </div>
      <div className="d-flex justify-content-between">
        <p style={{ fontSize: '12px', fontWeight: '600' }}>
          You can set any other template whenever you want.
        </p>
        <div style={{ width: '11vw', marginRight: '20px', marginBottom: '10px' }}>
          <CheerioButton
            btnText={'Save'}
            textStyle={{ color: colors.white, fontSize: '14px' }}
            backColor={'#9357FF'}
            borderStyle={{ border: 'none', borderRadius: '8px' }}
            onclick={() => {
              saveTemplateApiCall();
            }}
          />
        </div>
      </div>
    </div>
  );
};

export const ReInitiate = ({ chatData, attributesList }) => {
  const [selectTemplate, setSelectTemplate] = useState(false);
  const authtoken = useSelector((state) => state.main.auth_token);
  const dispatch = useDispatch();
  const localdata = useSelector((state) => state.main.localdata);

  const [templateData, setTemplateData] = useState([]);
  const [showSendTemplateModal, setShowSendTemplateModal] = useState(false);

  const templateExist = localdata?.reinitiateTemplateData;
  const agentdetails = useSelector((state) => state.main.agentdata);

  useEffect(() => {
    // if (selectTemplate) {
    //   GetTemplatesV2APIcall();
    // }
    getuserapicall();
  }, [showSendTemplateModal]);

  const getuserapicall = () => {
    let token = authtoken;
    getUserApi(token).then((res) => {
      if (res?.flag) {
        dispatch({ type: 'addLocaldata', payload: res?.data });
      } else {
        console.log('error get user api ===> ', res);
      }
    });
  };

  // const sendTemplateApiCall = () => {
  //   const data = {
  //     to: chatData?.user_details?.phone,
  //     templateName: localdata?.reinitiateTemplate?.template_name,
  //     languageCode: localdata?.reinitiateTemplate?.language,
  //     body: localdata?.reinitiateTemplate?.template_body,
  //   };
  //   sendWhatsAppTemplate(authtoken, data).then((res) => {
  //     if (res?.flag) {
  //       // console.log('return policy',res);
  //       toast.success(res?.message, { position: 'top-center' });
  //     } else {
  //       toast.error('error sending whatsApp template', res);
  //     }
  //   });
  // };
  const sendTemplateApiCall = () => {
    toast.loading('Sending Template', { toastId: 2 });
    const data = {
      to: attributesList?.phone,
      component: templateExist,
      repliedBy: agentdetails ? agentdetails?.name : localdata?.name,
    };
    sendWhatsAppTemplate(authtoken, data).then((res) => {
      if (res?.flag) {
        // console.log('return policy',res);
        toast.dismiss(2);

        toast.success(res?.message, { position: 'top-center' });
      } else {
        toast.dismiss(2);
        toast.error('error sending whatsApp template', res);
      }
    });
  };

  const GetTemplatesV2APIcall = () => {
    let token = authtoken;
    const data = {
      limit: '500',
    };
    GetTemplatesV2API(data, token).then((res) => {
      if (res?.data) {
        let newArr = res?.data;
        newArr = newArr.filter((data) => {
          if (
            data.components.length === 1 &&
            data.components[0].type === 'BODY' &&
            data.status === 'APPROVED' &&
            data.components[0].example === undefined
          ) {
            return true;
          }
          return false;
        });
        setTemplateData(newArr);
      } else {
        console.log('errror: ' + res);
      }
    });
  };

  // return templateExist ? (
  //   selectTemplate ? (
  //     <SaveTemplateMenu
  //       templateData={templateData}
  //       setSelectTemplate={setSelectTemplate}
  //       templateName={localdata?.reinitiateTemplate?.template_name}
  //     ></SaveTemplateMenu>
  //   ) : (
  return (
    <div
      className="d-flex justify-content-between"
      style={{
        backgroundColor: colors.white,
        alignItems: 'center',
        padding: '16px 10px',
        gap: '12px',
        flexDirection: MobileWidth ? 'column' : 'row',
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <p
          style={{
            fontSize: 14,
            color: colors.black,
          }}
        >
          User initiated conversations lasts for 24 hours only. You can reopen conversation using a
          template, but unless user replies to you, you will not be able reply to them without a
          template message.
        </p>
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          width: MobileWidth ? '100%' : 'auto',
          // border: '1px solid red'
        }}
      >
        <CheerioButton
          id="SendTemplateButton"
          btnText={'Send Template'}
          textStyle={{ color: colors.white, fontSize: '12px', textWrap: 'nowrap' }}
          backColor={colors.black}
          borderStyle={{ height: '32px', borderRadius: '8px', width: MobileWidth ? '100%' : 'auto' }}
          onclick={() => {
            if (templateExist) {
              sendTemplateApiCall();
            } else {
              setShowSendTemplateModal(true);
            }
          }}
        />
        {(
          <img
            src={images.EditSender}
            style={{ cursor: 'pointer', marginTop: '2px' }}
            onClick={() => setShowSendTemplateModal(true)}
            height="24px"
          />
        )}
      </div>
      {showSendTemplateModal && (
        <SendTemplateModal
          setShow={setShowSendTemplateModal}
          show={showSendTemplateModal}
          attributesList={attributesList}
          reinitiate={true}
        />
      )}
    </div>
  );
};
