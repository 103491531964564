import { Route, Routes, useParams } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { styledSx } from '../Tags/variableDependencies';
// import { ContactsDepsColsCustom } from '../ContactsAll/variableDependencies';
import { timeStampToDated } from '../Labels/variableDependencies';
import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { EditableCell } from '../ContactsAll/variableDependencies';
import { RenderEmailRow, RenderNameRow, RenderPhnoRow } from '../ContactsAll/variableDependencies';
import { fetchLabelContactsV2API, fetchTagsContactAPI, filterLabelContacts, AddEditcustomAttributes, updateContactLead, V2AgentGet, deleteLabelContactsAPI } from '../../../Services';
import EditSidebar from '../EditSidebar';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { getLocalDatetime, getLocaltime } from '../../../Utils/localdate'
import SpinnerLoader from '../../../Components/SpinnerLoader';
import { toast } from 'react-toastify';
import Checkbox from '@mui/material/Checkbox';
import colors from '../../../Utils/colors';
import { formatDateForInput, formatTimestampForInput, formatTimeForDisplay, formatDateForDisplay, formatTimestampForDisplay, formatTimeForInput } from '../../../Utils/formatConvertor';
import CheerioDropDown from '../../../Components/CheerioDropDown';
import DateTimeInput from '../../../Components/DateTimeInput';
import { ContactSelectionHeader } from '../ContactsAll';
import { Tooltip, Typography } from '@mui/material';
import { DeleteBulkContact } from '../ContactsAll';

const EditableTable = ({ header, search, userAttributes, excepted, labelFilter, setLabelFilter, setAllData, allData, andCondition, setIsLoading, isLoading }) => {
  const FilterValues = ['name', 'phone', 'email'];

  const [userAttributesColumn, setUserAttributesColumn] = useState([]);
  const { reqId, type } = useParams();
  const [selectedRows, setSelectedRows] = useState([]);
  const [totalSelectedRows, setTotalSelectedRows] = useState(0);
  const [selectedAll, setSelectedAll] = useState(false);
  const [excludedRows, setExcludedRows] = useState([]);
  const [partialSelectedRows, setPartialSelectedRows] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  });
  // const [isLoading, setIsLoading] = useState(false);
  const authtoken = useSelector((state) => state.main.auth_token);
  const [salesAgent, setSalesAgent] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState('');
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteContactId, setDeleteContactId] = useState(null);

  const addAttributesCheck = (attribute, value, contact_id) => {
    const attrType = userAttributes?.find((a) => a.attribute === attribute)?.type;
    if (attrType === 'date') {
      value = formatDateForInput(value);
    } else if (attrType === 'timestamp') {
      value = formatTimestampForInput(value);
    }
    let token = authtoken;
    let change = {};
    change[attribute] = value;
    let data = {
      contact_id: contact_id,
      newChange: change,
    };
    AddEditcustomAttributes(token, data).then((res) => {
      if (res?.status === 200) {
        fetchData();
        toast.success('Attribute successfully updated!', {
          autoClose: 500,
          position: 'top-right',
        });
      } else {
        toast.error('Attribute could not be updated, please try again later', {
          autoClose: 1000,
          position: 'top-right',
        });
      }
    });
  };

  const GetAgentsV2APICall = () => {
    let token = authtoken;
    V2AgentGet(token).then((res) => {
      if (res) {
        const supportAgents = Object.values(res?.data)?.filter(item => item?.role === 'sales')
        setSalesAgent(supportAgents);
      } else {
      }
    });
  };
  useEffect(() => {
    GetAgentsV2APICall();
  }, []);

  const handleDelete = async () => {
    console.log("gg", deleteContactId)
    if (deleteContactId) {
      let deleteData = {
        _id: deleteContactId,
      };
      const res = await deleteLabelContactsAPI(deleteData, authtoken);
      if (res?.flag) {
        fetchData();
        toast.success(res?.message);
        setDeleteModalOpen(false);
        setDeleteContactId(null); // Clear the ID after deletion
      } else {
        setDeleteModalOpen(false);
        setDeleteContactId(null);
      }
    }
  };

  const handleSelectRow = (id) => {
    if (selectedAll || partialSelectedRows) {
      setSelectedAll(false);
      setPartialSelectedRows(true);
      setExcludedRows((prevExcluded) =>
        prevExcluded.includes(id)
          ? prevExcluded.filter((rowId) => rowId !== id)
          : [...prevExcluded, id]
      );
      setTotalSelectedRows(totalSelectedRows - 1);
    } else {
      setSelectedRows((prevSelected) =>
        prevSelected.includes(id)
          ? prevSelected.filter((rowId) => rowId !== id)
          : [...prevSelected, id]
      );
    }
  };

  const handleSelectAll = () => {
    setSelectedAll(!selectedAll);
    setPartialSelectedRows(false);
    setExcludedRows([]);
    setSelectedRows([]);
  };

  useEffect(() => {
    if (selectedAll) {
      setTotalSelectedRows(allData?.data?.totalDocs);
    }
  }, [selectedAll]);

  useEffect(() => {
    // console.log("user attri ---> ",userAttributes)
    if (userAttributes?.length > 0) {
      setUserAttributesColumn(() => {
        let temp = [];
        userAttributes
          .filter((item) => item?.attribute && !FilterValues.includes(item?.attribute))
          .map((item) => {
            temp.push({
              field: item?.attribute + `AAA` + uuidv4(),
              headerName: item?.attribute,
              width: 200,
              align: 'center',
              headerAlign: 'center',
              attrtype: item?.type,
              options: item?.options ? item?.options : [],
              renderCell: (params) => {
                if (item?.type === 'dropdown') {
                  return (
                    <CheerioDropDown
                      name={item?.attribute}
                      id={params.row.id}
                      options={item?.options || []}
                      initialValue={params.value}
                      // isSelectable={false}
                      handleUpdate={addAttributesCheck}
                      isIdRequired={true}
                    />
                  );
                }
                return (
                  <Tooltip title={params?.value} arrow>
                    <Typography
                      variant="body2"
                      noWrap
                      sx={{
                        maxWidth: '100%',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {params?.value}
                    </Typography>
                  </Tooltip>
                );
              }
            });
          });
        return temp;
      });
    }
  }, [userAttributes]);

  function mergeArrays(array1, array2) {
    let mergedArray = array1.slice();
    let insertIndex = mergedArray.length;
    mergedArray.splice(insertIndex, 0, ...array2);
    return mergedArray;
  }

  const updateLeadAssign = async (mobile, salesAgent) => {
    console.log('data:', salesAgent, selectedAgent);
    if (!salesAgent) {
      toast.error('Please select a sales agent to assign lead')
      return;
    }
    const data = {
      mobile: mobile,
      salesAgent
    }
    try {
      const res = await updateContactLead(data, authtoken);
      if (res?.flag) {
        fetchData()
        toast.success(res?.message)
      }
      else {
        toast.error(res?.message)
      }
    } catch (error) {
      toast.error('Failed to update lead assignment, please try again later')
      console.log('error:', error);
    }
  }

  let pageData = {
    labelId: reqId,
    page: paginationModel.page + 1,
    limit: paginationModel.pageSize,
    search: search,
    filter: labelFilter,
    andCondition: andCondition,
  };
  const trigger = useSelector((state) => state.labelRedux.triggerState);
  let metaData;
  const fetchData = async () => {
    try {
      setIsLoading(true);
      const data = await filterLabelContacts(pageData, authtoken);
      if (data?.flag) {
        setAllData(data);
        setIsLoading(false);
        setSelectedAll(false);
        setPartialSelectedRows(false);
        setExcludedRows([]);
        setSelectedRows([]);
      }
    } catch (error) {
      setIsLoading(false);
      console.log('error:', error);
    }
  }
  useEffect(() => {
    fetchData();
  }, [search, paginationModel]);
  if (type === 'tags') {
    metaData = useQuery(['contactLabelDiff', trigger, paginationModel, search], () => {
      return fetchTagsContactAPI(pageData, authtoken);
    });
  }
  // const { data, isLoading, isError } = metaData;

  if (isLoading) {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center w-100"
        style={{ flex: 1 }} >
        <SpinnerLoader></SpinnerLoader>
      </div>
    );
  }
  let row_data = [];
  allData?.data?.docs.forEach((vls) => {
    let AttrRowObject =
      userAttributesColumn?.length > 0 &&
      userAttributesColumn.reduce((obj, item) => {
        let varName = String(item?.field).split('AAA')[0];
        let attrType = item?.attrtype;
        let options = item?.options;
        // obj[item?.field] = vls?.customData?.[varName] ? vls?.customData?.[varName] : '--';

        const attributeValue = vls?.customData?.[varName] ? vls?.customData?.[varName] : '--';

        if (attrType === 'date') {
          obj[item?.field] = attributeValue !== '--' ? formatDateForDisplay(attributeValue) : '--';
        } else if (attrType === 'time') {
          obj[item?.field] = attributeValue !== '--' ? formatTimeForDisplay(attributeValue) : '--';
        } else if (attrType === 'timestamp') {
          obj[item?.field] = attributeValue !== '--' ? formatTimestampForDisplay(attributeValue) : '--';
        } else {
          obj[item?.field] = attributeValue;
        }
        return obj;
      }, {});

    row_data.push(
      Object.keys(AttrRowObject).length > 0
        ? Object.assign(
          {},
          {
            id: vls._id,
            name: vls.name,
            addedOn: getLocalDatetime(vls.createdAt),
            updatedOn: getLocalDatetime(vls.updatedAt),
            phno: vls.mobile,
            email: vls.email,
            actions: vls,
            version: vls.version,
            sales_agent: vls.sales_agent,
            visible: !excepted,
          },
          AttrRowObject
        )
        : {
          id: vls._id,
          name: vls.name,
          addedOn: getLocaltime(vls.createdAt),
          updatedOn: getLocaltime(vls.updatedAt),
          phno: vls.mobile,
          email: vls.email,
          actions: vls,
          version: vls.version,
          sales_agent: vls.sales_agent,
          visible: !excepted,
        }
    );
  });

  const ContactsDepsColsCustom = [
    {
      field: 'select',
      headerName: (
        <Checkbox
          color="primary"
          checked={selectedAll}
          onChange={(e) =>
            handleSelectAll()
          }
          sx={{
            color: "gray",
            '&.Mui-checked': {
              color: colors.darkPurple,
            },
          }}
        />
      ),
      width: 50,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      description: "Select all rows",
      renderCell: (params) => {
        return (
          <Checkbox
            color="primary"
            checked={selectedRows.includes(params.row.id) || selectedAll || (partialSelectedRows && !excludedRows.includes(params.row.id)) ? true : false}
            onChange={() => handleSelectRow(params.row.id)}
            sx={{
              color: 'gray',
              '&.Mui-checked': {
                color: colors.darkPurple,
              },
            }}
          />
        );
      }
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 100,
      // flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return <EditableCell value={params.value} cnc_id={params.row.id} params={params} fetchData={fetchData} setDeleteModalOpen={setDeleteModalOpen} setDeleteContactId={setDeleteContactId} />;
      },
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 250,
      // flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return <RenderNameRow params={params} />;
      },
    },
    {
      field: 'phno',
      headerName: 'Phone Number',
      width: 200,
      // flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return <RenderPhnoRow params={params} />;
      },
    },
    {
      field: 'email',
      headerName: 'Email ID',
      width: 350,
      // flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return <RenderEmailRow params={params} />;
      },
    },
    {
      field: 'sales_agent',
      headerName: 'Lead Assignment',
      width: 200,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        const options = salesAgent?.map((item) => ({
          optionName: item.name,
          id: item._id,
          color: '#FEF3B1'
        }));
        return (
          <div>
            {<CheerioDropDown
              name={params.row.phno}
              id={params.row.sales_agent.id}
              options={options ?? []}
              initialValue={params.row.sales_agent.name}
              setValue={setSelectedAgent}
              isAgent={true}
              handleUpdate={updateLeadAssign}
            />}
          </div>
        )
      },
    },
    {
      field: 'addedOn',
      headerName: 'Added On',
      width: 218,
      // flex: 1,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'updatedOn',
      headerName: 'Updated On',
      width: 218,
      // flex: 1,
      align: 'center',
      headerAlign: 'center',
    },
  ];
  return (
    <>
      {(selectedRows?.length > 0
        || (partialSelectedRows && excludedRows?.length > 0)
        || (selectedAll && totalSelectedRows > 0

        )) &&
        <div>
          <ContactSelectionHeader
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            allData={allData}
            userAttributesColumn={userAttributesColumn}
            totalSelectedRows={totalSelectedRows}
            selectedAll={selectedAll}
            setSelectedAll={setSelectedAll}
            partialSelectedRows={partialSelectedRows}
            setPartialSelectedRows={setPartialSelectedRows}
            filter={labelFilter}
            excludedContacts={excludedRows}
            setExcludedRows={setExcludedRows}
            search={search}
            andCondition={andCondition}
            fetchData={fetchData}
            labelId={reqId}
            salesAgent={salesAgent}
          />
        </div>}
      <DataGrid
        columns={
          userAttributesColumn?.length > 0
            ? mergeArrays(ContactsDepsColsCustom, userAttributesColumn)
            : ContactsDepsColsCustom
        }
        rows={row_data}
        sx={styledSx}
        paginationModel={paginationModel}
        pageSizeOptions={[25, 50, 100]}
        paginationMode="server"
        onPaginationModelChange={setPaginationModel}
        rowCount={allData?.data?.totalDocs}
      />
      {deleteModalOpen && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
          }}
        >
          <div
            style={{
              backgroundColor: 'white',
              padding: '20px',
              borderRadius: '8px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            }}
          >
            <DeleteBulkContact
              setDeleteModal={setDeleteModalOpen}
              totalContacts={1}
              handleBulkDelete={handleDelete}
            />
          </div>
        </div>
      )}
      <Routes>
        <Route path={'/edit'} element={<EditSidebar />} />
      </Routes>
    </>
  );
};
export default EditableTable;
