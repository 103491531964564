import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import images from '../../../Utils/images';
import CircularProgress from '@mui/material/CircularProgress';
import CheerioButton from '../../../Components/CheerioButton';
import colors from '../../../Utils/colors'
import { markAllAsReadNotifications } from '../../../Services';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import WalletAnnBanner from './WalletAnnBanner'
const logoType = [
    {
        type: "alert",
        icon: images.NOTIFICATIONALERT
    },
    {
        type: "whatsapp",
        icon: images.whatsappLogo
    },
    {
        type: "campaigns",
        icon: images.CampaignWhite
    }
]

const NotificationModal = ({
    openNotificationModal,
    setOpenNotificationModal,
    anchorEl,
    allNotifications,
    loading,
    selectedTab,
    setSelectedTab,
    unreadNotifications,
    fetchNotifications
}) => {

    const authtoken = useSelector((state) => state.main.auth_token);
    const [isBannermodelopen, setisBannermodelopen] = useState(false)
    const navigate = useNavigate();
    const formatDate = (dateString) => {
        const options = { day: '2-digit', month: 'long', year: 'numeric' };
        const date = new Date(dateString);
        return date.toLocaleDateString('en-GB', options);
    };

    const location = useLocation();

    const handleNotificationClick = (notification) => {
        navigate(notification?.onclick);
        setOpenNotificationModal(false);
    };
    useEffect(() => {

        const queryParams = new URLSearchParams(location.search);
        console.log("loaction", queryParams)
        const openWalletModal = queryParams.get('openWalletModal') === 'true';

        if (openWalletModal) {
            setisBannermodelopen(true);
            setOpenNotificationModal(false);
        }
    }, [location.search, setOpenNotificationModal]);
    const handleClose = () => {
        setOpenNotificationModal(false);
    }
    useEffect(() => {

        const hasSeenBanner = localStorage.getItem('hasSeenWalletAnnBanner');

        if (!hasSeenBanner) {

            setisBannermodelopen(true);
            localStorage.setItem('hasSeenWalletAnnBanner', 'true');
        }
    }, []);

    const handleMarkAsRead = () => {
        try {
            const token = authtoken;
            markAllAsReadNotifications(token).then((response) => {
                if (response?.flag) {
                    setSelectedTab("all")
                    fetchNotifications()
                }
            })
        } catch (error) {
            console.log("Error in handleMarkAsRead", error)
        }
    }
    return (
        <div>
            <Popover
                open={openNotificationModal}
                onClose={handleClose}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                sx={{
                    '& .MuiPopover-paper': {
                        width: '400px',
                        borderRadius: '16px',
                    },
                }}
            >
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}>
                    <p style={{ padding: 15, fontWeight: 700 }}>Notifications</p>
                    <img src={images.CMClose} alt="" height={20} onClick={handleClose} style={{ cursor: "pointer", paddingRight: 15 }} />
                </div>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingInline: '20px',
                    fontSize: '14px',
                    fontWeight: '600',
                }}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            gap: '10px',
                            // paddingLeft: '10px',
                            // paddingRight: '10px'
                        }}
                    >
                        <div
                            onClick={() => setSelectedTab("all")}
                            style={{
                                backgroundColor: selectedTab === "all" ? '#ECE1FF' : '#F5F5F5',
                                cursor: 'pointer',
                                paddingInline: '12px',
                                color: selectedTab === "all" ? '#8E53F7' : '#999999',
                                borderRadius: '7px',
                                paddingTop: '5px',
                                paddingBottom: '5px',

                            }}
                        >
                            All
                        </div>
                        <div
                            onClick={() => setSelectedTab("unread")}
                            style={{
                                backgroundColor: selectedTab === "unread" ? '#ECE1FF' : '#F5F5F5',
                                cursor: 'pointer',
                                paddingInline: '12px',
                                color: selectedTab === "unread" ? '#8E53F7' : '#999999',
                                borderRadius: '7px',
                                paddingTop: '5px',
                                paddingBottom: '5px',

                            }}
                        >Unread</div>

                    </div>
                    <div
                        style={{
                            color: '#8E53F7',
                            cursor: 'pointer'
                        }}
                        onClick={handleMarkAsRead}
                    >
                        Mark all as read
                    </div>
                </div>
                {selectedTab === "all" ? (
                    <div style={{ maxHeight: '300px', overflow: 'auto', paddingBottom: '20px' }}
                    //  onScroll={handleScroll}
                    >
                        {allNotifications.length > 0 ? (
                            allNotifications.map((notification, index) => {
                                let notificationLogo = logoType.find((type) => type.type === notification?.type)
                                return <div>
                                    <hr />
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        gap: 5,
                                        paddingInline: 22,
                                        marginTop: 5,
                                        paddingBottom: 5
                                    }}>
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            gap: 5
                                        }}>
                                            {/* <img src={notificationLogo?.icon} alt="" height={20} /> */}
                                            <p style={{
                                                color: notification?.type === 'alert' ? "black" : "black",
                                                fontWeight: 500
                                            }}>{notification?.title}</p>

                                        </div>
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            gap: 5
                                        }}>
                                            {!notification?.isRead && <div style={{
                                                backgroundColor: "#8E53F7",
                                                borderRadius: "50%",
                                                width: "10px",
                                                height: "10px"
                                            }}></div>}
                                            <p style={{
                                                fontSize: "12px"
                                            }}>{formatDate(notification?.createdAt)}</p>

                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            paddingInline: 20,
                                            color: "#262626",
                                            paddingBottom: 3,
                                        }}
                                    >
                                        {notification?.message}
                                        <div
                                            style={{
                                                marginLeft: '-8px',
                                                marginTop: '5px',
                                                marginBottom: '-2px'
                                            }}
                                        >
                                            {/* {notification?.onclick &&
                                                <button
                                                    style={{
                                                        marginLeft: '10px',
                                                        backgroundColor: 'transparent',
                                                        color: '#8E53F7',
                                                        borderRadius: '10px',
                                                        border: '1px solid var(--Purple-Purple-dark, #8E53F7)',
                                                        padding: '6px 14px',
                                                        cursor: 'pointer',
                                                        height: '35px',
                                                        width: '120px',
                                                        gap: '6px',
                                                        cursor: notification?.onclick ? 'pointer' : 'default'
                                                    }}
                                                    onClick={() => handleNotificationClick(notification)}

                                                >
                                                    View Details

                                                </button>
                                                
                                            } */}
                                        </div>
                                    </div>
                                </div>

                            })
                        ) : (
                            <Typography sx={{ p: 2 }}>No notifications.</Typography>
                        )}
                        {loading && (
                            <div style={{ textAlign: 'center', padding: '10px' }}>
                                <CircularProgress size={24} />
                            </div>
                        )}
                    </div>
                ) : (
                    <div style={{ maxHeight: '300px', overflow: 'auto', paddingBottom: '20px' }}
                    //  onScroll={handleScroll}
                    >
                        {unreadNotifications.length > 0 ? (
                            unreadNotifications.map((notification, index) => {
                                let notificationLogo = logoType.find((type) => type.type === notification?.type)
                                return <div>
                                    <hr />
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        gap: 5,
                                        paddingInline: 22,
                                        marginTop: 5,
                                        paddingBottom: 5
                                    }}>
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            gap: 5
                                        }}>
                                            <img src={notificationLogo?.icon} alt="" height={20} />
                                            <p style={{
                                                color: notification?.type === 'alert' ? "red" : "black",
                                                fontWeight: 500
                                            }}>{notification?.title}</p>

                                        </div>
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            gap: 5
                                        }}>
                                            {!notification?.isRead && <div style={{
                                                backgroundColor: "#8E53F7",
                                                borderRadius: "50%",
                                                width: "10px",
                                                height: "10px"
                                            }}></div>}
                                            <p style={{
                                                fontSize: "12px"
                                            }}>{formatDate(notification?.createdAt)}</p>

                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            paddingInline: 20,
                                            color: "#262626",
                                            paddingBottom: 3,
                                            cursor: notification?.onclick ? 'pointer' : 'default',
                                            // textDecoration: notification.onclick ? 'underline' : 'none',
                                        }}

                                    >
                                        {notification?.message}
                                        <div
                                            style={{
                                                marginLeft: '-8px',
                                                marginTop: '4px',
                                                marginBottom: '-2px'
                                            }}
                                        >
                                            {notification?.onclick &&
                                                <button
                                                    style={{
                                                        marginLeft: '10px',
                                                        backgroundColor: 'transparent',
                                                        color: '#8E53F7',
                                                        borderRadius: '10px',
                                                        border: '1px solid var(--Purple-Purple-dark, #8E53F7)',
                                                        padding: '6px 14px',
                                                        cursor: 'pointer',
                                                        height: '35px',
                                                        width: '120px',
                                                        gap: '6px',
                                                        cursor: notification?.onclick ? 'pointer' : 'default'
                                                    }}
                                                    onClick={() => handleNotificationClick(notification)}
                                                >
                                                    View Details

                                                </button>
                                            }
                                        </div>
                                    </div>
                                </div>

                            })
                        ) : (
                            <Typography sx={{ p: 2 }}>No notifications.</Typography>
                        )}
                        {loading && (
                            <div style={{ textAlign: 'center', padding: '10px' }}>
                                <CircularProgress size={24} />
                            </div>
                        )}
                    </div>
                )}
            </Popover>

            {/* {isBannermodelopen && <WalletAnnBanner
                setisBannermodelopen={setisBannermodelopen}
                isBannermodelopen={isBannermodelopen}
            />} */}
        </div>
    )
}

export default NotificationModal