import React from 'react'
import colors from '../../../Utils/colors';
import { Handle, Position } from 'reactflow';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import images from '../../../Utils/images';
import CustomHandle from './CustomHandle';

const handleStyle = { backgroundColor: colors.blue03, height: 15, width: 15, borderRadius: "100%" };

const TimeDelayNode = ({ data, isConnectable }) => {

    const dataExists = data?.hours || data?.minutes || data?.seconds
    const days = Math.floor(Number(data?.hours) / 24);

    return (
        <>
            <div className='d-flex flex-column justify-content-between align-items-center' style={{ border: `1px solid ${colors.borderwhite}`, borderRadius: 16, backgroundColor: colors.white01, width: 230 }}>
                <CustomHandle type='target' position={Position.Left} isConnectable={isConnectable} style={handleStyle} />
                <div className='w-100 d-flex flex-column justify-content-start align-items-center' style={{ paddingInline: 16, paddingBlock: 8, backgroundColor: colors.blue03, borderTopLeftRadius: 16, borderTopRightRadius: 16 }}>
                    <div style={{ height: 4, width: '20%', borderRadius: 2, backgroundColor: colors.white, marginBlockEnd: 4 }}></div>
                    <div className='w-100 d-flex flex-row justify-content-between align-items-center' style={{}}>
                        <p style={{ padding: 0, margin: 0, fontSize: 16, fontWeight: 700, color: colors.white }}>
                            {'Time Delay'}
                        </p>
                        <img src={images.Edit} style={{ height: 20, width: 20, objectFit: 'contain' }}></img>
                    </div>
                </div>
                {/* <div className='w-100 d-flex flex-row justify-content-between align-items-center' style={{paddingInline:16,paddingBlock:8,backgroundColor:colors.blue03,borderTopLeftRadius:16,borderTopRightRadius:16}}>
            <p style={{padding:0,margin:0,fontSize:16,fontWeight:700,color:colors.white}}>
                {'Time Delay'}
            </p>
            <img src={images.Edit} style={{height:20,width:20,objectFit:'contain'}}></img>
        </div> */}
                <div className='w-100 d-flex flex-column justify-content-start align-items-center' style={{ paddingInline: 16, paddingBlock: 16 }}>
                    {Number(data?.hours) > 23 &&
                        <p style={{ padding: 0, margin: 0, marginBlock: 4, fontSize: 12, fontWeight: 400, color: colors.black, width: '100%' }}>
                            {`If the delay is more than 24 hours you can only use template messages or email`}
                        </p>
                    }
                    <p style={{ padding: 0, margin: 0, marginBlock: 4, fontSize: 12, fontWeight: 400, color: colors.black, width: '100%' }}>
                        {dataExists && (days > 1) ? `${days} days ${data?.hours - days * 24} hours ${data?.minutes} minutes` :
                            dataExists && (days == 1) ? `${days} day ${data?.hours - days * 24} hours ${data?.minutes} minutes` :
                                dataExists ? `${data?.hours} hours ${data?.minutes} minutes` : 'Set timer'}
                    </p>
                </div>
                <CustomHandle type='source' position={Position.Right} isConnectable={1} style={handleStyle} />
            </div>
        </>
    )
}

export default TimeDelayNode