import React from 'react';
import colors from '../../../Utils/colors';
import CheerioButton from '../../../Components/CheerioButton';

const GeneralDeleteModal = ({ setDeleteModalOpen, deleteAction, deletionText, btnText }) => {
  return (
    <>
      <div
        className="ModalDarkBg"
        onClick={() => {
          setDeleteModalOpen(false);
        }}
      >
        <div className="Center">
          <div
            className="delete_modal"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="delete_modal__header">
              <h3>{'Delete Confirmation'}</h3>
            </div>
            <p>{deletionText}</p>
            <div className="delete_modal__button_row">
              <CheerioButton
                borderStyle={{ borderColor: colors.black, width: '45%' }}
                textStyle={{ color: colors.white03 }}
                btnText={btnText}
                backColor={colors.red49}
                onclick={() => {
                  deleteAction();
                }}
              />
              <CheerioButton
                borderStyle={{ borderColor: colors.black, width: '45%' }}
                textStyle={{ color: colors.black }}
                btnText={'Cancel'}
                backColor={colors.white01}
                onclick={() => {
                  setDeleteModalOpen(false);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GeneralDeleteModal;
