import React, { useEffect, useRef, useState } from 'react';
import colors from '../../../Utils/colors';
import images from '../../../Utils/images';
import CheerioButton from '../../../Components/CheerioButton';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import {
  contactlabellistAPI,
  createTemplateAPI,
  customHTMLsendEmailApi,
  getCampaignListAPI,
  getlabelbalanceAPI,
  makeAnnouncementAPI,
  makeScheduledAnnouncementAPI,
  getContacsTagsList,
  getContacsTagsBalance,
  V2SendAnnouncement,
  createWAScheduler,
  createEmailScheduler,
  V2EmailSendAnnouncement,
  fetchContactLabels,
  contactlabellistWithoutCountAPI,
  sendSmsV2Announcement,
  getAllSenderId,
  getClientAttributes,
  getAttributeBasedBalance,
} from '../../../Services';
import { SenderIdModal } from '../../CreatorNew/Components/SenderIdModal';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Preview from '../../Creator/Announcements/Preview';
import { getImagePath } from '../../../Utils';
import SpinnerLoader from '../../../Components/SpinnerLoader';
import './../styles.css';
import ProgressBar from 'react-bootstrap/esm/ProgressBar';
import Alert from 'react-bootstrap/Alert';
import { Parser } from 'html-to-react';
import Badge from 'react-bootstrap/Badge';
import { newLineRemover } from '..';
import AnnTemplate from './AnnTemplate';
import AnnDetails from './AnnDetails';
import AnnCompletion from './AnnCompletion';
import { timeStampToDated } from '../../ContactModule/Labels/variableDependencies';
import ICNewModal from '../../ImportContactsNew/Components/ICNewModal';
import { toast } from 'react-toastify';
import Header from './Header';
import AnnTemplates from './AnnTemplates';
import AnnTemplateV1 from './AnnTemplateV1';
import HEImages from '../../CreatorNew/Components/HEImages';
import { eventsend } from '../../../Config/analyticsFunctions';
import SmsTemplates from './SmsTemplates';
import SmsDetails from './SmsDetails';
import UploadValidator from '../../../Components/UploadValidator';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Autocomplete, Box, styled, TextField, Tooltip, tooltipClasses } from '@mui/material';
import { OverlayTrigger } from 'react-bootstrap';

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 320,
    backgroundColor: colors.darkPurple,
    borderRadius: 8,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: colors.darkPurple,
  },
});

const AnnNewModal = ({
  setOpenANModal,
  setName,
  reTargetingLabelName,
  EmailBuilder = false,
  EBTemplateName,
  EBHTML,
  fromhomepage = false,
  UploadFromHome = false,
  setOpenCampaignModal,
  opencampaignmodal = false,
  fromCampaignpage = false,
}) => {
  const navigate = useNavigate();

  const mobileWidth = window.innerWidth < 900 ? true : false;

  const _id = useSelector((state) => state.main._id);
  const authtoken = useSelector((state) => state.main.auth_token);
  const localdata = useSelector((state) => state.main.localdata);
  const agentdata = useSelector((state) => state.main.agentdata);

  const [currentScreen, setCurrentScreen] = useState('ann_start');
  const [createSender, setCreateSender] = useState(false);
  const [isLoadingContacts, setIsLoadingContacts] = useState(false);
  const [saveforlater, setSaveforlater] = useState(false);
  const [campaignName, setCampaignName] = useState('');
  const [shareTo, setShareTo] = useState('Label');
  const [labelList, setLabelList] = useState([]);
  const [labelName, setLabelName] = useState('');
  const [balance, setBalance] = useState();
  const [tagsList, setTagsList] = useState([]);
  const [selectedTagsList, setSelectedTagsList] = useState([]);

  const [Eselected, setEselected] = useState(true);
  const [Wselected, setWselected] = useState(true);
  const [Sselected, setSselected] = useState(false);

  const [nextClicked, setNextClicked] = useState(false);
  const [type, setType] = useState('');
  const [preview1, setPreview1] = useState(false);
  const [selectedTab, setSelectedTab] = useState('Email');
  const [enterDetails, setEnterDetails] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const [sellerName, setSellerName] = useState('');
  const [companyName, setCompanyName] = useState(localdata?.company_name);
  const [renewalText, setRenewalText] = useState('');
  const [Rnumber, setRNumber] = useState(localdata?.mobile);
  const [productName, setProductName] = useState('');
  const [offerName, setOfferName] = useState('');
  const [discount, setDiscount] = useState('');
  const [days, setDays] = useState('');
  const [daystill, setDaystill] = useState('');
  const [productLink, setProductLink] = useState('');

  const [CILink, setCILink] = useState('');
  const [CTLink, setCTLink] = useState('');
  const [CLLink, setCLLink] = useState('');
  const [CFLink, setCFLink] = useState('');

  const [upimgdone, setupimgdone] = useState(false);
  const [upimg64, setupimg64] = useState('');
  const [upimgname, setupimgname] = useState('');
  const [logoimgdone, setlogoimgdone] = useState(false);
  const [logoimg64, setlogoimg64] = useState('');
  const [logoimgname, setlogoimgname] = useState('');
  const [isLoadingPrev, setIsLoadingPrev] = useState(false);
  const [previewData, setPreviewData] = useState();
  const [showPreview, setShowPreview] = useState(false);

  const [otherDetails, setOtherDetails] = useState(false);
  const [shareCampaignFinal, setShareCampaignFinal] = useState(false);
  const [shareCampaignFinalV2, setShareCampaignFinalV2] = useState(false);
  const [scheduleAnnouncement, setScheduleAnnouncement] = useState(false);
  const [scheduleCustomAnnouncement, setScheduleCustomAnnouncement] = useState(false);
  const [scheduleEmailAnnouncement, setScheduleEmailAnnouncement] = useState(false);
  const [isScheduled, setIsScheduled] = useState(false);

  const [successEnd, setSuccessEnd] = useState(false);
  const [failEnd, setFailEnd] = useState(false);
  const [isLoadingStart, setIsLoadingStart] = useState(false);
  const [openCustomHTML, setOpenCustomHTML] = useState(false);
  const [nameC, setNameC] = useState('');
  const [subjectC, setSubjectC] = useState('');
  const [previewTextC, setPreviewTextC] = useState('');
  const [selectedLabelC, setSelectedLabelC] = useState('');
  const [HTMLFile, setHTMLFile] = useState();
  const [rhtml, setrhtml] = useState();
  const [countOfProgress, setCountOfProgress] = useState(0);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [dragActive, setDragActive] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showHTMLPreview, setShowHTMLPreview] = useState(false);
  const [customHTMLSuccess, setCustomHTMLSuccess] = useState(false);

  const [ThankYou, setThankYou] = useState('');
  const [LinkDesc, setLinkDesc] = useState('');
  const [Link, setLink] = useState('');
  const [Feature1, setFeature1] = useState('');
  const [Feature2, setFeature2] = useState('');
  const [Feature3, setFeature3] = useState('');
  const [NFeature1, setNFeature1] = useState('');
  const [NFeature2, setNFeature2] = useState('');
  const [NFeature3, setNFeature3] = useState('');
  const [Feature4, setFeature4] = useState('');

  const [Greeting, setGreeting] = useState('');
  const [InfoType, setInfoType] = useState('');
  const [Info, setInfo] = useState('');
  const [Profession, setProfession] = useState('');

  const [SDate, setSDate] = useState('');
  const [STime, setSTime] = useState('');
  const [ISOSchedule, setISOSchedule] = useState('');

  const [promotionText, setPromotionText] = useState('');
  const [discountCode, setDiscountCode] = useState('');
  const [occasion, setOccasion] = useState('');

  const [instaHandle, setInstaHandle] = useState('');
  const [supportEmail, setSupportEmail] = useState('');
  const [duration, setDuration] = useState('');
  const [discountText, setDiscountText] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  const [TemplateV2Type, setTemplateV2Type] = useState('Recommended templates');
  const [ItemV2, setItemV2] = useState([]);
  const [mediaIDV2, setMediaIDV2] = useState('');
  const [s3Link, setS3Link] = useState('');
  const [DetailsV2, setDetailsV2] = useState(false);
  const [headerTypeV2, setHeaderTypeV2] = useState('');
  const [headerFileNameV2, setHeaderFileNameV2] = useState('');
  const [URLValueV2, setURLValueV2] = useState('');
  const [BodyParV2, setBodyParV2] = useState([]);
  const [ImageV2, setImageV2] = useState('');
  const [mpm, setMpm] = useState(false);
  const [openCreateSmsModal, setOpenCreateSmsModal] = useState(false);
  const [customSMSText, setCustomSMSText] = useState('');

  const [actionList, setActionList] = useState({});
  const [headerInputText, setHeaderInputText] = useState('');
  const [headerInputExists, setHeaderInputExists] = useState(false);

  const [posURL, setPosURL] = useState(null);
  const [annv2CTA, setAnnv2CTA] = useState([]);
  // const [posMPM, setPosMPM] = useState(null);

  const [openICModal, setOpenICModal] = useState(false);

  const [disableEBTN, setDisableEBTN] = useState(false);

  const [carouselCard, setCarouselCard] = useState([]);
  const [exceedLength, setExceedLength] = useState(true);
  const [checksender, setChecksender] = useState(false);
  const [WABN, setWABN] = useState(false);
  const htmlref = useRef();
  const isPremiumUser = localdata?.premium;
  const [attributes, setAttributes] = useState([
    'name',
    'email',
    'mobile',
    'createdAt',
    'updatedAt',
  ]);
  const [attributeData, setAttributeData] = useState({
    condition: [],
    query: 'AND',
  });
  const [attributeTour, setAttributeTour] = useState(false);
  const [verify, setVerify] = useState(true);
  const [error, setError] = useState('');
  const upimgref = useRef();
  const logoimgref = useRef();
  const isWalletBalanceActive = localdata?.walletCurrency;

  const channelSelected = Eselected || Wselected || Sselected;

  const specialStatus =
    localdata?._id == '64343407cb6cea95d9aedd32' ||
    localdata?._id == '62d0ed6ef0157c83b7464085' ||
    localdata?._id == '636be67d42c9a5cb33f60d98' ||
    localdata?._id == '62c2ed2c14dd1823bc9fb023' ||
    localdata?._id == '632b0972a9c50023bcde9d8c' ||
    localdata?._id == '64343407cb6cea95d9aedd32' ||
    localdata?._id == '635147d9eca6a13b38a7f258';

  const replyMultipleSpecials =
    localdata?._id == '64537f94673ceda82cb08a24' || localdata?._id == '636be67d42c9a5cb33f60d98';

  const userId = localdata?._id;
  const getSenderApiCall = () => {
    const token = authtoken;
    getAllSenderId(token).then((res) => {
      if (res?.flag) {
        if (res?.data && res?.data[0]?.verified?.status) {
          setChecksender(true);
        }
      } else {
        setChecksender(false);
      }
    });
  };

  const filterOptions = (options, { inputValue }) => {
    return options.filter((option) => option.toLowerCase().includes(inputValue.toLowerCase()));
  };

  useEffect(() => {
    // Check if the user has seen the tour before
    const seenTour = localStorage.getItem('seenTour');
    if (!seenTour && labelName !== '' && !openICModal) {
      setAttributeTour(true);
      localStorage.setItem('seenTour', 'true');
    }
    setAttributeData((prev) => ({
      ...prev,
      condition: [],
    }));
    setError('');
  }, [labelName]);

  const checkNullValues = (obj) => {
    if (obj?.condition?.length > 0) {
      for (let i = 0; i < obj?.condition?.length; i++) {
        const value = obj?.condition[i];
        if (value?.attributeName === '' || value?.conditionOperator === '' || value?.value === '') {
          return true;
        }
      }
    }
    if (obj?.query === '') {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (opencampaignmodal) {
      setNextClicked(true);
      setOpenCampaignModal(false);
    }
    eventsend('New_campaign_Campaign_page_clicked');
  }, []);

  const value = sessionStorage.getItem('campaignModal');
  const isOpen = value ? true : false;
  useEffect(() => {
    if (value) {
      setNextClicked(true);
    }
    sessionStorage.removeItem('campaignModal');
  });
  useEffect(() => {
    if (attributeData?.condition?.length > 0 && checkNullValues(attributeData)) {
      setVerify(false);
    }
  }, [attributeData]);

  const getAttributeBasedBalanceApiCall = () => {
    let data = {
      user_id: agentdata ? agentdata?.parentId : localdata?._id,
      label: labelName,
      data: attributeData,
    };
    let token = authtoken;
    toast.loading('Verifying the attributes. This may take a while', {
      toastId: 2,
      autoClose: 3000,
    });
    getAttributeBasedBalance(data, token).then((res) => {
      if (res.flag) {
        setError('');
        setVerify(true);
        toast.dismiss(2);
        console.log('attribute based balance res success ===> ', res.data);
        setBalance(res.data);
        toast.success('Successfully verified attributes.', { autoClose: true });
      } else {
        setVerify(false);
        console.log('attribute based balance res error ===> ', res);
        setError(res.message);
        toast.error(res?.message || 'Error occured', { autoClose: true });
        toast.dismiss(2);
      }
    });
  };

  const getUserAttributesAPICall = () => {
    let token = authtoken;
    getClientAttributes(token).then((res) => {
      if (res?.flag) {
        setAttributes((prev) => {
          const arr = res?.data.map((item) => item.attribute);
          return [...prev, ...arr, 'index'];
        });
      } else {
        console.log('Error while fething attributes data', res);
      }
    });
  };

  useEffect(() => {
    if (HTMLFile) {
      // convertToRead(HTMLFile);
      setFileUploaded(true);
      const timer = setInterval(() => {
        setCountOfProgress((oldProgress) => {
          if (oldProgress == 100) clearInterval(timer);
          return Math.min(oldProgress + Math.random() * 10, 100);
        });
      }, 200);
    }
  }, [HTMLFile]);

  useEffect(() => {
    console.log('preview 1 changed ===> ', preview1);
  }, [preview1]);

  // useEffect(()=>{
  //   if(countOfProgress==100){
  //     setTimeout(() => {
  //       setShowHTMLPreview(true);
  //     }, 1000);
  //   }
  // },[countOfProgress])

  const handlehtmlupload = async (e) => {
    const file = e.target.files[0];
    setHTMLFile(file);
    const readhtml = await convertToRead(file);
    setrhtml(readhtml);
  };
  const handlehtmluploadDND = async (e) => {
    const file = e;
    setHTMLFile(file);
    const readhtml = await convertToRead(file);
    setrhtml(readhtml);
  };

  const handleEBhtml = () => {
    const file = EBHTML;
    setHTMLFile(file);
    // const readhtml = await convertToRead(file);
    setrhtml(EBHTML);
  };

  const convertToRead = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsText(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (
      e.dataTransfer.files &&
      e.dataTransfer.files[0] &&
      e.dataTransfer.files[0].type == 'text/html'
    ) {
      handlehtmluploadDND(e.dataTransfer.files[0]);
    } else {
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 2000);
    }
  };

  const makeCustomAnnouncement = () => {
    const toastId = toast.loading('Preparing to send announcement, please wait.');
    let data = {
      email: JSON.stringify(balance?.emailArr ? balance?.emailArr : balance?.email_arr),
      user_id: agentdata ? agentdata?.parentId : localdata?._id ? localdata?._id : _id,
      label: labelName,

      campaign_name: campaignName,
      file: HTMLFile,
      subject: subjectC,
      previewText: previewTextC,
      use_email: true,
    };
    let token = authtoken;
    console.log('data -------------> ', data);
    customHTMLsendEmailApi(data, token).then((res) => {
      setCustomHTMLSuccess(true);
      setDisableEBTN(false);
      if (res?.flag) {
        toast.update(toastId, {
          render: 'Announcement sent successfully',
          type: 'success',
          isLoading: false,
          autoClose: true,
        });
        // console.log(res);
        // setCustomHTMLSuccess(true);
      } else {
        toast.update(toastId, {
          render: 'Announcement sending failed, please try again in some time.',
          type: 'error',
          isLoading: false,
          autoClose: true,
        });
        // console.log('err ---------> ', res);
      }
    });
  };

  const makeCustomAnnouncementV2 = () => {
    const toastId = toast.loading('Preparing to send announcement, please wait.');
    let data = {
      subject: subjectC,
      previewText: previewTextC,
      labelName: labelName,
      mailArr: balance.emailArr,
      contactArr: balance?.mobileArr,
      tagIdArr: selectedTagsList.map((e) => e._id),
      campaignName: campaignName,
      htmlData: HTMLFile,
    };
    let token = authtoken;
    V2EmailSendAnnouncement(data, token).then((res) => {
      setCustomHTMLSuccess(true);
      setDisableEBTN(false);
      if (res?.flag) {
        toast.update(toastId, {
          render: 'Announcement sent successfully',
          type: 'success',
          isLoading: false,
          autoClose: true,
        });
        // console.log('success v2 -------> ', res);
        // setCustomHTMLSuccess(true);
      } else {
        toast.update(toastId, {
          render: 'Announcement sending failed, please try again in some time.',
          type: 'error',
          isLoading: false,
          autoClose: true,
        });
        // console.log('err ---------> ', res);
      }
    });
  };

  const scheduleCustomEmailAnnouncement = () => {
    let data = {
      subject: subjectC,
      previewText: previewTextC,
      labelName: labelName,
      mailArr: balance.emailArr,
      contactArr: balance?.mobileArr,
      tagIdArr: selectedTagsList.map((e) => e._id),
      campaignName: campaignName,
      htmlData: HTMLFile,
      useCustomEmail: true,
      status: 'Initiated',
      scheduleAt: new Date(`${SDate}T${STime}:00`),
    };
    let token = authtoken;
    createEmailScheduler(data, token)
      .then((res) => {
        setCustomHTMLSuccess(true);
        if (res.flag) {
          console.log('success v2 -------> ', res);
          // setCustomHTMLSuccess(true);
        } else {
          console.log('err ---------> ', res);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.response?.data?.message);
      });
  };

  useEffect(() => {
    eventsend('announcement_modal_opened_web');
    if (reTargetingLabelName) {
      setLabelName(reTargetingLabelName);
      console.log('L', labelName);
    }
    if (setName) {
      setCampaignName(setName);
    }
    getlabellistapicall();
    getUserAttributesAPICall();
    if (EmailBuilder) {
      setOpenCustomHTML(true);
      if (EBHTML) {
        handleEBhtml();
        setShowHTMLPreview(true);
      }
    }
  }, []);

  useEffect(() => {
    if (!openICModal) {
      getlabellistapicall();
    }
  }, [openICModal]);

  const getlabellistapicall = async () => {
    setIsLoadingContacts(true);
    let data = {
      user_id: localdata?._id ? localdata?._id : _id,
    };
    let token = authtoken;
    // let dataApi =await fetchContactLabels(token);
    // const row_data = [];
    // dataApi.data.v1.forEach((vls) => {
    //   row_data.push(vls);
    // });
    // dataApi.data.v2.forEach((vls) => {
    //   row_data.push({
    //     ...vls,
    //     label: vls.label_name
    //   });
    // });

    // setLabelList(row_data);
    // setIsLoadingContacts(false);
    contactlabellistWithoutCountAPI(token).then((res) => {
      if (res.flag) {
        setLabelList(res?.data?.v1);
        setIsLoadingContacts(false);
        console.log('labellist without count', res.data.v1);
      } else {
        console.log('err===>', res);
      }
      setIsLoadingContacts(false);
    });
  };

  useEffect(() => {
    console.log('share to ===> ', shareTo);
    if (shareTo === 'Tags') {
      console.log('share to inside called ===> ', shareTo);
      getTagsListAPIcall();
    } else {
      eventsend('Label_Selected_campaign_pop_up_clicked');
    }
  }, [shareTo]);

  const getTagsListAPIcall = () => {
    setIsLoadingContacts(true);
    let data = {
      _id: localdata?._id,
    };
    let token = authtoken;
    getContacsTagsList(data, token).then((res) => {
      if (res?.flag) {
        console.log('success res ===> ', res);
        setTagsList(res?.data?.getTags);
      } else {
        console.log('err===>', res);
      }
    });
    setIsLoadingContacts(false);
  };

  const onclickpopaction = (item) => {
    const tempArr = tagsList;
    const modifiedArr = tempArr.filter((e) => e?._id !== item._id);
    setSelectedTagsList([...selectedTagsList, item]);
    setTagsList(modifiedArr);
  };

  const onclickremoveselected = (item) => {
    const tempArr = selectedTagsList;
    const modifiedArr = tempArr.filter((e) => e?._id !== item._id);
    setTagsList([...tagsList, item]);
    setSelectedTagsList(modifiedArr);
  };

  useEffect(() => {
    if (selectedTagsList.length > 0) {
      console.log('selected tags list ===>', selectedTagsList);
      setIsLoadingStart(true);
      getTagsBalanceAPIcall();
    }
  }, [selectedTagsList]);

  const getTagsBalanceAPIcall = () => {
    let data = {
      userId: agentdata ? agentdata?.parentId : localdata?._id,
      tagIdArr: selectedTagsList?.map((e) => e._id),
    };
    let token = authtoken;
    getContacsTagsBalance(data, token).then((res) => {
      if (res?.flag) {
        console.log('success res ===> ', res);
        setBalance(res?.data);
      } else {
        console.log('failure res ===> ', res);
      }
      setIsLoadingStart(false);
    });
  };

  const useDebouncedEffect = (effect, deps, delay) => {
    useEffect(() => {
      const handler = setTimeout(() => effect(), delay);

      return () => clearTimeout(handler);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...(deps || []), delay]);
  };

  useDebouncedEffect(() => getlabelbalanceapicall(), [labelName], 500);

  useEffect(() => {
    if (labelName) {
      setIsLoadingStart(true);
      // getlabelbalanceapicall();
    }
  }, [labelName]);

  const getlabelbalanceapicall = () => {
    let data = {
      user_id: agentdata ? agentdata?.parentId : localdata?._id,
      label: labelName,
    };
    let token = authtoken;
    getlabelbalanceAPI(data, token).then((res) => {
      if (res.flag) {
        console.log('balance res success ===> ', res.data.templateList);
        setBalance(res.data.templateList);
      } else {
        console.log('balance res error ===> ', res);
      }
    });
  };

  useEffect(() => {
    if (balance) {
      setIsLoadingStart(false);
      console.log('label details ---> ', balance);
    }
    getSenderApiCall();
  }, [balance]);

  const handleDragUpImage = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDropUpImage = (e, type = 'UpImage') => {
    console.log('handle drop triggered ----------------- ');
    e.preventDefault();
    e.stopPropagation();
    console.log('file ===> ', e.dataTransfer.files[0]);
    if (
      (e.dataTransfer.files &&
        e.dataTransfer.files[0] &&
        e.dataTransfer.files[0].type == 'image/jpg') ||
      'image/jpeg' ||
      'image/png'
    ) {
      handleDropUpload(e.dataTransfer.files[0], type);
    } else {
      toast.error('Error in file upload, please check file type and try again.', {
        position: 'top-center',
      });
    }
  };

  const handleDropUpload = async (file, type) => {
    // const file = file;
    if (file.size < 5242880) {
      console.log('file -------- ', file);
      if (type === 'LogoImage') {
        setlogoimgname(file.name);
      } else {
        setupimgname(file.name);
      }
      const base64 = await convertToBase64(file);
      if (type === 'LogoImage') {
        setlogoimg64(base64);
      } else {
        setupimg64(base64);
      }
    } else {
      toast.error('File size limit exceeded. Please check the size of the file to be uploaded.', {
        position: 'top-center',
      });
    }
  };

  const handleimgupload = async (e) => {
    const file = e.target.files[0];
    const type = 'image';
    if (file) {
      if (UploadValidator(file, type)) {
        setupimgname(file.name);
        const base64 = await convertToBase64(file);
        setupimg64(base64);
      }
    } else {
      toast.error('Unexpected error occurred, please try again later.', {
        position: 'top-center',
      });
    }

    // if (file.type == 'image/jpg' || file.type == 'image/jpeg' || file.type == 'image/png') {
    //   if (file.size < 5242880) {
    //     setupimgname(file.name);
    //     const base64 = await convertToBase64(file);
    //     setupimg64(base64);
    //   } else {
    //     alert('File size limit exceeded. Please check the size of the file to be uploaded.');
    //   }
    // } else {
    //   toast.error('Wrong file format uploaded. Only jpeg, png, and jpg are supported', {
    //
    //     position: 'top-center',
    //   });
    // }
  };
  const handlelogoimgupload = async (e) => {
    const file = e.target.files[0];
    const type = 'image';
    if (file) {
      if (UploadValidator(file, type)) {
        setlogoimgname(file.name);
        const base64 = await convertToBase64(file);
        setlogoimg64(base64);
      }
    } else {
      toast.error('Unexpected error occurred, please try again later.', {
        position: 'top-center',
      });
    }

    // if (file.type == 'image/jpg' || file.type == 'image/jpeg' || file.type == 'image/png') {
    //   if (file.size < 5242880) {
    //     setlogoimgname(file.name);
    //     const base64 = await convertToBase64(file);
    //     setlogoimg64(base64);
    //   } else {
    //     alert('File size limit exceeded. Please check the size of the file to be uploaded.');
    //   }
    // } else {
    //   toast.error('Wrong file format uploaded. Only jpeg, png, and jpg are supported', {
    //
    //     position: 'top-center',
    //   });
    // }
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  useEffect(() => {
    if (upimg64.length > 0) {
      console.log('=========================');
      console.log('=====>', upimg64);
      console.log('=========================');
    }
  }, [upimg64]);

  const validateData =
    type == 'thank_you_customer'
      ? upimg64 &&
      ThankYou &&
      LinkDesc &&
      Link &&
      Feature1 &&
      Feature2 &&
      Feature3 &&
      Feature4 &&
      sellerName &&
      companyName &&
      productLink
      : type == 'share_info'
        ? upimg64 && Greeting && InfoType && Info && Profession && productLink
        : type == 'Offer'
          ? productName &&
          sellerName &&
          offerName &&
          discount &&
          days &&
          productLink &&
          upimg64 &&
          logoimg64
          : type == 'customer_enquiry'
            ? sellerName && companyName && promotionText && daystill && upimg64 && productLink
            : type == 'renewal_remainder'
              ? companyName && upimg64 && productLink && Rnumber && renewalText
              : type == 'customer_win_back'
                ? promotionText && companyName && Feature1 && discountCode && productLink && upimg64
                : type == 'additional_discount_offer'
                  ? Greeting &&
                  occasion &&
                  companyName &&
                  promotionText &&
                  Feature1 &&
                  productLink &&
                  upimg64
                  : type == 'repeatcustomer_offer'
                    ? companyName &&
                    ThankYou &&
                    Feature1 &&
                    promotionText &&
                    Link &&
                    Feature2 &&
                    productLink &&
                    Rnumber &&
                    upimg64
                    : type == 'business_info_newsletter'
                      ? productName &&
                      NFeature1 &&
                      NFeature2 &&
                      NFeature3 &&
                      companyName &&
                      Feature1 &&
                      Feature2 &&
                      Feature3 &&
                      Link &&
                      productLink &&
                      Rnumber &&
                      upimg64
                      : type == 'welcome_insta_message_2'
                        ? companyName &&
                        productName &&
                        instaHandle &&
                        supportEmail &&
                        discountText &&
                        Link &&
                        duration &&
                        Rnumber &&
                        upimg64 &&
                        productLink
                        : type == 'loyal_customers_new'
                          ? productName &&
                          Info &&
                          discountCode &&
                          discount &&
                          Rnumber &&
                          upimg64 &&
                          productLink
                          : type == 'discount_all_products_new'
                            ? companyName && discountCode && discountText && upimg64 && productLink
                            : type == 'do_you_know'
                              ? productName &&
                              Feature1 &&
                              Feature2 &&
                              Feature3 &&
                              Feature4 &&
                              Info &&
                              Rnumber &&
                              upimg64 &&
                              productLink
                              : type == 'maintainance_update'
                                ? Info && companyName && upimg64
                                : type == 'new_product_service_marathi'
                                  ? productName && upimg64
                                  : type == 'reply_multiple'
                                    ? upimg64 &&
                                    sellerName &&
                                    productName &&
                                    Feature1 &&
                                    Feature2 &&
                                    Feature3 &&
                                    Feature4 &&
                                    promotionText &&
                                    offerName
                                    : productName &&
                                    sellerName &&
                                    productLink &&
                                    upimg64 &&
                                    logoimg64;

  const validateScheduler = SDate && STime;

  const createTemplateAPIcall = () => {
    setIsLoadingPrev(true);

    let data = {
      creator_id: localdata?._id ? localdata?._id : _id,
      template_name: campaignName,
      // template_name: type == 'customer_enquiry' || type == 'renewal_reminder' || type == 'share_info' || type == 'thank_you_customer'
      // || type == 'additional_discount_offer' || type == 'repeatcustomer_offer' || type == 'customer_win_back' || type == 'business_info_newsletter' ||
      // type == 'welcome_insta_message_2' || type == 'loyal_customers_new' || type == 'discount_all_products_new' || type == 'do_you_know' ||
      // type == 'maintainance_update' || type == 'new_product_service_marathi'  ? campaignName : productName ? productName : '',
      // template_name: campaignName,
      template_type: type,
      thumbnail: upimg64 ? upimg64 : '',
      logo: logoimg64 ? logoimg64 : '',
      creator_name: sellerName ? sellerName : '',
      template_link: productLink
        ? productLink
        : type == 'maintainance_update' || type == 'new_product_service_marathi'
          ? 'www.google.com'
          : '',
      offer_name: offerName ? offerName : '',
      discounts: discount ? discount : '',
      expired: days ? days : '',
      status: 'Active',
      instagram_handle: CILink ? CILink : '',
      twitter_handle: CTLink ? CTLink : '',
      linkedin_handle: CLLink ? CLLink : '',
      facebook_handle: CFLink ? CFLink : '',
      business_name: companyName ? companyName : '',
      promotion_text: discount ? `${discount}% discount` : '',
      end_date: daystill ? daystill : '',
      renewal_text: renewalText ? newLineRemover(renewalText) : '',
      number: Rnumber ? Rnumber : '',
      use_whatsapp_name: localdata?.whatsapp_config === 'Active' ? true : false,
      thank_you_for: newLineRemover(ThankYou),
      link_desc: newLineRemover(LinkDesc),
      link: Link,
      feature_1: newLineRemover(Feature1),
      feature_2: newLineRemover(Feature2),
      feature_3: newLineRemover(Feature3),
      feature_4: newLineRemover(Feature4),
      greeting: Greeting,
      info_type: InfoType,
      info: newLineRemover(Info),
      profession: Profession,
      occassion: occasion,
      promotion_text: promotionText,
      discount_code: discountCode,
      product_name: newLineRemover(productName),
      notworking_feature1: newLineRemover(NFeature1),
      notworking_feature2: newLineRemover(NFeature2),
      notworking_feature3: newLineRemover(NFeature3),
      instagram_handle: instaHandle,
      support_email: supportEmail,
      duration: duration,
      discount_text: discountText,
    };
    let token = authtoken;
    console.log('data sent ===> ', data);

    createTemplateAPI(data, token).then((res) => {
      if (res.flag) {
        console.log('////////////////////////');
        console.log('create template res ====> ', res);
        console.log('////////////////////////');
        setPreviewData(res.data.saveTemplate);
      } else {
        console.log('err ===> ', res);
      }
    });
  };

  useEffect(() => {
    if (previewData) {
      console.log('777777777777777777777777');
      console.log('777777777777', previewData);
      console.log('777777777777777777777777');
      setShowPreview(true);
    }
  }, [previewData]);

  const makeAnnouncementAction = () => {
    // setIsLoading(true)
    setSuccessEnd(true);
    let data = {
      user_id: agentdata ? agentdata?.parentId : localdata?._id ? localdata?._id : _id,
      template_id: previewData._id,
      // email_arr: balance.emailArr,
      // mobile_arr: balance.mobileArr,
      label: labelName,
      sms:
        type == 'customer_enquiry' ||
          type == 'renewal_remainder' ||
          type == 'share_info' ||
          type == 'thank_you_customer' ||
          type == 'additional_discount_offer' ||
          type == 'repeatcustomer_offer' ||
          type == 'customer_win_back' ||
          type == 'business_info_newsletter' ||
          type == 'welcome_insta_message_2' ||
          type == 'loyal_customers_new' ||
          type == 'discount_all_products_new' ||
          type == 'do_you_know' ||
          type == 'maintainance_update' ||
          type == 'new_product_service_marathi' ||
          type == 'reply_multiple'
          ? false
          : Sselected,
      email:
        type == 'customer_enquiry' ||
          type == 'renewal_remainder' ||
          type == 'share_info' ||
          type == 'thank_you_customer' ||
          type == 'additional_discount_offer' ||
          type == 'repeatcustomer_offer' ||
          type == 'customer_win_back' ||
          type == 'business_info_newsletter' ||
          type == 'welcome_insta_message_2' ||
          type == 'loyal_customers_new' ||
          type == 'discount_all_products_new' ||
          type == 'do_you_know' ||
          type == 'maintainance_update' ||
          type == 'new_product_service_marathi' ||
          type == 'reply_multiple'
          ? false
          : Eselected,
      whatsapp:
        type == 'customer_enquiry' ||
          type == 'renewal_remainder' ||
          type == 'share_info' ||
          type == 'thank_you_customer' ||
          type == 'additional_discount_offer' ||
          type == 'repeatcustomer_offer' ||
          type == 'customer_win_back' ||
          type == 'business_info_newsletter' ||
          type == 'welcome_insta_message_2' ||
          type == 'loyal_customers_new' ||
          type == 'discount_all_products_new' ||
          type == 'do_you_know' ||
          type == 'maintainance_update' ||
          type == 'new_product_service_marathi' ||
          type == 'reply_multiple'
          ? true
          : Wselected,
      use_whatsapp_name: localdata?.whatsapp_config === 'Active' ? true : false,
      use_email: true,
      tag_id_arr: selectedTagsList.map((e) => e._id),
    };
    console.log('/////////////////', data);

    let token = authtoken;
    makeAnnouncementAPI(data, token).then((res) => {
      // setIsLoading(false);
      // setSuccessEnd(true)
      if (res.flag) {
        // setSuccessEnd(true)
        eventsend('run_campaign_v1_successful_web');
        console.log('////////////////');
        console.log('make ann res=========>', res);
        console.log('////////////////');
      } else {
        eventsend('run_campaign_v1_failed_web');
        setFailEnd(true);
        // alert('Error occurred. Please try again later.')
        console.log(res);
      }
    });
  };

  const modifyActionList = () => {
    actionList?.sections?.map((item) => {
      item?.product_items?.map((el) => {
        delete el?.uniqueId;
      });
    });
  };

  const enableButtonV2 =
    ((headerInputExists && headerInputText) ||
      mediaIDV2 ||
      (!headerInputExists && headerTypeV2 === 'text') ||
      headerTypeV2 === 'NONE') &&
    ((BodyParV2 &&
      BodyParV2?.filter((item, index) => {
        return item === '';
      })?.length === 0) ||
      !BodyParV2) &&
    ((annv2CTA &&
      (annv2CTA?.filter((item, index) => {
        return item.value === '';
      })?.length === 0 ||
        annv2CTA?.some((e) => {
          return e.type === 'flow' || e.type === 'catalog';
        }))) ||
      !annv2CTA) &&
    ((actionList &&
      actionList?.index &&
      actionList?.thumbnail_product_retailer_id &&
      actionList?.sections?.length !== 0 &&
      actionList?.sections?.filter((item, index) => {
        return !item?.title || item?.product_items?.length === 0;
      }).length !== 0) ||
      actionList?.index === null ||
      !actionList) &&
    (carouselCard.length === 0 ||
      carouselCard?.filter((item, index) => {
        return (
          item?.header?.link === '' ||
          !item?.bodyParameters ||
          item?.bodyParameters?.includes('', 0)
        );
      })?.length === 0);
  const enableForSms =
    (exceedLength &&
      BodyParV2 &&
      BodyParV2?.filter((item, index) => {
        return item === '';
      })?.length === 0) ||
    !BodyParV2;

  // useEffect(()=>{
  //   console.log("=================== ",annv2CTA)
  //   console.log("=================== ",((headerInputExists && headerInputText) || mediaIDV2 || (!headerInputExists && headerTypeV2 === 'text') || headerTypeV2 === "NONE"))
  //   console.log("=================== ",((BodyParV2 && (BodyParV2?.filter((item,index)=>{return item === '';})?.length === 0)) || !BodyParV2))
  //   console.log("=================== ",(annv2CTA))
  //   console.log("=================== ",(annv2CTA?.filter((item,index)=>{return item.value === '' ;})?.length === 0))
  //   console.log("=================== ",(!annv2CTA))
  //   console.log("=================== ",((actionList && (actionList?.index && actionList?.thumbnail_product_retailer_id && actionList?.sections?.length !== 0
  //     && actionList?.sections?.filter((item,index)=>{
  //         return !item?.title || item?.product_items?.length === 0
  //     }).length !== 0)) || actionList?.index === null || !actionList))
  // },[enableButtonV2])

  const resetDataAction = () => {
    setItemV2([]);
    setAnnv2CTA([]);
    setHeaderTypeV2('');
    setHeaderInputText('');
    setMediaIDV2('');
    setHeaderFileNameV2('');
    setBodyParV2([]);
    setActionList({});
    setCarouselCard([]);
    setS3Link('');
  };

  const makeSmsAnnouncementV2Action = () => {
    setSuccessEnd(true);
    let data = {
      labelName: labelName,
      tagIdArr: selectedTagsList.map((e) => e._id),
      contactArr: balance?.mobileArr,
      campaignName: campaignName,
      templateName: openCreateSmsModal ? 'Twillio Custom Template' : ItemV2.name,
      message: openCreateSmsModal ? customSMSText : ItemV2.templateData.MessageTemplate,
      bodyParameters: BodyParV2,
    };
    let token = authtoken;
    console.log('data being sent V2 ====> ', data);
    sendSmsV2Announcement(token, data).then((res) => {
      if (res?.flag) {
        eventsend('run_campaign_v2_successful_web');
        console.log('v2 success response ===> ', res);
      } else {
        eventsend('run_campaign_v2_failed_web');
        console.log('v2 error response ===> ', res);
      }
    });
  };

  const makeAnnouncementV2Action = () => {
    setSuccessEnd(true);
    let data = {
      labelName: labelName,
      contactArr: balance?.mobileArr,
      tagIdArr: selectedTagsList.map((e) => e._id),
      campaignName: campaignName,
      templateName: ItemV2.name,
      language_code: ItemV2.language,
      cta: annv2CTA,
      // cta: {
      //   index: posURL,
      //   type: URLValueV2 ? 'url' : '',
      //   value: URLValueV2,
      // },
      header: {
        type: headerTypeV2,
        text: headerInputText,
        mediaId: mediaIDV2,
        fileName: headerFileNameV2 ? headerFileNameV2 : null,
        link: s3Link,
      },
      bodyParameters: BodyParV2,
      mpm: actionList,
      carouselCards: carouselCard,
    };
    if (annv2CTA.length === 0) {
      delete data.cta;
    }
    // if (URLValueV2 === '') {
    //   delete data.cta;
    // }
    if (headerTypeV2 === '') {
      delete data.header;
    }
    if (mpm === false) {
      delete data.mpm;
    }
    if (headerInputExists) {
      delete data.header.mediaId;
      delete data.header.fileName;
    }
    if (headerTypeV2 === 'text' && !headerInputExists) {
      delete data.header;
    }
    let token = authtoken;
    console.log('data being sent V2 ====> ', data);
    V2SendAnnouncement(data, token).then((res) => {
      if (res) {
        eventsend('run_campaign_v2_successful_web');
        console.log('v2 success response ===> ', res);
      } else {
        eventsend('run_campaign_v2_failed_web');
        console.log('v2 error response ===> ', res);
      }
    });
  };

  const scheduleAnnouncementV2Action = () => {
    setSuccessEnd(true);
    let data = {
      labelName: labelName,
      tagIdArr: selectedTagsList.map((e) => e._id),
      campaignName: campaignName,
      templateName: ItemV2.name,
      language_code: ItemV2.language,
      cta: annv2CTA,
      contactArr: balance?.mobileArr,
      // cta: {
      //   index: posURL ? posURL : '',
      //   type: URLValueV2 ? 'url' : '',
      //   value: URLValueV2,
      // },
      header: {
        type: headerTypeV2,
        text: headerInputText ?? null,
        mediaId: mediaIDV2,
        fileName: headerFileNameV2 ? headerFileNameV2 : null,
        link: s3Link,
      },
      bodyParameters: BodyParV2,
      carouselCards: carouselCard,
      sms: false,
      email: false,
      whatsapp: true,
      mpm: actionList,
      isV2: true,
      status: 'Initiated',
      scheduleAt: new Date(`${SDate}T${STime}:00`),
    };
    if (annv2CTA.length === 0) {
      delete data.cta;
    }
    // if (URLValueV2 === '') {
    //   delete data.cta;
    // }
    if (headerTypeV2 === '') {
      delete data.header;
    }
    if (mpm === false) {
      delete data.mpm;
    }
    if (headerInputExists) {
      delete data.header.mediaId;
      delete data.header.fileName;
    }
    if (headerTypeV2 === 'text' && !headerInputExists) {
      delete data.header;
    }
    let token = authtoken;
    console.log('data being sent V2 ====> ', data);
    createWAScheduler(data, token).then((res) => {
      if (res) {
        eventsend('schedule_campaign_v2_successful_web');
        console.log('v2 success response ===> ', res);
      } else {
        eventsend('schedule_campaign_v2_failed_web');
        console.log('v2 error response ===> ', res);
      }
    });
  };

  const ISOFormatFunction = () => {
    var dateObj = new Date(`${SDate} ${STime}`);
    var isos = dateObj.toISOString();
    setISOSchedule(isos);
    console.log('iso string value trial ===> ', isos);
  };

  useEffect(() => {
    if (ISOSchedule.length > 0) {
      console.log('iso schedule value trial ===> ', ISOSchedule);
      makeScheduledAnnouncementAction();
    }
  }, [ISOSchedule]);

  const makeScheduledAnnouncementAction = () => {
    setIsLoading(true);
    setIsScheduled(true);
    setSuccessEnd(true);
    let data = {
      user_id: agentdata ? agentdata?.parentId : localdata?._id ? localdata?._id : _id,
      template_id: previewData._id,
      email_arr: balance?.emailArr ? balance?.emailArr : balance?.email_arr,
      mobile_arr: balance?.mobileArr ? balance?.mobileArr : balance?.mobile_arr,
      label: labelName,
      sms:
        type == 'customer_enquiry' ||
          type == 'renewal_remainder' ||
          type == 'share_info' ||
          type == 'thank_you_customer' ||
          type == 'additional_discount_offer' ||
          type == 'repeatcustomer_offer' ||
          type == 'customer_win_back' ||
          type == 'business_info_newsletter' ||
          type == 'welcome_insta_message_2' ||
          type == 'loyal_customers_new' ||
          type == 'discount_all_products_new' ||
          type == 'do_you_know' ||
          type == 'maintainance_update' ||
          type == 'new_product_service_marathi' ||
          type == 'reply_multiple'
          ? false
          : Sselected,
      email:
        type == 'customer_enquiry' ||
          type == 'renewal_remainder' ||
          type == 'share_info' ||
          type == 'thank_you_customer' ||
          type == 'additional_discount_offer' ||
          type == 'repeatcustomer_offer' ||
          type == 'customer_win_back' ||
          type == 'business_info_newsletter' ||
          type == 'welcome_insta_message_2' ||
          type == 'loyal_customers_new' ||
          type == 'discount_all_products_new' ||
          type == 'do_you_know' ||
          type == 'maintainance_update' ||
          type == 'new_product_service_marathi' ||
          type == 'reply_multiple'
          ? false
          : Eselected,
      whatsapp:
        type == 'customer_enquiry' ||
          type == 'renewal_remainder' ||
          type == 'share_info' ||
          type == 'thank_you_customer' ||
          type == 'additional_discount_offer' ||
          type == 'repeatcustomer_offer' ||
          type == 'customer_win_back' ||
          type == 'business_info_newsletter' ||
          type == 'welcome_insta_message_2' ||
          type == 'loyal_customers_new' ||
          type == 'discount_all_products_new' ||
          type == 'do_you_know' ||
          type == 'maintainance_update' ||
          type == 'new_product_service_marathi' ||
          type == 'reply_multiple'
          ? true
          : Wselected,
      use_whatsapp_name: localdata?.whatsapp_config === 'Active' ? true : false,
      schedule_at: ISOSchedule,
    };
    // console.log("/////////////////",data)
    let token = authtoken;
    makeScheduledAnnouncementAPI(data, token).then((res) => {
      setIsLoading(false);
      if (res.flag) {
        eventsend('schedule_announcement_v1_successful_web');
        console.log('////////////////');
        console.log('make schedule ann res=========>', res);
        console.log('////////////////');
      } else {
        eventsend('schedule_announcement_v1_failed_web');
        setFailEnd(true);
        // alert('Error occurred. Please try again later.')
        console.log(res);
      }
    });
  };
  // useEffect(()=>{
  //   console.log("WABN current state ===> ",WABN);
  // },[WABN])

  // const scrolltoID = (id) => {
  //   const element = document.getElementById(id);
  //   if(element){
  //       element.scrollIntoView({behavior: 'smooth',block:'start'});
  //   }
  // }

  // useEffect(()=>{
  //   if(preview1){
  //     setTimeout(() => {
  //       const element = document.getElementById('preview1');
  //       element.scrollIntoView({behavior:'smooth',block:'start'});
  //     }, 500);
  //   }
  // },[preview1])

  return (
    <>
      <div className="ModalDarkBg" onClick={() => { }}>
        {/* <div style={{ ...Styles.DarkBg }} onClick={() => {}}> */}
        <div className="Center">
          {/* <div className="Center" style={{ ...Styles.Center }}> */}
          <div
            className="announcement_modal"
            // className="d-flex flex-column justify-content-between align-items-center"
            // style={{
            //   height: '80vh',
            //   width: 1012,
            //   backgroundColor: colors.white01,
            //   borderRadius: 16,
            //   zIndex: 0,
            //   border: `1px solid ${colors.borderwhite}`,
            //   paddingInline: 24,
            // }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {/* Header Component */}
            {/* <Header 
              onclickCloseAction={()=>{setOpenANModal(false);}}
              currentScreen={currentScreen}
            /> */}
            <div
              className="d-flex flex-row justify-content-between align-items-center w-100"
              style={{ paddingBlock: 16, borderBottom: `1px solid ${colors.borderwhite}` }}
            >
              {successEnd ? (
                <>
                  <div className="d-flex flex-row justify-content-center align-items-center">
                    <h2
                      style={{
                        padding: 0,
                        margin: 0,
                        // fontSize: 24,
                        fontWeight: 700,
                        color: colors.black,
                        marginInline: 16,
                      }}
                    >
                      {type == 'customer_enquiry'
                        ? 'Follow up with discount'
                        : type == 'renewal_remainder'
                          ? 'Send a Reminder'
                          : type == 'share_info'
                            ? 'Share info/update/quote'
                            : type == 'thank_you_customer'
                              ? 'Thank your customer & share info'
                              : type == 'Offer'
                                ? `Share an Offer`
                                : type == 'Course'
                                  ? `Sell a Course`
                                  : type == 'Product'
                                    ? 'Sell a Product'
                                    : type == 'customer_win_back'
                                      ? 'Customer win back'
                                      : type == 'additional_discount_offer'
                                        ? 'Additional discount offer'
                                        : type == 'repeatcustomer_offer'
                                          ? 'Repeat customer offer'
                                          : type == 'business_info_newsletter'
                                            ? 'Business info newsletter'
                                            : type == 'welcome_insta_message_2'
                                              ? 'Welcome Instagram'
                                              : type == 'loyal_customers_new'
                                                ? 'X% off with discount code'
                                                : type == 'discount_all_products_new'
                                                  ? 'Thank you message'
                                                  : type == 'do_you_know'
                                                    ? 'Do you know'
                                                    : type == 'maintainance_update'
                                                      ? 'Maintenance update'
                                                      : type == 'new_product_service_marathi'
                                                        ? 'New product service Marathi'
                                                        : type == 'reply_multiple'
                                                          ? 'Share awareness multiple replies'
                                                          : 'Share Custom Template'}
                    </h2>
                  </div>
                  <div className="d-flex flex-row justify-content-center align-items-center">
                    <img
                      src={images.CMClose}
                      style={{
                        height: 32,
                        width: 32,
                        objectFit: 'contain',
                        marginInline: 4,
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        eventsend('close_campaign_pop_up_clicked');
                        setOpenANModal(false);
                      }}
                    />
                  </div>
                </>
              ) : scheduleAnnouncement ? (
                <>
                  <div className="d-flex flex-row justify-content-center align-items-center">
                    <img
                      src={images.BackArrowBlack}
                      style={{ height: 24, width: 24, objectFit: 'contain', cursor: 'pointer' }}
                      onClick={() => {
                        setScheduleAnnouncement(false);
                        eventsend('back_campaign_pop_up_clicked');
                      }}
                    ></img>
                    <h2
                      style={{
                        padding: 0,
                        margin: 0,
                        // fontSize: 24,
                        fontWeight: 700,
                        color: colors.black,
                        marginInline: 16,
                      }}
                    >
                      {type == 'customer_enquiry'
                        ? 'Follow up with discount'
                        : type == 'renewal_remainder'
                          ? 'Send a Reminder'
                          : type == 'share_info'
                            ? 'Share info/update/quote'
                            : type == 'thank_you_customer'
                              ? 'Thank your customer & share info'
                              : type == 'Offer'
                                ? `Share an Offer`
                                : type == 'Course'
                                  ? `Sell a Course`
                                  : type == 'Product'
                                    ? 'Sell a Product'
                                    : type == 'customer_win_back'
                                      ? 'Customer win back'
                                      : type == 'additional_discount_offer'
                                        ? 'Additional discount offer'
                                        : type == 'repeatcustomer_offer'
                                          ? 'Repeat customer offer'
                                          : type == 'business_info_newsletter'
                                            ? 'Business info newsletter'
                                            : type == 'welcome_insta_message_2'
                                              ? 'Welcome Instagram'
                                              : type == 'loyal_customers_new'
                                                ? 'X% off with discount code'
                                                : type == 'discount_all_products_new'
                                                  ? 'Thank you message'
                                                  : type == 'do_you_know'
                                                    ? 'Do you know'
                                                    : type == 'maintainance_update'
                                                      ? 'Maintenance update'
                                                      : type == 'new_product_service_marathi'
                                                        ? 'New product service Marathi'
                                                        : type == 'reply_multiple' &&
                                                        'Share awareness multiple replies'}
                    </h2>
                  </div>
                  <div className="d-flex flex-row justify-content-center align-items-center">
                    <img
                      src={images.CMClose}
                      style={{
                        height: 32,
                        width: 32,
                        objectFit: 'contain',
                        marginInline: 4,
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setOpenANModal(false);
                        eventsend('close_campaign_pop_up_clicked');
                      }}
                    />
                  </div>
                </>
              ) : shareCampaignFinalV2 ? (
                <>
                  <div className="d-flex flex-row justify-content-center align-items-center">
                    <img
                      src={images.BackArrowBlack}
                      style={{ height: 24, width: 24, objectFit: 'contain', cursor: 'pointer' }}
                      onClick={() => {
                        setShareCampaignFinalV2(false);
                        setScheduleCustomAnnouncement(false);
                        eventsend('back_campaign_pop_up_clicked');
                      }}
                    ></img>
                    <h2
                      style={{
                        padding: 0,
                        margin: 0,
                        // fontSize: 24,
                        fontWeight: 700,
                        color: colors.black,
                        marginInline: 16,
                      }}
                    >
                      {'Share Custom Template'}
                    </h2>
                  </div>
                  <div className="d-flex flex-row justify-content-center align-items-center">
                    {scheduleCustomAnnouncement ? (
                      <>
                        <div
                          className="d-flex flex-row justify-content-center align-items-center"
                          style={{
                            paddingInline: 16,
                            paddingBlock: 8,
                            border: `1px solid ${colors.primary03}`,
                            borderRadius: 8,
                            marginInlineEnd: 16,
                            backgroundColor: colors.primary03,
                            cursor: 'pointer',
                          }}
                          disabled={validateScheduler && isPremiumUser ? false : true}
                          onClick={() => {
                            if (!isPremiumUser) return;
                            eventsend('schedule_campaign_v2_button_clicked_web');
                            console.log('Schedule custom campaign clicked');
                            scheduleAnnouncementV2Action();
                          }}
                        >
                          <img
                            src={images.Scheduler}
                            style={{ height: 19, width: 19, objectFit: 'contain' }}
                          ></img>
                          <p
                            style={{
                              padding: 0,
                              margin: 0,
                              fontSize: 12,
                              fontWeight: 600,
                              color: colors.white,
                              marginInlineStart: 8,
                            }}
                          >
                            {'Schedule Campaign'}
                          </p>
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          className="d-none d-md-flex flex-row justify-content-center align-items-center"
                          style={{
                            paddingInline: 16,
                            paddingBlock: 8,
                            border: `1px solid ${colors.primary03}`,
                            borderRadius: 8,
                            marginInlineEnd: 16,
                            backgroundColor: colors.primary03,
                            cursor: !isPremiumUser ? 'not-allowed' : 'pointer',
                          }}
                          onClick={() => {
                            if (!isPremiumUser) return;
                            console.log('Schedule custom campaign clicked');

                            setScheduleCustomAnnouncement(true);
                          }}
                          disabled={!isPremiumUser}
                        >
                          <img
                            src={images.Scheduler}
                            style={{ height: 19, width: 19, objectFit: 'contain' }}
                          ></img>
                          <p
                            style={{
                              padding: 0,
                              margin: 0,
                              fontSize: 12,
                              fontWeight: 600,
                              color: colors.white,
                              marginInlineStart: 8,
                            }}
                          >
                            {'Schedule Campaign'}
                          </p>
                        </div>
                        <div
                          id="RunCampaignButton"
                          className="d-flex flex-row justify-content-center align-items-center"
                          style={{
                            paddingInline: 16,
                            paddingBlock: 8,
                            border: `1px solid ${colors.primary03}`,
                            borderRadius: 8,
                            marginInlineEnd: 16,
                            backgroundColor: colors.primary03,
                            cursor: !isPremiumUser ? 'not-allowed' : 'pointer',
                          }}
                          onClick={() => {
                            eventsend('Run_a_campaign_campaign_popup_clicked');
                            if (!isPremiumUser) return;
                            eventsend('run_campaign_v2_button_clicked_web');
                            console.log('run campaign clicked');
                            eventsend('run_campaign_button_campaign_pop_up_clicked');
                            if (TemplateV2Type === 'SMS Templates') {
                              makeSmsAnnouncementV2Action();
                            } else {
                              makeAnnouncementV2Action();
                            }
                          }}
                          disabled={!isPremiumUser}
                        >
                          <img
                            src={images.CampaignWhite}
                            style={{ height: 19, width: 19, objectFit: 'contain' }}
                          ></img>
                          <p
                            style={{
                              padding: 0,
                              margin: 0,
                              fontSize: 12,
                              fontWeight: 600,
                              color: colors.white,
                              marginInlineStart: 8,
                            }}
                          >
                            {'Run Campaign'}
                          </p>
                        </div>
                      </>
                    )}

                    <img
                      src={images.CMClose}
                      style={{
                        height: 32,
                        width: 32,
                        objectFit: 'contain',
                        marginInline: 4,
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setOpenANModal(false);
                        eventsend('close_campaign_pop_up_clicked');
                      }}
                    />
                  </div>
                </>
              ) : shareCampaignFinal ? (
                <>
                  <div className="d-flex flex-row justify-content-center align-items-center">
                    <img
                      src={images.BackArrowBlack}
                      style={{ height: 24, width: 24, objectFit: 'contain', cursor: 'pointer' }}
                      onClick={() => {
                        setShareCampaignFinal(false);
                        eventsend('back_campaign_pop_up_clicked');
                      }}
                    ></img>
                    <h2
                      style={{
                        padding: 0,
                        margin: 0,
                        // fontSize: 24,
                        fontWeight: 700,
                        color: colors.black,
                        marginInline: 16,
                      }}
                    >
                      {type == 'customer_enquiry'
                        ? 'Follow up with discount'
                        : type == 'renewal_remainder'
                          ? 'Send a Reminder'
                          : type == 'share_info'
                            ? 'Share info/update/quote'
                            : type == 'thank_you_customer'
                              ? 'Thank your customer & share info'
                              : type == 'Offer'
                                ? `Share an Offer`
                                : type == 'Course'
                                  ? `Sell a Course`
                                  : type == 'Product'
                                    ? 'Sell a Product'
                                    : type == 'customer_win_back'
                                      ? 'Customer win back'
                                      : type == 'additional_discount_offer'
                                        ? 'Additional discount offer'
                                        : type == 'repeatcustomer_offer'
                                          ? 'Repeat customer offer'
                                          : type == 'business_info_newsletter'
                                            ? 'Business info newsletter'
                                            : type == 'welcome_insta_message_2'
                                              ? 'Welcome Instagram'
                                              : type == 'loyal_customers_new'
                                                ? 'X% off with discount code'
                                                : type == 'discount_all_products_new'
                                                  ? 'Thank you message'
                                                  : type == 'do_you_know'
                                                    ? 'Do you know'
                                                    : type == 'maintainance_update'
                                                      ? 'Maintenance update'
                                                      : type == 'new_product_service_marathi'
                                                        ? 'New product service Marathi'
                                                        : type == 'reply_multiple' &&
                                                        'Share awareness multiple replies'}
                    </h2>
                  </div>
                  <div className="d-flex flex-row justify-content-center align-items-center">
                    <div
                      className="d-none d-md-flex flex-row justify-content-center align-items-center"
                      style={{
                        paddingInline: 16,
                        paddingBlock: 8,
                        border: `1px solid ${colors.primary03}`,
                        borderRadius: 8,
                        marginInlineEnd: 16,
                        backgroundColor: colors.primary03,
                        cursor: !isPremiumUser ? 'not-allowed' : 'pointer',
                      }}
                      onClick={() => {
                        if (!isPremiumUser) return;
                        eventsend('Schedule_campaign_campaign_pop_up_clicked');
                        console.log('schedule campaign clicked');
                        setScheduleAnnouncement(true);
                      }}
                      disabled={!isPremiumUser}
                    >
                      <img
                        src={images.Scheduler}
                        style={{ height: 19, width: 19, objectFit: 'contain' }}
                      ></img>
                      <p
                        style={{
                          padding: 0,
                          margin: 0,
                          fontSize: 12,
                          fontWeight: 600,
                          color: colors.white,
                          marginInlineStart: 8,
                        }}
                      >
                        {'Schedule campaign'}
                      </p>
                    </div>
                    <div
                      id="RunCampaignButton"
                      className="d-flex flex-row justify-content-center align-items-center"
                      style={{
                        paddingInline: 16,
                        paddingBlock: 8,
                        border: `1px solid ${colors.primary03}`,
                        borderRadius: 8,
                        marginInlineEnd: 16,
                        backgroundColor: colors.primary03,
                        cursor: !isPremiumUser ? 'not-allowed' : 'pointer',
                      }}
                      onClick={() => {
                        if (!isPremiumUser) return;
                        eventsend('run_campaign_v1_button_clicked_web');
                        console.log('run campaign clicked');
                        makeAnnouncementAction();
                      }}
                      disabled={!isPremiumUser}
                    >
                      <img
                        src={images.CampaignWhite}
                        style={{ height: 19, width: 19, objectFit: 'contain' }}
                      ></img>
                      <p
                        style={{
                          padding: 0,
                          margin: 0,
                          fontSize: 12,
                          fontWeight: 600,
                          color: colors.white,
                          marginInlineStart: 8,
                        }}
                      >
                        {'Run Campaign'}
                      </p>
                    </div>
                    <img
                      src={images.CMClose}
                      style={{
                        height: 32,
                        width: 32,
                        objectFit: 'contain',
                        marginInline: 4,
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        eventsend('close_campaign_pop_up_clicked');
                        setOpenANModal(false);
                      }}
                    />
                  </div>
                </>
              ) : isLoadingPrev ? (
                <>
                  <div className="d-flex flex-row justify-content-center align-items-center">
                    <img
                      src={images.BackArrowBlack}
                      style={{ height: 24, width: 24, objectFit: 'contain', cursor: 'pointer' }}
                      onClick={() => {
                        setIsLoadingPrev(false);
                        setShowPreview(false);
                        eventsend('back_campaign_pop_up_clicked');
                      }}
                    ></img>
                    <h2
                      style={{
                        padding: 0,
                        margin: 0,
                        // fontSize: 24,
                        fontWeight: 700,
                        color: colors.black,
                        marginInline: 16,
                      }}
                    >
                      {type == 'customer_enquiry'
                        ? 'Follow up with discount'
                        : type == 'renewal_remainder'
                          ? 'Send a Reminder'
                          : type == 'share_info'
                            ? 'Share info/update/quote'
                            : type == 'thank_you_customer'
                              ? 'Thank your customer & share info'
                              : type == 'Offer'
                                ? `Share an Offer`
                                : type == 'Course'
                                  ? `Sell a Course`
                                  : type == 'Product'
                                    ? 'Sell a Product'
                                    : type == 'customer_win_back'
                                      ? 'Customer win back'
                                      : type == 'additional_discount_offer'
                                        ? 'Additional discount offer'
                                        : type == 'repeatcustomer_offer'
                                          ? 'Repeat customer offer'
                                          : type == 'business_info_newsletter'
                                            ? 'Business info newsletter'
                                            : type == 'welcome_insta_message_2'
                                              ? 'Welcome Instagram'
                                              : type == 'loyal_customers_new'
                                                ? 'X% off with discount code'
                                                : type == 'discount_all_products_new'
                                                  ? 'Thank you message'
                                                  : type == 'do_you_know'
                                                    ? 'Do you know'
                                                    : type == 'maintainance_update'
                                                      ? 'Maintenance update'
                                                      : type == 'new_product_service_marathi'
                                                        ? 'New product service Marathi'
                                                        : type == 'reply_multiple' &&
                                                        'Share awareness multiple replies'}
                    </h2>
                  </div>
                  <div className="d-flex flex-row justify-content-center align-items-center">
                    <div
                      className="d-flex flex-row justify-content-center align-items-center"
                      id="ShareCampaignButton"
                      style={{
                        paddingInline: 16,
                        paddingBlock: 8,
                        border: `1px solid ${colors.primary03}`,
                        borderRadius: 8,
                        marginInlineEnd: 16,
                        backgroundColor: colors.primary03,
                        cursor: !isPremiumUser ? 'not-allowed' : 'pointer',
                        opacity: !isPremiumUser ? '40%' : 'none',
                      }}
                      onClick={() => {
                        if (!isPremiumUser) return;
                        console.log('share campaign clicked');
                        eventsend('Share_campaign_button_campaign_pop_up_clicked');
                        setShareCampaignFinal(true);
                      }}
                    >
                      <img
                        src={images.Share}
                        style={{ height: 19, width: 19, objectFit: 'contain' }}
                      ></img>
                      <p
                        style={{
                          padding: 0,
                          margin: 0,
                          fontSize: 12,
                          fontWeight: 600,
                          color: colors.white,
                          marginInlineStart: 8,
                        }}
                      >
                        {'Share Campaign'}
                      </p>
                    </div>
                    <img
                      src={images.CMClose}
                      style={{
                        height: 32,
                        width: 32,
                        objectFit: 'contain',
                        marginInline: 4,
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        eventsend('close_campaign_pop_up_clicked');
                        setOpenANModal(false);
                      }}
                    />
                  </div>
                </>
              ) : DetailsV2 ? (
                <>
                  <div className="d-flex flex-row justify-content-center align-items-center">
                    <img
                      src={images.BackArrowBlack}
                      style={{ height: 24, width: 24, objectFit: 'contain', cursor: 'pointer' }}
                      onClick={() => {
                        resetDataAction();
                        setDetailsV2(false);
                        eventsend('back_campaign_pop_up_clicked');
                      }}
                    ></img>
                    <h2
                      style={{
                        padding: 0,
                        margin: 0,
                        // fontSize: 24,
                        fontWeight: 700,
                        color: colors.black,
                        marginInline: 16,
                      }}
                    >
                      {'Enter Custom Template details'}
                    </h2>
                  </div>
                  <div className="d-flex flex-row justify-content-center align-items-center">
                    <CheerioButton
                      borderStyle={{
                        borderColor: colors.primary03,
                        paddingInline: 16,
                        paddingBlock: 8,
                        marginInlineEnd: 16,
                      }}
                      id="ShareCampaignButton"
                      textStyle={{ fontSize: 12, fontWeight: 400, color: colors.white }}
                      btnText={'Share Campaign'}
                      backColor={colors.primary03}
                      icon={images.Share}
                      onclick={() => {
                        console.log('share v2 campaign clicked');
                        modifyActionList();
                        setShareCampaignFinalV2(true);
                      }}
                      disabled={
                        (TemplateV2Type === 'SMS Templates'
                          ? !enableForSms
                          : enableButtonV2
                            ? false
                            : true) || !isPremiumUser
                      }
                    />
                    <img
                      src={images.CMClose}
                      style={{
                        height: 32,
                        width: 32,
                        objectFit: 'contain',
                        marginInline: 4,
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        eventsend('close_campaign_pop_up_clicked');
                        setOpenANModal(false);
                      }}
                    />
                  </div>
                </>
              ) : enterDetails ? (
                <>
                  <div className="d-flex flex-row justify-content-center align-items-center">
                    <img
                      src={images.BackArrowBlack}
                      style={{ height: 24, width: 24, objectFit: 'contain', cursor: 'pointer' }}
                      onClick={() => {
                        eventsend('back_campaign_pop_up_clicked');
                        setEnterDetails(false);
                      }}
                    ></img>
                    <h2
                      style={{
                        padding: 0,
                        margin: 0,
                        // fontSize: 24,
                        fontWeight: 700,
                        color: colors.black,
                        marginInline: 16,
                      }}
                    >
                      {type == 'customer_enquiry'
                        ? 'Enter Follow up details'
                        : type == 'renewal_remainder'
                          ? 'Enter Reminder details'
                          : type == 'share_info'
                            ? 'Enter Share info/update/quote details'
                            : type == 'thank_you_customer'
                              ? 'Enter thank your customer details'
                              : type == 'Offer'
                                ? `Enter Offer details`
                                : type == 'Course'
                                  ? `Enter Course details`
                                  : type == 'Product'
                                    ? 'Enter Product details'
                                    : type == 'customer_win_back'
                                      ? 'Enter customer win back details'
                                      : type == 'additional_discount_offer'
                                        ? 'Enter additional discount offer details'
                                        : type == 'repeatcustomer_offer'
                                          ? 'Enter repeat customer offer details'
                                          : type == 'business_info_newsletter'
                                            ? 'Enter business info newsletter details'
                                            : type == 'welcome_insta_message_2'
                                              ? 'Enter welcome Instagram details'
                                              : type == 'loyal_customers_new'
                                                ? 'Enter X% off with discount code details'
                                                : type == 'discount_all_products_new'
                                                  ? 'Enter thank you message details'
                                                  : type == 'do_you_know'
                                                    ? 'Enter do you know details'
                                                    : type == 'maintainance_update'
                                                      ? 'Maintenance update'
                                                      : type == 'new_product_service_marathi'
                                                        ? 'New product service Marathi'
                                                        : type == 'reply_multiple' &&
                                                        'Share awareness multiple replies'}
                    </h2>
                  </div>
                  <div className="d-flex flex-row justify-content-center align-items-center">
                    <img
                      src={images.CMClose}
                      style={{
                        height: 32,
                        width: 32,
                        objectFit: 'contain',
                        marginInline: 4,
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        eventsend('close_campaign_pop_up_clicked');
                        setOpenANModal(false);
                      }}
                    />
                  </div>
                </>
              ) : preview1 ? (
                <>
                  <div className="d-flex flex-row justify-content-center align-items-center">
                    <img
                      src={images.BackArrowBlack}
                      style={{ height: 24, width: 24, objectFit: 'contain', cursor: 'pointer' }}
                      onClick={() => {
                        setPreview1(false);
                        eventsend('back_campaign_pop_up_clicked');
                      }}
                    ></img>
                    <h2
                      style={{
                        padding: 0,
                        margin: 0,
                        // fontSize: 24,
                        fontWeight: 700,
                        color: colors.black,
                        marginInline: 16,
                      }}
                    >
                      {type == 'customer_enquiry'
                        ? 'Follow up with a discount'
                        : type == 'renewal_remainder'
                          ? 'Send a Reminder'
                          : type == 'Offer'
                            ? `Share an Offer`
                            : type == 'Course'
                              ? `Sell a Course`
                              : type == 'Product'
                                ? 'Sell a Product'
                                : type == 'thank_you_customer'
                                  ? 'Thank you customer & share info'
                                  : type == 'share_info'
                                    ? 'Share an info/update/quote'
                                    : type == 'customer_win_back'
                                      ? 'Customer win back'
                                      : type == 'additional_discount_offer'
                                        ? 'Additional discount offer'
                                        : type == 'repeatcustomer_offer'
                                          ? 'Repeat customer offer'
                                          : type == 'business_info_newsletter'
                                            ? 'Business info newsletter'
                                            : type == 'welcome_insta_message_2'
                                              ? 'Welcome Instagram'
                                              : type == 'loyal_customers_new'
                                                ? 'X% off with discount code'
                                                : type == 'discount_all_products_new'
                                                  ? 'Thank you message'
                                                  : type == 'do_you_know'
                                                    ? 'Do you know'
                                                    : type == 'maintainance_update'
                                                      ? 'Maintenance update'
                                                      : type == 'new_product_service_marathi'
                                                        ? 'New product service Marathi'
                                                        : type == 'reply_multiple' &&
                                                        'Share awareness multiple replies'}
                    </h2>
                  </div>
                  <div className="d-flex flex-row justify-content-center align-items-center">
                    <div
                      className="d-flex flex-row justify-content-center align-items-center"
                      style={{
                        paddingInline: 16,
                        paddingBlock: 8,
                        border: `1px solid ${colors.primary03}`,
                        borderRadius: 8,
                        marginInlineEnd: 16,
                        backgroundColor: colors.primary03,
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setEnterDetails(true);
                      }}
                    >
                      <p
                        style={{
                          padding: 0,
                          margin: 0,
                          fontSize: 12,
                          fontWeight: 600,
                          color: colors.white,
                        }}
                      >
                        {'Use template'}
                      </p>
                    </div>
                    <img
                      src={images.CMClose}
                      style={{
                        height: 32,
                        width: 32,
                        objectFit: 'contain',
                        marginInline: 4,
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        eventsend('close_campaign_pop_up_clicked');
                        setOpenANModal(false);
                      }}
                    />
                  </div>
                </>
              ) : customHTMLSuccess ? (
                <>
                  <div className="d-flex flex-row justify-content-center align-items-center">
                    <h2
                      style={{
                        padding: 0,
                        margin: 0,
                        fontSize: 24,
                        fontWeight: 700,
                        color: colors.black,
                        marginInline: 16,
                      }}
                    >
                      {EBTemplateName ? EBTemplateName : 'Custom HTML Campaign'}
                    </h2>
                  </div>
                  <img
                    src={images.CMClose}
                    style={{
                      height: 32,
                      width: 32,
                      objectFit: 'contain',
                      marginInline: 4,
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      eventsend('close_campaign_pop_up_clicked');
                      setOpenANModal(false);
                    }}
                  />
                </>
              ) : openCustomHTML ? (
                <>
                  {scheduleEmailAnnouncement && (
                    <img
                      src={images.BackArrowBlack}
                      style={{ height: 24, width: 24, objectFit: 'contain', cursor: 'pointer' }}
                      onClick={() => {
                        setScheduleEmailAnnouncement(false);
                      }}
                    />
                  )}
                  <div className="d-flex flex-row justify-content-center align-items-center">
                    <h2
                      style={{
                        padding: 0,
                        margin: 0,
                        fontSize: 24,
                        fontWeight: 700,
                        color: colors.black,
                        marginInline: 16,
                      }}
                    >
                      {'Enter details'}
                    </h2>
                  </div>
                  <div className="d-flex flex-row justify-content-center align-items-center">
                    {showHTMLPreview ? (
                      scheduleEmailAnnouncement ? (
                        <>
                          <div
                            className="d-flex flex-row justify-content-center align-items-center"
                            style={{
                              paddingInline: 16,
                              paddingBlock: 8,
                              border: `1px solid ${colors.primary03}`,
                              borderRadius: 8,
                              marginInlineEnd: 16,
                              backgroundColor: colors.primary03,
                              cursor: 'pointer',
                            }}
                            disabled={validateScheduler && isPremiumUser ? false : true}
                            onClick={() => {
                              console.log('Schedule custom email campaign clicked');
                              scheduleCustomEmailAnnouncement();
                            }}
                          >
                            <img
                              src={images.CampaignWhite}
                              style={{ height: 19, width: 19, objectFit: 'contain' }}
                            ></img>
                            <p
                              style={{
                                padding: 0,
                                margin: 0,
                                fontSize: 12,
                                fontWeight: 600,
                                color: colors.white,
                                marginInlineStart: 8,
                              }}
                            >
                              {'Schedule Campaign'}
                            </p>
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            className="d-flex flex-row justify-content-center align-items-center"
                            style={{
                              paddingInline: 16,
                              paddingBlock: 8,
                              border: `1px solid ${colors.primary03}`,
                              borderRadius: 8,
                              marginInlineEnd: 16,
                              backgroundColor: colors.primary03,
                              cursor: checksender ? 'pointer' : 'not-allowed',
                              opacity: checksender && isPremiumUser ? '1' : '0.5',
                            }}
                            onClick={() => {
                              if (!isPremiumUser) return;
                              if (checksender) {
                                console.log('Schedule email campaign clicked');
                                setScheduleEmailAnnouncement(true);
                              }
                            }}
                            disabled={!isPremiumUser}
                          >
                            <img
                              src={images.CampaignWhite}
                              style={{ height: 19, width: 19, objectFit: 'contain' }}
                            ></img>
                            <p
                              style={{
                                padding: 0,
                                margin: 0,
                                fontSize: 12,
                                fontWeight: 600,
                                color: colors.white,
                                marginInlineStart: 8,
                              }}
                            >
                              {'Schedule Campaign'}
                            </p>
                          </div>
                          <div
                            style={{
                              cursor: checksender ? 'pointer' : 'not-allowed',
                            }}
                          >
                            <CheerioButton
                              borderStyle={{
                                borderColor: colors.primary03,
                                paddingInline: 30,
                                marginInline: 20,
                              }}
                              id="MakeAnnouncementButton"
                              textStyle={{ fontSize: 14, fontWeight: 600, color: colors.white }}
                              btnText={'Make announcement'}
                              backColor={colors.primary03}
                              disabled={
                                !disableEBTN &&
                                  isPremiumUser &&
                                  checksender &&
                                  ((campaignName && subjectC && labelName) ||
                                    (campaignName && subjectC && selectedTagsList))
                                  ? false
                                  : true
                              }
                              onclick={() => {
                                if (!isPremiumUser) return;
                                if (checksender) {
                                  setDisableEBTN(true);
                                  console.log('make announcement clicked...');
                                  if (EmailBuilder && !UploadFromHome) {
                                    makeCustomAnnouncementV2();
                                  } else {
                                    makeCustomAnnouncement();
                                  }
                                }

                                // setTimeout(() => {
                                //   setDisableEBTN(false);
                                // }, 5000);
                              }}
                              icon={images.CampaignWhite}
                            />
                          </div>
                        </>
                      )
                    ) : (
                      <>
                        <CheerioButton
                          borderStyle={{
                            borderColor: colors.primary03,
                            paddingInline: 30,
                            marginInline: 20,
                          }}
                          id="ProceedButton"
                          textStyle={{ fontSize: 14, fontWeight: 600, color: colors.white }}
                          btnText={'Proceed'}
                          disabled={countOfProgress == 100 && checksender ? false : true}
                          backColor={colors.primary03}
                          onclick={() => {
                            console.log('proceed clicked...');
                            setShowHTMLPreview(true);
                          }}
                        />
                      </>
                    )}
                    <img
                      src={images.CMClose}
                      style={{
                        height: 32,
                        width: 32,
                        objectFit: 'contain',
                        marginInline: 4,
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        eventsend('close_campaign_pop_up_clicked');
                        setOpenANModal(false);
                        navigate(-1);
                      }}
                    />
                  </div>
                </>
              ) : nextClicked ? (
                <>
                  <div className="d-flex flex-row justify-content-center align-items-center">
                    <img
                      src={images.BackArrowBlack}
                      style={{ height: 24, width: 24, objectFit: 'contain', cursor: 'pointer' }}
                      onClick={() => {
                        setNextClicked(false);
                        eventsend('back_campaign_pop_up_clicked');
                      }}
                    ></img>
                    <h2
                      style={{
                        padding: 0,
                        margin: 0,
                        // fontSize: 24,
                        fontWeight: 700,
                        color: colors.black,
                        marginInline: 16,
                      }}
                    >
                      {'Choose campaign template'}
                    </h2>
                  </div>
                  <div className="d-flex flex-row justify-content-center align-items-center">
                    {/* <div
                      className="d-none d-md-flex flex-row justify-content-center align-items-center "
                      style={{
                        paddingInline: 16,
                        paddingBlock: 4,
                        border: `1px solid ${colors.black}`,
                        borderRadius: 8,
                        marginInlineEnd: 16,
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        window.open(
                          'mailto:support@cheerio.freshdesk.com?subject=Request a new template'
                        );
                      }}
                    >
                      <img
                        src={images.ReqTemplate}
                        style={{ height: 19, width: 19, objectFit: 'contain' }}
                      ></img>
                      <p
                        style={{
                          padding: 0,
                          margin: 0,
                          marginInlineStart: 8,
                          fontSize: 12,
                          fontWeight: 600,
                          color: colors.black,
                        }}
                      >
                        {'Request new template'}
                      </p>
                    </div> */}
                    {TemplateV2Type === 'SMS Templates' && openCreateSmsModal && (
                      <CheerioButton
                        borderStyle={{
                          borderColor: colors.primary03,
                          paddingInline: 16,
                          paddingBlock: 8,
                          marginInlineEnd: 16,
                        }}
                        id="ShareCampaignButton"
                        textStyle={{ fontSize: 12, fontWeight: 400, color: colors.white }}
                        btnText={'Share Campaign'}
                        backColor={colors.primary03}
                        icon={images.Share}
                        onclick={() => {
                          console.log('share v2 campaign clicked');
                          modifyActionList();
                          setShareCampaignFinalV2(true);
                        }}
                      />
                    )}
                    <img
                      src={images.CMClose}
                      style={{
                        height: 32,
                        width: 32,
                        objectFit: 'contain',
                        marginInline: 4,
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        eventsend('close_campaign_pop_up_clicked');
                        setOpenANModal(false);
                      }}
                    />
                  </div>
                </>
              ) : (
                <>
                  <h2
                    style={{
                      padding: 0,
                      margin: 0,
                      // fontSize: 24,
                      fontWeight: 700,
                      color: colors.black,
                    }}
                  >
                    {'New Campaign'}
                  </h2>
                  <img
                    src={images.CMClose}
                    style={{ height: 32, width: 32, objectFit: 'contain', cursor: 'pointer' }}
                    onClick={() => {
                      eventsend('close_campaign_pop_up_clicked');
                      setOpenANModal(false);
                    }}
                  />
                </>
              )}
            </div>
            {/* Header End */}

            {successEnd ? (
              <>
                {isLoading ? (
                  <>
                    <div
                      className="d-flex flex-column justify-content-center align-items-center w-100"
                      style={{ flex: 1 }}
                    >
                      <SpinnerLoader />
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className="d-flex flex-column justify-content-center align-items-center w-100 verythinscrollnew"
                      style={{ flex: 1, overflowY: 'scroll' }}
                    >
                      <img
                        src={images.NewSuccessGIF}
                        style={{ height: 300, width: 300, objectFit: 'cover' }}
                      ></img>
                      <p
                        style={{
                          marginBlock: 16,
                          fontSize: 16,
                          fontWeight: 400,
                          color: colors.black,
                          textAlign: 'center',
                        }}
                      >
                        {isScheduled
                          ? 'Congratulations, Your campaign has been successfully set up and scheduled.'
                          : 'We are sending your messages. This may take a while, to view delivery progress check analytics. If you wish to stop this campaign click on the STOP button in Actions. You can close this screen now.'}
                        {/* : 'We are sending your messages to customers. This will take a few minutes.'} */}
                      </p>
                      <div className="d-flex flex-column flex-md-row justify-content-evenly align-items-center w-100">
                        <CheerioButton
                          borderStyle={{
                            marginBlock: 8,
                            width: mobileWidth ? '80%' : '25%',
                            paddingBlock: 10,
                            // marginInlineEnd: 16,
                            border: `1px solid ${colors.primary03}`,
                          }}
                          textStyle={{ fontSize: 14, fontWeight: 600, color: colors.primary03 }}
                          btnText={'Go to Analytics'}
                          backColor={colors.white}
                          onclick={() => {
                            eventsend('View_analytics_Campaign_pop_up_clicked');
                            console.log('back to home clicked . . .');
                            navigate('/creator/analytics');
                            eventsend('close_campaign_pop_up_clicked');
                            setOpenANModal(false);
                          }}
                        />
                        <CheerioButton
                          borderStyle={{
                            marginBlock: 8,
                            borderColor: colors.primary03,
                            width: mobileWidth ? '80%' : '25%',
                            paddingBlock: 10,
                            // marginInlineStart: 16,
                          }}
                          textStyle={{ fontSize: 14, fontWeight: 600, color: colors.white }}
                          btnText={'Back to home'}
                          backColor={colors.primary03}
                          onclick={() => {
                            eventsend('Back_to_home_Campaign_pop_up_clicked');
                            console.log('see contacts clicked . . .');
                            navigate('/creator');
                            eventsend('close_campaign_pop_up_clicked');
                            setOpenANModal(false);
                          }}
                        />
                      </div>
                    </div>
                  </>
                )}
              </>
            ) : scheduleAnnouncement ? (
              <>
                <div
                  className="d-flex flex-column justify-content-around align-items-center w-100 verythinscrollnew"
                  style={{ flex: 1, overflowY: 'scroll', paddingInline: 20 }}
                >
                  <p
                    style={{
                      padding: 0,
                      margin: 0,
                      fontSize: 18,
                      fontWeight: 600,
                      color: colors.black,
                    }}
                  >
                    {`Schedule your campaign`}
                  </p>
                  <div className="d-flex flex-column justify-content-center align-items-center w-100">
                    <InputGroup className="mb-4 mt-2">
                      <Form.Label style={{ ...Styles.TxtHeading2 }}>{'Pick a date'}</Form.Label>
                      <Form.Control
                        type="date"
                        datatype=""
                        placeholder="DD-MM-YYYY"
                        className="w-100 btncustom"
                        style={{ borderRadius: 8 }}
                        value={SDate}
                        onChange={(e) => {
                          setSDate(e.target.value);
                        }}
                      />
                    </InputGroup>
                    <InputGroup className="mb-4 mt-2">
                      <Form.Label style={{ ...Styles.TxtHeading2 }}>{'Pick a time'}</Form.Label>
                      <Form.Control
                        type="time"
                        placeholder="HH:mm"
                        className="w-100 btncustom"
                        style={{ borderRadius: 8 }}
                        value={STime}
                        onChange={(e) => {
                          setSTime(e.target.value);
                        }}
                      />
                    </InputGroup>
                    <p
                      style={{
                        padding: 0,
                        margin: 0,
                        fontSize: 16,
                        fontWeight: 400,
                        color: colors.greys04,
                        textAlign: 'center',
                      }}
                    >
                      {/* {`Please note that our campaign is scheduled to run only at specific time intervals: `}
                    <span style={{fontWeight:600,color:colors.black}}>
                      {`HH:00, HH:15, HH:30, and HH:45.`}
                    </span><br/> */}
                      {
                        'Note: Campaign scheduler runs every 15 minutes. So, your campaign will execute at the next 15th minute interval.'
                      }
                    </p>
                  </div>

                  <CheerioButton
                    borderStyle={{
                      marginBlock: 32,
                      borderColor: colors.primary03,
                      width: '25%',
                      paddingBlock: 10,
                    }}
                    textStyle={{ fontSize: 14, fontWeight: 600, color: colors.white }}
                    btnText={'Save'}
                    backColor={colors.primary03}
                    onclick={() => {
                      ISOFormatFunction();
                      console.log('date selected ===> ', SDate);
                      console.log('time selected ===> ', STime);
                      // createTemplateAPIcall()
                    }}
                    disabled={validateScheduler ? false : true}
                  />
                </div>
              </>
            ) : scheduleCustomAnnouncement ? (
              <>
                <div
                  className="d-flex flex-column justify-content-around align-items-center w-100 verythinscrollnew"
                  style={{ flex: 1, overflowY: 'scroll', paddingInline: 20 }}
                >
                  <p
                    style={{
                      padding: 0,
                      margin: 0,
                      fontSize: 18,
                      fontWeight: 600,
                      color: colors.black,
                    }}
                  >
                    {`Schedule your custom campaign`}
                  </p>
                  <div className="d-flex flex-column justify-content-center align-items-center w-100">
                    <InputGroup className="mb-4 mt-2">
                      <Form.Label style={{ ...Styles.TxtHeading2 }}>{'Pick a date'}</Form.Label>
                      <Form.Control
                        type="date"
                        datatype=""
                        placeholder="DD-MM-YYYY"
                        className="w-100 btncustom"
                        style={{ borderRadius: 8 }}
                        min={new Date().toISOString().split('T')[0]}
                        value={SDate}
                        onChange={(e) => {
                          setSDate(e.target.value);
                        }}
                      />
                    </InputGroup>
                    <InputGroup className="mb-4 mt-2">
                      <Form.Label style={{ ...Styles.TxtHeading2 }}>{'Pick a time'}</Form.Label>
                      <Form.Control
                        type="time"
                        placeholder="HH:mm"
                        className="w-100 btncustom"
                        style={{ borderRadius: 8 }}
                        value={STime}
                        onChange={(e) => {
                          setSTime(e.target.value);
                        }}
                      />
                    </InputGroup>
                    <p
                      style={{
                        padding: 0,
                        margin: 0,
                        fontSize: 16,
                        fontWeight: 400,
                        color: colors.greys04,
                        textAlign: 'center',
                      }}
                    >
                      {/* {`Please note that our campaign is scheduled to run only at specific time intervals: `}
                    <span style={{fontWeight:600,color:colors.black}}>
                      {`HH:00, HH:15, HH:30, and HH:45.`}
                    </span><br/> */}
                      {
                        'Note: Campaign scheduler runs every 15 minutes. So, your campaign will execute at the next 15th minute interval.'
                      }
                    </p>
                  </div>

                  {/* <CheerioButton
                    borderStyle={{
                      marginBlock: 32,
                      borderColor: colors.primary03,
                      width: '25%',
                      paddingBlock: 10,
                    }}
                    textStyle={{ fontSize: 14, fontWeight: 600, color: colors.white }}
                    btnText={'Save'}
                    backColor={colors.primary03}
                    onclick={() => {
                      ISOFormatFunction();
                      console.log('date selected ===> ', SDate);
                      console.log('time selected ===> ', STime);
                      // createTemplateAPIcall()
                    }}
                    disabled={validateScheduler ? false : true}
                  /> */}
                </div>
              </>
            ) : shareCampaignFinalV2 ? (
              <>
                <AnnCompletion
                  shareTo={shareTo}
                  selectedTagsList={selectedTagsList}
                  labelName={labelName}
                  balance={balance}
                  item={ItemV2}
                  variableList={BodyParV2}
                  templateV2Type={TemplateV2Type}
                  openCreateSmsModal={openCreateSmsModal}
                  customSMSText={customSMSText}
                />
              </>
            ) : shareCampaignFinal ? (
              <>
                <div
                  className="d-flex flex-column justify-content-start align-items-center w-100 verythinscrollnew"
                  style={{ flex: 1, overflowY: 'scroll' }}
                >
                  <div
                    className="d-flex flex-column justify-content-start align-items-start w-100"
                    style={{ paddingInline: mobileWidth ? 0 : 30, marginBlock: 16 }}
                  >
                    {/* {localdata?.whatsapp_config === 'Active' &&
                  <div className='d-flex flex-row justify-content-start align-items-center w-100' style={{marginBlock:16}}>
                    <div className='d-flex flex-row justify-content-center align-items-center' style={{height:18,width:18,border:`1px solid ${colors.primary04}`,cursor:'pointer'}}
                    onClick={()=>{setWABN(!WABN)}}>
                      {WABN &&
                        <img src={images.CATickFilled} style={{height:18,width:18,objectFit:'contain'}}></img>
                      }
                    </div>
                    <p style={{fontSize:16,fontWeight:600,color:colors.black,paddingBlock:0,paddingInlineStart:20,margin:0}}>
                      {`Use ${localdata?.whatsapp_credentials?.business_name} account to send whatsapp messages to customers`}
                    </p>
                  </div>
                  } */}
                    <h4
                      style={{
                        padding: 0,
                        margin: 0,
                        fontSize: 16,
                        fontWeight: 700,
                        color: colors.black,
                        marginBlock: 8,
                      }}
                    >
                      {'Sharing to:'}
                    </h4>
                    {shareTo === 'Tags' ? (
                      <>
                        <div
                          className="d-flex flex-row justify-content-start align-items-center"
                          style={{ flexWrap: 'wrap', marginBlock: 16 }}
                        >
                          {selectedTagsList &&
                            selectedTagsList.map((item) => {
                              return (
                                <>
                                  <div
                                    className="d-flex flex-row justify-content-center align-items-center"
                                    key={`key${item?._id}`}
                                    style={{
                                      paddingBlock: 4,
                                      paddingInline: 20,
                                      border: `1px solid #FEAF52`,
                                      borderRadius: 8,
                                      marginInlineEnd: 16,
                                      marginBlock: 6,
                                    }}
                                  >
                                    <p
                                      style={{
                                        padding: 0,
                                        margin: 0,
                                        fontSize: 14,
                                        fontWeight: 400,
                                        color: '#FEAF52',
                                      }}
                                    >
                                      {item?.tag_name}
                                    </p>
                                  </div>
                                </>
                              );
                            })}
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          className="d-flex flex-row justify-content-center align-items-center"
                          style={{
                            paddingBlock: 4,
                            paddingInline: 20,
                            border: `1px solid #FEAF52`,
                            borderRadius: 8,
                            marginBlock: 16,
                          }}
                        >
                          <p
                            style={{
                              padding: 0,
                              margin: 0,
                              fontSize: 14,
                              fontWeight: 400,
                              color: '#FEAF52',
                            }}
                          >
                            {labelName}
                          </p>
                        </div>
                      </>
                    )}

                    <h4
                      style={{
                        padding: 0,
                        margin: 0,
                        fontSize: 16,
                        fontWeight: 700,
                        color: colors.black,
                        marginBlock: 16,
                      }}
                    >
                      {'Net required credits:'}
                    </h4>
                    {type !== 'customer_enquiry' &&
                      type !== 'renewal_remainder' &&
                      type !== 'thank_you_customer' &&
                      type !== 'share_info' &&
                      type !== 'additional_discount_offer' &&
                      type !== 'customer_win_back' &&
                      type !== 'repeatcustomer_offer' &&
                      type !== 'business_info_newsletter' &&
                      type !== 'welcome_insta_message_2' &&
                      type !== 'loyal_customers_new' &&
                      type !== 'discount_all_products_new' &&
                      type !== 'do_you_know' &&
                      type !== 'maintainance_update' &&
                      type !== 'new_product_service_marathi' &&
                      type !== 'reply_multiple' && (
                        <>
                          <div
                            className="d-flex flex-row justify-content-start align-items-center"
                            style={{ width: '90%', marginBlockStart: 8 }}
                          >
                            <div
                              className="d-flex flex-row justify-content-between align-items-center"
                              style={{ width: '30%', marginInlineEnd: 50 }}
                            >
                              <p
                                style={{
                                  fontSize: 16,
                                  fontWeight: 400,
                                  color: colors.greys04,
                                  padding: 0,
                                  margin: 0,
                                }}
                              >
                                {'Required Emails'}
                              </p>
                              <p
                                style={{
                                  fontSize: 16,
                                  fontWeight: 400,
                                  color: colors.primary03,
                                  padding: 0,
                                  margin: 0,
                                }}
                              >
                                {balance?.emailArr?.length === 0
                                  ? '0'
                                  : balance?.emailArr?.length
                                    ? balance?.emailArr?.length
                                    : balance?.email_arr?.length}
                              </p>
                            </div>
                            <div
                              className="d-flex flex-row justify-content-between align-items-center"
                              style={{ width: '30%' }}
                            >
                              <p
                                style={{
                                  fontSize: 16,
                                  fontWeight: 400,
                                  color: colors.greys04,
                                  padding: 0,
                                  margin: 0,
                                }}
                              >
                                {'Available Emails'}
                              </p>
                              <p
                                style={{
                                  fontSize: 16,
                                  fontWeight: 400,
                                  color: colors.primary03,
                                  padding: 0,
                                  margin: 0,
                                }}
                              >
                                {localdata?.balance?.email}
                              </p>
                            </div>
                          </div>
                          <div
                            className="d-flex flex-row justify-content-start align-items-center"
                            style={{ width: '90%', marginBlock: 8 }}
                          >
                            <div
                              className="d-flex flex-row justify-content-between align-items-center"
                              style={{ width: '30%', marginInlineEnd: 50 }}
                            >
                              <p
                                style={{
                                  fontSize: 16,
                                  fontWeight: 400,
                                  color: colors.greys04,
                                  padding: 0,
                                  margin: 0,
                                }}
                              >
                                {'Required Messages'}
                              </p>
                              <p
                                style={{
                                  fontSize: 16,
                                  fontWeight: 400,
                                  color: colors.primary03,
                                  padding: 0,
                                  margin: 0,
                                }}
                              >
                                {balance?.mobileArr?.length === 0
                                  ? '0'
                                  : balance?.mobileArr?.length
                                    ? balance?.mobileArr?.length
                                    : balance?.mobile_arr?.length}
                              </p>
                            </div>
                            <div
                              className="d-flex flex-row justify-content-between align-items-center"
                              style={{ width: '30%' }}
                            >
                              <p
                                style={{
                                  fontSize: 16,
                                  fontWeight: 400,
                                  color: colors.greys04,
                                  padding: 0,
                                  margin: 0,
                                }}
                              >
                                {'Available Messages'}
                              </p>
                              <p
                                style={{
                                  fontSize: 16,
                                  fontWeight: 400,
                                  color: colors.primary03,
                                  padding: 0,
                                  margin: 0,
                                }}
                              >
                                {localdata?.balance?.sms}
                              </p>
                            </div>
                          </div>
                        </>
                      )}

                    <div
                      className="d-flex flex-row justify-content-start align-items-center"
                      style={{ width: '90%', marginBlockEnd: 16 }}
                    >
                      <div
                        className="d-flex flex-row justify-content-between align-items-center"
                        style={{ width: '30%', marginInlineEnd: 50 }}
                      >
                        <p
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            color: colors.greys04,
                            padding: 0,
                            margin: 0,
                          }}
                        >
                          {'Required Conversations'}
                        </p>
                        <p
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            color: colors.primary03,
                            padding: 0,
                            margin: 0,
                          }}
                        >
                          {balance?.mobileArr?.length === 0
                            ? '0'
                            : balance?.mobileArr?.length
                              ? balance?.mobileArr?.length
                              : balance?.mobile_arr?.length}
                        </p>
                      </div>
                      {localdata?.walletCurrency ? (
                        <div
                          className="d-flex flex-row justify-content-between align-items-center"
                          style={{ width: '30%' }}
                        >
                          <p
                            style={{
                              fontSize: 16,
                              fontWeight: 400,
                              color: colors.greys04,
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            {'Available Balance'}
                          </p>
                          <p
                            style={{
                              fontSize: 16,
                              fontWeight: 400,
                              color: colors.primary03,
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            {localdata?.walletCurrency !== 'INR' ? '$' : '₹'} {parseFloat(localdata?.walletBalance).toFixed(2)}
                          </p>
                        </div>
                      ) : (
                        <div
                          className="d-flex flex-row justify-content-between align-items-center"
                          style={{ width: '30%' }}
                        >
                          <p
                            style={{
                              fontSize: 16,
                              fontWeight: 400,
                              color: colors.greys04,
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            {'Available Conversations'}
                          </p>
                          <p
                            style={{
                              fontSize: 16,
                              fontWeight: 400,
                              color: colors.primary03,
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            {localdata?.balance?.whatsapp}
                          </p>
                        </div>)}
                    </div>

                    <h4
                      style={{
                        padding: 0,
                        margin: 0,
                        fontSize: 16,
                        fontWeight: 700,
                        color: colors.black,
                        marginBlock: 16,
                      }}
                    >
                      {'Template used:'}
                    </h4>
                    {type == 'customer_enquiry' ? (
                      <>
                        <div
                          className="d-flex flex-row justify-content-between align-items-center h-100"
                          style={{
                            width: mobileWidth ? 'fit-content' : 'calc( 50% + 14px )',
                            backgroundColor: colors.white02,
                            border: `1px solid ${colors.borderwhite}`,
                            padding: 20,
                            borderRadius: 16,
                          }}
                          onClick={() => {
                            setPreview1(true);
                            setType('customer_enquiry');
                          }}
                        >
                          <img
                            src={images.CAFollowUp}
                            style={{ height: 106, width: 106, objectFit: 'contain' }}
                          ></img>
                          <div
                            className="d-flex flex-column justify-content-center align-items-start"
                            style={{ flex: 1, paddingInlineStart: 16 }}
                          >
                            <p
                              style={{
                                padding: 0,
                                margin: 0,
                                fontSize: 16,
                                fontWeight: 700,
                                color: colors.black,
                              }}
                            >
                              {'Follow up with a discount'}
                              <span
                                style={{
                                  fontSize: 12,
                                  fontWeight: 400,
                                  color: colors.white,
                                  backgroundColor: colors.success03,
                                  paddingBlock: 4,
                                  paddingInline: 8,
                                  marginInlineStart: 4,
                                  borderRadius: 6,
                                }}
                              >
                                {'WhatsApp only'}
                              </span>
                            </p>
                            <p
                              style={{
                                padding: 0,
                                margin: 0,
                                fontSize: 14,
                                fontWeight: 400,
                                color: colors.black,
                                marginBlock: 8,
                              }}
                            >
                              {'Hello %Customername,'}
                            </p>
                            <p
                              style={{
                                padding: 0,
                                margin: 0,
                                fontSize: 14,
                                fontWeight: 400,
                                color: colors.black,
                                marginBlock: 8,
                              }}
                            >
                              {
                                'I am %sellername from %companyname. You had enquired about our services in the recent past. I just wanted to touch base with you...'
                              }
                            </p>
                          </div>
                        </div>
                      </>
                    ) : type == 'share_info' ? (
                      <>
                        <div
                          className="d-flex flex-column justify-content-between align-items-center h-100"
                          style={{
                            width: mobileWidth ? 'fit-content' : 'calc( 50% + 14px )',
                            backgroundColor: colors.white02,
                            border: `1px solid ${colors.borderwhite}`,
                            padding: 20,
                            borderRadius: 16,
                          }}
                        >
                          <div className="d-flex flex-row justify-content-between align-items-center w-100">
                            <img
                              src={images.CAShare}
                              style={{ height: 106, width: 106, objectFit: 'contain' }}
                            ></img>
                            <div
                              className="d-flex flex-column justify-content-between align-items-start h-100"
                              style={{ flex: 1, paddingInlineStart: 16 }}
                            >
                              <p
                                className=""
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  fontSize: 16,
                                  fontWeight: 700,
                                  color: colors.black,
                                  width: '100%',
                                }}
                              >
                                {'Share an info/update/quote'}
                              </p>
                              <p
                                className=""
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  fontSize: 10,
                                  fontWeight: 400,
                                  color: colors.white,
                                  backgroundColor: colors.success03,
                                  paddingBlock: 4,
                                  paddingInline: 8,
                                  borderRadius: 6,
                                }}
                              >
                                {'WhatsApp only'}
                              </p>
                              <p
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  fontSize: 14,
                                  fontWeight: 400,
                                  color: colors.black,
                                  marginBlock: 8,
                                }}
                              >
                                {'Hey "customer name",'}
                              </p>
                              <p
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  fontSize: 14,
                                  fontWeight: 400,
                                  color: colors.black,
                                }}
                              >
                                {'Good "timeofday",'}
                              </p>
                              <p
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  fontSize: 14,
                                  fontWeight: 400,
                                  color: colors.black,
                                }}
                              >
                                {'Here’s a %(type of message) we wanted to share with you today...'}
                              </p>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : type == 'thank_you_customer' ? (
                      <>
                        <div
                          className="d-flex flex-column justify-content-between align-items-center h-100"
                          style={{
                            width: mobileWidth ? 'fit-content' : 'calc( 50% + 14px )',
                            backgroundColor: colors.white02,
                            border: `1px solid ${colors.borderwhite}`,
                            padding: 20,
                            borderRadius: 16,
                          }}
                        >
                          <div className="d-flex flex-row justify-content-between align-items-center w-100">
                            <img
                              src={images.CAThankYou}
                              style={{ height: 106, width: 106, objectFit: 'contain' }}
                            ></img>
                            <div
                              className="d-flex flex-column justify-content-between align-items-start h-100"
                              style={{ flex: 1, paddingInlineStart: 16 }}
                            >
                              <p
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  fontSize: 16,
                                  fontWeight: 700,
                                  color: colors.black,
                                  width: '100%',
                                }}
                              >
                                {'Thank your customer & share info'}
                              </p>
                              <p
                                className=""
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  fontSize: 10,
                                  fontWeight: 400,
                                  color: colors.white,
                                  backgroundColor: colors.success03,
                                  paddingBlock: 4,
                                  paddingInline: 8,
                                  borderRadius: 6,
                                }}
                              >
                                {'WhatsApp only'}
                              </p>
                              <p
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  fontSize: 14,
                                  fontWeight: 400,
                                  color: colors.black,
                                  marginBlock: 8,
                                }}
                              >
                                {'Hello "customername",'}
                              </p>
                              <p
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  fontSize: 14,
                                  fontWeight: 400,
                                  color: colors.black,
                                  marginBlock: 8,
                                }}
                              >
                                {
                                  'Thank you for "action". Please find below "supporting". Below are some of the key features: "feature 1" "feature 2"...'
                                }
                              </p>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : type == 'renewal_remainder' ? (
                      <>
                        <div
                          className="d-flex flex-row justify-content-between align-items-center h-100"
                          style={{
                            width: mobileWidth ? 'fit-content' : 'calc( 50% + 14px )',
                            backgroundColor: colors.white02,
                            border: `1px solid ${colors.borderwhite}`,
                            padding: 20,
                            borderRadius: 16,
                          }}
                        >
                          <img
                            src={images.CARemainder}
                            style={{ height: 106, width: 106, objectFit: 'contain' }}
                          ></img>
                          <div
                            className="d-flex flex-column justify-content-between align-items-start h-100"
                            style={{ flex: 1, paddingInlineStart: 16 }}
                          >
                            <p
                              className=""
                              style={{
                                padding: 0,
                                margin: 0,
                                fontSize: 16,
                                fontWeight: 700,
                                color: colors.black,
                                width: '100%',
                              }}
                            >
                              {'Send a Reminder'}
                              <span
                                className=""
                                style={{
                                  fontSize: 10,
                                  fontWeight: 400,
                                  color: colors.white,
                                  backgroundColor: colors.success03,
                                  paddingBlock: 4,
                                  paddingInline: 8,
                                  marginInlineStart: 4,
                                  borderRadius: 6,
                                }}
                              >
                                {'WhatsApp only'}
                              </span>
                            </p>
                            <p
                              style={{
                                padding: 0,
                                margin: 0,
                                fontSize: 14,
                                fontWeight: 400,
                                color: colors.black,
                                marginBlock: 8,
                              }}
                            >
                              {'Dear %customer name'}
                            </p>
                            <p
                              style={{
                                padding: 0,
                                margin: 0,
                                fontSize: 14,
                                fontWeight: 400,
                                color: colors.black,
                              }}
                            >
                              {
                                'We would like to inform you that % descripiton .Please contact us at %Details% for more information/questions...'
                              }
                            </p>
                          </div>
                        </div>
                      </>
                    ) : type == 'additional_discount_offer' ? (
                      <>
                        <div
                          className="d-flex flex-column justify-content-between align-items-center h-100"
                          style={{
                            width: mobileWidth ? 'fit-content' : 'calc( 50% + 14px )',
                            backgroundColor: colors.white02,
                            border: `1px solid ${colors.borderwhite}`,
                            padding: 20,
                            borderRadius: 16,
                          }}
                        >
                          <div className="d-flex flex-row justify-content-between align-items-center w-100">
                            <img
                              src={images.CAAddDiscount}
                              style={{ height: 106, width: 106, objectFit: 'contain' }}
                            ></img>
                            <div
                              className="d-flex flex-column justify-content-between align-items-start h-100"
                              style={{ flex: 1, paddingInlineStart: 16 }}
                            >
                              <p
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  fontSize: 16,
                                  fontWeight: 700,
                                  color: colors.black,
                                  width: '100%',
                                }}
                              >
                                {'Additional discount offer'}
                              </p>
                              <p
                                className=""
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  fontSize: 10,
                                  fontWeight: 400,
                                  color: colors.white,
                                  backgroundColor: colors.success03,
                                  paddingBlock: 4,
                                  paddingInline: 8,
                                  borderRadius: 6,
                                }}
                              >
                                {'WhatsApp only'}
                              </p>
                              <p
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  fontSize: 14,
                                  fontWeight: 400,
                                  color: colors.black,
                                  marginBlock: 8,
                                }}
                              >
                                {'Dearest "customername",'}
                              </p>
                              <p
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  fontSize: 14,
                                  fontWeight: 400,
                                  color: colors.black,
                                  marginBlock: 8,
                                }}
                              >
                                {/* {'Thank you for "action". Please find below "supporting". Below are some of the key features: "feature 1" "feature 2"...'} */}
                                {
                                  'These are Special times and they call for Special needs . At "platform", we would like you to pamper yourself this ... '
                                }
                              </p>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : type == 'repeatcustomer_offer' ? (
                      <>
                        <div
                          className="d-flex flex-column justify-content-between align-items-center h-100"
                          style={{
                            width: mobileWidth ? 'fit-content' : 'calc( 50% + 14px )',
                            backgroundColor: colors.white02,
                            border: `1px solid ${colors.borderwhite}`,
                            padding: 20,
                            borderRadius: 16,
                          }}
                        >
                          <div className="d-flex flex-row justify-content-between align-items-center w-100">
                            <img
                              src={images.CARCOffer}
                              style={{ height: 106, width: 106, objectFit: 'contain' }}
                            ></img>
                            <div
                              className="d-flex flex-column justify-content-between align-items-start h-100"
                              style={{ flex: 1, paddingInlineStart: 16 }}
                            >
                              <p
                                className=""
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  fontSize: 16,
                                  fontWeight: 700,
                                  color: colors.black,
                                  width: '100%',
                                }}
                              >
                                {'Repeat customer offer'}
                              </p>
                              <p
                                className=""
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  fontSize: 10,
                                  fontWeight: 400,
                                  color: colors.white,
                                  backgroundColor: colors.success03,
                                  paddingBlock: 4,
                                  paddingInline: 8,
                                  borderRadius: 6,
                                }}
                              >
                                {'WhatsApp only'}
                              </p>
                              <p
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  fontSize: 14,
                                  fontWeight: 400,
                                  color: colors.black,
                                  marginBlock: 8,
                                }}
                              >
                                {'Hi "customer name",'}
                              </p>
                              <p
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  fontSize: 14,
                                  fontWeight: 400,
                                  color: colors.black,
                                }}
                              >
                                {
                                  'Welcome to the "business name". Thank you for "action" Its time to fill those carts again with "feature 1" ...'
                                }
                              </p>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : type == 'customer_win_back' ? (
                      <>
                        <div
                          className="d-flex flex-column justify-content-between align-items-center h-100 "
                          style={{
                            width: mobileWidth ? 'fit-content' : 'calc( 50% + 14px )',
                            backgroundColor: colors.white02,
                            border: `1px solid ${colors.borderwhite}`,
                            padding: 20,
                            borderRadius: 16,
                          }}
                        >
                          <div className="d-flex flex-row justify-content-between align-items-center w-100">
                            <img
                              src={images.CAWinback}
                              style={{ height: 106, width: 106, objectFit: 'contain' }}
                            ></img>
                            <div
                              className="d-flex flex-column justify-content-between align-items-start h-100"
                              style={{ flex: 1, paddingInlineStart: 16 }}
                            >
                              <p
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  fontSize: 16,
                                  fontWeight: 700,
                                  color: colors.black,
                                  width: '100%',
                                }}
                              >
                                {'Customer win back'}
                              </p>
                              <p
                                className=""
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  fontSize: 10,
                                  fontWeight: 400,
                                  color: colors.white,
                                  backgroundColor: colors.success03,
                                  paddingBlock: 4,
                                  paddingInline: 8,
                                  borderRadius: 6,
                                }}
                              >
                                {'WhatsApp only'}
                              </p>
                              <p
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  fontSize: 14,
                                  fontWeight: 400,
                                  color: colors.black,
                                  marginBlock: 8,
                                }}
                              >
                                {'Congratulations "customername",'}
                              </p>
                              <p
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  fontSize: 14,
                                  fontWeight: 400,
                                  color: colors.black,
                                  marginBlock: 8,
                                }}
                              >
                                {/* {'Thank you for "action". Please find below "supporting". Below are some of the key features: "feature 1" "feature 2"...'} */}
                                {
                                  'We have a "value" in store for you! We have been missing you on "platform" Why don’t you check out the "feature 1" ...'
                                }
                              </p>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : type == 'business_info_newsletter' ? (
                      <>
                        <div
                          className="d-flex flex-column justify-content-between align-items-center h-100"
                          style={{
                            width: mobileWidth ? 'fit-content' : 'calc( 50% + 14px )',
                            backgroundColor: colors.white02,
                            border: `1px solid ${colors.borderwhite}`,
                            padding: 20,
                            borderRadius: 16,
                          }}
                        >
                          <div className="d-flex flex-row justify-content-between align-items-center w-100">
                            <img
                              src={images.CABINews}
                              style={{ height: 106, width: 106, objectFit: 'contain' }}
                            ></img>
                            <div
                              className="d-flex flex-column justify-content-between align-items-start h-100"
                              style={{ flex: 1, paddingInlineStart: 16 }}
                            >
                              <p
                                className=""
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  fontSize: 16,
                                  fontWeight: 700,
                                  color: colors.black,
                                  width: '100%',
                                }}
                              >
                                {'Business Info newsletter'}
                              </p>
                              <p
                                className=""
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  fontSize: 10,
                                  fontWeight: 400,
                                  color: colors.white,
                                  backgroundColor: colors.success03,
                                  paddingBlock: 4,
                                  paddingInline: 8,
                                  borderRadius: 6,
                                }}
                              >
                                {'WhatsApp only'}
                              </p>
                              <p
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  fontSize: 14,
                                  fontWeight: 400,
                                  color: colors.black,
                                  marginBlock: 8,
                                }}
                              >
                                {'Hi "customer name",'}
                              </p>
                              <p
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  fontSize: 14,
                                  fontWeight: 400,
                                  color: colors.black,
                                }}
                              >
                                {'Want to switch to "value prompt",'}
                              </p>
                              <p
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  fontSize: 14,
                                  fontWeight: 400,
                                  color: colors.black,
                                }}
                              >
                                {
                                  'So what is stopping you ? "notworking feature 1" "notworking feature 2" ...'
                                }
                              </p>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : type == 'welcome_insta_message_2' ? (
                      <>
                        <div
                          className="d-flex flex-column justify-content-between align-items-center h-100 "
                          style={{
                            width: mobileWidth ? 'fit-content' : 'calc( 50% + 14px )',
                            backgroundColor: colors.white02,
                            border: `1px solid ${colors.borderwhite}`,
                            padding: 20,
                            borderRadius: 16,
                          }}
                        >
                          <div className="d-flex flex-row justify-content-between align-items-center w-100">
                            <img
                              src={images.CAWelcomeInsta}
                              style={{ height: 106, width: 106, objectFit: 'contain' }}
                            ></img>
                            <div
                              className="d-flex flex-column justify-content-between align-items-start h-100"
                              style={{ flex: 1, paddingInlineStart: 16 }}
                            >
                              <p
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  fontSize: 16,
                                  fontWeight: 700,
                                  color: colors.black,
                                  width: '100%',
                                }}
                              >
                                {'Welcome Instagram'}
                              </p>
                              <p
                                className=""
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  fontSize: 10,
                                  fontWeight: 400,
                                  color: colors.white,
                                  backgroundColor: colors.success03,
                                  paddingBlock: 4,
                                  paddingInline: 8,
                                  borderRadius: 6,
                                }}
                              >
                                {'WhatsApp only'}
                              </p>
                              <p
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  fontSize: 14,
                                  fontWeight: 400,
                                  color: colors.black,
                                  marginBlock: 8,
                                }}
                              >
                                {'Hello "customername"!,'}
                              </p>
                              <p
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  fontSize: 14,
                                  fontWeight: 400,
                                  color: colors.black,
                                }}
                              >
                                {`Welcome to "business name". We are glad you choose us for "product name". Let's connect on Instagram ...`}
                              </p>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : type == 'loyal_customers_new' ? (
                      <>
                        <div
                          className="d-flex flex-column justify-content-between align-items-center h-100"
                          style={{
                            width: mobileWidth ? 'fit-content' : 'calc( 50% + 14px )',
                            backgroundColor: colors.white02,
                            border: `1px solid ${colors.borderwhite}`,
                            padding: 20,
                            borderRadius: 16,
                          }}
                        >
                          <div className="d-flex flex-row justify-content-between align-items-center w-100">
                            <img
                              src={images.CALoyalCustomer}
                              style={{ height: 106, width: 106, objectFit: 'contain' }}
                            ></img>
                            <div
                              className="d-flex flex-column justify-content-between align-items-start h-100"
                              style={{ flex: 1, paddingInlineStart: 16 }}
                            >
                              <p
                                className=""
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  fontSize: 16,
                                  fontWeight: 700,
                                  color: colors.black,
                                  width: '100%',
                                }}
                              >
                                {'  '}
                              </p>
                              <p
                                className=""
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  fontSize: 10,
                                  fontWeight: 400,
                                  color: colors.white,
                                  backgroundColor: colors.success03,
                                  paddingBlock: 4,
                                  paddingInline: 8,
                                  borderRadius: 6,
                                }}
                              >
                                {'WhatsApp only'}
                              </p>
                              <p
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  fontSize: 14,
                                  fontWeight: 400,
                                  color: colors.black,
                                  marginBlock: 8,
                                }}
                              >
                                {'Hey "customer name"🤙,'}
                              </p>
                              <p
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  fontSize: 14,
                                  fontWeight: 400,
                                  color: colors.black,
                                }}
                              >
                                {
                                  'With more than "no of units" units sold 🥳, our product "product name" is one of our best-seller🥇 and an ABSOLUTE favorite ...'
                                }
                              </p>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : type == 'discount_all_products_new' ? (
                      <>
                        <div
                          className="d-flex flex-column justify-content-between align-items-center h-100"
                          style={{
                            width: mobileWidth ? 'fit-content' : 'calc( 50% + 14px )',
                            backgroundColor: colors.white02,
                            border: `1px solid ${colors.borderwhite}`,
                            padding: 20,
                            borderRadius: 16,
                          }}
                        >
                          <div className="d-flex flex-row justify-content-between align-items-center w-100">
                            <img
                              src={images.CAThankYou2}
                              style={{ height: 106, width: 106, objectFit: 'contain' }}
                            ></img>
                            <div
                              className="d-flex flex-column justify-content-between align-items-start h-100"
                              style={{ flex: 1, paddingInlineStart: 16 }}
                            >
                              <p
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  fontSize: 16,
                                  fontWeight: 700,
                                  color: colors.black,
                                  width: '100%',
                                }}
                              >
                                {'Thank you message'}
                              </p>
                              <p
                                className=""
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  fontSize: 10,
                                  fontWeight: 400,
                                  color: colors.white,
                                  backgroundColor: colors.success03,
                                  paddingBlock: 4,
                                  paddingInline: 8,
                                  borderRadius: 6,
                                }}
                              >
                                {'WhatsApp only'}
                              </p>
                              <p
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  fontSize: 14,
                                  fontWeight: 400,
                                  color: colors.black,
                                  marginBlock: 8,
                                }}
                              >
                                {'Hey "customer name"😊,'}
                              </p>
                              <p
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  fontSize: 14,
                                  fontWeight: 400,
                                  color: colors.black,
                                }}
                              >
                                {`Thankyou for recently buying "business name" Products😍. To show our appreciation🥳, we've got a special offer for you ...`}
                              </p>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : type == 'do_you_know' ? (
                      <>
                        <div
                          className="d-flex flex-column justify-content-between align-items-center h-100"
                          style={{
                            width: mobileWidth ? 'fit-content' : 'calc( 50% + 14px )',
                            backgroundColor: colors.white02,
                            border: `1px solid ${colors.borderwhite}`,
                            padding: 20,
                            borderRadius: 16,
                          }}
                        >
                          <div className="d-flex flex-row justify-content-between align-items-center w-100">
                            <img
                              src={images.CADoYouKnow}
                              style={{ height: 106, width: 106, objectFit: 'contain' }}
                            ></img>
                            <div
                              className="d-flex flex-column justify-content-between align-items-start h-100"
                              style={{ flex: 1, paddingInlineStart: 16 }}
                            >
                              <p
                                className=""
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  fontSize: 16,
                                  fontWeight: 700,
                                  color: colors.black,
                                  width: '100%',
                                }}
                              >
                                {'Do you know'}
                              </p>
                              <p
                                className=""
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  fontSize: 10,
                                  fontWeight: 400,
                                  color: colors.white,
                                  backgroundColor: colors.success03,
                                  paddingBlock: 4,
                                  paddingInline: 8,
                                  borderRadius: 6,
                                }}
                              >
                                {'WhatsApp only'}
                              </p>
                              <p
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  fontSize: 14,
                                  fontWeight: 400,
                                  color: colors.black,
                                  marginBlock: 8,
                                }}
                              >
                                {'Hi "customer name",'}
                              </p>
                              <p
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  fontSize: 14,
                                  fontWeight: 400,
                                  color: colors.black,
                                }}
                              >
                                {
                                  'Do you know that "product name" 1. "feature_1" 2. "feature_2" 3. "feature_3" 4. "feature_4" You can "info" ... '
                                }
                              </p>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : type == 'maintainance_update' ? (
                      <>
                        <div
                          className="d-flex flex-column justify-content-between align-items-center h-100 hovercustom"
                          style={{
                            width: mobileWidth ? 'fit-content' : '50%',
                            backgroundColor: colors.white02,
                            border: `1px solid ${colors.borderwhite}`,
                            padding: 20,
                            borderRadius: 16,
                            marginInlineEnd: 8,
                          }}
                        >
                          <div className="d-flex flex-row justify-content-between align-items-center w-100">
                            <img
                              src={images.CAMaintenance}
                              style={{ height: 106, width: 106, objectFit: 'contain' }}
                            ></img>
                            <div
                              className="d-flex flex-column justify-content-between align-items-start h-100"
                              style={{ flex: 1, paddingInlineStart: 16 }}
                            >
                              <p
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  fontSize: 16,
                                  fontWeight: 700,
                                  color: colors.black,
                                  width: '100%',
                                }}
                              >
                                {'Maintenance update English'}
                              </p>
                              <p
                                className=""
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  fontSize: 10,
                                  fontWeight: 400,
                                  color: colors.white,
                                  backgroundColor: colors.success03,
                                  paddingBlock: 4,
                                  paddingInline: 8,
                                  borderRadius: 6,
                                }}
                              >
                                {'WhatsApp only'}
                              </p>
                              <p
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  fontSize: 14,
                                  fontWeight: 400,
                                  color: colors.black,
                                  marginBlock: 8,
                                }}
                              >
                                {'Hello "customer name",'}
                              </p>
                              <p
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  fontSize: 14,
                                  fontWeight: 400,
                                  color: colors.black,
                                }}
                              >
                                {`We are "maintenance message". For more information feel free to reach to us. Thanks "business name" `}
                              </p>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : type == 'new_product_service_marathi' ? (
                      <>
                        <div
                          className="d-flex flex-column justify-content-between align-items-center h-100"
                          style={{
                            width: mobileWidth ? 'fit-content' : 'calc( 50% + 14px )',
                            backgroundColor: colors.white02,
                            border: `1px solid ${colors.borderwhite}`,
                            padding: 20,
                            borderRadius: 16,
                          }}
                        >
                          <div className="d-flex flex-row justify-content-between align-items-center w-100">
                            <img
                              src={images.CAServiceMarathi}
                              style={{ height: 106, width: 106, objectFit: 'contain' }}
                            ></img>
                            <div
                              className="d-flex flex-column justify-content-between align-items-start h-100"
                              style={{ flex: 1, paddingInlineStart: 16 }}
                            >
                              <p
                                className=""
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  fontSize: 16,
                                  fontWeight: 700,
                                  color: colors.black,
                                  width: '100%',
                                }}
                              >
                                {'New product service Marathi'}
                              </p>
                              <p
                                className=""
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  fontSize: 10,
                                  fontWeight: 400,
                                  color: colors.white,
                                  backgroundColor: colors.success03,
                                  paddingBlock: 4,
                                  paddingInline: 8,
                                  borderRadius: 6,
                                }}
                              >
                                {'WhatsApp only'}
                              </p>
                              <p
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  fontSize: 14,
                                  fontWeight: 400,
                                  color: colors.black,
                                  marginBlock: 8,
                                }}
                              >
                                {'नमस्ते {{1}} ,'}
                              </p>
                              <p
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  fontSize: 14,
                                  fontWeight: 400,
                                  color: colors.black,
                                }}
                              >
                                {
                                  'आम्ही {{2}}. अधिक माहिती साठी आपण खाली दिलेल्या क्रमांकावर संपर्क करू शकतात अथवा या मेसेजला रिप्लाय देऊन माहिती मिळवू शकतात. धन्यवाद.'
                                }
                              </p>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : type == 'reply_multiple' ? (
                      <>
                        <div
                          className="d-flex flex-column justify-content-between align-items-center h-100"
                          style={{
                            width: mobileWidth ? 'fit-content' : 'calc( 50% + 14px )',
                            backgroundColor: colors.white02,
                            border: `1px solid ${colors.borderwhite}`,
                            padding: 20,
                            borderRadius: 16,
                          }}
                        >
                          <div className="d-flex flex-row justify-content-between align-items-center w-100">
                            <img
                              src={images.CAMultiple}
                              style={{ height: 106, width: 106, objectFit: 'contain' }}
                            ></img>
                            <div
                              className="d-flex flex-column justify-content-between align-items-start h-100"
                              style={{ flex: 1, paddingInlineStart: 16 }}
                            >
                              <p
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  fontSize: 16,
                                  fontWeight: 700,
                                  color: colors.black,
                                  width: '100%',
                                }}
                              >
                                {'Share awareness multiple replies'}
                              </p>
                              <p
                                className=""
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  fontSize: 10,
                                  fontWeight: 400,
                                  color: colors.white,
                                  backgroundColor: colors.success03,
                                  paddingBlock: 4,
                                  paddingInline: 8,
                                  borderRadius: 6,
                                }}
                              >
                                {'WhatsApp only'}
                              </p>
                              <p
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  fontSize: 14,
                                  fontWeight: 400,
                                  color: colors.black,
                                  marginBlock: 8,
                                }}
                              >
                                {'"Greetings"'}
                              </p>
                              <p
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  fontSize: 14,
                                  fontWeight: 400,
                                  color: colors.black,
                                }}
                              >
                                {/* {'Thank you for "action". Please find below "supporting". Below are some of the key features: "feature 1" "feature 2"...'} */}
                                {/* {'We have a "value" in store for you! We have been missing you on "platform" Why don’t you check out the "feature 1" ...'} */}
                                {`You have to try "Product Name" Why ? -"Feature 1" -"Feature 2" - "Feature 3" ...`}
                              </p>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : type == 'Offer' ? (
                      <>
                        <div
                          className="d-flex flex-row justify-content-between align-items-center h-100"
                          style={{
                            width: mobileWidth ? 'fit-content' : 'calc( 50% + 14px )',
                            backgroundColor: colors.white02,
                            border: `1px solid ${colors.borderwhite}`,
                            padding: 20,
                            borderRadius: 16,
                          }}
                        >
                          <img
                            src={images.CAOffer}
                            style={{ height: 106, width: 106, objectFit: 'contain' }}
                          ></img>
                          <div
                            className="d-flex flex-column justify-content-center align-items-start"
                            style={{ flex: 1, paddingInlineStart: 16 }}
                          >
                            <p
                              style={{
                                padding: 0,
                                margin: 0,
                                fontSize: 16,
                                fontWeight: 700,
                                color: colors.black,
                              }}
                            >
                              {'Share an Offer'}
                            </p>
                            <p
                              style={{
                                padding: 0,
                                margin: 0,
                                fontSize: 14,
                                fontWeight: 400,
                                color: colors.black,
                                marginBlock: 8,
                              }}
                            >
                              {
                                '“seller name” has an exclusive offer for you for “product name”. “Discount” only for next “days” days is available for you.'
                              }
                            </p>
                            <p
                              style={{
                                padding: 0,
                                margin: 0,
                                fontSize: 14,
                                fontWeight: 400,
                                color: colors.black,
                              }}
                            >
                              {'BUY NOW https:'}
                              <span style={{ color: colors.linkblue }}>
                                {'//vbskjfsijflsl2356./'}
                              </span>
                            </p>
                          </div>
                        </div>
                      </>
                    ) : type == 'Course' ? (
                      <>
                        <div
                          className="d-flex flex-row justify-content-between align-items-center h-100"
                          style={{
                            width: mobileWidth ? 'fit-content' : 'calc( 50% + 14px )',
                            backgroundColor: colors.white02,
                            border: `1px solid ${colors.borderwhite}`,
                            padding: 20,
                            borderRadius: 16,
                          }}
                        >
                          <img
                            src={images.CACourse}
                            style={{ height: 106, width: 106, objectFit: 'contain' }}
                          ></img>
                          <div
                            className="d-flex flex-column justify-content-center align-items-start"
                            style={{ flex: 1, paddingInlineStart: 16 }}
                          >
                            <p
                              style={{
                                padding: 0,
                                margin: 0,
                                fontSize: 16,
                                fontWeight: 700,
                                color: colors.black,
                              }}
                            >
                              {'Sell a course'}
                            </p>
                            <p
                              style={{
                                padding: 0,
                                margin: 0,
                                fontSize: 14,
                                fontWeight: 400,
                                color: colors.black,
                                marginBlock: 8,
                              }}
                            >
                              {
                                '“seller name” has an exclusive offer for you for “product name”. “Discount” only for next “days” days is available for you.'
                              }
                            </p>
                            <p
                              style={{
                                padding: 0,
                                margin: 0,
                                fontSize: 14,
                                fontWeight: 400,
                                color: colors.black,
                              }}
                            >
                              {'ENROLL NOW https:'}
                              <span style={{ color: colors.linkblue }}>
                                {'//vbskjfsijflsl2356./'}
                              </span>
                            </p>
                          </div>
                        </div>
                      </>
                    ) : (
                      type == 'Product' && (
                        <>
                          <div
                            className="d-flex flex-row justify-content-between align-items-center h-100"
                            style={{
                              width: mobileWidth ? 'fit-content' : 'calc( 50% + 14px )',
                              backgroundColor: colors.white02,
                              border: `1px solid ${colors.borderwhite}`,
                              padding: 20,
                              borderRadius: 16,
                            }}
                          >
                            <img
                              src={images.CAProduct}
                              style={{ height: 106, width: 106, objectFit: 'contain' }}
                            ></img>
                            <div
                              className="d-flex flex-column justify-content-center align-items-start"
                              style={{ flex: 1, paddingInlineStart: 16 }}
                            >
                              <p
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  fontSize: 16,
                                  fontWeight: 700,
                                  color: colors.black,
                                }}
                              >
                                {'Sell a Product'}
                              </p>
                              <p
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  fontSize: 14,
                                  fontWeight: 400,
                                  color: colors.black,
                                  marginBlock: 8,
                                }}
                              >
                                {
                                  '“seller name” has an exclusive offer for you for “product name”. “Discount” only for next “days” days is available for you.'
                                }
                              </p>
                              <p
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  fontSize: 14,
                                  fontWeight: 400,
                                  color: colors.black,
                                }}
                              >
                                {'BUY NOW https:'}
                                <span style={{ color: colors.linkblue }}>
                                  {'//vbskjfsijflsl2356./'}
                                </span>
                              </p>
                            </div>
                          </div>
                        </>
                      )
                    )}

                    <h4
                      style={{
                        padding: 0,
                        margin: 0,
                        fontSize: 16,
                        fontWeight: 700,
                        color: colors.black,
                        marginBlockStart: 30,
                        marginBlockEnd: 8,
                      }}
                    >
                      {'Offer details:'}
                    </h4>
                    {type !== 'renewal_remainder' &&
                      type !== 'thank_you_customer' &&
                      type !== 'share_info' &&
                      type !== 'additional_discount_offer' &&
                      type !== 'customer_win_back' &&
                      type !== 'repeatcustomer_offer' &&
                      type !== 'business_info_newsletter' &&
                      type !== 'welcome_insta_message_2' &&
                      type !== 'loyal_customers_new' &&
                      type !== 'discount_all_products_new' &&
                      type !== 'do_you_know' &&
                      type !== 'maintainance_update' &&
                      type !== 'new_product_service_marathi' &&
                      type !== 'reply_multiple' && (
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            marginBlock: 2,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                          }}
                        >
                          {'Seller Name: '}
                          <span style={{ fontWeight: 400 }}>{sellerName}</span>
                        </p>
                      )}
                    {type !== 'customer_enquiry' &&
                      type !== 'renewal_remainder' &&
                      type !== 'thank_you_customer' &&
                      type !== 'share_info' &&
                      type !== 'additional_discount_offer' &&
                      type !== 'customer_win_back' &&
                      type !== 'repeatcustomer_offer' &&
                      type !== 'business_info_newsletter' &&
                      type !== 'welcome_insta_message_2' &&
                      type !== 'loyal_customers_new' &&
                      type !== 'discount_all_products_new' &&
                      type !== 'do_you_know' &&
                      type !== 'maintainance_update' &&
                      type !== 'new_product_service_marathi' &&
                      type !== 'reply_multiple' && (
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            marginBlock: 2,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                          }}
                        >
                          {'Product Name: '}
                          <span style={{ fontWeight: 400 }}>{productName}</span>
                        </p>
                      )}
                    {type == 'customer_enquiry' && (
                      <>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            marginBlock: 2,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                          }}
                        >
                          {'Business Name: '}
                          <span style={{ fontWeight: 400 }}>{companyName}</span>
                        </p>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            marginBlock: 2,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                          }}
                        >
                          {'Discount: '}
                          <span style={{ fontWeight: 400 }}>{`${discount}%`}</span>
                        </p>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            marginBlock: 2,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                          }}
                        >
                          {'Offer valid till: '}
                          <span style={{ fontWeight: 400 }}>{daystill}</span>
                        </p>
                      </>
                    )}
                    {type == 'renewal_remainder' && (
                      <>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            marginBlock: 2,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                          }}
                        >
                          {'Message: '}
                          <span style={{ fontWeight: 400 }}>{renewalText}</span>
                        </p>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            marginBlock: 2,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                          }}
                        >
                          {'Contact details: '}
                          <span style={{ fontWeight: 400 }}>{Rnumber}</span>
                        </p>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            marginBlock: 2,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                          }}
                        >
                          {'Sender details: '}
                          <span style={{ fontWeight: 400 }}>{companyName}</span>
                        </p>
                      </>
                    )}
                    {type == 'Offer' && (
                      <>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            marginBlock: 2,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                          }}
                        >
                          {'Offer Name: '}
                          <span style={{ fontWeight: 400 }}>{offerName}</span>
                        </p>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            marginBlock: 2,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                          }}
                        >
                          {'Discount(in percentage): '}
                          <span style={{ fontWeight: 400 }}>{`${discount}%`}</span>
                        </p>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            marginBlock: 2,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                          }}
                        >
                          {'Offer valid for: '}
                          <span style={{ fontWeight: 400 }}>{`next ${days} days`}</span>
                        </p>
                      </>
                    )}
                    {type == 'thank_you_customer' && (
                      <>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            marginBlock: 2,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                          }}
                        >
                          {'Action: '}
                          <span style={{ fontWeight: 400 }}>{ThankYou}</span>
                        </p>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            marginBlock: 2,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                          }}
                        >
                          {'Supporting text: '}
                          <span style={{ fontWeight: 400 }}>{LinkDesc}</span>
                        </p>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            marginBlock: 2,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                          }}
                        >
                          {'Supporting Link: '}
                          <span style={{ fontWeight: 400 }}>{Link}</span>
                        </p>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            marginBlock: 2,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                          }}
                        >
                          {'Feature 1: '}
                          <span style={{ fontWeight: 400 }}>{Feature1}</span>
                        </p>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            marginBlock: 2,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                          }}
                        >
                          {'Feature 2: '}
                          <span style={{ fontWeight: 400 }}>{Feature2}</span>
                        </p>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            marginBlock: 2,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                          }}
                        >
                          {'Feature 3: '}
                          <span style={{ fontWeight: 400 }}>{Feature3}</span>
                        </p>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            marginBlock: 2,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                          }}
                        >
                          {'Feature 4: '}
                          <span style={{ fontWeight: 400 }}>{Feature4}</span>
                        </p>
                      </>
                    )}
                    {type == 'share_info' && (
                      <>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            marginBlock: 2,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                          }}
                        >
                          {'Greeting: '}
                          <span style={{ fontWeight: 400 }}>{Greeting}</span>
                        </p>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            marginBlock: 2,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                          }}
                        >
                          {'Type of message: '}
                          <span style={{ fontWeight: 400 }}>{InfoType}</span>
                        </p>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            marginBlock: 2,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                          }}
                        >
                          {'Message: '}
                          <span style={{ fontWeight: 400 }}>{Info}</span>
                        </p>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            marginBlock: 2,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                          }}
                        >
                          {'Supporting message/ Profession: '}
                          <span style={{ fontWeight: 400 }}>{Profession}</span>
                        </p>
                      </>
                    )}
                    {type == 'additional_discount_offer' && (
                      <>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            marginBlock: 2,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                          }}
                        >
                          {'Greeting: '}
                          <span style={{ fontWeight: 400 }}>{Greeting}</span>
                        </p>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            marginBlock: 2,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                          }}
                        >
                          {'Business name: '}
                          <span style={{ fontWeight: 400 }}>{companyName}</span>
                        </p>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            marginBlock: 2,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                          }}
                        >
                          {'Occasion: '}
                          <span style={{ fontWeight: 400 }}>{occasion}</span>
                        </p>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            marginBlock: 2,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                          }}
                        >
                          {'Promotion text: '}
                          <span style={{ fontWeight: 400 }}>{promotionText}</span>
                        </p>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            marginBlock: 2,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                          }}
                        >
                          {'Feature: '}
                          <span style={{ fontWeight: 400 }}>{Feature1}</span>
                        </p>
                      </>
                    )}
                    {type == 'customer_win_back' && (
                      <>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            marginBlock: 2,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                          }}
                        >
                          {'Promotion text: '}
                          <span style={{ fontWeight: 400 }}>{promotionText}</span>
                        </p>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            marginBlock: 2,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                          }}
                        >
                          {'Business name: '}
                          <span style={{ fontWeight: 400 }}>{companyName}</span>
                        </p>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            marginBlock: 2,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                          }}
                        >
                          {'Feature: '}
                          <span style={{ fontWeight: 400 }}>{Feature1}</span>
                        </p>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            marginBlock: 2,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                          }}
                        >
                          {'Discount code: '}
                          <span style={{ fontWeight: 400 }}>{discountCode}</span>
                        </p>
                      </>
                    )}
                    {type == 'repeatcustomer_offer' && (
                      <>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            marginBlock: 2,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                          }}
                        >
                          {'Business name: '}
                          <span style={{ fontWeight: 400 }}>{companyName}</span>
                        </p>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            marginBlock: 2,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                          }}
                        >
                          {'Thanking action: '}
                          <span style={{ fontWeight: 400 }}>{ThankYou}</span>
                        </p>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            marginBlock: 2,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                          }}
                        >
                          {'Feature 1: '}
                          <span style={{ fontWeight: 400 }}>{Feature1}</span>
                        </p>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            marginBlock: 2,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                          }}
                        >
                          {'Occasion: '}
                          <span style={{ fontWeight: 400 }}>{occasion}</span>
                        </p>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            marginBlock: 2,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                          }}
                        >
                          {'Promotion text: '}
                          <span style={{ fontWeight: 400 }}>{promotionText}</span>
                        </p>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            marginBlock: 2,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                          }}
                        >
                          {'Feature 2: '}
                          <span style={{ fontWeight: 400 }}>{Feature2}</span>
                        </p>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            marginBlock: 2,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                          }}
                        >
                          {'Number: '}
                          <span style={{ fontWeight: 400 }}>{Rnumber}</span>
                        </p>
                      </>
                    )}
                    {type == 'business_info_newsletter' && (
                      <>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            marginBlock: 2,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                          }}
                        >
                          {'Prodct name: '}
                          <span style={{ fontWeight: 400 }}>{productName}</span>
                        </p>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            marginBlock: 2,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                          }}
                        >
                          {'Not working feature 1: '}
                          <span style={{ fontWeight: 400 }}>{NFeature1}</span>
                        </p>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            marginBlock: 2,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                          }}
                        >
                          {'Not working feature 2: '}
                          <span style={{ fontWeight: 400 }}>{NFeature2}</span>
                        </p>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            marginBlock: 2,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                          }}
                        >
                          {'Not working feature 3: '}
                          <span style={{ fontWeight: 400 }}>{NFeature3}</span>
                        </p>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            marginBlock: 2,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                          }}
                        >
                          {'Business name: '}
                          <span style={{ fontWeight: 400 }}>{companyName}</span>
                        </p>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            marginBlock: 2,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                          }}
                        >
                          {'Feature 1: '}
                          <span style={{ fontWeight: 400 }}>{Feature1}</span>
                        </p>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            marginBlock: 2,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                          }}
                        >
                          {'Feature 2: '}
                          <span style={{ fontWeight: 400 }}>{Feature2}</span>
                        </p>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            marginBlock: 2,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                          }}
                        >
                          {'Feature 3: '}
                          <span style={{ fontWeight: 400 }}>{Feature3}</span>
                        </p>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            marginBlock: 2,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                          }}
                        >
                          {'Number: '}
                          <span style={{ fontWeight: 400 }}>{Rnumber}</span>
                        </p>
                      </>
                    )}
                    {type == 'welcome_insta_message_2' && (
                      <>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            marginBlock: 2,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                          }}
                        >
                          {'Business name: '}
                          <span style={{ fontWeight: 400 }}>{companyName}</span>
                        </p>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            marginBlock: 2,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                          }}
                        >
                          {'Product name: '}
                          <span style={{ fontWeight: 400 }}>{productName}</span>
                        </p>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            marginBlock: 2,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                          }}
                        >
                          {'Instagram handle: '}
                          <span style={{ fontWeight: 400 }}>{instaHandle}</span>
                        </p>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            marginBlock: 2,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                          }}
                        >
                          {'Support Email: '}
                          <span style={{ fontWeight: 400 }}>{supportEmail}</span>
                        </p>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            marginBlock: 2,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                          }}
                        >
                          {'Discount: '}
                          <span style={{ fontWeight: 400 }}>{discountText}</span>
                        </p>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            marginBlock: 2,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                          }}
                        >
                          {'Duration: '}
                          <span style={{ fontWeight: 400 }}>{duration}</span>
                        </p>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            marginBlock: 2,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                          }}
                        >
                          {'Contact detail: '}
                          <span style={{ fontWeight: 400 }}>{Rnumber}</span>
                        </p>
                      </>
                    )}
                    {type == 'loyal_customers_new' && (
                      <>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            marginBlock: 2,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                          }}
                        >
                          {'Product name: '}
                          <span style={{ fontWeight: 400 }}>{productName}</span>
                        </p>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            marginBlock: 2,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                          }}
                        >
                          {'Discount code: '}
                          <span style={{ fontWeight: 400 }}>{discountCode}</span>
                        </p>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            marginBlock: 2,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                          }}
                        >
                          {'Quantity Info: '}
                          <span style={{ fontWeight: 400 }}>{Info}</span>
                        </p>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            marginBlock: 2,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                          }}
                        >
                          {'Discount: '}
                          <span style={{ fontWeight: 400 }}>{discount}</span>
                        </p>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            marginBlock: 2,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                          }}
                        >
                          {'Contact detail: '}
                          <span style={{ fontWeight: 400 }}>{Rnumber}</span>
                        </p>
                      </>
                    )}
                    {type == 'discount_all_products_new' && (
                      <>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            marginBlock: 2,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                          }}
                        >
                          {'Business name: '}
                          <span style={{ fontWeight: 400 }}>{companyName}</span>
                        </p>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            marginBlock: 2,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                          }}
                        >
                          {'Discount code: '}
                          <span style={{ fontWeight: 400 }}>{discountCode}</span>
                        </p>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            marginBlock: 2,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                          }}
                        >
                          {'Discount: '}
                          <span style={{ fontWeight: 400 }}>{discountText}</span>
                        </p>
                      </>
                    )}
                    {type == 'do_you_know' && (
                      <>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            marginBlock: 2,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                          }}
                        >
                          {'Do you know message: '}
                          <span style={{ fontWeight: 400 }}>{productName}</span>
                        </p>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            marginBlock: 2,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                          }}
                        >
                          {'Feature 1: '}
                          <span style={{ fontWeight: 400 }}>{Feature1}</span>
                        </p>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            marginBlock: 2,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                          }}
                        >
                          {'Feature 2: '}
                          <span style={{ fontWeight: 400 }}>{Feature2}</span>
                        </p>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            marginBlock: 2,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                          }}
                        >
                          {'Feature 3: '}
                          <span style={{ fontWeight: 400 }}>{Feature3}</span>
                        </p>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            marginBlock: 2,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                          }}
                        >
                          {'Feature 4: '}
                          <span style={{ fontWeight: 400 }}>{Feature4}</span>
                        </p>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            marginBlock: 2,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                          }}
                        >
                          {'Info message: '}
                          <span style={{ fontWeight: 400 }}>{Info}</span>
                        </p>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            marginBlock: 2,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                          }}
                        >
                          {'Contact detail: '}
                          <span style={{ fontWeight: 400 }}>{Rnumber}</span>
                        </p>
                      </>
                    )}
                    {type == 'maintainance_update' && (
                      <>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            marginBlock: 2,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                          }}
                        >
                          {'Maintenance message: '}
                          <span style={{ fontWeight: 400 }}>{Info}</span>
                        </p>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            marginBlock: 2,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                          }}
                        >
                          {'Business name: '}
                          <span style={{ fontWeight: 400 }}>{companyName}</span>
                        </p>
                      </>
                    )}
                    {type == 'new_product_service_marathi' && (
                      <>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            marginBlock: 2,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                          }}
                        >
                          {'Message: '}
                          <span style={{ fontWeight: 400 }}>{productName}</span>
                        </p>
                      </>
                    )}
                    {type == 'reply_multiple' && (
                      <>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            marginBlock: 2,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                          }}
                        >
                          {'Greeting: '}
                          <span style={{ fontWeight: 400 }}>{sellerName}</span>
                        </p>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            marginBlock: 2,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                          }}
                        >
                          {'Product/Service: '}
                          <span style={{ fontWeight: 400 }}>{productName}</span>
                        </p>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            marginBlock: 2,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                          }}
                        >
                          {'Feature 1: '}
                          <span style={{ fontWeight: 400 }}>{Feature1}</span>
                        </p>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            marginBlock: 2,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                          }}
                        >
                          {'Feature 2: '}
                          <span style={{ fontWeight: 400 }}>{Feature2}</span>
                        </p>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            marginBlock: 2,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                          }}
                        >
                          {'Feature 3: '}
                          <span style={{ fontWeight: 400 }}>{Feature3}</span>
                        </p>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            marginBlock: 2,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                          }}
                        >
                          {'USP 1: '}
                          <span style={{ fontWeight: 400 }}>{promotionText}</span>
                        </p>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            marginBlock: 2,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                          }}
                        >
                          {'USP 2: '}
                          <span style={{ fontWeight: 400 }}>{Feature4}</span>
                        </p>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            marginBlock: 2,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                          }}
                        >
                          {'Offerings: '}
                          <span style={{ fontWeight: 400 }}>{offerName}</span>
                        </p>
                      </>
                    )}
                    {type !== 'maintainance_update' && type !== 'new_product_service_marathi' && (
                      <p
                        style={{
                          padding: 0,
                          margin: 0,
                          marginBlock: 2,
                          fontSize: 16,
                          fontWeight: 600,
                          color: colors.black,
                        }}
                      >
                        {'Link: '}
                        <span style={{ fontWeight: 400 }}>{productLink}</span>
                      </p>
                    )}
                    {type !== 'customer_enquiry' &&
                      type !== 'renewal_remainder' &&
                      type !== 'thank_you_customer' &&
                      type !== 'share_info' &&
                      type !== 'additional_discount_offer' &&
                      type !== 'customer_win_back' &&
                      type !== 'repeatcustomer_offer' &&
                      type !== 'business_info_newsletter' &&
                      type !== 'welcome_insta_message_2' &&
                      type !== 'loyal_customers_new' &&
                      type !== 'discount_all_products_new' &&
                      type !== 'do_you_know' &&
                      type !== 'maintainance_update' &&
                      type !== 'new_product_service_marathi' &&
                      type !== 'reply_multiple' && (
                        <>
                          <p
                            style={{
                              padding: 0,
                              margin: 0,
                              marginBlock: 2,
                              fontSize: 16,
                              fontWeight: 600,
                              color: colors.black,
                            }}
                          >
                            {'Logo: '}
                          </p>
                          <HEImages
                            userGeneratedSrc={getImagePath(previewData.logo)}
                            style={{ width: '25%', objectFit: 'contain', marginBlock: 16 }}
                          />
                          {/* <img
                            src={getImagePath(previewData.logo)}
                            style={{ width: '25%', objectFit: 'contain', marginBlock: 16 }}
                          ></img> */}
                        </>
                      )}
                    <p
                      style={{
                        padding: 0,
                        margin: 0,
                        marginBlock: 2,
                        fontSize: 16,
                        fontWeight: 600,
                        color: colors.black,
                      }}
                    >
                      {'Thumbnail Image: '}
                    </p>
                    <HEImages
                      userGeneratedSrc={getImagePath(previewData.thumbnail)}
                      style={{ width: '25%', objectFit: 'contain', marginBlock: 16 }}
                    />
                    {/* <img
                      src={getImagePath(previewData.thumbnail)}
                      style={{ width: '25%', objectFit: 'contain', marginBlock: 16 }}
                    ></img> */}
                  </div>
                </div>
              </>
            ) : showPreview ? (
              <>
                <div
                  className="d-flex flex-column justify-content-start align-items-center w-100 verythinscrollnew"
                  style={{ flex: 1, overflowY: 'scroll' }}
                >
                  <div
                    className="d-flex flex-row justify-content-between align-items-center w-100"
                    style={{ paddingInline: mobileWidth ? 6 : 60 }}
                  >
                    {type == 'customer_enquiry' ||
                      type == 'renewal_remainder' ||
                      type == 'thank_you_customer' ||
                      type == 'share_info' ||
                      type == 'additional_discount_offer' ||
                      type == 'repeatcustomer_offer' ||
                      type == 'customer_win_back' ||
                      type == 'business_info_newsletter' ||
                      type == 'welcome_insta_message_2' ||
                      type == 'loyal_customers_new' ||
                      type == 'discount_all_products_new' ||
                      type == 'do_you_know' ||
                      type == 'maintainance_update' ||
                      type == 'new_product_service_marathi' ||
                      type == 'reply_multiple' ? (
                      <>
                        <h2
                          style={{
                            padding: 0,
                            margin: 0,
                            // fontSize: 24,
                            fontWeight: 700,
                            color: colors.black,
                            marginBlock: 16,
                            width: '100%',
                            textAlign: 'center',
                          }}
                        >{`WhatsApp preview`}</h2>
                      </>
                    ) : (
                      <>
                        <img
                          src={images.DownArrowTip}
                          style={{
                            height: 24,
                            width: 24,
                            objectFit: 'contain',
                            transform: 'rotate(90deg)',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            if (selectedTab == 'Email') {
                              setSelectedTab('SMS');
                            } else if (selectedTab == 'WhatsApp') {
                              setSelectedTab('Email');
                            } else if (selectedTab == 'SMS') {
                              setSelectedTab('WhatsApp');
                            }
                          }}
                        ></img>
                        <h2
                          style={{
                            padding: 0,
                            margin: 0,
                            // fontSize: 24,
                            fontWeight: 700,
                            color: colors.black,
                            marginBlock: 16,
                          }}
                        >{`${selectedTab} preview`}</h2>
                        <img
                          src={images.DownArrowTip}
                          style={{
                            height: 24,
                            width: 24,
                            objectFit: 'contain',
                            transform: 'rotate(-90deg)',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            if (selectedTab == 'Email') {
                              setSelectedTab('WhatsApp');
                            } else if (selectedTab == 'WhatsApp') {
                              setSelectedTab('SMS');
                            } else if (selectedTab == 'SMS') {
                              setSelectedTab('Email');
                            }
                          }}
                        ></img>
                      </>
                    )}
                  </div>
                  <div
                    className="d-flex flex-row justify-content-center align-items-center"
                    style={{ width: mobileWidth ? '100%' : '60%' }}
                  >
                    <Preview
                      data={previewData}
                      selectedTab={
                        type == 'customer_enquiry' ||
                          type == 'renewal_remainder' ||
                          type == 'thank_you_customer' ||
                          type == 'share_info' ||
                          type == 'additional_discount_offer' ||
                          type == 'repeatcustomer_offer' ||
                          type == 'customer_win_back' ||
                          type == 'business_info_newsletter' ||
                          type == 'welcome_insta_message_2' ||
                          type == 'loyal_customers_new' ||
                          type == 'discount_all_products_new' ||
                          type == 'do_you_know' ||
                          type == 'maintainance_update' ||
                          type == 'new_product_service_marathi' ||
                          type == 'reply_multiple'
                          ? 'WhatsApp'
                          : selectedTab
                      }
                    />
                  </div>
                </div>
              </>
            ) : isLoadingPrev ? (
              <>
                <div
                  className="d-flex flex-column justify-content-center align-items-center w-100 verythinscrollnew"
                  style={{ flex: 1, overflowY: 'scroll' }}
                >
                  <SpinnerLoader />
                </div>
              </>
            ) : DetailsV2 ? (
              <>
                {TemplateV2Type === 'SMS Templates' ? (
                  <SmsDetails
                    item={ItemV2}
                    bodyPar={BodyParV2}
                    setBodyPar={setBodyParV2}
                    setExceedLength={setExceedLength}
                  ></SmsDetails>
                ) : (
                  <AnnDetails
                    item={ItemV2}
                    setMediaID={setMediaIDV2}
                    setHeaderType={setHeaderTypeV2}
                    headerType={headerTypeV2}
                    setHeaderFileName={setHeaderFileNameV2}
                    URLvalue={URLValueV2}
                    setURLvalue={setURLValueV2}
                    bodyPar={BodyParV2}
                    setBodyPar={setBodyParV2}
                    mpm={mpm}
                    setMpm={setMpm}
                    actionList={actionList}
                    setActionList={setActionList}
                    headerInputText={headerInputText}
                    setHeaderInputText={setHeaderInputText}
                    setHeaderInputExists={setHeaderInputExists}
                    setPosURL={setPosURL}
                    annv2CTA={annv2CTA}
                    setAnnv2CTA={setAnnv2CTA}
                    setCarouselCardAPI={setCarouselCard}
                    setS3Link={setS3Link}
                  // setPosMPM={setPosMPM}
                  />
                )}
              </>
            ) : enterDetails ? (
              <>
                <div
                  className="d-flex flex-column justify-content-start align-items-center w-100 verythinscrollnew"
                  style={{ flex: 1, overflowY: 'scroll' }}
                >
                  <Form className="px-3 py-2" style={{ width: mobileWidth ? '100%' : '75%' }}>
                    {type == 'Offer' ? (
                      <>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>Seller name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter seller name"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            value={sellerName}
                            maxLength={Sselected ? 20 : ''}
                            onChange={(e) => {
                              setSellerName(e.target.value);
                            }}
                          />
                        </InputGroup>

                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>Product name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter product name"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            value={productName}
                            maxLength={Sselected ? 30 - sellerName.length : ''}
                            onChange={(e) => {
                              setProductName(e.target.value);
                            }}
                          />
                        </InputGroup>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>Offer name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter offer name"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            value={offerName}
                            onChange={(e) => {
                              setOfferName(e.target.value);
                            }}
                          />
                        </InputGroup>

                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>
                            {'Discount (in %)'}
                          </Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="Enter discount"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            value={discount}
                            onChange={(e) => {
                              setDiscount(e.target.value);
                            }}
                          />
                        </InputGroup>

                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>
                            No. of days offer available
                          </Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="Enter no. of days"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            value={days}
                            onChange={(e) => {
                              setDays(e.target.value);
                            }}
                          />
                        </InputGroup>

                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>Offer link</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter offer link"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            value={productLink}
                            onChange={(e) => {
                              setProductLink(e.target.value);
                            }}
                          />
                        </InputGroup>
                      </>
                    ) : type == 'Product' ? (
                      <>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>Seller name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter seller name"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            value={sellerName}
                            onChange={(e) => {
                              setSellerName(e.target.value);
                            }}
                          />
                        </InputGroup>

                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>Product name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter product name"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            value={productName}
                            onChange={(e) => {
                              setProductName(e.target.value);
                            }}
                          />
                        </InputGroup>

                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>Product link</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter offer link"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            value={productLink}
                            onChange={(e) => {
                              setProductLink(e.target.value);
                            }}
                          />
                        </InputGroup>
                      </>
                    ) : type == 'Course' ? (
                      <>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>Seller name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter seller name"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            value={sellerName}
                            onChange={(e) => {
                              setSellerName(e.target.value);
                            }}
                          />
                        </InputGroup>

                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>Course name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter course name"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            value={productName}
                            onChange={(e) => {
                              setProductName(e.target.value);
                            }}
                          />
                        </InputGroup>

                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>Course link</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter course link"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            value={productLink}
                            onChange={(e) => {
                              setProductLink(e.target.value);
                            }}
                          />
                        </InputGroup>
                      </>
                    ) : type == 'customer_enquiry' ? (
                      <>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>Seller name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter seller name"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            value={sellerName}
                            onChange={(e) => {
                              setSellerName(e.target.value);
                            }}
                          />
                        </InputGroup>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>Company name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter company name"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            value={companyName}
                            onChange={(e) => {
                              setCompanyName(e.target.value);
                            }}
                          />
                        </InputGroup>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>
                            {'Promotion text'}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="ex: 20% discount"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            value={promotionText}
                            onChange={(e) => {
                              setPromotionText(e.target.value);
                            }}
                          />
                        </InputGroup>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>
                            {'Offer valid till'}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="ex: 24th April"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            value={daystill}
                            onChange={(e) => {
                              setDaystill(e.target.value);
                            }}
                          />
                        </InputGroup>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>Offer link</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter offer link"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            value={productLink}
                            onChange={(e) => {
                              setProductLink(e.target.value);
                            }}
                          />
                        </InputGroup>
                      </>
                    ) : (
                      type == 'renewal_remainder' && (
                        <>
                          <InputGroup className="mb-2">
                            <Form.Label style={{ ...Styles.TxtHeading2 }}>
                              {'Enter reminder description'}
                            </Form.Label>
                            <span
                              className="ms-auto"
                              style={{
                                color: renewalText.length > 640 ? colors.error04 : colors.greys04,
                                fontSize: 12,
                                fontWeight: 400,
                                padding: 0,
                                margin: 0,
                              }}
                            >
                              {`${renewalText.length}/640`}
                            </span>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              placeholder="ex: your membership is up for renewal by 27th April "
                              className="w-100 btncustom"
                              style={{ borderRadius: 8 }}
                              isInvalid={renewalText.length >= 640}
                              maxLength={640}
                              value={renewalText}
                              onChange={(e) => {
                                setRenewalText(e.target.value);
                              }}
                            />
                          </InputGroup>
                          <InputGroup className="mb-2">
                            <Form.Label style={{ ...Styles.TxtHeading2 }}>
                              {'Enter callback number'}
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter here"
                              className="w-100 btncustom"
                              style={{ borderRadius: 8 }}
                              value={Rnumber}
                              onChange={(e) => {
                                setRNumber(e.target.value);
                              }}
                            />
                          </InputGroup>
                          <InputGroup className="mb-2">
                            <Form.Label style={{ ...Styles.TxtHeading2 }}>
                              {'Enter company/sender name'}
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter here"
                              className="w-100 btncustom"
                              style={{ borderRadius: 8 }}
                              value={companyName}
                              onChange={(e) => {
                                setCompanyName(e.target.value);
                              }}
                            />
                          </InputGroup>
                          <InputGroup className="mb-2">
                            <Form.Label style={{ ...Styles.TxtHeading2 }}>
                              {'Website Link'}
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter link here"
                              className="w-100 btncustom"
                              style={{ borderRadius: 8 }}
                              value={productLink}
                              onChange={(e) => {
                                setProductLink(e.target.value);
                              }}
                            />
                          </InputGroup>
                        </>
                      )
                    )}

                    <InputGroup className="mb-2">
                      <Form.Label style={{ ...Styles.TxtHeading2 }}>Upload image</Form.Label>

                      <div
                        id="UploadImageButton"
                        className="d-flex flex-row justify-content-between align-items-center px-3"
                        style={{
                          width: '100%',
                          borderColor: '#CCCCCC',
                          borderWidth: 1,
                          borderStyle: 'solid',
                          borderRadius: 8,
                          cursor: 'pointer',
                        }}
                        onDragEnter={handleDragUpImage}
                        onDragExit={handleDragUpImage}
                        onDragOver={handleDragUpImage}
                        onDrop={(e) => {
                          eventsend('Upload_file_campaign_popup_clicked');
                          handleDropUpImage(e, 'UpImage');
                        }}
                        onClick={() => {
                          eventsend('Upload_file_campaign_popup_clicked');
                          if (!upimg64) {
                            upimgref.current.click();
                          }
                        }}
                      >
                        <div
                          className="d-flex flex-row justify-content-start align-items-center pe-2"
                          style={{ width: '60%' }}
                        >
                          <img
                            src={upimg64 ? getImagePath(upimg64) : images.ImgUpIcon}
                            style={{
                              height: 50,
                              width: 68,
                              objectFit: 'contain',
                              marginInlineEnd: 20,
                            }}
                          ></img>
                          {upimgname ? (
                            <>
                              <span
                                style={{
                                  fontSize: 14,
                                  fontWeight: 600,
                                  color: colors.black,
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  wordBreak: 'break-all',
                                }}
                              >
                                {upimgname}
                              </span>
                              {upimg64 && (
                                <img
                                  src={images.CDelete}
                                  style={{
                                    height: 24,
                                    width: 24,
                                    objectFit: 'contain',
                                    marginInlineStart: 8,
                                  }}
                                  onClick={() => {
                                    setupimgname('');
                                    setupimg64('');
                                  }}
                                ></img>
                              )}
                            </>
                          ) : (
                            <>
                              <span
                                style={{
                                  fontSize: 14,
                                  fontWeight: 600,
                                  color: colors.black,
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  wordBreak: 'break-all',
                                }}
                              >
                                {'Upload Image'}
                                <br />
                                {'Max size: 5MB'}
                                <br />
                                {'Allowed formats: PNG, JPEG'}
                              </span>
                            </>
                          )}
                        </div>
                        <div
                          className="d-flex flex-column justify-content-center align-items-center"
                          style={{ paddingBlockStart: 60, paddingBlockEnd: 20 }}
                        >
                          <div
                            className="d-flex flex-row justify-content-center align-items-center"
                            style={{
                              height: 32,
                              backgroundColor: colors.black,
                              borderRadius: 8,
                              width: 100,
                              cursor: 'pointer',
                            }}
                          >
                            <text
                              style={{
                                fontSize: 14,
                                fontWeight: 600,
                                color: colors.white01,
                                cursor: 'pointer',
                              }}
                            >
                              {'Browse files'}
                            </text>
                          </div>
                          <p
                            style={{
                              padding: 0,
                              margin: 0,
                              fontSize: 12,
                              fontWeight: 600,
                              color: colors.black,
                              textAlign: 'center',
                              marginBlockStart: 16,
                            }}
                          >
                            {'Max file size (5MB)'}
                            <br />
                            {'(350px x 350px)  to  (1500px x 1500px)'}
                          </p>
                        </div>
                      </div>
                    </InputGroup>

                    {type == 'thank_you_customer' ? (
                      <>
                        <p
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            color: colors.greys04,
                            width: '100%',
                            marginBlock: 16,
                          }}
                        >
                          {'Hello "customer name",'}
                          <br />
                          <br />
                          {'Thank you for'}
                        </p>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>{'Action'}</Form.Label>
                          <span
                            className="ms-auto"
                            style={{
                              color: ThankYou.length > 640 ? colors.error04 : colors.greys04,
                              fontSize: 12,
                              fontWeight: 400,
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            {`${ThankYou.length}/640`}
                          </span>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="ex: installing our app."
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            isInvalid={ThankYou.length >= 640}
                            maxLength={640}
                            value={ThankYou}
                            onChange={(e) => {
                              setThankYou(e.target.value);
                            }}
                          />
                        </InputGroup>
                        <p
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            color: colors.greys04,
                            width: '100%',
                            marginBlock: 16,
                          }}
                        >
                          {'Please find below'}
                        </p>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>
                            {'Supporting text'}
                          </Form.Label>
                          <span
                            className="ms-auto"
                            style={{
                              color: LinkDesc.length > 640 ? colors.error04 : colors.greys04,
                              fontSize: 12,
                              fontWeight: 400,
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            {`${LinkDesc.length}/640`}
                          </span>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="ex: the youtube link for the demo video."
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            isInvalid={LinkDesc.length >= 640}
                            maxLength={640}
                            value={LinkDesc}
                            onChange={(e) => {
                              setLinkDesc(e.target.value);
                            }}
                          />
                        </InputGroup>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>{'Link'}</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="ex: https://youtu.be/OqfnppSZdwA"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            value={Link}
                            onChange={(e) => {
                              setLink(e.target.value);
                            }}
                          />
                        </InputGroup>
                        <p
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            color: colors.greys04,
                            width: '100%',
                            marginBlock: 16,
                          }}
                        >
                          {'Below are some of the key features:'}
                        </p>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>{'Feature 1'}</Form.Label>
                          <span
                            className="ms-auto"
                            style={{
                              color: Feature1.length > 640 ? colors.error04 : colors.greys04,
                              fontSize: 12,
                              fontWeight: 400,
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            {`${Feature1.length}/640`}
                          </span>
                          <Form.Control
                            type="text"
                            placeholder="ex: WhatsApp Integration"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            maxLength={640}
                            value={Feature1}
                            onChange={(e) => {
                              setFeature1(e.target.value);
                            }}
                          />
                        </InputGroup>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>{'Feature 2'}</Form.Label>
                          <span
                            className="ms-auto"
                            style={{
                              color: Feature2.length > 640 ? colors.error04 : colors.greys04,
                              fontSize: 12,
                              fontWeight: 400,
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            {`${Feature2.length}/640`}
                          </span>
                          <Form.Control
                            type="text"
                            placeholder="ex: Email Integration"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            maxLength={640}
                            value={Feature2}
                            onChange={(e) => {
                              setFeature2(e.target.value);
                            }}
                          />
                        </InputGroup>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>{'Feature 3'}</Form.Label>
                          <span
                            className="ms-auto"
                            style={{
                              color: Feature3.length > 640 ? colors.error04 : colors.greys04,
                              fontSize: 12,
                              fontWeight: 400,
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            {`${Feature3.length}/640`}
                          </span>
                          <Form.Control
                            type="text"
                            placeholder="ex: Advanced analytics"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            maxLength={640}
                            value={Feature3}
                            onChange={(e) => {
                              setFeature3(e.target.value);
                            }}
                          />
                        </InputGroup>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>{'Feature 4'}</Form.Label>
                          <span
                            className="ms-auto"
                            style={{
                              color: Feature4.length > 640 ? colors.error04 : colors.greys04,
                              fontSize: 12,
                              fontWeight: 400,
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            {`${Feature4.length}/640`}
                          </span>
                          <Form.Control
                            type="text"
                            placeholder="ex: Link CTR tracking"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            maxLength={640}
                            value={Feature4}
                            onChange={(e) => {
                              setFeature4(e.target.value);
                            }}
                          />
                        </InputGroup>
                        <p
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            color: colors.greys04,
                            width: '100%',
                            marginBlock: 16,
                          }}
                        >
                          {'If you have any questions, please feel free to reach out to us.'}
                          <br />
                          <br />
                          {'Best Regards'}
                        </p>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>Sender name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter sender name"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            value={sellerName}
                            onChange={(e) => {
                              setSellerName(e.target.value);
                            }}
                          />
                        </InputGroup>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>Company name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter company name"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            value={companyName}
                            onChange={(e) => {
                              setCompanyName(e.target.value);
                            }}
                          />
                        </InputGroup>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>
                            {'Call to Action (Add URL)'}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter url here"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            value={productLink}
                            onChange={(e) => {
                              setProductLink(e.target.value);
                            }}
                          />
                        </InputGroup>
                      </>
                    ) : type == 'share_info' ? (
                      <>
                        <p
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            color: colors.greys04,
                            width: '100%',
                            marginBlock: 16,
                          }}
                        >
                          {'Hey "customer name",'}
                          <br />
                          <br />
                          {'Wish you a very'}
                        </p>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>{'Greeting'}</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="ex: Good Morning."
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            value={Greeting}
                            onChange={(e) => {
                              setGreeting(e.target.value);
                            }}
                          />
                        </InputGroup>
                        <p
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            color: colors.greys04,
                            width: '100%',
                            marginBlock: 16,
                          }}
                        >
                          {"Here's a "}
                        </p>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>
                            {'Type of message'}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="ex: tip, headline, thought, etc "
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            value={InfoType}
                            onChange={(e) => {
                              setInfoType(e.target.value);
                            }}
                          />
                        </InputGroup>
                        <p
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            color: colors.greys04,
                            width: '100%',
                            marginBlock: 16,
                          }}
                        >
                          {'of the day we wanted to share with you.'}
                        </p>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>{'Add message'}</Form.Label>
                          <span
                            className="ms-auto"
                            style={{
                              color: Info.length > 640 ? colors.error04 : colors.greys04,
                              fontSize: 12,
                              fontWeight: 400,
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            {`${Info.length}/640`}
                          </span>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="ex: When sending emails, make sure to add a prominent unsubscribe button ... "
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            isInvalid={Info.length >= 640}
                            maxLength={640}
                            value={Info}
                            onChange={(e) => {
                              setInfo(e.target.value);
                            }}
                          />
                        </InputGroup>
                        <p
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            color: colors.greys04,
                            width: '100%',
                            marginBlock: 16,
                          }}
                        >
                          {'In your journey to be an avid'}
                        </p>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>
                            {'Supporting message'}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="ex: marketer"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            value={Profession}
                            onChange={(e) => {
                              setProfession(e.target.value);
                            }}
                          />
                        </InputGroup>
                        <p
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            color: colors.greys04,
                            width: '100%',
                            marginBlock: 16,
                          }}
                        >
                          {'we are always there for you.'}
                        </p>
                        <p
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            color: colors.greys04,
                            width: '100%',
                            marginBlock: 16,
                          }}
                        >
                          {
                            'If you are interested in knowing more about how we can help, click the link below.'
                          }
                        </p>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>
                            {'Call to Action (Add URL)'}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter url here"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            value={productLink}
                            onChange={(e) => {
                              setProductLink(e.target.value);
                            }}
                          />
                        </InputGroup>
                      </>
                    ) : type == 'additional_discount_offer' ? (
                      <>
                        <p
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            color: colors.greys04,
                            width: '100%',
                            marginBlock: 16,
                          }}
                        >
                          {'Dearest'}
                        </p>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>{'Greeting'}</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="ex: coding superstarts"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            value={Greeting}
                            onChange={(e) => {
                              setGreeting(e.target.value);
                            }}
                          />
                        </InputGroup>
                        <p
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            color: colors.greys04,
                            width: '100%',
                            marginBlock: 16,
                          }}
                        >
                          {'These are Special times and they call for Special needs.'}
                          <br />
                          <br />
                          {'At'}
                        </p>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>
                            {'Business name'}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="ex: cheerio"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            value={companyName}
                            onChange={(e) => {
                              setCompanyName(e.target.value);
                            }}
                          />
                        </InputGroup>
                        <p
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            color: colors.greys04,
                            width: '100%',
                            marginBlock: 16,
                          }}
                        >
                          {', we would like you to pamper yourself this '}
                        </p>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>{'Occasion'}</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="ex: Diwali"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            value={occasion}
                            onChange={(e) => {
                              setOccasion(e.target.value);
                            }}
                          />
                        </InputGroup>
                        <p
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            color: colors.greys04,
                            width: '100%',
                            marginBlock: 16,
                          }}
                        >
                          {'Shop now and get additional '}
                        </p>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>
                            {'Promotion text'}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="ex: 5% off"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            value={promotionText}
                            onChange={(e) => {
                              setPromotionText(e.target.value);
                            }}
                          />
                        </InputGroup>
                        <p
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            color: colors.greys04,
                            width: '100%',
                            marginBlock: 16,
                          }}
                        >
                          {'You can be rest assured that'}
                        </p>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>{'Feature'}</Form.Label>
                          <span
                            className="ms-auto"
                            style={{
                              color: Feature1.length > 640 ? colors.error04 : colors.greys04,
                              fontSize: 12,
                              fontWeight: 400,
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            {`${Feature1.length}/640`}
                          </span>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="ex: our documentation and support is unmatched"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            isInvalid={Feature1.length >= 640}
                            maxLength={640}
                            value={Feature1}
                            onChange={(e) => {
                              setFeature1(e.target.value);
                            }}
                          />
                        </InputGroup>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>
                            {'Call to Action (Add URL)'}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter url here"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            value={productLink}
                            onChange={(e) => {
                              setProductLink(e.target.value);
                            }}
                          />
                        </InputGroup>
                      </>
                    ) : type == 'customer_win_back' ? (
                      <>
                        <p
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            color: colors.greys04,
                            width: '100%',
                            marginBlock: 16,
                          }}
                        >
                          {'Congratulations "customer name"'}
                          <br />
                          <br />
                          {'We have a '}
                        </p>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>
                            {'Promotion text'}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="ex: surprise"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            value={promotionText}
                            onChange={(e) => {
                              setPromotionText(e.target.value);
                            }}
                          />
                        </InputGroup>
                        <p
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            color: colors.greys04,
                            width: '100%',
                            marginBlock: 16,
                          }}
                        >
                          {'in store for you!'}
                          <br />
                          <br />
                          {'We have been missing you on '}
                        </p>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>
                            {'Business name'}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="ex: cheerio web app"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            value={companyName}
                            onChange={(e) => {
                              setCompanyName(e.target.value);
                            }}
                          />
                        </InputGroup>
                        <p
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            color: colors.greys04,
                            width: '100%',
                            marginBlock: 16,
                          }}
                        >
                          {"Why don't you check out the "}
                        </p>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>{'Feature'}</Form.Label>
                          <span
                            className="ms-auto"
                            style={{
                              color: Feature1.length > 640 ? colors.error04 : colors.greys04,
                              fontSize: 12,
                              fontWeight: 400,
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            {`${Feature1.length}/640`}
                          </span>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="ex: chatbot we just released"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            isInvalid={Feature1.length >= 640}
                            maxLength={640}
                            value={Feature1}
                            onChange={(e) => {
                              setFeature1(e.target.value);
                            }}
                          />
                        </InputGroup>
                        <p
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            color: colors.greys04,
                            width: '100%',
                            marginBlock: 16,
                          }}
                        >
                          {"In fact, here's a present for you, use the code "}
                        </p>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>
                            {'Discount Code'}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="ex: CH500"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            value={discountCode}
                            onChange={(e) => {
                              setDiscountCode(e.target.value);
                            }}
                          />
                        </InputGroup>
                        <p
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            color: colors.greys04,
                            width: '100%',
                            marginBlock: 16,
                          }}
                        >
                          {'We will be waiting for you! '}
                          <br />
                          <br />
                          {'Hurry now, limited time offer!'}
                        </p>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>
                            {'Call to Action (Add URL)'}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter url here"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            value={productLink}
                            onChange={(e) => {
                              setProductLink(e.target.value);
                            }}
                          />
                        </InputGroup>
                      </>
                    ) : type == 'repeatcustomer_offer' ? (
                      <>
                        <p
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            color: colors.greys04,
                            width: '100%',
                            marginBlock: 16,
                          }}
                        >
                          {'Hi "customer name",'}
                          <br />
                          <br />
                          {'Welcome to the '}
                        </p>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>
                            {'Business name'}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="ex: cheerio family"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            value={companyName}
                            onChange={(e) => {
                              setCompanyName(e.target.value);
                            }}
                          />
                        </InputGroup>
                        <p
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            color: colors.greys04,
                            width: '100%',
                            marginBlock: 16,
                          }}
                        >
                          {'Thank you for '}
                        </p>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>
                            {'Thanking action'}
                          </Form.Label>
                          <span
                            className="ms-auto"
                            style={{
                              color: ThankYou.length > 640 ? colors.error04 : colors.greys04,
                              fontSize: 12,
                              fontWeight: 400,
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            {`${ThankYou.length}/640`}
                          </span>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="ex: signing up for our free trial"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            isInvalid={ThankYou.length >= 640}
                            maxLength={640}
                            value={ThankYou}
                            onChange={(e) => {
                              setThankYou(e.target.value);
                            }}
                          />
                        </InputGroup>
                        <p
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            color: colors.greys04,
                            width: '100%',
                            marginBlock: 16,
                          }}
                        >
                          {"It's time to fill those carts again with "}
                        </p>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>{'Feature'}</Form.Label>
                          <span
                            className="ms-auto"
                            style={{
                              color: Feature1.length > 640 ? colors.error04 : colors.greys04,
                              fontSize: 12,
                              fontWeight: 400,
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            {`${Feature1.length}/640`}
                          </span>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="ex: our discounted licenses on messaging"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            isInvalid={Feature1.length >= 640}
                            maxLength={640}
                            value={Feature1}
                            onChange={(e) => {
                              setFeature1(e.target.value);
                            }}
                          />
                        </InputGroup>
                        <p
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            color: colors.greys04,
                            width: '100%',
                            marginBlock: 16,
                          }}
                        >
                          {'On the Special occasion of '}
                        </p>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>{'Occasion'}</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="ex: Mother's day"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            value={occasion}
                            onChange={(e) => {
                              setOccasion(e.target.value);
                            }}
                          />
                        </InputGroup>
                        <p
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            color: colors.greys04,
                            width: '100%',
                            marginBlock: 16,
                          }}
                        >
                          {', we are offering you a special discount of'}
                        </p>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>
                            {'Promotion text'}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="ex: 25%"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            value={promotionText}
                            onChange={(e) => {
                              setPromotionText(e.target.value);
                            }}
                          />
                        </InputGroup>
                        <p
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            color: colors.greys04,
                            width: '100%',
                            marginBlock: 16,
                          }}
                        >
                          {'You can place your orders online on '}
                        </p>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>{'Link'}</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="ex: cheerio.in"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            value={Link}
                            onChange={(e) => {
                              setLink(e.target.value);
                            }}
                          />
                        </InputGroup>
                        <p
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            color: colors.greys04,
                            width: '100%',
                            marginBlock: 16,
                          }}
                        >
                          {'Stock up on your essential '}
                        </p>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>{'Feature 2'}</Form.Label>
                          <span
                            className="ms-auto"
                            style={{
                              color: Feature2.length > 640 ? colors.error04 : colors.greys04,
                              fontSize: 12,
                              fontWeight: 400,
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            {`${Feature2.length}/640`}
                          </span>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="ex: requirements for web or app messaging."
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            isInvalid={Feature2.length >= 640}
                            maxLength={640}
                            value={Feature2}
                            onChange={(e) => {
                              setFeature2(e.target.value);
                            }}
                          />
                        </InputGroup>
                        <p
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            color: colors.greys04,
                            width: '100%',
                            marginBlock: 16,
                          }}
                        >
                          {'Call us at'}
                        </p>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>
                            {'Contact detail'}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="ex: 9876598765"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            value={Rnumber}
                            onChange={(e) => {
                              setRNumber(e.target.value);
                            }}
                          />
                        </InputGroup>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>
                            {'Call to Action (Add URL)'}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter url here"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            value={productLink}
                            onChange={(e) => {
                              setProductLink(e.target.value);
                            }}
                          />
                        </InputGroup>
                      </>
                    ) : type == 'business_info_newsletter' ? (
                      <>
                        <p
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            color: colors.greys04,
                            width: '100%',
                            marginBlock: 16,
                          }}
                        >
                          {'Hi "customer name",'}
                          <br />
                          <br />
                          {'Want to switch to '}
                        </p>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>
                            {'Product name'}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="ex: hassle free CRM"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            value={productName}
                            onChange={(e) => {
                              setProductName(e.target.value);
                            }}
                          />
                        </InputGroup>
                        <p
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            color: colors.greys04,
                            width: '100%',
                            marginBlock: 16,
                          }}
                        >
                          {'? So what is stopping you?'}
                        </p>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>
                            {'Not working feature 1'}
                          </Form.Label>
                          <span
                            className="ms-auto"
                            style={{
                              color: NFeature1.length > 640 ? colors.error04 : colors.greys04,
                              fontSize: 12,
                              fontWeight: 400,
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            {`${NFeature1.length}/640`}
                          </span>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="ex: Not getting a reliable support"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            isInvalid={NFeature1.length >= 640}
                            maxLength={640}
                            value={NFeature1}
                            onChange={(e) => {
                              setNFeature1(e.target.value);
                            }}
                          />
                        </InputGroup>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>
                            {'Not working feature 2'}
                          </Form.Label>
                          <span
                            className="ms-auto"
                            style={{
                              color: NFeature2.length > 640 ? colors.error04 : colors.greys04,
                              fontSize: 12,
                              fontWeight: 400,
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            {`${NFeature2.length}/640`}
                          </span>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="ex: Too expensive"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            isInvalid={NFeature2.length >= 640}
                            maxLength={640}
                            value={NFeature2}
                            onChange={(e) => {
                              setNFeature2(e.target.value);
                            }}
                          />
                        </InputGroup>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>
                            {'Not working feature 3'}
                          </Form.Label>
                          <span
                            className="ms-auto"
                            style={{
                              color: NFeature3.length > 640 ? colors.error04 : colors.greys04,
                              fontSize: 12,
                              fontWeight: 400,
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            {`${NFeature3.length}/640`}
                          </span>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="ex: Don't have the time to compare alternatives"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            isInvalid={NFeature3.length >= 640}
                            maxLength={640}
                            value={NFeature3}
                            onChange={(e) => {
                              setNFeature3(e.target.value);
                            }}
                          />
                        </InputGroup>
                        <p
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            color: colors.greys04,
                            width: '100%',
                            marginBlock: 16,
                          }}
                        >
                          {'Welcome to '}
                        </p>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>
                            {'Business name'}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="ex: Cheerio"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            value={companyName}
                            onChange={(e) => {
                              setCompanyName(e.target.value);
                            }}
                          />
                        </InputGroup>
                        <p
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            color: colors.greys04,
                            width: '100%',
                            marginBlock: 16,
                          }}
                        >
                          {'where we have a solution to all your obstacles:'}
                        </p>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>{'Feature 1'}</Form.Label>
                          <span
                            className="ms-auto"
                            style={{
                              color: Feature1.length > 640 ? colors.error04 : colors.greys04,
                              fontSize: 12,
                              fontWeight: 400,
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            {`${Feature1.length}/640`}
                          </span>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="ex: We have over 24 hours support"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            isInvalid={Feature1.length >= 640}
                            maxLength={640}
                            value={Feature1}
                            onChange={(e) => {
                              setFeature1(e.target.value);
                            }}
                          />
                        </InputGroup>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>{'Feature 2'}</Form.Label>
                          <span
                            className="ms-auto"
                            style={{
                              color: Feature2.length > 640 ? colors.error04 : colors.greys04,
                              fontSize: 12,
                              fontWeight: 400,
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            {`${Feature2.length}/640`}
                          </span>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="ex: We have a special 10% discount for you"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            isInvalid={Feature2.length >= 640}
                            maxLength={640}
                            value={Feature2}
                            onChange={(e) => {
                              setFeature2(e.target.value);
                            }}
                          />
                        </InputGroup>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>{'Feature 3'}</Form.Label>
                          <span
                            className="ms-auto"
                            style={{
                              color: Feature3.length > 640 ? colors.error04 : colors.greys04,
                              fontSize: 12,
                              fontWeight: 400,
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            {`${Feature3.length}/640`}
                          </span>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="ex: We give feature by feature parity matrix"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            isInvalid={Feature3.length >= 640}
                            maxLength={640}
                            value={Feature3}
                            onChange={(e) => {
                              setFeature3(e.target.value);
                            }}
                          />
                        </InputGroup>
                        <p
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            color: colors.greys04,
                            width: '100%',
                            marginBlock: 16,
                          }}
                        >
                          {'You can place your order online on'}
                        </p>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>{'Link'}</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="ex: cheerio.in"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            value={Link}
                            onChange={(e) => {
                              setLink(e.target.value);
                            }}
                          />
                        </InputGroup>
                        <p
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            color: colors.greys04,
                            width: '100%',
                            marginBlock: 16,
                          }}
                        >
                          {'To know more, you can call us on '}
                        </p>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>
                            {'Contact detail'}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="ex: 9876598765"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            value={Rnumber}
                            onChange={(e) => {
                              setRNumber(e.target.value);
                            }}
                          />
                        </InputGroup>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>
                            {'Call to Action (Add URL)'}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter url here"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            value={productLink}
                            onChange={(e) => {
                              setProductLink(e.target.value);
                            }}
                          />
                        </InputGroup>
                      </>
                    ) : type == 'welcome_insta_message_2' ? (
                      <>
                        <p
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            color: colors.greys04,
                            width: '100%',
                            marginBlock: 16,
                          }}
                        >
                          {'Hello Nishant,'}
                          <br />
                          <br />
                          {'Welcome to '}
                        </p>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>
                            {'Business name'}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="ex: Cheerio"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            value={companyName}
                            onChange={(e) => {
                              setCompanyName(e.target.value);
                            }}
                          />
                        </InputGroup>
                        <p
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            color: colors.greys04,
                            width: '100%',
                            marginBlock: 16,
                          }}
                        >
                          {'We are glad you chose us for '}
                        </p>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>
                            {'Product name'}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="ex: CRM Marketing"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            value={productName}
                            onChange={(e) => {
                              setProductName(e.target.value);
                            }}
                          />
                        </InputGroup>
                        <p
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            color: colors.greys04,
                            width: '100%',
                            marginBlock: 16,
                          }}
                        >
                          {`Let's connect on Instagram for some fun`}
                        </p>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>
                            {'Instagram handle'}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="ex: https://www.instagram.com/cheeriosocial/"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            value={instaHandle}
                            onChange={(e) => {
                              setInstaHandle(e.target.value);
                            }}
                          />
                        </InputGroup>
                        <p
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            color: colors.greys04,
                            width: '100%',
                            marginBlock: 16,
                          }}
                        >
                          {`If you need anything, write to us at `}
                        </p>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>
                            {'Support Email'}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="ex: admin@cheeerio.in"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            value={supportEmail}
                            onChange={(e) => {
                              setSupportEmail(e.target.value);
                            }}
                          />
                        </InputGroup>
                        <p
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            color: colors.greys04,
                            width: '100%',
                            marginBlock: 16,
                          }}
                        >
                          {`Here's your `}
                        </p>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>{'Discount'}</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="ex: 20%"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            value={discountText}
                            onChange={(e) => {
                              setDiscountText(e.target.value);
                            }}
                          />
                        </InputGroup>
                        <p
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            color: colors.greys04,
                            width: '100%',
                            marginBlock: 16,
                          }}
                        >
                          {`discount code to shop any item on the website --`}
                        </p>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>{'Link'}</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="ex: https://www.cheerio.in"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            value={Link}
                            onChange={(e) => {
                              setLink(e.target.value);
                            }}
                          />
                        </InputGroup>
                        <p
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            color: colors.greys04,
                            width: '100%',
                            marginBlock: 16,
                          }}
                        >
                          {`⚡️ No minimum purchase is required.`}
                          <br />
                          {`⚡️ This discount code is valid for the next `}
                        </p>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>{'Duration'}</Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="ex: 2"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            value={duration}
                            onChange={(e) => {
                              setDuration(e.target.value);
                            }}
                          />
                        </InputGroup>
                        <p
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            color: colors.greys04,
                            width: '100%',
                            marginBlock: 16,
                          }}
                        >
                          {`months.`}
                          <br />
                          <br />
                          {`Call us at `}
                        </p>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>
                            {'Contact details'}
                          </Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="ex: 9876598765"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            value={Rnumber}
                            onChange={(e) => {
                              setRNumber(e.target.value);
                            }}
                          />
                        </InputGroup>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>
                            {'Call to Action (Add URL)'}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter url here"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            value={productLink}
                            onChange={(e) => {
                              setProductLink(e.target.value);
                            }}
                          />
                        </InputGroup>
                      </>
                    ) : type == 'loyal_customers_new' ? (
                      <>
                        <p
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            color: colors.greys04,
                            width: '100%',
                            marginBlock: 16,
                          }}
                        >
                          {'Hey Arth 🤙,'}
                          <br />
                          <br />
                          {'With more than '}
                        </p>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>
                            {'Quantity info'}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="ex: 500"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            value={Info}
                            onChange={(e) => {
                              setInfo(e.target.value);
                            }}
                          />
                        </InputGroup>
                        <p
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            color: colors.greys04,
                            width: '100%',
                            marginBlock: 16,
                          }}
                        >
                          {'units sold 🥳, our product '}
                        </p>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>
                            {'Product name'}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="ex: Cheerio WA"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            value={productName}
                            onChange={(e) => {
                              setProductName(e.target.value);
                            }}
                          />
                        </InputGroup>
                        <p
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            color: colors.greys04,
                            width: '100%',
                            marginBlock: 16,
                          }}
                        >
                          {
                            'is one of our best-seller🥇 and an ABSOLUTE favorite among our customers!'
                          }
                          <br />
                          <br />
                          {`And that's why we want to give a chance to everyone to experience it. This month, we are giving`}
                        </p>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>
                            {'Discount (in %)'}
                          </Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="ex: 20"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            value={discount}
                            onChange={(e) => {
                              setDiscount(e.target.value);
                            }}
                          />
                        </InputGroup>
                        <p
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            color: colors.greys04,
                            width: '100%',
                            marginBlock: 16,
                          }}
                        >
                          {'% off on "product name".'}
                          <br />
                          {`Apply the code `}
                        </p>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>
                            {'Discount code'}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="ex: CHEER20"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            value={discountCode}
                            onChange={(e) => {
                              setDiscountCode(e.target.value);
                            }}
                          />
                        </InputGroup>
                        <p
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            color: colors.greys04,
                            width: '100%',
                            marginBlock: 16,
                          }}
                        >
                          {'on checkout.'}
                          <br />
                          <br />
                          {`✅ No minimum order required.`}
                          <br />
                          {`✅ Cash on delivery available.`}
                          <br />
                          <br />
                          {`Call us at `}
                        </p>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>
                            {'Contact details'}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="ex: 9876598765"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            value={Rnumber}
                            onChange={(e) => {
                              setRNumber(e.target.value);
                            }}
                          />
                        </InputGroup>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>
                            {'Call to Action (Add URL)'}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter url here"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            value={productLink}
                            onChange={(e) => {
                              setProductLink(e.target.value);
                            }}
                          />
                        </InputGroup>
                      </>
                    ) : type == 'discount_all_products_new' ? (
                      <>
                        <p
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            color: colors.greys04,
                            width: '100%',
                            marginBlock: 16,
                          }}
                        >
                          {'Hey Arth 😊,'}
                          <br />
                          <br />
                          {'Thankyou for recently buying'}
                        </p>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>
                            {'Business name'}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="ex: Cheerio"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            value={companyName}
                            onChange={(e) => {
                              setCompanyName(e.target.value);
                            }}
                          />
                        </InputGroup>
                        <p
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            color: colors.greys04,
                            width: '100%',
                            marginBlock: 16,
                          }}
                        >
                          {'Products.😍'}
                          <br />
                          <br />
                          {"To show our appreciation🥳, we've got a special offer for you FLAT "}
                        </p>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>{'Discount'}</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="ex: 20%"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            value={discountText}
                            onChange={(e) => {
                              setDiscountText(e.target.value);
                            }}
                          />
                        </InputGroup>
                        <p
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            color: colors.greys04,
                            width: '100%',
                            marginBlock: 16,
                          }}
                        >
                          {'discount on all products.'}
                          <br />
                          <br />
                          {'Discount Code : '}
                        </p>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>
                            {'Discount code'}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="ex: CHEER20"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            value={discountCode}
                            onChange={(e) => {
                              setDiscountCode(e.target.value);
                            }}
                          />
                        </InputGroup>
                        <p
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            color: colors.greys04,
                            width: '100%',
                            marginBlock: 16,
                          }}
                        >
                          {'Shop Now.'}
                        </p>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>
                            {'Call to Action (Add URL)'}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter url here"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            value={productLink}
                            onChange={(e) => {
                              setProductLink(e.target.value);
                            }}
                          />
                        </InputGroup>
                      </>
                    ) : type == 'do_you_know' ? (
                      <>
                        <p
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            color: colors.greys04,
                            width: '100%',
                            marginBlock: 16,
                          }}
                        >
                          {'Hi Nishant,'}
                          <br />
                          <br />
                          {'Do you know that '}
                        </p>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>{'Message'}</Form.Label>
                          <span
                            className="ms-auto"
                            style={{
                              color: productName.length > 640 ? colors.error04 : colors.greys04,
                              fontSize: 12,
                              fontWeight: 400,
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            {`${productName.length}/640`}
                          </span>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="ex: knowing your Prakriti helps you in the following ways:"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            isInvalid={productName.length >= 640}
                            maxLength={640}
                            value={productName}
                            onChange={(e) => {
                              setProductName(e.target.value);
                            }}
                          />
                        </InputGroup>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>{'Feature 1'}</Form.Label>
                          <span
                            className="ms-auto"
                            style={{
                              color: Feature1.length > 640 ? colors.error04 : colors.greys04,
                              fontSize: 12,
                              fontWeight: 400,
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            {`${Feature1.length}/640`}
                          </span>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="ex: 1. Get a Personalized treatment plan for your health issues"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            isInvalid={Feature1.length >= 640}
                            maxLength={640}
                            value={Feature1}
                            onChange={(e) => {
                              setFeature1(e.target.value);
                            }}
                          />
                        </InputGroup>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>{'Feature 2'}</Form.Label>
                          <span
                            className="ms-auto"
                            style={{
                              color: Feature2.length > 640 ? colors.error04 : colors.greys04,
                              fontSize: 12,
                              fontWeight: 400,
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            {`${Feature2.length}/640`}
                          </span>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="ex: 2. Prevent any disease from occuring"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            maxLength={640}
                            isInvalid={Feature2.length >= 640}
                            value={Feature2}
                            onChange={(e) => {
                              setFeature2(e.target.value);
                            }}
                          />
                        </InputGroup>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>{'Feature 3'}</Form.Label>
                          <span
                            className="ms-auto"
                            style={{
                              color: Feature3.length > 640 ? colors.error04 : colors.greys04,
                              fontSize: 12,
                              fontWeight: 400,
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            {`${Feature3.length}/640`}
                          </span>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="ex: 3. Improved quality of life"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            isInvalid={Feature3.length >= 640}
                            maxLength={640}
                            value={Feature3}
                            onChange={(e) => {
                              setFeature3(e.target.value);
                            }}
                          />
                        </InputGroup>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>{'Feature 4'}</Form.Label>
                          <span
                            className="ms-auto"
                            style={{
                              color: Feature4.length > 640 ? colors.error04 : colors.greys04,
                              fontSize: 12,
                              fontWeight: 400,
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            {`${Feature4.length}/640`}
                          </span>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="ex: 4. Better management of chronic conditions"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            isInvalid={Feature4.length >= 640}
                            maxLength={640}
                            value={Feature4}
                            onChange={(e) => {
                              setFeature4(e.target.value);
                            }}
                          />
                        </InputGroup>
                        <p
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            color: colors.greys04,
                            width: '100%',
                            marginBlock: 16,
                          }}
                        >
                          {'You can '}
                        </p>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>
                            {'Info message'}
                          </Form.Label>
                          <span
                            className="ms-auto"
                            style={{
                              color: Info.length > 640 ? colors.error04 : colors.greys04,
                              fontSize: 12,
                              fontWeight: 400,
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            {`${Info.length}/640`}
                          </span>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="ex: read about the 3 types of Prakriti on our Blog to know what they are and ..."
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            isInvalid={Info.length >= 640}
                            maxLength={640}
                            value={Info}
                            onChange={(e) => {
                              setInfo(e.target.value);
                            }}
                          />
                        </InputGroup>
                        <p
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            color: colors.greys04,
                            width: '100%',
                            marginBlock: 16,
                          }}
                        >
                          {'You can also call us at '}
                        </p>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>
                            {'Contact details'}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="ex: 9876598765"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            value={Rnumber}
                            onChange={(e) => {
                              setRNumber(e.target.value);
                            }}
                          />
                        </InputGroup>
                        <p
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            color: colors.greys04,
                            width: '100%',
                            marginBlock: 16,
                          }}
                        >
                          {'for any further information'}
                        </p>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>
                            {'Call to Action (Add URL)'}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter url here"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            value={productLink}
                            onChange={(e) => {
                              setProductLink(e.target.value);
                            }}
                          />
                        </InputGroup>
                      </>
                    ) : type == 'maintainance_update' ? (
                      <>
                        <p
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            color: colors.greys04,
                            width: '100%',
                            marginBlock: 16,
                          }}
                        >
                          {'Hello Nikhil,'}
                          <br />
                          <br />
                          {'We are '}
                        </p>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>
                            {'Maintenance message'}
                          </Form.Label>
                          <span
                            className="ms-auto"
                            style={{
                              color: Info.length > 640 ? colors.error04 : colors.greys04,
                              fontSize: 12,
                              fontWeight: 400,
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            {`${Info.length}/640`}
                          </span>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="ex: looking into the issue that came to our server and will try to fix it as soon as possible."
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            isInvalid={Info.length >= 640}
                            maxLength={640}
                            value={Info}
                            onChange={(e) => {
                              setInfo(e.target.value);
                            }}
                          />
                        </InputGroup>
                        <p
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            color: colors.greys04,
                            width: '100%',
                            marginBlock: 16,
                          }}
                        >
                          {'For more information feel free to reach to us.'}
                          <br />
                          <br />
                          {'Thanks'}
                        </p>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>
                            {'Business name'}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="ex: Team Eyecreative"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            value={companyName}
                            onChange={(e) => {
                              setCompanyName(e.target.value);
                            }}
                          />
                        </InputGroup>
                      </>
                    ) : type == 'new_product_service_marathi' ? (
                      <>
                        <p
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            color: colors.greys04,
                            width: '100%',
                            marginBlock: 16,
                          }}
                        >
                          {'नमस्ते निखिल,'}
                          <br />
                          <br />
                          {'आम्ही '}
                        </p>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>{'Message'}</Form.Label>
                          <span
                            className="ms-auto"
                            style={{
                              color: productName.length > 640 ? colors.error04 : colors.greys04,
                              fontSize: 12,
                              fontWeight: 400,
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            {`${productName.length}/640`}
                          </span>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="ex: आपल्यासाठी घेऊन आलो आहोत एक नवीन उत्पादन - रबर मॅट्स. ज्या आपण आपल्या रोजच्या वापरासाठी उपयोगात आणू शकतात."
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            isInvalid={productName.length >= 640}
                            maxLength={640}
                            value={productName}
                            onChange={(e) => {
                              setProductName(e.target.value);
                            }}
                          />
                        </InputGroup>
                        <p
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            color: colors.greys04,
                            width: '100%',
                            marginBlock: 16,
                          }}
                        >
                          {
                            'अधिक माहिती साठी आपण खाली दिलेल्या क्रमांकावर संपर्क करू शकतात अथवा या मेसेजला रिप्लाय देऊन माहिती मिळवू शकतात.'
                          }
                          <br />
                          <br />
                          {'धन्यवाद'}
                        </p>
                      </>
                    ) : type == 'reply_multiple' ? (
                      <>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>{'Greeting'}</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="ex: Good Evening"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            value={sellerName}
                            onChange={(e) => {
                              setSellerName(e.target.value);
                            }}
                          />
                        </InputGroup>
                        <p
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            color: colors.greys04,
                            width: '100%',
                            marginBlock: 16,
                          }}
                        >
                          {'You have to try'}
                          <br />
                          <br />
                        </p>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>
                            {'Product/Service'}
                          </Form.Label>
                          <span
                            className="ms-auto"
                            style={{
                              color: productName.length > 640 ? colors.error04 : colors.greys04,
                              fontSize: 12,
                              fontWeight: 400,
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            {`${productName.length}/640`}
                          </span>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="ex: the new chatbot we have introduced"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            isInvalid={productName.length >= 640}
                            maxLength={640}
                            value={productName}
                            onChange={(e) => {
                              setProductName(e.target.value);
                            }}
                          />
                        </InputGroup>
                        <p
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            color: colors.greys04,
                            width: '100%',
                            marginBlock: 16,
                          }}
                        >
                          {'Why?'}
                          <br />
                          <br />
                        </p>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>{'Feature 1'}</Form.Label>
                          <span
                            className="ms-auto"
                            style={{
                              color: Feature1.length > 640 ? colors.error04 : colors.greys04,
                              fontSize: 12,
                              fontWeight: 400,
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            {`${Feature1.length}/640`}
                          </span>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="ex: It is made with NLP"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            isInvalid={Feature1.length >= 640}
                            maxLength={640}
                            value={Feature1}
                            onChange={(e) => {
                              setFeature1(e.target.value);
                            }}
                          />
                        </InputGroup>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>{'Feature 2'}</Form.Label>
                          <span
                            className="ms-auto"
                            style={{
                              color: Feature2.length > 640 ? colors.error04 : colors.greys04,
                              fontSize: 12,
                              fontWeight: 400,
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            {`${Feature2.length}/640`}
                          </span>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="ex: It is highly Customizable acc to business demand"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            isInvalid={Feature2.length >= 640}
                            maxLength={640}
                            value={Feature2}
                            onChange={(e) => {
                              setFeature2(e.target.value);
                            }}
                          />
                        </InputGroup>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>{'Feature 3'}</Form.Label>
                          <span
                            className="ms-auto"
                            style={{
                              color: Feature3.length > 640 ? colors.error04 : colors.greys04,
                              fontSize: 12,
                              fontWeight: 400,
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            {`${Feature3.length}/640`}
                          </span>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="ex: Can be trained in 2 minutes"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            isInvalid={Feature3.length >= 640}
                            maxLength={640}
                            value={Feature3}
                            onChange={(e) => {
                              setFeature3(e.target.value);
                            }}
                          />
                        </InputGroup>
                        <p
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            color: colors.greys04,
                            width: '100%',
                            marginBlock: 16,
                          }}
                        >
                          {'What more?'}
                          <br />
                          <br />
                        </p>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>{'USP 1'}</Form.Label>
                          <span
                            className="ms-auto"
                            style={{
                              color: promotionText.length > 640 ? colors.error04 : colors.greys04,
                              fontSize: 12,
                              fontWeight: 400,
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            {`${promotionText.length}/640`}
                          </span>
                          <Form.Control
                            type="text"
                            placeholder="ex: It is completely free"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            isInvalid={promotionText.length >= 640}
                            maxLength={640}
                            value={promotionText}
                            onChange={(e) => {
                              setPromotionText(e.target.value);
                            }}
                          />
                        </InputGroup>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>{'USP 2'}</Form.Label>
                          <span
                            className="ms-auto"
                            style={{
                              color: Feature4.length > 640 ? colors.error04 : colors.greys04,
                              fontSize: 12,
                              fontWeight: 400,
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            {`${Feature4.length}/640`}
                          </span>
                          <Form.Control
                            type="text"
                            placeholder="ex: It is great for operations and support use cases"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            maxLength={640}
                            value={Feature4}
                            onChange={(e) => {
                              setFeature4(e.target.value);
                            }}
                          />
                        </InputGroup>
                        <p
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            color: colors.greys04,
                            width: '100%',
                            marginBlock: 16,
                          }}
                        >
                          {'Sounds Interesting?'}
                          <br />
                          <br />
                          {'Reply NOW to this message and get a'}
                        </p>
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>{'Offering'}</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="ex: free setup today"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            value={offerName}
                            onChange={(e) => {
                              setOfferName(e.target.value);
                            }}
                          />
                        </InputGroup>
                      </>
                    ) : (
                      <></>
                    )}

                    {type !== 'customer_enquiry' &&
                      type !== 'renewal_remainder' &&
                      type !== 'thank_you_customer' &&
                      type !== 'share_info' &&
                      type !== 'additional_discount_offer' &&
                      type !== 'customer_win_back' &&
                      type !== 'repeatcustomer_offer' &&
                      type !== 'business_info_newsletter' &&
                      type !== 'welcome_insta_message_2' &&
                      type !== 'loyal_customers_new' &&
                      type !== 'discount_all_products_new' &&
                      type !== 'do_you_know' &&
                      type !== 'maintainance_update' &&
                      type !== 'new_product_service_marathi' &&
                      type !== 'reply_multiple' && (
                        <InputGroup className="mb-2">
                          <Form.Label style={{ ...Styles.TxtHeading2, width: '100%' }}>
                            Upload logo image
                          </Form.Label>

                          <div
                            className="d-flex flex-row justify-content-between align-items-center px-3"
                            id="UploadLogoImageButton"
                            style={{
                              width: '100%',
                              borderColor: '#CCCCCC',
                              borderWidth: 1,
                              borderStyle: 'solid',
                              borderRadius: 8,
                              cursor: 'pointer',
                            }}
                            onDragEnter={handleDragUpImage}
                            onDragExit={handleDragUpImage}
                            onDragOver={handleDragUpImage}
                            onDrop={(e) => {
                              eventsend('Upload_file_campaign_popup_clicked');
                              handleDropUpImage(e, 'LogoImage');
                            }}
                            onClick={() => {
                              eventsend('Upload_file_campaign_popup_clicked');
                              if (!logoimg64) {
                                logoimgref.current.click();
                              }
                            }}
                          >
                            <div
                              className="d-flex flex-row justify-content-start align-items-center pe-2"
                              style={{ width: '60%' }}
                            >
                              <img
                                src={logoimg64 ? getImagePath(logoimg64) : images.ImgUpIcon}
                                style={{
                                  height: 50,
                                  width: 68,
                                  objectFit: 'contain',
                                  marginInlineEnd: 20,
                                }}
                              ></img>
                              {logoimgname ? (
                                <>
                                  <span
                                    style={{
                                      fontSize: 14,
                                      fontWeight: 600,
                                      color: colors.black,
                                      textOverflow: 'ellipsis',
                                      whiteSpace: 'nowrap',
                                      overflow: 'hidden',
                                      wordBreak: 'break-all',
                                    }}
                                  >
                                    {logoimgname}
                                  </span>
                                  {logoimg64 && (
                                    <img
                                      src={images.CDelete}
                                      style={{
                                        height: 24,
                                        width: 24,
                                        objectFit: 'contain',
                                        marginInlineStart: 8,
                                      }}
                                      onClick={() => {
                                        setlogoimgname('');
                                        setlogoimg64('');
                                      }}
                                    ></img>
                                  )}
                                </>
                              ) : (
                                <>
                                  <span
                                    style={{
                                      fontSize: 14,
                                      fontWeight: 600,
                                      color: colors.black,
                                      textOverflow: 'ellipsis',
                                      whiteSpace: 'nowrap',
                                      overflow: 'hidden',
                                      wordBreak: 'break-all',
                                    }}
                                  >
                                    {'Upload Image'}
                                    <br />
                                    {'Max size: 5MB'}
                                    <br />
                                    {'Allowed formats: PNG, JPEG'}
                                  </span>
                                </>
                              )}
                            </div>
                            <div
                              className="d-flex flex-column justify-content-center align-items-center"
                              style={{ paddingBlockStart: 60, paddingBlockEnd: 20 }}
                            >
                              <div
                                className="d-flex flex-row justify-content-center align-items-center"
                                style={{
                                  height: 32,
                                  backgroundColor: colors.black,
                                  borderRadius: 8,
                                  width: 100,
                                  cursor: 'pointer',
                                }}
                              >
                                <p
                                  style={{
                                    margin: 0,
                                    padding: 0,
                                    fontSize: 14,
                                    fontWeight: 600,
                                    color: colors.white01,
                                    cursor: 'pointer',
                                  }}
                                >
                                  {'Browse files'}
                                </p>
                              </div>
                              <p
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  fontSize: 12,
                                  fontWeight: 600,
                                  color: colors.black,
                                  textAlign: 'center',
                                  marginBlockStart: 16,
                                }}
                              >
                                {'Max file size (5MB)'}
                                <br />
                                {'(350px x 350px)  to  (1500px x 1500px)'}
                              </p>
                            </div>
                          </div>
                        </InputGroup>
                      )}
                    <input
                      id="ImageUpload"
                      type="file"
                      style={{ display: 'none' }}
                      ref={upimgref}
                      accept=".jpeg, .png, .jpg"
                      onChange={(e) => {
                        handleimgupload(e);
                        eventsend('Upload_file_campaign_popup_clicked');
                      }}
                    />
                    <input
                      id="LogoImageUpload"
                      type="file"
                      style={{ display: 'none' }}
                      ref={logoimgref}
                      accept=".jpeg, .png, .jpg"
                      onChange={(e) => {
                        handlelogoimgupload(e);
                        eventsend('Upload_file_campaign_popup_clicked');
                      }}
                    />

                    {type !== 'customer_enquiry' &&
                      type !== 'renewal_remainder' &&
                      type !== 'thank_you_customer' &&
                      type !== 'share_info' &&
                      type !== 'additional_discount_offer' &&
                      type !== 'customer_win_back' &&
                      type !== 'repeatcustomer_offer' &&
                      type !== 'business_info_newsletter' &&
                      type !== 'welcome_insta_message_2' &&
                      type !== 'loyal_customers_new' &&
                      type !== 'discount_all_products_new' &&
                      type !== 'do_you_know' &&
                      type !== 'maintainance_update' &&
                      type !== 'new_product_service_marathi' &&
                      type !== 'reply_multiple' && (
                        <>
                          <div
                            className="w-100 my-3 d-flex flex-row justify-content-between align-items-center"
                            onClick={() => {
                              setOtherDetails(!otherDetails);
                            }}
                          >
                            <text style={{ ...Styles.TxtHeading }} className="">
                              {'Other details'}
                            </text>
                            <img
                              src={images.DownArrowTip}
                              style={{
                                height: 20,
                                width: 20,
                                objectFit: 'contain',
                                transform: otherDetails ? 'rotate(180deg)' : '',
                                cursor: 'pointer',
                              }}
                            ></img>
                          </div>
                          {otherDetails && (
                            <>
                              <text
                                style={{
                                  fontSize: 16,
                                  fontWeight: 600,
                                  color: colors.greys04,
                                  flexWrap: 'wrap',
                                }}
                                className="w-100 mt-2 mb-3"
                              >
                                {
                                  'You can add your social media links for email footer. This will take your customers to your social links'
                                }
                              </text>
                              <InputGroup className="my-2">
                                <Form.Label style={{ ...Styles.TxtHeading2 }}>
                                  Instagram handle
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Enter link"
                                  className="w-100 btncustom"
                                  style={{ borderRadius: 8 }}
                                  value={CILink}
                                  onChange={(e) => {
                                    setCILink(e.target.value);
                                  }}
                                />
                              </InputGroup>
                              <InputGroup className="mb-2">
                                <Form.Label style={{ ...Styles.TxtHeading2 }}>
                                  Twitter handle
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Enter link"
                                  className="w-100 btncustom"
                                  style={{ borderRadius: 8 }}
                                  value={CTLink}
                                  onChange={(e) => {
                                    setCTLink(e.target.value);
                                  }}
                                />
                              </InputGroup>
                              <InputGroup className="mb-2">
                                <Form.Label style={{ ...Styles.TxtHeading2 }}>
                                  LinkedIn handle
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Enter link"
                                  className="w-100 btncustom"
                                  style={{ borderRadius: 8 }}
                                  value={CLLink}
                                  onChange={(e) => {
                                    setCLLink(e.target.value);
                                  }}
                                />
                              </InputGroup>
                              <InputGroup className="mb-2">
                                <Form.Label style={{ ...Styles.TxtHeading2 }}>
                                  Facebook handle
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Enter link"
                                  className="w-100 btncustom"
                                  style={{ borderRadius: 8 }}
                                  value={CFLink}
                                  onChange={(e) => {
                                    setCFLink(e.target.value);
                                  }}
                                />
                              </InputGroup>
                            </>
                          )}
                        </>
                      )}
                  </Form>
                  <CheerioButton
                    borderStyle={{
                      marginBlock: 32,
                      borderColor: colors.primary03,
                      width: mobileWidth ? '80%' : '25%',
                      paddingBlock: 10,
                    }}
                    id="GeneratePreviewButton"
                    textStyle={{ fontSize: 14, fontWeight: 600, color: colors.white }}
                    btnText={'Generate Preview'}
                    backColor={colors.primary03}
                    onclick={() => {
                      console.log('generate preview clicked ...');

                      createTemplateAPIcall();
                    }}
                    disabled={validateData ? false : true}
                  />
                </div>
              </>
            ) : preview1 ? (
              <>
                <div
                  id="preview1"
                  className="d-flex flex-column justify-content-start align-items-center w-100 verythinscrollnew"
                  style={{ flex: 1, overflowY: 'scroll' }}
                >
                  <div></div>
                  <div
                    className="d-flex flex-row justify-content-between align-items-center w-100"
                    style={{ paddingInline: mobileWidth ? 6 : 60 }}
                  >
                    {type == 'customer_enquiry' ||
                      type == 'renewal_remainder' ||
                      type == 'thank_you_customer' ||
                      type == 'share_info' ||
                      type == 'additional_discount_offer' ||
                      type == 'repeatcustomer_offer' ||
                      type == 'customer_win_back' ||
                      type == 'business_info_newsletter' ||
                      type == 'welcome_insta_message_2' ||
                      type == 'loyal_customers_new' ||
                      type == 'discount_all_products_new' ||
                      type == 'do_you_know' ||
                      type == 'maintainance_update' ||
                      type == 'new_product_service_marathi' ||
                      type == 'reply_multiple' ? (
                      <>
                        <h2
                          style={{
                            padding: 0,
                            margin: 0,
                            // fontSize: 24,
                            fontWeight: 700,
                            color: colors.black,
                            marginBlock: 16,
                            textAlign: 'center',
                            width: '100%',
                          }}
                        >{`WhatsApp preview`}</h2>
                      </>
                    ) : (
                      <>
                        <img
                          src={images.DownArrowTip}
                          style={{
                            height: 24,
                            width: 24,
                            objectFit: 'contain',
                            transform: 'rotate(90deg)',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            if (selectedTab == 'Email') {
                              setSelectedTab('SMS');
                            } else if (selectedTab == 'WhatsApp') {
                              setSelectedTab('Email');
                            } else if (selectedTab == 'SMS') {
                              setSelectedTab('WhatsApp');
                            }
                          }}
                        ></img>
                        <h2
                          style={{
                            padding: 0,
                            margin: 0,
                            // fontSize: 24,
                            fontWeight: 700,
                            color: colors.black,
                            marginBlock: 16,
                          }}
                        >{`${selectedTab} preview`}</h2>
                        <img
                          src={images.DownArrowTip}
                          style={{
                            height: 24,
                            width: 24,
                            objectFit: 'contain',
                            transform: 'rotate(-90deg)',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            if (selectedTab == 'Email') {
                              setSelectedTab('WhatsApp');
                            } else if (selectedTab == 'WhatsApp') {
                              setSelectedTab('SMS');
                            } else if (selectedTab == 'SMS') {
                              setSelectedTab('Email');
                            }
                          }}
                        ></img>
                      </>
                    )}
                  </div>
                  <div
                    className="d-flex flex-row justify-content-center align-items-center"
                    style={{ width: mobileWidth ? '100%' : '60%' }}
                  >
                    {type == 'customer_enquiry' ? (
                      <Preview
                        FollowUp={true}
                        data={{ template_type: type, template_link: 'https://Rymnd.adyr/xvf4' }}
                        DemoData={{ thumbnail: images.FollowUpDemo }}
                        selectedTab={'WhatsApp'}
                      />
                    ) : type == 'renewal_remainder' ? (
                      <Preview
                        Reminder={true}
                        data={{ template_type: type, template_link: 'https://Rymnd.adyr/xvf4' }}
                        DemoData={{ thumbnail: images.FollowUpDemo }}
                        selectedTab={'WhatsApp'}
                      />
                    ) : type == 'thank_you_customer' ? (
                      <Preview
                        ThankYou={true}
                        data={{ template_type: type, template_link: 'https://Rymnd.adyr/xvf4' }}
                        DemoData={{ thumbnail: images.FollowUpDemo }}
                        selectedTab={'WhatsApp'}
                      />
                    ) : type == 'share_info' ? (
                      <Preview
                        ShareInfo={true}
                        data={{ template_type: type, template_link: 'https://Rymnd.adyr/xvf4' }}
                        DemoData={{ thumbnail: images.FollowUpDemo }}
                        selectedTab={'WhatsApp'}
                      />
                    ) : type == 'additional_discount_offer' ? (
                      <Preview
                        AddDiscount={true}
                        data={{ template_type: type, template_link: 'https://www.google.com/' }}
                        DemoData={{ thumbnail: images.CheerioWALogo }}
                        selectedTab={'WhatsApp'}
                      />
                    ) : type == 'repeatcustomer_offer' ? (
                      <Preview
                        RCOffer={true}
                        data={{ template_type: type, template_link: 'https://www.google.com/' }}
                        DemoData={{ thumbnail: images.CheerioWALogo }}
                        selectedTab={'WhatsApp'}
                      />
                    ) : type == 'repeatcustomer_offer2' ? (
                      <Preview
                        RCOffer={true}
                        data={{ template_type: type, template_link: 'https://www.google.com/' }}
                        DemoData={{ thumbnail: images.CheerioWALogo }}
                        selectedTab={'WhatsApp'}
                      />
                    ) : type == 'customer_win_back' ? (
                      <Preview
                        CWinback={true}
                        data={{ template_type: type, template_link: 'https://www.google.com/' }}
                        DemoData={{ thumbnail: images.CheerioWALogo }}
                        selectedTab={'WhatsApp'}
                      />
                    ) : type == 'business_info_newsletter' ? (
                      <Preview
                        BINews={true}
                        data={{ template_type: type, template_link: 'https://www.google.com/' }}
                        DemoData={{ thumbnail: images.CheerioWALogo }}
                        selectedTab={'WhatsApp'}
                      />
                    ) : type == 'welcome_insta_message_2' ? (
                      <Preview
                        WelcomeInsta={true}
                        data={{ template_type: type, template_link: 'https://www.google.com/' }}
                        DemoData={{ thumbnail: images.CheerioWALogo }}
                        selectedTab={'WhatsApp'}
                      />
                    ) : type == 'loyal_customers_new' ? (
                      <Preview
                        LoyalCustomer={true}
                        data={{ template_type: type, template_link: 'https://www.google.com/' }}
                        DemoData={{ thumbnail: images.CheerioWALogo }}
                        selectedTab={'WhatsApp'}
                      />
                    ) : type == 'discount_all_products_new' ? (
                      <Preview
                        ThankYou2={true}
                        data={{ template_type: type, template_link: 'https://www.google.com/' }}
                        DemoData={{ thumbnail: images.CheerioWALogo }}
                        selectedTab={'WhatsApp'}
                      />
                    ) : type == 'do_you_know' ? (
                      <Preview
                        DoYouKnow={true}
                        data={{ template_type: type, template_link: 'https://www.google.com/' }}
                        DemoData={{ thumbnail: images.DYKWA }}
                        selectedTab={'WhatsApp'}
                      />
                    ) : type == 'maintainance_update' ? (
                      <Preview
                        Maintenance={true}
                        data={{ template_type: type, template_link: 'https://www.google.com/' }}
                        DemoData={{ thumbnail: images.CAWAService }}
                        selectedTab={'WhatsApp'}
                      />
                    ) : type == 'new_product_service_marathi' ? (
                      <Preview
                        serviceMarathi={true}
                        data={{ template_type: type, template_link: 'https://www.google.com/' }}
                        DemoData={{ thumbnail: images.CAWAService }}
                        selectedTab={'WhatsApp'}
                      />
                    ) : type == 'reply_multiple' ? (
                      <Preview
                        ReplyMultiple={true}
                        data={{ template_type: type, template_link: 'https://www.google.com/' }}
                        DemoData={{ thumbnail: images.CAWAService }}
                        selectedTab={'WhatsApp'}
                      />
                    ) : (
                      <Preview
                        data={{
                          template_type: type,
                          creator_name: 'Raymond Adyar',
                          product_name: 'New Year Sale',
                          discounts: 12,
                          template_link: 'https://Rymnd.adyr/xvf4',
                        }}
                        DemoData={{
                          logo: images.Edemologo,
                          thumbnail: images.Edemothumbnail,
                          expired: 7,
                        }}
                        selectedTab={selectedTab}
                      />
                    )}
                  </div>
                </div>
              </>
            ) : customHTMLSuccess ? (
              <>
                <div
                  className="d-flex flex-column justify-content-center align-items-center w-100 verythinscrollnew"
                  style={{ flex: 1, overflowY: 'scroll' }}
                >
                  <img
                    src={images.NewSuccessGIF}
                    style={{ height: 300, width: 300, objectFit: 'cover' }}
                  ></img>
                  <p
                    style={{ marginBlock: 16, fontSize: 16, fontWeight: 400, color: colors.black }}
                  >
                    {'We are sending your Email to customers. This will take a few minutes.'}
                  </p>
                  <div className="d-flex flex-row justify-content-center align-items-center w-100">
                    <CheerioButton
                      borderStyle={{
                        marginBlock: 8,
                        width: '25%',
                        paddingBlock: 10,
                        marginInlineEnd: 16,
                        border: `1px solid ${colors.primary03}`,
                      }}
                      textStyle={{ fontSize: 14, fontWeight: 600, color: colors.primary03 }}
                      btnText={'Go to Analytics'}
                      backColor={colors.white}
                      onclick={() => {
                        navigate('/creator/analytics');
                        eventsend('close_campaign_pop_up_clicked');
                        setOpenANModal(false);
                      }}
                    />
                    <CheerioButton
                      borderStyle={{
                        marginBlock: 8,
                        borderColor: colors.primary03,
                        width: '25%',
                        paddingBlock: 10,
                        marginInlineStart: 16,
                      }}
                      textStyle={{ fontSize: 14, fontWeight: 600, color: colors.white }}
                      btnText={'Back to home'}
                      backColor={colors.primary03}
                      onclick={() => {
                        console.log('see contacts clicked . . .');
                        navigate('/creator');
                        eventsend('close_campaign_pop_up_clicked');
                        setOpenANModal(false);
                      }}
                    />
                  </div>
                </div>
              </>
            ) : openCustomHTML ? (
              scheduleEmailAnnouncement ? (
                <>
                  <div
                    className="d-flex flex-column justify-content-around align-items-center w-100 verythinscrollnew"
                    style={{ flex: 1, overflowY: 'scroll', paddingInline: 20 }}
                  >
                    <p
                      style={{
                        padding: 0,
                        margin: 0,
                        fontSize: 18,
                        fontWeight: 600,
                        color: colors.black,
                      }}
                    >
                      {`Schedule your custom campaign`}
                    </p>
                    <div className="d-flex flex-column justify-content-center align-items-center w-100">
                      <InputGroup className="mb-4 mt-2">
                        <Form.Label style={{ ...Styles.TxtHeading2 }}>{'Pick a date'}</Form.Label>
                        <Form.Control
                          type="date"
                          datatype=""
                          placeholder="DD-MM-YYYY"
                          className="w-100 btncustom"
                          style={{ borderRadius: 8 }}
                          min={new Date().toISOString().split('T')[0]}
                          value={SDate}
                          onChange={(e) => {
                            setSDate(e.target.value);
                          }}
                        />
                      </InputGroup>
                      <InputGroup className="mb-4 mt-2">
                        <Form.Label style={{ ...Styles.TxtHeading2 }}>{'Pick a time'}</Form.Label>
                        <Form.Control
                          type="time"
                          placeholder="HH:mm"
                          className="w-100 btncustom"
                          style={{ borderRadius: 8 }}
                          value={STime}
                          onChange={(e) => {
                            setSTime(e.target.value);
                          }}
                        />
                      </InputGroup>
                      <p
                        style={{
                          padding: 0,
                          margin: 0,
                          fontSize: 16,
                          fontWeight: 400,
                          color: colors.greys04,
                          textAlign: 'center',
                        }}
                      >
                        {/* {`Please note that our campaign is scheduled to run only at specific time intervals: `}
                    <span style={{fontWeight:600,color:colors.black}}>
                      {`HH:00, HH:15, HH:30, and HH:45.`}
                    </span><br/> */}
                        {
                          'Note: Campaign scheduler runs every 15 minutes. So, your campaign will execute at the next 15th minute interval.'
                        }
                      </p>
                    </div>

                    {/* <CheerioButton
                    borderStyle={{
                      marginBlock: 32,
                      borderColor: colors.primary03,
                      width: '25%',
                      paddingBlock: 10,
                    }}
                    textStyle={{ fontSize: 14, fontWeight: 600, color: colors.white }}
                    btnText={'Save'}
                    backColor={colors.primary03}
                    onclick={() => {
                      ISOFormatFunction();
                      console.log('date selected ===> ', SDate);
                      console.log('time selected ===> ', STime);
                      // createTemplateAPIcall()
                    }}
                    disabled={validateScheduler ? false : true}
                   /> */}
                  </div>
                </>
              ) : (
                <>
                  <div
                    className="d-flex flex-column justify-content-start align-items-center w-100 verythinscrollnew"
                    style={{ overflowY: 'scroll', height: 'auto' }}
                  >
                    <div className="d-flex flex-column justify-content-start align-items-center w-100">
                      {showAlert && (
                        <>
                          <Alert
                            variant="danger"
                            onClose={() => setShowAlert(false)}
                            dismissible
                            style={{ position: 'absolute', top: 20 }}
                          >
                            <Alert.Heading>Wrong file format uploaded</Alert.Heading>
                            <p>Please upload only .html files.</p>
                          </Alert>
                        </>
                      )}
                      <h2
                        style={{
                          padding: 0,
                          margin: 0,
                          fontSize: 24,
                          fontWeight: 700,
                          color: colors.black,
                          marginBlock: 20,
                        }}
                      >
                        {EmailBuilder ? EBTemplateName : 'Upload HTML'}
                      </h2>
                      {!EmailBuilder && (
                        <p style={{ fontSize: 16, fontWeight: 600, color: colors.black04 }}>
                          {'You can upload your custom HTML for Emails only'}
                        </p>
                      )}
                      {/* {(localdata?.isAppsumoUser || localdata?.isSesUser) && (
                        <>
                          <div
                            className="d-flex flex-row justify-content-between align-items-center w-75"
                            style={{
                              backgroundColor: colors.yellow03,
                              paddingBlock: 20,
                              paddingInline: 24,
                              borderRadius: 16,
                              marginBlock: 16,
                            }}
                          >
                            <img
                              src={images.InfoCircle}
                              style={{ height: 24, width: 24, objectFit: 'contain' }}
                            ></img>
                            <p
                              style={{
                                flex: 1,
                                margin: 0,
                                paddingBlock: 0,
                                fontSize: 16,
                                fontWeight: 400,
                                color: colors.black,
                                paddingInline: 16,
                                textAlign: 'justify',
                              }}
                            >
                              {
                                'We are experiencing temporarily email delivery failure. It’s expected to restore within 24 hours. We apologize for the inconvenience caused.'
                              }
                            </p>
                          </div>
                        </>
                      )} */}
                      <div className="w-75">
                        {!checksender && (
                          <div
                            className="w-100 px-5 py-2 mb-3 rounded"
                            style={{ backgroundColor: colors.primary01 }}
                          >
                            You have not created a verified sender ID yet. To start using email
                            channel,{' '}
                            <a
                              style={{
                                color: 'blue',
                                textDecoration: 'underline',
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                eventsend('Create_sender_ID_clicked');
                                setCreateSender(true);
                              }}
                            >
                              Create Sender ID.
                            </a>
                            {createSender && (
                              <SenderIdModal
                                openSenderIdModal={createSender}
                                setOpenSenderIdModal={setCreateSender}
                              ></SenderIdModal>
                            )}
                          </div>
                        )}
                      </div>
                      <Form className="w-75 px-3 py-2" style={{}}>
                        <InputGroup className="mb-3">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>
                            {'Name your Campaign'}
                          </Form.Label>
                          <span
                            className="ms-auto"
                            style={{
                              color: campaignName.length > 30 ? colors.error04 : colors.greys04,
                              fontSize: 12,
                              fontWeight: 400,
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            {`${campaignName.length}/30`}
                          </span>
                          <Form.Control
                            type="text"
                            placeholder="Enter campaign name"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            value={campaignName}
                            maxLength={30}
                            isInvalid={campaignName.length > 30}
                            onChange={(e) => {
                              setCampaignName(e.target.value);
                              eventsend('Name_campaign_campaign_pop_up_clicked');
                            }}
                          />
                        </InputGroup>
                        <InputGroup className="mb-3">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>
                            {'Add a Subject line'}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Add Email subject here"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            value={subjectC}
                            onChange={(e) => {
                              setSubjectC(e.target.value);
                            }}
                          />
                          <Form.Text style={{ fontSize: '12px', color: '#666666' }}>
                            Tip: You can now personalize your messages by adding the recipient's
                            name as a variable using %name%.
                          </Form.Text>
                        </InputGroup>
                        <InputGroup className="mb-3">
                          <Form.Label style={{ ...Styles.TxtHeading2 }}>
                            Add a Preview Text
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Add Email Preview Text here"
                            className="w-100 btncustom"
                            style={{ borderRadius: 8 }}
                            value={previewTextC}
                            onChange={(e) => {
                              setPreviewTextC(e.target.value);
                            }}
                          />
                          <Form.Text style={{ fontSize: '12px', color: '#666666' }}>
                            Tip: You can now personalize your messages by adding the recipient's
                            name as a variable using %name%.
                          </Form.Text>
                        </InputGroup>
                        {shareTo === 'Label' ? (
                          <>
                            <InputGroup className="mb-3">
                              <Form.Label style={{ ...Styles.TxtHeading2 }}>
                                {'Select a Label'}
                              </Form.Label>
                              <Typeahead
                                id="labelNameTypeahead"
                                labelKey="label_name"
                                options={['All contacts', ...labelList]}
                                placeholder="Choose label"
                                className="w-100 btncustom dropdown"
                                style={{
                                  borderRadius: 8,
                                  fontSize: 16,
                                  fontWeight: 400,
                                  color: '#6c757d',
                                }}
                                selected={selectedOption ? [selectedOption] : []}
                                onChange={(selected) => {
                                  if (selected?.length > 0 && selected[0] === 'All contacts') {
                                    setSelectedOption(selected[0]);
                                    setLabelName(selected[0]);
                                  } else if (selected?.length > 0) {
                                    setSelectedOption(selected[0].label_name);
                                    eventsend('Label_selected_drop_down_campaign_pop_up');
                                    setLabelName(selected[0]?.label_name);
                                  } else {
                                    setSelectedOption(null);
                                  }
                                }}
                                renderMenuItemChildren={(option) => {
                                  const displayText =
                                    option === 'All contacts'
                                      ? option
                                      : option.label_name.length > 30
                                        ? option.label_name.substring(0, 30) + '...'
                                        : option.label_name;

                                  return (
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={<Tooltip>{option.label_name}</Tooltip>}
                                    >
                                      <span>{displayText}</span>
                                    </OverlayTrigger>
                                  );
                                }}
                              />
                            </InputGroup>
                          </>
                        ) : (
                          <>
                            <div style={{ width: '90%', marginBlock: 16 }}>
                              <h4
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  fontSize: 16,
                                  fontWeight: 600,
                                  color: colors.black,
                                  marginBlock: 4,
                                }}
                              >
                                {'Enter Tags'}
                              </h4>
                              <div
                                className="btncustom d-flex flex-row justify-content-start align-items-center px-2 py-2"
                                style={{
                                  minHeight: 50,
                                  width: '100%',
                                  border: `1px solid ${colors.greys03}`,
                                  borderRadius: 8,
                                  marginBlock: 4,
                                  overflowX: 'auto',
                                  overflowY: 'hidden',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                {selectedTagsList &&
                                  selectedTagsList.map((item) => {
                                    return (
                                      <>
                                        <div
                                          key={`key${item._id}`}
                                          className="d-flex flex-row justify-content-between align-items-center"
                                          style={{
                                            paddingInline: 16,
                                            paddingBlock: 4,
                                            borderRadius: 4,
                                            backgroundColor: colors.borderwhite,
                                            marginInlineEnd: 16,
                                          }}
                                        >
                                          <p
                                            style={{
                                              padding: 0,
                                              margin: 0,
                                              fontSize: 14,
                                              fontWeight: 400,
                                              color: colors.greys04,
                                            }}
                                          >
                                            {item?.tag_name}
                                          </p>
                                          <img
                                            src={images.CMClose}
                                            style={{
                                              height: 14,
                                              width: 14,
                                              objectFit: 'contain',
                                              marginInlineStart: 8,
                                              cursor: 'pointer',
                                            }}
                                            onClick={() => {
                                              onclickremoveselected(item);
                                            }}
                                          />
                                        </div>
                                      </>
                                    );
                                  })}
                              </div>
                              <div
                                className="d-flex flex-row justify-content-start align-items-center w-100"
                                style={{ flexWrap: 'wrap' }}
                              >
                                {tagsList &&
                                  tagsList.map((item) => {
                                    return (
                                      <>
                                        <div
                                          key={`key${item._id}`}
                                          className="d-flex flex-row justify-content-center align-items-center"
                                          style={{
                                            paddingInline: 16,
                                            paddingBlock: 6,
                                            borderRadius: 4,
                                            backgroundColor: colors.borderwhite,
                                            marginInlineEnd: 16,
                                            marginBlock: 6,
                                            cursor: 'pointer',
                                          }}
                                          onClick={() => {
                                            onclickpopaction(item);
                                          }}
                                        >
                                          <p
                                            style={{
                                              padding: 0,
                                              margin: 0,
                                              fontSize: 12,
                                              fontWeight: 400,
                                              color: colors.greys04,
                                            }}
                                          >
                                            {item?.tag_name}
                                          </p>
                                        </div>
                                      </>
                                    );
                                  })}
                              </div>
                            </div>
                          </>
                        )}
                        <div style={{ width: '100%', marginBottom: 10 }}>
                          {attributeData.condition?.length > 0 &&
                            attributeData.condition.map((item, index) => {
                              return (
                                <div>
                                  {index > 0 && (
                                    <div
                                      class="container"
                                      style={{
                                        width: '30%',
                                        marginBlock: '10px',
                                        backgroundColor: '#FAFAFA',
                                        borderRadius: '12px',
                                      }}
                                    >
                                      <div
                                        class="row"
                                        style={{ borderRadius: '12px', width: '100%' }}
                                      >
                                        <div
                                          class="col"
                                          style={{
                                            borderRight: '1px solid black',
                                            padding: '7px',
                                            textAlign: 'center',
                                            cursor: 'pointer',
                                            width: '100%',
                                            borderRadius: '8px 0px 0px 8px',
                                            backgroundColor:
                                              attributeData?.query === 'AND'
                                                ? '#8E53F7'
                                                : '#F5F5F5',
                                            color:
                                              attributeData?.query === 'AND' ? 'white' : 'black',
                                          }}
                                          onClick={() =>
                                            setAttributeData((prevState) => ({
                                              ...prevState,
                                              query: 'AND',
                                            }))
                                          }
                                        >
                                          AND
                                        </div>
                                        <div
                                          class="col"
                                          onClick={() =>
                                            setAttributeData((prevState) => ({
                                              ...prevState,
                                              query: 'OR',
                                            }))
                                          }
                                          style={{
                                            backgroundColor:
                                              attributeData?.query === 'OR' ? '#8E53F7' : '#F5F5F5',
                                            color:
                                              attributeData?.query === 'OR' ? 'white' : 'black',
                                            width: '100%',
                                            padding: '7px',
                                            textAlign: 'center',
                                            cursor: 'pointer',
                                            borderRadius: '0px 8px 8px 0px',
                                          }}
                                        >
                                          OR
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                  <AttributeRow
                                    item={item}
                                    attributes={attributes}
                                    setOptionsAction={(
                                      attributeName,
                                      conditionOperator,
                                      attributeValue
                                    ) => {
                                      setVerify(false);
                                      setAttributeData((prev) => ({
                                        ...prev,
                                        condition: prev.condition.map((conditionItem, ind) => {
                                          if (ind === index) {
                                            return {
                                              ...conditionItem,
                                              attributeName: attributeName,
                                              conditionOperator:
                                                attributeName === 'index'
                                                  ? 'between'
                                                  : conditionOperator,
                                              value: attributeValue,
                                            };
                                          }
                                          return conditionItem;
                                        }),
                                      }));
                                    }}
                                    onDeleteAction={() => {
                                      setError('');
                                      setVerify(false);
                                      if (attributeData?.condition?.length === 1) {
                                        setAttributeData((prev) => ({
                                          ...prev,
                                          condition: [],
                                        }));
                                        setVerify(true);
                                      } else {
                                        setAttributeData((prev) => {
                                          const updatedOptions = { ...prev };
                                          const conditionArray = [...updatedOptions.condition];
                                          if (conditionArray.length > 1) {
                                            conditionArray.splice(index, 1);
                                          }

                                          updatedOptions.condition = conditionArray;
                                          return updatedOptions;
                                        });
                                      }
                                      toast.success('Deleted attribute row successfully!', {});
                                    }}
                                  />
                                </div>
                              );
                            })}
                        </div>
                        <div style={{ width: '100%' }} className="d-flex justify-content">
                          {labelName && (
                            <div>
                              {attributeTour ? (
                                <StyledTooltip
                                  open={attributeTour}
                                  title={
                                    <div style={{ marginBlock: 10 }}>
                                      <div
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                          margin: 10,
                                        }}
                                      >
                                        <h4 style={{ fontWeight: 700 }}>Filter using attributes</h4>
                                        <img
                                          src={images.CMCloseWhite}
                                          style={{ height: 24, width: 24, cursor: 'pointer' }}
                                          onClick={() => {
                                            setAttributeTour(false);
                                          }}
                                        />
                                      </div>
                                      <div style={{ margin: 10 }}>
                                        <p style={{ fontWeight: 300 }}>
                                          Now you can filter your customers data using contact
                                          attributes by clicking here
                                        </p>
                                      </div>
                                    </div>
                                  }
                                  arrow
                                  placement="right"
                                >
                                  <div
                                    style={{
                                      paddingBlock: 5,
                                      borderRadius: 8,
                                      border: '1px solid black',
                                      fontSize: 14,
                                      fontWeight: 600,
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      color: colors.black,
                                      cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                      setAttributeTour(false);
                                      if (attributeData.condition.length < 5) {
                                        setAttributeData((prev) => ({
                                          ...prev,
                                          condition: [
                                            ...prev.condition,
                                            { attributeName: '', conditionOperator: '', value: '' },
                                          ],
                                        }));

                                        toast.success('Added an attribute successfully!', {});
                                      } else {
                                        toast.error('You can only set up to 5 attributes', {});
                                      }
                                    }}
                                  >
                                    <p
                                      style={{
                                        borderRadius: 8,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        fontSize: 14,
                                        fontWeight: 600,
                                        color: colors.black,
                                        paddingLeft: 16,
                                        paddingRight: 16,
                                      }}
                                    >
                                      Add attribute
                                    </p>
                                  </div>
                                </StyledTooltip>
                              ) : (
                                <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                                  {attributeData?.condition?.length < 5 && (
                                    <CheerioButton
                                      borderStyle={{ paddingBlock: 5, borderRadius: 8 }}
                                      textStyle={{
                                        fontSize: 14,
                                        fontWeight: 600,
                                        color: colors.black,
                                      }}
                                      btnText={'Add attribute'}
                                      backColor={colors.white}
                                      onclick={() => {
                                        eventsend('Add_attribute_Campaign_popup_clicked');
                                        setError('');
                                        if (attributeData.condition.length < 5) {
                                          setAttributeData((prev) => ({
                                            ...prev,
                                            condition: [
                                              ...prev.condition,
                                              {
                                                attributeName: '',
                                                conditionOperator: '',
                                                value: '',
                                              },
                                            ],
                                          }));

                                          toast.success('Added an attribute successfully!', {});
                                        } else {
                                          toast.error('You can only set up to 5 attributes', {});
                                        }
                                      }}
                                    />
                                  )}
                                  {attributeData?.condition?.length > 0 && (
                                    <CheerioButton
                                      borderStyle={{
                                        paddingBlock: 5,
                                        borderRadius: 8,
                                        borderColor: colors.primary03,
                                      }}
                                      textStyle={{
                                        fontSize: 14,
                                        fontWeight: 600,
                                        color: colors.white,
                                      }}
                                      btnText={'Verify attribute'}
                                      backColor={colors.primary03}
                                      onclick={() => {
                                        eventsend('verify_attributes_Campaign_popup_clicked');
                                        setError('');
                                        if (
                                          labelName === 'All contacts' &&
                                          attributeData?.condition?.some(
                                            (condition) => condition?.attributeName === 'index'
                                          )
                                        ) {
                                          toast.error('Index cannot be selected for All Contacts');
                                        } else if (
                                          labelName !== '' &&
                                          !checkNullValues(attributeData)
                                        ) {
                                          getAttributeBasedBalanceApiCall();
                                        }
                                      }}
                                      disabled={checkNullValues(attributeData)}
                                    />
                                  )}
                                </div>
                              )}
                              <div style={{ margin: 5 }}>
                                {error !== '' && <p style={{ color: 'red' }}>{error}</p>}
                              </div>
                            </div>
                          )}
                        </div>
                        {isLoadingStart ? (
                          <>
                            <div
                              className="d-flex flex-column justify-content-center align-items-center"
                              style={{ flex: 1 }}
                            >
                              <SpinnerLoader />
                            </div>
                          </>
                        ) : (
                          <>
                            {balance && (
                              <>
                                {localdata?.isAppsumoUser ? (
                                  <>
                                    <div
                                      className="d-flex flex-column justify-content-start align-items-start"
                                      style={{ marginBlock: 30, width: '90%' }}
                                    >
                                      <h4 style={{ marginBlockEnd: 8 }}>
                                        {'Contact Label details'}
                                      </h4>
                                      <p>
                                        {`Emails : ${balance?.emailArr?.length === 0
                                          ? '0'
                                          : balance?.emailArr?.length
                                            ? balance?.emailArr?.length
                                            : balance?.email_arr?.length
                                          }`}
                                      </p>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div
                                      className="d-flex flex-row justify-content-start align-items-center"
                                      style={{ width: '90%', marginBlockStart: 40 }}
                                    >
                                      <div
                                        className="d-flex flex-row justify-content-between align-items-center"
                                        style={{
                                          width: '35%',
                                          paddingInlineEnd: 20,
                                          marginInlineEnd: 20,
                                          borderRight: `2px solid ${colors.borderwhite}`,
                                        }}
                                      >
                                        <p
                                          style={{
                                            fontSize: 16,
                                            fontWeight: 400,
                                            color: colors.greys04,
                                            padding: 0,
                                            margin: 0,
                                          }}
                                        >
                                          {'Required Email Credits'}
                                        </p>
                                        <p
                                          style={{
                                            fontSize: 16,
                                            fontWeight: 400,
                                            color: colors.primary03,
                                            padding: 0,
                                            margin: 0,
                                          }}
                                        >
                                          {balance?.emailArr?.length === 0
                                            ? '0'
                                            : balance?.emailArr?.length
                                              ? balance?.emailArr?.length
                                              : balance?.email_arr?.length}
                                        </p>
                                      </div>
                                      <div
                                        className="d-flex flex-row justify-content-between align-items-center"
                                        style={{ width: '35%' }}
                                      >
                                        <p
                                          style={{
                                            fontSize: 16,
                                            fontWeight: 400,
                                            color: colors.greys04,
                                            padding: 0,
                                            margin: 0,
                                          }}
                                        >
                                          {'Available Email Credits'}
                                        </p>
                                        <p
                                          style={{
                                            fontSize: 16,
                                            fontWeight: 400,
                                            color: colors.primary03,
                                            padding: 0,
                                            margin: 0,
                                          }}
                                        >
                                          {localdata?.balance?.email}
                                        </p>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </Form>
                      {showHTMLPreview ? (
                        <>
                          <div className="my-3 w-75" style={{ height: 'auto' }}>
                            {Parser().parse(rhtml)}
                          </div>

                          {/* <CheerioButton
                    borderStyle={{borderColor:colors.primary03,width:'40%',marginBlock:20}}
                    textStyle={{fontSize:14,fontWeight:600,color:colors.white}}
                    btnText={'Make announcement'}
                    backColor={colors.primary03}
                    disabled={nameC && subjectC && labelName ? false : true}
                    onclick={()=>{
                        console.log("make announcement clicked...")
                        makeCustomAnnouncement()
                    }}
                /> */}
                        </>
                      ) : (
                        <>
                          <div
                            className="d-flex flex-column justify-content-center align-items-center px-3 w-75"
                            style={{
                              height: 250,
                              backgroundColor: colors.white04,
                              ...Styles.borderDashed,
                              marginBlock: 16,
                              cursor: 'pointer',
                            }}
                            onDragEnter={handleDrag}
                            onDragOver={handleDrag}
                            onDragExit={handleDrag}
                            onDrop={handleDrop}
                            onClick={() => {
                              eventsend('Upload_file_campaign_popup_clicked');
                              if (!HTMLFile) {
                                console.log('------------upload clicked--------');
                                htmlref.current.click();
                              }
                            }}
                          >
                            <input
                              type="file"
                              id="htmlFile"
                              style={{ display: 'none' }}
                              ref={htmlref}
                              accept=".html"
                              value=""
                              onChange={(e) => {
                                eventsend('Upload_file_campaign_popup_clicked');
                                console.log('--------input ref onchange------');
                                handlehtmlupload(e);
                              }}
                            />
                            {fileUploaded ? (
                              <>
                                <div
                                  className="d-flex flex-row justify-content-center align-items-center"
                                  style={{ width: '75%' }}
                                >
                                  <p
                                    style={{
                                      ...Styles.TxtBody,
                                      marginLeft: 40,
                                      marginRight: 20,
                                      padding: 0,
                                      margin: 0,
                                    }}
                                  >{`${HTMLFile.name}`}</p>
                                  <img
                                    onClick={() => {
                                      setHTMLFile(null);
                                      setrhtml(null);
                                      setCountOfProgress(0);
                                      setFileUploaded(!fileUploaded);
                                    }}
                                    src={images.CreatorClose}
                                    style={{ height: 30, width: 30, objectFit: 'contain' }}
                                  ></img>
                                </div>
                                <div
                                  className="w-75 py-3 px-2 d-block flex-column justify-content-center align-items-center"
                                  style={{ height: 'auto' }}
                                >
                                  <ProgressBar
                                    variant="customcolor"
                                    style={{ color: colors.primary03 }}
                                    now={countOfProgress}
                                  />
                                </div>
                                <p
                                  className=""
                                  style={{ ...Styles.TxtBody, padding: 0 }}
                                >{`File uploaded (${parseInt(countOfProgress)}%)`}</p>
                              </>
                            ) : (
                              <>
                                <p
                                  className=""
                                  style={{
                                    ...Styles.TxtBody,
                                    textAlign: 'center',
                                    padding: 0,
                                    margin: 0,
                                  }}
                                >
                                  <span
                                    id="UploadButton"
                                    style={{ color: colors.linkblue, padding: 0, margin: 0 }}
                                  >
                                    {'Upload '}
                                  </span>
                                  {'or Drag & drop here'}
                                </p>
                              </>
                            )}
                          </div>
                          {/* <CheerioButton
                    borderStyle={{borderColor:colors.primary03,width:'40%',marginBlock:20}}
                    textStyle={{fontSize:14,fontWeight:600,color:colors.white}}
                    btnText={'Proceed'}
                    disabled={countOfProgress == 100 ? false : true}
                    backColor={colors.primary03}
                    onclick={()=>{
                        console.log("proceed clicked...")
                        setShowHTMLPreview(true)
                    }}
                  /> */}
                        </>
                      )}
                    </div>
                  </div>
                </>
              )
            ) : nextClicked ? (
              <>
                {/* <div
                  className="d-flex flex-row justify-content-start align-items-center w-100"
                  style={{ marginBlock: 8, borderBottom: `1px solid ${colors.borderwhite}` }}
                >
                  <div
                    className="d-flex justify-content-center align-items-center h-100"
                    style={{
                      cursor: 'pointer',
                      borderBottom:
                        TemplateV2Type === 'Recommended templates'
                          ? `2px solid ${colors.primary03}`
                          : '',
                    }}
                    onClick={() => {
                      setTemplateV2Type('Recommended templates');
                    }}
                  >
                    <p
                      style={{
                        margin: 0,
                        padding: 0,
                        fontSize: 16,
                        fontWeight: TemplateV2Type === 'Recommended templates' ? 600 : 400,
                        color:
                          TemplateV2Type === 'Recommended templates'
                            ? colors.black
                            : colors.greys04,
                        paddingInline: 30,
                        paddingBlock: 8,
                      }}
                    >
                      {'Recommended templates'}
                    </p>
                  </div>
                  <div
                    className="d-flex justify-content-center align-items-center h-100"
                    style={{
                      cursor: 'pointer',
                      borderBottom:
                        TemplateV2Type === 'Custom templates'
                          ? `2px solid ${colors.primary03}`
                          : '',
                    }}
                    onClick={() => {
                      setTemplateV2Type('Custom templates');
                    }}
                  >
                    <p
                      style={{
                        margin: 0,
                        padding: 0,
                        fontSize: 16,
                        fontWeight: TemplateV2Type === 'Custom templates' ? 600 : 400,
                        color:
                          TemplateV2Type === 'Custom templates' ? colors.black : colors.greys04,
                        paddingInline: 30,
                        paddingBlock: 8,
                      }}
                    >
                      {'Custom templates'}
                    </p>
                  </div>
                </div> */}

                <AnnTemplates
                  TemplateV2Type={TemplateV2Type}
                  setTemplateV2Type={setTemplateV2Type}
                  sSelected={Sselected}
                  wSelected={Wselected}
                />

                {TemplateV2Type === 'Recommended templates' ? (
                  <>
                    <AnnTemplateV1
                      sSelected={Sselected}
                      onclickSeePreviewAction={(item) => {
                        eventsend('Recommended_templates_clicked');
                        setPreview1(true);
                        setType(item);
                      }}
                      onclickUseTemplateAction={(item) => {
                        if (item === 'HTML') {
                          // setType(item);
                          setOpenCustomHTML(true);
                        } else if (item === 'CustomHTML') {
                          if (fromhomepage) {
                            setOpenCampaignModal(true)
                          }
                          if (fromCampaignpage) {
                            sessionStorage.setItem("campaignModal", "true");
                          }
                          navigate('/creator/design');
                          eventsend('close_campaign_pop_up_clicked');
                          setOpenANModal(false);
                        } else {
                          setType(item);
                          setPreview1(false);
                          setEnterDetails(true);
                        }
                      }}
                    />
                  </>
                ) : TemplateV2Type === 'WhatsApp templates' ? (
                  <>
                    <AnnTemplate
                      onclickAction={(item) => {
                        setItemV2(item);
                        setDetailsV2(true);
                      }}
                    />
                  </>
                ) : (
                  TemplateV2Type === 'SMS Templates' && (
                    <>
                      <SmsTemplates
                        onclickAction={(item) => {
                          const val = {
                            name: item.TemplateName,
                            language: 'en',
                            templateData: item,
                          };
                          setItemV2(val);
                          setDetailsV2(true);
                        }}
                        setOpenANModal={setOpenANModal}
                        setOpenCreateSmsModal={setOpenCreateSmsModal}
                        openCreateSmsModal={openCreateSmsModal}
                        setCustomSMSText={setCustomSMSText}
                        customSMSText={customSMSText}
                      />
                    </>
                  )
                )}
              </>
            ) : (
              <>
                {/* Body Scroll Component */}
                {isLoadingContacts ? (
                  <>
                    <div
                      className="d-flex flex-column justify-content-center align-items-center w-100"
                      style={{ flex: 1 }}
                    >
                      <SpinnerLoader />
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className="d-flex flex-column justify-content-start align-items-center w-100 verythinscrollnew"
                      style={{ flex: 1, overflowY: 'scroll' }}
                    >
                      <InputGroup
                        className=""
                        style={{ width: '90%', marginBlockStart: 24, marginBlockEnd: 16 }}
                      >
                        <Form.Label
                          style={{
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                            marginBlock: 4,
                          }}
                        >
                          {'Name Campaign'}
                        </Form.Label>
                        <span
                          className="ms-auto"
                          style={{
                            color: campaignName.length > 30 ? colors.error04 : colors.greys04,
                            fontSize: 12,
                            fontWeight: 400,
                            padding: 0,
                            margin: 0,
                          }}
                        >
                          {`${campaignName.length}/30`}
                        </span>
                        <Form.Control
                          type="text"
                          placeholder="Enter here"
                          className="w-100 btncustom"
                          style={{
                            borderRadius: 8,
                            fontSize: 16,
                            fontWeight: 400,
                            color: colors.greys01,
                          }}
                          value={campaignName}
                          maxLength={30}
                          isInvalid={campaignName.length > 30}
                          onChange={(e) => {
                            setCampaignName(e.target.value);
                          }}
                        />
                      </InputGroup>
                      {shareTo === 'Label' ? (
                        <>
                          <InputGroup className="" style={{ width: '90%', marginBlock: 16 }}>
                            <Form.Label
                              style={{
                                fontSize: 16,
                                fontWeight: 600,
                                color: colors.black,
                                marginBlock: 4,
                              }}
                            >
                              {'Select a Label'}
                            </Form.Label>
                            <Autocomplete
                              disablePortal
                              id="combo-box-demo"
                              style={{
                                marginBottom: '5px',
                              }}
                              filterOptions={filterOptions}
                              className="w-100 btncustom"
                              size="small"
                              renderOption={(props, option) => {
                                const { key, ...optionProps } = props;

                                const displayText =
                                  option.length > 30 ? option.substring(0, 30) + '...' : option;

                                return (
                                  <Box
                                    key={key}
                                    sx={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      width: '100%',
                                      justifyContent: 'space-between',
                                    }}
                                    {...optionProps}
                                  >
                                    <Tooltip title={option} placement="top">
                                      <p
                                        style={{
                                          width: '98%',
                                          whiteSpace: 'nowrap',
                                          overflow: 'hidden',
                                          textOverflow: 'ellipsis',
                                        }}
                                      >
                                        {displayText}
                                      </p>
                                    </Tooltip>
                                    {/* <p style={{ width: '98%' }}>{option}</p> */}
                                    {option === 'All contacts' && (
                                      <img
                                        src={images.FilledPin}
                                        style={{
                                          height: 22,
                                        }}
                                      />
                                    )}
                                  </Box>
                                );
                              }}
                              renderInput={(params) => (
                                <TextField
                                  name="variable"
                                  size={'small'}
                                  placeholder={'Select segment'}
                                  className={'restylingLabels'}
                                  InputProps={{
                                    notched: false,
                                    style: {
                                      borderRadius: 8,
                                    },
                                  }}
                                  onChange={(e) => {
                                    setLabelName(e.target.value);
                                  }}
                                  {...params}
                                />
                              )}
                              value={labelName}
                              onChange={(e, newValue) => {
                                setLabelName(newValue);
                              }}
                              freeSolo={true}
                              options={[
                                'All contacts',
                                ...labelList
                                  .filter((item) => {
                                    return !(
                                      item?.label_name.includes('abandoned-cart-on') ||
                                      item?.label_name.includes('orders-feedback-on') ||
                                      item?.label_name.includes('orders-cancelled-on') ||
                                      item?.label_name.includes('orders-fulfilled-on') ||
                                      item?.label_name.includes('Workflows -') ||
                                      item?.label_name.includes('Workflows-') ||
                                      item?.label_name.includes('WhatsApp Contacts who') ||
                                      item?.label_name.includes('orders-placed-on') ||
                                      item?.label_name.includes('cod-confirmation-on')
                                    );
                                  })
                                  .map((item) => item?.label_name),
                              ]}
                            />
                            {labelName && (
                              <p
                                style={{
                                  fontSize: '14px',
                                  paddingLeft: 5,

                                  color: '#666666',
                                }}
                              >
                                {
                                  'To filter data from existing label you can add an attribute based on label you have selected.'
                                }
                              </p>
                            )}
                          </InputGroup>
                        </>
                      ) : (
                        <>
                          <div style={{ width: '90%', marginBlock: 16 }}>
                            <h4
                              style={{
                                padding: 0,
                                margin: 0,
                                fontSize: 16,
                                fontWeight: 600,
                                color: colors.black,
                                marginBlock: 4,
                              }}
                            >
                              {'Enter Tags'}
                            </h4>
                            <div
                              className="btncustom d-flex flex-row justify-content-start align-items-center px-2 py-2"
                              style={{
                                minHeight: 50,
                                width: '100%',
                                border: `1px solid ${colors.greys03}`,
                                borderRadius: 8,
                                marginBlock: 4,
                                overflowX: 'auto',
                                overflowY: 'hidden',
                                whiteSpace: 'nowrap',
                              }}
                            >
                              {selectedTagsList &&
                                selectedTagsList.map((item) => {
                                  return (
                                    <>
                                      <div
                                        key={`key${item._id}`}
                                        className="d-flex flex-row justify-content-between align-items-center"
                                        style={{
                                          paddingInline: 16,
                                          paddingBlock: 4,
                                          borderRadius: 4,
                                          backgroundColor: colors.borderwhite,
                                          marginInlineEnd: 16,
                                        }}
                                      >
                                        <p
                                          style={{
                                            padding: 0,
                                            margin: 0,
                                            fontSize: 14,
                                            fontWeight: 400,
                                            color: colors.greys04,
                                          }}
                                        >
                                          {item?.tag_name}
                                        </p>
                                        <img
                                          src={images.CMClose}
                                          style={{
                                            height: 14,
                                            width: 14,
                                            objectFit: 'contain',
                                            marginInlineStart: 8,
                                            cursor: 'pointer',
                                          }}
                                          onClick={() => {
                                            onclickremoveselected(item);
                                          }}
                                        />
                                      </div>
                                    </>
                                  );
                                })}
                            </div>
                            <div
                              className="d-flex flex-row justify-content-start align-items-center w-100"
                              style={{ flexWrap: 'wrap' }}
                            >
                              {tagsList &&
                                tagsList.map((item) => {
                                  return (
                                    <>
                                      <div
                                        key={`key${item._id}`}
                                        className="d-flex flex-row justify-content-center align-items-center"
                                        style={{
                                          paddingInline: 16,
                                          paddingBlock: 6,
                                          borderRadius: 4,
                                          backgroundColor: colors.borderwhite,
                                          marginInlineEnd: 16,
                                          marginBlock: 6,
                                          cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                          onclickpopaction(item);
                                        }}
                                      >
                                        <p
                                          style={{
                                            padding: 0,
                                            margin: 0,
                                            fontSize: 12,
                                            fontWeight: 400,
                                            color: colors.greys04,
                                          }}
                                        >
                                          {item?.tag_name}
                                        </p>
                                      </div>
                                    </>
                                  );
                                })}
                            </div>
                          </div>
                        </>
                      )}
                      <div style={{ width: '92%', marginBottom: 10 }}>
                        {attributeData.condition?.length > 0 &&
                          attributeData.condition.map((item, index) => {
                            return (
                              <div>
                                {index > 0 && (
                                  <div
                                    class="container"
                                    style={{
                                      width: '30%',
                                      marginBlock: '10px',
                                      backgroundColor: '#FAFAFA',
                                      borderRadius: '12px',
                                    }}
                                  >
                                    <div
                                      class="row"
                                      style={{ borderRadius: '12px', width: '100%' }}
                                    >
                                      <div
                                        class="col"
                                        style={{
                                          borderRight: '1px solid black',
                                          padding: '7px',
                                          textAlign: 'center',
                                          cursor: 'pointer',
                                          width: '100%',
                                          borderRadius: '8px 0px 0px 8px',
                                          backgroundColor:
                                            attributeData?.query === 'AND' ? '#8E53F7' : '#F5F5F5',
                                          color: attributeData?.query === 'AND' ? 'white' : 'black',
                                        }}
                                        onClick={() =>
                                          setAttributeData((prevState) => ({
                                            ...prevState,
                                            query: 'AND',
                                          }))
                                        }
                                      >
                                        AND
                                      </div>
                                      <div
                                        class="col"
                                        onClick={() =>
                                          setAttributeData((prevState) => ({
                                            ...prevState,
                                            query: 'OR',
                                          }))
                                        }
                                        style={{
                                          backgroundColor:
                                            attributeData?.query === 'OR' ? '#8E53F7' : '#F5F5F5',
                                          color: attributeData?.query === 'OR' ? 'white' : 'black',
                                          width: '100%',
                                          padding: '7px',
                                          textAlign: 'center',
                                          cursor: 'pointer',
                                          borderRadius: '0px 8px 8px 0px',
                                        }}
                                      >
                                        OR
                                      </div>
                                    </div>
                                  </div>
                                )}
                                <AttributeRow
                                  item={item}
                                  attributes={attributes}
                                  setOptionsAction={(
                                    attributeName,
                                    conditionOperator,
                                    attributeValue
                                  ) => {
                                    setVerify(false);
                                    setAttributeData((prev) => ({
                                      ...prev,
                                      condition: prev.condition.map((conditionItem, ind) => {
                                        if (ind === index) {
                                          return {
                                            ...conditionItem,
                                            attributeName: attributeName,
                                            conditionOperator:
                                              attributeName === 'index'
                                                ? 'between'
                                                : conditionOperator,
                                            value: attributeValue,
                                          };
                                        }
                                        return conditionItem;
                                      }),
                                    }));
                                  }}
                                  onDeleteAction={() => {
                                    setError('');
                                    setVerify(false);
                                    if (attributeData?.condition?.length === 1) {
                                      setAttributeData((prev) => ({
                                        ...prev,
                                        condition: [],
                                      }));
                                      setVerify(true);
                                    } else {
                                      setAttributeData((prev) => {
                                        const updatedOptions = { ...prev };
                                        const conditionArray = [...updatedOptions.condition];
                                        if (conditionArray.length > 1) {
                                          conditionArray.splice(index, 1);
                                        }

                                        updatedOptions.condition = conditionArray;
                                        return updatedOptions;
                                      });
                                    }
                                    toast.success('Deleted attribute row successfully!', {});
                                  }}
                                />
                              </div>
                            );
                          })}
                      </div>
                      <div style={{ width: '90%' }} className="d-flex justify-content">
                        {labelName ? (
                          <div>
                            {attributeTour ? (
                              <StyledTooltip
                                open={attributeTour}
                                title={
                                  <div style={{ marginBlock: 10 }}>
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        margin: 10,
                                      }}
                                    >
                                      <h4 style={{ fontWeight: 700 }}>Filter using attributes</h4>
                                      <img
                                        src={images.CMCloseWhite}
                                        style={{ height: 24, width: 24, cursor: 'pointer' }}
                                        onClick={() => {
                                          setAttributeTour(false);
                                        }}
                                      />
                                    </div>
                                    <div style={{ margin: 10 }}>
                                      <p style={{ fontWeight: 300 }}>
                                        Now you can filter your customers data using contact
                                        attributes by clicking here
                                      </p>
                                    </div>
                                  </div>
                                }
                                arrow
                                placement="right"
                              >
                                <div
                                  style={{
                                    paddingBlock: 5,
                                    borderRadius: 8,
                                    border: '1px solid black',
                                    fontSize: 14,
                                    fontWeight: 600,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    color: colors.black,
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => {
                                    setAttributeTour(false);
                                    if (attributeData.condition.length < 5) {
                                      setAttributeData((prev) => ({
                                        ...prev,
                                        condition: [
                                          ...prev.condition,
                                          { attributeName: '', conditionOperator: '', value: '' },
                                        ],
                                      }));

                                      toast.success('Added an attribute successfully!', {});
                                    } else {
                                      toast.error('You can only set up to 5 attributes', {});
                                    }
                                  }}
                                >
                                  <p
                                    style={{
                                      borderRadius: 8,
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      fontSize: 14,
                                      fontWeight: 600,
                                      color: colors.black,
                                      paddingLeft: 16,
                                      paddingRight: 16,
                                    }}
                                  >
                                    Add attribute
                                  </p>
                                </div>
                              </StyledTooltip>
                            ) : (
                              <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                                {attributeData?.condition?.length < 5 && (
                                  <CheerioButton
                                    borderStyle={{ paddingBlock: 5, borderRadius: 8 }}
                                    textStyle={{
                                      fontSize: 14,
                                      fontWeight: 600,
                                      color: colors.black,
                                    }}
                                    btnText={'Add attribute'}
                                    backColor={colors.white}
                                    onclick={() => {
                                      eventsend('Add_attribute_Campaign_popup_clicked');
                                      setError('');
                                      if (attributeData.condition.length < 5) {
                                        setAttributeData((prev) => ({
                                          ...prev,
                                          condition: [
                                            ...prev.condition,
                                            { attributeName: '', conditionOperator: '', value: '' },
                                          ],
                                        }));

                                        toast.success('Added an attribute successfully!', {});
                                      } else {
                                        toast.error('You can only set up to 5 attributes', {});
                                      }
                                    }}
                                  />
                                )}
                                {attributeData?.condition?.length > 0 && (
                                  <CheerioButton
                                    borderStyle={{
                                      paddingBlock: 5,
                                      borderRadius: 8,
                                      borderColor: colors.primary03,
                                    }}
                                    textStyle={{
                                      fontSize: 14,
                                      fontWeight: 600,
                                      color: colors.white,
                                    }}
                                    btnText={'Verify attribute'}
                                    backColor={colors.primary03}
                                    onclick={() => {
                                      eventsend('verify_attributes_Campaign_popup_clicked');
                                      setError('');
                                      if (
                                        labelName === 'All contacts' &&
                                        attributeData?.condition?.some(
                                          (condition) => condition?.attributeName === 'index'
                                        )
                                      ) {
                                        toast.error('Index cannot be selected for All Contacts');
                                      } else if (
                                        labelName !== '' &&
                                        !checkNullValues(attributeData)
                                      ) {
                                        getAttributeBasedBalanceApiCall();
                                      }
                                    }}
                                    disabled={checkNullValues(attributeData)}
                                  />
                                )}
                              </div>
                            )}
                            <div style={{ margin: 5 }}>
                              {error !== '' && <p style={{ color: 'red' }}>{error}</p>}
                            </div>
                          </div>
                        ) : (
                          <CheerioButton
                            borderStyle={{ paddingBlock: 5, borderRadius: 8 }}
                            textStyle={{
                              fontSize: 14,
                              fontWeight: 600,
                              color: colors.black,
                            }}
                            btnText={'Upload New Label'}
                            backColor={colors.white}
                            onclick={() => {
                              // navigate('/creator/uploadContacts',{ state: { from: '/creator/announcements' } })

                              if (userId === '64f6fc44a8ab1b1d3eaa8d2c') setOpenICModal(true);
                              if (fromhomepage) {
                                setOpenANModal(false);
                                navigate('/creator/uploadContacts', {
                                  state: { from: '/creator' },
                                });
                              } else {
                                setOpenANModal(false);
                                navigate('/creator/uploadContacts', {
                                  state: { from: '/creator/announcements' },
                                });
                              }
                            }}
                            icon={images.UploadBlack}
                          />
                        )}

                        {openICModal && (
                          <ICNewModal
                            campaignFlow={true}
                            setLabelName={setLabelName}
                            setOpenICModal={setOpenICModal}
                            setAttributeTour={setAttributeTour}
                          />
                        )}
                      </div>
                      {isLoadingStart ? (
                        <>
                          <div
                            className="d-flex flex-column justify-content-center align-items-center"
                            style={{ flex: 1 }}
                          >
                            <SpinnerLoader />
                          </div>
                        </>
                      ) : (
                        <>
                          {balance && (
                            <>
                              {localdata?.isAppsumoUser ? (
                                <>
                                  <div
                                    className="d-flex flex-column justify-content-start align-items-start"
                                    style={{ marginBlock: 30, width: '90%' }}
                                  >
                                    <h4 style={{ marginBlockEnd: 8 }}>{'Contact Label details'}</h4>
                                    <p>
                                      {`Emails : ${balance?.emailArr?.length === 0
                                        ? '0'
                                        : balance?.emailArr?.length
                                          ? balance?.emailArr?.length
                                          : balance?.email_arr?.length
                                        }`}
                                    </p>
                                    <p>
                                      {`Phone numbers : ${balance?.mobileArr?.length === 0
                                        ? '0'
                                        : balance?.mobileArr?.length
                                          ? balance?.mobileArr?.length
                                          : balance?.mobile_arr?.length
                                        }`}
                                    </p>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div
                                    className="d-flex flex-row justify-content-start align-items-center"
                                    style={{ width: '90%', marginBlockStart: 40 }}
                                  >
                                    <div
                                      className="d-flex flex-row justify-content-between align-items-center"
                                      style={{
                                        width: '35%',
                                        paddingInlineEnd: 20,
                                        marginInlineEnd: 20,
                                        borderRight: `2px solid ${colors.borderwhite}`,
                                      }}
                                    >
                                      <p
                                        style={{
                                          fontSize: 16,
                                          fontWeight: 400,
                                          color: colors.greys04,
                                          padding: 0,
                                          margin: 0,
                                        }}
                                      >
                                        {'Required Email Credits'}
                                      </p>
                                      <p
                                        style={{
                                          fontSize: 16,
                                          fontWeight: 400,
                                          color: colors.primary03,
                                          padding: 0,
                                          margin: 0,
                                        }}
                                      >
                                        {balance?.emailArr?.length === 0
                                          ? '0'
                                          : balance?.emailArr?.length
                                            ? balance?.emailArr?.length
                                            : balance?.email_arr?.length}
                                      </p>
                                    </div>
                                    <div
                                      className="d-flex flex-row justify-content-between align-items-center"
                                      style={{ width: '35%' }}
                                    >
                                      <p
                                        style={{
                                          fontSize: 16,
                                          fontWeight: 400,
                                          color: colors.greys04,
                                          padding: 0,
                                          margin: 0,
                                        }}
                                      >
                                        {'Available Email Credits'}
                                      </p>
                                      <p
                                        style={{
                                          fontSize: 16,
                                          fontWeight: 400,
                                          color: colors.primary03,
                                          padding: 0,
                                          margin: 0,
                                        }}
                                      >
                                        {localdata?.balance?.email}
                                      </p>
                                    </div>
                                  </div>
                                  <div
                                    className="d-flex flex-row justify-content-start align-items-center"
                                    style={{ width: '90%' }}
                                  >
                                    {!isWalletBalanceActive && (
                                      <div
                                        className="d-flex flex-row justify-content-between align-items-center"
                                        style={{
                                          width: '35%',
                                          paddingInlineEnd: 20,
                                          paddingBlock: 8,
                                          marginInlineEnd: 20,
                                          borderRight: `2px solid ${colors.borderwhite}`,
                                        }}
                                      >
                                        <p
                                          style={{
                                            fontSize: 16,
                                            fontWeight: 400,
                                            color: colors.greys04,
                                            padding: 0,
                                            margin: 0,
                                          }}
                                        >
                                          {'Required SMS Credits'}
                                        </p>
                                        <p
                                          style={{
                                            fontSize: 16,
                                            fontWeight: 400,
                                            color: colors.primary03,
                                            padding: 0,
                                            margin: 0,
                                          }}
                                        >
                                          {balance?.mobileArr?.length === 0
                                            ? '0'
                                            : balance?.mobileArr?.length
                                              ? balance?.mobileArr?.length
                                              : balance?.mobile_arr?.length}
                                        </p>
                                      </div>
                                    )}
                                    {!isWalletBalanceActive && (
                                      <div
                                        className="d-flex flex-row justify-content-between align-items-center"
                                        style={{ width: '35%', paddingBlock: 8 }}
                                      >
                                        <p
                                          style={{
                                            fontSize: 16,
                                            fontWeight: 400,
                                            color: colors.greys04,
                                            padding: 0,
                                            margin: 0,
                                          }}
                                        >
                                          {'Available SMS Credits'}
                                        </p>
                                        <p
                                          style={{
                                            fontSize: 16,
                                            fontWeight: 400,
                                            color: colors.primary03,
                                            padding: 0,
                                            margin: 0,
                                          }}
                                        >
                                          {localdata?.balance?.sms}
                                        </p>
                                      </div>
                                    )}
                                  </div>
                                  <div
                                    className="d-flex flex-row justify-content-start align-items-center"
                                    style={{ width: '90%', marginBlockEnd: 40 }}
                                  >
                                    {!isWalletBalanceActive && (
                                      <div
                                        className="d-flex flex-row justify-content-between align-items-center"
                                        style={{
                                          width: '35%',
                                          paddingInlineEnd: 20,
                                          marginInlineEnd: 20,
                                          borderRight: `2px solid ${colors.borderwhite}`,
                                        }}
                                      >
                                        <p
                                          style={{
                                            fontSize: 16,
                                            fontWeight: 400,
                                            color: colors.greys04,
                                            padding: 0,
                                            margin: 0,
                                          }}
                                        >
                                          {'Required WhatsApp Credits'}
                                        </p>
                                        <p
                                          style={{
                                            fontSize: 16,
                                            fontWeight: 400,
                                            color: colors.primary03,
                                            padding: 0,
                                            margin: 0,
                                          }}
                                        >
                                          {balance?.mobileArr?.length === 0
                                            ? '0'
                                            : balance?.mobileArr?.length
                                              ? balance?.mobileArr?.length
                                              : balance?.mobile_arr?.length}
                                        </p>
                                      </div>
                                    )}
                                    {!isWalletBalanceActive && (
                                      <div
                                        className="d-flex flex-row justify-content-between align-items-center"
                                        style={{ width: '35%' }}
                                      >
                                        <p
                                          style={{
                                            fontSize: 16,
                                            fontWeight: 400,
                                            color: colors.greys04,
                                            padding: 0,
                                            margin: 0,
                                          }}
                                        >
                                          {'Available WhatsApp Credits'}
                                        </p>
                                        <p
                                          style={{
                                            fontSize: 16,
                                            fontWeight: 400,
                                            color: colors.primary03,
                                            padding: 0,
                                            margin: 0,
                                          }}
                                        >
                                          {localdata?.balance?.whatsapp}
                                        </p>
                                      </div>
                                    )}
                                  </div>
                                </>
                              )}

                              {/* </> */}
                              {/* } */}

                              {/* Channel selection */}
                              <h4
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  width: '90%',
                                  fontSize: 16,
                                  fontWeight: 600,
                                  color: colors.black,
                                  marginBlock: 8,
                                }}
                              >
                                {'Choose Channel(s)'}
                              </h4>
                              {/* Email */}
                              <div
                                className="d-flex flex-row justify-content-start align-items-center"
                                style={{ marginBlock: 8, width: '90%' }}
                              >
                                <div
                                  id="EmailChannel"
                                  className="d-flex flex-row justify-content-center align-items-center"
                                  style={{
                                    height: 18,
                                    width: 18,
                                    border: `1px solid ${colors.primary03}`,
                                    marginInlineEnd: 12,
                                    cursor: checksender ? 'pointer' : 'not-allowed',
                                    opacity: checksender ? 1 : 0.5,
                                  }}
                                  onClick={() => {
                                    eventsend(
                                      'Email_checkbox_Campaign_popup_Campaign_page_clicked'
                                    );
                                    if (checksender) {
                                      setEselected(!Eselected);
                                    }
                                  }}
                                >
                                  {Eselected && (
                                    <img
                                      src={images.CATickFilled}
                                      style={{ height: 18, width: 18, objectFit: 'contain' }}
                                    ></img>
                                  )}
                                </div>
                                <p
                                  style={{
                                    margin: 0,
                                    padding: 0,
                                    fontSize: 16,
                                    fontWeight: 400,
                                    color: colors.black,
                                  }}
                                >
                                  <span
                                    style={{
                                      cursor: checksender ? 'default' : 'not-allowed',
                                      opacity: checksender ? 1 : 0.5,
                                    }}
                                  >
                                    Email
                                  </span>
                                  {!checksender && (
                                    <span
                                      style={{
                                        marginInlineStart: 12,
                                      }}
                                    >
                                      You have not created a verified sender ID yet. To start using
                                      email channel,{' '}
                                      <a
                                        style={{
                                          color: 'blue',
                                          textDecoration: 'underline',
                                          cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                          setCreateSender(true);
                                        }}
                                      >
                                        Create Sender ID.
                                      </a>
                                      {createSender && (
                                        <SenderIdModal
                                          openSenderIdModal={createSender}
                                          setOpenSenderIdModal={setCreateSender}
                                        ></SenderIdModal>
                                      )}
                                    </span>
                                  )}
                                  {(balance?.emailArr?.length
                                    ? balance?.emailArr?.length
                                    : balance?.email_arr?.length) > localdata?.balance?.email && (
                                      <span
                                        style={{ marginInlineStart: 12, color: colors.greys04 }}
                                      >{`(Your Email balance is low. Only first ${localdata?.balance?.email} customers will receive the Email.)`}</span>
                                    )}
                                </p>
                              </div>
                              {/* SMS */}
                              <div
                                className="d-flex flex-row justify-content-start align-items-center"
                                style={{ marginBlock: 8, width: '90%' }}
                              >
                                <div
                                  id="SMSChannel"
                                  className="d-flex flex-row justify-content-center align-items-center"
                                  style={{
                                    height: 18,
                                    width: 18,
                                    border: `1px solid ${colors.primary03}`,
                                    marginInlineEnd: 12,
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => {
                                    eventsend('SMS_checkbox_Campaign_popup_Campaign_page_clicked');
                                    setSselected(!Sselected);
                                  }}
                                >
                                  {Sselected && (
                                    <img
                                      src={images.CATickFilled}
                                      style={{ height: 18, width: 18, objectFit: 'contain' }}
                                    ></img>
                                  )}
                                </div>
                                <p
                                  style={{
                                    margin: 0,
                                    padding: 0,
                                    fontSize: 16,
                                    fontWeight: 400,
                                    color: colors.black,
                                  }}
                                >
                                  {'SMS'}
                                  {(balance?.mobileArr?.length
                                    ? balance?.mobileArr?.length
                                    : balance?.mobile_arr?.length) > localdata?.balance?.sms && (
                                      <span
                                        style={{ marginInlineStart: 12, color: colors.greys04 }}
                                      >{`(Your SMS balance is low. Only first ${localdata?.balance?.sms} customers will receive the message.)`}</span>
                                    )}
                                </p>
                              </div>
                              {/* WhatsApp */}
                              <div
                                className="d-flex flex-row justify-content-start align-items-center"
                                style={{ marginBlockStart: 8, marginBlockEnd: 24, width: '90%' }}
                              >
                                <div
                                  id="WhatsAppChannel"
                                  className="d-flex flex-row justify-content-center align-items-center"
                                  style={{
                                    height: 18,
                                    width: 18,
                                    border: `1px solid ${colors.primary03}`,
                                    marginInlineEnd: 12,
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => {
                                    eventsend(
                                      'WhatsApp_checkbox_Campaign_popup_Campaign_page_clicked'
                                    );
                                    setWselected(!Wselected);
                                  }}
                                >
                                  {Wselected && (
                                    <img
                                      src={images.CATickFilled}
                                      style={{ height: 18, width: 18, objectFit: 'contain' }}
                                    ></img>
                                  )}
                                </div>
                                <p
                                  style={{
                                    margin: 0,
                                    padding: 0,
                                    fontSize: 16,
                                    fontWeight: 400,
                                    color: colors.black,
                                  }}
                                >
                                  {'WhatsApp'}
                                  {!localdata?.whatsapp_credentials?.countryBasedBilling &&
                                    (balance?.mobileArr?.length || balance?.mobile_arr?.length) >
                                    localdata?.balance?.whatsapp && (
                                      <span
                                        style={{ marginInlineStart: 12, color: colors.greys04 }}
                                      >
                                        {`(Your WhatsApp balance is low. Only the first ${localdata?.balance?.whatsapp > 0
                                          ? localdata?.balance?.whatsapp
                                          : 0
                                          } customers will receive the conversation.)`}
                                      </span>
                                    )}
                                </p>
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </>
                )}

                {/* Footer Component */}
                <div
                  className="d-flex flex-row justify-content-end align-items-center w-100"
                  style={{ paddingBlock: 16, borderTop: `1px solid ${colors.borderwhite}` }}
                >
                  <CheerioButton
                    borderStyle={{
                      marginBlock: 8,
                      borderColor: colors.primary03,
                      width: '25%',
                      paddingBlock: 10,
                    }}
                    textStyle={{ fontSize: 14, fontWeight: 600, color: colors.white }}
                    btnText={'Next'}
                    backColor={colors.primary03}
                    onclick={() => {
                      if (EmailBuilder) {
                        setOpenCustomHTML(true);
                        handleEBhtml();
                        setShowHTMLPreview(true);
                      } else {
                        setNextClicked(true);
                        eventsend('Next1_button_campaign_pop_up_clicked ');
                        eventsend('Next_Campaign_popup_clicked');
                        setCurrentScreen('ann_templates');
                      }
                    }}
                    // disabled={campaignName && (labelName || selectedTagsList) && channelSelected ? false : true}
                    disabled={
                      (campaignName &&
                        labelName &&
                        !checkNullValues(attributeData) &&
                        verify &&
                        (balance?.mobileArr?.length !== 0 || balance?.emailArr?.length !== 0) &&
                        channelSelected) ||
                        (campaignName &&
                          selectedTagsList.length !== 0 &&
                          (balance?.mobileArr?.length !== 0 || balance?.emailArr?.length !== 0) &&
                          channelSelected)
                        ? false
                        : true
                    }
                  />
                </div>
                {/* Footer End */}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AnnNewModal;

const AttributeRow = ({ item, attributes, setOptionsAction, onDeleteAction }) => {
  const [variable, setVariable] = useState(item?.attributeName);
  const [condition, setCondition] = useState(item?.conditionOperator);
  const [value1, setValue1] = useState(item?.value?.split(',')[0] || '');
  const [value2, setValue2] = useState(item?.value?.split(',')[1] || '');
  const [variables, setVariables] = useState([]);

  const conditionArr = [
    { key: 'equal', value: 'equal (==)' },
    { key: 'notEqual', value: 'not equal (!=)' },
    { key: 'greaterThanOrEqual', value: 'greater than or equal (>=)' },
    { key: 'lessThanOrEqual', value: 'less than or equal (<=)' },
    { key: 'contains', value: 'contains' },
    { key: 'notContains', value: 'not contains' },
  ];

  useEffect(() => {
    if (variable === 'index') {
      setCondition('between');
    } else if (item?.conditionOperator !== condition) {
      setCondition(item?.conditionOperator);
    }

    if (variable && item?.attributeName !== variable) {
      setVariable(item?.attributeName);
    }

    if (item?.value) {
      const [v1, v2] = item.value.split(',');
      setValue1(v1 || '');
      setValue2(v2 || '');
    }
  }, [item]);

  useEffect(() => {
    if (condition === 'between') {
      setOptionsAction(variable, condition, `${value1},${value2}`);
    } else {
      setOptionsAction(variable, condition, value1);
    }
  }, [variable, condition, value1, value2]);

  const handleConditionOptions = () => {
    if (variable === 'index') {
      return [{ key: 'between', value: 'between' }];
    }
    return conditionArr;
  };

  return (
    <>
      <div
        style={{ marginTop: '10px', alignItems: 'center' }}
        className="d-flex flex-row justify-content-evenly align-items-center w-100 "
      >
        <InputGroup className="mb-2" style={{ width: '30%' }}>
          <Form.Label style={{ fontSize: 14, fontWeight: 600, color: colors.black }}>
            {'Attribute Name'}
          </Form.Label>
          <Form.Select
            className="w-100 btncustom dropdown"
            style={{
              borderRadius: 8,
              fontSize: 16,
              fontWeight: 400,
              color: colors.greys01,
            }}
            value={variable}
            onChange={(e) => setVariable(e.target.value)}
          >
            <option hidden value>
              {'Select'}
            </option>
            <option disabled>{'Attributes'}</option>

            {attributes?.map((attr) => (
              <option key={attr} value={attr}>
                {attr}
              </option>
            ))}

            {variables?.length > 0 && (
              <>
                <option disabled>{'Variables'}</option>
                {variables.map((varItem) => (
                  <option key={varItem} value={varItem}>
                    {varItem}
                  </option>
                ))}
              </>
            )}
          </Form.Select>
        </InputGroup>

        <InputGroup className="mb-2" style={{ width: '30%' }}>
          <Form.Label style={{ fontSize: 14, fontWeight: 600, color: colors.black }}>
            {'Condition'}
          </Form.Label>
          <Form.Select
            className="w-100 btncustom dropdown"
            style={{
              borderRadius: 8,
              fontSize: 16,
              fontWeight: 400,
              color: colors.greys01,
            }}
            value={condition}
            onChange={(e) => setCondition(e.target.value)}
            disabled={variable === 'index'}
          >
            <option hidden value>
              {'Select'}
            </option>
            {handleConditionOptions().map((cond) => (
              <option key={cond.key} value={cond.key}>
                {cond.value}
              </option>
            ))}
          </Form.Select>
        </InputGroup>

        {condition === 'between' ? (
          <>
            <InputGroup className="mb-2" style={{ width: '15%' }}>
              <Form.Label style={{ fontSize: 14, fontWeight: 600, color: colors.black }}>
                {'Value 1'}
              </Form.Label>
              <Form.Control
                type="text"
                className="w-100 btncustom"
                style={{
                  borderRadius: 8,
                  fontSize: 16,
                  fontWeight: 400,
                  color: colors.greys01,
                }}
                value={value1}
                onChange={(e) => setValue1(e.target.value)}
              />
            </InputGroup>

            <InputGroup className="mb-2" style={{ width: '15%' }}>
              <Form.Label style={{ fontSize: 14, fontWeight: 600, color: colors.black }}>
                {'Value 2'}
              </Form.Label>
              <Form.Control
                type="text"
                className="w-100 btncustom"
                style={{
                  borderRadius: 8,
                  fontSize: 16,
                  fontWeight: 400,
                  color: colors.greys01,
                }}
                value={value2}
                onChange={(e) => setValue2(e.target.value)}
              />
            </InputGroup>
          </>
        ) : (
          <InputGroup className="mb-2" style={{ width: '30%' }}>
            <Form.Label style={{ fontSize: 14, fontWeight: 600, color: colors.black }}>
              {'Attribute Value'}
            </Form.Label>
            <Form.Control
              type="text"
              className="w-100 btncustom"
              style={{
                borderRadius: 8,
                fontSize: 16,
                fontWeight: 400,
                color: colors.greys01,
              }}
              value={value1}
              onChange={(e) => setValue1(e.target.value)}
            />
          </InputGroup>
        )}

        <img
          src={images.DeleteBlack}
          style={{
            height: 24,
            width: 24,
            marginTop: 15,
            objectFit: 'contain',
            marginInlineStart: 8,
            cursor: 'pointer',
          }}
          onClick={onDeleteAction}
        />
      </div>
    </>
  );
};

const Styles = {
  DarkBg: {
    backgroundColor: 'rgba(0,0,0,0.4)',
    width: '100vw',
    height: '100vh',
    // flex:1,
    zIndex: 1,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    position: 'fixed',
  },
  Center: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
  },
  TxtHeading: {
    fontSize: 24,
    fontWeight: 700,
    color: colors.black,
  },
  TxtHeading2: {
    fontSize: 16,
    fontWeight: 700,
    color: colors.black,
  },
  TxtBody2: {
    fontSize: 14,
    fontWeight: 400,
    color: colors.greys04,
  },
  TxtBody1: {
    fontSize: 16,
    fontWeight: 400,
    color: colors.primary03,
  },
  borderstyle: {
    backgroundColor: colors.white,
    borderRadius: 16,
    boxShadow: '0px 0px 10px 0 rgba(0, 0, 0, 0.15)',
  },
  borderDashed: {
    borderStyle: 'dashed',
    borderWidth: 1,
    borderColor: colors.primary04,
    borderRadius: 40,
  },
  TxtBody: {
    fontSize: 16,
    fontWeight: 600,
    color: colors.black04,
  },
};
