import React, { useCallback, useEffect, useState } from 'react'
import colors from '../../../Utils/colors';
import { Handle, Position } from 'reactflow';
import CustomHandle from './CustomHandle';
import images from '../../../Utils/images';

const handleStyle = { backgroundColor: '#0064E1', height: 15, width: 15, borderRadius: "100%", top: '60%' };

const PixelEventNode = ({ data, isConnectable }) => {

    return (
        <>
            <div className='d-flex flex-column justify-content-between align-items-center' style={{ border: `1px solid ${colors.borderwhite}`, borderRadius: 16, backgroundColor: colors.white01, width: 250 }}>
                <Handle type='target' position={Position.Left} isConnectable={isConnectable} style={handleStyle} />
                <div className='w-100 d-flex flex-row justify-content-between align-items-center' style={{ paddingInline: 16, paddingBlock: 8, backgroundColor: '#0064E1', borderTopLeftRadius: 16, borderTopRightRadius: 16 }}>
                    <p style={{ padding: 0, margin: 0, fontSize: 16, fontWeight: 700, color: colors.white }}>
                        {'Add a Pixel Event'}
                    </p>
                    <img src={images.Edit} style={{ height: 20, width: 20, objectFit: 'contain', cursor: 'pointer' }}></img>
                </div>
                <div className='w-100 d-flex flex-column justify-content-start align-items-center' style={{ paddingInline: 16, paddingBlockStart: 8, paddingBlockEnd: 16 }}>
                    <p style={{ width: '100%', margin: 0, fontSize: 12, fontWeight: 400, color: colors.black }}>
                        {data?.selectedPixel?.name ?? 'Select Options'}
                    </p>
                </div>
                <CustomHandle type='source' position={Position.Right} style={handleStyle} isConnectable={1} />
            </div>
        </>
    )
}

export default PixelEventNode;