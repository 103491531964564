import React, { useEffect, useState } from 'react'
import colors from '../../../Utils/colors'
import { useSelector } from 'react-redux';
import { getAllSenderId } from '../../../Services';
import images from '../../../Utils/images';
import { Checkbox } from '@mui/material';
import CheerioButton from '../../../Components/CheerioButton';
import SpinnerLoader from '../../../Components/SpinnerLoader';

const SelectSenderIDModal = ({ setIsModalOpen, selectedSenderID, setSelectedSenderID }) => {

    const authToken = useSelector((state) => state.main.auth_token); 
    const [senderData,setSenderData] = useState(null) 
    const [isLoading, setIsLoading] = useState(true);

    useEffect(()=>{
        getAllSenderIdApiCall();
    },[])

    const getAllSenderIdApiCall = () => {
        const token = authToken;
        getAllSenderId(token).then((res) => {
            if (res?.flag) {
                setSenderData(res?.data);
            } else {
                // toast(res?.message, { position: 'top-center' });
            }
            setIsLoading(false)
        });
    };

    const isRowSelected = (id) => selectedSenderID.includes(id);
    const handleCheckboxChange = (id) => {
        setSelectedSenderID((prev) =>
            prev.includes(id)
                ? prev.filter((senderId) => senderId !== id) // Remove if already selected
                : [...prev, id] // Add if not selected
        );
    };

  return (
    <>
    <div className='DarkBg' onClick={()=>{}}>
        <div className='Center'>
            <div className='Column' style={{
                justifyContent:'flex-start',
                width: 760,
                backgroundColor: colors.white01,
                borderRadius:16,
                zIndex:0,
                border:`1px solid ${colors.borderwhite}`,
                paddingInline:40,
                paddingBlock:24,
            }}>
                <div className='Row justify-content-between w-100' style={{
                    paddingBlockEnd:20,
                    borderBottom:`1px solid ${colors.borderwhite}`
                }}>
                    <h4 style={{fontSize:16,fontWeight:600}}>
                        {"Sender ID's"}
                    </h4>
                    <img 
                        src={images.CMClose} 
                        style={{height:20,width:20,objectFit:'contain',cursor:'pointer'}} 
                        onClick={()=>{setIsModalOpen(false)}}
                    >
                    </img>
                </div>

                <div className='Column justify-content-start w-100' style={{
                    height:250,
                    overflowY: 'auto',
                }}>
                    {isLoading ? (
                        <>
                        <div className='Column justify-content-center w-100 h-100' style={{}}>
                            <SpinnerLoader />
                        </div>
                        </>
                    ):(
                        <>
                        <table style={{width:'100%'}}>
                            <thead style={{backgroundColor:'#F5F5F5'}}>
                                <tr>
                                    <th className='py-2' scope='col' style={{width:'10%',paddingInline:10}}>
                                        {''}
                                    </th>
                                    <th className='py-2' scope='col' align='center' style={{width:'30%',paddingInline:10}}>
                                        {'Name'}
                                    </th>
                                    <th className='py-2' scope='col' align='center' style={{width:'45%',paddingInline:10}}>
                                        {'Email'}
                                    </th>
                                    <th className='py-2' scope='col' align='center' style={{width:'15%',paddingInline:10}}>
                                        {'Daily limit'}
                                    </th>
                                </tr>
                            </thead>
                            <tbody style={{backgroundColor:colors.white01}}>
                                {senderData && Object.entries(senderData).map((data,index)=>(
                                    <tr key={index} style={{paddingBlock:8}}>
                                        <td>
                                            <Checkbox 
                                                checked={isRowSelected(data[1]?._id)} 
                                                onChange={()=>{
                                                    handleCheckboxChange(data[1]?._id)
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <div className='px-2 py-2 Row justify-content-start' style={{overflow:'hidden',textOverflow:'ellipsis'}}>
                                                <p>
                                                    {data[1]?.from?.name}
                                                </p> 
                                            </div>
                                        </td>
                                        <td>
                                            <div className='px-2 py-2 Row justify-content-start' style={{overflow:'hidden',textOverflow:'ellipsis'}}>
                                                <p>
                                                    {data[1]?.from?.email}
                                                </p> 
                                            </div>
                                        </td>
                                        <td>
                                            <div className='px-2 py-2 Row justify-content-center' style={{overflow:'hidden',textOverflow:'ellipsis'}}>
                                                <p>
                                                    {`${data[1]?.usage?.sentToday ?? '0'}/${data[1]?.settings?.messagePerDay}`}
                                                </p> 
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        </>
                    )}
                    
                </div>
                <CheerioButton 
                    btnText={'Save'}
                    textStyle={{color:colors.white01}}
                    backColor={colors.primary03}
                    borderStyle={{borderColor:colors.primary03,width:'98%',marginBlock:16}} 
                    onclick={()=>{setIsModalOpen(false)}} 
                    disabled={(selectedSenderID?.length > 0) ? false : true}
                />
            </div>
        </div>
    </div>
    </>
  )
}

export default SelectSenderIDModal