const formatTimestampForDisplay = (timestamp) => {
    const date = new Date(timestamp);
    if (isNaN(date.getTime())) {
        return "NA";
    }

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    const hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const period = hours >= 12 ? 'PM' : 'AM';

    const formattedHour = String(hours % 12 || 12).padStart(2, "0");

    return `${day}/${month}/${year}, ${formattedHour}:${minutes} ${period}`;
};
const formatTimeForDisplay = (time) => {
    if (!time) {
        return "NA";
    }
    const [hours, minutes] = time?.split(":");
    const hour = parseInt(hours, 10);
    if (isNaN(hour) || isNaN(parseInt(minutes, 10)) || hour < 0 || hour > 23 || minutes < 0 || minutes > 59) {
        return "NA"
    }
    const suffix = hour >= 12 ? "PM" : "AM";
    const displayHour = String(hour % 12 || 12).padStart(2, "0");
    const displayMinute = String(minutes).padStart(2, "0");
    return `${displayHour}:${displayMinute} ${suffix}`;
};
const formatDateForDisplay = (value) => {
    if (!value) {
        return "NA";
    }
    const [year, month, day] = value?.split("-");
    if (
        isNaN(parseInt(year, 10)) ||
        isNaN(parseInt(month, 10)) ||
        isNaN(parseInt(day, 10)) ||
        month < 1 || month > 12 ||
        day < 1 || day > 31
    ) {
        return "NA";
    }
    return `${day}/${month}/${year}`;
};

const formatTimestampForInput = (timestamp) => {
    const [datePart, timePart] = timestamp?.split(', ');
    if (!datePart || !timePart) {
        return 'NA';
    }
    const [day, month, year] = datePart?.split('/')?.map((part) => part?.padStart(2, '0'));
    const [time, period] = timePart?.split(' ');
    const [hours, minutes] = time?.split(':');

    let hour = parseInt(hours, 10);

    if (period === 'PM' && hour !== 12) {
        hour += 12;
    }


    return `${year}-${month}-${day}T${hour < 10 ? '0' : ''}${hour}:${minutes}`; // 2021-01-01T12:00

    // return `${year}-${month}-${day}T${hours}:${minutes}`;
};

const formatTimeForInput = (time) => {
    const [timePart, period] = time?.split(' ');
    const [hours, minutes] = timePart?.split(':');

    let hour = parseInt(hours, 10);

    if (period === 'PM' && hour !== 12) {
        hour += 12;
    } else if (period === 'AM' && hour === 12) {
        hour = 0;
    }

    return `${hour < 10 ? '0' : ''}${hour}:${minutes}`;
};

const formatDateForInput = (value) => {
    const [day, month, year] = value?.split("/");
    if (
        isNaN(parseInt(year, 10)) ||
        isNaN(parseInt(month, 10)) ||
        isNaN(parseInt(day, 10)) ||
        month < 1 || month > 12 ||
        day < 1 || day > 31
    ) {
        return "NA";
    }
    return `${year}-${month}-${day}`;
};

export { formatTimestampForDisplay, formatTimeForDisplay, formatDateForDisplay, formatTimestampForInput, formatTimeForInput, formatDateForInput };