// index.js (for Sequences)
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import SequencesPage from './Components/Sequences';
import CreateSequencePage from './Components/CreateSequence';
import LinkedinSequenceAnalytics from './Components/LinkedinSequenceAnalytics';
const SequenceHome = () => {
  return (
    <Routes>
      <Route path="/" element={<SequencesPage />} />
      <Route path="/create" element={<CreateSequencePage />} />
      <Route path='analytics/:id' element={<LinkedinSequenceAnalytics />} ></Route>
    </Routes>
  );
};

export default SequenceHome;