import React from 'react'
import colors from '../../../Utils/colors';
import { Handle, Position } from 'reactflow';
import images from '../../../Utils/images';

const handleStyle = { backgroundColor: colors.yellow03, height: 15, width: 15, borderRadius: "100%" };

const AssignToChatBot = ({ data, isConnectable }) => {
    return (
        <>
            <div className='d-flex flex-column justify-content-between align-items-center' style={{ border: `1px solid ${colors.borderwhite}`, borderRadius: 16, backgroundColor: colors.white01, width: 230 }}>
                <Handle type='target' position={Position.Left} isConnectable={isConnectable} style={handleStyle} />
                <div className='w-100 d-flex flex-column justify-content-start align-items-center' style={{ paddingInline: 16, paddingBlock: 8, backgroundColor: colors.yellow04, borderTopLeftRadius: 16, borderTopRightRadius: 16 }}>
                    <div style={{ height: 4, width: '20%', borderRadius: 2, backgroundColor: colors.white, marginBlockEnd: 4 }}></div>
                    <div className='w-100 d-flex flex-row justify-content-between align-items-center' style={{}}>
                        <p style={{ padding: 0, margin: 0, fontSize: 16, fontWeight: 700, color: colors.white }}>
                            {'Assign to chatbot'}
                        </p>
                        {/* <img src={images.Edit} style={{height:20,width:20,objectFit:'contain'}}></img> */}
                    </div>
                </div>
                {/* <div className='w-100 d-flex flex-row justify-content-between align-items-center' style={{paddingInline:16,paddingBlock:8,backgroundColor:colors.yellow04,borderTopLeftRadius:16,borderTopRightRadius:16}}>
            <p style={{padding:0,margin:0,fontSize:16,fontWeight:700,color:colors.white}}>
                {'Assign to chatbot'}
            </p>
        </div> */}
                <div className='w-100 d-flex flex-column justify-content-start align-items-center' style={{ paddingInline: 16, paddingBlockStart: 8, paddingBlockEnd: 16, backgroundColor: colors.yellow04, borderBottomLeftRadius: 16, borderBottomRightRadius: 16 }}>
                    <p style={{ padding: 0, margin: 0, marginBlock: 4, fontSize: 12, fontWeight: 400, color: colors.white, width: '100%' }}>
                        {'Chatbot will attempt to resolve query otherwise it will be assigned to human agent'}
                    </p>
                </div>
            </div>
        </>
    )
}

export default AssignToChatBot