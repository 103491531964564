import React, { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form';
import colors from '../../../Utils/colors';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import SpinnerLoader from '../../../Components/SpinnerLoader';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const LinkedinGraphComponent = ({ graphData, graphIsLoading = false }) => {
    const [labels, setLabels] = useState(['Actions']);

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'bottom',
            },
        },
        scales: {
            y: {
                beginAtZero: true,
                max: graphData?.totalLeads,
                ticks: {
                    stepSize: (graphData?.totalLeads / 10)   // This will show ticks at intervals of 50
                }
            }
        }
    };

    const processQueueData = () => {
        const stats = {
            sentInvite: 0,
            inviteEmail: 0,
            accepted: 0,
            message: 0,
            responded: 0,
            viewedProfile: 0,
            FlowCompleted: 0,
        };

        // Create a map of node IDs to their types
        const nodeTypeMap = {};
        graphData.nodes.forEach(node => {
            nodeTypeMap[node.nodeId] = node.type;
        });

        // Process each queue
        graphData.linkedinQueues.forEach(queue => {
            if (queue.nodesExecuted && queue.nodesExecuted.length > 0) {
                queue.nodesExecuted.forEach(nodeId => {
                    const nodeType = nodeTypeMap[nodeId];
                    switch (nodeType) {
                        case 'sendInviteNode':
                            stats.sentInvite++;
                            break;
                        case 'checkMailNode':
                            stats.inviteEmail++;
                            break;
                        case 'ifConnectedNode':
                            stats.accepted++;
                            break;
                        case 'inboxMessageNode':
                            stats.message++;
                            break;
                        case 'IfMessageRepliedNode':
                            stats.responded++;
                            break;
                        case 'viewProfileNode':
                            stats.viewedProfile++;
                            break;
                        case 'endFlowNode':
                            stats.FlowCompleted++;
                            break;
                    }
                });
            }
        });

        return stats;
    };

    const stats = processQueueData();

    const data = {
        labels,
        datasets: [
            {
                label: 'Sent Invite',
                backgroundColor: '#9357FF',
                data: [stats.sentInvite],
            },
            {
                label: 'Accepted',
                backgroundColor: '#EEB728',
                data: [stats.accepted],
            },
            {
                label: 'Message',
                backgroundColor: '#51C1C1',
                data: [stats.message],
            },
            {
                label: 'Viewed Profile',
                backgroundColor: '#E9758A',
                data: [stats.viewedProfile],
            },
            {
                label: 'Flow Completed',
                backgroundColor: '#BF51C1',
                data: [stats.FlowCompleted],
            },
        ],
    };

    return (
        <>
            <div className='graph_component_container' style={{ width: '100%', paddingInline: 0 }}>
                <div className='graph_component_div shadow_small'>
                    <div className='graph_component_div__row'>
                        <h3>
                            {'LinkedIn Sequence Actions'}
                        </h3>
                    </div>
                    <div className='graph_component_div__graph'>
                        {graphIsLoading ? (
                            <>
                                <SpinnerLoader />
                            </>
                        ) : (
                            <>
                                <Bar options={options} data={data} height={'300px'} />
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default LinkedinGraphComponent
