import React, { useEffect, useState } from 'react';
import colors from '../../../Utils/colors';
import CheerioButton from '../../../Components/CheerioButton';
import { useSelector } from 'react-redux';
import { getShopifyAnalyticsData, getShopifyAnalyticsGraphData, ShopifyAnalyticsAPIV2 } from '../../../Services';
//import DatePicker from 'react-datepicker';
//import 'react-datepicker/dist/react-datepicker.css';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import images from '../../../Utils/images';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import SpinnerLoader from '../../../Components/SpinnerLoader';
import { DetailUserAnalytics } from './DetailUserAnalytics';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { eventsend } from '../../../Config/analyticsFunctions';
import GraphComponent from '../../AgentAnalytics/Components/GraphComponent';
import { toast } from 'react-toastify';

const SAItemTile = ({ imageSrc, itemText, itemValue }) => {
  return (
    <>
      <div className='py-1 px-2 m-1' style={{ width: '33%' }}>
        <div className='px-3 py-3 d-flex flex-column justify-content-start align-items-start w-100' style={{
          backgroundColor: colors.white01,
          borderRadius: 16,
          boxShadow: '0px 0px 10px 0 rgba(0, 0, 0, 0.15)',
          height: 'auto',
        }}>
          <div className='d-flex flex-column justify-content-center align-items-center' style={{
            height: 50,
            width: 50,
            borderRadius: 12,
            backgroundColor: colors.white03,
          }}>
            <img style={{
              height: 24,
              width: 24,
              objectFit: 'contain',
            }} src={imageSrc}></img>
          </div>
          <p className='my-3' style={{
            width: '100%',
            fontSize: 16,
            fontWeight: 400,
            color: colors.black,
          }}>
            {itemText}
          </p>
          <p className='' style={{
            width: '100%',
            fontSize: 32,
            fontWeight: 700,
            color: colors.black,
          }}>
            {itemValue}
          </p>
        </div>
      </div>
    </>
  )
}

const ShopifyAnalytics = ({ }) => {
  const localdata = useSelector((state) => state.main.localdata);
  const _id = useSelector((state) => state.main._id);
  const authtoken = useSelector((state) => state.main.auth_token);

  const [selectedDuration, setSelectedDuration] = useState('7');
  const [analyticsData, setAnalyticsData] = useState();
  const [SAGraphData, setSAGraphData] = useState();
  const [graphLoading, setGraphLoading] = useState(false);

  useEffect(() => {
    getSAGraphDataAPIcall();
  }, []);

  useEffect(() => {
    getSADataAPIcall()
  }, [selectedDuration]);

  const getSAGraphDataAPIcall = () => {
    setGraphLoading(true)
    let token = authtoken;
    getShopifyAnalyticsGraphData(token).then((res) => {
      if (res?.flag) {
        setSAGraphData(res?.data);
        // console.log("3333333333 ", res);
      } else {
        toast.error('Failed to fetch analytics graph data, please try again later', { autoClose: true, position: 'top-right' })
        // console.log("3333333333 ", res);
      }
      setGraphLoading(false);
    })
  }

  const getSADataAPIcall = () => {

    const today = new Date();
    const endDate = today.toISOString().split("T")[0]; // Format: YYYY-MM-DD

    const daysToSubtract = parseInt(selectedDuration ?? 7, 10);
    const startDate = new Date(today);
    startDate.setDate(today.getDate() - daysToSubtract);
    const formattedStartDate = startDate.toISOString().split("T")[0];


    let token = authtoken;
    let data = {
      startDate: formattedStartDate,
      // endDate: endDate,
    }
    getShopifyAnalyticsData(token, data).then((res) => {
      if (res?.flag) {
        setAnalyticsData(res?.data)
        // console.log("22222222222 ", res)
      } else {
        toast.error('Failed to fetch analytics data, please try again later', { autoClose: true, position: 'top-right' })
        // console.log("222222222223333 ", res)
      }
    })
  }

  return (
    <>
      <div
        className="w-100 h-100 verythinscrollnew"
        id="main"
        style={{
          overflowY: 'auto',
          backgroundColor: colors.white02,
          border: `1px solid ${colors.borderwhite}`,
        }}
      >
        <div
          className="d-flex flex-row justify-content-between align-items-center w-100"
          style={{ paddingInline: 30, marginBlock: 20 }}
        >
          <h3 style={{ color: colors.black }}>
            {'Analytics'}
          </h3>

          <InputGroup
            className="btncustom"
            style={{
              width: 'auto',
              marginInline: 8,
              marginBlock: 6,
              height: '100%',
            }}
          >
            <InputGroup.Text
              id="basic-addon1"
              style={{ fontSize: 12, fontWeight: 600, color: colors.black, cursor: 'default' }}
            >
              {'View'}
            </InputGroup.Text>
            <Form.Select
              className="btncustom"
              aria-label="Duration selector"
              style={{
                fontSize: 12,
                fontWeight: 600,
                color: colors.greys04,
                cursor: 'pointer',
              }}
              value={selectedDuration}
              onChange={(e) => {
                // console.log("777777 ", e.target.value)
                setSelectedDuration(e.target.value)
              }}
            >
              <option value={'1'} key={'1'}>
                {'1 day'}
              </option>
              <option value={'7'} key={'2'}>
                {'7 days'}
              </option>
              <option value={'30'} key={'3'}>
                {'1 month'}
              </option>
              <option value={'182'} key={'4'}>
                {'6 months'}
              </option>
              <option value={'365'} key={'5'}>
                {'1 year'}
              </option>
            </Form.Select>
          </InputGroup>
        </div>

        <div className='d-flex flex-row justify-content-between align-items-stretch w-100 my-3' style={{
          paddingInline: 30,
        }}>
          <SAItemTile
            imageSrc={images.SARevenue}
            itemText={'Revenue Recovered'}
            itemValue={analyticsData ? `₹${analyticsData?.additionalRevenue.toFixed(2)}` : '--'}
          />
          <SAItemTile
            imageSrc={images.SAMessaging}
            itemText={'Messaging Cost'}
            itemValue={analyticsData ? `₹${analyticsData?.messsagingCost?.cost.toFixed(2)}` : '--'}
          />
          <SAItemTile
            imageSrc={images.SAROI}
            itemText={'Return on Investment'}
            itemValue={analyticsData && analyticsData?.messsagingCost?.cost
                ? `${Math.floor(Number(analyticsData?.additionalRevenue) / Number(analyticsData?.messsagingCost?.cost))}x`: '--'}
          />
        </div>

        <div className='d-flex flex-row justify-content-between align-items-stretch w-100 my-3' style={{
          paddingInline: 30,
        }}>
          <SAItemTile
            imageSrc={images.SACartAbandoned}
            itemText={'Cart Abandoned'}
            itemValue={analyticsData ? `${analyticsData?.abandoned}` : '--'}
          />
          <SAItemTile
            imageSrc={images.SACartRecovered}
            itemText={'Cart Recovered'}
            itemValue={analyticsData ? `${analyticsData?.recovered}` : '--'}
          />
          <SAItemTile
            imageSrc={images.SACOD}
            itemText={'COD-to-Prepaid'}
            itemValue={analyticsData ? `${analyticsData?.codToPrepaid}` : '--'}
          />
        </div>

        <div className='d-flex flex-row justify-content-between align-items-stretch w-100 my-3' style={{
          paddingInline: 30,
        }}>
          <GraphComponent
            graphTimeframe={'1w'}
            graphData={SAGraphData}
            graphIsLoading={graphLoading}
            isShopify={true}
          />
        </div>
      </div>
    </>
  );
};

export default ShopifyAnalytics;
