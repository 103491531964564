import images from '../../../Utils/images';
import Form from 'react-bootstrap/Form';
import { useState, useEffect, useRef } from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import colors from '../../../Utils/colors';
import { Checkbox } from '@mui/material';
import { V2AgentGet } from '../../../Services';
import { useSelector } from 'react-redux';
import { IoMdCloseCircleOutline } from 'react-icons/io';
import { Tooltip } from '@mui/material';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import EmojiPicker from 'emoji-picker-react';
import UploadValidator from '../../../Components/UploadValidator';
import UploaderText from '../../../Components/UploaderText';
import { toast } from 'react-toastify';
import { V2WorkflowS3Link } from '../../../Services';
import { getImagePath } from '../../../Utils';
import { TextField, InputAdornment } from "@mui/material";
import { FaLink } from "react-icons/fa6";
import { RiDeleteBinLine } from "react-icons/ri";
import ReplayIcon from "@mui/icons-material/Replay";
import { Button } from "@mui/material";
import { BsTextLeft } from "react-icons/bs";
import { BsImage } from "react-icons/bs";
import { BsCameraVideo } from "react-icons/bs";
import { BsMic } from "react-icons/bs";
import { IoCardOutline } from "react-icons/io5";
import { IoReturnUpBackSharp } from "react-icons/io5";
import { GoLinkExternal } from "react-icons/go";
import { IoMdCopy } from 'react-icons/io';
import { BiCube } from "react-icons/bi";
import { getInstagramCatalogProductsAPI } from '../../../Services';
import { IoMdAdd } from 'react-icons/io';
import { RiCloseLine } from 'react-icons/ri';
import { eventsend } from '../../../Config/analyticsFunctions';
import { AiOutlineInfoCircle } from 'react-icons/ai';
export const ImageUploader = ({ data, setData, setShowSpecificContent, setMessage, variable, setVariable, skipReply, setSkipReply }) => {
  const [upFile, setUpFile] = useState(null);
  const [upFile64, setUpFile64] = useState('');
  const [upFileName, setUpFileName] = useState('');
  const [dragActive, setDragActive] = useState(false);
  const [s3Link, setS3Link] = useState(null);
  const upImageRef = useRef();
  const [imageUrl, setImageUrl] = useState('');
  const authtoken = useSelector((state) => state.main.auth_token);

  useEffect(() => {
    if (data?.mediaUrl) {
      setS3Link(data?.mediaUrl);
    }
    if (data?.variable) {
      setVariable(data?.variable);
    }
    if (data?.skipReply) {
      setSkipReply(data?.skipReply);
    }
    eventsend("Image_Reply-in-dm_instagram-action-node_selected");
  }, []);


  useEffect(() => {
    setData({
      ...data,
      mediaUrl: s3Link,
      type: data?.type,
    });
  }, [upFile, upFile64, s3Link]);

  const handlefileupload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      if (UploadValidator(file, 'Image')) {
        setUpFile(file);
        setUpFileName(file.name);
        const base64 = await convertToBase64(file);
        setUpFile64(base64);
      }
    } else {
      toast.error('Unexpected error occurred, please try again later.', {
        position: 'top-center',
      });
    }
  };

  const handleDragFile = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDropFile = async (e, type) => {
    e.preventDefault();
    e.stopPropagation();
    console.log('file ===> ', e.dataTransfer.files[0]);
    const file = e.dataTransfer.files[0];
    if (file && type) {
      if (UploadValidator(file, type)) {
        setUpFile(file);
        setUpFileName(file.name);
        const base64 = await convertToBase64(file);
        setUpFile64(base64);
        setImageUrl('');
      }
    } else {
      toast.error('Unexpected error occurred, please try again later.', {
        position: 'top-center',
      });
    }
  };

  const handleImageUrl = async (image) => {
    const url = image;
    if (url === '') {
      return;
    }
    if (upFileName) {
      toast.error('Delete uploaded image and try again', {
        position: 'top-center',
      });
      return;
    }
    // const regex = /^(https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&//=]*\.(?:png|jpg|jpeg|gif|bmp|svg)))$/i;
    // ;
    // console.log(url);
    // if (!regex.test(url)) {
    //   toast.error('Invalid image URL. Please check the URL and try again.', {
    //     position: 'top-center',
    //
    //   });
    //   return;
    // }
    setImageUrl(url);
    setUpFile(null);
    setUpFile64('');

    if (url) {
      try {
        const response = await fetch(url)
          .then((res) => {
            if (res.ok) {
              return res;
            } else {
              console.error('Error:', res.statusText);
            }
          })
          .catch((error) => {
            console.error('Error:', error);
          });
        const blob = await response.blob();
        const file = new File([blob], 'image_from_url.jpg', { type: blob.type });
        const base64 = await convertToBase64(file);
        setUpFile(file);
        setUpFile64(base64);
        setUpFileName(file.name);
      } catch (error) {
        toast.error('Not able to fetch image.Please check your url.', {
          position: 'top-center',
        });
      }
    }
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  useEffect(() => {
    if (upFile64) {
      getS3Link();
    }
  }, [upFile64]);

  const getS3Link = () => {
    let data = {
      image: upFile64,
      type: upFile?.type?.split('/')[0],
      format: upFile?.type?.split('/')[1],
    };
    let token = authtoken;
    toast.promise(
      V2WorkflowS3Link(data, token),
      {
        pending: 'Uploading file...',
        success: {
          render({ data }) {
            setS3Link(data?.data?.url);
            console.log('s3 link success response', data);
            return 'File uploaded successfully!';
          },
          icon: '🟢',
          autoClose: true,
          isLoading: false,
        },
        error: {
          render({ data }) {
            console.log('s3 link error response', data);
            return 'Error occurred while uploading file, please try again later.';
          },
          icon: '🔴',
          autoClose: true,
          isLoading: false,
        },
      },
      {
        position: 'top-center',
      }
    );
  };


  const handleBackButton = () => {
    setShowSpecificContent(false);
    setMessage('');
    setVariable('');  // Reset variable
    setSkipReply(false);  // Reset skipReply
    setData({
      ...data,
      type: '',
      message: '',
      mediaUrl: undefined,
      variable: '',
      skipReply: false
    });
  };


  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      gap: '12px',
      padding: '20px 0px',
      background: '#FFFFFF',
      borderRadius: '12px',
      width: '100%'
    }}>
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 10,
        paddingBottom: 10,
        borderRadius: 10,
        alignItems: 'center',
        background: '#FAFAFA',
        cursor: 'pointer',
      }}
        onClick={handleBackButton}
      >
        <img
          src={images.BackArrowBlack}
          style={{ height: 18, width: 18, objectFit: 'contain', cursor: 'pointer', marginRight: 5 }}
        ></img>
        <span style={{ fontWeight: 700 }}>Change content block</span>
      </div>
      <div
        className="d-flex flex-column justify-content-center align-items-center w-100 my-2"
        style={s3Link ? containerStyle : { ...containerStyle, height: 260 }}
        onDragEnter={handleDragFile}
        onDragExit={handleDragFile}
        onDragOver={handleDragFile}
        onDrop={(e) => {
          handleDropFile(e, 'Image');
        }}
        onClick={() => {
          upImageRef.current.click();
        }}
      >
        {s3Link ? (
          <img
            src={getImagePath(s3Link)}
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
              objectFit: 'contain',
            }}
          />
        ) : (
          <>
            <img
              src={images.ImageUploadNew}
              style={{ height: '100%', width: '100%', objectFit: 'cover' }}
            />
          </>
        )}
        <input
          type="file"
          style={{ display: 'none' }}
          ref={upImageRef}
          accept="image/*"
          onChange={(e) => {
            handlefileupload(e);
          }}
        />
      </div>
      {s3Link && <div style={{ display: "flex", justifyContent: 'center', alignItems: "center", gap: "16px" }}>
        <Button
          onClick={() => {
            upImageRef.current.click();
          }}
          endIcon={<ReplayIcon />}
          style={{ textTransform: "none", color: 'black', borderRadius: 8, border: '1px solid black', minWidth: "150px", minHeight: '30px' }}
        >
          Replace Image
        </Button>

        <Button
          color="error"
          onClick={(e) => {
            e.stopPropagation();
            setUpFile(null);
            setUpFile64(null);
            setUpFileName('');
            setS3Link(null);
            setImageUrl('');
          }}
          endIcon={<RiDeleteBinLine />}
          style={{
            textTransform: "none",
            backgroundColor: "#FFDEDE",
            color: "#E00000",
            borderRadius: 8,
            minWidth: "150px",
            minHeight: '30px'
          }}
        >
          Delete
        </Button>
      </div>}
      {!s3Link && <TextField
        variant="outlined"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <FaLink />
              <span style={{ marginLeft: "8px", color: "#000000", fontSize: 14 }}>
                Image URL
              </span>
              <div
                style={{
                  height: "20px",
                  width: "1px",
                  backgroundColor: "#808080",
                  marginLeft: "8px",
                }}
              ></div>
            </InputAdornment>
          ),
          style: {
            backgroundColor: "#FAFAFA",
            borderRadius: "8px",
            border: 'none'
          },
        }}
        placeholder="Paste Image Url"
        value={imageUrl}
        onPaste={(event) => {
          const pastedURL = event.clipboardData.getData('text');
          handleImageUrl(pastedURL);
        }}
        onChange={(e) => {
          setImageUrl(e.target.value);
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleImageUrl(imageUrl);
          }
          if (e.key === 'Backspace') {
            e.stopPropagation();
            setUpFile(null);
            setUpFile64(null);
            setUpFileName('');
            setS3Link(null);
            setImageUrl(e.target.value);
          }
        }}
        fullWidth
      />}
      <div
        style={{
          background: '#FAFAFA',
          borderRadius: 10,
          padding: '12px',
          marginTop: '16px'
        }}
      >
        <div style={{ fontWeight: 600, marginBottom: '8px' }}>Save response as</div>
        <input
          className="form-control shadow-none"
          style={{
            width: '100%',
            padding: '8px',
            borderRadius: '4px',
            background: '#FAFAFA',
            marginBottom: '12px',
            border: '1px solid #EFEFEF'
          }}
          value={variable}
          onChange={(e) => {
            eventsend("save-replies-to-variable_Instagram-action-node_clicked");
            const val = e.target.value;
            if (val.startsWith('@')) {
              setVariable(val);
            } else {
              setVariable('@' + val);
            }
          }}
        />
        <div className="d-flex flex-row justify-content-between align-items-center">
          <Form.Check
            type="checkbox"
            className="btncustom"
            checked={skipReply}
            label="Skip user reply"
            onChange={() => {
              eventsend("skip-user-reply_Instagram-action-node_clicked");
              setSkipReply(!skipReply);
            }}
          />
          <Tooltip
            title={'Next node will trigger itself even if recipient does not reply'}
            placement="bottom-end"
          >
            <div>
              <AiOutlineInfoCircle size={20} style={{ marginInlineEnd: 16 }} />
            </div>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export const VideoUploader = ({ data, setData, setShowSpecificContent, setMessage, variable, setVariable, skipReply, setSkipReply }) => {
  const [upFile, setUpFile] = useState(null);
  const [upFile64, setUpFile64] = useState('');
  const [upFileName, setUpFileName] = useState('');
  const [dragActive, setDragActive] = useState(false);
  const [s3Link, setS3Link] = useState(null);
  const upVideoRef = useRef();
  // const [imageUrl, setImageUrl] = useState('');
  const MAX_FILE_SIZE = 26214400; // 25MB in bytes
  const ALLOWED_VIDEO_TYPES = [
    'video/mp4',
    'video/ogg',
    'video/avi',
    'video/mov',
    'video/webm',
    'video/quicktime', // for .mov files
    'video/x-msvideo'  // for .avi files
  ];

  useEffect(()=>{
    eventsend("Video_Reply-in-dm_instagram-action-node_selected");
  },[])

  const authtoken = useSelector((state) => state.main.auth_token);

  useEffect(() => {
    if (data?.mediaUrl) {
      setS3Link(data?.mediaUrl);
    }
    if (data?.variable) {
      setVariable(data?.variable);
    }
    if (data?.skipReply) {
      setSkipReply(data?.skipReply);
    }
  }, []);


  useEffect(() => {
    setData({
      ...data,
      mediaUrl: s3Link,
      type: data?.type,
    });
  }, [upFile, upFile64, s3Link]);

  const handlefileupload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > MAX_FILE_SIZE) {
        toast.error('File size limit exceeded. Maximum size is 25MB', {
          position: 'top-center',
        });
        return;
      }
      if (!ALLOWED_VIDEO_TYPES.includes(file.type)) {
        toast.error('Only MP4, OGG, AVI, MOV, and WEBM formats are supported', {
          position: 'top-center',
        });
        return;
      }
      setUpFile(file);
      setUpFileName(file.name);
      const base64 = await convertToBase64(file);
      setUpFile64(base64);
    } else {
      toast.error('Unexpected error occurred, please try again later.', {
        position: 'top-center',
      });
    }
  };


  const handleDragFile = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDropFile = async (e, type) => {
    e.preventDefault();
    e.stopPropagation();
    const file = e.dataTransfer.files[0];
    if (file && type === 'Video') {
      if (file.size > MAX_FILE_SIZE) {
        toast.error('File size limit exceeded. Maximum size is 25MB', {
          position: 'top-center',
        });
        return;
      }
      if (!ALLOWED_VIDEO_TYPES.includes(file.type)) {
        toast.error('Only MP4, OGG, AVI, MOV, and WEBM formats are supported', {
          position: 'top-center',
        });
        return;
      }
      setUpFile(file);
      setUpFileName(file.name);
      const base64 = await convertToBase64(file);
      setUpFile64(base64);
    } else {
      toast.error('Unexpected error occurred, please try again later.', {
        position: 'top-center',
      });
    }
  };
  // const handleImageUrl = async (image) => {
  //   const url = image;
  //   if (url === '') {
  //     return;
  //   }
  //   if (upFileName) {
  //     toast.error('Delete uploaded image and try again', {
  //       position: 'top-center',
  //     });
  //     return;
  //   }
  //   // const regex = /^(https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&//=]*\.(?:png|jpg|jpeg|gif|bmp|svg)))$/i;
  //   // ;
  //   // console.log(url);
  //   // if (!regex.test(url)) {
  //   //   toast.error('Invalid image URL. Please check the URL and try again.', {
  //   //     position: 'top-center',
  //   //
  //   //   });
  //   //   return;
  //   // }
  //   setImageUrl(url);
  //   setUpFile(null);
  //   setUpFile64('');

  //   if (url) {
  //     try {
  //       const response = await fetch(url)
  //         .then((res) => {
  //           if (res.ok) {
  //             return res;
  //           } else {
  //             console.error('Error:', res.statusText);
  //           }
  //         })
  //         .catch((error) => {
  //           console.error('Error:', error);
  //         });
  //       const blob = await response.blob();
  //       const file = new File([blob], 'image_from_url.jpg', { type: blob.type });
  //       const base64 = await convertToBase64(file);
  //       setUpFile(file);
  //       setUpFile64(base64);
  //       setUpFileName(file.name);
  //     } catch (error) {
  //       toast.error('Not able to fetch image.Please check your url.', {
  //         position: 'top-center',
  //       });
  //     }
  //   }
  // };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  useEffect(() => {
    if (upFile64) {
      getS3Link();
    }
  }, [upFile64]);

  const getS3Link = () => {
    let data = {
      image: upFile64,
      type: upFile?.type?.split('/')[0],
      format: upFile?.type?.split('/')[1],
    };
    let token = authtoken;
    toast.promise(
      V2WorkflowS3Link(data, token),
      {
        pending: 'Uploading file...',
        success: {
          render({ data }) {
            setS3Link(data?.data?.url);
            console.log('s3 link success response', data);
            return 'File uploaded successfully!';
          },
          icon: '🟢',
          autoClose: true,
          isLoading: false,
        },
        error: {
          render({ data }) {
            console.log('s3 link error response', data);
            return 'Error occurred while uploading file, please try again later.';
          },
          icon: '🔴',
          autoClose: true,
          isLoading: false,
        },
      },
      {
        position: 'top-center',
      }
    );
  };


  const handleBackButton = () => {
    setShowSpecificContent(false);
    setMessage('');
    setVariable('');
    setSkipReply(false);
    setData({
      ...data,
      type: '',
      message: '',
      mediaUrl: undefined,
      variable: '',
      skipReply: false,
    });
  };


  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      gap: '12px',
      padding: '20px 0px',
      background: '#FFFFFF',
      borderRadius: '12px',
      width: '100%'
    }}>
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 10,
        paddingBottom: 10,
        borderRadius: 10,
        alignItems: 'center',
        background: '#FAFAFA',
        cursor: 'pointer',
      }}
        onClick={handleBackButton}
      >
        <img
          src={images.BackArrowBlack}
          style={{ height: 18, width: 18, objectFit: 'contain', cursor: 'pointer', marginRight: 5 }}
        ></img>
        <span style={{ fontWeight: 700 }}>Change content block</span>
      </div>
      <div
        className="d-flex flex-column justify-content-center align-items-center w-100 my-2"
        style={s3Link ? containerStyle : { ...containerStyle, height: 260 }}
        onDragEnter={handleDragFile}
        onDragExit={handleDragFile}
        onDragOver={handleDragFile}
        onDrop={(e) => {
          handleDropFile(e, 'Video');
        }}
        onClick={() => {
          upVideoRef.current.click();
        }}
      >
        {s3Link ? (
          <video
            src={getImagePath(s3Link)}
            style={{
              maxWidth: '100%',
              maxHeight: '200px',
              objectFit: 'contain',
            }}
          />
        ) : (
          <>
            <img
              src={images.VideoPreview}
              style={{ height: '100%', width: '100%', objectFit: 'cover' }}
            />
          </>
        )}
        <input
          type="file"
          style={{ display: 'none' }}
          ref={upVideoRef}
          accept="video/*"
          onChange={(e) => {
            handlefileupload(e);
          }}
        />
      </div>
      {s3Link && <div style={{ display: "flex", justifyContent: 'center', alignItems: "center", gap: "16px" }}>
        <Button
          onClick={() => {
            upVideoRef.current.click();
          }}
          endIcon={<ReplayIcon />}
          style={{ textTransform: "none", color: 'black', borderRadius: 8, border: '1px solid black', minWidth: "150px", minHeight: '30px' }}
        >
          Replace Video
        </Button>

        <Button
          color="error"
          onClick={(e) => {
            e.stopPropagation();
            setUpFile(null);
            setUpFile64(null);
            setUpFileName('');
            setS3Link(null);
            // setImageUrl('');
          }}
          endIcon={<RiDeleteBinLine />}
          style={{
            textTransform: "none",
            backgroundColor: "#FFDEDE",
            color: "#E00000",
            borderRadius: 8,
            minWidth: "150px",
            minHeight: '30px'
          }}
        >
          Delete
        </Button>
      </div>}
      <div
        style={{
          background: '#FAFAFA',
          borderRadius: 10,
          padding: '12px',
          marginTop: '16px'
        }}
      >
        <div style={{ fontWeight: 600, marginBottom: '8px' }}>Save response as</div>
        <input
          className="form-control shadow-none"
          style={{
            width: '100%',
            padding: '8px',
            borderRadius: '4px',
            background: '#FAFAFA',
            marginBottom: '12px',
            border: '1px solid #EFEFEF'
          }}
          value={variable}
          onChange={(e) => {
            const val = e.target.value;
            eventsend("save-replies-to-variable_Instagram-action-node_clicked");
            if (val.startsWith('@')) {
              setVariable(val);
            } else {
              setVariable('@' + val);
            }
          }}
        />
        <div className="d-flex flex-row justify-content-between align-items-center">
          <Form.Check
            type="checkbox"
            className="btncustom"
            checked={skipReply}
            label="Skip user reply"
            onChange={() => {
              eventsend("skip-user-reply_Instagram-action-node_clicked");
              setSkipReply(!skipReply);
            }}
          />
          <Tooltip
            title={'Next node will trigger itself even if recipient does not reply'}
            placement="bottom-end"
          >
            <div>
              <AiOutlineInfoCircle size={20} style={{ marginInlineEnd: 16 }} />
            </div>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export const AudioUploader = ({ data, setData, setShowSpecificContent, setMessage, variable, setVariable, skipReply, setSkipReply }) => {
  const [upFile, setUpFile] = useState(null);
  const [upFile64, setUpFile64] = useState('');
  const [upFileName, setUpFileName] = useState('');
  const [dragActive, setDragActive] = useState(false);
  const [s3Link, setS3Link] = useState(null);
  const upAudioRef = useRef();
  const [audioUrl, setAudioUrl] = useState('');

  const authtoken = useSelector((state) => state.main.auth_token);
  const MAX_FILE_SIZE = 26214400;
  const ALLOWED_AUDIO_TYPES = ['audio/wav', 'audio/acc', 'audio/mp4', 'audio/m4a'];

  useEffect(()=>{
    eventsend("Audio_Reply-in-dm_instagram-action-node_selected");
  },[])

  useEffect(() => {
    if (data?.mediaUrl) {
      setS3Link(data?.mediaUrl);
    }
    if (data?.variable) {
      setVariable(data?.variable);
    }
    if (data?.skipReply) {
      setSkipReply(data?.skipReply);
    }
  }, []);


  useEffect(() => {
    setData({
      ...data,
      mediaUrl: s3Link,
      type: data?.type,
    });
  }, [upFile, upFile64, s3Link]);

  const handlefileupload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > MAX_FILE_SIZE) {
        toast.error('File size limit exceeded. Maximum size is 25MB', {
          position: 'top-center',
        });
        return;
      }
      if (!ALLOWED_AUDIO_TYPES.includes(file.type)) {
        toast.error('Only MP4, WAV, ACC and M4A formats are supported', {
          position: 'top-center',
        });
        return;
      }
      setUpFile(file);
      setUpFileName(file.name);
      const base64 = await convertToBase64(file);
      setUpFile64(base64);
    } else {
      toast.error('Unexpected error occurred, please try again later.', {
        position: 'top-center',
      });
    }
  };


  const handleDragFile = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDropFile = async (e, type) => {
    e.preventDefault();
    e.stopPropagation();
    const file = e.dataTransfer.files[0];
    if (file && type === 'Audio') {
      if (file.size > MAX_FILE_SIZE) {
        toast.error('File size limit exceeded. Maximum size is 25MB', {
          position: 'top-center',
        });
        return;
      }
      if (!ALLOWED_AUDIO_TYPES.includes(file.type)) {
        toast.error('Only MP4, WAV, ACC and M4A formats are supported', {
          position: 'top-center',
        });
        return;
      }
      setUpFile(file);
      setUpFileName(file.name);
      const base64 = await convertToBase64(file);
      setUpFile64(base64);
      setAudioUrl('');
    } else {
      toast.error('Unexpected error occurred, please try again later.', {
        position: 'top-center',
      });
    }
  };

  const handleAudioUrl = async (audioUrl) => {
    if (audioUrl === '') {
      return;
    }
    if (upFileName) {
      toast.error('Delete uploaded audio and try again', {
        position: 'top-center',
      });
      return;
    }

    setAudioUrl(audioUrl);
    setUpFile(null);
    setUpFile64('');

    try {
      const response = await fetch(audioUrl)
        .then((res) => {
          if (res.ok) {
            return res;
          } else {
            console.error('Error:', res.statusText);
          }
        })
        .catch((error) => {
          console.error('Error:', error);
        });
      const blob = await response.blob();
      const file = new File([blob], 'audio_from_url.mp3', { type: blob.type });
      const base64 = await convertToBase64(file);
      setUpFile(file);
      setUpFile64(base64);
      setUpFileName(file.name);
    } catch (error) {
      toast.error('Not able to fetch audio. Please check your URL.', {
        position: 'top-center',
      });
    }
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  useEffect(() => {
    if (upFile64) {
      getS3Link();
    }
  }, [upFile64]);

  const getS3Link = () => {
    let data = {
      image: upFile64,
      type: upFile?.type?.split('/')[0],
      format: upFile?.type?.split('/')[1],
    };
    let token = authtoken;
    toast.promise(
      V2WorkflowS3Link(data, token),
      {
        pending: 'Uploading file...',
        success: {
          render({ data }) {
            setS3Link(data?.data?.url);
            console.log('s3 link success response', data);
            return 'File uploaded successfully!';
          },
          icon: '🟢',
          autoClose: true,
          isLoading: false,
        },
        error: {
          render({ data }) {
            console.log('s3 link error response', data);
            return 'Error occurred while uploading file, please try again later.';
          },
          icon: '🔴',
          autoClose: true,
          isLoading: false,
        },
      },
      {
        position: 'top-center',
      }
    );
  };


  const handleBackButton = () => {
    setShowSpecificContent(false);
    setMessage('');
    setVariable('');
    setSkipReply(false);
    setData({
      ...data,
      type: '',
      message: '',
      mediaUrl: undefined,
      variable: '',
      skipReply: false,
    });
  };


  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      gap: '12px',
      padding: '20px 0px',
      background: '#FFFFFF',
      borderRadius: '12px',
      width: '100%'
    }}>
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 10,
        paddingBottom: 10,
        borderRadius: 10,
        alignItems: 'center',
        background: '#FAFAFA',
        cursor: 'pointer'
      }}
        onClick={handleBackButton}

      >
        <img
          src={images.BackArrowBlack}
          style={{ height: 18, width: 18, objectFit: 'contain', cursor: 'pointer', marginRight: 5 }}
        ></img>
        <span style={{ fontWeight: 700 }}>Change content block</span>
      </div>
      <div
        className="d-flex flex-column justify-content-center align-items-center w-100 my-2"
        style={s3Link ? containerStyle : { ...containerStyle, height: 260 }}
        onDragEnter={handleDragFile}
        onDragExit={handleDragFile}
        onDragOver={handleDragFile}
        onDrop={(e) => {
          handleDropFile(e, 'Audio');
        }}
        onClick={() => {
          upAudioRef.current.click();
        }}
      >
        {s3Link ? (
          <audio
            src={getImagePath(s3Link)}
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
              objectFit: 'contain',
            }}
            controls
          />
        ) : (
          <>
            <img
              src={images.AudioPreview}
              style={{ height: '100%', width: '100%', objectFit: 'cover' }}
            />
          </>
        )}
        <input
          type="file"
          style={{ display: 'none' }}
          ref={upAudioRef}
          accept="audio/*"
          onChange={(e) => {
            handlefileupload(e);
          }}
        />
      </div>
      {s3Link && <div style={{ display: "flex", justifyContent: 'center', alignItems: "center", gap: "16px" }}>
        <Button
          onClick={() => {
            upAudioRef.current.click();
          }}
          endIcon={<ReplayIcon />}
          style={{ textTransform: "none", color: 'black', borderRadius: 8, border: '1px solid black', minWidth: "150px", minHeight: '30px' }}
        >
          Replace Audio
        </Button>

        <Button
          color="error"
          onClick={(e) => {
            e.stopPropagation();
            setUpFile(null);
            setUpFile64(null);
            setUpFileName('');
            setS3Link(null);
            setAudioUrl('');
          }}
          endIcon={<RiDeleteBinLine />}
          style={{
            textTransform: "none",
            backgroundColor: "#FFDEDE",
            color: "#E00000",
            borderRadius: 8,
            minWidth: "150px",
            minHeight: '30px'
          }}
        >
          Delete
        </Button>
      </div>}
      {!s3Link && <TextField
        variant="outlined"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <FaLink />
              <span style={{ marginLeft: "8px", color: "#000000", fontSize: 14 }}>
                Audio URL
              </span>
              <div
                style={{
                  height: "20px",
                  width: "1px",
                  backgroundColor: "#808080",
                  marginLeft: "8px",
                }}
              ></div>
            </InputAdornment>
          ),
          style: {
            backgroundColor: "#FAFAFA",
            borderRadius: "8px",
            border: 'none'
          },
        }}
        placeholder="Paste Audio Url"
        value={audioUrl}
        onPaste={(event) => {
          const pastedURL = event.clipboardData.getData('text');
          handleAudioUrl(pastedURL);
        }}
        onChange={(e) => {
          setAudioUrl(e.target.value);
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleAudioUrl(audioUrl);
          }
          if (e.key === 'Backspace') {
            e.stopPropagation();
            setUpFile(null);
            setUpFile64(null);
            setUpFileName('');
            setS3Link(null);
            setAudioUrl(e.target.value);
          }
        }}
        fullWidth
      />}
      <div
        style={{
          background: '#FAFAFA',
          borderRadius: 10,
          padding: '12px',
          marginTop: '16px'
        }}
      >
        <div style={{ fontWeight: 600, marginBottom: '8px' }}>Save response as</div>
        <input
          className="form-control shadow-none"
          style={{
            width: '100%',
            padding: '8px',
            borderRadius: '4px',
            background: '#FAFAFA',
            marginBottom: '12px',
            border: '1px solid #EFEFEF'
          }}
          value={variable}
          onChange={(e) => {
            eventsend("save-replies-to-variable_Instagram-action-node_clicked");
            const val = e.target.value;
            if (val.startsWith('@')) {
              setVariable(val);
            } else {
              setVariable('@' + val);
            }
          }}
        />
        <div className="d-flex flex-row justify-content-between align-items-center">
          <Form.Check
            type="checkbox"
            className="btncustom"
            checked={skipReply}
            label="Skip user reply"
            onChange={() => {
              eventsend("skip-user-reply_Instagram-action-node_clicked");
              setSkipReply(!skipReply);
            }}
          />
          <Tooltip
            title={'Next node will trigger itself even if recipient does not reply'}
            placement="bottom-end"
          >
            <div>
              <AiOutlineInfoCircle size={20} style={{ marginInlineEnd: 16 }} />
            </div>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export const CardComponent = ({ data, setData, setShowSpecificContent, setMessage, variable, setVariable, skipReply, setSkipReply }) => {
  const [upFile, setUpFile] = useState(null);
  const [upFile64, setUpFile64] = useState('');
  const [upFileName, setUpFileName] = useState('');
  const [dragActive, setDragActive] = useState(false);
  const [s3Link, setS3Link] = useState(null);
  const upImageRef = useRef();
  const [imageUrl, setImageUrl] = useState('');
  const [title, setTitle] = useState(data?.title || '');
  const [showTitleEmojiPicker, setShowTitleEmojiPicker] = useState(false);
  const [description, setDescription] = useState(data?.description || '');
  const [showDescriptionEmojiPicker, setShowDescriptionEmojiPicker] = useState(false);
  const [showButtonOptions, setShowButtonOptions] = useState(false);
  const [buttons, setButtons] = useState(data?.buttons || []);
  const [addLinkBoxIndex, setAddLinkBoxIndex] = useState(-1);

  const toggleAddLinkBox = (index) => {
    setAddLinkBoxIndex(index === addLinkBoxIndex ? -1 : index);
  };

  useEffect(()=>{
    eventsend("Cards_Reply-in-dm_instagram-action-node_selected");
  },[])

  const copyLinkToClipboard = (link) => {
    navigator.clipboard.writeText(link);
    toast.success('Link copied to clipboard', {
      position: 'top-center'
    });
  };

  const handleRemoveLink = (index) => {
    const updatedButtons = buttons.map((button, i) => {
      if (i === index) {
        return {
          ...button,
          url: ''
        };
      }
      return button;
    });

    setButtons(updatedButtons);
    setData({
      ...data,
      buttons: updatedButtons
    });
    setAddLinkBoxIndex(-1);
  };

  const authtoken = useSelector((state) => state.main.auth_token);

  useEffect(() => {
    if (data?.mediaUrl) {
      setS3Link(data?.mediaUrl);
    }
    if (data?.variable) {
      setVariable(data?.variable);
    }
    if (data?.skipReply) {
      setSkipReply(data?.skipReply);
    }
  }, []);


  useEffect(() => {
    setData({
      ...data,
      mediaUrl: s3Link,
      type: data?.type,
    });
  }, [upFile, upFile64, s3Link]);

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handlefileupload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      if (UploadValidator(file, 'Image')) {
        setUpFile(file);
        setUpFileName(file.name);
        const base64 = await convertToBase64(file);
        setUpFile64(base64);
      }
    } else {
      toast.error('Unexpected error occurred, please try again later.', {
        position: 'top-center',
      });
    }
  };

  const handleDescriptionChange = (e) => {
    const newDescription = e.target.value;
    setDescription(newDescription);
    setData({
      ...data,
      description: newDescription
    });
  };


  const handleDragFile = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDropFile = async (e, type) => {
    e.preventDefault();
    e.stopPropagation();
    console.log('file ===> ', e.dataTransfer.files[0]);
    const file = e.dataTransfer.files[0];
    if (file && type) {
      if (UploadValidator(file, type)) {
        setUpFile(file);
        setUpFileName(file.name);
        const base64 = await convertToBase64(file);
        setUpFile64(base64);
        setImageUrl('');
      }
    } else {
      toast.error('Unexpected error occurred, please try again later.', {
        position: 'top-center',
      });
    }
  };

  const handleAddButton = (buttonType) => {
    if (buttons.length >= 3) {
      toast.error('Maximum 3 buttons allowed', {
        position: 'top-center',
      });
      return;
    }

    if (buttonType === 'quick_reply') {
      const newButton = {
        type: 'postback',
        title: '',  // Empty title initially
        payload: '' // Empty payload initially
      };

      const updatedButtons = [...buttons, newButton];
      setButtons(updatedButtons);
      setData({
        ...data,
        buttons: updatedButtons
      });
    } else if (buttonType === 'cta') {
      const newButton = {
        type: 'web_url',
        url: '',     // Empty URL initially
        title: ''   // Empty title initially
      };

      const updatedButtons = [...buttons, newButton];
      setButtons(updatedButtons);
      setData({
        ...data,
        buttons: updatedButtons
      });
    }
    setShowButtonOptions(false);
  };

  const handleButtonTitleChange = (index, newValue, field) => {
    const updatedButtons = buttons.map((button, i) => {
      if (i === index) {
        if (button.type === 'postback' && field === 'title') {
          return {
            ...button,
            title: newValue,
            payload: newValue
          };
        }
        return {
          ...button,
          [field]: newValue
        };
      }
      return button;
    });
    setButtons(updatedButtons);
    setData({
      ...data,
      buttons: updatedButtons
    });
  }

  const handleRemoveButton = (index) => {
    const updatedButtons = buttons.filter((_, i) => i !== index);
    setButtons(updatedButtons);
    setData({
      ...data,
      buttons: updatedButtons
    });
  };

  const ButtonOptions = () => (
    <div style={{
      position: 'absolute',
      top: '100%',
      left: 0,
      backgroundColor: 'white',
      borderRadius: '8px',
      boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
      zIndex: 10,
      width: '200px',
      padding: '8px'
    }}>
      <div
        onClick={() => handleAddButton('quick_reply')}
        style={{
          display: 'flex',
          alignItems: 'center',
          padding: '8px',
          cursor: 'pointer',
          borderRadius: '4px',
          ':hover': {
            backgroundColor: '#FAFAFA'
          }
        }}
      >
        <span style={{ marginRight: '8px' }}>↩</span>
        Quick reply
      </div>
      <div
        onClick={() => handleAddButton('cta')}
        style={{
          display: 'flex',
          alignItems: 'center',
          padding: '8px',
          cursor: 'pointer',
          borderRadius: '4px',
          ':hover': {
            backgroundColor: '#FAFAFA'
          }
        }}
      >
        <span style={{ marginRight: '8px' }}><GoLinkExternal /></span>
        Link button
      </div>
    </div>
  );

  // const handleImageUrl = async (image) => {
  //   const url = image;
  //   if (url === '') {
  //     return;
  //   }
  //   if (upFileName) {
  //     toast.error('Delete uploaded image and try again', {
  //       position: 'top-center',
  //     });
  //     return;
  //   }
  //   // const regex = /^(https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&//=]*\.(?:png|jpg|jpeg|gif|bmp|svg)))$/i;
  //   // ;
  //   // console.log(url);
  //   // if (!regex.test(url)) {
  //   //   toast.error('Invalid image URL. Please check the URL and try again.', {
  //   //     position: 'top-center',
  //   //
  //   //   });
  //   //   return;
  //   // }
  //   setImageUrl(url);
  //   setUpFile(null);
  //   setUpFile64('');

  //   if (url) {
  //     try {
  //       const response = await fetch(url)
  //         .then((res) => {
  //           if (res.ok) {
  //             return res;
  //           } else {
  //             console.error('Error:', res.statusText);
  //           }
  //         })
  //         .catch((error) => {
  //           console.error('Error:', error);
  //         });
  //       const blob = await response.blob();
  //       const file = new File([blob], 'image_from_url.jpg', { type: blob.type });
  //       const base64 = await convertToBase64(file);
  //       setUpFile(file);
  //       setUpFile64(base64);
  //       setUpFileName(file.name);
  //     } catch (error) {
  //       toast.error('Not able to fetch image.Please check your url.', {
  //         position: 'top-center',
  //       });
  //     }
  //   }
  // };

  // const convertToBase64 = (file) => {
  //   return new Promise((resolve, reject) => {
  //     const fileReader = new FileReader();
  //     fileReader.readAsDataURL(file);
  //     fileReader.onload = () => {
  //       resolve(fileReader.result);
  //     };
  //     fileReader.onerror = (error) => {
  //       reject(error);
  //     };
  //   });
  // };

  useEffect(() => {
    if (upFile64) {
      getS3Link();
    }
  }, [upFile64]);

  const getS3Link = () => {
    let data = {
      image: upFile64,
      type: upFile?.type?.split('/')[0],
      format: upFile?.type?.split('/')[1],
    };
    let token = authtoken;
    toast.promise(
      V2WorkflowS3Link(data, token),
      {
        pending: 'Uploading file...',
        success: {
          render({ data }) {
            setS3Link(data?.data?.url);
            console.log('s3 link success response', data);
            return 'File uploaded successfully!';
          },
          icon: '🟢',
          autoClose: true,
          isLoading: false,
        },
        error: {
          render({ data }) {
            console.log('s3 link error response', data);
            return 'Error occurred while uploading file, please try again later.';
          },
          icon: '🔴',
          autoClose: true,
          isLoading: false,
        },
      },
      {
        position: 'top-center',
      }
    );
  };

  const handleTitleChange = (e) => {
    const newTitle = e.target.value;
    setTitle(newTitle);
    setData({
      ...data,
      title: newTitle
    });
  };

  const handleTitleEmojiClick = (emoji) => {
    setTitle((prev) => prev + emoji.emoji); // Append selected emoji
  }

  const handleDescriptionEmojiClick = (emoji) => {
    setDescription((prev) => prev + emoji.emoji); // Append selected emoji
  }


  const handleBackButton = () => {
    setShowSpecificContent(false);
    setTitle('');
    setVariable('');
    setSkipReply(false);
    setData({
      ...data,
      type: '',
      message: '',
      mediaUrl: undefined,
      variable: '',
      skipReply: false
    });
  };


  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      gap: '12px',
      padding: '2px 0px',
      background: '#FFFFFF',
      borderRadius: '12px',
      width: '100%'
    }}>
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 10,
        paddingBottom: 10,
        borderRadius: 10,
        alignItems: 'center',
        background: '#FAFAFA',
        cursor: 'pointer',
      }}
        onClick={handleBackButton}

      >
        <img
          src={images.BackArrowBlack}
          style={{ height: 18, width: 18, objectFit: 'contain', cursor: 'pointer', marginRight: 5 }}
        ></img>
        <span style={{ fontWeight: 700 }}>Change content block</span>
      </div>
      <div style={{ position: 'relative' }}>
        <textarea
          placeholder="Description"
          value={description}
          onChange={handleDescriptionChange}
          style={{
            width: '100%',
            padding: '12px',
            borderRadius: '8px',
            // border: '1px solid #E0E0E0',
            backgroundColor: '#FAFAFA',
            minHeight: '100px',
            resize: 'none',
            outline: 'none',
            '&:focus': {
              border: '1px solid #8E53F7'
            }
          }}
        />
        <button
          onClick={() => {
            if (showTitleEmojiPicker) {
              setShowTitleEmojiPicker(false);
            }
            setShowDescriptionEmojiPicker((prev) => !prev)
          }}
          style={{
            position: "absolute",
            right: '6px',
            top: '70%',
            border: "none",
            backgroundColor: "transparent",
            cursor: "pointer",
            fontSize: "20px",
          }}
        >
          😊
        </button>
        {showDescriptionEmojiPicker && (
          <div style={{
            position: "fixed",
            top: "30px",
            left: "700px",
            zIndex: 1000,
            backgroundColor: 'white',
            borderRadius: '8px',
            boxShadow: '0 4px 6px rgba(0,0,0,0.1)'
          }}>
            <div style={{
              display: 'flex',
              justifyContent: 'flex-end',
              padding: '8px',
              borderBottom: '1px solid #e0e0e0'
            }}>
              <button
                onClick={() => setShowDescriptionEmojiPicker(false)}
                style={{
                  background: 'none',
                  border: 'none',
                  cursor: 'pointer',
                  padding: '4px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <RiCloseLine size={20} />
              </button>
            </div>
            <EmojiPicker onEmojiClick={handleDescriptionEmojiClick} />
          </div>
        )}
      </div>
      {buttons.map((button, index) => (
        <div key={index} style={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: '#FAFAFA',
          borderRadius: '8px',
          marginBottom: '8px',
          position: 'relative',
          width: '100%',
          minWidth: 0,
        }}>
          <span style={{
            fontSize: 12,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            minWidth: '80px',
            padding: '0 4px'
          }}>
            {button.type === 'postback' ? (
              <div style={{
                display: 'flex',
                alignItems: 'center',
                gap: '4px'
              }}>
                <IoReturnUpBackSharp size={17} />
                <span>Quick Reply</span>
              </div>
            ) : (
              <div style={{
                display: 'flex',
                alignItems: 'center',
                gap: '4px'
              }}>
                <GoLinkExternal size={17} />
                <span>Link</span>
              </div>
            )}
          </span>
          <div style={{
            height: "20px",
            width: "1px",
            backgroundColor: "#808080",
            marginLeft: "4px",
          }}></div>

          <input
            type="text"
            value={button.title}
            onChange={(e) => handleButtonTitleChange(index, e.target.value, 'title')}
            placeholder="Add button text"
            style={{
              flex: 1,
              padding: '4px',
              borderRadius: '4px',
              border: 'none',
              backgroundColor: '#FFFFFF',
              outline: 'none',
              background: '#FAFAFA',
              minWidth: 0,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          />

          {button.type === 'web_url' && (
            <div style={{ display: 'flex', alignItems: 'center', }}>
              <button
                onClick={() => toggleAddLinkBox(index)}
                style={{
                  padding: '8px',
                  backgroundColor: 'transparent',
                  border: 'none',
                  cursor: 'pointer',
                  color: button.url ? 'blue' : 'inherit'
                }}
              >
                <FaLink size={16} />
              </button>
              {addLinkBoxIndex === index && (
                <div style={{
                  position: 'absolute', top: '50px', left: '0', width: '250px', zIndex: 1, backgroundColor: '#FAFAFA',
                  borderRadius: '8px',
                  padding: '12px',
                  boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
                }}>
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginBottom: '8px',
                  }}>
                    <span>Add a link</span>
                    <div style={{ display: 'flex', gap: '8px' }}>
                      <button
                        onClick={() => copyLinkToClipboard(button.url)}
                        style={{
                          padding: '4px',
                          backgroundColor: 'transparent',
                          border: 'none',
                          cursor: 'pointer',
                        }}
                      >
                        <IoMdCopy size={14} />
                      </button>
                      <button
                        onClick={() => handleRemoveLink(index)}
                        style={{
                          padding: '4px',
                          backgroundColor: 'transparent',
                          border: 'none',
                          cursor: 'pointer',
                        }}
                      >
                        <RiDeleteBinLine size={14} />
                      </button>
                    </div>
                  </div>
                  <input
                    type="text"
                    value={button.url}
                    onChange={(e) => handleButtonTitleChange(index, e.target.value, 'url')}
                    placeholder="Paste link URL"
                    style={{
                      width: '100%',
                      padding: '8px',
                      borderRadius: '4px',
                      border: 'none',
                      backgroundColor: '#FFFFFF',
                      outline: 'none',
                      color: '#3366CC'
                    }}
                  />
                </div>
              )}
            </div>
          )}

          <button
            onClick={() => handleRemoveButton(index)}
            style={{
              padding: '8px',
              backgroundColor: 'transparent',
              border: 'none',
              cursor: 'pointer'
            }}
          >
            <RiDeleteBinLine />
          </button>
        </div>
      ))}

      {buttons.length < 3 && <div style={{ position: 'relative' }}>
        <button
          onClick={() => setShowButtonOptions(!showButtonOptions)}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '8px',
            padding: '12px',
            width: '100%',
            backgroundColor: '#FAFAFA',
            border: '1px dashed #E0E0E0',
            borderRadius: '8px',
            cursor: 'pointer',
            opacity: buttons.length >= 3 ? 0.5 : 1
          }}
          disabled={buttons.length >= 3}
        >
          <span><IoMdAdd size={17} style={{ marginBottom: 2 }} /> Add Button</span>
        </button>
        {showButtonOptions && <ButtonOptions />}
      </div>}
      <div
        style={{
          background: '#FAFAFA',
          borderRadius: 10,
          padding: '12px',
          marginTop: '16px'
        }}
      >
        <div style={{ fontWeight: 600, marginBottom: '8px' }}>Save response as</div>
        <input
          className="form-control shadow-none"
          style={{
            width: '100%',
            padding: '8px',
            borderRadius: '4px',
            background: '#FAFAFA',
            marginBottom: '12px',
            border: '1px solid #EFEFEF'
          }}
          value={variable}
          onChange={(e) => {
            eventsend("save-replies-to-variable_Instagram-action-node_clicked");
            const val = e.target.value;
            if (val.startsWith('@')) {
              setVariable(val);
            } else {
              setVariable('@' + val);
            }
          }}
        />
        <div className="d-flex flex-row justify-content-between align-items-center">
          <Form.Check
            type="checkbox"
            className="btncustom"
            checked={skipReply}
            label="Skip user reply"
            onChange={() => {
              eventsend("skip-user-reply_Instagram-action-node_clicked");
              setSkipReply(!skipReply);
            }}
          />
          <Tooltip
            title={'Next node will trigger itself even if recipient does not reply'}
            placement="bottom-end"
          >
            <div>
              <AiOutlineInfoCircle size={20} style={{ marginInlineEnd: 16 }} />
            </div>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export const ProductComponent = ({ data, setData, setShowSpecificContent, setMessage }) => {
  const [showProductDropdown, setShowProductDropdown] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const localdata = useSelector((state) => state.main.localdata);
  const authtoken = useSelector((state) => state.main.auth_token);
  const [isLoading, setIsLoading] = useState(true);
  const [productCatalogs, setProductCatalogs] = useState([]);
  const [products, setProducts] = useState([]);
  // const [selectedCatalogId, setSelectedCatalogId] = useState('');

  useEffect(()=>{
    eventsend("Products_Reply-in-dm_instagram-action-node_selected")
  },[])

  const getInstagramCatalogProducts = () => {
    setIsLoading(true);
    if (localdata?.whatsapp_credentials?.activeCatalogId && localdata?.aiSensyDetails?.catalogConnected) {
      let token = authtoken;
      getInstagramCatalogProductsAPI(token, localdata?.whatsapp_credentials?.activeCatalogId).then((res) => {
        try {
          if (res?.flag) {
            setProducts(res?.data);
          } else {
            toast.error(res?.message);
          }
        } catch (err) {
          toast.error(err?.message);
        } finally {
          setIsLoading(false);
        }
      });
    } else {
      toast.error('Please connect your catalog first');
      setIsLoading(false);
    }
  };


  const handleBackButton = () => {
    setShowSpecificContent(false);
    setData({
      ...data,
      type: '',
      products: [],
      productDetails: [],
      totalProductsSelected: 0
    });
  };

  useEffect(() => {
    getInstagramCatalogProducts();
  }, [localdata?.whatsapp_credentials?.activeCatalogId]);



  const handleProductSelect = (product) => {
    const isSelected = data?.products?.some(p => p.id === product.id);

    if (isSelected) {
      setData({
        ...data,
        products: data?.products?.filter(p => p.id !== product.id),
        productDetails: data?.productDetails?.filter(p => p.id !== product.id),
      });
    } else if (data?.products?.length >= 10) {
      toast.error('Maximum 10 products can be selected');
    } else {
      setData({
        ...data,
        products: [...(data?.products || []), { id: product.id }],
        productDetails: [...(data?.productDetails || []), product],
      });
    }
    if (data?.products.length === 1) {
      eventsend("Instagram_action_Product_message_selected");
    } else if (data?.products.length > 1) {
      eventsend("Instagram_action_carousel_message_selected");
    }
  };

  const handleRemoveProduct = (productId) => {
    setData({
      ...data,
      products: data?.products?.filter(p => p.id !== productId),
      productDetails: data?.productDetails?.filter(p => p.id !== productId),
    });
  };

  const filteredProducts = products.filter(product =>
    product.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    product.id.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      gap: '12px',
      padding: '2px 0px',
      background: '#FFFFFF',
      borderRadius: '12px',
      width: '100%'
    }}>
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 10,
        paddingBottom: 10,
        borderRadius: 10,
        alignItems: 'center',
        background: '#FAFAFA',
        cursor: 'pointer',
      }}
        onClick={handleBackButton}
      >
        <img
          src={images.BackArrowBlack}
          style={{ height: 18, width: 18, objectFit: 'contain', cursor: 'pointer', marginRight: 5 }}
        ></img>
        <span style={{ fontWeight: 700 }}>Change content block</span>
      </div>

      {isLoading ? (
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '20px'
        }}>
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '200px'
          }}>
            <div style={{
              width: '40px',
              height: '40px',
              border: '3px solid #f3f3f3',
              borderTop: '3px solid #8E53F7',
              borderRadius: '50%',
              animation: 'spin 1s linear infinite',
            }}>
              <style>
                {`
            @keyframes spin {
              0% { transform: rotate(0deg); }
              100% { transform: rotate(360deg); }
            }
          `}
              </style>
            </div>
          </div>
        </div>
      ) : (
        <>
          {data?.products.map((productId) => {
            const productDetails = products.find(p => p.id === productId.id);

            return productDetails ? (
              <div key={productId.id} style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '12px',
                backgroundColor: '#FAFAFA',
                borderRadius: '8px',
                marginBottom: '8px'
              }}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                  <img src={productDetails.image_url} alt="" style={{ width: 24, height: 24 }} />
                  <div>
                    <div>{productDetails.name}</div>
                    <div style={{ color: '#666' }}>{productDetails.id}</div>
                  </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                  <span>{productDetails.price}</span>
                  <button
                    onClick={() => handleRemoveProduct(productId.id)}
                    style={{
                      border: 'none',
                      background: 'none',
                      cursor: 'pointer'
                    }}
                  >
                    <RiDeleteBinLine />
                  </button>
                </div>
              </div>
            ) : null;
          })}

          {data?.products?.length < 10 && <button
            onClick={() => setShowProductDropdown(!showProductDropdown)}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '8px',
              padding: '12px',
              backgroundColor: '#FAFAFA',
              border: '1px dashed #E0E0E0',
              borderRadius: '8px',
              opacity: data?.products?.length >= 10 ? 0.5 : 1,
            }}
            disabled={data?.products?.length >= 10}
          >
            <span><IoMdAdd size={17} style={{ marginBottom: 2 }} /> Choose the products to add</span>
          </button>}

          {showProductDropdown && data.products.length < 10 && (
            <div style={{
              position: 'absolute',
              top: '30%',
              left: 350,
              right: 30,
              backgroundColor: 'white',
              borderRadius: '8px',
              boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
              zIndex: 1000,
              maxHeight: '400px',
              display: 'flex',
              flexDirection: 'column'
            }}>
              <div style={{
                position: 'sticky',
                top: 0,
                backgroundColor: 'white',
                padding: '12px',
                borderBottom: '1px solid #E0E0E0',
                zIndex: 1
              }}>
                <input
                  type="text"
                  placeholder="Search product"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  style={{
                    width: '100%',
                    padding: '8px',
                    borderRadius: '4px',
                    border: '1px solid #E0E0E0'
                  }}
                />
              </div>

              <div
                style={{ overflow: 'auto', maxHeight: '350px' }}
              >
                {filteredProducts.map((product) => (
                  <div
                    key={product.id}
                    onClick={() => handleProductSelect(product)}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      padding: '12px',
                      cursor: 'pointer',
                      borderBottom: '1px solid #E0E0E0',
                      backgroundColor: 'white',
                      ':hover': {
                        backgroundColor: '#FAFAFA'
                      }
                    }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                      <input
                        type="checkbox"
                        checked={data?.products.some(p => p.id === product.id)}
                        onChange={() => handleProductSelect(product)}
                        onClick={(e) => e.stopPropagation()}
                      />
                      <img src={product?.image_url} alt="" style={{ width: 24, height: 24 }} />
                      <div>
                        <div>{product.name}</div>
                        <div style={{ color: '#666' }}>{product.id}</div>
                      </div>
                    </div>
                    <span>{product.price}</span>
                  </div>
                ))}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

const InstagramActionsModal = ({ data, setData, setBtndisabled }) => {
  const [selectedPostType, setSelectedPostType] = useState(data?.option || '');
  const [showContentCreator, setShowContentCreator] = useState(false);
  const [message, setMessage] = useState(data?.message || '');
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [showSpecificContent, setShowSpecificContent] = useState(false);
  const [showCommentsModal, setShowCommentsModal] = useState(false);
  const [comments, setComments] = useState(data?.comments || []);
  const localdata = useSelector((state) => state.main.localdata);
  const [skipReply, setSkipReply] = useState(data?.skipReply || false);
  const [variable, setVariable] = useState(data?.variable || '');


  const contentOptions = localdata?.metaConfig?.newIGToken ? [
    { id: 'text', label: 'Text', description: 'Add simple text and buttons' },
    { id: 'image', label: 'Image', description: 'Add an image' },
    { id: 'video', label: 'Video', description: 'Add a Video' },
    { id: 'card', label: 'Card', description: 'Add text and buttons' },
    { id: 'products', label: 'Products', description: 'Add up to 10 products in a carousel' },
    { id: 'audio', label: 'Audio', description: 'Send voice snippets in chat' }
  ] : [
    { id: 'text', label: 'Text', description: 'Add simple text and buttons' }
  ];

  const [currentProductIndex, setCurrentProductIndex] = useState(0);

  const handlePrevProduct = (e) => {
    e.stopPropagation();
    setCurrentProductIndex(prev => prev > 0 ? prev - 1 : data.products.length - 1);
  };

  const handleNextProduct = (e) => {
    e.stopPropagation();
    setCurrentProductIndex(prev => prev < data.products.length - 1 ? prev + 1 : 0);
  };


  const handleReplyInComments = () => {
    setSelectedPostType('replyinComments');
    eventsend("Reply-in-comments_instagram-action-node_selected");
    setShowCommentsModal(true);
  };

  const getIcon = (optionId) => {
    switch (optionId) {
      case 'text':
        return <BsTextLeft size={24} />;
      case 'image':
        return <BsImage size={24} />;
      case 'video':
        return <BsCameraVideo size={24} />;
      case 'audio':
        return <BsMic size={24} />;
      case 'card':
        return <IoCardOutline size={24} />;
      case 'products':
        return <BiCube size={24} />;
      default:
        return null;
    }
  };

  const handleReplyInDM = () => {
    setSelectedPostType('replyinDM');
    eventsend("Reply-in-dm_instagram-action-node_selected");
    setShowContentCreator(true);
  };

  const handleContentTypeSelect = (contentType) => {
    if (contentType === 'text') {
      setShowSpecificContent(true);
      eventsend("Instgram_action_Text_reply_selected");
      setData({
        ...data,
        mediaUrl: undefined,
        type: 'text',
        message: ''
      });
      setBtndisabled(true);
    } else if (contentType === 'image') {
      setShowSpecificContent(true);
      eventsend("Instgram_action_Image_reply_selected")
      setData({
        ...data,
        type: 'image',
        mediaUrl: undefined
      });
      setBtndisabled(true);
    } else if (contentType === 'video') {
      setShowSpecificContent(true);
      eventsend("Instagram_action_video_reply_selected")
      setData({
        ...data,
        type: 'video',
        mediaUrl: undefined
      });
      setBtndisabled(true);
    } else if (contentType === 'audio') {
      setShowSpecificContent(true);
      eventsend("Instagram_action_audio_message-selected")
      setData({
        ...data,
        type: 'audio',
        mediaUrl: undefined
      });
      setBtndisabled(true);
    } else if (contentType === 'card') {
      setShowSpecificContent(true);
      setData({
        ...data,
        type: 'card',
        mediaUrl: undefined,
        title: '',
        description: '',
        buttons: [],
      });
      eventsend("Instagram_action_card_reply_selected")
      setBtndisabled(true);
    } else if (contentType === 'products') {
      setShowSpecificContent(true);
      setData({
        ...data,
        type: 'products',
        products: [],
      });
      setBtndisabled(true);
    }
  };

  const handleBackButton = () => {
    setShowSpecificContent(false);
    setMessage('');
    setVariable('');
    setSkipReply(false);
    setData({
      ...data,
      type: '',
      message: '',
      mediaUrl: undefined,
      variable: '',
      skipReply: false,
    });
  };

  const handleMessageChange = (e) => {
    const newMessage = e.target.value;
    setMessage(newMessage);
    setData({
      ...data,
      message: newMessage
    });
    if (data?.type === 'text') {
      setBtndisabled(!newMessage.trim());
      eventsend("Text_Reply-in-dm_instagram-action-node_selected");
    }
  };


  useEffect(() => {
    setData({
      ...data,
      variable: variable,
      skipReply: skipReply,
    });
  }, [skipReply, variable]);

  useEffect(() => {
    if (data?.skipReply !== undefined) {
      setSkipReply(data.skipReply);
    }
    if (data?.variable) {
      setVariable(data.variable);
    }
  }, [data?.skipReply, data?.variable]);

  useEffect(() => {
    if (!data?.type && data?.option !== 'replyinComments') return;


    switch (data?.type) {
      case 'text':
        setBtndisabled(!message.trim());
        break;

      case 'image':
        setBtndisabled(!data.mediaUrl);
        break;

      case 'video':
        setBtndisabled(!data.mediaUrl);
        break;

      case 'audio':
        setBtndisabled(!data.mediaUrl);
        break;

      case 'card':
        const hasValidButtons = data.buttons?.every((button) => {
          if (button.type === 'postback') {
            return button.title && button.title.trim() !== '';
          } else if (button.type === 'web_url') {
            return button.title && button.title.trim() !== '' &&
              button.url && button.url.trim() !== '';
          }
          return false;
        });

        const hasCardContent = data.description?.trim() &&
          data.buttons?.length > 0 &&
          hasValidButtons;

        setBtndisabled(!hasCardContent);
        break;

      case 'products':
        // Check if at least one product is selected
        setBtndisabled(!data.products?.length || data.products.length === 0);
        break;

      default:
        setBtndisabled(true);
    }
    if (data?.option === 'replyinComments') {
      // Disable button if comments array is empty or all comments are empty
      const hasValidComments = data.comments &&
        data.comments.length > 0

      setBtndisabled(!hasValidComments);
    }
  }, [data?.type, message, data?.mediaUrl, data?.title, data?.description, data?.buttons, data?.products, data?.comments, data?.option]);
  const handleEmojiClick = (emoji) => {
    const newMessage = message + emoji.emoji;
    setMessage(newMessage);
    setData({
      ...data,
      message: newMessage
    });
  }


  useEffect(() => {
    setData({
      ...data,
      option: selectedPostType,
    });
  }, [selectedPostType])

  useEffect(() => {
    if (data?.type) {
      setShowContentCreator(true);
    }
    else if (data?.option === 'replyinComments') {
      setShowCommentsModal(true);
    }
  }, []);


  if (showContentCreator) {
    return (
      <div style={{
        display: 'flex',
        gap: '12px',
        padding: '20px 0px',
        background: '#FFFFFF',
        borderRadius: '12px',
        width: '100%'
      }}>
        <div style={{
          flex: '1',
          display: 'flex',
          flexDirection: 'column',
          gap: '16px'
        }}>
          {data?.type === 'text' ? (
            <div style={{
              paddingLeft: '5px',
              paddingTop: '5px',
              display: 'flex',
              flexDirection: 'column',
              gap: '16px'
            }}>
              <div style={{
                display: 'flex',
                justifyContent: 'center',
                paddingTop: 10,
                paddingBottom: 10,
                borderRadius: 10,
                alignItems: 'center',
                background: '#FAFAFA',
                cursor: 'pointer',
              }}
                onClick={handleBackButton}
              >
                <img
                  src={images.BackArrowBlack}
                  style={{ height: 18, width: 18, objectFit: 'contain', cursor: 'pointer', marginRight: 5 }}
                ></img>
                <span style={{ fontWeight: 700 }}>Change content block</span>
              </div>
              <div style={{ position: "relative", width: "100%" }}>
                <textarea
                  placeholder="Type message here"
                  value={message}
                  onChange={handleMessageChange}
                  style={{
                    padding: "12px",
                    borderRadius: "8px",
                    border: "1px solid #EFEFEF",
                    backgroundColor: "#FAFAFA",
                    width: "100%",
                    height: "120px",
                    fontSize: "16px",
                    boxSizing: "border-box",
                    resize: "none",
                    overflow: "hidden",
                  }}
                />
                <button
                  onClick={() => setShowEmojiPicker((prev) => !prev)}
                  style={{
                    position: "absolute",
                    right: "4px",
                    bottom: "14px",
                    border: "none",
                    backgroundColor: "transparent",
                    cursor: "pointer",
                    fontSize: "20px",
                  }}
                >
                  😊
                </button>
                {showEmojiPicker && (
                  <div style={{
                    position: "fixed",
                    top: "30px",
                    left: "700px",
                    zIndex: 1000,
                    backgroundColor: 'white',
                    borderRadius: '8px',
                    boxShadow: '0 4px 6px rgba(0,0,0,0.1)'
                  }}>
                    <div style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      padding: '8px',
                      borderBottom: '1px solid #e0e0e0'
                    }}>
                      <button
                        onClick={() => setShowEmojiPicker(false)}
                        style={{
                          background: 'none',
                          border: 'none',
                          cursor: 'pointer',
                          padding: '4px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <RiCloseLine size={20} />
                      </button>
                    </div>
                    <EmojiPicker onEmojiClick={handleEmojiClick} />
                  </div>
                )}
              </div>
              <div
                style={{
                  background: '#FAFAFA',
                  borderRadius: 10,
                  padding: '12px',
                  marginTop: '16px'
                }}
              >
                <div style={{ fontWeight: 600, marginBottom: '8px' }}>Save response as</div>
                <input
                  className="form-control shadow-none"
                  style={{
                    width: '100%',
                    padding: '8px',
                    borderRadius: '4px',
                    background: '#FAFAFA',
                    marginBottom: '12px',
                    border: '1px solid #EFEFEF'
                  }}
                  value={variable}
                  onChange={(e) => {
                    const val = e.target.value;
                    eventsend("save-replies-to-variable_Instagram-action-node_clicked");
                    if (val.startsWith('@')) {
                      setVariable(val);
                    } else {
                      setVariable('@' + val);
                    }
                  }}
                />
                <div className="d-flex flex-row justify-content-between align-items-center">
                  <Form.Check
                    type="checkbox"
                    className="btncustom"
                    checked={skipReply}
                    label="Skip user reply"
                    onChange={() => {
                      eventsend("skip-user-reply_Instagram-action-node_clicked");
                      setSkipReply(!skipReply);
                    }}
                  />
                  <Tooltip
                    title={'Next node will trigger itself even if recipient does not reply'}
                    placement="bottom-end"
                  >
                    <div>
                      <AiOutlineInfoCircle size={20} style={{ marginInlineEnd: 16 }} />
                    </div>
                  </Tooltip>
                </div>
              </div>
            </div>
          ) : data?.type === 'image' ? (
            <ImageUploader data={data} setData={setData} setShowSpecificContent={setShowSpecificContent} setMessage={setMessage} variable={variable} setVariable={setVariable} skipReply={skipReply} setSkipReply={setSkipReply} />
          ) : data?.type === 'video' ? (
            <VideoUploader data={data} setData={setData} setShowSpecificContent={setShowSpecificContent} setMessage={setMessage} variable={variable} setVariable={setVariable} skipReply={skipReply} setSkipReply={setSkipReply} />
          ) : data?.type === 'audio' ? (
            <AudioUploader data={data} setData={setData} setShowSpecificContent={setShowSpecificContent} setMessage={setMessage} variable={variable} setVariable={setVariable} skipReply={skipReply} setSkipReply={setSkipReply} />
          ) : data?.type === 'card' ? (
            <CardComponent data={data} setData={setData} setShowSpecificContent={setShowSpecificContent} setMessage={setMessage} variable={variable} setVariable={setVariable} skipReply={skipReply} setSkipReply={setSkipReply} />
          ) :
            data?.type === 'products' ? (
              <ProductComponent data={data} setData={setData} setShowSpecificContent={setShowSpecificContent} setMessage={setMessage} />
            )

              : (
                contentOptions.map((option) => (
                  <div
                    key={option.id}
                    onClick={() => handleContentTypeSelect(option.id)}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      padding: '12px',
                      cursor: 'pointer',
                      background: '#FAFAFA',
                      borderRadius: 8,
                      // borderBottom: '1px solid #EFEFEF'
                    }}
                  >
                    <div style={{ marginRight: '12px' }}>
                      {getIcon(option.id)}
                    </div>
                    <div>
                      <div style={{ fontWeight: '500' }}>{option.label}</div>
                      <div style={{ fontSize: '14px', color: '#666' }}>
                        {option.description}
                      </div>
                    </div>
                  </div>
                ))
              )}
        </div>
        <div style={{
          flex: '1',
          maxWidth: '375px'
        }}>
          <div style={{
            background: '#1A1A1A',
            borderRadius: '16px',
            overflow: 'hidden',
            aspectRatio: '9/16',
            display: 'flex',
            flexDirection: 'column'
          }}>
            <div style={{
              padding: '12px 16px',
              display: 'flex',
              alignItems: 'center',
              gap: '8px'
            }}>
              <div style={{
                width: '36px',
                height: '36px',
                background: '#D9D9D9',
                borderRadius: '50%'
              }}></div>
              <div style={{
                fontSize: '14px',
                color: 'white'
              }}>
                <div>Instagram Handle</div>
                <div style={{ fontSize: '12px', color: '#F0F0F0' }}>
                  Business Chat
                </div>
              </div>
            </div>
            <div style={{
              padding: '0 16px',
              marginTop: '8px',
              overflowY: 'auto',
              maxHeight: 'calc(100% - 60px)',
              scrollbarWidth: 'none',
              msOverflowStyle: 'none'
            }}>
              <div style={{
                width: '17px',
                height: '17px',
                background: '#D9D9D9',
                borderRadius: '50%',
                marginRight: 4,
                marginTop: 10,
              }}></div>
              {data?.type === 'text' ? (
                <div style={{
                  background: '#3A3A3A',
                  color: '#F0F0F0',
                  padding: '8px 12px',
                  borderRadius: '18px',
                  fontSize: '14px',
                  display: 'inline-block',
                  marginTop: '3px'
                }}>
                  {message ? (
                    message.split("\n").map((line, index) => (
                      <p key={index} style={{ margin: "4px 0" }}>
                        {line}
                      </p>
                    ))
                  ) : (
                    <p style={{ color: "#A9A9A9", margin: "4px 0" }}>
                      Type message here
                    </p>
                  )}
                </div>
              ) : data?.type === 'image' ? (
                <img
                  src={data.mediaUrl || images.InstagramImagePreview}
                  style={{
                    maxWidth: '100%',
                    maxHeight: '100%',
                    objectFit: 'cover',
                    borderRadius: 10,
                    marginTop: 3
                  }}
                />
              ) : data?.type === 'video' ? (
                <video
                  src={data.mediaUrl || images.InstagramImagePreview}
                  style={{
                    maxWidth: '100%',
                    maxHeight: '100%',
                    objectFit: 'contain',
                  }}
                  controls
                />
              ) :
                data?.type === 'audio' ? (
                  <audio
                    src={data.mediaUrl || images.InstagramImagePreview}
                    style={{
                      maxWidth: '100%',
                      maxHeight: '100%',
                      objectFit: 'contain',
                    }}
                    controls
                  />
                ) : data?.type === 'card' ? (
                  <div style={{
                    background: '#3A3A3A',
                    borderRadius: '10px',
                    overflow: 'hidden',
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: '3px'
                  }}>
                    <div style={{
                      flex: '1',
                      padding: '12px',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center'
                    }}>
                      <div style={{
                        fontSize: '14px',
                        color: '#F0F0F0',
                        marginBottom: '8px'
                      }}>
                        {data?.description ? data.description : 'Description'}
                      </div>
                      {data.buttons.map((button, index) => (
                        <div key={index} style={{
                          background: '#6C6C6C',
                          color: '#FFFFFF',
                          padding: '4px 8px',
                          borderRadius: '4px',
                          fontSize: '14px',
                          display: 'inline-block',
                          marginRight: '8px',
                          marginTop: '4px',
                          cursor: 'pointer',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}>
                          {button?.title ? button.title : 'Button'}
                        </div>
                      ))}
                    </div>
                  </div>
                ) :
                  data?.type === 'products' ? (
                    <div style={{
                      width: '100%',
                      marginTop: '3px'
                    }}>
                      <div style={{
                        background: '#3A3A3A',
                        borderRadius: '10px',
                        overflow: 'hidden',
                        width: '100%',
                        marginBottom: '12px'
                      }}>
                        {(data?.productDetails && data?.productDetails.length > 0 && data?.products) ? (
                          <div style={{
                            padding: '12px',
                          }}>
                            <div style={{
                              width: '100%',
                              aspectRatio: '1/1',
                              background: '#D9D9D9',
                              borderRadius: '8px',
                              marginBottom: '8px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }}>
                              <img
                                src={data?.productDetails[currentProductIndex]?.image_url}
                                alt=""
                                style={{
                                  width: '100%',
                                  height: '100%',
                                  objectFit: 'cover'
                                }}
                              />
                            </div>

                            <div style={{
                              color: '#F0F0F0',
                              fontSize: '14px'
                            }}>
                              <div style={{ fontWeight: 'bold', marginBottom: '4px' }}>
                                {data?.productDetails[currentProductIndex]?.name || 'Product Name'}
                              </div>
                              <div style={{ color: '#A9A9A9', fontSize: '12px', marginBottom: '4px' }}>
                                {data?.productDetails[currentProductIndex]?.id || 'Product ID'}
                              </div>
                              <div style={{ fontWeight: 'bold' }}>
                                {data?.productDetails[currentProductIndex]?.price || '150.00'}
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div style={{
                            padding: '12px',
                            color: '#A9A9A9',
                            textAlign: 'center'
                          }}>
                            No products selected
                          </div>
                        )}
                      </div>

                      {(data.productDetails && data.productDetails.length > 1 && data.products) && (
                        <div style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          gap: '8px'
                        }}>
                          <button
                            onClick={handlePrevProduct}
                            style={{
                              width: '32px',
                              height: '32px',
                              borderRadius: '50%',
                              background: '#1A1A1A',
                              border: '1px solid #3A3A3A',
                              cursor: 'pointer',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              color: '#FFFFFF'
                            }}
                          >
                            ←
                          </button>

                          <div style={{
                            display: 'flex',
                            gap: '4px',
                            alignItems: 'center'
                          }}>
                            {data.products.map((_, index) => (
                              <div
                                key={index}
                                style={{
                                  width: '6px',
                                  height: '6px',
                                  borderRadius: '50%',
                                  background: index === currentProductIndex ? '#FFFFFF' : '#808080'
                                }}
                              />
                            ))}
                          </div>

                          <button
                            onClick={handleNextProduct}
                            style={{
                              width: '32px',
                              height: '32px',
                              borderRadius: '50%',
                              background: '#1A1A1A',
                              border: '1px solid #3A3A3A',
                              cursor: 'pointer',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              color: '#FFFFFF'
                            }}
                          >
                            →
                          </button>
                        </div>
                      )}
                    </div>
                  )

                    : (
                      <p style={{ color: "#A9A9A9", margin: "4px 0" }}>
                        Type message here
                      </p>
                    )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (showCommentsModal) {
    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
        padding: '20px',
        background: '#FFFFFF',
        borderRadius: '12px',
        width: '100%'
      }}>
        <span style={{
          color: '#666666',
        }}>
          You can create up to 10 comment as a comment reply. Cheerio will randomly reply from these comments
        </span>
        {comments.map((comment, index) => (
          <div key={index} style={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#FAFAFA',
            borderRadius: '8px',
            marginBottom: '8px',
            position: 'relative',
          }}>
            <div style={{ position: 'relative', flex: 1 }}>
              <textarea
                value={comment.text}
                onChange={(e) => {
                  const updatedComments = comments.map((c, i) =>
                    i === index ? { ...c, text: e.target.value } : c
                  );
                  setComments(updatedComments);
                  setData({ ...data, comments: updatedComments });
                }}
                placeholder="Add comment text"
                style={{
                  width: '100%',
                  padding: '8px 40px 8px 12px',
                  borderRadius: '4px',
                  border: 'none',
                  backgroundColor: '#FAFAFA',
                  outline: 'none',
                  resize: 'none',
                  minHeight: '36px',
                  maxHeight: '100px',
                  overflow: 'auto',
                  lineHeight: '20px',
                  margin: '8px 0',
                  verticalAlign: 'middle'
                }}
              />
              <button
                onClick={() => {
                  const updatedComments = comments.map(c => ({
                    ...c,
                    showEmojiPicker: false
                  }));

                  updatedComments[index].showEmojiPicker = true;

                  setComments(updatedComments);
                }}
                style={{
                  position: 'absolute',
                  right: '8px',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  border: 'none',
                  backgroundColor: 'transparent',
                  cursor: 'pointer',
                  fontSize: '20px',
                  padding: '4px'
                }}
              >
                😊
              </button>
              {comment.showEmojiPicker && (
                <div style={{
                  position: 'absolute',
                  top: '100%',
                  right: '0',
                  zIndex: 1000,
                  backgroundColor: 'white',
                  borderRadius: '8px',
                  boxShadow: '0 4px 6px rgba(0,0,0,0.1)'
                }}>
                  <div style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    padding: '8px',
                    borderBottom: '1px solid #e0e0e0'
                  }}>
                    <button
                      onClick={() => {
                        const updatedComments = [...comments];
                        updatedComments[index].showEmojiPicker = false;
                        setComments(updatedComments);
                      }}
                      style={{
                        background: 'none',
                        border: 'none',
                        cursor: 'pointer',
                        padding: '4px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      <RiCloseLine size={20} />
                    </button>
                  </div>
                  <EmojiPicker onEmojiClick={(emojiData) => {
                    const updatedComments = [...comments];
                    updatedComments[index].text += emojiData.emoji;
                    setComments(updatedComments);
                    setData({ ...data, comments: updatedComments });
                  }} />
                </div>
              )}
            </div>

            <button
              onClick={() => {
                const updatedComments = comments.filter((_, i) => i !== index);
                setComments(updatedComments);
                setData({ ...data, comments: updatedComments });
              }}
              style={{
                padding: '8px',
                backgroundColor: 'transparent',
                border: 'none',
                cursor: 'pointer',
                height: '36px',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <RiDeleteBinLine />
            </button>
          </div>
        ))}

        {comments.length < 10 && (
          <button
            onClick={() => {
              if (comments.length < 10) {
                const newComment = {
                  text: '',
                  showEmojiPicker: false
                };
                const updatedComments = [...comments, newComment];
                setComments(updatedComments);
                setData({ ...data, comments: updatedComments });
              }
            }}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '8px',
              padding: '12px',
              width: '100%',
              backgroundColor: '#FAFAFA',
              border: '1px dashed #E0E0E0',
              borderRadius: '8px',
              cursor: 'pointer',
              opacity: comments.length >= 10 ? 0.5 : 1
            }}
            disabled={comments.length >= 10}
          >
            <span><IoMdAdd size={17} style={{ marginBottom: 2 }} /> Add Comment</span>
          </button>
        )}
        <div
          style={{
            background: '#FAFAFA',
            borderRadius: 10,
            padding: '12px',
            marginTop: '16px'
          }}
        >
          <div style={{ fontWeight: 600, marginBottom: '8px' }}>Save response as</div>
          <input
            className="form-control shadow-none"
            style={{
              width: '100%',
              padding: '8px',
              borderRadius: '4px',
              background: '#FAFAFA',
              marginBottom: '12px',
              border: '1px solid #EFEFEF'
            }}
            value={variable}
            onChange={(e) => {
              const val = e.target.value;
              eventsend("save-replies-to-variable_Instagram-action-node_clicked");
              if (val.startsWith('@')) {
                setVariable(val);
              } else {
                setVariable('@' + val);
              }
            }}
          />
          <div className="d-flex flex-row justify-content-between align-items-center">
            <Form.Check
              type="checkbox"
              className="btncustom"
              checked={skipReply}
              label="Skip user reply"
              onChange={() => {
                eventsend("skip-user-reply_Instagram-action-node_clicked");      
                setSkipReply(!skipReply);
              }}
            />
            <Tooltip
              title={'Next node will trigger itself even if recipient does not reply'}
              placement="bottom-end"
            >
              <div>
                <AiOutlineInfoCircle size={20} style={{ marginInlineEnd: 16 }} />
              </div>
            </Tooltip>
          </div>
        </div>
      </div>

    );
  }

  return (
    <div style={{
      padding: '20px 0',
      background: '#FAFAFA',
      borderRadius: '8px'
    }}>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
        padding: '0 10px',
        maxWidth: '100%'
      }}>
        <div
          onClick={handleReplyInDM}
          style={{
            padding: '16px',
            borderRadius: '8px',
            cursor: 'pointer',
            border: selectedPostType === 'replyinDM' ? '2px solid #8E53F7' : 'none',
            background: '#FFFFFF',
            width: '100%'
          }}
        >
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
            <span style={{ fontWeight: '500' }}>Reply in DM</span>
            {selectedPostType === 'replyinDM' && (
              <div style={{
                width: '20px',
                height: '20px',
                borderRadius: '50%',
                background: '#8E53F7',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
                <span style={{ color: 'white' }}>✓</span>
              </div>
            )}
          </div>

        </div>

        {localdata?.metaConfig?.newIGToken && (<div
          onClick={handleReplyInComments}
          style={{
            padding: '16px',
            borderRadius: '8px',
            cursor: 'pointer',
            border: selectedPostType === 'replyinComments' ? '2px solid #8E53F7' : 'none',
            background: '#FFFFFF',
            width: '100%'
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <span style={{ fontWeight: '500' }}>Reply in Comments</span>
            {selectedPostType === 'replyinComments' && (
              <div style={{
                width: '20px',
                height: '20px',
                borderRadius: '50%',
                background: '#8E53F7',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
                <span style={{ color: 'white' }}>✓</span>
              </div>
            )}
          </div>
        </div>)}
      </div>
    </div>
  );
};

export const selectStyles = {
  backgroundColor: '#FFFFFF',
  '& .MuiSelect-select': {
    padding: '6px 12px',
    color: '#666',
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none'
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    border: 'none'
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: 'none'
  }
};

export const inputStyles = {
  border: '1px solid #8E53F7',
  borderRadius: '20px',
  padding: '6px 12px',
  outline: 'none',
  width: '120px',
  background: 'none',
  textAlign: 'center',
  '&:focus': {
    borderColor: '#8E53F7',
  }
};

export const containerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: `1px solid ${colors.borderwhite}`,
  borderRadius: 8,
  height: 'auto',
  width: '100%',
  maxHeight: '100%',
  overflow: 'hidden',
};

export default InstagramActionsModal;
