import React, { useState, useEffect } from 'react';
import colors from '../../Utils/colors';
import images from '../../Utils/images';
import { useSelector } from 'react-redux';
import { getAllSenderId } from '../../Services';

const Sidebar = () => {
  const [EventsOpen, setEventsOpen] = useState(true);
  const [ActionOpen, setActionOpen] = useState(true);
  const localdata = useSelector((state) => state.main.localdata);
  const authtoken = useSelector((state) => state.main.auth_token);
  const [checkSender, setChecksender] = useState(false);
  const isBasic = localdata?.premiumPlanName === 'Basic' && localdata?.premiumSubscriptionId;
  const isStartup = localdata?.premiumPlanName === 'Startup' && localdata?.premiumSubscriptionId;
  const isPlanNameEmpty = !localdata?.premiumPlanName || localdata?.premiumPlanName === "";

  // const isCheerioEmail = localdata.email.endsWith('@cheerio.in');

  const isGptUser = localdata.integratedApp.some(app => app.includes("Chat GPT"));
  const instagramSpecialId = localdata?._id === '660e878b6d6015c4e49b0434' || localdata?._id === '6489675b4f598e055ed74e34' || localdata?._id === '65bb8f10ce5345ef070cc069' || localdata._id === "664def3a016ce42427d48d7d";

  const specialID =
    localdata?._id === '636be67d42c9a5cb33f60d98' ||
    localdata?._id === '660e878b6d6015c4e49b0434' ||
    localdata?._id === '6489675b4f598e055ed74e34' ||
    localdata?._id === '65f7d39aecb11f98652fffcd' ||
    localdata?._id === '65f7d42aecb11f98652fffd2';

  useEffect(() => {
    const getSenderApiCall = async () => {
      const token = authtoken;
      try {
        const res = await getAllSenderId(token);
        const isVerified = Object.values(res?.data).some((sender) => sender?.verified?.status);
        if (res?.flag) {
          if (isVerified) {
            setChecksender(true);
          } else {
            setChecksender(false);
          }
        } else {
          setChecksender(false);
        }
      } catch (error) {
        console.error('Error fetching sender IDs:', error);
        setChecksender(false);
      }
    };
    getSenderApiCall();
  }, []);

  const onDragStart = (event, nodeType) => {
    event.dataTransfer.setData('application/reactflow', nodeType);
    event.dataTransfer.effectAllowed = 'move';
  };

  const SideItem = ({ itemColor, imgSource, itemText, nodeType, backGround = false }) => {
    const isDisabled = itemText === 'Send Email' && !checkSender;
    return (
      <>
        <div
          className="d-flex flex-column justify-content-start align-items-center"
          style={{
            width: 60,
            opacity: isDisabled ? 0.5 : 1,
            pointerEvents: isDisabled ? 'none' : 'auto',
          }}
        >
          {backGround ? (
            <>
              <div
                className="d-flex justify-content-center align-items-center"
                style={{
                  height: 50,
                  width: 50,
                  borderRadius: 10,
                  background: itemColor,
                  // backgroundColor: itemColor,
                  cursor: isDisabled ? 'not-allowed' : 'pointer',
                }}
                onDragStart={(event) => onDragStart(event, nodeType)}
                draggable
              >
                <img src={imgSource} style={{ height: 32, width: 32, objectFit: 'contain' }}></img>
              </div>
            </>
          ) : (
            <>
              <div
                className="d-flex justify-content-center align-items-center"
                style={{
                  height: 50,
                  width: 50,
                  borderRadius: 10,
                  backgroundColor: itemColor,
                  cursor: 'pointer',
                }}
                onDragStart={(event) => onDragStart(event, nodeType)}
                draggable
              >
                <img src={imgSource} style={{ height: 32, width: 32, objectFit: 'contain' }}></img>
              </div>
            </>
          )}

          <p
            className=""
            style={{
              margin: 0,
              padding: 0,
              marginBlock: 4,
              fontSize: 10,
              fontWeight: 600,
              color: colors.black,
              flexWrap: 'wrap',
              textAlign: 'center',
            }}
          >
            {itemText}
          </p>
        </div>
      </>
    );
  };

  return (
    <>
      <div
        className="verythinscrollnewinv"
        style={{
          height: '100%',
          width: 140,
          backgroundColor: colors.white01,
          border: `1px solid ${colors.borderwhite}`,
          paddingBlock: 8,
          overflowY: 'auto',
        }}
      >
        <div
          className="d-flex flex-column justify-content-start align-items-center w-100"
          style={{ paddingBlock: 10, borderBottom: `1px solid ${colors.borderwhite}` }}
        >
          <div
            className="d-flex flex-row justify-content-between align-items-center w-100"
            style={{ paddingInline: 10 }}
          >
            <p
              style={{ margin: 0, padding: 0, fontSize: 14, fontWeight: 700, color: colors.black }}
            >
              {'Triggers'}
            </p>
            <img
              src={images.DownArrowTip}
              style={{
                height: 20,
                width: 20,
                objectFit: 'contain',
                cursor: 'pointer',
                transform: EventsOpen ? 'rotate(180deg)' : '',
                transition: 'all 0.2s ease-in-out',
              }}
              onClick={() => {
                setEventsOpen(!EventsOpen);
              }}
            ></img>
          </div>
          <p
            style={{
              margin: 0,
              padding: 0,
              fontSize: 10,
              fontWeight: 400,
              color: colors.greys04,
              width: '100%',
              paddingInline: 10,
            }}
          >
            {'When this happens'}
          </p>
          <div
            className="flex-row justify-content-start align-items-start w-100"
            style={{
              marginBlockStart: 8,
              transition: 'visibility 0.2s ease-in-out',
              display: EventsOpen ? 'flex' : 'none',
              flexWrap: 'wrap',
              paddingInline: 5,
            }}
          >
            <SideItem
              itemColor={colors.success03}
              imgSource={images.WFWhatsApp}
              itemText={'Incoming WhatsApp'}
              nodeType={'incomingWA'}
            />

            <SideItem
              itemColor={'#3200C1'}
              imgSource={images.WFAnnounce}
              itemText={'Campaign Sent'}
              nodeType={'campaign'}
            />
            {((!isBasic) || isPlanNameEmpty) && (
              <SideItem
                itemColor={'#004D1F'}
                imgSource={images.ShopifyWhite}
                itemText={'Shopify Events'}
                nodeType={'shopify'}
              />)}
            {((!isBasic && !isStartup) || isPlanNameEmpty) && (
              <SideItem
                itemColor={'#3366CC'}
                imgSource={images.FacebookWhite}
                itemText={'Facebook Lead'}
                nodeType={'facebookLead'}
              />)}
            <SideItem
              itemColor={'#0176FF'}
              imgSource={images.KylasWorkflow}
              itemText={'Kylas Event Create'}
              nodeType={'kylasCreate'}
            />
            <SideItem
              itemColor={'#0176FF'}
              imgSource={images.KylasWorkflow}
              itemText={'Kylas Event Update'}
              nodeType={'kylasUpdate'}
            />
            {/* {specialID && (
              <SideItem
                itemColor={
                  'linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%)'
                }
                imgSource={images.WFInstagram}
                itemText={'Instagram DM'}
                nodeType={'instagramLead'}
                backGround={true}
              />
            )} */}
            {((!isBasic && !isStartup) || isPlanNameEmpty) && (
              <SideItem
                itemColor={'#22B376'}
                imgSource={images.WFPabbly}
                itemText={'Pabbly Event'}
                nodeType={'pabblyEvent'}
                backGround={true}
              />)}
            <SideItem
              itemColor={'#009684'}
              imgSource={images.WEBHOOK_TRIGGER}
              itemText={'Incoming Webhook'}
              nodeType={'incomingWebhook'}
              backGround={true}
            />
            {(instagramSpecialId || specialID || ((!isBasic && !isStartup) || isPlanNameEmpty)) && <SideItem
              itemColor={'linear-gradient(45deg, #F58529, #DD2A7B, #8134AF, #515BD4)'}
              imgSource={images.WFInstagram}
              itemText={'Instagram'}
              nodeType={'instagramEvent'}
              backGround={true}
            />}
            {((!isBasic) || isPlanNameEmpty) && (
              <SideItem
                itemColor={'#7B50C6'}
                imgSource={images.WOOIMG}
                itemText={'Commerce Event'}
                nodeType={'wooWFNode'}
                backGround={true}
              />)}
            <SideItem
              itemColor={'#530FE3'}
              imgSource={images.CONTACT_ICON}
              itemText={'New contact'}
              nodeType={'contactWFNode'}
              backGround={true}
            />
            <SideItem
              itemColor={'#8E53F7'}
              imgSource={images.WFForm}
              itemText={'New form response'}
              nodeType={'formsWFNode'}
              backGround={true}
            />
          </div>
        </div>
        <div
          className="d-flex flex-column justify-content-start align-items-center w-100"
          style={{ paddingBlock: 10, borderBottom: `1px solid ${colors.borderwhite}` }}
        >
          <div
            className="d-flex flex-row justify-content-between align-items-center w-100"
            style={{ paddingInline: 10 }}
          >
            <p
              style={{ margin: 0, padding: 0, fontSize: 14, fontWeight: 700, color: colors.black }}
            >
              {'Actions'}
            </p>
            <img
              src={images.DownArrowTip}
              style={{
                height: 20,
                width: 20,
                objectFit: 'contain',
                cursor: 'pointer',
                transform: ActionOpen ? 'rotate(180deg)' : '',
                transition: 'all 0.2s ease-in-out',
              }}
              onClick={() => {
                setActionOpen(!ActionOpen);
              }}
            ></img>
          </div>
          <p
            style={{
              margin: 0,
              padding: 0,
              paddingInline: 10,
              fontSize: 10,
              fontWeight: 400,
              color: colors.greys04,
              width: '100%',
            }}
          >
            {'System initiated'}
          </p>
          <div
            className="flex-row justify-content-start align-items-start w-100"
            style={{
              marginBlockStart: 8,
              transition: 'visibility 0.2s ease-in-out',
              display: ActionOpen ? 'flex' : 'none',
              flexWrap: 'wrap',
              paddingInline: 5,
            }}
          >
            {/* <SideItem 
                    itemColor={colors.success03}
                    imgSource={images.WFDefault}
                    itemText={'Default WA response'}
                    nodeType={'defaultResponse'}
                /> */}
            <SideItem
              itemColor={colors.success03}
              imgSource={images.WFResponse}
              itemText={'Response message'}
              nodeType={'responseMessage'}
            />
            <SideItem
              itemColor={colors.success03}
              imgSource={images.WFWhatsApp}
              itemText={'Send WA template'}
              nodeType={'sendWATemplate'}
            /><SideItem
              itemColor={"#FAC300"}
              imgSource={images.WFFeedback}
              itemText={'Feedback Collection'}
              nodeType={'feedbackNode'}
            />
            <SideItem
              itemColor={'#FF4D4D'}
              imgSource={images.WFEmail}
              itemText={'Send Email'}
              nodeType={'sendEmail'}
            />
            {(instagramSpecialId || specialID || ((!isBasic && !isStartup) || isPlanNameEmpty)) && <SideItem
              itemColor={
                'linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%)'
              }
              imgSource={images.WFResponse}
              itemText={'Instagram Action'}
              nodeType={'instagramAction'}
              backGround={true}
            />}
            <SideItem
              itemColor={'#0064E1'}
              imgSource={images.PIXEL_EVENT}
              itemText={'Meta Pixel'}
              nodeType={'metaPixelNode'}
              backGround={true}
            />
            <SideItem
              itemColor={'#004D1F'}
              imgSource={images.ShopifyWhite}
              itemText={'Shopify Actions'}
              nodeType={'updateShopifyAttributes'}
            />
            {(specialID || isGptUser ||
              localdata._id === '65798c2397fa479d4ff02d57' ||
              localdata._id === '65bb8f10ce5345ef070cc069' ||
              ((!isBasic && !isStartup) || isPlanNameEmpty)) && (
                <SideItem
                  itemColor={'#14B38B'}
                  imgSource={images.WFGpt}
                  itemText={'Assign GPT'}
                  nodeType={'chatgpt'}
                />
              )}
            <SideItem
              itemColor={colors.yellow04}
              imgSource={images.WFChatBot}
              itemText={'Assign to chatbot'}
              nodeType={'assignChatbot'}
            />
            <SideItem
              itemColor={colors.success03}
              imgSource={images.Book}
              itemText={'Send WA Catalog'}
              nodeType={'catalog'}
            />
            <SideItem
              itemColor={'#B752C7'}
              imgSource={images.PaymentReceipt}
              itemText={'Payment Message'}
              nodeType={'whatsAppPayment'}
            />

            <SideItem
              itemColor={'#FF7C32'}
              imgSource={images.WFAgent}
              itemText={'Assign Agent'}
              nodeType={'assignAgent'}
            />

            <SideItem
              itemColor={'#30D3C0'}
              imgSource={images.WFChatStatus}
              itemText={'Update Chat Status'}
              nodeType={'updateChatStatus'}
            />

            <SideItem
              itemColor={colors.blue03}
              imgSource={images.WFTime}
              itemText={'Time delay'}
              nodeType={'timeDelay'}
            />
            <SideItem
              itemColor={colors.error03}
              imgSource={images.WFAT}
              itemText={'Attribute condition'}
              nodeType={'attributeCondition'}
            />



            {/* <SideItem 
                    itemColor={colors.linkblue}
                    imgSource={images.WFSMS}
                    itemText={'Send SMS'}
                    nodeType={'sendSMS'}
                /> */}
            {/* <SideItem 
                    itemColor={'#FF6666'}
                    imgSource={images.WFNewFlow}
                    itemText={'Invoke new flow'}
                    nodeType={'invokeNewFlow'}
                /> */}
            {/* <SideItem 
                    itemColor={'#777777'}
                    imgSource={images.WFUnsubscribe}
                    itemText={'Unsubscribe'}
                /> */}



            <SideItem
              itemColor={'#DBEEF5'}
              imgSource={images.WFLlama}
              itemText={'Llama'}
              nodeType={'llama'}
            />
            <SideItem
              itemColor={'#14B38B'}
              imgSource={images.Label}
              itemText={'Add to Label'}
              nodeType={'addToLabel'}
            />

            <SideItem
              itemColor={'#6430D3'}
              imgSource={images.Tag}
              itemText={'Update Attribute'}
              nodeType={'updateAttribute'}
            />
            <SideItem
              itemColor={colors.error03}
              imgSource={images.WFAT}
              itemText={'Set a condition'}
              nodeType={'condition'}
            />
            <SideItem
              itemColor={colors.error03}
              imgSource={images.WEBHOOK_TRIGGER}
              itemText={'Webhook Trigger'}
              nodeType={'webhookTrigger'}
            />
            <SideItem
              itemColor={'#C10808'}
              imgSource={images.WFEnd}
              itemText={'End Flow'}
              nodeType={'endNode'}
            />
            <SideItem
              itemColor={'#18C655'}
              imgSource={images.BROADCAST_ICON}
              itemText={'Update Broadcast'}
              nodeType={'updateBroadcast'}
            />

          </div>
        </div>
        {/* <div className='d-flex flex-row justify-content-evenly align-items-center w-100' style={{flexWrap:'wrap'}}>
            <div className='d-flex justify-content-center align-items-center' 
                style={{height:50,width:50,borderRadius:10,backgroundColor:colors.success03,cursor:'pointer'}}
                onDragStart={(event)=>onDragStart(event,'defaultResponse')}
                draggable
            >
                <img src={images.WFWhatsApp} style={{height:32,width:32,objectFit:'contain'}}></img>
            </div>
            <div className='d-flex justify-content-center align-items-center' style={{height:50,width:50,borderRadius:10}}>
                
            </div>
        </div> */}
      </div>
    </>
  );
};

export default Sidebar;
