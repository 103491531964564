import React, { useState } from "react";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker, TimePicker, DateTimePicker } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";

dayjs.extend(localizedFormat);

const DateTimeInput = ({ type = "date", value, onChange, currentEditing = true }) => {
    const [inputValue, setInputValue] = useState(value || "");

    const handleChange = (newValue) => {
        let formattedValue = newValue;
        if (type === "date" && dayjs(newValue).isValid()) {
            formattedValue = dayjs(newValue).format("DD/MM/YYYY");
        } else if (type === "time" && dayjs(newValue).isValid()) {
            formattedValue = dayjs(newValue).format("h:mm A");
        } else if (type === "timestamp" && dayjs(newValue).isValid()) {
            formattedValue = dayjs(newValue).format("DD/MM/YYYY, h:mm A");
        }
        setInputValue(formattedValue);
        if (onChange) onChange(formattedValue);
    };

    const parseValue = (val, format) => {
        if (!val) return null;
        const parsedValue = dayjs(val, format, true); // strict parsing
        return parsedValue.isValid() ? parsedValue : null;
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            {type === "date" && (
                <DatePicker
                    value={parseValue(inputValue, "DD/MM/YYYY")}
                    onChange={(newValue) => handleChange(newValue)}
                    format="DD/MM/YYYY"
                    disabled={!currentEditing}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            sx={{
                                "& .MuiInputBase-root": {
                                    padding: "2px 6px", // Reduce padding
                                    fontSize: "0.875rem", // Adjust font size
                                },
                                "& .MuiSvgIcon-root": {
                                    fontSize: "18px", // Adjust icon size
                                },
                            }}
                        />
                    )}
                />
            )}
            {type === "time" && (
                <TimePicker
                    value={parseValue(inputValue, "h:mm A")}
                    onChange={(newValue) => handleChange(newValue)}
                    format="h:mm A"
                    disabled={!currentEditing}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            sx={{
                                "& .MuiInputBase-root": {
                                    padding: "2px 6px", // Reduce padding
                                    fontSize: "0.875rem", // Adjust font size
                                },
                                "& .MuiSvgIcon-root": {
                                    fontSize: "18px", // Adjust icon size
                                },
                            }}
                        />
                    )}
                />
            )}
            {type === "timestamp" && (
                <DateTimePicker
                    value={parseValue(inputValue, "DD/MM/YYYY, h:mm A")}
                    onChange={(newValue) => handleChange(newValue)}
                    format="DD/MM/YYYY, h:mm A"
                    disabled={!currentEditing}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            sx={{
                                "& .MuiInputBase-root": {
                                    padding: "2px 6px", // Reduce padding
                                    fontSize: "0.875rem", // Adjust font size
                                },
                                "& .MuiSvgIcon-root": {
                                    height: "2px",
                                    width: "2px",
                                },
                            }}
                        />
                    )}
                />
            )}
        </LocalizationProvider>
    );
};

export default DateTimeInput;
