import React, { useEffect, useState } from 'react';
import colors from '../../../Utils/colors';
import images from '../../../Utils/images';
import { Form, InputGroup } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { contactlabellistWithoutCountAPI } from '../../../Services';
import { toast } from 'react-toastify';
import CheerioButton from '../../../Components/CheerioButton';

const VerificationStartModal = ({
  setIsModalOpen,
  verificationName,
  setVerificationName,
  selectedLabel,
  setSelectedLabel, 
  onclicknextaction,
}) => {
  const auth_token = useSelector((state) => state.main.auth_token);
  const [labelList, setLabelList] = useState();

  useEffect(() => {
    getlabellistapicall();
  }, []);

  const getlabellistapicall = () => {
    let token = auth_token;
    contactlabellistWithoutCountAPI(token).then((res) => {
      if (res?.flag) {
        setLabelList(
          res.data.v1.filter((item) => {
            return !(
              item?.label_name.includes('abandoned-cart-on') ||
              item?.label_name.includes('orders-feedback-on') ||
              item?.label_name.includes('orders-cancelled-on') ||
              item?.label_name.includes('Workflows -') ||
              item?.label_name.includes('Workflows-') ||
              item?.label_name.includes('WhatsApp Contacts who') ||
              item?.label_name.includes('orders-fulfilled-on') ||
              item?.label_name.includes('orders-placed-on') ||
              item?.label_name.includes('cod-confirmation-on')
            );
          })
        );
      } else {
        toast.error('Unable to fetch labels list, please try again later', {
          position: 'top-right',
          autoClose: true,
        });
      }
    });
  };

  return (
    <>
      <div className="DarkBg" onClick={() => {}}>
        <div className="Center" style={{transform:'translate(calc(-50% + 40px),-50%)'}}>
          <div
            className="Column"
            style={{
              justifyContent: 'flex-start',
              width: 600,
              backgroundColor: colors.white01,
              borderRadius: 16,
              zIndex: 0,
              border: `1px solid ${colors.borderwhite}`,
              paddingInline: 40,
              paddingBlock: 24,
            }}
          >
            <div
              className="Row justify-content-between w-100"
              style={{
                paddingBlockEnd: 20,
                borderBottom: `1px solid ${colors.borderwhite}`,
              }}
            >
              <h4 style={{ fontSize: 16, fontWeight: 600 }}>{'Email Verification'}</h4>
              <img
                src={images.CMClose}
                style={{
                  height: 20,
                  width: 20,
                  objectFit: 'contain',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setIsModalOpen(false);
                }}
              />
            </div>
            <div className="Column my-2 w-100">
              <InputGroup className="my-2 w-100">
                <Form.Label style={{ fontSize: 16, fontWeight: 500 }}>{'Name'}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="verification name"
                  className="w-100 btncustom"
                  style={{ borderRadius: 8 }}
                  value={verificationName}
                  onChange={(e) => {
                    setVerificationName(e.target.value);
                  }}
                />
              </InputGroup>
              <InputGroup className="my-2 w-100">
                <Form.Label style={{ fontSize: 16, fontWeight: 500 }}>
                  {'Select Existing Label'}
                </Form.Label>
                {/* <Form.Control 
                            type='text' 
                            placeholder='verification name' 
                            className='w-100 btncustom' 
                            style={{borderRadius:8}} 
                            value={verificationName} 
                            onChange={(e)=>{setVerificationName(e.target.value)}}
                        />  */}
                <Form.Select
                  className="btncustom w-100 dropdown mt-2"
                  style={{ borderRadius: 8, fontSize: 16, fontWeight: 400 }}
                  value={selectedLabel?._id}
                  onChange={(e) => {
                    setSelectedLabel(labelList.filter((item) => item?._id === e.target.value)[0]);
                  }}
                >
                  <option
                    style={{ width: '100%', marginBlock: 4, borderRadius: 8 }}
                    key="blankchoice"
                    hidden
                    value
                  >
                    {'Select label'}
                  </option>
                  {labelList &&
                    labelList.map((e) => {
                      const displayText =
                        e.label_name.length > 30
                          ? e.label_name.substring(0, 30) + '...'
                          : e.label_name;
                      return (
                        <option
                          style={{
                            width: '100%',
                            marginBlock: 4,
                            borderRadius: 8,
                          }}
                          value={e._id}
                          key={e._id}
                          title={e.label_name}
                        >
                          {displayText}
                        </option>
                      );
                    })}
                </Form.Select>
              </InputGroup>
            </div>
            <CheerioButton
              btnText={'Next'}
              textStyle={{ color: colors.white01 }}
              backColor={colors.primary03}
              borderStyle={{
                paddingInline: 20,
                paddingBlock: 8,
                borderColor: colors.primary03,
                marginBlock: 16,
                width:'100%',
              }}
              onclick={() => {
                onclicknextaction()
              }} 
              disabled={selectedLabel?._id && verificationName ? false : true}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default VerificationStartModal;
