import React, { useState } from 'react'
import { Route, Routes } from 'react-router-dom';
import EFhome from './EFhome';
import EFCreate from './EFCreate';
import EFResponses from './EFResponses';

const EmbeddableForms = () => {

    const [currentScreen, setCurrentScreen] = useState('home');

  return (
    <>
    <Routes>
        <Route path='/' element={<EFhome />}></Route>
        <Route path='/create' element={<EFCreate />}></Route>
        <Route path='/create/:id' element={<EFCreate />}></Route>
        <Route path='/responses/:id/:formName' element={<EFResponses />}></Route>
    </Routes>
    </>
  )
}

export default EmbeddableForms