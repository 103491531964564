import React from 'react';
import CheerioButton from '../CheerioButton';
import colors from '../../Utils/colors';

const DeleteModal = ({ setDeleteModalOpen, deleteAction }) => {
  return (
    <>
      <div
        style={{
          width: '100%',
          height: "100%",
          zIndex: 1000,
          top: 0,
          left: 0,
          position: 'fixed',
          background: 'rgba(0,0,0,0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            width: '400px',
            background: colors.white01,
            borderRadius: '8px',
            padding: '20px',
            boxShadow: '0 4px 10px rgba(0,0,0,0.2)',
          }}
        >
          <div className="delete_modal__header" style={{ textAlign: 'center' }}>
            <h3>{'Delete Confirmation'}</h3>
          </div>
          <p style={{ textAlign: 'start', marginBottom: '20px' }}>
            {'Deletion is a permanent action, please proceed with caution.'}
          </p>
          <div
            className="delete_modal__button_row"
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <CheerioButton
              borderStyle={{ borderColor: colors.black, width: '48%' }}
              textStyle={{ color: colors.white03 }}
              btnText={'Delete'}
              backColor={colors.red49}
              onclick={deleteAction}
            />
            <CheerioButton
              borderStyle={{ borderColor: colors.black, width: '48%' }}
              textStyle={{ color: colors.black }}
              btnText={'Cancel'}
              backColor={colors.white01}
              onclick={() => setDeleteModalOpen(false)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default DeleteModal;
