import React, { useEffect, useState } from 'react';
import colors from '../../../Utils/colors';
import images from '../../../Utils/images';
import CheerioButton from '../../../Components/CheerioButton';
import { TextField } from '@mui/material';
import SpinnerLoader from '../../../Components/SpinnerLoader';
import { useSelector } from 'react-redux';
import { sendExcelAnnouncement } from '../../../Services';
import { RiPaintFill } from 'react-icons/ri';
import SendWATemplateModal from '../../WorkFlow/Modals/SendWATemplateModal';

const ExcelAnnouncementModal = ({ setIsModalOpen, labelData }) => {
  const authtoken = useSelector((state) => state.main.auth_token);
  const [currentScreen, setCurrentScreen] = useState('Start');
  const [CName, setCName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [headerText, setHeaderText] = useState('');
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [textColor, setTextColor] = useState('#000000');
  const [cellColor, setCellColor] = useState('#ff0000');
  const [btndisabled, setBtndisabled] = useState(false);
  const [annData, setAnnData] = useState({});

  useEffect(() => {
    console.log('111111111 ', labelData);
  }, []);


  const sendExcelAnnouncementAPIcall = () => {
    setCurrentScreen('Loading');
    let token = authtoken;
    let data = {
      labelId: labelData?._id,
      campaignName: CName,
      foreignKey: 'loid',
      announcement: {
        ...annData,
      },
      headerHTML: {
        header: headerText,
        bold: isBold,
        italic: isItalic,
        color: textColor,
        bgColor: cellColor,
      }
    };
    const successTimeout = setTimeout(() => {
      setCurrentScreen('Success');
    }, 3000);
    sendExcelAnnouncement(data, token).then((res) => {
      clearTimeout(successTimeout);
      if (res?.flag) {
        // setTimeout(() => {
        //     setCurrentScreen('Success')
        // }, 1000);
      } else {
        // setErrorMessage(res?.message ?? 'Unexpected error occurred')
        // setTimeout(() => {
        //     setCurrentScreen('Failure')
        // }, 1000);
      }
      setTimeout(() => {
        setCurrentScreen('Success');
      }, 1000);
    });
  };

  return (
    <>
      <div className="DarkBg">
        <div className="Center">
          <div
            style={{
              width: 900,
              height: 500,
              backgroundColor: colors.white01,
              borderRadius: 16,
              zIndex: 0,
              border: `1px solid ${colors.borderwhite}`,
              paddingBlock: 30,
              paddingInline: 40,
              // overflowY: 'auto',
            }}
          >
            <div className="Column w-100 h-100 justify-content-between">
              <div
                className="Row w-100 justify-content-between"
                style={{
                  borderBottom: `1px solid ${colors.borderwhite}`,
                  paddingBlockEnd: 16,
                  marginBlockEnd: 16,
                }}
              >
                <h3>{'Run a campaign'}</h3>
                {currentScreen !== 'Loading' && (
                  <img
                    src={images.CMClose}
                    style={{
                      height: 20,
                      width: 20,
                      objectFit: 'contain',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      setIsModalOpen(false);
                    }}
                  ></img>
                )}
              </div>
              <div className='Column justify-content-start w-100' style={{flex:1,overflowY:'auto'}}>
                <>
                {currentScreen == 'Start' ? (
                <>
                  <div
                    className="Column w-100"
                    style={{
                      flex: 1,
                      justifyContent: 'flex-start',
                    }}
                  >
                    <p style={{ fontWeight: 500, width: '100%' }}>{'Campaign Name'}</p>
                    <TextField
                      name="campaignName"
                      placeholder="Enter campaign name"
                      value={CName}
                      onChange={(e) => {
                        setCName(e.target.value);
                      }}
                      type="text"
                      size="small"
                      style={{
                        marginBlockStart: 8,
                        marginBlockEnd: 16,
                        width: '100%',
                      }}
                      className="btncustom"
                    />
                    <p style={{ fontWeight: 500, width: '100%' }}>{'Label'}</p>
                    <TextField
                      name="label"
                      value={labelData?.listName}
                      type="text"
                      size="small"
                      style={{
                        marginBlockStart: 8,
                        marginBlockEnd: 16,
                        width: '100%',
                      }}
                      className="btncustom"
                      inputProps={{ readOnly: true }}
                    />
                    <p style={{ fontWeight: 500, width: '100%' }}>
                      {'Add a header text for image (Optional)'}
                    </p>
                    <TextField
                      name="headerText"
                      value={headerText}
                      placeholder="Sample text"
                      onChange={(e) => {
                        setHeaderText(e.target.value);
                      }}
                      type="text"
                      size="small"
                      style={{
                        marginBlockStart: 8,
                        marginBlockEnd: 16,
                        width: '100%',
                      }}
                      className="btncustom"
                    />
                    <div className="Row justify-content-start align-items-center w-100">
                      <button
                        className="py-2"
                        style={{
                          backgroundColor: isBold ? colors.white04 : colors.white,
                          borderWidth: 0,
                          borderRadius: 6,
                          width: 32,
                          // fontWeight: isBold ? 500 : 400,
                        }}
                        onClick={() => {
                          setIsBold(!isBold);
                        }}
                      >
                        {'B'}
                      </button>
                      <button
                        className="py-2 mx-2"
                        style={{
                          backgroundColor: isItalic ? colors.white04 : colors.white,
                          borderWidth: 0,
                          borderRadius: 6,
                          fontStyle: 'italic',
                          width: 32,
                        }}
                        onClick={() => {
                          setIsItalic(!isItalic);
                        }}
                      >
                        {'I'}
                      </button>
                      <button
                        className="py-2"
                        style={{
                          backgroundColor: isUnderline ? colors.white04 : colors.white,
                          borderWidth: 0,
                          borderRadius: 6,
                          textDecorationLine: 'underline',
                          width: 32,
                        }}
                        onClick={() => {
                          setIsUnderline(!isUnderline);
                        }}
                      >
                        {'U'}
                      </button>
                      <div
                        className="mx-2"
                        style={{ width: 2, height: 24, backgroundColor: colors.greys01 }}
                      ></div>
                      <p
                        className="ms-2"
                        style={{
                          textDecorationLine: 'underline',
                          fontWeight: 'bold',
                          marginInlineEnd: 6,
                          fontSize: 18,
                        }}
                      >
                        {'A'}
                      </p>
                      <input
                        type="color"
                        value={textColor}
                        onChange={(e) => {
                          setTextColor(e.target.value);
                        }}
                        className="me-2"
                        style={{
                          width: 24,
                          height: 24,
                        }}
                      ></input>
                      <RiPaintFill size={18} style={{ marginInlineEnd: 6 }} className="ms-2" />
                      <input
                        type="color"
                        value={cellColor}
                        onChange={(e) => {
                          setCellColor(e.target.value);
                        }}
                        className="me-2"
                        style={{
                          width: 24,
                          height: 24,
                        }}
                      ></input>
                      <div
                        className="mx-3 px-4 py-1 Row"
                        style={{
                          borderRadius: 4,
                          backgroundColor: cellColor,
                        }}
                      >
                        <p
                          style={{
                            color: textColor,
                            fontWeight: isBold ? 'bold' : 'normal',
                            fontStyle: isItalic ? 'italic' : 'normal',
                            textDecorationLine: isUnderline ? 'underline' : 'none',
                          }}
                        >
                          {'Sample text'}
                        </p>
                      </div>
                    </div>
                  </div>
                </>
                ) : currentScreen == 'Template' ? (
                  <>
                  <div className='w-100 h-100' style={{}}>
                      <SendWATemplateModal 
                          btnDisabled={btndisabled} 
                          setBtnDisabled={setBtndisabled} 
                          data={annData} 
                          setData={setAnnData} 
                          ExcelAnn={true}
                      />
                  </div>
                  </>
                ) : currentScreen == 'Loading' ? (
                  <>
                    <div
                      className="Column justify-content-center w-100"
                      style={{
                        flex: 1,
                      }}
                    >
                      <SpinnerLoader />
                      <h3>{'Sending messages'}</h3>
                      <p>{'Please wait we’re sending messages'}</p>
                    </div>
                  </>
                ) : currentScreen == 'Success' ? (
                  <>
                    <div
                      className="Column justify-content-center w-100"
                      style={{
                        flex: 1,
                      }}
                    >
                      <img
                        src={images.CircleCheckGreen}
                        style={{
                          height: 140,
                          width: 140,
                          objectFit: 'contain',
                        }}
                      ></img>
                      <h3 className="my-2">{'Messages are being sent'}</h3>
                      <p>
                        {
                          'We’re sending your messages. Once it is completed we will send you a notification'
                        }
                      </p>
                    </div>
                  </>
                ) : (
                  currentScreen == 'Failure' && (
                    <>
                      <div
                        className="Column justify-content-center w-100"
                        style={{
                          flex: 1,
                        }}
                      >
                        <img
                          src={images.ErrorImg}
                          style={{
                            height: 140,
                            width: 140,
                            objectFit: 'contain',
                          }}
                        ></img>
                        <h3 className="my-2">{'Something went wrong! Please Retry'}</h3>
                        <p>{errorMessage}</p>
                      </div>
                    </>
                  )
                )}
                </>
              </div>
              
              {(currentScreen === 'Start' || currentScreen === 'Failure' || currentScreen === 'Template') && (
                <CheerioButton
                  borderStyle={{ borderWidth: 0, width: '100%', marginBlockStart: 16 }}
                  textStyle={{ color: colors.white01 }}
                  backColor={colors.primary03}
                  btnText={
                    currentScreen === 'Start'
                      ? 'Select template' 
                      : currentScreen === 'Template' ? 'Run Campaign'
                      : currentScreen === 'Failure' && 'Retry Campaign'
                  }
                  onclick={() => {
                    if (currentScreen === 'Start') {
                      setCurrentScreen('Template');
                    } else {
                      sendExcelAnnouncementAPIcall();
                    }
                  }}
                  disabled={CName && labelData?.listName ? false : true}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExcelAnnouncementModal;
