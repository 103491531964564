import { Form, InputGroup } from 'react-bootstrap';
import colors from '../../Utils/colors';
import { useRef, useState } from 'react';
import { Chip } from '@mui/material';
import { Styles } from './Styles';
import CheerioButton from '../../Components/CheerioButton';
import images from '../../Utils/images';
import { toast } from 'react-toastify';
import UploaderText from '../../Components/UploaderText';
import { eventsend } from '../../Config/analyticsFunctions';
const HeaderList = ['NONE', 'TEXT', 'IMAGE', 'VIDEO', 'DOCUMENT'];
const CarouselList = ['IMAGE', 'VIDEO'];
// media:{
//     filename: string;
//     base64: string;
// }
const UploadImageComponent = ({ type, media, setMedia }) => {
  const upimgref = useRef();
  const [dragActive, setDragActive] = useState(false);
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };
  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    console.log('e.dataTransfer.files', e.dataTransfer.files);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      eventsend("Header_dropdown_template_builder_selected");
      // setupimgname(file.name)
      console.log('e.dataTransfer.files[0]');
      handleUploadImage(e.dataTransfer.files[0]);
    } else {
      console.log('error drag and drop');
    }
  };

  const handleUploadImage = async (file) => {
    if (type === 'IMAGE' && file.size < 5242880) {
      if (file.type == 'image/jpg' || file.type == 'image/jpeg' || file.type == 'image/png') {
        const base64 = await convertToBase64(file);
        setMedia({
          filename: file.name,
          base64,
        });
      } else {
        toast.error('Wrong file format uploaded. Only jpeg, png, and jpg are supported', { position: 'top-center' });
      }
    } else if (type === 'VIDEO' && file.size < 16777216) {
      const base64 = await convertToBase64(file);
      setMedia({
        filename: file.name,
        base64,
      });
    } else if (type === 'DOCUMENT' && file.size < 104857600) {
      const base64 = await convertToBase64(file);
      setMedia({
        filename: file.name,
        base64,
      });
    } else {
      const fileSizeMap = {
        VIDEO: "16",
        IMAGE: "5",
        DOCUMENT: "100",
      };
      const fileSize = fileSizeMap[type] || "unknown"; 
      toast.error(`File size limit exceeded. Please upload the maximum file size of ${fileSize} MB`, { position: 'top-center' });
    }
  };

  const getDefaultImage = () => {
    if (type === 'IMAGE') {
      return images.Upload;
    } else if (type === 'VIDEO') {
      return images.Video;
    } else if (type === 'DOCUMENT') {
      return images.Pdf;
    } else {
      return images.Upload;
    }
  };

  const previewMedia = () => {
    if (media?.base64) {
      if (type === 'IMAGE') {
        return (
          <img
            src={media.base64}
            style={{ width: 140, height: 90, objectFit: 'contain', marginTop: 20 }}
          />
        );
      }
      if (type === 'VIDEO') {
        return (
          <>
            <video
              src={media.base64}
              style={{
                width: 140,
                height: 90,
                objectFit: 'contain',
                marginTop: 20,
                // marginTop: 20,
              }}
            ></video>
          </>
        );
      }

      if (type === 'DOCUMENT') {
        return (
          <embed
            src={media.base64}
            width="100%"
            height="100%"
            type="application/pdf"
            style={{
              width: 140,
              height: 90,
              objectFit: 'contain',
              marginTop: 20,
              // marginTop: 20,
            }}
          />
        );
      }
    } else {
      return (
        <>
          <img src={getDefaultImage()} style={{
            width: 140, height: 90, objectFit: 'contain', marginBlock: 12,
          }}></img>
          <UploaderText
            type={type}
            textStyle={{
              fontSize: 12,
              fontWeight: 600,
              color: colors.black,
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              wordBreak: 'break-all',
              textAlign: 'center',
            }}
          />
        </>

      )

    }

  };

  return (
    <>
      <InputGroup className="mb-2" style={{ width: '700px', position: 'relative', top: '16px' }}>
        <Form.Label
          style={{
            fontSize: 16,
            fontWeight: 600,
            color: colors.black,
            marginTop: '10px',
            width: '100%',
          }}
        >
          Upload {type}
        </Form.Label>

        <p
          style={{
            fontSize: 14,
            fontWeight: 400,
            color: colors.greys04,
            width: '100%',
            marginBlock: 8,
          }}
        >
          If you face an error while creating a template. Please make sure media is whatsapp compatible ( 8 Bit).
        </p>
        <p
          style={{
            fontSize: 14,
            fontWeight: 400,
            color: colors.greys04,
            width: '100%',
            marginBlock: 8,
          }}
        >
          To make media whatsapp compatible, please send media to any whatsapp contact. Download the sent media and upload again.
        </p>
        <div
          className="d-flex flex-column justify-content-around align-items-center"
          style={{
            width: '600px',
            border: `1px dotted ${colors.greys03}`,
            borderRadius: 16,
            cursor: 'pointer',
            height: 230,
            backgroundColor: colors.white02,
          }}
          onClick={() => {
            upimgref.current.click();
          }}
          // || upvideoref.current.click()
          onDragEnter={handleDrag}
          onDragOver={handleDrag}
          onDragExit={handleDrag}
          onDrop={handleDrop}
        >
          {previewMedia()}

          <p
            style={{
              fontSize: 12,
              fontWeight: 600,
              color: colors.black,
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              wordBreak: 'break-all',
            }}
          >
            {media.filename !== '' ? media.file_name : 'Drag & drop file here'}
            {media.base64 && (
              <img
                alt="cross"
                src={images.Cross}
                style={{
                  height: 18,
                  width: 18,
                  marginInlineStart: 24,
                  cursor: 'pointer',
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setMedia({
                    filename: '',
                    base64: null,
                  });
                }}
              />
            )}
          </p>
          {/* <p
            style={{
              fontSize: 12,
              fontWeight: 600,
              color: colors.black,
              textAlign: "center",
            }}
          >
            {"(350px * 350px) to (1500px * 1500px)"}
            <br />
            {"Max file size: 5MB"}
          </p> */}
        </div>
        <p
          style={{
            fontSize: 14,
            fontWeight: 400,
            color: colors.greys04,
            width: '100%',
            marginBlock: 8,
          }}
        >
          To help us review your content, provide examples of the variables or media in the header.
          Do not include any customer information. Cloud API hosted by Meta reviews templates and
          variable parameters to protect the security and integrity of our services.
        </p>
      </InputGroup>
      <div>
        <input
          type="file"
          style={{ display: 'none' }}
          ref={upimgref}
          accept={
            type === 'IMAGE'
              ? '.jpeg, .png, .jpg'
              : type === 'VIDEO'
                ? 'video/*'
                : type === 'DOCUMENT'
                  ? '.pdf, .doc'
                  : null
          }
          onChange={(e) => {
            if (e.target.files[0]) {
              handleUploadImage(e.target.files[0]);
            }
          }}
        />
      </div>
    </>
  );
};

export const VariableHeader = ({ data, setData, Carousel = false, index = 0 }) => {
  const editorRef = useRef(null);
  const dontResetCursor = (cursorPosition) => {
    setTimeout(() => {
      editorRef.current.focus();
      editorRef.current.setSelectionRange(cursorPosition, cursorPosition);
    }, 0);
  };

  const handleBodyTextChange = (event) => {
    const { value } = event.target;
    // if key is backspace remove char before cursor
    const cursorPosition = event.target.selectionStart;
    let modifiedText = value;
    const patternFullVariable = /\{\{+\d+\}\}+/g;
    const variablesDetected = value.match(patternFullVariable);
    const charactersBeforeCursor = value.substring(cursorPosition - 2, cursorPosition);
    if (event.nativeEvent.inputType === 'deleteContentBackward') {
      event.preventDefault();

      setData({
        ...data,
        text: modifiedText,
      });
      return;
    }
    if (
      cursorPosition >= 2 &&
      charactersBeforeCursor === '{{' &&
      (!variablesDetected || variablesDetected.length === 0)
    ) {
      modifiedText = value.substring(0, cursorPosition) + `1}}` + value.substring(cursorPosition);
    }

    if (variablesDetected?.length > 1) {
      modifiedText = modifiedText.substring(0, cursorPosition - 5);
    }
    setData({
      ...data,
      text: modifiedText.replace(patternFullVariable, '{{1}}'),
    });

    dontResetCursor(cursorPosition);
  };
  return (
    <Form
      className="px-3 py-2 verythinscrollnew"
      style={{ borderBottom: '1px solid grey', width: '100%' }}
    >
      <div className="" style={{ border: '' ,width:'900px'}}>
        <div>
          <Form.Label style={{ ...Styles.TxtHeading2 }}>
            Header
            {Carousel ? (
              <>
                <span
                  style={{
                    color: colors.error03,
                    fontSize: '14px',
                    marginLeft: '10px',
                    fontWeight: '600',
                  }}
                >
                  {'*Mandatory'}<br />{'Please Note: Media type must be same across all cards'}
                </span>
              </>
            ) : (
              <>
                <span
                  style={{
                    color: 'grey',
                    fontSize: '14px',
                    marginLeft: '10px',
                    fontWeight: '600',
                  }}
                >
                  Optional
                </span>
              </>
            )

            }
          </Form.Label>
          <p
            style={{
              fontSize: '14px',
              color: 'grey',
            }}
          >
            Add a title or choose what type of media you will use for this header
          </p>
        </div>
        <InputGroup
          className="mb-2"
          style={{
            marginBottom: '10px',
            position: 'relative',
            paddingBottom: '35px',
            left: '1',
            width: '100%',
          }}
        >
          {/* <Form.Control type='text' placeholder="Enter seller name" className='w-100 btncustom'
                    style={{borderRadius:8}}
                    // value={sellerName}
                    value={""}
                    // onChange={(e)=>{setSellerName(e.target.value)}}
                /> */}
          {/* This is the change done from 50% to 100% */}
          <InputGroup
            className="btncustom"
            style={{ width: '30%', height: '100%', paddingTop: '20px' }}
          >
            <InputGroup.Text
              id="basic-addon1"
              style={{
                fontSize: 12,
                fontWeight: 600,
                color: colors.black,
                cursor: 'default',
              }}
            >
              {'Header'}
            </InputGroup.Text>
            <Form.Select
              className="btncustom"
              aria-label="Default select example"
              style={{
                width: '30%',
                fontSize: 12,
                fontWeight: 600,
                color: colors.greys04,
                cursor: 'pointer',
                position: 'relative',
              }}
              value={data.format}
              onChange={(e) => {
                setData({
                  ...data,
                  format: e.target.value,
                  text: '',
                  example: {
                    header_text: [],
                    header_handle: [],
                  },
                });
              }}
              disabled={index !== 0 ? true : false}
            >
              <option
                style={{
                  width: '30%',
                  marginTop: 4,
                  marginBottom: 4,
                  borderRadius: 8,
                }}
                key="blankchoice"
                hidden
                value
              >
                {'Choose media type'}
              </option>
              {Carousel ? (
                <>
                  {CarouselList.map((e, index) => {
                    return (
                      <option
                        style={{
                          width: '30%',
                          marginTop: 4,
                          marginBottom: 4,
                          borderRadius: 8,
                        }}
                        value={e}
                        key={index}
                      >
                        {e}
                      </option>
                    );
                  })}
                </>
              ) : (
                <>
                  {HeaderList.map((e, index) => {
                    return (
                      <option
                        style={{
                          width: '30%',
                          marginTop: 4,
                          marginBottom: 4,
                          borderRadius: 8,
                        }}
                        value={e}
                        key={index}
                      >
                        {e}
                      </option>
                    );
                  })}
                </>
              )}

            </Form.Select>
          </InputGroup>
          {data.format === 'TEXT' && (
            <div style={{ width: '100%' }}>
              <p
                style={{
                  color: 'grey',
                  textAlign: 'end',
                  fontSize: '12px',
                  marginBottom: '-1px',
                }}
              >
                {data.text.length}/60
              </p>
              <Form.Control
                type="text"
                className="btncustom"
                id="editor"
                contentEditable="true"
                ref={editorRef}
                style={{
                  resize: 'none',
                  borderRadius: '5px',
                  width: '100%',
                }}
                placeholder="Enter Text"
                // disabled={headerText.length > 60}
                value={data.text}
                onChange={handleBodyTextChange}
              />
              <div
                className="d-flex flex-row-reverse"
                style={{
                  textAlign: 'end',
                  width: '20%',
                  marginLeft: '80%',
                }}
              >
                {!data.text.match(/\{\{(\d+)\}\}/g) && (
                  <CheerioButton
                    className="button"
                    borderStyle={{
                      height: '32px',
                      marginBlockStart: 8,
                      marginBlockEnd: 2,
                      width: '100%',
                    }}
                    textStyle={{
                      color: 'black',
                      fontSize: 12,
                      fontWeight: 600,
                    }}
                    btnText={'Add variable'}
                    backColor={'white'}
                    onclick={() => {
                      const patternFullVariable = /\{\{+\d+\}\}+/g;
                      const variablesDetected = data.text.match(patternFullVariable);

                      if (!variablesDetected || variablesDetected.length < 1) {
                        const patternFullVariable = /\{\{+\d+\}\}+/g;
                        const variablesDetected = data.text.match(patternFullVariable);
                        setData({
                          ...data,
                          text: `${data.text}{{${!variablesDetected ? 1 : variablesDetected.length + 1
                            }}}`,
                        });
                      }
                    }}
                  // sampleVariable = {sampleVariable}
                  />
                )}
              </div>
              <div style={{ width: '100%' }}>
                {data.text.match(/\{\{(\d+)\}\}/g)?.map((tag, index) => (
                  <div
                    className="w-100"
                    key={index}
                    style={{ display: 'flex', width: '500px', marginTop: '20px' }}
                  >
                    <Chip label={tag} style={{ margin: '5px' }} />
                    {/* {inputBoxes[index]} */}
                    <div key={index} className="w-100">
                      <Form.Control
                        type="text"
                        placeholder="Enter content for sample variable"
                        className="w-100 btncustom"
                        style={{ borderRadius: 8 }}
                        value={data.example.header_text[index] ?? ''}
                        onChange={(e) => {
                          const { value } = e.target;
                          const updatedHeaderText = [...data.example.header_text];
                          updatedHeaderText[index] = value;
                          setData({
                            ...data,
                            example: {
                              ...data.example,
                              header_text: updatedHeaderText,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
              {/* <Form.Control
            type='text'
            ref={headerVariableRef}
            value={headerText}
            // onChange={handleHeaderTextChange}
              onChange={(e) => {
                const value = e.target.value;
                if (value.length <= 60) {
                  setHeaderText(value);
                }
              }}
            disabled={headerText.length > 60}
            placeholder="Enter text"
            className='w-100 btncustom'
            style={{ borderRadius: 8, position: "relative", top: "20px",width:"100%" }}
            /> */}
              {/* <AddVariable
                headerText = {headerText}
                changeBodyText = {changeBodyText}
                headerText = {headerText}
                changeHeaderText = {changeHeaderText}
                editorRef = {headerVariableRef}
            /> */}

              {/* <button style={{ borderRadius: 8, position: "relative", top: "30px", left: "380px" }} onClick={() => { setHeaderVariable(true); setHeaderVariableCnt(headerCnt + 1) }}>Add Variable</button> */}
            </div>
          )}
          {data.format === 'IMAGE' || data.format === 'VIDEO' || data.format === 'DOCUMENT' ? (
            <>
              <UploadImageComponent
                media={data.example.header_handle[0] ?? {}}
                setMedia={(media) => {
                  setData({
                    ...data,
                    example: {
                      ...data.example,
                      header_handle: [
                        {
                          file_name: media.filename,
                          base64: media.base64,
                        },
                      ],
                    },
                  });
                }}
                type={data.format}
              />

              {/* <input  //hide
              type='file'
              style={{display:'none'}}
              ref={upimgref} accept='video/*'
              // onChange={(e)=>{
              //   handleimgupload(e);
              // }}
              onChange={handleFileChange}
            />  */}
              {/* <input //hide
                  type="file"
                  style={{ display: "none" }}
                  ref={
                    header === "Image"
                      ? upimgref
                      : header === "Video"
                      ? upvideoref
                      : null
                  }
                  accept={
                    header === "Image"
                      ? ".jpeg, .png, .jpg"
                      : header === "Video"
                      ? "video/*"
                      : null
                  }
                  // onChange={(e)=>{
                  //   handleimgupload(e);
                  // }}
                  // onChange={handleFileChange}
                  onChange={(e) => {
                    header === "Image"
                      ? handleimgupload(e)
                      : handleFileChange(e);
                  }}
                /> */}
            </>
          ) : null}
        </InputGroup>

      </div>

      {/* {header!=="None" ? (
                  <InputGroup className="mb-2">
                  <Form.Label style={{...Styles.TxtHeading2}}>Upload {header}</Form.Label>
                  
                  <div className='d-flex flex-row justify-content-between align-items-center px-3' 
                  style={{width:'100%',borderColor:'#CCCCCC',borderWidth:1,borderStyle:'solid',borderRadius:8,cursor:'pointer'}}
                  onClick={()=>{upimgref.current.click()}}>
                      <div className='d-flex flex-row justify-content-start align-items-center pe-2' style={{width:'60%'}}>
                          <img src={upimg64 ? getImagePath(upimg64) : images.ImgUpIcon} alt="upload"style={{height:50,width:68,objectFit:'contain',marginInlineEnd:20}}></img>
                          <span style={{fontSize:14,fontWeight:600,color:colors.black,textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden',wordBreak:'break-all'}}>
                              {upimgname ? upimgname : 'Upload Image'}
                          </span>
                      </div>
                      <div className='d-flex flex-column justify-content-center align-items-center' style={{paddingBlockStart:60,paddingBlockEnd:20}}>
                          <div className='d-flex flex-row justify-content-center align-items-center' style={{height:32,backgroundColor:colors.black,borderRadius:8,width:100,cursor:'pointer'}}>
                          <text style={{fontSize:14,fontWeight:600,color:colors.white01,cursor:'pointer'}}>
                              {'Browse files'}
                          </text>
                          </div>
                          <p style={{padding:0,margin:0,fontSize:12,fontWeight:600,color:colors.black,textAlign:'center',marginBlockStart:16}}>
                          {'Max file size (5MB)'}<br />
                          {'(350px x 350px)  to  (1500px x 1500px)'}
                          </p>
                      </div>
                      
                  </div>
                  <p style={{fontSize:"14px",color:"grey"}}>To help us review your content, provide examples of the variables or media in the header. Do not include any customer information. Our API’s reviews templates and variable parameters to protect the security and integrity of our services.
</p>
              </InputGroup>
          ): null
          } */}
      {/* <input 
              type='file' 
              style={{display:'none'}} 
              ref={upimgref} accept='.jpeg, .png, .jpg'
              onChange={(e)=>{
                  // handleimgupload(e);
              }}
          />
          <input 
              type='file' 
              style={{display:'none'}} 
              ref={logoimgref} accept='.jpeg, .png, .jpg' 
              onChange={(e)=>{
                  // handlelogoimgupload(e);
              }}
          /> */}
    </Form>
  );
};
