import { useEffect, useState } from 'react';
import { Position } from 'reactflow';
import Form from 'react-bootstrap/Form';
import CustomHandle from './CustomHandle';
import images from '../../../Utils/images';
import colors from '../../../Utils/colors';
import InputGroup from 'react-bootstrap/InputGroup';

const handleStyle = { backgroundColor: '#7B50C6', height: 15, width: 15, borderRadius: "100%" };

const WooCommerceNode = ({ data, isConnectable }) => {

    return (
        <>
            <div
                className="d-flex flex-column justify-content-between align-items-center"
                style={{
                    border: `1px solid ${colors.borderwhite}`,
                    borderRadius: 16,
                    backgroundColor: colors.white01,
                    width: 250,
                }}
            >
                <div
                    className="w-100 d-flex flex-column justify-content-start align-items-center"
                    style={{
                        paddingInline: 16,
                        paddingBlock: 8,
                        backgroundColor: '#7B50C6',
                        borderTopLeftRadius: 16,
                        borderTopRightRadius: 16,
                    }}
                >
                    <div
                        style={{
                            height: 4,
                            width: '20%',
                            borderRadius: 2,
                            backgroundColor: colors.white,
                            marginBlockEnd: 4,
                        }}
                    ></div>
                    <div
                        className="w-100 d-flex flex-row justify-content-between align-items-center"
                        style={{}}
                    >
                        <p
                            style={{ padding: 0, margin: 0, fontSize: 16, fontWeight: 700, color: colors.white }}
                        >
                            WooCommerce Event
                        </p>
                        <img src={images.Edit} style={{ height: 20, width: 20, objectFit: 'contain' }}></img>
                    </div>
                </div>

                <div
                    className="w-100 d-flex flex-column justify-content-start align-items-center"
                    style={{ paddingInline: 16, paddingBlockStart: 8, paddingBlockEnd: 16 }}
                >
                    <InputGroup className="mb-3" style={{ width: '100%', marginBlock: 0 }}>
                        <Form.Label style={{ fontSize: 12, fontWeight: 600, color: colors.black }}>
                            Choose an Event
                        </Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Select Event"
                            className="w-100 btncustom dropdown"
                            style={{ borderRadius: 8, fontSize: 16, fontWeight: 400, color: colors.greys01 }}
                            value={data?.wooEvent ? data.wooEvent : ''}
                        />
                    </InputGroup>

                </div>
                <CustomHandle
                    type="source"
                    position={Position.Right}
                    id="Event_UserInit"
                    isConnectable={1}
                    style={handleStyle}
                />
            </div >
        </>
    );
}

export default WooCommerceNode