import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import colors from '../../../Utils/colors';
import { BiLike, BiSolidLike } from 'react-icons/bi';
import '../styles.css';
import { deleteComment, postComment, toggleCommentUpvote } from '../../../Services';
import { toast } from 'react-toastify';
import CheerioButton from '../../../Components/CheerioButton';
import { FaRegTrashAlt } from 'react-icons/fa';
import { eventsend } from '../../../Config/analyticsFunctions';
import images from '../../../Utils/images';

export const Comments = ({ data, comments, setComments }) => {
  const localdata = useSelector((state) => state.main.localdata);
  const authtoken = useSelector((state) => state.main.auth_token);
  const agentdata = useSelector((state) => state.main.agentdata);
  const [commentText, setCommentText] = useState('');
  const commentBoxRef = useRef();
  const commenter = agentdata ?? localdata;

  const postCommentApiCall = () => {
    const postData = {
      name: commenter?.name,
      phoneNumber: data?.user_details?.phone,
      text: commentText,
    };

    postComment(authtoken, postData).then((res) => {
      if (res?.flag) {
        setComments([...res?.data?.comments]);
        setCommentText('');
      } else {
        toast.error(res?.message);
      }
    });
  };

  const deleteCommentApiCall = (commentId) => {
    const deleteCommentData = {
      phoneNumber: data?.user_details?.phone,
      commentId: commentId,
    };
    deleteComment(authtoken, deleteCommentData).then((res) => {
      if (res?.flag) {
        setComments([...res?.data?.comments]);
      } else {
        toast.error(res?.message);
      }
    });
  };

  const toggleUpvoteCommentApiCall = (commentId, liked) => {
    const apiData = {
      agentId: commenter?._id,
      commentId: commentId,
      phoneNumber: data?.user_details?.phone,
      value: true,
    };
    toggleCommentUpvote(authtoken, apiData).then((res) => {
      if (res?.flag) {
        if (!liked) {
          setComments((prev) => {
            const elemIndx = prev.findIndex((comment) => comment?.id === commentId);
            const newArr = [...prev];
            newArr[elemIndx] = {
              ...prev[elemIndx],
              upvote: [...prev[elemIndx]?.upvote, commenter?._id],
            };
            return [...newArr];
          });
        } else {
          setComments((prev) => {
            const elemIndx = prev.findIndex((comment) => comment?.id === commentId);
            const newArr = [...prev];
            const newUpVote = prev[elemIndx]?.upvote.filter((item) => item !== commenter?._id);
            console.log('newup', newUpVote);
            newArr[elemIndx] = {
              ...prev[elemIndx],
              upvote: newUpVote,
            };
            return [...newArr];
          });
        }
      } else {
        toast.error(res?.message);
      }
    });
  };

  return (
    <div
      className="d-flex flex-column justify-content-between align-items-center"
      style={{
        // marginTop: '8%',
        marginBlockStart: 8,
        width: '100%',
        height: '40%',
      }}
    >
      <p style={{ fontSize: '14px', fontWeight: 600, width: '100%' }}>Comments</p>
      <div
        className="d-flex flex-column justify-content-start align-items-center w-100 verythinscrollnew"
        style={{ flex: 1, overflowY: 'auto' }}
      >
        {/* <p style={{ fontSize: '14px', fontWeight: 600, width:'100%' }}>Comments</p> */}
        {comments && comments.length > 0 ? (
          <div
            style={{
              marginTop: '10px',
              width: '100%',
              // overflowY: 'auto',
              // scrollbarWidth: 'thin',
              // height:'auto',
            }}
          >
            {comments.map((comment) => {
              const liked = comment?.upvote.some((id) => id === commenter?._id);
              return (
                <div
                  className="d-flex justify-content-between w-100"
                  style={{
                    border: '1px solid lightgrey',
                    marginBottom: '10px',
                    borderRadius: '8px',
                    padding: '12px',
                  }}
                >
                  <div className="d-flex">
                    <div
                      style={{
                        fontWeight: 'normal',
                        fontSize: 12,
                        color: colors.white,
                        backgroundColor: '#5FC5FF',
                        height: 24,
                        borderRadius: 55,
                        textAlign: 'center',
                        padding: '12px',
                        width: 24,
                        fontWeight: '600',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      {comment?.name
                        .split(' ')
                        .map((n) => n[0])
                        .join('')}
                    </div>
                    <div
                      style={{
                        marginLeft: '6px',
                        gap: '4px',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <p style={{ color: 'black', fontSize: '14px', fontWeight: '600' }}>
                          {comment?.name}
                        </p>
                        <p
                          style={{
                            color: 'grey',
                            fontSize: '12px',
                            fontWeight: '400',
                          }}
                        >
                          {new Date(comment?.date).toLocaleString('en-US', {
                            timeZone: 'Asia/Kolkata',
                            day: '2-digit',
                            month: 'short',
                          })}
                        </p>
                      </div>
                      <p
                        style={{
                          color: 'black',
                          fontSize: '14px',
                          fontWeight: '400',
                        }}
                      >
                        {comment?.text}
                      </p>
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      justifyContent: 'center',
                      gap: '8px',
                    }}
                  >
                    {liked ? (
                      <img
                        id="liked-thumbs-up"
                        src={images.ThumbsupSelIcon}
                        style={{
                          height: '20px',
                          width: '20px',
                          cursor: 'pointer',
                          opacity: 1,
                        }}
                        onMouseDown={(e) => {
                          e.preventDefault();
                          toggleUpvoteCommentApiCall(comment?.id, liked);
                        }}
                      />
                    ) : (
                      <img
                        id="unliked-thumbs-up"
                        src={images.ThumbsupIcon}
                        style={{
                          height: '20px',
                          width: '20px',
                          cursor: 'pointer',
                          opacity: 0.65,
                        }}
                        onMouseDown={(e) => {
                          e.preventDefault();
                          toggleUpvoteCommentApiCall(comment?.id, liked);
                        }}
                      />
                    )}
                    <img
                      src={images.CDelete}
                      id='delete-comment-btn'
                      style={{
                        height: '20px',
                        width: '20px',
                        opacity: 0.65,
                        cursor: 'pointer',
                      }}
                      onMouseDown={(e) => {
                        e.preventDefault();
                        deleteCommentApiCall(comment?.id);
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div style={{ fontSize: '14px', fontWeight: '400', color: 'grey', marginTop: '20px' }}>
            No comments added
          </div>
        )}
      </div>
      <div
        // className="d-flex"
        style={{
          display: 'none',
          marginBlock: 12,
          // bottom: '0px',
          width: '100%',
        }}
      >
        {' '}
        <p
          style={{
            fontWeight: 'normal',
            fontSize: 12,
            color: colors.white,
            backgroundColor: '#5FC5FF',
            height: 38,
            marginRight: '4px',
            borderRadius: 55,
            textAlign: 'center',
            paddingTop: 9,
            paddingLeft: 10,
            paddingRight: 10,
            width: '38px',
            fontWeight: '700',
          }}
        >
          {commenter?.name
            .split(' ')
            .map((n) => n[0])
            .join('')}
        </p>
        <div className="comment-div">
          <textarea
            ref={commentBoxRef}
            class="comment-field"
            placeholder="Add a comment"
            value={commentText}
            onClick={(e) => { eventsend("Add a comment_Side_bar_Inbox_page_clicked") }}
            onChange={(e) => {
              setCommentText(e.target.value);
            }}
            onSelect={() => {
              commentBoxRef.current.scrollIntoView({ behaviour: 'smooth' });
            }}
          ></textarea>
          <CheerioButton
            borderStyle={{
              borderColor: colors.primary03,
              paddingInline: 10,
              paddingBlock: 4,
              alignSelf: 'flex-end',
            }}
            textStyle={{ color: colors.white }}
            btnText={'Comment'}
            backColor={colors.primary03}
            onclick={() => {
              if (commentText) {
                postCommentApiCall();
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};
