import React from 'react';
import colors from '../../../Utils/colors';
import images from '../../../Utils/images';
import CustomHandle from './CustomHandle';
import { Position } from 'reactflow';

const handleStyle = {
    backgroundColor: '#6430D3',
    height: 15, width: 15, borderRadius: "100%",
    top: '60%',
};

const AddToLabel = ({ data, isConnectable }) => {
    return (
        <>
            <div
                className="d-flex flex-column justify-content-between align-items-center"
                style={{
                    border: `1px solid ${colors.borderwhite}`,
                    borderRadius: 16,
                    backgroundColor: colors.white01,
                    width: 250,
                }}
            >
                <CustomHandle
                    type="target"
                    position={Position.Left}
                    isConnectable={isConnectable}
                    style={handleStyle}
                />
                <div
                    className="d-flex flex-row justify-content-between align-items-center w-100"
                    style={{
                        paddingInline: 16,
                        paddingBlock: 8,
                        backgroundColor: '#6430D3',
                        borderTopLeftRadius: 16,
                        borderTopRightRadius: 16,
                    }}
                >
                    <p style={{ fontWeight: 700, color: colors.white }}>{'Add to Label'}</p>
                    <img
                        src={images.Edit}
                        style={{ height: 20, width: 20, objectFit: 'contain', cursor: 'pointer' }}
                    ></img>
                </div>
                <div
                    className="w-100 d-flex flex-column justify-content-center align-items-center"
                    style={{ paddingInline: 16, paddingBlock: 16 }}
                >
                    <p
                        style={{
                            fontSize: 14,
                            fontWeight: 400,
                            color: colors.black,
                            width: '100%',
                            textAlign: 'center',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            wordBreak: 'break-all',
                        }}
                    >
                        {data?.labelName ? data?.labelName : 'Select a Label'}
                    </p>
                </div>
                <CustomHandle
                    type="source"
                    position={Position.Right}
                    style={handleStyle}
                    isConnectable={1}
                />
            </div>
        </>
    );
};

export default AddToLabel;
