import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import CheerioButton from '../../../Components/CheerioButton';
import images from '../../../Utils/images';
import '../styles.css';
import countryList from 'react-select-country-list';
import { useState, useMemo } from 'react';
import myData from '../../ManageSender/india_states_codes.json';
import { useSelector } from 'react-redux';
import {
  AISensyExploreTemplatesApi,
  createSenderId,
  resendSenderIdVerification,
} from '../../../Services';
// import { Toast } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ValidateEmail } from '../../Chatbot/Setupbot';
import { NewLoader } from '../../../Components/SpinnerLoader';
import { eventsend } from '../../../Config/analyticsFunctions';

export const SenderIdModal = ({ openSenderIdModal, setOpenSenderIdModal, setSenderDataSelected, senderDataSelected }) => {
  const auth_token = useSelector((state) => state.main.auth_token);
  const userId = useSelector((state) => state.main._id);
  const mobile = useSelector((state) => state.main.mobile);

  const navigate = useNavigate();
  const [check, setCheck] = useState(true);
  const [stateValue, setStateValue] = useState(null);
  const [senderName, setSenderName] = useState('');
  const [senderEmail, setSenderEmail] = useState('');
  const [replyEmail, setReplyEmail] = useState('');
  const [replyName, setReplyName] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [country, setCountry] = useState('');
  const [city, setCity] = useState('');
  const [pincode, setPinCode] = useState('');
  const [nickName, setNickName] = useState('');
  const [missingError, setMissingError] = useState(false);
  const [displayError, setDisplayError] = useState(false);
  const [senderId, setSenderId] = useState();
  const [loading, setLoading] = useState(true);
  const [apiLoading, setApiLoading] = useState(false);
  const localdata = useSelector((state) => state.main.localdata);

  const options = useMemo(() => countryList().getData(), []);

  const createSenderIdApiCall = () => {
    setApiLoading(true);
    let data = {
      nick_name: nickName,
      name: senderName,
      email: senderEmail,
      replier_email: replyEmail,
      replier_name: replyName,
      address: address1,
      address_2: address2,
      city: city,
      state: stateValue,
      zip_code: pincode,
      country: country,
    };
    let token = auth_token;
    createSenderId(data, token).then((res) => {
      if (res?.flag) {
        console.log('create senderID resp', res);
        setCheck(false);

        setLoading(false);
        setApiLoading(false);

        if (localdata?.isSesUser) {
          setSenderId(res?.data?._id);
          // setOpenSenderIdModal(false);
        } else {
          setSenderId(res?.data?.id);
        }
        toast(res?.message, { position: 'top-center' });
        setSenderDataSelected(res?.data);

        navigate('/creator/manageSender');
      } else {
        toast(res?.message, { position: 'top-center' });
        if (localdata?.isSesUser) {
          setOpenSenderIdModal(false);
        }
        setApiLoading(false);
        setCheck(true);
      }
    });
  };

  const resendVerificationApiCall = () => {
    let data = {};
    let token = auth_token;
    resendSenderIdVerification(senderId, data, token).then((res) => {
      if (res?.flag) {
        toast(res?.message, { position: 'top-center' });
        navigate('/creator/manageSender');
      } else {
        toast(res?.message, { position: 'top-center' });
      }
    });
  };

  const changeHandleState = (e) => {
    //console.log("state",e.target.value);
    setStateValue(e.target.value);
  };

  const changeHandleCountry = (e) => {
    //console.log("v",e.target.value);
    setCountry(e.target.value);
  };
  useEffect(() => {
    if (
      senderName === '' ||
      senderEmail === '' ||
      replyEmail === '' ||
      address1 === '' ||
      city === '' ||
      country === '' ||
      nickName === ''
    ) {
      setMissingError(true);
    } else {
      setMissingError(false);
    }
  }, [senderName, senderEmail, replyEmail, address1, city, country, nickName]);
  const HeadingText = (localdata?.isAppsumoUser || localdata?.isSesUser) ? "Verify Sender Id" : "Create sender identity";
  return (
    <div>
      <Modal
        show={openSenderIdModal}
        aria-labelledby="example-custom-modal-styling-title"
        dialogClassName="my-modal"
        contentClassName="sender-id-modal"
      >
        {check ? (
          <Modal.Body
            style={{ padding: '30px', width: '100%', overflowY: 'auto', scrollbarWidth: 'thin' }}
          >
            <div className="d-flex justify-content-between">
              <p style={{ fontSize: '20px', fontWeight: '700' }}>Create sender identity</p>
              <div className="d-flex flex-row">
                <img
                  src={images.CMClose}
                  onClick={() => {
                    setOpenSenderIdModal(false);
                  }}
                  style={{
                    cursor: 'pointer',
                    height: 22,
                    width: 22,
                    marginInlineEnd: 8,
                  }}
                ></img>
              </div>
            </div>
            <div style={{ fontSize: '16px', fontWeight: '400' }}>
              You are required to setup a sender ID for every promotional email you send in order to
              comply with the anti- spam laws such as CAN-SPAM and CASL.
            </div>

            <div>
              <div className="d-flex justify-content-between">
                <div class="form-group mt-4" style={{ width: '48%' }}>
                  <label
                    for="exampleFormControlInput1"
                    style={{ fontWeight: '600', fontSize: '16px', color: 'black' }}
                  >
                    Sender Name*
                  </label>
                  <input
                    class="form-control shadow-none"
                    placeholder="Enter Here"
                    value={senderName}
                    onChange={(e) => setSenderName(e.target.value)}
                    style={{
                      width: '',
                      border: '1px solid grey',
                      marginTop: '5px',
                      borderRadius: '8px',
                    }}
                  />
                </div>
                <div class="form-group mt-4" style={{ width: '48%' }}>
                  <label
                    for="exampleFormControlInput1"
                    style={{ fontWeight: '600', fontSize: '16px', color: 'black' }}
                  >
                    Sender Email*
                  </label>
                  <input
                    class="form-control shadow-none"
                    placeholder="Enter Here"
                    value={senderEmail}
                    onChange={(e) => setSenderEmail(e.target.value)}
                    style={{
                      width: '',
                      border: '1px solid grey',
                      marginTop: '5px',
                      borderRadius: '8px',
                    }}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div class="form-group mt-4" style={{ width: '48%' }}>
                  <label
                    for="exampleFormControlInput1"
                    style={{ fontWeight: '600', fontSize: '16px', color: 'black' }}
                  >
                    Reply to Email*
                  </label>
                  <input
                    class="form-control shadow-none"
                    placeholder="Enter Here"
                    value={replyEmail}
                    onChange={(e) => setReplyEmail(e.target.value)}
                    style={{
                      width: '',
                      border: '1px solid grey',
                      marginTop: '5px',
                      borderRadius: '8px',
                    }}
                  />
                </div>
                <div class="form-group mt-4" style={{ width: '48%' }}>
                  <label
                    for="exampleFormControlInput1"
                    style={{ fontWeight: '600', fontSize: '16px', color: 'black' }}
                  >
                    Reply to Name
                  </label>
                  <input
                    class="form-control shadow-none"
                    placeholder="Enter Here"
                    value={replyName}
                    onChange={(e) => setReplyName(e.target.value)}
                    style={{
                      width: '',
                      border: '1px solid grey',
                      marginTop: '5px',
                      borderRadius: '8px',
                    }}
                  />
                </div>
              </div>

              <div className="d-flex justify-content-between">
                <div class="form-group mt-4" style={{ width: '48%' }}>
                  <label
                    for="exampleFormControlInput1"
                    style={{ fontWeight: '600', fontSize: '16px', color: 'black' }}
                  >
                    Address 1*
                  </label>
                  <input
                    class="form-control shadow-none"
                    placeholder="Enter Here"
                    value={address1}
                    onChange={(e) => setAddress1(e.target.value)}
                    style={{
                      width: '',
                      border: '1px solid grey',
                      marginTop: '5px',
                      borderRadius: '8px',
                    }}
                  />
                </div>
                <div class="form-group mt-4" style={{ width: '48%' }}>
                  <label
                    for="exampleFormControlInput1"
                    style={{ fontWeight: '600', fontSize: '16px', color: 'black' }}
                  >
                    Address 2
                  </label>
                  <input
                    class="form-control shadow-none"
                    placeholder="Enter Here"
                    value={address2}
                    onChange={(e) => setAddress2(e.target.value)}
                    style={{
                      width: '',
                      border: '1px solid grey',
                      marginTop: '5px',
                      borderRadius: '8px',
                    }}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div class="form-group mt-4" style={{ width: '48%' }}>
                  <label
                    for="exampleFormControlInput1"
                    style={{ fontWeight: '600', fontSize: '16px', color: 'black' }}
                  >
                    Country*
                  </label>
                  {/* <Select className="react-select shadow-none" options={options} value={value} onChange={changeHandler} /> */}
                  <select
                    class="form-select shadow-none"
                    value={country}
                    onChange={(e) => changeHandleCountry(e)}
                    style={{
                      overflow: 'auto',
                      border: '1px solid grey',
                      marginTop: '5px',
                      borderRadius: '8px',
                    }}
                    aria-label="Default select example"
                  >
                    <option selected disabled>
                      Select country
                    </option>
                    {options &&
                      options.map((option, index) => {
                        return (
                          <option key={index} value={option.value}>
                            {option.label}
                          </option>
                        );
                      })}
                  </select>
                </div>
                <div class="form-group mt-4" style={{ width: '48%' }}>
                  <label
                    for="exampleFormControlInput1"
                    style={{ fontWeight: '600', fontSize: '16px', color: 'black' }}
                  >
                    State
                  </label>
                  {country === 'IN' ? (
                    <select
                      class="form-select shadow-none"
                      value={stateValue}
                      onChange={(e) => changeHandleState(e)}
                      style={{
                        overflow: 'auto',
                        border: '1px solid grey',
                        marginTop: '5px',
                        borderRadius: '8px',
                      }}
                      aria-label="Default select example"
                    >
                      <option selected disabled>
                        Select state
                      </option>
                      {myData &&
                        Object.entries(myData).map(([key, val]) => {
                          // Pretty straightforward - use key for the key and value for the value.
                          // Just to clarify: unlike object destructuring, the parameter names don't matter here.
                          return <option value={key}>{val}</option>;
                        })}
                    </select>
                  ) : (
                    <input
                      onChange={(e) => setStateValue(e.target.value)}
                      class="form-control shadow-none"
                      placeholder="Enter Here"
                      style={{
                        width: '',
                        border: '1px solid grey',
                        marginTop: '5px',
                        borderRadius: '8px',
                      }}
                    />
                  )}
                </div>
              </div>

              <div className="d-flex justify-content-between">
                <div class="form-group mt-4" style={{ width: '48%' }}>
                  <label
                    for="exampleFormControlInput1"
                    style={{ fontWeight: '600', fontSize: '16px', color: 'black' }}
                  >
                    City*
                  </label>
                  <input
                    class="form-control shadow-none"
                    placeholder="Enter Here"
                    onChange={(e) => setCity(e.target.value)}
                    style={{
                      width: '',
                      border: '1px solid grey',
                      marginTop: '5px',
                      borderRadius: '8px',
                    }}
                  />
                </div>
                <div class="form-group mt-4" style={{ width: '48%' }}>
                  <label
                    for="exampleFormControlInput1"
                    style={{ fontWeight: '600', fontSize: '16px', color: 'black' }}
                  >
                    Pincode
                  </label>
                  <input
                    class="form-control shadow-none"
                    placeholder="Enter Here"
                    onChange={(e) => setPinCode(e.target.value)}
                    style={{
                      width: '',
                      border: '1px solid grey',
                      marginTop: '5px',
                      borderRadius: '8px',
                    }}
                  />
                </div>
              </div>
              <div class="form-group mt-4">
                <label
                  for="exampleFormControlInput1"
                  style={{ fontWeight: '600', fontSize: '16px', color: 'black' }}
                >
                  Sender’s Nickname*
                </label>
                <input
                  class="form-control shadow-none"
                  placeholder="Enter Here"
                  value={nickName}
                  onChange={(e) => setNickName(e.target.value)}
                  style={{
                    width: '',
                    border: '1px solid grey',
                    marginTop: '5px',
                    borderRadius: '8px',
                  }}
                />
              </div>
              {missingError && displayError && (
                <div style={{ marginTop: '10px', marginBottom: '-30px', color: 'red' }}>
                  Some Fields are missing *
                </div>
              )}

              <CheerioButton
                className="button"
                borderStyle={{
                  height: '',
                  width: '',
                  marginTop: '30px',
                  padding: '2%',
                }}
                textStyle={{ color: 'white', fontSize: 16, fontWeight: 600 }}
                btnText={'Create Sender ID'}
                backColor={'black'}
                disabled={apiLoading}
                onclick={() => {
                  if (missingError) {
                    setDisplayError(true);
                  } else {
                    setDisplayError(false);
                    if (ValidateEmail(senderEmail) && ValidateEmail(replyEmail)) {
                      const sEmail = senderEmail.split('@');
                      if (
                        (sEmail[1] === 'cheerio.in' &&
                          (localdata?.mobile === '9779003936' || mobile === '9779003936')) ||
                        mobile === '8496910755' ||
                        localdata?.mobile === '8496910755' ||
                        mobile === '8179991357' ||
                        localdata?.mobile === '8496910755'
                      ) {
                        createSenderIdApiCall();
                        if (!localdata?.isSesUser) {
                          setCheck(false);
                        }
                      } else if (sEmail[1] !== 'cheerio.in') {
                        eventsend("Submit_sender_ID_popup_clicked");
                        createSenderIdApiCall();

                        if (!localdata?.isSesUser) {
                          setCheck(false);
                        }
                      } else {
                        toast.error('You cannot use cheerio.in in your email.', {
                          position: 'top-center',
                        });
                      }
                    }
                  }
                }}
              />
            </div>
          </Modal.Body>
        ) : (
          <Modal.Body
            style={{ padding: '30px', width: '100%', overflowY: 'auto', scrollbarWidth: 'thin' }}
          >
            {loading ? (
              <div
                style={{ paddingTop: '12%', height: '20rem' }}
                className="d-flex justify-content-center"
              >
                {' '}
                <NewLoader></NewLoader>
              </div>
            ) : (
              <>
                <div className="d-flex justify-content-between">
                  <p style={{ fontSize: '20px', fontWeight: '700' }}>{HeadingText}</p>
                  <div className="d-flex flex-row">
                    <img
                      src={images.CMClose}
                      onClick={() => {
                        if (senderDataSelected) navigate('/creator/manageSender/setting');
                        else navigate('/creator/manageSender');

                        setOpenSenderIdModal(false);
                      }}
                      style={{
                        cursor: 'pointer',
                        height: 22,
                        width: 22,
                        marginInlineEnd: 8,
                      }}
                    ></img>
                  </div>
                </div>
                <div style={{ fontSize: '16px', fontWeight: '400' }}>
                  You are required to setup a sender ID for every promotional email you send in
                  order to comply with the anti- spam laws such as CAN-SPAM and CASL.
                </div>

                <div className="d-flex align-items-center flex-column" style={{}}>
                  <img src={images.SenderImage} style={{ width: '50%' }}></img>
                  <p style={{ fontWeight: '700', fontSize: '18px' }}>Sender has been created</p>
                  <p style={{ width: '50%', textAlign: 'center' }}>
                    {' '}
                    To verify sender identity, check your inbox at <a>{senderEmail}</a>
                  </p>
                </div>
                {(!localdata?.isAppsumoUser || !localdata?.isSesUser) && (
                <CheerioButton
                  className="button"
                  borderStyle={{
                    height: '',
                    width: '',
                    marginTop: '30px',
                    padding: '2%',
                  }}
                  textStyle={{ color: 'white', fontSize: 16, fontWeight: 600 }}
                  btnText={'Resend Email'}
                  backColor={'black'}
                  onclick={() => {
                    resendVerificationApiCall();
                    setOpenSenderIdModal(false);
                  }}
                />
                )}
              </>
            )}
          </Modal.Body>
        )}{' '}
      </Modal>{' '}
    </div>
  );
};
