import React, { useCallback, useEffect, useState } from 'react';
import colors from '../../../Utils/colors';
import { Handle, Position } from 'reactflow';
import images from '../../../Utils/images';
import CustomHandle from './CustomHandle';

const handleStyle = { backgroundColor: "#FAC300", height: 15, width: 15, borderRadius: "100%" };

const FeedbackCollectionNode = ({ data, isConnectable }) => {
  console.log('FeedbackCollectionNode', data);

  return (
    <div
      className="d-flex flex-column justify-content-between align-items-center"
      style={{
        border: `1px solid ${colors.borderwhite}`,
        borderRadius: 16,
        backgroundColor: colors.white01,
        width: 250,
      }}
    >
      <CustomHandle
        type="target"
        position={Position.Left}
        isConnectable={isConnectable}
        style={handleStyle}
      />
      <div
        className="d-flex flex-row justify-content-between align-items-center w-100"
        style={{
          paddingInline: 16,
          paddingBlock: 8,
          backgroundColor: '#FAC300',
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
        }}
      >
        <p style={{ fontWeight: 700, color: colors.white }}>{'Feedback Collection'}</p>
        <img
          src={images.Edit}
          style={{ height: 20, width: 20, objectFit: 'contain', cursor: 'pointer' }}
        ></img>
      </div>
      <div
        className="w-100 d-flex flex-column justify-content-center align-items-center"
        style={{ paddingInline: 16, paddingBlock: 16 }}
      >
        <p
          style={{
            fontSize: 14,
            fontWeight: 400,
            color: colors.black,
            width: '100%',
            textAlign: 'start',
            // textOverflow: 'ellipsis',
            // whiteSpace: 'nowrap',
            // overflow: 'hidden',
            // wordBreak: 'break-all',
          }}
        >
          {data?.body?.message.length > 0 ? data?.body?.message : "Add a question"}
        </p>
      </div>
      {/* <CustomHandle
        type="source"
        position={Position.Right}
        style={handleStyle}
        isConnectable={1}
      /> */}
    </div>
  );
};

export default FeedbackCollectionNode;
