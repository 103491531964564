import React, { useEffect, useState } from 'react'
import colors from '../../../Utils/colors'
import images from '../../../Utils/images'
import { Checkbox } from '@mui/material'
import CheerioButton from '../../../Components/CheerioButton'
import { eventsend } from '../../../Config/analyticsFunctions'
import { useSelector } from 'react-redux'
import { getlabelbalanceAPI } from '../../../Services'

const ImportSettingsModal = ({ setIsImportSettingsModalOpen, verifyEmail, setVerifyEmail, selectedLabel, setActiveStep, EV=false, verifyaction }) => {

    const authToken = useSelector((state) => state.main.auth_token);
    const localdata = useSelector((state) => state.main.localdata);
    const [balance, setBalance] = useState()
    const [isLoading, setIsLoading] = useState(true);

    useEffect(()=>{
        if(selectedLabel?._id && selectedLabel?.label_name){
            getlabelbalanceapicall();
        }
    },[selectedLabel?._id])

    const getlabelbalanceapicall = () => {
        let data = {
            user_id: localdata?._id,
            label: selectedLabel?.label_name,
        };
        let token = authToken;
        getlabelbalanceAPI(data, token).then((res) => {
            if (res.flag) {
                console.log('balance res success ===> ', res.data.templateList);
                setBalance(res.data.templateList);
            } else {
                console.log('balance res error ===> ', res);
            }
            setIsLoading(false)
        });
    };

  return (
    <>
    <div className='DarkBg' onClick={()=>{}}>
        <div className='Center' style={{transform:'translate(calc(-50% + 40px),-50%)'}}>
            <div className='Column' style={{
                justifyContent:'flex-start',
                width: 600,
                backgroundColor: colors.white01,
                borderRadius:16,
                zIndex:0,
                border:`1px solid ${colors.borderwhite}`,
                paddingInline:40,
                paddingBlock:24,
            }}>
                <div className='Row justify-content-between w-100' style={{
                    paddingBlockEnd:20,
                    borderBottom:`1px solid ${colors.borderwhite}`
                }}>
                    <h4 style={{fontSize:16,fontWeight:600}}>
                        {'Email Verification'}
                    </h4>
                    <img 
                        src={images.CMClose} 
                        style={{height:20,width:20,objectFit:'contain',cursor:'pointer'}} 
                        onClick={()=>{
                            setIsImportSettingsModalOpen(false)
                            eventsend("Import-settings-modal-closed");
                        }}
                    >
                    </img>
                </div>
                <div className='Row justify-content-between align-items-start w-100' style={{
                    backgroundColor: colors.primary01,
                    marginBlock:16,
                    paddingBlock:16,
                    paddingInline:16,
                    borderRadius:10,
                }}>
                    <img src={images.InfoCircle} style={{height:20,width:20,objectFit:'contain',marginInlineEnd:8,marginBlockStart:4}}></img>
                    <p className='' style={{flex:1}}>
                        {'Verifying your email list every 3 months ensures higher deliverability, reduces bounce rates, and keeps your sender reputation strong for better engagement.'}<br/><br/>
                        {'We highly recommend verifying, it’s a small cost to pay for increased sales and revenue.'}
                    </p>
                </div>
                <div className='Column justify-content-start w-100' style={{marginBlock:16}}>
                    <h4 style={{fontSize:16,fontWeight:700,width:'100%'}}>
                        {`Verification cost (${localdata?.isAppsumoUser ?  '$0.0014' : '₹0.12'}/Email)`}
                    </h4>
                    <p className='w-100' style={{color:colors.primary03,paddingBlock:16}}>
                        {`Required Balance to verify: ${localdata?.isAppsumoUser ? '$' : '₹'}${localdata?.isAppsumoUser ? Number(balance?.emailArr?.length)*0.0014.toFixed(4) : Number(balance?.emailArr?.length)*0.12.toFixed(2)}`}
                    </p>
                    <p className='w-100' style={{paddingBlockEnd:16}}>
                        {'The required amount will be deducted from your wallet automatically. Please ensure you have enough balance to verify.'}
                    </p>
                </div>
                <div className='Row w-100 my-2'>
                    {!EV && 
                    <p style={{color:colors.primary03,textDecorationLine:'underline',cursor:'pointer'}} onClick={()=>{
                        setVerifyEmail(false);
                        setActiveStep(1);
                        setIsImportSettingsModalOpen(false);
                    }}>
                        {'Skip this time'}
                    </p>
                    }
                    <CheerioButton
                        btnText={'Verify'}
                        textStyle={{color:colors.white01}}
                        backColor={colors.primary03}
                        borderStyle={{borderColor:colors.primary03,width:EV ? '100%' : 'auto'}} 
                        onclick={()=>{
                            if(EV){
                                verifyaction();
                            }else{
                                setVerifyEmail(true);
                                // setActiveStep(1);
                                setIsImportSettingsModalOpen(false);
                            }
                        }} 
                    />
                </div>
                {/* <div className='Row justify-content-start w-100 mt-2'>
                    <Checkbox
                        checked={verifyEmail}
                        onChange={() => { 
                            setVerifyEmail(!verifyEmail)
                            eventsend("Check-box-checked_Import-settings_sequences_clicked");
                        }}
                    />
                    <p>
                        {'Automatically verify all emails before importing'}
                    </p>
                </div>
                <CheerioButton
                    btnText={'Save'}
                    textStyle={{color:colors.white01}}
                    backColor={colors.primary03}
                    borderStyle={{borderColor:colors.primary03,width:'98%',marginBlock:16}} 
                    onclick={()=>{setIsImportSettingsModalOpen(false)}} 
                /> */}
            </div>
        </div>
    </div>
    </>
  )
}

export default ImportSettingsModal