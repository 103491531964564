import React, { useCallback } from 'react';
import colors from '../../../Utils/colors';
import { Handle, Position } from 'reactflow';
import images from '../../../Utils/images';
import { Form, InputGroup } from 'react-bootstrap';
import CustomHandle from './CustomHandle';

const handleStyle = {
  backgroundColor: '#004D1F',
  height: 15, width: 15, borderRadius: "100%",
  top: '60%',
};

const ShopifyOrderNode = ({ data, isConnectable }) => {
  // console.log('ddddd', data);
  return (
    <>
      <div
        className="d-flex flex-column justify-content-between align-items-center"
        style={{
          border: `1px solid ${colors.borderwhite}`,
          borderRadius: 16,
          backgroundColor: colors.white01,
          width: 250,
        }}
      >
        {/* <Handle
          type="target"
          position={Position.Left}
          isConnectable={isConnectable}
          style={handleStyle}
        /> */}
        {(!data?.quickReplyButtons || data?.quickReplyButtons?.length === 0) && (
          <Handle
            type="source"
            position={Position.Right}
            isConnectable={isConnectable}
            style={handleStyle}
          />
        )}

        <div
          className="w-100 d-flex flex-column justify-content-start align-items-center"
          style={{
            paddingInline: 16,
            paddingBlock: 8,
            backgroundColor: '#004D1F',
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
          }}
        >
          <div
            style={{
              height: 4,
              width: '20%',
              borderRadius: 2,
              backgroundColor: colors.white,
              marginBlockEnd: 4,
            }}
          ></div>
          <div className="d-flex flex-row justify-content-between align-items-center w-100">
            <p
              style={{ padding: 0, margin: 0, fontSize: 16, fontWeight: 700, color: colors.white }}
            >
              {'Shopify Event'}
            </p>
            <img
              src={images.Edit}
              style={{ height: 20, width: 20, objectFit: 'contain', cursor: 'pointer' }}
            ></img>
          </div>
        </div>

        <div
          className="w-100 d-flex flex-column justify-content-start align-items-center"
          style={{ paddingInline: 16, paddingBlockStart: 8, paddingBlockEnd: 16 }}
        >
          <p
            style={{
              padding: 0,
              margin: 0,
              marginBlock: 4,
              fontSize: 10,
              fontWeight: 600,
              color: colors.black,
              width: '100%',
            }}
          >
            {'Choose an Event'}
          </p>
          <InputGroup className="my-1">
            <Form.Control
              type="text"
              placeholder={'Select Event'}
              className="w-100 btncustom"
              style={{ borderRadius: 8, fontSize: 10, fontWeight: 400, color: colors.black }}
              // isInvalid={(renewalText.length >= 640)}
              maxLength={640}
              value={data?.shopifyEvent ? data.shopifyEvent : ''}
            // onChange={onChangeEmail}
            />
          </InputGroup>
        </div>
        <CustomHandle
          type="source"
          position={Position.Right}
          id="Event_UserInit"
          style={handleStyle}
          isConnectable={1}
        />
      </div>
    </>
  );
};

export default ShopifyOrderNode;
