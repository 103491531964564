import { AiOutlineDownload, AiOutlineEye, AiOutlineReload, AiOutlineSync } from 'react-icons/ai';
import { FiTrash2 } from 'react-icons/fi';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import DeleteContacts from '../../ImportContactsNew/Components/DeleteContacts';
import { labelDeleteAPI, reSyncFbLeads } from '../../../Services';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { triggerReRender } from './redux';
import { BiSolidDownload } from 'react-icons/bi';
import EmailModal from './components/EmailModal';
import { Tooltip } from '@mui/material';
import Helper from '../../../Utils/Helper';
import images from '../../../Utils/images';
import colors from '../../../Utils/colors';
import { eventsend } from '../../../Config/analyticsFunctions';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
export const timeStampToDated = (timestamp) => {
  const dateObj = new Date(timestamp);
  const dateStr = dateObj.toISOString().split('T')[0];
  return dateStr;
};

const CircularIndeterminate = ({ text }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <CircularProgress size={20} sx={{ color: colors.darkPurple }} />
      <span style={{ marginLeft: '8px' }}>{text}</span>
    </Box>
  );
};
export const columns = [
  {
    field: 'label',
    headerName: 'Label',
    flex: 1,
    align: 'left',
    headerAlign: 'center',
    renderCell: (params) => {
      return <RenderLabelRow params={params} />;
    },
  },
  {
    field: 'contacts',
    headerName: 'Contacts',
    flex: 1,
    align: 'center',
    headerAlign: 'center',
    renderCell: (params) => (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {params?.row?.isUploading ? (
          <CircularIndeterminate text="Syncing..." />
        ) : (
          <span>{params?.row?.contacts}</span>
        )}
      </div>
    ),
  },
  {
    field: 'addedOn',
    headerName: 'Added on',
    flex: 1,
    align: 'center',
    headerAlign: 'center',
  },
  {
    field: 'action',
    headerName: 'Action',
    flex: 1,
    align: 'center',
    headerAlign: 'center',
    renderCell: (params) => {
      return <RenderNavigationPanel params={params} type={'label'} />;
    },
  },
];

export const RenderLabelRow = ({ params }) => {
  const agentdata = useSelector((state) => state.main.agentdata);
  const localdata = useSelector((state) => state.main.localdata);

  return (
    <div style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
       <Tooltip title={params?.row?.label} arrow>
        <ToolTipText>{params?.row?.label}</ToolTipText>
      </Tooltip>
      {params?.row?.privateLabel && (
        <div>
          <img src={images.Privatelock} alt="Private Label" style={{ height: 12, width: 12 }} />
        </div>
      )}
    </div>
  );
};

export const LabelColumnHeaderCell = ({ params, type }) => {
  const [show, setShow] = useState(false);
  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        textAlign: 'left',
        paddingLeft: '30px',
      }}
    >
      <span>{params.value}</span>
    </div>
  );
};
export const RenderNavigationPanel = ({ params, type }) => {
  const { id, deletion } = params.value;
  console.log('id', id);
  const navigate = useNavigate();
  const [deleteState, setDeleteState] = useState(false);
  const authtoken = useSelector((state) => state.main.auth_token);
  const [show, setShow] = useState(false);
  const [isLabel, setIsLabel] = useState(false);
  const agentdata = useSelector((state) => state.main.agentdata);
  const dispatch = useDispatch();
  return (
    <>
      {show && (
        <EmailModal
          show={show}
          setShow={setShow}
          type={type}
          reqId={params.row.id}
          isLabel={isLabel}
        />
      )}
      <RenderDiv>
        <Tooltip title="View contacts">
          <div
            onClick={() => {
              eventsend("View_contact_Labels_page_clicked");
              if (
                !params?.row?.privateLabel ||
                (params?.row?.privateLabel &&
                  (!agentdata || params?.row?.uploadedUser === agentdata?._id))
              ) {
                Helper.SelectedLabelName = params?.row?.label;
                Helper.SelectedLabelId = params?.row?.id;
                navigate('view/' + id + '/label');
              } else {
                toast.error(
                  'Private Labels are only visible to owner and agents who have uploaded the label.'
                );
              }
            }}
          >
            <AiOutlineEye />
          </div>
        </Tooltip>

        {deletion && (
          <Tooltip title="Delete Label">
            <div
              onClick={() => {
                setDeleteState(true);
              }}
            >
              <FiTrash2 />
            </div>
          </Tooltip>
        )}
        {params?.row?.visible && (
          <Tooltip title="Export Label">
            <div>
              <AiOutlineDownload
                onClick={() => {
                  setIsLabel(true);
                  eventsend("Download_label_Label_page_clicked");
                  setShow((state) => !state);
                }}
              />
            </div>
          </Tooltip>
        )}

        {deleteState && (
          <DeleteContacts
            deleteAction={(e) => {
              console.log(e);
              let data = {
                _id: id,
              };
              let token = authtoken;
              labelDeleteAPI(data, token).then((res) => {
                dispatch(triggerReRender());
                toast.success('Contact list successfully deleted.');
              });
            }}
            item={{
              _id: id,
              label_name: params.row.label,
              createdAt: params.row.addedOn,
              count: params.row.contacts,
            }}
            setOpenDelete={setDeleteState}
          />
        )}
        {params?.row?.source && params?.row?.source === 'facebook' && (
          <Tooltip title="Sync Leads">
            <div>
              <AiOutlineSync
                onClick={() => {
                  //console.log('p', authtoken);
                  toast.loading('Leads are syncing', {
                    position: 'top-center',

                    toastId: 1,
                  });
                  const data = {
                    labelId: id,
                  };
                  reSyncFbLeads(authtoken, data).then((res) => {
                    if (res?.flag) {
                      toast.dismiss(1);
                      dispatch(triggerReRender());
                      toast.success('Synced successfully!', { position: 'top-center' });
                    } else {
                      toast.dismiss(1);
                      toast.error(res?.message, { position: 'top-center' });
                    }
                  });
                }}
              />
            </div>
          </Tooltip>
        )}
      </RenderDiv>
    </>
  );
};
//TODO:external CSS not reaching the renderCell function.
export const RenderDiv = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 15px;
  div {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
  }

  & > div {
    color: var(--links-blue, #36c);
  }

  & > div:nth-child(2) {
    display: flex;
    align-items: center;
  }
`;

export const SxStyling = {
  '&>.MuiDataGrid-main': {
    '&>.MuiDataGrid-columnHeaders': {
      borderBottom: 'none',
      background: '#F0F2F2',
    },
    '.MuiDataGrid-columnHeaderTitle': {
      fontWeight: '600',
    },
    '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
      borderRight: `1px solid #B3B3B3`,
    },
    '& div  >.MuiDataGrid-cell': {
      borderBottom: 'none',
      borderRight: '1px solid #B3B3B3',
      fontWeight: '400',
    },
  },
  '.MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel': {
    'margin-top': '1em',
    'margin-bottom': '1em',
  },
};

export const ToolTipText = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 150px;
`;