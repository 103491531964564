import React, { useEffect } from 'react'
import { WAV2Preview } from '../../Pages/AnnouncementsNew/Components/NewComponents';
import images from '../../Utils/images';
import colors from '../../Utils/colors';

const TemplatePreviewModal = ({ setPreviewModalOpen, item, channel = 'whatsapp' }) => {

    const BodyElement = item?.components?.filter((e) => e?.type === 'BODY');
    const HeadElement = item?.components?.filter((e) => e?.type === 'HEADER');
    const ButtonElement = item?.components?.filter((e) => e?.type === 'BUTTONS');
    const CarouselElement = item?.components?.filter((e) => e?.type === 'CAROUSEL');
    const FooterElement =  item?.components?.filter((e) => e?.type === 'FOOTER');

    const ButtonList = ButtonElement && ButtonElement[0]?.buttons;

    return (
        <>
            <div className='ModalDarkBg' onClick={() => { setPreviewModalOpen(false) }}>
                <div className='Center'>
                    <div className='template_preview_modal' onClick={(e) => { e.stopPropagation(); }}>
                        <div className='template_preview_modal__header'>
                            <img src={images.CMClose} onClick={() => { setPreviewModalOpen(false) }}></img>
                        </div>
                        {channel == "whatsapp" ? (
                            <>
                                <WAV2Preview
                                    // upimage={}
                                    BodyElement={BodyElement[0]?.text}
                                    FooterElement = {FooterElement[0]?.text ? FooterElement[0]?.text : ''}
                                    uptype={HeadElement[0]?.format}
                                    // upimage={s3Link ? s3Link : upFile64}
                                    ButtonList={ButtonList}
                                    CarouselData={CarouselElement[0]?.cards}
                                    CarouselInput={[]}
                                    HeadElement={HeadElement}
                                    HeaderOption={"uploadShopify"}
                                // HeaderInputText={headerInputText}
                                // BodyElement={BodyElement[0].text}
                                // upFile={upFile}
                                />
                            </>
                        ) : channel == 'sms' && (
                            <>
                                <div
                                    className="d-flex flex-column justify-content-start align-items-start my-2 py-2 px-3"
                                    style={{
                                        backgroundColor: '#1F2C34',
                                        width: '60%',
                                        paddingBlock: 24,
                                        borderRadius: 16,
                                    }}
                                >
                                    <p
                                        style={{
                                            fontSize: 16,
                                            fontWeight: 400,
                                            color: colors.white,
                                            marginBlock: 16,
                                            width: '100%',
                                            flexWrap: 'wrap',
                                        }}>
                                        {item.MessageTemplate}
                                    </p>

                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default TemplatePreviewModal