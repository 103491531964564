import React, { useState, useEffect, useRef } from 'react';
import colors from '../../../Utils/colors';
import images from '../../../Utils/images';
import '../index.scss';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import CheerioButton from '../../../Components/CheerioButton';
import { useSelector } from 'react-redux';
import { V2AgentCreate } from '../../../Services';
import { toast } from 'react-toastify';
import { BiError } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';

const ValidatePassword = (password) => {
  if (/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&])[A-Za-z\d@#$!%*?&]{8,}/.test(password)) {
    return true;
  }
  toast(
    'You need to enter a password of minimum 8 characters with one uppercase and lowercase letter, one number, and one special character',
    { postion: 'top-center' }
  );
  return false;
};

const InputItems = ({ title, type, placeholder, value, onChange, name, autocomplete }) => {
  return (
    <>
      <InputGroup className="" style={{ width: '100%', marginBlock: 4 }}>
        <Form.Label style={{ fontSize: 14, fontWeight: 600, color: colors.black, marginBlock: 4 }}>
          {title}
        </Form.Label>
        <Form.Control
          type={type}
          name={name}
          autocomplete={autocomplete}
          placeholder={placeholder}
          className="w-100 btncustom"
          style={{ borderRadius: 8, fontSize: 16, fontWeight: 400, color: colors.greys01 }}
          value={value}
          onChange={(e) => {
            onChange(e.target.value);
          }}
        />
      </InputGroup>
    </>
  );
};

const RoleInfoModal = ({ openModal, setOpenModal }) => {
  return (
    <>
      <div style={{
        width: '100%',
        height: "100%",
        zIndex: 1000,
        top: 0,
        left: 0,
        position: 'fixed',
        background: 'rgba(0,0,0,0.5)',
        textAlign: 'justify'
      }}
      >
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
          }}
        >
          <div
            className="d-flex flex-column justify-content-start align-items-center"
            style={{
              width: "100%",
              backgroundColor: colors.white01,
              borderRadius: 16,
              zIndex: 0,
              border: `1px solid ${colors.borderwhite}`,
              paddingBlock: 20,
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div
              className="d-flex flex-row justify-content-between align-items-center w-100"
              style={{
                paddingBlockEnd: 8,
                borderBottom: `1px solid ${colors.borderwhite}`,
                paddingInline: 16,
              }}
            >

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '4px',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                }}
              >
                <p
                  style={{
                    padding: 0,
                    margin: 0,
                    fontSize: 18,
                    fontWeight: 700,
                    color: colors.black,
                  }}
                >
                  {'Role-based Access Control'}
                </p>
              </div>
              <img
                src={images.CMClose}
                style={{
                  height: 24,
                  width: 24,
                  objectFit: 'contain',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setOpenModal(false);
                }}
              ></img>
            </div>
            <div
              className="px-4 w-100"
              style={{ marginTop: 12, gap: '10px' }}
            >
              <div>
                <p style={{ fontWeight: 500, color: "black", marginBottom: 4 }}>{'Admin'}</p>
                <p>{'Admin is a the primary owner of the Cheerio account with the access of all the features and data. They can perform any action as per their subscription plan.'}</p>
              </div>
              <div>
                <p style={{ fontWeight: 500, marginTop: 12, color: "black", marginBottom: 4 }}>{'Manager'}</p>
                <p>{'Manager role will have access to all the features and data except the payments and subscription details of Cheerio.'}</p>
              </div>
              <div>
                <p style={{ fontWeight: 500, marginTop: 12, color: "black", marginBottom: 4 }}>{'Sales Agent'}</p>
                <p style={{ fontSize: 15, marginBottom: 4 }}>{'Sales Agent will have limited access to features as per their role. Admin can manually tweak these permissions while adding a new agent or modify it later.'} </p><p style={{ fontSize: 15 }}> {'Leads or contacts can be assigned to a dedicated sales agent for follow-ups and track the status from Contacts or Labels page. They can initiate a new WhatsApp Chat from your WABA number directly from the inbox page.'}</p>
              </div>
              <div>
                <p style={{ fontWeight: 500, marginTop: 12, color: "black", marginBottom: 4 }}>{'Support Agent'}</p>
                <p style={{ fontSize: 15, marginBottom: 4 }}>{'Support Agent will have limited access to features as per their role. Admin can manually tweak these permissions while adding a new agent or modify it later.'}</p><p style={{ fontSize: 15 }}>{'Incoming support tickets on WhatsApp or Instagram can be assigned to a support agent manually or automatically as per the configuration. Admin or Manager can track and monitor their performance metrics like FRT, Average Resolution Time etc. from agent analytics dashboard.'}</p>
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  )
}

const SwitchItems = ({ title, setField, Checked, fieldName }) => {
  return (
    <>
      <div className="Row w-100" style={{ marginBlock: 4 }}>
        <p style={{ padding: 0, margin: 0, fontSize: 14, fontWeight: 400, color: colors.black }}>
          {title}
        </p>
        <Form.Check
          type="switch"
          className="btncustom"
          defaultChecked={Checked}
          onChange={(e) => setField(fieldName, e.target.checked)}
        />
      </div>
    </>
  );
};

const SubPermissionToggle = ({ title, setField, Checked, fieldName, disabled }) => {
  return (
    <>
      <div className="Row w-100" style={{ marginBlock: 4, }}>
        <p style={{ padding: 10, margin: 0, fontSize: 16, fontWeight: 400, color: colors.black, marginLeft: '10px' }}>
          &#8226; {title}
        </p>
        <Form.Check
          disabled={disabled}
          type="switch"
          className="btncustom"
          defaultChecked={Checked}
          onChange={(e) => setField(fieldName, e.target.checked)}
          style={{ marginRight: '10px' }}
        />
      </div>
    </>
  );
};

const PermissionToggleSection = ({ title, permissions = {}, setField, disabled, subPermissions, fieldName }) => {
  const [showSubPermissions, setShowSubPermissions] = useState(false);

  const handleToggle = (isChecked) => {
    setField(fieldName, isChecked);

    if (subPermissions?.length > 0) {
      setShowSubPermissions(isChecked);
      subPermissions.forEach((sub) => {
        setField(sub.field, isChecked);
      });
    }
  };

  useEffect(() => {
    if (subPermissions?.length > 0) {
      const anySubPermissionOn = subPermissions.some((sub) => permissions?.[sub.field]);
      const allSubPermissionsOff = subPermissions.every((sub) => !permissions?.[sub.field]);

      if (anySubPermissionOn && permissions?.[fieldName] !== true) {
        setField(fieldName, true);
      } else if (allSubPermissionsOff && permissions?.[fieldName] !== false) {
        setField(fieldName, false);
      }
    }
  }, [permissions, subPermissions, fieldName, setField]);

  return (
    <div
      style={{
        marginBottom: '1.5rem',
        padding: '-4px',
        border: '1px solid #ddd',
        borderRadius: '8px',
        backgroundColor: '#f9f9f9',
        width: '100%',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          cursor: 'pointer',
          padding: '5px 10px',
          fontWeight: 600,
          fontSize: '16px',
          marginBottom: '6px',
        }}
        onClick={() => setShowSubPermissions(!showSubPermissions)}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            sx={{
              fontSize: 16,
              fontWeight: 600,
              color: colors.black,
              marginRight: '4px',
            }}
          >
            {title}
          </Typography>
          {subPermissions?.length > 0 && (
            <i
              className={`pi ${showSubPermissions ? 'pi-angle-up' : 'pi-angle-down'}`}
              style={{ fontSize: '16px' }}
            ></i>
          )}
        </div>

        <Form.Check
          type="switch"
          className="btncustom"
          checked={permissions?.[fieldName] || false}
          onChange={(e) => {
            e.stopPropagation();
            handleToggle(e.target.checked);
          }}
          disabled={disabled}
          style={{
            marginLeft: '50px',
            transform: 'scale(1.0)',
          }}
        />
      </div>

      {showSubPermissions && subPermissions?.length > 0 && (
        <div>
          {subPermissions?.map((sub) => (
            <SubPermissionToggle
              key={sub.field}
              title={sub.title}
              setField={setField}
              Checked={permissions?.[sub?.field] || false}
              fieldName={sub.field}
              disabled={disabled}
            />
          ))}
        </div>
      )}
    </div>
  );
};
const AddPeopleModal = ({ memberLimit, setIsAPModalOpen, tableData }) => {
  const agentCount = Object.keys(tableData)?.length;
  const navigate = useNavigate();
  const permissionsRef = useRef(null);
  const PermissionsLabels = [
    {
      title: 'Contacts',
      field: 'contact',
      subPermissions: [
        { title: 'All Contacts', field: 'allContact' },
        { title: 'Contacts Report', field: 'contactReport' },
        { title: 'Labels', field: 'labels' },
        { title: 'Labels Report', field: 'labelsReport' },
      ],
    },
    {
      title: 'Campaigns',
      field: 'campaign',
      subPermissions: [
        { title: 'Campaigns Export', field: 'campaignExport' },
      ],
    },
    {
      title: 'Analytics',
      field: 'analytics',
      subPermissions: [
        { title: 'Global Analytics ', field: 'globalAnalytics' },
        { title: 'Global Analytics Report', field: 'globalAnalyticsReport' },
        { title: 'Campaign Analytics', field: 'campaignAnalytics' },
        { title: 'Campaign Analytics Report', field: 'campaignAnalyticsReport' },
        { title: 'Agent Analytics', field: 'agentAnalytics' },
        { title: 'Agent Analytics Report', field: 'agentAnalyticsReport' },
      ],
    },
    {
      title: 'WhatsApp Inbox',
      field: 'whatsappInbox',
      subPermissions: [
        { title: 'Messages Export ', field: 'messageExport' },
        { title: 'Report Export', field: 'reportExport' },
        { title: 'Chat Replies', field: 'chatReplies' },
        { title: 'Access all chats', field: 'allChats' },
      ],
    },
    {
      title: 'Template Library',
      field: 'templateLibrary'
    },
    {
      title: 'Email',
      field: 'email',
      subPermissions: [
        { title: 'Email Builder ', field: 'emailBuilder' },
        { title: 'Upload custom html', field: 'uploadCustomHtml' },
        { title: 'Manage Sender ID', field: 'manageSenderId' },
      ],
    },
    {
      title: 'Integration',
      field: 'integrations'
    },
    {
      title: 'Automation',
      field: 'automation',
      subPermissions: [
        { title: 'Keyword Automation ', field: 'keywordAutomation' },
        { title: 'Workflows', field: 'workflow' },
        { title: 'Time trigger', field: 'timeTrigger' },
        { title: 'Chatbot', field: 'chatbot' },
      ],
    },
    {
      title: 'Embedded Forms',
      field: 'embeddedForms'
    },
    {
      title: 'Instagram Inbox',
      field: 'instaReplies'
    },
    {
      title: 'WhatsApp Catalog',
      field: 'whatsappCatalog'
    },
  ];
  const [Name, setName] = useState('');
  const [Email, setEmail] = useState('');
  const [Mobile, setMobile] = useState('');
  const [Role, setRole] = useState('');
  const [Password, setPassword] = useState('');
  const [success, setSuccess] = useState(false);

  const [DropDown, setDropDown] = useState(false);

  const authtoken = useSelector((state) => state.main.auth_token);
  const localData = useSelector((state) => state.main.localdata);

  const agentLimit = localData?.addOnSubscriptionActive ? 12 : 2;

  const [permissions, setPermissions] = useState({
    contact: false,
    campaign: false,
    analytics: false,
    chatReplies: false,
    email: false,
    integrations: false,
    automation: false,
  });
  const [openModal, setOpenModal] = useState(false);

  const setField = (field, value) => {
    setPermissions((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  useEffect(() => {
    const handleMouseUp = (e) => {
      const selection = window.getSelection();
      if (selection && selection.toString().length > 0) {
        e.stopPropagation();
        e.preventDefault();
      }
    };

    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, []);

  const handleRoleChange = (e) => {
    setRole(e.target.value);
    setDropDown(true);
    setTimeout(() => {
      if (e.target.value === 'agent' && permissionsRef.current) {
        permissionsRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
      }
    }, 100);
  };

  const TickItems = ({ text }) => {
    return (
      <>
        <div className="Row" style={{ marginBlock: 8, justifyContent: 'flex-start', width: '70%' }}>
          <img
            src={images.SUCCESSFULL}
            style={{ height: 20, width: 20, objectFit: 'contain' }}
          ></img>
          <p
            style={{
              fontSize: 14,
              fontWeight: 400,
              color: colors.greys04,
              padding: 0,
              margin: 0,
              marginInlineStart: 16,
            }}
          >
            {text}
          </p>
        </div>
      </>
    );
  };

  const CreateAgentV2APICall = () => {
    let data = {
      name: Name,
      email: Email.toLowerCase(),
      phone: Mobile,
      role: Role,
      permission: permissions,
      status: 1,
      password: Password,
    };
    let token = authtoken;
    console.log('data being sent ===> ', data);
    if (ValidatePassword(Password)) {
      V2AgentCreate(data, token).then((res) => {
        if (res?.flag) {
          console.log('success response ===> ', res);
          setSuccess(true);
        } else {
          toast.error(res?.message, { position: 'top-center', zIndex: 5 });
        }
      });
    }
  };

  return (
    <>
      <div className="DarkBg"
        onClick={(e) => {
          if (e.target.className === 'DarkBg') {
            const selection = window.getSelection();
            if (!selection || selection.toString().length === 0) {
              setIsAPModalOpen(false);
            }
          }
        }}
      >
        <div className="Center">
          <div className={'APModalContainer Column'} onClick={(e) => e.stopPropagation()}>
            <div className={'APMHeader Row'}>
              <div className="d-flex flex-row justify-content-start align-items-center">
                {agentCount >= agentLimit && localData?.isAppsumoUser && (
                  <BiError size={24} color={colors.error03} style={{ marginInlineEnd: 6 }} />
                )}
                <h2
                  style={{
                    fontSize: 24,
                    fontWeight: 700,
                    color:
                      agentCount >= agentLimit && localData?.isAppsumoUser
                        ? colors.error03
                        : colors.black,
                    padding: 0,
                    margin: 0,
                  }}
                >
                  {'Invite members to your account'}
                </h2>
              </div>

              <img
                src={images.CMClose}
                style={{ height: 24, width: 24, objectFit: 'contain', cursor: 'pointer' }}
                onClick={() => {
                  setIsAPModalOpen(false);
                }}
              ></img>
            </div>

            {success ? (
              <>
                <img
                  src={images.CATSuccess}
                  style={{ height: 160, width: 160, objectFit: 'contain', marginBlock: 20 }}
                ></img>
                <p
                  style={{
                    padding: 0,
                    margin: 0,
                    fontSize: 16,
                    fontWeight: 700,
                    color: colors.black,
                    textAlign: 'center',
                    width: '60%',
                  }}
                >
                  {'Invitation sent'}
                </p>
                <CheerioButton
                  borderStyle={{ borderWidth: 0, width: '100%', marginBlockStart: 16 }}
                  textStyle={{ fontSize: 16, fontWeight: 600, color: colors.white }}
                  btnText={'Close'}
                  backColor={colors.black}
                  onclick={() => {
                    setIsAPModalOpen(false);
                  }}
                // icon={images.MPPlus}
                // iconStyle={{height}}
                // disabled={builderReady ? false : true}
                />
              </>
            ) : (
              <>
                <div className="w-100 Row" style={{ height: 'calc( 100% - 60px )' }}>
                  <div
                    className="w-50 h-100 verythinscrollnew d-flex flex-column justify-content-start align-items-center"
                    style={{ overflowY: 'scroll', paddingInline: 16 }}
                  >
                    {agentCount >= agentLimit && localData?.isAppsumoUser && (
                      <p
                        style={{
                          fontSize: 14,
                          fontWeight: 400,
                          color: colors.error03,
                          padding: 0,
                          margin: 0,
                          marginBlockEnd: 12,
                        }}
                      >
                        {
                          'You have reached your maximum agent invitation limit. To add more agents please '
                        }
                        <span
                          style={{
                            color: colors.linkblue,
                            textDecorationLine: 'underline',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            navigate('/addons');
                          }}
                        >
                          {'upgrade'}
                        </span>
                      </p>
                    )}
                    <p
                      style={{
                        fontSize: 14,
                        fontWeight: 400,
                        color: colors.greys04,
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      {
                        'Invite/ add your all team members to access your WhatsApp business account at once. You can set restrictions for each member feature by feature.'
                      }
                    </p>

                    <InputItems
                      title={'Name'}
                      type={'text'}
                      placeholder={'Enter name here'}
                      value={Name}
                      autocomplete={'new-name'}
                      onChange={setName}
                    />
                    <InputItems
                      title={'Email ID'}
                      type={'email'}
                      placeholder={'Enter email here'}
                      value={Email}
                      autocomplete={'new-email'}
                      name={'AgentEmail'}
                      onChange={setEmail}
                    />
                    <InputItems
                      title={'Phone No'}
                      type={'tel'}
                      name={'AgentPhone'}
                      autocomplete={'new-mobile'}
                      placeholder={'Enter number here'}
                      value={Mobile}
                      onChange={setMobile}
                    />
                    <InputItems
                      title={'Password'}
                      type={'password'}
                      name={'AgentPassword'}
                      autocomplete={'new-password'}
                      placeholder={'Enter password here'}
                      value={Password}
                      onChange={setPassword}
                    />

                    <InputGroup className="" style={{ width: '100%', marginBlock: 4 }}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Form.Label
                          style={{
                            fontSize: 14,
                            fontWeight: 600,
                            color: colors.black,
                            marginBlock: 4,
                          }}
                        >
                          {'Role'}
                        </Form.Label>

                        <img
                          src={images.InfoCircleGrey}
                          style={{
                            height: 18,
                            width: 18,
                            objectFit: 'contain',
                            marginInlineStart: 2,
                            cursor: 'pointer',
                          }}
                          onClick={() => setOpenModal(true)}
                        />
                      </div>

                      <Form.Select
                        type="text"
                        placeholder="Select Role"
                        className="w-100 btncustom"
                        style={{
                          borderRadius: 8,
                          fontSize: 16,
                          fontWeight: 400,
                          color: colors.greys01,
                        }}
                        value={Role}
                        onChange={handleRoleChange}
                      >
                        <option
                          style={{ width: '100%', marginBlock: 4, borderRadius: 8 }}
                          key="blankchoice"
                          hidden
                          value
                        >
                          {'Choose Role'}
                        </option>
                        {/* <option
                                        style={{width: '100%',marginTop: 4,marginBottom: 4,borderRadius: 8}}
                                        value={'owner'}
                                        key={`key=Owner`}
                                    >
                                        {'Owner'}
                                    </option>
                                    <option
                                        style={{width: '100%',marginTop: 4,marginBottom: 4,borderRadius: 8}}
                                        value={'admin'}
                                        key={`key=Admin`}
                                    >
                                        {'Admin'}
                                    </option> */}
                        <option
                          style={{ width: '100%', marginTop: 4, marginBottom: 4, borderRadius: 8 }}
                          value={'agent'}
                          key={`key=Support_Agent`}
                        >
                          {'Support Agent'}
                        </option>
                        <option
                          style={{ width: '100%', marginTop: 4, marginBottom: 4, borderRadius: 8 }}
                          value={'sales'}
                          key={`key=Sales_Agent`}
                        >
                          {'Sales Agent'}
                        </option>
                      </Form.Select>
                    </InputGroup>

                    <div
                      ref={permissionsRef}
                      className="Row w-100" style={{ marginBlock: 16 }}>
                      <p
                        style={{
                          padding: 0,
                          margin: 0,
                          fontSize: 16,
                          fontWeight: 600,
                          color: colors.black,
                        }}
                      >
                        {'Manage Permissions'}
                      </p>
                      <img
                        src={images.DownArrowTip}
                        style={{
                          height: 20,
                          width: 20,
                          objectFit: 'contain',
                          transform: DropDown ? 'rotate( 180deg )' : '',
                          transition: 'all 0.5s ease-in-out',
                        }}
                        onClick={() => {
                          setDropDown(!DropDown);
                        }}
                      ></img>
                    </div>

                    {DropDown && (
                      <>
                        <div className="Column w-100" style={{ justifyContent: 'flex-start' }}>
                          <ul style={{ paddingInlineStart: 16 }}>
                            <li style={{ fontSize: 14, fontWeight: 400, color: colors.greys04 }}>
                              {
                                !Role || (Role === 'agent') ? 'Team members will not be able to access the features with no permissions' :
                                  'Sales Agent will have access to all the enabled features. To change you can manually turn on the toggle'
                              }
                            </li>
                            <li style={{ fontSize: 14, fontWeight: 400, color: colors.greys04 }}>
                              {!Role || (Role === 'agent') ? 'In order to access they can request access anytime' :
                                'You can assign leads from contacts/Labels to share with them'}
                            </li>
                            <li style={{ fontSize: 14, fontWeight: 400, color: colors.greys04 }}>
                              {!Role || (Role === 'agent') ? 'You can also add/revoke permissions anytime you want' :
                                'You can also add/revoke permissions anytime you want'}
                            </li>
                          </ul>
                        </div>

                        <div className="Row w-100" style={{ marginBlock: 8 }}>
                          <h3
                            style={{
                              padding: 0,
                              margin: 0,
                              fontSize: 16,
                              fontWeight: 600,
                              color: colors.black,
                            }}
                          >
                            {'Features'}
                          </h3>
                          <h3
                            style={{
                              padding: 0,
                              margin: 0,
                              fontSize: 16,
                              fontWeight: 600,
                              color: colors.black,
                            }}
                          >
                            {'Access'}
                          </h3>
                        </div>

                        {PermissionsLabels?.map((item, index) => (
                          <PermissionToggleSection
                            key={item.field}
                            title={item.title}
                            permissions={permissions}
                            setField={setField}
                            subPermissions={item.subPermissions}
                            fieldName={item.field}
                          />
                        )
                        )}
                      </>
                    )}

                    <CheerioButton
                      borderStyle={{ borderWidth: 0, width: '100%', marginBlock: 8 }}
                      textStyle={{ fontSize: 16, fontWeight: 600, color: colors.white }}
                      btnText={'Share Invitation'}
                      backColor={colors.primary03}
                      onclick={() => {
                        console.log('invite button click');
                        CreateAgentV2APICall();
                      }}
                      disabled={agentCount >= agentLimit && localData?.isAppsumoUser && localData?._id !== '664cf714f3e3a40203785e71' ? true : false}
                    // icon={images.MPPlus}
                    // iconStyle={{height}}
                    // disabled={builderReady ? false : true}
                    />
                  </div>

                  <div
                    className="w-50 h-100 verythinscrollnew d-flex flex-column justify-content-start align-items-center"
                    style={{ overflowY: 'auto', paddingInline: 16 }}
                  >
                    <img
                      src={images.InviteModalPic}
                      style={{ width: '90%', objectFit: 'contain', marginBlock: 16 }}
                    ></img>
                    <TickItems text={`You can invite maximum ${memberLimit} members`} />
                    <TickItems text={'Set permissions for every member'} />
                    <TickItems text={'Manage customers with your team'} />
                    <TickItems text={'Plan, schedule, launch campaigns'} />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {openModal && <RoleInfoModal openModal={openModal} setOpenModal={setOpenModal} />}
    </>
  );
};

export default AddPeopleModal;