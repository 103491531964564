import { RenderDiv } from '../Labels/variableDependencies';
import { FiEdit2, FiTrash2 } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { updateEditContact } from '../contactsRedux/contactsRedux';
import { useNavigate } from 'react-router-dom';
import { ContactSideBar } from './contactSidebar'
import {
  deleteLabelContactsAPI,
  fetchContactLabels,
  getIndividualCustomAttributes,
  fetchContactLabelsineditSidebar,
  fetchIndividualLabels,
} from '../../../Services';
import { triggerReRender } from '../Labels/redux';
import { toast } from 'react-toastify';
import { Tooltip } from '@mui/material';
import { useState, useEffect } from 'react';
import DeleteModal from '../../../Components/Modal/DeleteModal';
import { eventsend } from '../../../Config/analyticsFunctions';
import { FiEdit } from 'react-icons/fi';
import { Drawer, Checkbox } from '@mui/material';
import colors from '../../../Utils/colors';
import CheerioDropDown from '../../../Components/CheerioDropDown';
import { DeleteBulkContact } from '.';
const MobileWidth = window.innerWidth < 900 ? true : false;

export function hiddenName(w) {
  w = String(w);
  if (w.length < 3) return w;
  return w.substring(0, 1) + '*'.repeat(w.length - 2) + w.substring(w.length - 1);
}

export const hiddenPhone = (number) => {
  return String(number).replace(/(\d{2})(\d+)(\d{1})/, function (_, first, middle, last) {
    return first + middle.replace(/\d/g, '*') + last;
  });
};

export const hiddenEmail = (email) => {
  return String(email).replace(/(\w{1})(.*)(\w{1})@(\w{1})(.*)(\.\w+)/, '$1******$3@$4****$6');
};



export const RenderNameRow = ({ params }) => {
  return (
    <div style={{ textOverflow: 'ellipsis' }}>
      <p>{params?.row?.visible ? params?.row?.name : hiddenName(params?.row?.name)}</p>
    </div>
  );
};

export const RenderEmailRow = ({ params }) => {
  return (
    <div style={{ textOverflow: 'ellipsis' }}>
      <p>{params?.row?.visible ? params?.row?.email : hiddenEmail(params?.row?.email)}</p>
    </div>
  );
};

export const RenderPhnoRow = ({ params }) => {
  return (
    <div>
      <p>{params?.row?.visible ? params?.row?.phno : hiddenPhone(params?.row?.phno)}</p>
    </div>
  );
};

export const EditableCell = ({ value, cnc_id, params, fetchData, setDeleteModalOpen, setDeleteContactId }) => {
  const [contactSideModalOpen, setcontactSideModalOpen] = useState(false);
  const [showSideBar, setShowSideBar] = useState(false);
  const [sidebarView, setSidebarView] = useState('details');
  const [individualAttributes, setIndividualAttributes] = useState();
  const [contactlabels, setcontactlabels] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authtoken = useSelector((state) => state.main.auth_token);

  const handleEditClick = () => {
    indAttributesAPIcall()
    getindividuallablesids();
    setShowSideBar(true);
  };

  const indAttributesAPIcall = () => {
    let token = authtoken;
    let data = {
      id: params?.value?._id ?? '',
    };

    if (!token || !data.id) {
      console.error('Token or ID is missing');
      return;
    }

    getIndividualCustomAttributes(token, data)
      .then((res) => {
        if (res?.flag) {
          setIndividualAttributes(res?.data ?? []);
          console.log('ind attributes res ---> ', res);
        } else {
          console.log("ind attributes err ---> ", res);
        }
      })
      .catch((error) => {
        console.error('Failed to fetch individual custom attributes:', error);
      });
  };

  const getindividuallablesids = () => {
    let token = authtoken;
    let id = params?.value?._id ?? '';

    if (!token || !id) {
      console.error('Token or ID is missing');
      return;
    }

    fetchIndividualLabels(token, id)
      .then((data) => {
        if (data?.status === 200 && data?.flag) {
          getlables(data?.data ?? []);
        } else {
          console.error('Error fetching individual labels ids:', data?.message ?? 'Unknown error');
        }
      })
      .catch((error) => {
        console.error('Failed to fetch individual labels ids:', error);
      });
  };

  const getlables = (lables) => {
    let token = authtoken;

    if (!token || !Array.isArray(lables)) {
      console.error('Token is missing or labels are not an array');
      return;
    }
    if (lables?.length < 1) {
      setcontactlabels([]);
    }
    else if (lables?.length > 0) {
      fetchContactLabelsineditSidebar(token, lables)
        .then((data) => {
          if (data?.status === 200 && data?.flag) {
            setcontactlabels(data?.data ?? []);
            console.log("lables from first API", data?.data);
          } else {
            console.error('Error fetching contact labels:', data?.message ?? 'Unknown error');
          }
        })
        .catch((error) => {
          console.error('Failed to fetch contact labels:', error);
        });
    }
  };

  return (
    <>
      {/* {deleteModalOpen && (
        // <DeleteModal
        //   setDeleteModalOpen={setDeleteModalOpen}
        //   deleteAction={() => {
        //     let data = {
        //       _id: cnc_id,
        //     };
        //     deleteLabelContactsAPI(data, authtoken).then((res) => {
        //       if (res?.flag) {
        //         fetchData()
        //         toast.success(res?.message)
        //       }
        //       dispatch(triggerReRender());
        //       setDeleteModalOpen(false);
        //     });
        //   }}
        // />
        <DeleteBulkContact
          setDeleteModal={setDeleteModalOpen}
          totalContacts={1}
          handleBulkDelete={handleDelete}
        />
      )} */}

      <Drawer
        anchor="right"
        open={showSideBar}
        onClose={() => setShowSideBar(false)}
        PaperProps={{
          style: {
            width: '380px',
            height: '100vh',
            gap: '0px',
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'auto',

          },
        }}
      >
        <ContactSideBar
          id={params?.value?._id}
          tocalllatestlables={() => getindividuallablesids()}
          name={params?.row?.name}
          userNumber={params?.row?.phno}
          useremail={params?.row?.email}
          labels={contactlabels}
          setShow={setShowSideBar}
          attributes={individualAttributes}
          updateattributes={() => indAttributesAPIcall()}
          selected={sidebarView}
          setSelected={setSidebarView}
          updateLables={() => getlables()}
          indAttributesAPIcall={indAttributesAPIcall}
        />
      </Drawer>
      <RenderDiv>
        {/* {params.row.version === 2 && params?.row?.visible && (
          <Tooltip title={'edit contact'}>
            <div>
              <FiEdit2
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  eventsend("Edit_contact_Contact_page_clicked");
                  dispatch(updateEditContact({ ...value }));
                  navigate('../contacts/edit', { relative: 'path' });
                }}
              />
            </div>
          </Tooltip>
        )} */}
        <Tooltip title={'Edit button'}>
          <div>
            <FiEdit2
              style={{ cursor: 'pointer', color: colors.darkPurple }}
              onClick={handleEditClick}
            />
          </div>
        </Tooltip>
        <Tooltip title={'delete contact'}>
          <div>
            <FiTrash2
              style={{ cursor: 'pointer', color: colors.darkPurple }}
              onClick={() => {
                eventsend('Delete_contact_Contact_page_clicked');
                setDeleteModalOpen(true);
                setDeleteContactId(cnc_id); // set the id to delete.
              }}
            />
          </div>
        </Tooltip>
      </RenderDiv>
    </>
  );
};

const EditableCellCustom = ({ value, cnc_id, params }) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authtoken = useSelector((state) => state.main.auth_token);
  console.log("param", params)

  const handleEditClick = () => {
    indAttributesAPIcall()
    getindividuallablesids();
    setShowSideBar(true);
  };
  return (
    <>
      {/* {deleteModalOpen && (
        <DeleteModal
          setDeleteModalOpen={setDeleteModalOpen}
          deleteAction={() => {
            let data = {
              _id: cnc_id,
            };
            deleteLabelContactsAPI(data, authtoken).then((res) => {
              dispatch(triggerReRender());
              setDeleteModalOpen(false);
            });
          }}
        />
      )} */}
      <RenderDiv>
        {/* {deleteModalOpen && 
        <DeleteModal 
          setDeleteModalOpen={setDeleteModalOpen}
        />
      } */}

        {params.row.version === 2 && params?.row?.visible && (
          <Tooltip title={'Edit button'}>
            <div>
              <FiEdit2
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  // dispatch(updateEditContact({ ...value }));
                  // navigate('edit');
                  onClick = { handleEditClick }
                }}
              />
            </div>
          </Tooltip>
        )}
        <Tooltip title={'Delete'}>
          <div>
            <FiTrash2
              onClick={() => {
                eventsend('Delete_contact_Contact_page_clicked');
                setDeleteModalOpen(true);
                setDeleteContactId(cnc_id);
              }}
            />
          </div>
        </Tooltip>
      </RenderDiv>
    </>
  );
};
