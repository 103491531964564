import React, { useCallback, useEffect, useState } from 'react'
import colors from '../../../Utils/colors';
import { Handle, Position } from 'reactflow';
import CustomHandle from './CustomHandle';
import images from '../../../Utils/images';

const handleStyle = { backgroundColor: '#DD2A7B', height: 15, width: 15, borderRadius: "100%", top: '60%' };

const IncomingInstagramNode = ({ data, isConnectable }) => {

    const onChange = useCallback((evt) => {
        console.log(evt.target.value)
    }, []);

    return (
        <>
            <div className='d-flex flex-column justify-content-between align-items-center' style={{ border: `1px solid ${colors.borderwhite}`, borderRadius: 16, backgroundColor: colors.white01, width: 250 }}>
                {/* <Handle type='target' position={Position.Left} isConnectable={isConnectable} /> */}
                <div className='w-100 d-flex flex-row justify-content-between align-items-center' style={{ paddingInline: 16, paddingBlock: 8, background: 'linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%)', borderTopLeftRadius: 16, borderTopRightRadius: 16 }}>
                    <p style={{ padding: 0, margin: 0, fontSize: 16, fontWeight: 700, color: colors.white }}>
                        {'Event'}
                    </p>
                    <img src={images.Edit} style={{ height: 20, width: 20, objectFit: 'contain', cursor: 'pointer' }}></img>
                </div>
                <div className='w-100 d-flex flex-column justify-content-start align-items-center' style={{ paddingInline: 16, paddingBlockStart: 8, paddingBlockEnd: 16 }}>
                    <p style={{ padding: 0, margin: 0, fontSize: 12, fontWeight: 400, color: colors.black }}>
                        {'On Incoming Instagram DM'}
                    </p>
                </div>
                {!data?.options &&
                    <CustomHandle type='source' position={Position.Right} id="Event_UserInit" style={handleStyle} isConnectable={1} />
                }
            </div>
        </>
    )
}

export default IncomingInstagramNode