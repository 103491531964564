import React, { useEffect, useState } from 'react';
import CheerioButton from '../../Components/CheerioButton';
import images from '../../Utils/images';
import { useSelector } from 'react-redux';
import {
  AISensyExploreTemplatesApi,
  deleteSenderId,
  getAllSenderId,
  resendSenderIdVerification,
  setDefaultSender,
  updateSenderId,
} from '../../Services';
import './styles.css';
import { SenderIdModal } from '../CreatorNew/Components/SenderIdModal';
import { UpdateSenderIdModal } from './UpdateSenderIdModal';
import SpinnerLoader, { NewLoader } from '../../Components/SpinnerLoader';
import { ThreeDots } from 'react-loader-spinner';
import { Dropdown, Spinner } from 'react-bootstrap';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PremiumOptionModal } from '../../Components/Modal/PremiumOptionModal';
import colors from '../../Utils/colors';
import { eventsend } from '../../Config/analyticsFunctions';
import { trackPages } from '../../Utils/brevo';
import { IoMdSettings } from "react-icons/io";
import { Tooltip } from '@mui/material';
import SettingsPage from './Components/SettingsPage';
import DeleteModal from '../../Components/Modal/DeleteModal';

export const ManageSender = () => {
  const authToken = useSelector((state) => state.main.auth_token);
  const userId = useSelector((state) => state.main._id);
  const [senderData, setSenderData] = useState(null);
  const [check, setCheck] = useState(true);
  const [create, setCreate] = useState(false);
  const [edit, setEdit] = useState(false);
  const [info, setInfo] = useState();
  const [render, setRender] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const navigate = useNavigate();
  const localdata = useSelector((state) => state.main.localdata);
  const isPremiumUser = localdata?.premium;
  const [isFTUser, setIsFTUser] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [senderDataSelected, setSenderDataSelected] = useState();
  const planName = localdata?.premiumPlanName;
  const agentdata = useSelector((state) => state.main.agentdata);
  const getRemainingDays = (e) => {
    const d = new Date(e);
    const date = new Date();
    let dd = d.toDateString();
    let cd = date.toDateString();
    let diff = Math.floor((Date.parse(dd) - Date.parse(cd)) / 86400000);
    return diff;
  };
  const getMaxSenderIds = (planName) => {
    switch (planName) {
      case 'Basic':
      case 'Startup':
        return 1;
      case 'Growth':
        return 3;
      case 'Pro':
        return 5;
      case 'Enterprise':
        return 10;
      default:
        return 10;
    }
  };
  const maxSenderIds = localdata?._id === "66c12a6a2447ac755dbf9233" ? 8 : getMaxSenderIds(planName);

  const handleCreateNewSender = () => {
    if (senderData && Object.keys(senderData).length >= maxSenderIds) {
      setShowToast(true);
      console.log(Object.keys(senderData).length);
      toast.error(`You have reached the maximum limit of ${maxSenderIds} SenderID(s) for your ${planName} plan.`, {
        position: "top-center"
      });
    } else {
      setCreate(true);
    }
  };

  const handleSettingsUpdate = () => {
    setRender(true);
  };


  const remainingDays = getRemainingDays(localdata?.free_trail?.end_date);
  useEffect(() => {
    if (remainingDays > 0) {
      setIsFTUser(true);
    }
  }, [localdata]);

  useEffect(() => {
    trackPages('email_manage_sender_page', localdata);
    eventsend('manage_sender_pageview_web');
    if (agentdata && !agentdata?.permission?.manageSenderId) {
      toast.error(
        'Feature access denied. Kindly request the owner to grant you permission to access this feature'
      );
      navigate('/creator');
    }
  }, []);

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();

        onClick(e);
      }}
    >
      {children}
      <img src={images.ThreeDot} style={{ height: '20px', marginTop: '-10px' }} />
    </a>
  ));

  const resendVerificationApiCall = (senderId) => {
    let data = {};
    let token = authToken;
    resendSenderIdVerification(senderId, data, token).then((res) => {
      if (res?.flag) {
        console.log('resend verification', res);
        toast(res.message, { position: 'top-center' });
        navigate('/creator/manageSender');
        setRender(true);
      } else {
        console.log('resend verification', res);
        toast(res?.message, { position: 'top-center' });
      }
    });
  };

  const setdefaultSenderApiCall = (senderId) => {
    const token = authToken;
    setDefaultSender(senderId, token).then((res) => {
      if (res?.flag) {
        console.log('default sender resp', res);
        setRender(true);
      } else {
        toast(res?.message, { position: 'top-center' });
      }
    });
  };

  const getAllSenderIdApiCall = () => {
    const token = authToken;
    getAllSenderId(token).then((res) => {
      if (res?.flag) {
        console.log('getAll sender id', res);

        if (res?.data) {
          setSenderData(res?.data);
          setCheck(true);
        } else {
          setCheck(false);
        }
      } else {
        // toast(res?.message, { position: 'top-center' });
        setCheck(false);
      }
    });
  };

  const deleteSenderIdApiCall = (senderID) => {
    const token = authToken;
    const data = {
      senderId: senderID,
    };
    deleteSenderId(data, token).then((res) => {
      if (res?.flag) {
        console.log('deleted successfully', res);
        setRender(true);
      } else {
        toast(res?.message, { position: 'top-center' });
      }
    });
  };

  useEffect(() => {
    setSenderData(null);
    getAllSenderIdApiCall();
    setRender(false);
  }, [render, create]);

  return (
    <div
      style={{
        overflowY: 'auto',
        scrollbarWidth: 'thin',
        width: '100%',
        height: '100%',
        padding: '30px',
      }}
    >
      {isDeleteModalOpen &&
        <DeleteModal
          setDeleteModalOpen={setIsDeleteModalOpen}
          deleteAction={() => {
            deleteSenderIdApiCall(deleteData);
            setSenderData(null);
            setDeleteData(null);
            setIsDeleteModalOpen(false);
          }}
        />
      }
      {location.pathname === '/creator/manageSender' &&
        <>
          {!isFTUser && !isPremiumUser && (
            <PremiumOptionModal
              option={'Manage SenderId'}
              openPremiumModal={true}
              setOpenPremiumModal={() => { }}
            ></PremiumOptionModal>
          )}
          <div className="d-flex flex-row align-items-center justify-content-between my-2" style={{}}>
            <h3 style={{ color: colors.black }}>Manage Sender</h3>
            {((localdata?.isAppsumoUser && senderData === null) || (!localdata?.isAppsumoUser && senderData) || (
              localdata?._id == '66c12a6a2447ac755dbf9233' && senderData && Object.keys(senderData).length < 8) || (senderData && Object.keys(senderData).length < maxSenderIds)
            ) && (
                <CheerioButton
                  className="button"
                  borderStyle={{ border: "none" }}
                  textStyle={{ color: colors.white }}
                  btnText={'Create new sender'}
                  backColor={'#8E53F7'}
                  onclick={handleCreateNewSender}
                />
              )}
          </div>
          {create && (
            <SenderIdModal openSenderIdModal={create} setOpenSenderIdModal={setCreate} setSenderDataSelected={setSenderDataSelected} senderDataSelected={senderDataSelected}></SenderIdModal>
          )}

          <div style={{ padding: '20px', marginTop: '20px' }}>
            {check ? (
              senderData ? (
                <table class="table" style={{ border: '' }}>
                  <thead style={{ backgroundColor: '#F5F5F5' }}>
                    <tr style={{ borderRight: '' }}>
                      <th scope="col" style={{ width: '25%', paddingLeft: '20px' }}>
                        Senders
                      </th>
                      <th scope="col" style={{ width: '15%', paddingLeft: '20px' }}>
                        Daily Limit
                      </th>
                      <th scope="col" style={{ width: '15%', paddingLeft: '20px' }}>
                        Warmup
                      </th>
                      <th scope="col" style={{ width: '15%', paddingLeft: '20px' }}>
                        Status
                      </th>
                      {/* <th scope="col" style={{ width: '10%', paddingLeft: '20px' }}>
                    Reputation
                  </th> */}
                      <th scope="col" style={{ width: '15%', paddingLeft: '20px' }}>
                        Nickname
                      </th>
                      <th scope="col" style={{ width: '15%', paddingLeft: '20px' }}>
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody style={{ backgroundColor: 'white' }}>
                    {senderData &&
                      Object.entries(senderData).map((data, index) => (
                        <tr style={{}} key={index}>
                          <th style={{ paddingLeft: '20px', paddingTop: '10px' }} scope="row">
                            <div className="d-flex">
                              <div style={{ fontSize: '14px', frontWeight: '400', color: 'black' }}>
                                {data[1]?.from?.name}
                              </div>
                              {data[1]?.active && (
                                <div
                                  style={{
                                    fontWeight: '500',
                                    fontSize: '13px',
                                    marginBlock: 0,
                                    marginLeft: '30px',
                                    // width: '50%',
                                    paddingInline: '16px',
                                    paddingBlock: '4px',
                                    textAlign: 'center',
                                    color: 'white',
                                    // height: '25px',
                                    backgroundColor: '#04B100',
                                    borderRadius: '6px',
                                  }}
                                >
                                  Default
                                </div>
                              )}
                            </div>
                            <div style={{ fontSize: '12px', frontWeight: '400', color: '#666666' }}>
                              From: {data[1]?.from?.email}
                            </div>
                          </th>
                          <td
                            style={{
                              paddingLeft: '20px',
                              paddingTop: '20px',
                              fontSize: '14px',
                              frontWeight: '400',
                              color: 'black',
                            }}
                          >
                            {`${data[1]?.usage?.sentToday ?? '0'}/${data[1]?.settings?.messagePerDay}`}
                          </td>
                          <td
                            style={{
                              fontSize: '14px',
                              frontWeight: '400',
                              color: 'black',
                            }}
                          >
                            <p
                              style={{
                                borderRadius: '8px',
                                height: '1%',
                                backgroundColor: data[1]?.settings?.emailWarmup?.isActive ? '#EEFBFA' : '#F6F6F6',
                                padding: '8px',
                                textAlign: 'center',
                                justifySelf: 'center',
                                width: '90%',
                                color: data[1]?.settings?.emailWarmup?.isActive ? '#35BAB2' : '#666666',
                              }}
                            >
                              {`${data[1]?.settings?.emailWarmup?.isActive ? 'Enabled' : 'Disabled'}`}
                            </p>
                          </td>
                          <td style={{}}>
                            {data[1]?.verified?.status ? (
                              <p
                                style={{
                                  borderRadius: '8px',
                                  height: '1%',
                                  backgroundColor: '#CFFFCE',
                                  padding: '8px',
                                  width: '90%',
                                  color: '#039600',
                                  textAlign: 'center',
                                  justifySelf: 'center',
                                }}
                              >
                                Verified{' '}
                              </p>
                            ) : (
                              <p
                                style={{
                                  borderRadius: '8px',
                                  backgroundColor: '#FFF5C6',
                                  padding: '8px',
                                  width: '90%',
                                  color: '#DB7600',
                                  height: '1%',
                                  textAlign: 'center',
                                }}
                              >
                                Not Verified{' '}
                              </p>
                            )}
                          </td>
                          <td style={{ justifyContent: 'center', alignItems: 'center', paddingInline: 16 }}>
                            {data[1]?.nickname}
                          </td>
                          <td style={{ paddingLeft: '10px', paddingTop: '20px' }}>
                            <div className="d-flex justify-content-around" style={{ height: '21px' }}>
                              <img
                                src={images.Trash}
                                style={{ cursor: 'pointer', height: 18, width: 18, objectFit: 'contain' }}
                                onClick={() => {
                                  if (localdata?.isSesUser) {
                                    setDeleteData(data[1]?._id)
                                  } else {
                                    setDeleteData(data[1]?.id)
                                  }
                                  setIsDeleteModalOpen(true);

                                  // if (localdata?.isSesUser) {
                                  //   deleteSenderIdApiCall(data[1]?._id);
                                  // } else {
                                  //   deleteSenderIdApiCall(data[1]?.id);
                                  // }
                                  // setSenderData(null);
                                }}
                              ></img>

                              <Tooltip title={'Settings'}>
                                <IoMdSettings size={20} style={{ marginInline: 10, cursor: 'pointer', objectFit: 'contain' }} onClick={() => {
                                  console.log('data sender id ---> ', data[1]);
                                  setSenderDataSelected(data[1]);
                                  navigate('/creator/manageSender/setting');
                                }} />
                              </Tooltip>

                              {!localdata?.isSesUser && (
                                <img
                                  src={images.EditSender}
                                  style={{ cursor: 'pointer', height: 18, width: 18, objectFit: 'contain' }}
                                  onClick={() => {
                                    setEdit(true);
                                    setInfo(data);
                                  }}
                                ></img>
                              )}
                              {localdata?.isSesUser ? (
                                data[1]?.verified?.status ? (
                                  <Dropdown style={{}}>
                                    <Dropdown.Toggle as={CustomToggle}></Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      <Dropdown.Item
                                        onClick={() => {
                                          if (localdata?.isSesUser) {
                                            setdefaultSenderApiCall(data[1]?._id);
                                          } else {
                                            setdefaultSenderApiCall(data[1]?.id);
                                          }
                                        }}
                                      >
                                        <img src={images.Flag}></img> Set as default
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                ) : (
                                  ''
                                )
                              ) : (
                                <Dropdown style={{}}>
                                  <Dropdown.Toggle as={CustomToggle}></Dropdown.Toggle>
                                  {data[1]?.verified?.status ? (
                                    <Dropdown.Menu>
                                      <Dropdown.Item
                                        onClick={() => {
                                          setdefaultSenderApiCall(data[1]?.id);
                                        }}
                                      >
                                        <img src={images.Flag}></img> Set as default
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  ) : (
                                    <Dropdown.Menu>
                                      <Dropdown.Item
                                        onClick={() => {
                                          if (localdata?.isSesUser) {
                                            resendVerificationApiCall(data[1]?._id);
                                          } else {
                                            resendVerificationApiCall(data[1]?.id);
                                          }
                                        }}
                                      >
                                        <img src={images.Verified}></img> Resend verification
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  )}
                                </Dropdown>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                  {edit && (
                    <UpdateSenderIdModal
                      setOpenUpdateIdModal={setEdit}
                      openUpdateIdModal={edit}
                      data={info}
                      modalRender={setRender}
                    ></UpdateSenderIdModal>
                  )}
                </table>
              ) : (
                <div style={{ height: '20rem' }} className="d-flex justify-content-center">
                  {' '}
                  <SpinnerLoader></SpinnerLoader>
                </div>
              )
            ) : (
              <div className="d-flex align-items-center flex-column" style={{}}>
                <img src={images.SenderImage} style={{ width: '27%' }}></img>
                <p style={{ fontWeight: '700', fontSize: '18px' }}>Create your first sender Id</p>
                <p style={{ width: '50%', textAlign: 'center', fontWeight: '400', margin: '10px' }}>
                  {' '}
                  You are required to setup a sender ID for every promotional email you send in order to
                  comply with the anti- spam laws such as CAN-SPAM and CASL.
                </p>
                <CheerioButton
                  className="button"
                  borderStyle={{
                    width: '12rem',
                    border: "none"
                  }}
                  textStyle={{ color: 'white', fontSize: 16, fontWeight: 600 }}
                  btnText={'Create new sender'}
                  backColor={'#8E53F7'}
                  onclick={handleCreateNewSender}
                />
              </div>
            )}
          </div>
        </>
      }
      <Routes>
        <Route path='setting' element={<SettingsPage SenderData={senderDataSelected} onSettingsUpdate={handleSettingsUpdate} />}></Route>
      </Routes>
    </div >
  );
};
