import React, { useState } from 'react';
import images from '../../../Utils/images';
import colors from '../../../Utils/colors';
import CheerioButton from '../../../Components/CheerioButton';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { eventsend } from '../../../Config/analyticsFunctions';
import{sendWabaOnboardingNotificationMail} from '../../../../src/Services/index'

const WAPreSetupModal = ({
  setIsWAPreSetupOpen,
  onclickAction,
  MetaEmbedded,
  AISensyEmbedded,
  onClickApply,
  openEmbedded,
  WAStatus = false,
}) => {
  const localdata = useSelector((state) => state.main.localdata);
  const authtoken = useSelector((state) => state.main.auth_token);
  const navigate = useNavigate();

  const [DirectCheerio, setDirectCheerio] = useState(true);

  const MobileWidth = window.innerWidth < 900 ? true : false;
  
  const HandleSendMail = ()=>{
    const data  = {
      name:localdata.name,
      email:localdata.email,
      phone:localdata.mobile
    }
    let token = authtoken;
     sendWabaOnboardingNotificationMail( data,token).then((res) => {
      if (res?.flag) {
         console.log('sent mail success -------------->');
      } else {
         console.log('error api --------------> ', res);
      }
    });
  }
  const ProgressComponent = ({ text, status }) => {
    return (
      <>
        <div
          className="d-flex flex-column justify-content-start align-items-center"
          style={{ flex: 1, width: '100%' }}
        >
          <img
            src={
              status === 'success'
                ? images.CircleCheckGreen
                : status === 'pending'
                  ? images.CirclePending
                  : images.CircleUncheckBlack
            }
            style={{
              height: 32,
              width: 32,
              objectFit: 'contain',
              backgroundColor: colors.white01,
              zIndex: 0,
            }}
          />
          <p
            style={{
              padding: 0,
              margin: 0,
              marginBlockStart: 16,
              fontSize: 13,
              fontWeight: 400,
              color: colors.black,
              flexWrap: 'wrap',
              wordBreak: 'break-word',
              textAlign: 'center',
            }}
          >
            {text}
          </p>
        </div>
      </>
    );
  };

  return (
    <>
      <div
        className="DarkBg"
        onClick={() => {
          setIsWAPreSetupOpen(false);
        }}
      >
        <div className="Center">
          <div
            className="wa_presetup_modal_container"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {/* {MetaEmbedded && 
                <>
                <div className='wa_presetup_modal_container__header'>
                    <h3>{'Apply for WhatsApp Business API'}</h3>
                    <img src={images.CMClose} 
                    style={{height:24,width:24,objectFit:'contain',cursor:'pointer'}}
                    onClick={()=>{setIsWAPreSetupOpen(false)}}></img>
                </div>
                <p style={{color:colors.black}} className='my-2'>
                    {'Click on continue with Facebook to Apply for WhatsApp Business API'}
                </p>
                <p className='my-2'>
                    {'Requirements'}<br/>
                    {`1. A Phone number that doesn't have WhatsApp App or WhatsApp Business App installed (Please DELETE if installed or buy a new number )`}<br/>
                    {`2. A Registered Business & working website`}
                </p>
                <div
                className="d-flex flex-row justify-content-start align-items-center w-100"
                style={{ marginBlockStart: 20 }}
                >
                <CheerioButton
                    borderStyle={{ borderColor: '#1877f2', height:40 , borderRadius:4, paddingInline:24 }}
                    textStyle={{ fontSize: 16, fontWeight: 500, color: colors.white }}
                    btnText={'Continue with Facebook'}
                    backColor={'#1877f2'}
                    // icon={images.WhatsAppWhite}
                    onclick={onclickAction}
                />
                <CheerioButton
                    borderStyle={{ borderColor: colors.black, height:40 , borderRadius:4, paddingInline:24 , marginInline: 16 }}
                    textStyle={{ fontSize: 16, fontWeight: 600, color: colors.black }}
                    btnText={'Schedule LIVE Demo'}
                    backColor={colors.white01}
                    icon={images.Calendar}
                    onclick={() => {
                    console.log('schedule demo clicked . . .');
                    window.open('https://calendly.com/avinash-cheerio/30min', '_blank');
                    }}
                />
                </div>
                </>
                } */}
            {/* {AISensyEmbedded && 
                <> */}
            <div className="wa_presetup_modal_container__header">
              <h3>{'Setup & Engage with your customers on WhatsApp'}</h3>
              <img
                src={images.CMClose}
                style={{ height: 24, width: 24, objectFit: 'contain', cursor: 'pointer' }}
                onClick={() => {
                  setIsWAPreSetupOpen(false);
                }}
              ></img>
            </div>
            <p style={{ color: colors.black }} className="my-2">
              {
                'To use WhatsApp for your business you have to apply for WhatsApp business API using'
              }
            </p>
            <div className="d-flex flex-row justify-content-start align-items-center w-100">
              <Form.Check // prettier-ignore
                inline
                type={'radio'}
                id={`radio-Owner`}
                label={`Direct Cheerio (recommended)`}
                style={{ marginInlineEnd: 20, padding: 5, marginLeft: '6%' }}
                checked={DirectCheerio}
                onChange={() => {
                  setDirectCheerio(true)
                  eventsend("Setup_via_cheerio_WhatsApp_setup_pop_up_Clicked");
                }}
              />
              {/* <Form.Check // prettier-ignore
                inline
                type={'radio'}
                id={`radio-Agent`}
                label={`via BSP`}
                style={{ marginInlineEnd: 20, padding: 5, marginLeft: '6%' }}
                checked={!DirectCheerio}
                onChange={() => {
                  setDirectCheerio(false)
                  eventsend("Setup_via_BSP_WhatsApp_setup_pop_up_Clicked");
                }}
              /> */}
            </div>
            <div
              className=""
              style={{
                height: 2,
                width: '60%',
                backgroundColor: colors.black,
                position: 'relative',
                top: 56,
              }}
            ></div>
            <div
              className="d-flex flex-row justify-content-between align-items-start w-75"
              style={{ marginBlockStart: 40, marginBlockEnd: 32 }}
            >
              <ProgressComponent
                text={'Apply for API'}
              // status={'success'}
              />
              <ProgressComponent
                text={'Phone no. verification'}
              // status={'pending'}
              />
              <ProgressComponent text={'FB manager verification'} />
              <ProgressComponent text={'Profile setup'} />
              <ProgressComponent text={'Apply for Green badge'} />
            </div>
            {/* <p style={{color:colors.black}} className='my-2'>
                    {'To use WhatsApp for your business you have to apply for WhatsApp business API'}
                </p> */}
            {/* <p className='my-2'>
                    {'Requirements'}<br/>
                    {`1. A Phone number that doesn't have WhatsApp App or WhatsApp Business App installed (Please DELETE if installed or buy a new number )`}<br/>
                    {`2. A Registered Business & working website`}
                </p> */}
            <div
              className="d-flex flex-row justify-content-start align-items-center w-100"
              style={{ marginBlockStart: 20 }}
            >
              <CheerioButton
                borderStyle={{ borderColor: colors.primary03 }}
                textStyle={{ color: colors.white }}
                btnText={'Apply for WhatsApp business API'}
                backColor={colors.primary03}
                icon={images.WhatsAppWhite}
                onclick={() => {
                  console.log('apply clicked . . .');
                  if (DirectCheerio) {
                    HandleSendMail();
                    onclickAction();
                  } else {
                    if (localdata?.embeddedSignupUrl) {
                      console.log('open embedded flow...');
                      openEmbedded();
                    } else {
                      onClickApply();
                    }
                  }
                }}
              />
              <CheerioButton
                borderStyle={{ borderColor: colors.primary03, marginInline: 16 }}
                textStyle={{ color: colors.primary03 }}
                btnText={'Schedule onboarding call'}
                backColor={colors.white01}
                icon={images.CalendarPrimary}
                onclick={() => {
                  console.log('schedule demo clicked . . .');
                  window.open('https://calendly.com/avinash-cheerio/30min', '_blank');
                }}
              />
            </div>
            <p style={{ color: colors.black }} className="my-2">
              {'Incase you already have existing WABA API. You can migrate to Cheerio. '}
              <span
                style={{ color: colors.linkblue, cursor: 'pointer' }}
                onClick={() => {
                  window.open('https://cheerio.freshdesk.com/support/tickets/new', '_blank');
                }}
              >
                {'Contact support'}
              </span>
            </p>
            {/* </>
                } */}
          </div>
        </div>
      </div>
    </>
  );
};

export default WAPreSetupModal;
