import React, { useEffect, useRef, useState } from 'react';
import colors from '../../../Utils/colors';
import images from '../../../Utils/images';
import CheerioButton from '../../../Components/CheerioButton';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import EventEmitter from '../../../Utils/EventEmitter';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { V2WorkflowS3Link } from '../../../Services';
import { getImagePath } from '../../../Utils';
import UploadValidator from '../../../Components/UploadValidator';
import UploaderText from '../../../Components/UploaderText';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { TextField, Tooltip } from '@mui/material';
import { getClientAttributes } from '../../../Services';
import AttributesModal from './AttributesModal';
import AdvancedOptions from '../Components/AdvancedOptions';

const FeedbackModal = ({ data, setData }) => {
  const [messageText, setMessageText] = useState('Your feedback matters! Please rate this chat on scale of 0-5');
  const [InputOptions, setInputOptions] = useState([
    { InputValue: '1' },
    { InputValue: '2' },
    { InputValue: '3' },
    { InputValue: '4' },
    { InputValue: '5' },
  ]);
  const [mediaType, setMediaType] = useState('Image');
  const [upFile, setUpFile] = useState();
  const [upFile64, setUpFile64] = useState('');
  const [upFileName, setUpFileName] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [s3Link, setS3Link] = useState('');
  const [dragActive, setDragActive] = useState(false);
  const [variable, setVariable] = useState('');
  const [skipReply, setSkipReply] = useState(false);
  const [allAttributes, setAllAttributes] = useState(["name", 'email', 'phone', 'createdAt', 'updatedAt']);
  const [openAttributes, setOpenAttributes] = useState(false);
  const [keyPressed, setKeyPressed] = useState('none');
  const [e, setE] = useState(null);
  const [IAFilterValue, setIAFilterValue] = useState('');
  const [sendTo, setSendTo] = useState(data?.toPhone ? data?.toPhone : '');

  const authtoken = useSelector((state) => state.main.auth_token);

  const upImageRef = useRef();
  const upVideoRef = useRef();
  const upDocRef = useRef();

  console.log("input value", InputOptions)

  const handlefileupload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      if (UploadValidator(file, mediaType)) {
        setUpFile(file);
        setUpFileName(file.name);
        const base64 = await convertToBase64(file);
        setUpFile64(base64);
      }
    } else {
      toast.error('Unexpected error occurred, please try again later.', {
        position: 'top-center',
      });
    }
  };

  console.log("feedback")
  useEffect(() => {
    if (messageText) {
      let currentWord = getWordAtCursor();
      if (currentWord[0] === '%' && currentWord?.length > 1) {
        setIAFilterValue(currentWord?.substring(1));
      } else {
        setIAFilterValue('');
      }
    }
  }, [messageText]);

  useEffect(() => {
    const indAttributesAPIcall = () => {
      let token = authtoken;
      getClientAttributes(token).then((res) => {
        if (res?.flag) {
          const attributes = res.data.map((item) => item.attribute);
          setAllAttributes([...allAttributes, ...attributes]);
        } else {
          // console.log("ind attributes err ---> ",res)
        }
      });
    };
    indAttributesAPIcall();
  }, []);

  const selectedAttributeAction = (value) => {
    const currentWord = getWordAtCursor();
    const wrappedValue = `%${value}%`;

    setMessageText((prev) => {
      let editedText = prev;

      if (!currentWord || prev.endsWith(currentWord)) {
        editedText = prev.replace(new RegExp(`${currentWord}$`), wrappedValue);
      } else {
        editedText = prev.replace(currentWord, wrappedValue);
      }

      return editedText;
    });

    document.getElementById('textInputAttr').focus();
    setOpenAttributes(false);
  };

  function getWordAtCursor() {
    const input = document.getElementById('textInputAttr');
    const cursorPosition = input.selectionStart;
    const text = input.value;

    let startIndex = cursorPosition;
    let endIndex = cursorPosition;

    while (startIndex > 0 && !isWordBoundary(text[startIndex - 1])) {
      startIndex--;
    }

    while (endIndex < text.length && !isWordBoundary(text[endIndex])) {
      endIndex++;
    }

    const currentWord = text.substring(startIndex, endIndex);
    return currentWord;
  }

  function isWordBoundary(char) {
    return char === ' ' || char === '\n';
  }

  const handleDragFile = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDropFile = async (e, type) => {
    e.preventDefault();
    e.stopPropagation();
    const file = e.dataTransfer.files[0];
    if (file && type) {
      if (UploadValidator(file, type)) {
        setUpFile(file);
        setUpFileName(file.name);
        const base64 = await convertToBase64(file);
        setUpFile64(base64);
        setImageUrl('');
      }
    } else {
      toast.error('Unexpected error occurred, please try again later.', {
        position: 'top-center',
      });
    }
  };

  const handleImageUrl = async (image) => {
    const url = image;
    if (url === '') {
      return;
    }
    if (upFileName) {
      toast.error('Delete uploaded image and try again', {
        position: 'top-center',
      });
      return;
    }
    // const regex = /^(https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&//=]*\.(?:png|jpg|jpeg|gif|bmp|svg)))$/i;
    // ;
    // console.log(url);
    // if (!regex.test(url)) {
    //   toast.error('Invalid image URL. Please check the URL and try again.', {
    //     position: 'top-center',
    //
    //   });
    //   return;
    // }
    setImageUrl(url);
    setUpFile(null);
    setUpFile64('');

    if (url) {
      try {
        const response = await fetch(url)
          .then((res) => {
            if (res.ok) {
              return res;
            } else {
              console.error('Error:', res.statusText);
            }
          })
          .catch((error) => {
            console.error('Error:', error);
          });
        const blob = await response.blob();
        const file = new File([blob], 'image_from_url.jpg', { type: blob.type });
        const base64 = await convertToBase64(file);
        setUpFile(file);
        setUpFile64(base64);
        setUpFileName(file.name);
      } catch (error) {
        toast.error('Not able to fetch image.Please check your url.', {
          position: 'top-center',
        });
      }
    }
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  useEffect(() => {
    if (upFile64) {
      getS3Link();
    }
  }, [upFile64]);

  const getS3Link = () => {
    let data = {
      image: upFile64,
      type: upFile?.type?.split('/')[0],
      format: upFile?.type?.split('/')[1],
    };
    let token = authtoken;
    toast.promise(
      V2WorkflowS3Link(data, token),
      {
        pending: 'Uploading file...',
        success: {
          render({ data }) {
            setS3Link(data?.data?.url);
            console.log('s3 link success response', data);
            return 'File uploaded successfully!';
          },
          icon: '🟢',
          autoClose: true,
          isLoading: false,
        },
        error: {
          render({ data }) {
            console.log('s3 link error response', data);
            return 'Error occurred while uploading file, please try again later.';
          },
          icon: '🔴',
          autoClose: true,
          isLoading: false,
        },
      },
      {
        position: 'top-center',
      }
    );
  };

  useEffect(() => {
    console.log('incoming edit data ===> ', data);
    if (data?.body?.message) {
      setMessageText(data?.body?.message);
    }
    if (data?.header?.mediaType) {
      setMediaType(data?.header?.mediaType);
    }
    if (data?.header?.mediaName) {
      setUpFileName(data?.header?.mediaName);
    }
    if (data?.header?.mediaFile) {
      setS3Link(data?.header?.mediaFile);
    }
    if (data?.variable) {
      setVariable(data?.variable);
    }
    if (data?.skip) {
      setSkipReply(data?.skip);
    }
  }, []);

  useEffect(() => {
    setData({
      ...data,
      header: {
        mediaType: mediaType,
        mediaName: upFileName,
        mediaFile: s3Link,
      },
      body: {
        message: messageText,
      },
      options: InputOptions.map((item) => ({
        InputValue: item.InputValue.trim(),
      })),
      variable: variable,
      skip: skipReply,
      toPhone: sendTo,
    });
  }, [messageText, InputOptions, mediaType, upFile, upFile64, s3Link, variable, skipReply, sendTo]);
  console.log('data ===> ', data);

  return (
    <>
      <div className="w-100 h-100 d-flex flex-column justify-content-start align-items-start">
        <p style={{
          color: colors.greys03,
          marginBottom: 16,
          marginTop: 8
        }}>{"Track your customer’s CSAT Score. Connect this node at the end of your WhatsApp Chat Flow"}</p>
        <p
          style={{
            padding: 0,
            margin: 0,
            marginBlock: 4,
            fontSize: 16,
            fontWeight: 600,
            color: colors.black,
            width: '100%',
          }}
        >
          {'Question'}
          <span style={{ color: colors.error03 }}>{'* (Mandatory)'}</span>
        </p>

        <InputGroup
          className="my-1"
          style={{
            borderBottom: `1px solid ${colors.borderwhite}`,
            paddingBlockEnd: 20,
            position: 'relative',
            zIndex: 1,
          }}
        >
          <Form.Control
            id="textInputAttr"
            required
            as="textarea"
            rows={4}
            placeholder={'Enter here'}
            className="w-100 btncustom"
            style={{ borderRadius: 8, fontSize: 14, fontWeight: 400, color: colors.black }}
            // isInvalid={(renewalText.length >= 640)}
            maxLength={640}
            value={messageText}
            onChange={(e) => {
              setMessageText(e.target.value);
            }}
            onKeyDown={(event) => {
              if (
                event.key === 'Backspace' &&
                messageText.charAt(event.target.selectionStart - 1) === '%'
              ) {
                setOpenAttributes(false);
              } else if (event.key === 'ArrowUp' && openAttributes) {
                event.preventDefault();
                setKeyPressed('Up');
              } else if (event.key === 'ArrowDown' && openAttributes) {
                event.preventDefault();
                setKeyPressed('Down');
              } else if (event.key === 'Tab' && openAttributes) {
                event.preventDefault();
                setKeyPressed('Tab');
              } else if (event.key === '%' && event.shiftKey) {
                setOpenAttributes(true);
              } else if (event.code === 'Space') {
                setOpenAttributes(false);
              } else if (event.key === 'Enter') {
                setOpenAttributes(false);
              }
              setE(event);
            }}
          />
        </InputGroup>

        {openAttributes && (
          <AttributesModal
            setOpenAttributes={setOpenAttributes}
            attributeObject={allAttributes}
            selectedAttributeAction={selectedAttributeAction}
            filterValue={IAFilterValue}
            keyPressed={keyPressed}
            setKeyPressed={setKeyPressed}
          />
        )}

        {InputOptions &&
          InputOptions.map((item, index) => {
            return (
              <>
                <div
                  key={`key==${index}`}
                  className="d-flex flex-column justify-content-start align-items-center w-100"
                >
                  <div className="d-flex flex-row justify-content-between align-items-center w-100">
                    <p
                      style={{
                        padding: 0,
                        margin: 0,
                        marginBlock: 4,
                        fontSize: 16,
                        fontWeight: 600,
                        color: colors.greys01,
                        width: '100%',
                      }}
                    >
                      {`Button`}
                    </p>

                  </div>

                  <InputGroup className="my-1">
                    <Form.Control
                      type="text"
                      placeholder={'Enter here'}
                      className="w-100 btncustom"
                      style={{
                        borderRadius: 8,
                        fontSize: 14,
                        fontWeight: 400,
                        color: colors.greys01,
                        backgroundColor: "#f8f9fa"
                      }}
                      // isInvalid={(renewalText.length >= 640)}
                      maxLength={640}
                      value={InputOptions[index].InputValue}
                      disabled={true}
                    />
                    {InputOptions[index].InputValue?.length > 20 && (
                      <p
                        style={{
                          fontSize: 12,
                          color: colors.red49,
                          margin: 0,
                          padding: 0,
                          width: '100%',
                          marginTop: 4,
                        }}
                      >
                        Buttons and Lists can only have 20 characters for each option. This node
                        will be converted to a text node.
                      </p>
                    )}
                  </InputGroup>
                </div>
              </>
            );
          })}



      </div>

    </>
  );
};

export default FeedbackModal;
