import React from 'react';
import colors from '../../../Utils/colors';
import { Handle, Position } from 'reactflow';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import images from '../../../Utils/images';
import CustomHandle from './CustomHandle';

const handleStyle = { backgroundColor: colors.success03, height: 15, width: 15, borderRadius: "100%" };

const WAPaymentNode = ({ data, isConnectable }) => {
    return (
        <>
            <div
                className="d-flex flex-column justify-content-between align-items-center"
                style={{
                    border: `1px solid ${colors.borderwhite}`,
                    borderRadius: 16,
                    backgroundColor: colors.white01,
                    width: 250,
                }}
            >
                <CustomHandle
                    type="target"
                    position={Position.Left}
                    isConnectable={isConnectable}
                    style={handleStyle}
                />

                <div
                    className="w-100 d-flex flex-column justify-content-start align-items-center"
                    style={{
                        paddingInline: 16,
                        paddingBlock: 8,
                        backgroundColor: '#B752C7',
                        borderTopLeftRadius: 16,
                        borderTopRightRadius: 16,
                    }}
                >
                    <div
                        style={{
                            height: 4,
                            width: '20%',
                            borderRadius: 2,
                            backgroundColor: colors.white,
                            marginBlockEnd: 4,
                        }}
                    ></div>
                    <div className="d-flex flex-row justify-content-between align-items-center w-100">
                        <p
                            style={{ padding: 0, margin: 0, fontSize: 16, fontWeight: 700, color: colors.white }}
                        >
                            {'Payment Message'}
                        </p>
                        <img
                            src={images.Edit}
                            style={{ height: 20, width: 20, objectFit: 'contain', cursor: 'pointer' }}
                        ></img>
                    </div>
                </div>

                <div
                    className="w-100 d-flex flex-column justify-content-start align-items-center"
                    style={{ paddingInline: 16, paddingBlockStart: 8, paddingBlockEnd: 16 }}
                >
                    <p
                        style={{
                            padding: 0,
                            margin: 0,
                            marginBlock: 4,
                            fontSize: 14,
                            fontWeight: 400,
                            color: colors.black,
                            width: '100%',
                        }}
                    >
                        {'Set Final Order Message'}
                    </p>
                </div>
                <div className="d-flex flex-column justify-content-start align-items-center w-100 py-2 px-2">
                    <div
                        key={`key=1`}
                        className="d-flex flex-row justify-content-start align-items-center w-100"
                        style={{
                            borderRadius: 8,
                            backgroundColor: colors.white03,
                            marginBlock: 8,
                            paddingBlock: 6,
                            paddingInline: 16,
                        }}
                    >
                        <p
                            style={{
                                padding: 0,
                                margin: 0,
                                fontSize: 12,
                                fontWeight: 600,
                                color: colors.black,
                            }}
                        >
                            {'Successful Payment'}
                        </p>
                        <CustomHandle
                            type="source"
                            // position={Position.Right}
                            position={Position.Right}
                            id={`child_node_0`}
                            style={{ ...handleStyle, top: 'auto', bottom: `calc( 0*16 )` }}
                            isConnectable={1}
                        />
                    </div>
                    <div
                        key={`key=2`}
                        className="d-flex flex-row justify-content-start align-items-center w-100"
                        style={{
                            borderRadius: 8,
                            backgroundColor: colors.white03,
                            marginBlock: 8,
                            paddingBlock: 6,
                            paddingInline: 16,
                        }}
                    >
                        <p
                            style={{
                                padding: 0,
                                margin: 0,
                                fontSize: 12,
                                fontWeight: 600,
                                color: colors.black,
                            }}
                        >
                            {'Payment Failure'}
                        </p>
                        <CustomHandle
                            type="source"
                            // position={Position.Right}
                            position={Position.Right}
                            id={`child_node_1`}
                            style={{ ...handleStyle, top: 'auto', backgroundColor: '#EB0000', bottom: `calc( 1*16 )` }}
                            isConnectable={1}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default WAPaymentNode;
