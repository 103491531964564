import { useEffect, useState } from 'react';
import { GoAlert } from 'react-icons/go';
import { FaListUl } from 'react-icons/fa';
import { IoCubeOutline } from 'react-icons/io5';
import { FiCheckCircle } from 'react-icons/fi';
import { getPayments, getUserApi } from '../../Services';
import { useSelector, useDispatch } from 'react-redux';
import { HiOutlineCreditCard } from 'react-icons/hi2';
import {
  connectCatalog,
  generateCatalogUrl,
  changeCatalogStatus,
  disconnectCatalog,
  syncCatalog,
  getWhatsappProducts
} from '../../Services';
import images from '../../Utils/images';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CiSettings } from 'react-icons/ci';
import { FaArrowRightLong } from 'react-icons/fa6';
import { GoLinkExternal } from 'react-icons/go';
import { setupWhatsappPayments } from '../../Services';
import SpinnerLoader from '../../Components/SpinnerLoader';
import CheerioButton from '../../Components/CheerioButton';
import colors from '../../Utils/colors';
import debounce from 'lodash/debounce';
import zIndex from '@mui/material/styles/zIndex';
import BetaFeatureBanner from '../../Components/BetaFeatureBanner';

export const Catalog = () => {
  const [catalogs, setCatalogs] = useState([]);
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const [isConnected, setIsConnected] = useState(false);
  const [isDisconnected, setIsDisconnected] = useState(false);
  const [selectedTab, setSelectedTab] = useState('catalogs');
  const [isCatalogConnected, setIsCatalogConnected] = useState(false);
  const localdata = useSelector((state) => state.main.localdata);
  const authtoken = useSelector((state) => state.main.auth_token);
  const [products, setProducts] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const ai_sensy_token = localdata?.whatsapp_credentials?.ai_sensy_token;
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [before, setBefore] = useState('');
  const [after, setAfter] = useState('');
  const [payments, setPayments] = useState([]);
  const navigate = useNavigate();
  const agentdata = useSelector((state) => state.main.agentdata);
  const [formData, setFormData] = useState({
    paymentConfig: localdata?.whatsapp_credentials?.paymentConfiguration ?? '',
    integrationChannel: localdata?.whatsapp_credentials?.paymentIntegrationType ?? 'Razorpay',
  });
  const [currentPaymentPage, setCurrentPaymentPage] = useState(1);
  const [rowsPerPaymentPage, setRowsPerPaymentPage] = useState(25);
  const [isLastPage, setIsLastPage] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState('');
  const [filteredPayments, setFilteredPayments] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [isFocused, setIsFocused] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [hasPrevPage, setHasPrevPage] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [currentId, setCurrentId] = useState(localdata?.whatsapp_credentials?.activeCatalogId || '');

  console.log(ai_sensy_token);
  console.log(localdata);

  const getuserapicall = () => {
    let token = authtoken;
    getUserApi(token).then((res) => {
      // console.log('get user api response ==> ', res);
      if (res?.flag) {
        dispatch({ type: 'addLocaldata', payload: res?.data });
      } else {
        navigate('/');
        toast.error(res?.message);
      }
    });
  };
  useEffect(() => {
    if (
      localdata?.aiSensyDetails?.embeddedCatalogURL &&
      localdata?.aiSensyDetails?.embeddedCatalogURL !== ''
    ) {
      fetch('https://backend.aisensy.com/direct-apis/t1/catalog', {
        headers: {
          Authorization: `Bearer ${ai_sensy_token}`,
          'Content-Type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.catalogs.length > 0) {
            if (!localdata?.aiSensyDetails?.catalogConnected) {
              changeCatalogStatus(authtoken, true).then((res) => {
                if (res?.flag) {
                  getuserapicall();
                } else {
                  navigate('/');
                  toast.error(res?.message);
                }
              });
            }
            setCatalogs(data?.catalogs);
            console.log(localdata);
            console.log(data);
          } else {
            if (localdata?.aiSensyDetails?.catalogConnected) {
              changeCatalogStatus(authtoken, false).then((res) => {
                if (res?.flag) {
                  getuserapicall();
                } else {
                  navigate('/');
                  toast.error(res?.message);
                }
              });
            }
          }
        })
        .catch((error) => {
          if (localdata?.aiSensyDetails?.catalogConnected) {
            changeCatalogStatus(authtoken, false).then((res) => {
              if (res?.flag) {
                getuserapicall();
              } else {
                navigate('/');
                toast.error(res?.message);
              }
            });
          }
          console.error('Error fetching data:', error);
        });
    }
  }, []);

  const saveAction = (formData) => {
    let token = authtoken;
    setupWhatsappPayments(formData, token).then((res) => {
      // console.log('get user api response ==> ', res);
      if (res?.flag) {
        toast.success('Payment Configured Successfully');
      } else {
        toast.error(res?.message);
      }
    });
  };

  useEffect(() => {
    localdata?.whatsapp_credentials?.activeCatalogId !== ''
      ? getProducts(localdata?.whatsapp_credentials?.activeCatalogId, 1, rowsPerPage)
      : '';
  }, []);

  const getProducts = async (catalogId, page = 1, limit = 25) => {
    try {
      const response = await getWhatsappProducts(
        {
          catalogId,
          page,
          limit
        },
        authtoken
      );

      if (response?.flag) {
        setProducts(response?.data?.docs || []);
        setFilteredProducts(response?.data?.docs || []);

        // If you need to handle pagination data
        setHasNextPage(response?.data?.page < response?.data?.pages);
        setHasPrevPage(page > 1);

        const filterOptions = response?.data?.docs.map((item) => item.currency);
        setSelectedCategory(filterOptions);
      } else {
        toast.error(response?.message || 'Error fetching products');
      }
    } catch (error) {
      toast.error('Error fetching products:', error);
    }
  };

  const handleRowsPerPageChange = (e) => {
    const newRowsPerPage = parseInt(e.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1);
    getProducts(currentId, 1, newRowsPerPage);
  };

  const handleSearch = debounce(async (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    setCurrentPage(1); // Reset to first page when searching

    try {
      const response = await getWhatsappProducts(
        {
          catalogId: currentId,
          page: 1,
          limit: rowsPerPage,
          searchQuery: query
        },
        authtoken
      );

      if (response?.flag) {
        setFilteredProducts(response?.data?.docs || []);
        setHasNextPage(response?.data?.page < response?.data?.pages);
        setHasPrevPage(response?.data?.page > 1);
      }
    } catch (error) {
      console.error('Error searching products:', error);
      toast.error('Failed to search products');
    }
  }, 300);

  const handlePaymentSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    filterPayments(paymentStatus, query);
  };

  console.log(filteredProducts);

  useEffect(() => {
    const connectionDone = localdata?.aiSensyDetails?.catalogConnected;
    const url =
      localdata?.aiSensyDetails?.embeddedCatalogURL === '' ||
        !localdata?.aiSensyDetails?.embeddedCatalogURL
        ? false
        : true;
    console.log(url);
    setIsConnected(connectionDone);
    setIsCatalogConnected(localdata?.aiSensyDetails?.catalogConnected);
  }, [localdata]);

  useEffect(() => {
    if (agentdata && !agentdata?.permission?.whatsappCatalog) {
      toast.error(
        'Feature access denied. Kindly request the owner to grant you permission to access this feature'
      );
      navigate('/creator');
    }
  }, []);
  console.log(localdata);

  const handleConnect = async (catalogId, authtoken) => {
    let token = authtoken;
    setIsLoading(true);
    connectCatalog(catalogId, token).then((res) => {
      // console.log('get user api response ==> ', res);
      if (res?.flag) {
        const data = res;
        getuserapicall();
        setIsLoading(false);
        console.log(data);
      } else {
        setIsLoading(false);
        toast.error(res?.message);
      }
    });
  };
  const handleDisconnect = async (catalogId, authtoken) => {
    let token = authtoken;
    setIsLoading(true);
    disconnectCatalog(catalogId, token).then((res) => {
      // console.log('get user api response ==> ', res);
      if (res?.flag) {
        getuserapicall();
        setIsLoading(false);
      } else {
        setIsLoading(false);
        toast.error(res?.message);
      }
    });
  };
  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);

  const handleSearchFocus = (e) => {
    e.target.style.borderColor = '#8E53F7';
  };

  const handleSearchBlur = (e) => {
    e.target.style.borderColor = '#E0E0E0';
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFilters = () => {
    let filtered = payments;

    // Filter by status if selected
    if (paymentStatus) {
      filtered = filtered.filter((payment) => payment.status === paymentStatus);
    }

    setFilteredPayments(filtered);
  };

  const filterPayments = (status, query) => {
    let filtered = payments;

    if (status) {
      filtered = filtered.filter((payment) => payment.status === status);
    }

    if (query) {
      filtered = filtered.filter(
        (payment) =>
          payment.referenceId.toLowerCase().includes(query.toLowerCase()) ||
          payment.status.toLowerCase().includes(query.toLowerCase()) ||
          (payment.transactionId &&
            payment.transactionId.toLowerCase().includes(query.toLowerCase())) ||
          payment.paymentType.toLowerCase().includes(query.toLowerCase()) ||
          payment.paymentMethod.toLowerCase().includes(query.toLowerCase())
      );
    }

    setFilteredPayments(filtered);
  };
  const handleStatusChange = (e) => {
    const newStatus = e.target.value;
    setPaymentStatus(newStatus);
    filterPayments(newStatus, searchQuery);
  };

  const handlePayments = async (page, limit) => {
    try {
      const response = await getPayments(authtoken, page, limit);

      if (response?.flag) {
        setPayments(response?.data?.docs);
        setFilteredPayments(response?.data?.docs);
        setHasPrevPage(response?.data?.hasPrevPage);
        setHasNextPage(response?.data?.hasNextPage); // Set filteredPayments initially
        setIsLastPage(!response?.data?.hasNextPage);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.error('Error fetching payment details:', error);
      toast.error('Failed to fetch payment details.');
    }
  };
  const CatalogUrl = () => {
    let token = authtoken;
    generateCatalogUrl(token).then((res) => {
      // console.log('get user api response ==> ', res);
      if (res?.flag) {
        window.open(res.data.embeddedCatalogUrl, '_blank');
        console.log(res.data.embeddedCatalogUrl);
      } else {
        toast.error(res?.message);
      }
    });
  };

  const syncCatalogs = () => {
    let token = authtoken;
    syncCatalog(token).then((res) => {
      // console.log('get user api response ==> ', res);
      if (res?.flag) {
        toast.success(res?.message);
      } else {
        toast.error(res?.message);
      }
    });
  };

  console.log(selectedCategory);
  console.log(isCatalogConnected);

  const handlePaymentPageChange = async (newPage) => {
    if (newPage < 1) return;

    await handlePayments(newPage, rowsPerPaymentPage);
    setCurrentPaymentPage(newPage);
  };

  const handlePreviousPaymentPage = () => handlePaymentPageChange(currentPaymentPage - 1);
  const handleNextPaymentPage = () => {
    if (!isLastPage) handlePaymentPageChange(currentPaymentPage + 1);
  };

  const handleRowsPerPaymentPageChange = async (event) => {
    const newRowsPerPaymentPage = parseInt(event.target.value, 10);
    setRowsPerPaymentPage(newRowsPerPaymentPage);
    setCurrentPaymentPage(1);
    await handlePayments(1, newRowsPerPaymentPage);
  };

  useEffect(() => {
    if (selectedTab === 'payments') {
      handlePayments(currentPaymentPage, rowsPerPaymentPage);
    }
  }, [selectedTab, currentPaymentPage, rowsPerPaymentPage]);



  function formatDate(dateString) {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
      timeZone: 'Asia/Kolkata',
      day: 'numeric',
      month: 'short',
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
    }) + ' IST';
  }

  const totalPages = Math.ceil(products?.length / rowsPerPage);

  const handlePageChange = (pageNumber) => {
    if (pageNumber < 1 || !hasNextPage && pageNumber > currentPage) {
      return;
    }
    getProducts(currentId, pageNumber, rowsPerPage);
    setCurrentPage(pageNumber);
  };

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };
  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center w-100 h-100">
        <SpinnerLoader />
      </div>
    );
  }
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
      }}
    >
      <div
        style={{
          backgroundColor: 'white',
          width: '15%',
          height: '101vh',
          display: 'flex',
          gap: 10,
          paddingTop: '2%',
          paddingBottom: '2%',
          paddingLeft: '1%',
          paddingRight: '1%',
          flexDirection: 'column',
          border: '1px solid #E6E6E6',
        }}
      >
        <button
          onClick={() => handleTabClick('catalogs')}
          style={{
            display: 'flex',
            gap: 10,
            border: 'none',
            paddingTop: '5%',
            paddingBottom: '5%',
            borderRadius: 8,
            backgroundColor: selectedTab === 'catalogs' ? '#F5F0FF' : 'white',
            color: selectedTab === 'catalogs' ? '#9357FF' : 'black',
            alignItems: 'center',
            paddingLeft: '3%',
          }}
        >
          <FaListUl
            style={{
              width: 14,
              height: 14,
              textAlign: 'center',
              paddingTop: 2,
            }}
          />
          <p
            style={{
              fontSize: 16,
              textAlign: 'center',
              fontWeight: 600,
            }}
          >
            Catalogs
          </p>
        </button>
        <button
          onClick={() => handleTabClick('products')}
          style={{
            display: 'flex',
            gap: 10,
            border: 'none',
            paddingTop: '5%',
            paddingBottom: '5%',
            borderRadius: 8,
            backgroundColor: selectedTab === 'products' ? '#F5F0FF' : 'white',
            color: selectedTab === 'products' ? '#9357FF' : 'black',
            alignItems: 'center',
            paddingLeft: '3%',
          }}
        >
          <IoCubeOutline
            style={{
              width: 18,
              height: 18,
              textAlign: 'center',
            }}
          />
          <p
            style={{
              fontSize: 16,
              textAlign: 'center',
              fontWeight: 600,
            }}
          >
            Products
          </p>
        </button>
        <button
          onClick={() => handleTabClick('payments')}
          style={{
            display: 'flex',
            gap: 10,
            border: 'none',
            paddingTop: '5%',
            paddingBottom: '5%',
            borderRadius: 8,
            backgroundColor: selectedTab === 'payments' ? '#F5F0FF' : 'white',
            color: selectedTab === 'payments' ? '#9357FF' : 'black',
            alignItems: 'center',
            paddingLeft: '3%',
          }}
        >
          <HiOutlineCreditCard
            style={{
              width: 18,
              height: 18,
              textAlign: 'center',
            }}
          />
          <p
            style={{
              fontSize: 16,
              textAlign: 'center',
              fontWeight: 600,
            }}
          >
            Payments
          </p>
        </button>
        <button
          onClick={() => handleTabClick('setup')}
          style={{
            display: 'flex',
            gap: 10,
            border: 'none',
            paddingTop: '5%',
            paddingBottom: '5%',
            borderRadius: 8,
            backgroundColor: selectedTab === 'setup' ? '#F5F0FF' : 'white',
            color: selectedTab === 'setup' ? '#9357FF' : 'black',
            alignItems: 'center',
            paddingLeft: '2%',
          }}
        >
          <CiSettings
            style={{
              width: 20,
              height: 20,
              textAlign: 'center',
            }}
          />
          <p
            style={{
              fontSize: 16,
              textAlign: 'center',
              fontWeight: 600,
            }}
          >
            Payment setup
          </p>
        </button>
      </div>

      <div
        style={{
          paddingLeft: '2%',
          paddingTop: '1%',
          width: '83%',
        }}
      >
        {selectedTab === 'catalogs' && (
          <>
            {/* Banner */}
            {/* <BetaFeatureBanner
              BannerText={
                'Catalogs may have been affected for your account. Please recheck and re-connect the catalog to share messages. Inconvenience is regretted.'
              }
            /> */}
            <div
              style={{
                display: 'flex',
              }}
            >
              <p
                style={{
                  fontWeight: 700,
                  fontSize: 16,
                  paddingRight: '1%',
                }}
              >
                Connect catalog on Cheerio AI
              </p>
              {isConnected === true ? (
                <div
                  style={{
                    backgroundColor: '#D6FFD6',
                    color: '#5CB85C',
                    borderRadius: 5,
                    fontSize: 16,
                    fontWeight: 400,
                    paddingLeft: '1%',
                    paddingRight: '1%',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <FiCheckCircle
                    style={{
                      width: 14,
                      height: 14,
                      color: '#5CB85C',
                      marginRight: '5px',
                    }}
                  />
                  Connected
                </div>
              ) : (
                <div
                  style={{
                    backgroundColor: '#FFDBDA',
                    color: '#D9534F',
                    borderRadius: 5,
                    fontSize: 16,
                    fontWeight: 400,
                    paddingLeft: '1%',
                    paddingRight: '1%',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <GoAlert
                    style={{
                      width: 14,
                      height: 14,
                      color: '#D9534F',
                      marginRight: '5px',
                    }}
                  />
                  Not Connected
                </div>
              )}
            </div>
            <p
              style={{
                paddingTop: '1%',
                fontWeight: 400,
                fontSize: 14,
                color: '#666666',
              }}
            >
              Here are all your catalogs connected to your WhatsApp business account. Please connect
              a catalog on Cheerio AI to share messages.
            </p>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <p
                style={{
                  paddingTop: '2%',
                  fontWeight: 600,
                  fontSize: 14,
                  color: '#666666',
                  paddingBottom: '2%',
                }}
              >
                NOTE: You can connect one catalog at a time
              </p>
              <button
                style={{
                  padding: '8px 16px',
                  backgroundColor: '#8E53F7',
                  color: 'white',
                  border: 'none',
                  borderRadius: '10px',
                  cursor: 'pointer',
                  fontSize: 16,
                }}
                onClick={() => syncCatalogs()}
              >
                Sync Catalog
              </button>
            </div>
            {isCatalogConnected === false && (
              <div
                style={{
                  textAlign: 'center',
                  marginTop: '2%',
                }}
              >
                <img
                  src={images.Catalog}
                  style={{
                    marginTop: '5%',
                  }}
                />
                <p
                  style={{
                    marginTop: '2%',
                    fontWeight: 600,
                    fontSize: 16,
                  }}
                >
                  You have no catalog connected to your WhatsApp Business account
                </p>
                <button
                  style={{
                    padding: '8px 16px',
                    backgroundColor: '#8E53F7',
                    color: 'white',
                    border: 'none',
                    borderRadius: '10px',
                    cursor: 'pointer',
                    fontSize: 16,
                    marginTop: '2%',
                  }}
                  onClick={() => {
                    if (localdata?.aiSensyDetails?.embeddedCatalogURL) {
                      window.open(localdata?.aiSensyDetails?.embeddedCatalogURL, '_blank');
                    } else {
                      CatalogUrl();
                    }
                  }}
                >
                  Connect Catalog
                </button>
              </div>
            )}

            {isCatalogConnected === true && (
              <table style={styles.table}>
                <thead>
                  <tr>
                    <th
                      style={{
                        fontWeight: 600,
                        fontSize: 14.38,
                        border: '1px solid #ddd',
                        padding: '8px 25px 8px 25px',
                        textAlign: 'left',
                        backgroundColor: '#f2f2f2',
                        width: '20%',
                      }}
                    >
                      Catalog Name
                    </th>
                    <th style={styles.th}>Catalog ID</th>
                    <th style={styles.th}>Connection status</th>
                    <th style={styles.th}>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {catalogs?.length > 0 &&
                    catalogs?.map((catalog, index) => (
                      <tr key={index}>
                        <td
                          style={{
                            fontWeight: 400,
                            fontSize: 14,
                            backgroundColor: 'white',
                            padding: '8px 25px 8px 25px',
                            textAlign: 'left',
                            width: '25%',
                          }}
                        >
                          {catalog.name}
                        </td>
                        <td style={styles.td}>{catalog.id}</td>
                        <td style={styles.td}>
                          {localdata?.whatsapp_credentials?.activeCatalogId !== catalog.id ? (
                            'Not Connected'
                          ) : (
                            <div
                              style={{
                                // width: '80%',
                                // display: 'flex',
                                // justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: '#D6FFD6',
                                color: '#5CB85C',
                                borderRadius: 5,
                              }}
                            >
                              <FiCheckCircle
                                style={{
                                  width: 14,
                                  height: 14,
                                  color: '#5CB85C',
                                  marginRight: '5px',
                                }}
                              />
                              Connected
                            </div>
                          )}
                        </td>
                        <td style={styles.td}>
                          {/* <button style={styles.button}
                                                onClick={() => {
                                                    getProducts(catalog.id);

                                                    handleTabClick('products');
                                                }
                                                }
                                            >View</button> */}
                          <button
                            disabled={
                              localdata?.whatsapp_credentials?.activeCatalogId !== catalog.id &&
                              localdata?.whatsapp_credentials?.activeCatalogId !== '' &&
                              localdata?.whatsapp_credentials?.activeCatalogId !== undefined
                            }
                            style={{
                              backgroundColor: 'white',
                              border: '1px solid #8E53F7',
                              color: '#8E53F7',
                              padding: '5px 15px',
                              marginRight: '8px',
                              cursor: 'pointer',
                              borderRadius: '8px',
                              opacity:
                                isConnected &&
                                  localdata?.whatsapp_credentials?.activeCatalogId !== catalog.id &&
                                  localdata?.whatsapp_credentials?.activeCatalogId !== '' &&
                                  localdata?.whatsapp_credentials?.activeCatalogId !== undefined
                                  ? '30%'
                                  : '100%',
                            }}
                            onClick={() => {
                              if (localdata?.whatsapp_credentials?.activeCatalogId === catalog.id) {
                                handleDisconnect(catalog.id, authtoken);
                                setFilteredProducts([]);
                              } else {
                                handleConnect(catalog.id, authtoken);
                                getProducts(catalog.id);
                              }
                            }}
                          >
                            {localdata?.whatsapp_credentials?.activeCatalogId !== catalog.id
                              ? 'Connect'
                              : 'Disconnect'}
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            )}
          </>
        )}

        {(selectedTab === 'products') && (
          <div style={styles2.container}>
            <div style={styles2.header}>
              <h1 style={styles2.headerTitle}>Products</h1>
              <p style={styles2.headerSubtitle}>
                Here are all your products in your connected catalog
              </p>
            </div>
            <div style={styles2.filters}>
              <input
                type="text"
                placeholder="Search product"
                onChange={handleSearch}
                style={{
                  ...styles2.search,
                  borderColor: '#E0E0E0',
                  outline: 'none',
                  transition: 'border-color 0.3s ease',
                }}
                onFocus={handleSearchFocus}
                onBlur={handleSearchBlur}
              />
              {/* <input type="number" placeholder="Price From" style={styles2.input} />
                            <input type="number" placeholder="Price to" style={styles2.input} /> */}
              {/* <button style={styles2.downloadButton}>Download</button> */}
            </div>
            {(isConnected === false || (filteredProducts.length === 0 && !searchQuery)) && (
              <div
                style={{
                  textAlign: 'center',
                  marginTop: '2%',
                }}
              >
                <img
                  src={images.CatalogProducts}
                  width="266"
                  height="283"
                  style={{
                    marginTop: '5%',
                  }}
                />
                <p
                  style={{
                    marginTop: '2%',
                    fontWeight: 600,
                    fontSize: 16,
                  }}
                >
                  Your catalog has no products to show. Try adding products to your catalog
                </p>
              </div>
            )}

            {(isConnected === true && (filteredProducts.length > 0 || searchQuery)) && (
              <div style={styles2.tableContainer}>
                <table style={styles2.table}>
                  <thead style={{ position: 'sticky', top: 0 }}>
                    <tr>
                      <th style={styles2.th}>Name</th>
                      <th style={styles2.th}>Image url</th>
                      <th style={styles2.th}>Retailer ID</th>
                      <th style={styles2.th}>Brand</th>
                      <th style={styles2.th}>Description</th>
                      <th style={styles2.th}>Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredProducts.map((product, index) => (
                      <tr key={index}>
                        <td style={styles2.td} title={product?.name}>
                          {truncateText(product.name, 15)}
                        </td>
                        <td style={styles2.td}>
                          <a
                            href={product?.image_url}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={styles2.link}
                          >
                            {truncateText(product.imageUrl, 15)}
                          </a>
                        </td>
                        <td style={styles2.td} title={product?.productRetailerId}>
                          {truncateText(product.productRetailerId, 15)}
                        </td>
                        <td style={styles2.td} title={product?.brand}>
                          {truncateText(product.brand, 15)}
                        </td>
                        <td style={styles2.td} title={product?.description}>
                          {truncateText(product.description, 15)}
                        </td>
                        <td style={styles2.td} title={product?.price}>
                          {truncateText(product.price, 15)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
            {filteredProducts?.length > 0 && (
              <div style={styles2.pagination}>
                <button
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={!hasPrevPage}
                  style={styles2.pageButton}
                >
                  <img
                    src={images.Left}
                    style={{
                      height: 24,
                      width: 24,
                      objectFit: 'contain',
                      cursor: 'pointer',
                      opacity: !hasPrevPage ? '30%' : '100%'
                    }}
                    alt="Previous"
                  />
                </button>
                <span style={styles2.pageInfo}>Page {currentPage}</span>
                <button
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={!hasNextPage}
                  style={styles2.pageButton}
                >
                  <img
                    src={images.Right}
                    style={{
                      height: 24,
                      width: 24,
                      objectFit: 'contain',
                      cursor: 'pointer',
                      opacity: !hasNextPage ? '30%' : '100%'
                    }}
                    alt="Next"
                  />
                </button>
                <div style={styles2.rowsPerPage}>
                  <span style={{ paddingRight: 5 }}>Rows per page: </span>
                  <select
                    value={rowsPerPage}
                    onChange={handleRowsPerPageChange}
                    style={styles2.select}
                  >
                    <option value={10}>10</option>
                    <option value={25}>25</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                  </select>
                </div>
              </div>
            )}
          </div>
        )}
        {selectedTab === 'payments' && (
          <div style={styles3.container}>
            {/* <BetaFeatureBanner
              BannerText={
                'Payment Setup may have been affected for your account. Please setup your payment setting and payment gateway again. Inconvenience is regretted'
              }
            /> */}
            <div style={styles3.header}>
              <div>
                <h1 style={styles3.headerTitle}>Payments</h1>
                <p style={styles3.headerSubtitle}>
                  Here are the details of all your orders from WhatsApp Commerce
                </p>
              </div>
              {/* <button
                                style={{ ...styles3.downloadButton, opacity: (localdata?.whatsapp_credentials?.activeCatalogId === '') ? '30%' : '100%' }}
                                disabled={localdata?.whatsapp_credentials?.activeCatalogId === "" ? true : false}
                            >
                                Download
                            </button> */}
            </div>
            <div style={styles3.filters}>
              <input
                type="text"
                placeholder="Search"
                style={{
                  ...styles3.search,
                  borderColor: '#E0E0E0',
                  outline: 'none',
                  transition: 'border-color 0.3s ease',
                }}
                onChange={handlePaymentSearch}
                onFocus={handleSearchFocus}
                onBlur={handleSearchBlur}
              />
              <select
                style={{
                  ...styles3.select, // Spread existing styles
                  borderColor: isFocused ? '#8E53F7' : styles3.select.borderColor, // Change border color on focus
                  outline: 'none', // Remove default focus outline
                  transition: 'border-color 0.3s ease',
                }}
                value={paymentStatus}
                onChange={handleStatusChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
              >
                <option value="">Select Status</option>
                <option value="Captured">Success</option>
                <option value="Pending">Pending</option>
                <option value="Expired">Expired</option>
              </select>
            </div>
            {localdata?.whatsapp_credentials?.activeCatalogId === '' ||
              !localdata?.whatsapp_credentials?.activeCatalogId ? (
              <div style={styles3.image}>
                <img src={images.RecieptLogo} alt="Payments" width="300" />
                <p style={{ fontWeight: 600, fontSize: 16, marginTop: '2%' }}>
                  All your orders payment details will be shown here
                </p>
              </div>
            ) : (
              <>
                <div style={styles2.tableContainer}>
                  <table style={styles2.table}>
                    <thead style={{ position: 'sticky', top: 0 }}>
                      <tr>
                        <th style={styles2.th}>Order ID</th>
                        <th style={styles2.th}>Order Date</th>
                        <th style={styles2.th}>Total Order Amount</th>
                        <th style={styles2.th}>Contact Name</th>
                        <th style={styles2.th}>Phone Number</th>
                        <th style={styles2.th}>Address</th>
                        <th style={styles2.th}>Product Name</th>
                        <th style={styles2.th}>Product ID</th>
                        <th style={{ ...styles2.th, minWidth: 30 }}>Quantity</th>
                        <th style={styles2.th}>Payment Status</th>
                        <th style={styles2.th}>Transaction ID</th>
                        <th style={styles2.th}>Payment Type</th>
                        <th style={styles2.th}>Payment Method</th>
                        <th style={styles2.th}>Cheerio Share</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredPayments.map((payment, index) => (
                        payment.products.map((product, productIndex) => (
                          <tr key={`${index}-${productIndex}`}>
                            <td style={styles2.td} title={payment?.referenceId}>
                              {productIndex === 0 ? truncateText(payment?.referenceId, 20) : ''}
                            </td>
                            <td style={styles2.td} title={payment?.createdAt}>
                              {productIndex === 0 ? truncateText(formatDate(payment?.createdAt), 20) : ''}
                            </td>
                            <td style={styles2.td} title={payment?.totalAmount}>
                              {productIndex === 0 ? `₹ ${truncateText((payment?.totalAmount / 100).toString(), 10)}` : ''}
                            </td>
                            <td style={styles2.td} title={payment?.contactName}>
                              {productIndex === 0 ? truncateText(payment?.contactName || '-', 15) : ''}
                            </td>
                            <td style={styles2.td} title={payment?.contactPhoneNumber}>
                              {productIndex === 0 ? truncateText(payment?.contactPhoneNumber || '-', 15) : ''}
                            </td>
                            <td style={styles2.td} title={
                              payment?.address ?
                                `${payment?.address?.line1}, ${payment?.address?.line2}, ${payment?.address?.city}, ${payment?.address?.state}, ${payment?.address?.pincode}` :
                                'No address provided'
                            }>
                              {productIndex === 0 ? (payment?.address ?
                                truncateText(`${payment.address.line1}, ${payment.address.city}`, 20) :
                                '-') : ''}
                            </td>
                            <td style={{ ...styles2.td, minWidth: 450 }} title={product?.name}>
                              {truncateText(product?.name, 50)}
                            </td>
                            <td style={styles2.td} title={product?.retailer_id}>
                              {product?.retailer_id}
                            </td>
                            <td style={{ ...styles2.td, minWidth: 30 }} title={product?.quantity}>
                              {product?.quantity}
                            </td>
                            <td style={{
                              ...styles2.td,
                              fontWeight: payment.status === 'Captured' ? 'bold' : 'normal'
                            }} title={payment?.status}>
                              {productIndex === 0 ? truncateText(payment.status, 15) : ''}
                            </td>
                            <td style={styles2.td} title={payment?.transactionId}>
                              {productIndex === 0 ? truncateText(payment.transactionId ?? '', 15) : ''}
                            </td>
                            <td style={styles2.td} title={payment?.paymentType}>
                              {productIndex === 0 ? truncateText(payment.paymentType.toUpperCase(), 15) : ''}
                            </td>
                            <td style={styles2.td} title={payment?.paymentMethod}>
                              {productIndex === 0 ? truncateText(payment.paymentMethod.toUpperCase(), 15) : ''}
                            </td>
                            <td style={styles2.td} title={payment?.cheerioShare}>
                              {productIndex === 0 ? truncateText(payment.cheerioShare, 15) : ''}
                            </td>
                          </tr>
                        ))
                      ))}
                    </tbody>
                  </table>
                </div>
                {payments?.length > 0 && (
                  <div style={styles2.pagination}>
                    <button
                      onClick={handlePreviousPaymentPage}
                      disabled={!hasPrevPage}
                      style={styles2.pageButton}
                    >
                      <img
                        src={images.Left}
                        style={{ height: 24, width: 24, objectFit: 'contain', cursor: 'pointer', opacity: !hasPrevPage ? '30%' : '100%' }}
                        alt="Previous"
                      />
                    </button>
                    <span style={styles2.pageInfo}>Page {currentPaymentPage}</span>
                    <button
                      onClick={handleNextPaymentPage}
                      disabled={!hasNextPage}
                      style={styles2.pageButton}
                    >
                      <img
                        src={images.Right}
                        style={{ height: 24, width: 24, objectFit: 'contain', cursor: 'pointer', opacity: !hasNextPage ? '30%' : '100%' }}
                        alt="Next"
                      />
                    </button>
                    <div style={styles2.rowsPerPage}>
                      <span style={{ paddingRight: 5 }}>Rows per page: </span>
                      <select
                        value={rowsPerPaymentPage}
                        onChange={handleRowsPerPaymentPageChange}
                        style={styles2.select}
                      >
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        )}
        {selectedTab === 'setup' && (
          <div style={{ padding: '20px' }}>
            {/* Informational Section */}
            {/* <BetaFeatureBanner
              BannerText={
                'Payment Setup may have been affected for your account. Please setup your payment setting and payment gateway again. Inconvenience is regretted'
              }
            /> */}
            <h2
              style={{
                paddingBottom: '2%',
              }}
            >
              Setup WhatsApp Payments
            </h2>
            <div
              style={{
                border: '1px solid #ccc',
                borderRadius: '16px',
                padding: '20px',
                marginBottom: '20px',
                backgroundColor: 'white',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <div>
                <p
                  style={{
                    fontWeight: 600,
                    fontSize: 16,
                    paddingBottom: '1%',
                  }}
                >
                  Setup to receive payments directly from WhatsApp
                </p>
                <p
                  style={{
                    fontWeight: 400,
                    fontSize: 16,
                    paddingBottom: '1%',
                    textAlign: 'left',
                    color: '#666666',
                  }}
                >
                  Now receive payments & track order directly from Cheerio AI dashboard itself.
                  Configure payment method & messages for your customers.
                </p>
                <p
                  style={{
                    fontWeight: 400,
                    fontSize: 16,
                    paddingBottom: '1%',
                    textAlign: 'left',
                    color: '#666666',
                  }}
                >
                  Check the guide below to setup payments on Cheerio
                </p>
                <a
                  href="https://www.cheerio.in/post/how-to-setup-whatsapp-catalog-payments-on-cheerio-ai"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    fontWeight: 600,
                    fontSize: 16,
                    paddingBottom: '1%',
                    paddingRight: '1%',
                    textAlign: 'left',
                    color: '#8E53F7',
                    textDecoration: 'none',
                  }}
                >
                  Read guide
                  <FaArrowRightLong
                    style={{
                      width: 20,
                      height: 20,
                      paddingLeft: 5,
                    }}
                  />
                </a>

                <p
                  style={{
                    paddingBottom: '1%',
                  }}
                >
                  Visit the link below to setup payments on Facebook business manager. <br />
                </p>
                <a
                  href="https://business.facebook.com/wa/manage/india"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    fontWeight: 600,
                    fontSize: 16,
                    textAlign: 'left',
                    color: '#8E53F7',
                    textDecoration: 'none',
                  }}
                >
                  <GoLinkExternal
                    style={{
                      width: 20,
                      height: 20,
                    }}
                  />
                  https://business.facebook.com
                </a>
              </div>
              <div>
                <img src={images.RecieptLogo} alt="Payments" width="300" />
              </div>
            </div>

            {/* Payment Details Section */}
            <div
              style={{
                border: '1px solid #ccc',
                borderRadius: '16px',
                padding: '20px',
                backgroundColor: 'white',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <div>
                <p
                  style={{
                    fontSize: 16,
                    fontWeight: 600,
                    paddingBottom: '3%',
                  }}
                >
                  Payment Details
                </p>
                <div style={{ marginBottom: '20px' }}>
                  <label
                    htmlFor="paymentConfig"
                    style={{ display: 'block', marginBottom: '5px', fontWeight: 700, fontSize: 14 }}
                  >
                    Payment Configuration
                  </label>
                  <input
                    type="text"
                    id="paymentConfig"
                    name="paymentConfig"
                    value={formData.paymentConfig}
                    onChange={handleChange}
                    placeholder="Enter payment configuration name"
                    style={{
                      width: '100%',
                      padding: '8px',
                      borderRadius: '8px',
                      border: '1px solid #ccc',
                    }}
                  />
                  <small>Refer to the screenshot to copy the Payment Configuration Name</small>
                </div>
                <div style={{ marginBottom: '20px' }}>
                  <label
                    htmlFor="integrationChannel"
                    style={{ display: 'block', marginBottom: '5px', fontWeight: 700, fontSize: 14 }}
                  >
                    Payment Gateway
                  </label>
                  <select
                    id="integrationChannel"
                    name="integrationChannel"
                    value={formData.integrationChannel}
                    onChange={handleChange}
                    placeholder="Select Gateway"
                    style={{
                      width: '100%',
                      padding: '8px',
                      borderRadius: '8px',
                      border: '1px solid #ccc',
                    }}
                  >
                    <option value="Razorpay">Razorpay</option>
                    <option value="PayU">PayU</option>
                  </select>
                </div>
                <div style={{ marginLeft: 10 }}>
                  <CheerioButton
                    borderStyle={{
                      width: '30%',
                      padding: '8px 16px',
                      border: 'none',
                      borderRadius: 10,
                      cursor: 'pointer',
                    }}
                    textStyle={{ color: colors.white03 }}
                    btnText={'Save Details'}
                    backColor={'#8E53F7'}
                    onclick={() => {
                      saveAction(formData);
                    }}
                  />
                </div>
                {/* <button style={styles2.downloadButton} onClick={() => saveAction(formData)}>Save Details</button> */}
              </div>
              <div>
                <img src={images.paymentSetup} alt="Payments" width="400" />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const styles = {
  container: {
    padding: '20px',
  },
  header: {
    color: '#FF0000', // Red color for the "Not connected" warning
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  td: {
    fontWeight: 400,
    fontSize: 14,
    backgroundColor: 'white',
    padding: '8px 25px 8px 25px',
    textAlign: 'center',
    width: '25%',
  },
  th: {
    fontWeight: 600,
    fontSize: 14.38,
    border: '1px solid #ddd',
    padding: '8px 25px 8px 25px',
    textAlign: 'center',
    backgroundColor: '#f2f2f2',
    width: '20%',
  },
  button: {
    backgroundColor: 'white',
    border: '1px solid #8E53F7',
    color: '#8E53F7',
    padding: '5px 15px',
    marginRight: '8px',
    cursor: 'pointer',
    borderRadius: '8px',
  },
};
const truncateText = (text, maxLength) => {
  if (text?.length > maxLength) {
    return text.substring(0, maxLength) + '...';
  }
  return text;
};
const styles2 = {
  container: {
    padding: '20px',
  },
  header: {
    marginBottom: '20px',
  },
  headerTitle: {
    fontSize: '24px',
    marginBottom: '5px',
  },
  headerSubtitle: {
    fontSize: '16px',
    color: '#666',
  },
  filters: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    marginBottom: '20px',
  },
  search: {
    padding: '10px',
    fontSize: '14px',
    border: '1px solid #ccc',
    borderRadius: '5px',
  },
  select: {
    fontSize: '14px',
    border: '1px solid #ccc',
    borderRadius: '5px',
  },
  input: {
    padding: '10px',
    fontSize: '14px',
    border: '1px solid #ccc',
    borderRadius: '5px',
  },
  downloadButton: {
    padding: '10px 20px',
    backgroundColor: '#8E53F7',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  tableContainer: {
    overflowX: 'auto',
    overflowY: 'auto',
    height: '70vh',
    scrollbarWidth: 'thin',
    scrollbarColor: '#e6e6e6 #fafafa',
  },
  tableContainerScrollbar: {
    width: '8px',
  },
  tableContainerScrollbarThumb: {
    borderRadius: '4px',
  },
  table: {
    width: '100%',
    minWidth: '1200px', // Ensure the table is wide enough for horizontal scroll
    borderCollapse: 'collapse',
    marginBottom: '20px',
    position: 'sticky',
    top: 0,
  },
  th: {
    padding: '10px',
    border: '1px solid #ddd',
    textAlign: 'left',
    backgroundColor: '#f2f2f2',
    fontWeight: 'bold',
    minWidth: '150px', // Ensure each column has a minimum width
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  td: {
    padding: '10px',
    border: '1px solid #ddd',
    textAlign: 'left',
    minWidth: '200px', // Ensure each column has a minimum width
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  link: {
    textDecoration: 'none',
    color: 'blue',
  },
  pagination: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  pageButton: {
    padding: '10px 15px',
    margin: '0 5px',
    border: 'none',
    backgroundColor: '#FAFAFA',
    cursor: 'pointer',
  },
  activePageButton: {
    backgroundColor: '#8E53F7',
    color: 'white',
    borderRadius: 5,
  },
  rowsPerPage: {
    display: 'flex',
    alignItems: 'center',
  },
  pageInfo: {
    fontSize: '14px',
    margin: '0 10px',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '200px',
  },
  pageButton: {
    padding: '10px 15px',
    margin: '0 5px',
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

const styles3 = {
  container: {
    padding: '20px',
  },
  header: {
    marginBottom: '2%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  headerTitle: {
    fontSize: '20px',
    fontWeight: 'bold',
  },
  headerSubtitle: {
    fontSize: '14px',
    color: '#757575',
  },
  filters: {
    display: 'flex',
    gap: 15,
    marginBottom: '2%',
  },
  search: {
    padding: '10px',
    border: '1px solid #E0E0E0',
    borderRadius: '8px',
    width: '30%',
  },
  select: {
    padding: '10px',
    border: '1px solid #E0E0E0',
    borderRadius: '8px',
    width: '15%',
    borderColor: '#E0E0E0',
    appearance: 'none', // Remove default arrow
    appearance: 'none', // Remove default arrow
    background: `url(${images.DownArrowTip}) no-repeat right 15px center, linear-gradient(to bottom, #fff, #f9f9f9)`, // Custom arrow icon with a gap from the right
    backgroundSize: '15px 15px, auto', // Adjust size of the custom arrow
  },
  input: {
    padding: '10px',
    border: '1px solid #E0E0E0',
    borderRadius: '5px',
    width: '15%',
  },
  downloadButton: {
    paddingInline: '30px',
    paddingTop: '2px',
    paddingBottom: '2px',
    backgroundColor: '#8E53F7',
    color: 'white',
    border: 'none',
    borderRadius: '10px',
    cursor: 'pointer',
    fontSize: 16,
  },
  image: {
    textAlign: 'center',
    marginTop: '2%',
  },
};
