import React, { useEffect, useState } from 'react';
import images from '../../Utils/images';
import { Modal } from 'react-bootstrap';
import { Autocomplete, TextField } from '@mui/material';
import './index.scss';
import CheerioButton from '../../Components/CheerioButton';
import { contactlabellistV2API, createSingleContact } from '../../Services';
import { useSelector } from 'react-redux';
import { options } from 'linkifyjs';
import { toast } from 'react-toastify';
import { eventsend } from '../../Config/analyticsFunctions';
import colors from '../../Utils/colors';

export const AddContactsManually = ({ show, setShow }) => {
  const [existing, setExisting] = useState(false);
  const [labelName, setLabelName] = useState('');
  const [details, setDetails] = useState({ name: '', mobile: '', email: '' });
  const [labelList, setLabelList] = useState([]);
  const [error, setError] = useState();

  const authtoken = useSelector((state) => state.main.auth_token);

  useEffect(() => {
    getlabellistapicall();
    eventsend("add_a_contact_Contacts_page_clicked");
  }, []);

  const createSingleContactLabelApiCall = () => {
    const payLoad = {
      labelName: labelName,
      data: {
        name: details.name,
        phone: details.mobile,
        email: details.email,
      },
    };
    createSingleContact(authtoken, payLoad).then((res) => {
      if (res?.flag) {
        toast.success(res?.message, { position: 'top-right' });
        setShow(false);
      } else {
        toast.error(res?.message, { position: 'top-right' });
        setShow(false);
      }
    });
  };

  const getlabellistapicall = () => {
    // let data = {
    //     user_id : _id,
    // };
    let token = authtoken;
    contactlabellistV2API(token).then((res) => {
      if (res.flag) {
        let allLabels = [
          ...res.data.v2.map((vls) => {
            return { label: vls.label_name };
          }),
        ];
        setLabelList(allLabels.filter((item)=>{
          return !(
            item?.label.includes('abandoned-cart-on') ||
            item?.label.includes('orders-feedback-on') ||
            item?.label.includes('orders-cancelled-on') ||
            item?.label.includes('Workflows -') ||
            item?.label.includes('Workflows-') ||
            item?.label.includes('WhatsApp Contacts who') ||
            item?.label.includes('orders-fulfilled-on') ||
            item?.label.includes('orders-placed-on') ||
            item?.label.includes('cod-confirmation-on')
          );
        }));
      } else {
        console.log('err===>', res);
      }
    });
  };

  return (
    <div>
      <Modal show={show} dialogClassName="contactModal">
        <Modal.Body>
          <div>
            <div className="d-flex justify-content-between " style={{ marginBottom: '2%' }}>
              <p style={{ fontWeight: '700', fontSize: '20px' }}>Add a contact </p>

              <div className="d-flex flex-row">
                <img
                  src={images.CMClose}
                  onClick={() => setShow(false)}
                  style={{
                    cursor: 'pointer',
                    height: 22,
                    width: 22,
                    marginInlineEnd: 8,
                  }}
                ></img>
              </div>
            </div>
            <div style={{}}>
              <div className="d-flex " style={{}}>
                <input
                  type="radio"
                  checked={!existing}
                  onChange={() => {
                    setExisting(!existing);
                    setLabelName('');
                  }}
                  style={{ width: '16px', height: '16px', marginRight: '6px', marginTop: '4px' }}
                />
                <label
                  style={{
                    fontSize: '16px',
                  }}
                >
                  Add contacts to new list
                </label>
                <span
                    className="mx-3"
                    style={{
                        color: labelName.length > 30 ? colors.error04 : colors.greys04,
                        fontSize: 12,
                        fontWeight: 400,
                        padding: 0,
                        margin: 0,
                    }}
                >
                    {`${labelName.length}/30`}
                </span>
              </div>
              {!existing && (
                <TextField
                  id="standard-basic"
                  variant="standard"
                  size="small"
                  placeholder={'Enter label name'}
                  style={{ marginLeft: '3%', marginTop: '4px' }}
                  inputProps={{
                    maxLength: 30,
                  }}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    // if (!newValue.match(/[%<>\\$'"\[\]]/)) {
                    // if (
                    //   !newValue.match(
                    //     /[\!\@\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\-]/g
                    //     ///^([A-Za-z0-9 _]+)$/
                    //   )
                    if (/^([A-Za-z0-9 _]+)$/.test(newValue)) {
                      let matchedLabel = labelList.filter((item) => {
                        return item.label === newValue;
                      });
                      console.log('matched', matchedLabel);
                      if (matchedLabel.length > 0) {
                        setError('Label already exist!');
                      } else {
                        matchedLabel = false;
                        setError('');
                      }
                    } else {
                      setError('Special characters are not allowed');
                    }
                    setLabelName(newValue);
                  }}
                  helperText={error}
                  error={!!error}
                />
              )}
              <div className="d-flex " style={{ marginTop: '2%' }}>
                <input
                  type="radio"
                  checked={existing}
                  onChange={() => {
                    setExisting(!existing);
                    setLabelName('');
                  }}
                  style={{ width: '16px', height: '16px', marginRight: '6px', marginTop: '4px' }}
                />
                <label
                  style={{
                    fontSize: '16px',
                  }}
                >
                  Add contacts to existing list
                </label>
              </div>
              {existing && (
                <Autocomplete
                  disableCloseOnSelect
                  id="size-small-standard"
                  size="small"
                  style={{ marginLeft: '3%', marginTop: '2%', width: '30%' }}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setLabelName(newValue.label);
                      setError('');
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      placeholder="Labels"
                      size="small"
                      inputProps={{ ...params.inputProps, style: { fontSize: '14px' } }}
                    />
                  )}
                  options={labelList}
                />
              )}
            </div>
            <div className="d-flex justify-content-between" style={{ marginTop: '5%' }}>
              <input
                class="form-control shadow-none"
                placeholder="Name"
                value={details.name}
                onChange={(e) => {
                  setDetails((prev) => {
                    return { ...prev, name: e.target.value };
                  });
                }}
              />
              <input
                class="form-control shadow-none"
                placeholder="91XXXXXXXXXX"
                style={{ marginLeft: '10px', marginRight: '10px' }}
                value={details.mobile}
                onChange={(e) => {
                  setDetails((prev) => {
                    return { ...prev, mobile: e.target.value };
                  });
                }}
              />
              <input
                class="form-control shadow-none"
                placeholder="Email"
                value={details.email}
                onChange={(e) => {
                  setDetails((prev) => {
                    return { ...prev, email: e.target.value };
                  });
                }}
              />
            </div>
            <div className="d-flex justify-content-end" style={{ marginTop: '10%' }}>
              <CheerioButton
                borderStyle={{
                  border: 'none',
                  width: '20%',
                  borderRadius: 8,
                }}
                textStyle={{
                  fontWeight: 600,
                  color: 'white',
                }}
                btnText={'Save Contact'}
                backColor={'#8E53F7'}
                onclick={() => {
                  // setIsWebhookOpen(true);
                  eventsend("Save_contact_upload_contacts_popup_clicked");
                  if (
                    error === '' &&
                    labelName !== '' &&
                    details.name !== '' &&
                    details.mobile !== '' &&
                    details.email !== ''
                  ) {
                    createSingleContactLabelApiCall();
                  } else {
                    toast.error('Please fill all the fields!', { position: 'top-center' });
                  }
                }}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
