import React, { useEffect } from 'react'
import colors from '../../../Utils/colors'
import SpinnerLoader from '../../../Components/SpinnerLoader'
import { useSelector } from 'react-redux';
import { verifyEmailSequences } from '../../../Services';
import CheerioButton from '../../../Components/CheerioButton';

const VerifyEmailModal = ({ labelId, onclickproceedaction, checkStatus, status, EV = false }) => {

    const authToken = useSelector((state) => state.main.auth_token);

    useEffect(() => {
        if (labelId) {
            checkStatus();
            // verifyEmailAPIcall();
        }
    }, [])

    const verifyEmailAPIcall = () => {
        let token = authToken;
        let data = {
            labelId: labelId,
            taskName: `Email Verification ${new Date()}`
        };
        verifyEmailSequences(data, token).then((res) => {
            if (res?.flag) {
                console.log("success response ---> ", res);
            } else {
                console.log("failure res ---> ", res);
            }
        })
        setTimeout(() => {
            onclickproceedaction();
        }, 1500);
    }

    return (
        <>
            <div className='DarkBg' onClick={() => { }}>
                <div className='Center' style={{ transform: 'translate(calc(-50% + 40px),-50%)' }}>
                    <div className='Column' style={{
                        justifyContent: 'space-between',
                        width: 600,
                        backgroundColor: colors.white01,
                        borderRadius: 16,
                        zIndex: 0,
                        border: `1px solid ${colors.borderwhite}`,
                        paddingInline: 40,
                        paddingBlock: 24,
                    }}>
                        <div className='Column justify-content-center w-100' style={{
                            paddingBlock: 20,
                        }}>
                            <SpinnerLoader />
                            <p className='my-2' style={{ fontWeight: 700 }}>
                                {'Verifying emails'}
                            </p>
                            <p className='my-2' style={{ textAlign: 'center' }}>
                                {'The verification process may take a little extra time. Feel free to create and initiate your sequence; only verified emails will be included in your list.'}
                            </p>
                        </div>
                        {/* <SpinnerLoader />
                <p>
                    {'Verifying emails, please wait'}
                </p>
                <p style={{color:colors.primary03,textDecorationLine:'underline',cursor:'pointer'}} onClick={()=>{onclickproceedaction()}}>
                    {'Skip this time'}
                </p> */}
                        <CheerioButton
                            btnText={EV === true ? 'Close' : 'Proceed to Sequence'}
                            textStyle={{ color: colors.white01 }}
                            backColor={colors.primary03}
                            borderStyle={{ borderColor: colors.primary03, width: '98%' }}
                            onclick={() => {
                                onclickproceedaction();
                            }}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default VerifyEmailModal