export function getLocaltime(params) {
  const gmtDate = new Date(params);
  const localDateTime = gmtDate.toLocaleString();
  const timeZoneAbbreviation = gmtDate.toLocaleTimeString(undefined, { timeZoneName: 'short' }).split(' ').pop();
  return `${localDateTime} ${timeZoneAbbreviation}`;
}

export function getLocalDatetime(params) {
  const gmtDate = new Date(params);
  const localDateTime = gmtDate.toLocaleString(undefined, { hour: '2-digit', minute: '2-digit', year: 'numeric', month: '2-digit', day: '2-digit' });
  //const timeZoneAbbreviation = gmtDate.toLocaleTimeString(undefined, { timeZoneName: 'short' }).split(' ').pop();
  return `${localDateTime}`;
}

export const getUserDateTimeSettings = () => {
  try {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return { timeZone: timeZone || 'UTC' };
  } catch (error) {
    console.error('Error getting timezone:', error);
    return { timeZone: 'UTC' };
  }
};

export function gettime(params) {
  if (!params) return '';
  const gmtDate = new Date(params);

  const istDate = new Date(gmtDate.toLocaleString('en-US', { timeZone: 'Asia/Kolkata' }));

  const day = String(istDate.getDate()).padStart(2, '0');
  const month = String(istDate.getMonth() + 1).padStart(2, '0');
  const year = String(istDate.getFullYear());

  const formattedDate = `${day}/${month}/${year}`;

  const localTime = istDate.toLocaleTimeString('en-US', {
    hour12: true,
    hour: '2-digit',
    minute: '2-digit',
  });

  const formattedDateTime = `${formattedDate} ${localTime}`;;

  return formattedDateTime;
}