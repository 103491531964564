import React from 'react';
import colors from '../Utils/colors';
import images from '../Utils/images';

const BetaFeatureBanner = ({ BannerText, optionalButton }) => {
  return (
    <div className="w-100 py-2 my-2">
      <div
        className="d-flex flex-row justify-content-between align-items-center w-100 py-3 px-3"
        style={{
          backgroundColor: colors.yellow03,
          borderRadius: 12,
          borderWidth: 1,
        }}
      >
        <img src={images.InfoCircle} style={{ height: 24, width: 24, objectFit: 'contain' }} />
        <div className="px-2" style={{ flex: 1 }}>
          <p
            style={{
              padding: 0,
              margin: 0,
              fontSize: 16,
              fontWeight: 400,
              color: colors.black,
              flexWrap: 'wrap',
            }}
          >
            {BannerText}
          </p>
        </div>

        {/* Optional Button */}
        {optionalButton && (
          <div className="ms-2">
            {optionalButton}
          </div>
        )}
      </div>
    </div>
  );
};

export default BetaFeatureBanner;
