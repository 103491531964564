import { useState } from 'react';
import { LabelColumnHeaderCell, RenderDiv } from '../Labels/variableDependencies';
import { AiOutlineDownload, AiOutlineEye } from 'react-icons/ai';
import { FiTrash2 } from 'react-icons/fi';
import { Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import EmailModal from '../Labels/components/EmailModal';

export const styledSx = {
  '&>.MuiDataGrid-main': {
    '&>.MuiDataGrid-columnHeaders': {
      borderBottom: 'none',
      background: '#F0F2F2',
    },
    '.MuiDataGrid-columnHeaderTitle': {
      fontWeight: '600',
    },
    '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
      borderRight: `1px solid #B3B3B3`,
    },
    '& div  >.MuiDataGrid-cell': {
      borderBottom: 'none',
      borderRight: '1px solid #B3B3B3',
      fontWeight: '400',
    },
  },
  '.MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel': {
    'margin-top': '1em',
    'margin-bottom': '1em',
  },
  '& .MuiDataGrid-columnHeader': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
  },
  '& .MuiDataGrid-cell': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  '& .MuiDataGrid-columnHeaderCheckbox': {
    margin: 0,
  },
};
export const TagsColumns = [
  {
    field: 'tags',
    headerName: 'Tag',
    flex: 1,
    align: 'left',
    headerAlign: 'center',
  },
  {
    field: 'contacts',
    headerName: 'Contacts',
    flex: 1,
    align: 'center',
    headerAlign: 'center',
  },
  {
    field: 'addedOn',
    headerName: 'Added On',
    flex: 1,
    align: 'center',
    headerAlign: 'center',
  },
  {
    field: 'action',
    headerName: 'Action',
    flex: 1,
    align: 'center',
    headerAlign: 'center',
    renderCell: (params) => {
      return <RenderNavigationPanel value={params.value} type={"tags"} params={params} />;
    },
  },
];
export const RenderNavigationPanel = ({ value, type, params }) => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  return (
    <>
      {show && <EmailModal show={show} setShow={setShow} type={type} reqId={params.row.id} />}
      <RenderDiv>
        <Tooltip title={'View'}>
          <div
            onClick={() => {
              navigate('view/' + value + '/tags');
            }}
          >
            <AiOutlineEye />
          </div>
        </Tooltip>
        <Tooltip title={'Download'}>
          <div>
            <AiOutlineDownload
              onClick={() => {
                setShow(state => !state);
              }}
            />
          </div>
        </Tooltip>

      </RenderDiv>
    </>
  );
};
