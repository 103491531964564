import React from "react";

const HelperVideos = ({ videos }) => {
    return (
        <div style={{ width: "100%" }}>
            <h3 style={{ fontSize: "18px", fontWeight: "bold", marginBottom: "10px" }}>
                Help Videos for you!
            </h3>
            <div
                style={{
                    display: "flex",
                    overflowX: "auto",
                    scrollBehavior: "smooth",
                    gap: "12px",
                    paddingBottom: "10px",
                }}
            >
                {videos.map((video, index) => {
                    let videoId = null;

                    try {
                        const url = new URL(video.link);
                        if (url.hostname.includes("youtube.com")) {
                            videoId = url.searchParams.get("v");
                        } else if (url.hostname.includes("youtu.be")) {
                            videoId = url.pathname.substring(1);
                        }
                    } catch (error) {
                        console.error("Invalid YouTube URL:", video.link);
                    }

                    return (
                        <div
                            key={index}
                            style={{
                                minWidth: "300px",
                                maxWidth: "350px",
                                borderRadius: "10px",
                                padding: "10px",
                            }}
                        >
                            {videoId ? (
                                <iframe
                                    width="100%"
                                    height="180"
                                    src={`https://www.youtube.com/embed/${videoId}`}
                                    title={video.title}
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                    style={{ borderRadius: "8px" }}
                                ></iframe>
                            ) : (
                                <p style={{ color: "red" }}>Invalid video link</p>
                            )}

                            <p style={{ fontSize: "14px", fontWeight: "500", marginTop: "6px" }}>
                                {video.title}
                            </p>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default HelperVideos;