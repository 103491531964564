import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import images from '../../../Utils/images';
import '../../ContactModule/index.scss';
import CheerioButton from '../../../Components/CheerioButton';
import { useDispatch, useSelector } from 'react-redux';
import { getAllAdAccounts, paginationAds, saveLeads } from '../../../Services';
import { toast } from 'react-toastify';
// import { triggerReRender } from '../../ContactModule/Labels/redux';
import { NewLoader } from '../../../Components/SpinnerLoader';

const Step1 = ({ acc, syncData, setSyncData, setAdsData }) => {
    useEffect(()=>{
        console.log("ACC",acc);
    },[])
    return (
        <>
            <p style={{ fontSize: '16px', fontWeight: '400', color: '#666666' }}>
                Choose an Ad account to sync lead
            </p>

            <div>
                {' '}
                {acc?.length > 0 ? (
                    <form
                        className="d-flex flex-row justify-content-start"
                        style={{
                            flexWrap: 'wrap',
                            height: '40vh',
                            overflowY: 'auto',
                            scrollbarWidth: 'thin',
                        }}
                        // defaultValue={syncData?.accId}
                        onChange={(e) => {
                            console.log('sel', e.target.value);
                            const ads = acc.filter((item) => item?.id === e.target.value);
                            if (ads) {
                                setAdsData(ads[0]?.ads);
                            }
                            setSyncData((prev) => {
                                return { ...prev, accId: e.target.value };
                            });
                        }}
                    >
                        {acc.map((a) => (
                            <div
                                className="d-flex "
                                style={{ marginTop: '3%', marginLeft: '2%', width: '30%', height: '10%' }}
                            >
                                <input
                                    type="radio"
                                    style={{ width: '16px', height: '16px', marginRight: '6px' }}
                                    id={a?.id}
                                    checked={syncData?.accId === a?.id}
                                    name={'group'}
                                    value={a?.id}
                                />
                                <label
                                    for={'som'}
                                    style={{
                                        marginTop: '-4px',
                                        fontSize: '16px',
                                        overflowX: 'auto',
                                        whiteSpace: 'nowrap',
                                        scrollbarWidth: 'none',
                                        overflowY: 'hidden',
                                    }}
                                >
                                    {a?.name}
                                </label>
                            </div>
                        ))}
                    </form>
                ) : (
                    <div>No, Ad accounts found.</div>
                )}
            </div>
        </>
    );
};

const Step2 = ({ acc, syncData, setSyncData }) => {
    return (
        <>
            <p style={{ fontSize: '16px', fontWeight: '400', color: '#666666' }}>
                Choose an Ad to sync lead
            </p>
            <div>
                <form
                    className="d-flex flex-row justify-content-start"
                    style={{
                        flexWrap: 'wrap',
                        alignContent: 'flex-start',
                        height: '40vh',
                        overflowY: 'auto',
                        scrollbarWidth: 'thin',
                    }}
                    onChange={(e) => {
                        console.log('add', e.target.value);
                        setSyncData((prev) => {
                            return { ...prev, adId: e.target.value, adName: e.target.name };
                        });
                    }}
                >
                    {acc.map((ad) => (
                        <div
                            className="d-flex "
                            style={{
                                marginTop: '3%',
                                marginLeft: '2%',
                                width: '30%',
                                height: '30px',
                            }}
                        >
                            <input
                                type="radio"
                                style={{ width: '16px', height: '16px', marginRight: '6px' }}
                                id={ad?.id}
                                checked={syncData?.adId === ad?.id}
                                name={ad?.name}
                                value={ad?.id}
                            />
                            <label
                                for={'som'}
                                style={{
                                    marginTop: '-4px',
                                    fontSize: '14px',
                                    overflowX: 'auto',
                                    whiteSpace: 'nowrap',
                                    scrollbarWidth: 'none',
                                    overflowY: 'hidden',
                                }}
                            >
                                {ad?.name}
                            </label>
                        </div>
                    ))}
                </form>
            </div>
        </>
    );
};

const Step3 = ({ ads, syncData, setSyncData, inputFields, setInputs }) => {
    const leads = ads.filter((ad) => ad?.id === syncData?.adId)[0]?.leads?.data[0]?.field_data;

    return leads.length > 0 ? (
        <div>
            <div className="d-flex justify-content-between">
                <p style={{ color: '#666666' }}>Cheerio Fields</p>

                <p style={{ border: '', width: '50%', color: '#666666' }}>Facebook Form Fields</p>
            </div>
            <div className="" style={{ flexWrap: 'wrap', border: '', width: '100%', overflowY: 'auto' }}>
                {inputFields.map((val, i) => (
                    <div
                        className="d-flex justify-content-between"
                        style={{ width: '100%', marginBottom: '10px' }}
                    >
                        <input
                            class="form-control shadow-none"
                            placeholder={'Enter Input'}
                            disabled={i <= 2}
                            style={{
                                width: '40%',
                                border: '1px solid grey',
                                marginTop: '5px',
                                borderRadius: '8px',
                            }}
                            value={val?.option}
                            onChange={(e) => {
                                const newVal = e.target.value;
                                let arr = [...inputFields];
                                let obj = arr[i];
                                obj.option = newVal;
                                arr[i] = obj;
                                setInputs([...arr]);
                            }}
                        />
                        <img
                            src={images.ArrowRight}
                            height={25}
                            width={25}
                            style={{ marginTop: '1%', border: '' }}
                        ></img>
                        <select
                            style={{
                                width: '50%',
                                border: '1px solid black',
                                boxShadow: 'none',
                                height: '10%',
                                marginTop: '5px',
                            }}
                            class="form-select"
                            aria-label="Default select example"
                            value={val?.value}
                            onChange={(e) => {
                                const newVal = e.target.value;
                                let arr = [...inputFields];
                                let obj = arr[i];
                                obj.value = newVal;
                                arr[i] = obj;
                                setInputs([...arr]);
                            }}
                        >
                            <option value={''} hidden>
                                Open this select menu
                            </option>
                            {leads.map((lead) => (
                                <option value={lead?.name}>{lead?.name}</option>
                            ))}
                        </select>
                        <div style={{ marginRight: i <= 1 ? '2%' : '' }}>
                            {i > 1 && (
                                <img
                                    src={images.Trash}
                                    style={{ marginTop: '15px', cursor: 'pointer' }}
                                    onClick={() => {
                                        setInputs((oldArray) => {
                                            return oldArray.filter((value, index) => index !== i);
                                        });
                                    }}
                                ></img>
                            )}{' '}
                        </div>
                    </div>
                ))}
                {inputFields.length < leads.length && (
                    <CheerioButton
                        borderStyle={{
                            border: '1px solid black',
                            width: '20%',
                            borderRadius: 8,
                        }}
                        textStyle={{
                            fontWeight: 600,
                            fontSize: '16px',
                            color: 'black',
                        }}
                        btnText={'Add a row'}
                        backColor={'white'}
                        icon={images.IconAdd}
                        onclick={() => {
                            setInputs((prev) => {
                                return [...prev, { option: '', value: '' }];
                            });
                            // setIsWebhookOpen(true);
                        }}
                    />
                )}
            </div>
        </div>
    ) : (
        <div>No leads found for the selected ad.</div>
    );
};

const Step4 = ({ syncData, setSyncData }) => {
    return (
        <div>
            <p style={{ fontWeight: '700' }}>Label Name</p>
            <input
                class="form-control shadow-none"
                placeholder={'Enter Label Name'}
                style={{
                    border: '1px solid grey',
                    borderRadius: '8px',
                }}
                value={syncData?.labelName}
                onChange={(e) => {
                    setSyncData((prev) => {
                        return { ...prev, labelName: e.target.value };
                    });
                }}
            />
        </div>
    );
};

export const SyncFBWorkflowModal = ({
    show,
    setShow,
    data,
    syncData,
    setSyncData,
    setData,
    before,
    after,
    setBefore,
    setAfter,
    onSubmit,
}) => {
    const [step, setStep] = useState(1);
    const [inputs, setInputs] = useState([
        { option: 'Name', value: '' },
        { option: 'Phone', value: '' },
        { option: 'Email', value: '' },
        { option: 'Company', value: '' },
    ]);
    const [success, setSuccess] = useState(false);
    const authToken = useSelector((state) => state.main.auth_token);
    const [adsData, setAdsData] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();

    console.log('f', before);
    const fetchData = (af, bef) => {
        if (step === 1) {
            let data = {
                key: '',
                value: '',
                pixel: 'false'
            };
            if (af === '') {
                data.key = 'before';
                data.value = bef;
            } else {
                data.key = 'after';
                data.value = af;
            }
            console.log('d', data);
            //api for account pagination
            getAllAdAccounts(authToken, data).then((res) => {
                if (res?.flag) {
                    setData(res?.data?.adaccounts ?? res?.data);
                    setBefore(res?.data?.adaccounts?.paging?.cursors?.before ?? res?.data?.paging?.cursors?.before);
                    setAfter(res?.data?.adaccounts?.paging?.cursors?.after ?? res?.data?.paging?.cursors?.after);
                    setSyncData((prev) => {
                        return { ...prev, accId: '' };
                    });
                } else {
                    toast.error(res?.message, { position: 'top-center' });
                }
            });
        } else if (step === 2) {
            let data = {
                adAccountId: syncData?.accId,
                key: '',
                value: '',
            };
            if (af === '') {
                data.key = 'before/';
                data.value = bef;
            } else {
                data.key = 'after/';
                data.value = af;
            }
            // const data = {
            //   adAccountId: syncData?.accId,
            //   after: af,
            //   before: bef,
            // };
            //api for ads pagination
            paginationAds(authToken, data).then((res) => {
                if (res?.flag) {
                    setAdsData(res?.data);
                    setBefore(res?.data?.paging?.cursors?.before);
                    setAfter(res?.data?.paging?.cursors?.after);
                    setSyncData((prev) => {
                        return { ...prev, adId: '' };
                    });
                } else {
                    toast.error(res?.message, { position: 'top-center' });
                }
            });
            //setAdsData();
        }
    };
    const saveLeadsApiCall = () => {
        setIsLoading(true);
        const map = {};
        inputs.forEach((item) => {
            map[item?.option.toLowerCase()] = item?.value;
        });
        const data = { ...syncData, mapping: map, label: "facebookLead Node" };
        saveLeads(authToken, data).then((res) => {
            if (res?.flag) {
                setIsLoading(false);
                setSuccess(true);
                onSubmit(data);
                // dispatch(triggerReRender());
            } else {
                //setStep((prev) => prev - 1);
                setIsLoading(false);
                toast.error(res?.message, { position: 'top-center' });
            }
        });
    };

    return (
        <div>
            <Modal show={show} dialogClassName="fbModal">
                <Modal.Body style={{ padding: '20px' }}>
                    <div className="d-flex justify-content-between ">
                        <div className="d-flex">
                            {step > 1 && !success && (
                                <img
                                    src={images.BackArrowBlack}
                                    height={20}
                                    width={20}
                                    style={{ marginRight: '10px', marginTop: '5px', cursor: 'pointer' }}
                                    onClick={() => {
                                        setStep((prev) => {
                                            if (prev === 2) {
                                                setBefore(data?.paging?.cursors?.before);
                                                setAfter(data?.paging?.cursors?.after);
                                            }
                                            return prev - 1;
                                        });
                                    }}
                                ></img>
                            )}
                            <p style={{ fontWeight: '700', fontSize: '20px' }}>
                                {step === 3 ? 'Map your data' : 'Sync from Facebook'}
                            </p>
                        </div>

                        <div className="d-flex flex-row">
                            <img
                                src={images.CMClose}
                                onClick={(e) => {
                                    setShow(false);
                                    setStep(1);
                                    setSyncData({
                                        accId: '',
                                        adId: '',
                                        labelName: '',
                                    });
                                    setInputs([
                                        { option: 'Name', value: '' },
                                        { option: 'Phone', value: '' },
                                        { option: 'Email', value: '' },
                                        { option: 'Company', value: '' },
                                    ]);
                                    e.stopPropagation();
                                }}
                                style={{
                                    cursor: 'pointer',
                                    height: 22,
                                    width: 22,
                                    marginInlineEnd: 8,
                                }}
                            ></img>
                        </div>
                    </div>
                    {isLoading ? (
                        <div
                            className="d-flex flex-column align-items-center"
                            style={{ marginTop: '10%', height: '100%', width: '100%', overflowY: 'hidden' }}
                        >
                            <NewLoader></NewLoader>

                            <p style={{ fontWeight: '700', fontSize: '18px' }}>Processing your contacts</p>
                            <p style={{ fontSize: '12px', color: 'grey', marginTop: '1%', width: '50%' }}>
                                <i>
                                    {' '}
                                    Please Note: In case of large contacts may take some time to sync contacts and
                                    reflect the changes. We appreciate your patience!
                                </i>
                            </p>
                        </div>
                    ) : success ? (
                        <div className="d-flex align-items-center flex-column" style={{ marginTop: '50px' }}>
                            <img
                                src={images.CircleCheckGreen}
                                style={{
                                    alignItems: 'center',
                                    marginInlineEnd: 8,
                                }}
                            ></img>
                            <p style={{ fontWeight: '700', fontSize: '16px', marginTop: '20px' }}>
                                Facebook leads Synced{' '}
                            </p>
                            <p style={{ fontSize: '14px', color: 'grey', marginTop: '' }}>New contacts added </p>
                            <p style={{ fontSize: '12px', color: 'grey', marginTop: '1%', width: '50%' }}>
                                <i>
                                    {' '}
                                    Please Note: In case of large contacts may take some time to sync contacts and
                                    reflect the changes. We appreciate your patience!
                                </i>
                            </p>
                        </div>
                    ) : (
                        <>
                            <div className="d-flex justify-content-center" style={{ marginTop: '2%' }}>
                                <img
                                    height={18}
                                    width={18}
                                    style={{ marginTop: '6px' }}
                                    src={step > 1 ? images.CircleCheck : images.CircleUncheck}
                                ></img>
                                <hr style={{ height: '1px', backgroundColor: 'black', width: '30%' }}></hr>
                                <img
                                    height={18}
                                    width={18}
                                    style={{ marginTop: '6px' }}
                                    src={step > 2 ? images.CircleCheck : images.CircleUncheck}
                                ></img>
                                <hr style={{ height: '1px', backgroundColor: 'black', width: '30%' }}></hr>
                                <img
                                    height={18}
                                    width={18}
                                    style={{ marginTop: '6px' }}
                                    src={step > 3 ? images.CircleCheck : images.CircleUncheck}
                                ></img>
                            </div>
                            <div className="d-flex justify-content-center" style={{ marginTop: '-8px' }}>
                                <p style={{ fontSize: '14px', fontWeight: '400', color: '#666666' }}>Ad Account</p>
                                <hr
                                    style={{ height: '1px', color: 'white', backgroundColor: 'white', width: '27%' }}
                                ></hr>
                                <p style={{ fontSize: '14px', fontWeight: '400', color: '#666666' }}>Ad Name</p>

                                <hr
                                    style={{ height: '1px', color: 'white', backgroundColor: 'white', width: '26%' }}
                                ></hr>
                                <p style={{ fontSize: '14px', fontWeight: '400', color: '#666666' }}>Mapping</p>
                            </div>
                            <hr style={{ marginTop: '' }}></hr>
                            <div>
                                {step === 1 && (
                                    <Step1
                                        acc={data?.data}
                                        setAdsData={setAdsData}
                                        syncData={syncData}
                                        setSyncData={setSyncData}
                                    ></Step1>
                                )}
                                {step === 2 && (
                                    <Step2 acc={adsData?.data} syncData={syncData} setSyncData={setSyncData}></Step2>
                                )}
                                {step === 3 && (
                                    <Step3
                                        ads={adsData?.data}
                                        syncData={syncData}
                                        setSyncData={setSyncData}
                                        inputFields={inputs}
                                        setInputs={setInputs}
                                    ></Step3>
                                )}
                                {step === 4 && <Step4 syncData={syncData} setSyncData={setSyncData}></Step4>}

                                {step < 3 && data?.data?.length > 0 && (
                                    <div className="d-flex justify-content-center" style={{ marginTop: '2%' }}>
                                        {step === 1
                                            ? (data?.paging?.previous ?? data?.data?.paging?.previous) && (
                                                <img
                                                    src={images.Left}
                                                    height={20}
                                                    width={20}
                                                    style={{ cursor: 'pointer', marginRight: '10px' }}
                                                    onClick={() => {
                                                        const af = '';
                                                        const bef = before;
                                                        fetchData(af, bef);
                                                    }}
                                                ></img>
                                            )
                                            : adsData?.paging?.previous && (
                                                <img
                                                    src={images.Left}
                                                    height={20}
                                                    width={20}
                                                    style={{ cursor: 'pointer', marginRight: '10px' }}
                                                    onClick={() => {
                                                        const af = '';
                                                        const bef = before;
                                                        fetchData(af, bef);
                                                    }}
                                                ></img>
                                            )}
                                        {step === 1
                                            ? (data?.paging?.next ?? data?.data?.paging?.next) && (
                                                <img
                                                    src={images.Right}
                                                    height={20}
                                                    width={20}
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => {
                                                        const af = after;
                                                        const bef = '';
                                                        fetchData(af, bef);
                                                    }}
                                                ></img>
                                            )
                                            : adsData?.paging?.next && (
                                                <img
                                                    src={images.Right}
                                                    height={20}
                                                    width={20}
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => {
                                                        const af = after;
                                                        const bef = '';
                                                        fetchData(af, bef);
                                                    }}
                                                ></img>
                                            )}
                                    </div>
                                )}
                                <div className="d-flex justify-content-center" style={{ marginTop: '2%' }}>
                                    {step < 4 ? (
                                        <CheerioButton
                                            borderStyle={{
                                                border: 'none',
                                                width: '20%',
                                                borderRadius: 8,
                                            }}
                                            textStyle={{
                                                fontWeight: 600,
                                                color: 'white',
                                            }}
                                            btnText={'Next'}
                                            backColor={'#8E53F7'}
                                            onclick={() => {
                                                if (step === 1) {
                                                    if (syncData?.accId !== '') {
                                                        if (adsData?.data.length > 0) {
                                                            setStep((prev) => prev + 1);
                                                            setBefore(adsData?.paging?.cursors?.before);
                                                            setAfter(adsData?.paging?.cursors?.after);
                                                        } else {
                                                            toast.error('No,ads found! Please select an account with ads', {
                                                                position: 'top-center',
                                                            });
                                                        }
                                                    } else {
                                                        toast.error('Please select an option', { position: 'top-center' });
                                                    }
                                                } else if (step === 2) {
                                                    if (syncData?.adId !== '') {
                                                        if (
                                                            adsData?.data.filter((ad) => ad?.id === syncData?.adId)[0]?.leads
                                                                ?.data[0]?.field_data.length > 0
                                                        ) {
                                                            setStep((prev) => prev + 1);
                                                        } else {
                                                            toast.error('No leads exist for selected ad!', {
                                                                position: 'top-center',
                                                            });
                                                        }
                                                    } else {
                                                        toast.error('Please select an option', { position: 'top-center' });
                                                    }
                                                }
                                                if (step === 3) {
                                                    let check = true;
                                                    inputs.forEach((item) => {
                                                        if (item?.option === '' || item?.value === '') {
                                                            check = false;
                                                        }
                                                    });
                                                    if (check) {
                                                        setStep((prev) => prev + 1);
                                                    } else {
                                                        toast.error('Please fill all fields!', { position: 'top-center' });
                                                    }
                                                }
                                                // setIsWebhookOpen(true);
                                            }}
                                        />
                                    ) : (
                                        <CheerioButton
                                            borderStyle={{
                                                border: 'none',
                                                width: '40%',
                                                borderRadius: 8,
                                            }}
                                            textStyle={{
                                                fontWeight: 600,
                                                color: 'white',
                                            }}
                                            btnText={'Save & Sync'}
                                            backColor={'#8E53F7'}
                                            onclick={() => {
                                                if (syncData?.labelName !== '') {
                                                    saveLeadsApiCall();
                                                } else {
                                                    toast.error('Please enter label name', { position: 'top-center' });
                                                }
                                                // setIsWebhookOpen(true);
                                            }}
                                        />
                                    )}
                                </div>
                            </div>
                        </>
                    )}
                </Modal.Body>
            </Modal>
        </div>
    );
};
