import React from 'react';
import colors from '../../../Utils/colors';
import { IoIosArrowForward } from 'react-icons/io';
import images from '../../../Utils/images';
import { CiClock2 } from 'react-icons/ci';
import { Tooltip } from '@mui/material';

const AppSumoAccodians = ({
    ItemImage,
    ItemHeader,
    ItemText,
    ActionText,
    onclickAction,
    completed = false,
    InfoTooltip = false,
}) => {
    const isMobile = window.innerWidth < 700;

    return (
        <div
            className=" me-3 d-flex flex-column justify-content-between"
            style={{
                width: isMobile ? '100%' : '330px', // Wider card
                minWidth: '300px',
                border: "1px solid #F0F0F0",
                borderRadius: '12px',
                backgroundColor: "#FAFAFA",
                padding: '16px',
                marginBottom: isMobile ? 12 : 8,
            }}
        >
            {/* Header and Image */}
            <div className="d-flex align-items-start">

                <div className="d-flex flex-column" style={{ flex: 1 }}>
                    <h4
                        style={{
                            color: colors.black,
                            fontSize: '18px',
                            fontWeight: 'bold',
                            marginBottom: '6px',
                        }}
                    >
                        {ItemHeader}
                    </h4>
                    <p
                        style={{
                            color: colors.greys04,
                            fontSize: '14px',
                            marginBottom: '10px',
                            lineHeight: '1.4',
                        }}
                    >
                        {ItemText}
                    </p>
                </div>
                <img
                    className="me-2"
                    src={ItemImage}
                    alt={ItemHeader}
                    style={{ height: 30, width: 30, objectFit: 'contain' }}
                />
                {InfoTooltip && (
                    <Tooltip title={'Onboarding support call recommended'}>
                        <img
                            src={images.InfoCircleGrey}
                            alt="Info"
                            style={{
                                height: 18,
                                width: 18,
                                objectFit: 'contain',
                                cursor: 'pointer',
                            }}
                        />
                    </Tooltip>
                )}
            </div>

            {/* Action Button */}
            <div
                className="mt-auto"
                style={{
                    cursor: completed ? 'default' : 'pointer',
                    marginTop: '15px',
                }}
                onClick={() => !completed && onclickAction()}
            >
                {ItemHeader === 'SMS' ? (
                    completed === 'pending' ? (
                        <div className="d-flex align-items-center justify-content-center w-100"
                            style={{
                                background: '#FEAF52',
                                borderRadius: '8px',
                                padding: '10px',
                                color: '#fff',
                                fontWeight: 'bold',
                            }}
                        >
                            <CiClock2 style={{ marginRight: '8px', fontSize: '18px' }} />
                            Request Processing
                        </div>
                    ) : completed === 'completed' ? (
                        <div className="d-flex align-items-center justify-content-center w-100"
                            style={{
                                background: colors.success03,
                                borderRadius: '8px',
                                padding: '10px',
                                color: '#fff',
                                fontWeight: 'bold',
                            }}
                        >
                            <p className="mb-0">Completed</p>
                            <img
                                src={images.SUCCESSFULL}
                                alt="Success"
                                style={{ height: 20, width: 20, marginLeft: '8px' }}
                            />
                        </div>
                    ) : (
                        <button
                            style={{
                                width: '100%',
                                backgroundColor: colors.themeDarkBlue,
                                color: 'white',
                                fontSize: '14px',
                                padding: '12px',
                                border: 'none',
                                borderRadius: '8px',
                                fontWeight: 'bold',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            {ActionText}
                            <IoIosArrowForward style={{ marginLeft: '8px' }} />
                        </button>
                    )
                ) : completed ? (
                    <div className="d-flex align-items-center justify-content-center w-100"
                        style={{
                            background: colors.success03,
                            borderRadius: '8px',
                            padding: '10px',
                            color: '#fff',
                            fontWeight: 'bold',
                        }}
                    >
                        <p className="mb-0">Completed</p>
                        <img
                            src={images.SUCCESSFULL}
                            alt="Success"
                            style={{ height: 20, width: 20, marginLeft: '8px' }}
                        />
                    </div>
                ) : (
                    <button
                        style={{
                            width: '100%',
                            color: 'white',
                            fontSize: '14px',
                            padding: '12px',
                            border: `1px solid ${colors.themeDarkBlue}`,
                            backgroundColor: colors.white,
                            borderRadius: '8px',
                            fontWeight: '500',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            color: colors.themeDarkBlue,
                        }}
                    >
                        {ActionText}
                        <IoIosArrowForward style={{ marginLeft: '8px' }} />
                    </button>
                )}
            </div>
        </div>
    );
};

export default AppSumoAccodians;
