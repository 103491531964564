// CreateSequencePage.jsx
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoArrowBackOutline } from "react-icons/io5";
import { FiSettings } from "react-icons/fi";
import { Autocomplete, TextField, Box } from '@mui/material';
import images from '../../../Utils/images';
import { contactlabellistWithoutCountAPI, uploadLinkedinCSV } from '../../../Services';
import { useSelector } from 'react-redux';
import { useRef } from 'react';
import { Link } from '@mui/material';
import { toast } from 'react-toastify';
import { Stepper, Step, StepLabel } from '@mui/material';
// import { CampaignSetup } from './CampaignSetup';
import Flow from './Flow';
import { eventsend } from '../../../Config/analyticsFunctions';


const CreateSequencePage = () => {
  const navigate = useNavigate();
  const [campaignName, setCampaignName] = useState('');
  const [selectedOption, setSelectedOption] = useState('');
  const [labelName, setLabelName] = useState('');
  const [labelList, setLabelList] = useState([]); // You'll need to populate this with your label data
  const localdata = useSelector((state) => state.main.localdata);
  const authtoken = useSelector((state) => state.main.auth_token);
  const [CSVfile, setCSVfile] = useState(null);
  const [fileUpload, setFileUpload] = useState(false);
  const [countOfProgress, setCountOfProgress] = useState(0);
  const [error, setError] = useState('');
  const csvref = useRef();
  const [activeStep, setActiveStep] = useState(0);
  const [profileUrls, setProfileUrls] = useState('');
  const [csvLabelName, setCsvLabelName] = useState('');

  const steps = ['Audience', 'Create Sequence', 'Campaign Setup'];

  const stepperStyles = {
    '& .MuiStepLabel-root .Mui-completed': {
      color: '#7C3AED',
    },
    '& .MuiStepLabel-root .Mui-active': {
      color: '#7C3AED',
    },
    '& .MuiStepLabel-label': {
      fontSize: '14px',
    },
    '& .MuiStepIcon-root': {
      fontSize: '24px',
    }
  };

  const filterOptions = (options, { inputValue }) => {
    return options.filter((option) => option.toLowerCase().includes(inputValue.toLowerCase()));
  };
  const handleOptionChange = (option) => {
    setSelectedOption(option);
    if (option !== 'csv') {
      eventsend("paste-profile-urls_selected");
      setCSVfile(null);
      setFileUpload(false);
      setCountOfProgress(0);
    }else{
      eventsend("Upload-csv-file_selected");
    }
  };

  const isValidLinkedInUrl = (url) => {
    // Basic LinkedIn profile URL validation
    const linkedInUrlPattern = /^https?:\/\/(?:www\.)?linkedin\.com\/in\/[\w-]+\/?$/i;
    return linkedInUrlPattern.test(url.trim());
  };

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.dataTransfer.files && e.dataTransfer.files[0] && e.dataTransfer.files[0].type === 'text/csv') {
      setCSVfile(e.dataTransfer.files[0]);
      setFileUpload(true);
      setCountOfProgress(100);
    } else {
      toast.error('Please upload a CSV file');
    }
  };

  const handleUploadCSV = async () => {
    if (selectedOption === 'profile') {
      setActiveStep(1);
      return;
    }

    const uploadingToast = toast.loading('Uploading file, Please wait...');
    let form = new FormData();
    form.append('files', CSVfile);

    const mappedData = {
      "linkedin profile url": "linkedinProfileUrl",
      "linkedin": "linkedinProfileUrl",
      "linkedin profile": "linkedinProfileUrl",
      "name": "name",
      "full name": "name",
      "first name": "name",
      "email": "email",
      "phone": "phone",
      "mobile": "phone",
      "phone number": "phone",
      "country code": "countryCode"
    };

    form.append('mappedData', JSON.stringify(mappedData));

    try {
      const response = await uploadLinkedinCSV(form, authtoken);
      console.log('response===>', response);

      if (response?.flag) {
        if (response?.data?.length > 0) {
          toast.update(uploadingToast, {
            render: 'File uploaded successfully',
            type: 'success',
            autoClose: true,
            isLoading: false,
          });
          const urlString = response.data.join(',');
          setProfileUrls(urlString);
          setActiveStep(1);
        } else {
          toast.update(uploadingToast, {
            render: 'No valid LinkedIn profiles found in the file',
            type: 'error',
            autoClose: true,
            isLoading: false,
          });
        }
      } else {
        toast.update(uploadingToast, {
          render: response?.message || 'Unexpected error occurred, please try again later',
          type: 'error',
          autoClose: true,
          isLoading: false,
        });
      }
    } catch (error) {
      console.error('Error uploading CSV:', error);
      toast.update(uploadingToast, {
        render: error?.message || 'Error uploading file, please try again',
        type: 'error',
        autoClose: true,
        isLoading: false,
      });
    }
  };

  const getlabellistapicall = async () => {
    // setIsLoadingContacts(true);
    let data = {
      user_id: localdata?._id ? localdata?._id : _id,
    };
    let token = authtoken;
    // let dataApi =await fetchContactLabels(token);
    // const row_data = [];
    // dataApi.data.v1.forEach((vls) => {
    //   row_data.push(vls);
    // });
    // dataApi.data.v2.forEach((vls) => {
    //   row_data.push({
    //     ...vls,
    //     label: vls.label_name
    //   });
    // });

    // setLabelList(row_data);
    // setIsLoadingContacts(false);
    contactlabellistWithoutCountAPI(token).then((res) => {
      if (res.flag) {
        setLabelList(res?.data?.v1);
        // setIsLoadingContacts(false);
        console.log('labellist without count', res.data.v1);
      } else {
        console.log('err===>', res);
      }
      // setIsLoadingContacts(false);
    });
  };

  useEffect(() => {
    getlabellistapicall();
  }, [])

  const handleProfileUrlsChange = (e) => {
    const inputValue = e.target.value;
    setProfileUrls(inputValue);

    // Only validate URLs when they are complete (i.e., when they end with something other than a comma)
    if (!inputValue.endsWith(',')) {
      const urls = inputValue.split(',').map(url => url.trim()).filter(url => url);
      const invalidUrls = urls.filter(url => url && !isValidLinkedInUrl(url));

      if (invalidUrls.length > 0) {
        setError('Please enter valid LinkedIn profile URLs (e.g., https://linkedin.com/in/username)');
      } else {
        setError('');
      }
    }
  };


  return (
    <div
      style={{
        width: '100%'
      }}
    >
      <div style={{
        padding: '10px',
        background: 'white'
      }}>
        {/* Header with back button */}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            marginBottom: '15px'
          }}
        >
          <IoArrowBackOutline size={24} onClick={() => navigate(-1)} style={{ cursor: 'pointer', marginTop: 3 }} />
          <span style={{ fontSize: '24px', fontWeight: '700' }}>Create a new sequence</span>
        </div>

        {/* Campaign Name Input */}
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '24px'
        }}>
          <input
            type="text"
            placeholder="Campaign Name"
            value={campaignName}
            onChange={(e) => {
              setCampaignName(e.target.value)
              eventsend("Add-sequence-name_clicked");
            }}
            style={{
              width: '100%',
              maxWidth: '500px',
              padding: '12px',
              borderRadius: '8px',
              border: '1px solid #E5E7EB'
            }}
          />

          {/* MUI Stepper */}
          <div style={{ width: '50%' }}>
            <Stepper activeStep={activeStep} sx={stepperStyles}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </div>
        </div>

      </div>
      {/* Audience Selection Card */}
      {activeStep === Number(0) && <div style={{
        maxWidth: '800px',
        margin: '0 auto',
        marginTop: '3%',
        backgroundColor: 'white',
        borderRadius: '12px',
        padding: '24px',
        paddingTop: '2%',
        boxShadow: '0 1px 3px rgba(0,0,0,0.1)'
      }}>
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '24px'
        }}>
          <h3 style={{ fontSize: 16, fontWeight: 600 }}>Select audience segment</h3>
          {/* <FiSettings size={20} style={{
            background: 'none',
            border: 'none',
            cursor: 'pointer'
          }} /> */}
        </div>

        <div style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '26px',
          marginBottom: '24px'
        }}>
          {/* <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
            <label style={{ display: 'flex', gap: '8px', alignItems: 'center', fontWeight: 700, fontSize: 16 }}>
              <input
                type="radio"
                name="audience"
                style={{ width: '20px', height: '20px' }}
                checked={selectedOption === 'existing'}
                onChange={() => handleOptionChange('existing')}
              />
              <span>Select Existing Label</span>
            </label>

            {selectedOption === 'existing' && (
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                style={{
                  marginBottom: '5px',
                }}
                filterOptions={filterOptions}
                className="w-100 btncustom"
                size="small"
                renderOption={(props, option) => {
                  const { key, ...optionProps } = props;
                  return (
                    <Box
                      key={key}
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        justifyContent: 'space-between',
                      }}
                      {...optionProps}
                    >
                      <p style={{ width: '98%' }}>{option}</p>
                      {option === 'All contacts' && (
                        <img
                          src={images.FilledPin}
                          style={{
                            height: 22,
                          }}
                        />
                      )}
                    </Box>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    name="variable"
                    size={'small'}
                    placeholder={'Select segment'}
                    className={'restylingLabels'}
                    InputProps={{
                      notched: false,
                      style: {
                        borderRadius: 8,
                      },
                    }}
                    onChange={(e) => {
                      setLabelName(e.target.value);
                    }}
                    {...params}
                  />
                )}
                value={labelName}
                onChange={(e, newValue) => {
                  setLabelName(newValue);
                }}
                freeSolo={true}
                options={[
                  'All contacts',
                  ...labelList
                    .filter((item) => {
                      return !(
                        item?.label_name.includes('abandoned-cart-on') ||
                        item?.label_name.includes('orders-feedback-on') ||
                        item?.label_name.includes('orders-cancelled-on') ||
                        item?.label_name.includes('orders-fulfilled-on') ||
                        item?.label_name.includes('Workflows -') ||
                        item?.label_name.includes('Workflows-') ||
                        item?.label_name.includes('WhatsApp Contacts who') ||
                        item?.label_name.includes('orders-placed-on') ||
                        item?.label_name.includes('cod-confirmation-on')
                      );
                    })
                    .map((item) => item?.label_name),
                ]}
              />
            )}
          </div> */}
          {/* <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
            <label style={{ display: 'flex', gap: '8px', alignItems: 'center', fontWeight: 700, fontSize: 16 }}>
              <input
                type="radio"
                name="audience"
                style={{ width: '20px', height: '20px' }}
                checked={selectedOption === 'linkedin'}
                onChange={() => handleOptionChange('linkedin')}
              />
              <span>LinkedIn Search URL</span>
            </label>
            {selectedOption === 'linkedin' && (
              <input
                type="text"
                placeholder="Paste search url"
                style={{
                  padding: '12px',
                  borderRadius: '8px',
                  fontSize: '14px'
                }}
              />
            )}
          </div> */}
          <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
            <label style={{ display: 'flex', gap: '8px', alignItems: 'center', fontWeight: 700, fontSize: 16 }}>
              <input
                type="radio"
                name="audience"
                style={{ width: '20px', height: '20px' }}
                checked={selectedOption === 'profile'}
                onChange={() => handleOptionChange('profile')}
              />
              <span>Paste profile URLs</span>
            </label>
            {selectedOption === 'profile' && (
              <>
                <p style={{
                  fontSize: '12px',
                  color: '#666666',
                  margin: '0',
                  marginTop: '-8px'
                }}>
                  Please add a comma (,) after each LinkedIn profile URL
                </p>
                <textarea
                  placeholder="https://linkedin.com/in/username"
                  value={profileUrls}
                  onChange={handleProfileUrlsChange}
                  style={{
                    padding: '12px',
                    borderRadius: '8px',
                    fontSize: '14px',
                    minHeight: '120px',
                    resize: 'vertical',
                    fontFamily: 'inherit',
                    border: error ? '2px solid #dc3545' : '1px solid #E5E7EB',
                    outline: 'none' // This prevents the default focus outline
                  }}
                />
                {error && (
                  <p style={{
                    color: '#dc3545',
                    fontSize: '12px',
                    margin: '4px 0 0'
                  }}>
                    {error}
                  </p>
                )}
              </>
            )}
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
            <label style={{ display: 'flex', gap: '8px', alignItems: 'center', fontWeight: 700, fontSize: 16 }}>

              <input
                type="radio"
                name="audience"
                style={{ width: '20px', height: '20px' }}
                checked={selectedOption === 'csv'}
                onChange={() => handleOptionChange('csv')}
              />
              <span>Upload csv file</span>
            </label>
            {selectedOption === 'csv' && (
              <p style={{ marginBottom: '1%' }}>
                <strong>Need Help? </strong>
                <a
                  href="/sample-linkedin-list.csv"
                  download
                  style={{ color: '#7C3AED', textDecoration: 'underline' }}
                >
                  Download our CSV template
                </a>{' '}
                to make sure your CSV is formatted correctly
              </p>)
            }
            {selectedOption === 'csv' && (
              <div className="form-group mb-3">
                <input
                  type="text"
                  placeholder="Enter label name for this CSV"
                  value={csvLabelName}
                  onChange={(e) => setCsvLabelName(e.target.value)}
                  style={{
                    width: '100%',
                    padding: '8px 12px',
                    borderRadius: '8px',
                    border: '1px solid #E5E7EB'
                  }}
                />
              </div>)
            }
            {selectedOption === 'csv' && (
              <div className="upload_50" style={{ marginTop: '20px' }}>
                {!fileUpload ? (
                  <div
                    style={{
                      width: '100%',
                      backgroundColor: '#f9fafb',
                      border: '1px dashed #E5E7EB',
                      borderRadius: 16,
                      padding: '24px',
                      textAlign: 'center',
                      cursor: 'pointer'
                    }}
                    onDragEnter={handleDrag}
                    onDragOver={handleDrag}
                    onDrop={handleDrop}
                    onClick={() => csvref.current.click()}
                  >
                    <input
                      type="file"
                      style={{ display: 'none' }}
                      ref={csvref}
                      accept=".csv"
                      onChange={(e) => {
                        const file = e.target.files[0];
                        if (file) {
                          setCSVfile(file);
                          setFileUpload(true);
                        }
                      }}
                    />
                    <img
                      src={images.ImgUpIcon}
                      style={{
                        width: 140,
                        height: 100,
                        objectFit: 'contain',
                        marginBottom: '24px'
                      }}
                      alt="Upload Icon"
                    />
                    <p style={{ fontSize: 12, fontWeight: 600 }}>Drag & drop the file here</p>
                  </div>
                ) : (
                  <div style={{
                    padding: '20px',
                    backgroundColor: '#f9fafb',
                    border: '1px solid #E5E7EB',
                    borderRadius: 16,
                  }}>
                    <div style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginBottom: '8px'
                    }}>
                      <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        <img src={images.CFile} style={{ height: 24, width: 24 }} alt="File" />
                        <span style={{ fontSize: 16, fontWeight: 600 }}>{CSVfile.name}</span>
                      </div>
                      <img
                        src={images.CDelete}
                        style={{ height: 24, width: 24, cursor: 'pointer' }}
                        onClick={() => {
                          setCSVfile(null);
                          setFileUpload(false);
                        }}
                        alt="Delete"
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        <button
          style={{
            width: '100%',
            padding: '12px',
            backgroundColor: '#7C3AED',
            color: 'white',
            border: 'none',
            borderRadius: '8px',
            cursor: (selectedOption === 'csv' && fileUpload) ||
              (selectedOption === 'profile' && profileUrls.trim().length > 0)
              ? 'pointer' : 'not-allowed',
            fontSize: '16px',
            opacity: (selectedOption === 'csv' && fileUpload && csvLabelName !== '') ||
              (selectedOption === 'profile' && profileUrls.trim().length > 0)
              ? 1 : 0.5
          }}
          disabled={!(
            (selectedOption === 'csv' && fileUpload && csvLabelName !== '') ||
            (selectedOption === 'profile' && profileUrls.trim().length > 0)
          )}
          onClick={handleUploadCSV}
        >
          Next
        </button>
      </div>}


      {(activeStep === Number(1) || activeStep === Number(2)) &&
        <div className='Row justify-content-center w-100' style={{
          flex: 1,
        }}>
          <Flow
            setActiveStep={setActiveStep}
            activeStep={activeStep}
            campaignName={campaignName}
            setCampaignName={setCampaignName}
            profileUrls={profileUrls}
            labelName={selectedOption === 'csv' ? csvLabelName : 'pasted-profile-urls'}
          />
        </div>
      }

      {/* {activeStep === Number(1) &&
        <>
          <div className='Row justify-content-center w-100' style={{
            flex: 1,
          }}>
            <Flow setActiveStep={setActiveStep} activeStep={activeStep} />
          </div>
        </>
      } */}

      {/* {activeStep === Number(2) &&
        <>
          <div className='Column justify-content-center align-items-center w-100' style={{
            flex: 1,
          }}>
            <Flow setActiveStep={setActiveStep} activeStep={activeStep} />
          </div>
        </>
      } */}

    </div>
  );
};

export default CreateSequencePage;