import React, { useEffect, useRef, useState } from 'react';
import colors from '../../../Utils/colors';
import images from '../../../Utils/images';
import CheerioButton from '../../../Components/CheerioButton';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import EventEmitter from '../../../Utils/EventEmitter';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { V2WorkflowS3Link } from '../../../Services';
import { getImagePath } from '../../../Utils';
import UploadValidator from '../../../Components/UploadValidator';
import UploaderText from '../../../Components/UploaderText';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { TextField, Tooltip } from '@mui/material';
import { getClientAttributes } from '../../../Services';
import AttributesModal from './AttributesModal';
import AdvancedOptions from '../Components/AdvancedOptions';

const ResponseModal = ({ data, setData }) => {
  const [messageText, setMessageText] = useState('');
  const [InputOptions, setInputOptions] = useState([{ InputValue: '' }]);
  const [mediaType, setMediaType] = useState('Image');
  const [upFile, setUpFile] = useState();
  const [upFile64, setUpFile64] = useState('');
  const [upFileName, setUpFileName] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [s3Link, setS3Link] = useState('');
  const [dragActive, setDragActive] = useState(false);
  const [variable, setVariable] = useState('');
  const [skipReply, setSkipReply] = useState(false);
  const [allAttributes, setAllAttributes] = useState(["name", 'email', 'phone', 'createdAt', 'updatedAt']);
  const [openAttributes, setOpenAttributes] = useState(false);
  const [keyPressed, setKeyPressed] = useState('none');
  const [e, setE] = useState(null);
  const [IAFilterValue, setIAFilterValue] = useState('');
  const [sendTo, setSendTo] = useState(data?.toPhone ? data?.toPhone : '');

  const authtoken = useSelector((state) => state.main.auth_token);

  const upImageRef = useRef();
  const upVideoRef = useRef();
  const upDocRef = useRef();

  const handlefileupload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      if (UploadValidator(file, mediaType)) {
        setUpFile(file);
        setUpFileName(file.name);
        const base64 = await convertToBase64(file);
        setUpFile64(base64);
      }
    } else {
      toast.error('Unexpected error occurred, please try again later.', {
        position: 'top-center',
      });
    }
  };

  useEffect(() => {
    if (messageText) {
      let currentWord = getWordAtCursor();
      if (currentWord[0] === '%' && currentWord?.length > 1) {
        setIAFilterValue(currentWord?.substring(1));
      } else {
        setIAFilterValue('');
      }
    }
  }, [messageText]);

  useEffect(() => {
    const indAttributesAPIcall = () => {
      let token = authtoken;
      getClientAttributes(token).then((res) => {
        if (res?.flag) {
          const attributes = res.data.map((item) => item.attribute);
          setAllAttributes([...allAttributes, ...attributes]);
        } else {
          // console.log("ind attributes err ---> ",res)
        }
      });
    };
    indAttributesAPIcall();
  }, []);

  const selectedAttributeAction = (value) => {
    const currentWord = getWordAtCursor();
    const wrappedValue = `%${value}%`;

    setMessageText((prev) => {
      let editedText = prev;

      if (!currentWord || prev.endsWith(currentWord)) {
        editedText = prev.replace(new RegExp(`${currentWord}$`), wrappedValue);
      } else {
        editedText = prev.replace(currentWord, wrappedValue);
      }

      return editedText;
    });

    document.getElementById('textInputAttr').focus();
    setOpenAttributes(false);
  };

  function getWordAtCursor() {
    const input = document.getElementById('textInputAttr');
    const cursorPosition = input.selectionStart;
    const text = input.value;

    let startIndex = cursorPosition;
    let endIndex = cursorPosition;

    while (startIndex > 0 && !isWordBoundary(text[startIndex - 1])) {
      startIndex--;
    }

    while (endIndex < text.length && !isWordBoundary(text[endIndex])) {
      endIndex++;
    }

    const currentWord = text.substring(startIndex, endIndex);
    return currentWord;
  }

  function isWordBoundary(char) {
    return char === ' ' || char === '\n';
  }

  const handleDragFile = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDropFile = async (e, type) => {
    e.preventDefault();
    e.stopPropagation();
    console.log('file ===> ', e.dataTransfer.files[0]);
    const file = e.dataTransfer.files[0];
    if (file && type) {
      if (UploadValidator(file, type)) {
        setUpFile(file);
        setUpFileName(file.name);
        const base64 = await convertToBase64(file);
        setUpFile64(base64);
        setImageUrl('');
      }
    } else {
      toast.error('Unexpected error occurred, please try again later.', {
        position: 'top-center',
      });
    }
  };

  const handleImageUrl = async (image) => {
    const url = image;
    if (url === '') {
      return;
    }
    if (upFileName) {
      toast.error('Delete uploaded image and try again', {
        position: 'top-center',
      });
      return;
    }
    // const regex = /^(https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&//=]*\.(?:png|jpg|jpeg|gif|bmp|svg)))$/i;
    // ;
    // console.log(url);
    // if (!regex.test(url)) {
    //   toast.error('Invalid image URL. Please check the URL and try again.', {
    //     position: 'top-center',
    //
    //   });
    //   return;
    // }
    setImageUrl(url);
    setUpFile(null);
    setUpFile64('');

    if (url) {
      try {
        const response = await fetch(url)
          .then((res) => {
            if (res.ok) {
              return res;
            } else {
              console.error('Error:', res.statusText);
            }
          })
          .catch((error) => {
            console.error('Error:', error);
          });
        const blob = await response.blob();
        const file = new File([blob], 'image_from_url.jpg', { type: blob.type });
        const base64 = await convertToBase64(file);
        setUpFile(file);
        setUpFile64(base64);
        setUpFileName(file.name);
      } catch (error) {
        toast.error('Not able to fetch image.Please check your url.', {
          position: 'top-center',
        });
      }
    }
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  useEffect(() => {
    if (upFile64) {
      getS3Link();
    }
  }, [upFile64]);

  const getS3Link = () => {
    let data = {
      image: upFile64,
      type: upFile?.type?.split('/')[0],
      format: upFile?.type?.split('/')[1],
    };
    let token = authtoken;
    toast.promise(
      V2WorkflowS3Link(data, token),
      {
        pending: 'Uploading file...',
        success: {
          render({ data }) {
            setS3Link(data?.data?.url);
            console.log('s3 link success response', data);
            return 'File uploaded successfully!';
          },
          icon: '🟢',
          autoClose: true,
          isLoading: false,
        },
        error: {
          render({ data }) {
            console.log('s3 link error response', data);
            return 'Error occurred while uploading file, please try again later.';
          },
          icon: '🔴',
          autoClose: true,
          isLoading: false,
        },
      },
      {
        position: 'top-center',
      }
    );
  };

  useEffect(() => {
    console.log('incoming edit data ===> ', data);
    if (data?.body?.message) {
      setMessageText(data?.body?.message);
    }
    if (data?.options?.length > 0) {
      setInputOptions(data?.options);
    } else {
      setInputOptions([]);
    }
    if (data?.header?.mediaType) {
      setMediaType(data?.header?.mediaType);
    }
    if (data?.header?.mediaName) {
      setUpFileName(data?.header?.mediaName);
    }
    if (data?.header?.mediaFile) {
      setS3Link(data?.header?.mediaFile);
    }
    if (data?.variable) {
      setVariable(data?.variable);
    }
    if (data?.skip) {
      setSkipReply(data?.skip);
    }
  }, []);

  useEffect(() => {
    setData({
      ...data,
      header: {
        mediaType: mediaType,
        mediaName: upFileName,
        mediaFile: s3Link,
      },
      body: {
        message: messageText,
      },
      options: InputOptions.map((item) => ({
        InputValue: item.InputValue.trim(),
      })),
      variable: variable,
      skip: skipReply,
      toPhone: sendTo,
    });
  }, [messageText, InputOptions, mediaType, upFile, upFile64, s3Link, variable, skipReply, sendTo]);
  console.log('data ===> ', data);

  return (
    <>
      <div className="w-100 h-100 d-flex flex-column justify-content-start align-items-start">
        <p
          style={{
            padding: 0,
            margin: 0,
            marginBlock: 4,
            fontSize: 16,
            fontWeight: 600,
            color: colors.black,
            width: '100%',
          }}
        >
          {'Upload media'}
        </p>

        <InputGroup className="mb-2" style={{ width: '50%', marginBlock: 16 }}>
          <Form.Label style={{ fontSize: 14, fontWeight: 600, color: colors.black }}>
            {'Select media type'}
          </Form.Label>
          <Form.Select
            type="text"
            placeholder="Select media type"
            className="w-100 btncustom dropdown"
            style={{ borderRadius: 8, fontSize: 16, fontWeight: 400, color: colors.greys01 }}
            value={mediaType}
            onChange={(e) => {
              setMediaType(e.target.value);
            }}
          >
            <option
              style={{ width: '100%', marginTop: 4, marginBottom: 4, borderRadius: 8 }}
              value={'Image'}
              key={'Image'}
            >
              {'Image'}
            </option>
            <option
              style={{ width: '100%', marginTop: 4, marginBottom: 4, borderRadius: 8 }}
              value={'Video'}
              key={'Video'}
            >
              {'Video'}
            </option>
            <option
              style={{ width: '100%', marginTop: 4, marginBottom: 4, borderRadius: 8 }}
              value={'Document'}
              key={'Document'}
            >
              {'Document'}
            </option>
          </Form.Select>
        </InputGroup>

        <div
          className="d-flex flex-column justify-content-center align-items-center w-100 my-2"
          style={{ border: `1px solid ${colors.borderwhite}`, borderRadius: 8, height: 260 }}
          onDragEnter={handleDragFile}
          onDragExit={handleDragFile}
          onDragOver={handleDragFile}
          onDrop={(e) => {
            handleDropFile(e, mediaType);
          }}
          onClick={() => {
            if (imageUrl || upFileName) {
              toast.error('Delete uploaded image and try again', {
                position: 'top-center',
              });
              return;
            }
            if (mediaType === 'Image') {
              upImageRef.current.click();
            } else if (mediaType === 'Video') {
              upVideoRef.current.click();
            } else if (mediaType === 'Document') {
              upDocRef.current.click();
            }
          }}
        >
          {data?.header?.mediaFile || upFile || s3Link ? (
            <>
              {s3Link && (
                // {}
                <>
                  {mediaType === 'Image' ? (
                    <img
                      src={getImagePath(s3Link)}
                      style={{ height: 180, width: '80%', objectFit: 'contain' }}
                    ></img>
                  ) : mediaType === 'Document' ? (
                    <>
                      {/* <img src={getImagePath(s3Link)} style={{height:180,width:'80%',objectFit:'contain'}}></img> */}
                      {/* {upFile && 
              <embed src={URL.createObjectURL(s3Link)} style={{height:180,width:'80%',objectFit:'cover'}}></embed>
            } */}
                      {upFile ? (
                        <embed
                          src={URL.createObjectURL(upFile)}
                          style={{ height: 180, width: '80%', objectFit: 'cover' }}
                        ></embed>
                      ) : (
                        <img
                          src={images.CATREmail}
                          style={{ height: 180, width: '80%', objectFit: 'contain' }}
                        ></img>
                      )}
                    </>
                  ) : (
                    mediaType === 'Video' && (
                      <video
                        src={getImagePath(s3Link)}
                        style={{ height: 180, width: '80%', objectFit: 'contain' }}
                      ></video>
                    )
                  )}
                </>
              )}
              {upFileName && (
                <div className="d-flex flex-row justify-content-between align-items-center w-75">
                  <p
                    style={{
                      padding: 0,
                      margin: 0,
                      marginBlock: 4,
                      fontSize: 16,
                      fontWeight: 600,
                      color: colors.greys04,
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      wordBreak: 'break-all',
                    }}
                  >
                    {upFileName}
                  </p>
                  <img
                    src={images.Cross}
                    style={{ height: 18, width: 18, marginInlineStart: 24, cursor: 'pointer' }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setUpFile(null);
                      setUpFile64(null);
                      setUpFileName('');
                      setS3Link(null);
                      setImageUrl('');
                    }}
                  />
                </div>
              )}
            </>
          ) : (
            <>
              <img
                src={images.ImgUpIcon}
                style={{ height: 100, width: 70, objectFit: 'contain' }}
              ></img>
              <UploaderText
                type={mediaType}
                textStyle={{
                  fontSize: 14,
                  fontWeight: 600,
                  color: colors.greys04,
                  textAlign: 'center',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  wordBreak: 'break-all',
                }}
              />
              {/* <p style={{padding:0,margin:0,marginBlock:4,fontSize:16,fontWeight:600,color:colors.greys04}}>
            {'Upload Image'}
          </p>
          <p style={{padding:0,margin:0,marginBlock:8,fontSize:14,fontWeight:600,color:colors.greys04,textAlign:'center'}}>
            {'350px x 350px to 1500px x 1500px'}<br/>
            {'Max file size: 5 MB'}
          </p> */}
            </>
          )}
        </div>
        {mediaType === 'Image' && (
          <div
            className="d-flex flex-column justify-content-center align-items-center w-100 my-2"
            style={{
              width: '100%',
            }}
          >
            <div
              style={{
                fontWeight: '700',
                marginBottom: '10px',
              }}
            >
              OR
            </div>
            <div className="btncustom" style={{ marginBottom: '5px', width: '100%' }}>
              <TextField
                type="url"
                className={'restylingLabels'}
                size="small"
                style={{
                  borderRadius: 4,
                  width: '100%',
                }}
                placeholder="Paste Image Url"
                value={imageUrl}
                onPaste={(event) => {
                  const pastedURL = event.clipboardData.getData('text');
                  handleImageUrl(pastedURL);
                }}
                onChange={(e) => {
                  setImageUrl(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleImageUrl(imageUrl);
                  }
                  if (e.key === 'Backspace') {
                    e.stopPropagation();
                    setUpFile(null);
                    setUpFile64(null);
                    setUpFileName('');
                    setS3Link(null);
                    setImageUrl(e.target.value);
                  }
                }}
              />
            </div>
            <p className='w-100 my-2'>
              {"If you're facing issues related to image sizes, "}
              <span style={{ color: colors.linkblue, cursor: 'pointer' }} onClick={() => {
                window.open('https://imgupscaler.com/', '_blank')
              }}>
                {'Try resizing'}
              </span>
            </p>
          </div>
        )}

        <input
          type="file"
          style={{ display: 'none' }}
          ref={upImageRef}
          accept="image/*"
          onChange={(e) => {
            handlefileupload(e);
          }}
        />
        <input
          type="file"
          style={{ display: 'none' }}
          ref={upVideoRef}
          accept="video/*"
          onChange={(e) => {
            handlefileupload(e);
          }}
        />
        <input
          type="file"
          style={{ display: 'none' }}
          ref={upDocRef}
          accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf,.pdfx,.csv"
          onChange={(e) => {
            handlefileupload(e);
          }}
        />

        <div
          className="w-100"
          style={{ height: 1, backgroundColor: colors.borderwhite, marginBlock: 16 }}
        ></div>

        <p
          style={{
            padding: 0,
            margin: 0,
            marginBlock: 4,
            fontSize: 16,
            fontWeight: 600,
            color: colors.black,
            width: '100%',
          }}
        >
          {'Type a message'}
          <span style={{ color: colors.error03 }}>{'* (Mandatory)'}</span>
        </p>

        <InputGroup
          className="my-1"
          style={{
            borderBottom: `1px solid ${colors.borderwhite}`,
            paddingBlockEnd: 20,
            position: 'relative',
            zIndex: 1,
          }}
        >
          <Form.Control
            id="textInputAttr"
            required
            as="textarea"
            rows={4}
            placeholder={'Enter here'}
            className="w-100 btncustom"
            style={{ borderRadius: 8, fontSize: 14, fontWeight: 400, color: colors.black }}
            // isInvalid={(renewalText.length >= 640)}
            maxLength={640}
            value={messageText}
            onChange={(e) => {
              setMessageText(e.target.value);
            }}
            onKeyDown={(event) => {
              if (
                event.key === 'Backspace' &&
                messageText.charAt(event.target.selectionStart - 1) === '%'
              ) {
                setOpenAttributes(false);
              } else if (event.key === 'ArrowUp' && openAttributes) {
                event.preventDefault();
                setKeyPressed('Up');
              } else if (event.key === 'ArrowDown' && openAttributes) {
                event.preventDefault();
                setKeyPressed('Down');
              } else if (event.key === 'Tab' && openAttributes) {
                event.preventDefault();
                setKeyPressed('Tab');
              } else if (event.key === '%' && event.shiftKey) {
                setOpenAttributes(true);
              } else if (event.code === 'Space') {
                setOpenAttributes(false);
              } else if (event.key === 'Enter') {
                setOpenAttributes(false);
              }
              setE(event);
            }}
          />
        </InputGroup>

        {openAttributes && (
          <AttributesModal
            setOpenAttributes={setOpenAttributes}
            attributeObject={allAttributes}
            selectedAttributeAction={selectedAttributeAction}
            filterValue={IAFilterValue}
            keyPressed={keyPressed}
            setKeyPressed={setKeyPressed}
          />
        )}

        {InputOptions &&
          InputOptions.map((item, index) => {
            return (
              <>
                <div
                  key={`key==${index}`}
                  className="d-flex flex-column justify-content-start align-items-center w-100"
                >
                  <div className="d-flex flex-row justify-content-between align-items-center w-100">
                    <p
                      style={{
                        padding: 0,
                        margin: 0,
                        marginBlock: 4,
                        fontSize: 16,
                        fontWeight: 600,
                        color: colors.black,
                        width: '100%',
                      }}
                    >
                      {`Input Option ${index + 1}`}
                    </p>
                    <img
                      src={images.DeleteBlack}
                      style={{ height: 18, width: 18, objectFit: 'contain', cursor: 'pointer' }}
                      onClick={() => {
                        let temp = [...InputOptions];
                        let index = temp.indexOf(item);
                        let delEle = temp.splice(index, 1);
                        setInputOptions(temp);
                      }}
                    ></img>
                  </div>

                  <InputGroup className="my-1">
                    <Form.Control
                      type="text"
                      placeholder={'Enter here'}
                      className="w-100 btncustom"
                      style={{
                        borderRadius: 8,
                        fontSize: 14,
                        fontWeight: 400,
                        color: colors.black,
                      }}
                      // isInvalid={(renewalText.length >= 640)}
                      maxLength={640}
                      value={InputOptions[index].InputValue}
                      onChange={(e) => {
                        const value = e.target.value;
                        // const allregex = /^[\p{L}\p{N}\p{M} ]+$/u;
                        const allregex = /^(?:[\p{L}\p{N}\p{M} ]*|)$/u;
                        if (allregex.test(value)) {
                          const tempObj = [...InputOptions];
                          tempObj[index].InputValue = value;
                          setInputOptions(tempObj);
                          console.log(InputOptions);
                        }
                      }}
                    />
                    {InputOptions[index].InputValue?.length > 20 && (
                      <p
                        style={{
                          fontSize: 12,
                          color: colors.red49,
                          margin: 0,
                          padding: 0,
                          width: '100%',
                          marginTop: 4,
                        }}
                      >
                        Buttons and Lists can only have 20 characters for each option. This node
                        will be converted to a text node.
                      </p>
                    )}
                  </InputGroup>
                </div>
              </>
            );
          })}

        <CheerioButton
          borderStyle={{ marginBlockStart: 8, marginBlockEnd: 16 }}
          textStyle={{ fontSize: 14, padding: 0, margin: 0, fontWeight: 600, color: colors.black }}
          btnText={'Add Option'}
          backColor={colors.white01}
          onclick={() => {
            console.log('add option clicked');
            const tempArr = [...InputOptions];
            tempArr.push({ InputValue: '' });
            setInputOptions(tempArr);
          }}
        />
        <div class="form-group my-2" style={{ width: '100%' }}>
          <label
            for="exampleFormControlInput1"
            style={{ fontWeight: '700', fontSize: '14px', color: 'black' }}
          >
            Save reply in a variable
          </label>

          <input
            class="form-control shadow-none"
            placeholder="Enter value"
            value={variable}
            onChange={(e) => {
              let val = e.target.value;
              val = val.replace(/[^@a-zA-Z0-9_]/g, '');
              if (val.startsWith('@')) {
                setVariable(val);
              } else {
                setVariable('@' + val);
              }
            }}
          />
        </div>
      </div>
      <div className="d-flex flex-row justify-content-between align-items-center w-100 mb-4 mt-2">
        <Form.Check
          // disabled={disabled}
          type="checkbox"
          className="btncustom"
          // value={skipReply ? true : false}
          checked={skipReply}
          label="Skip user reply"
          onChange={() => {
            setSkipReply(!skipReply);
          }}
        />
        <Tooltip
          title={'Next node will trigger itself even if recipient does not reply'}
          placement="bottom-end"
        >
          <div>
            <AiOutlineInfoCircle size={20} style={{ marginInlineEnd: 16 }} />
          </div>
        </Tooltip>
      </div>

      <AdvancedOptions
        sendTo={sendTo}
        setSendTo={setSendTo}
        type={'Number'}
      />

    </>
  );
};

export default ResponseModal;
