import React, { useState, useEffect } from 'react';
import CheerioButton from '../../../Components/CheerioButton';
import { Modal } from 'react-bootstrap';
import images from '../../../Utils/images';
import colors from '../../../Utils/colors';
import { useSelector } from 'react-redux';
import { createApiKey, getApiKey, updateApiKey } from '../../../Services';
import { toast } from 'react-toastify';
import { eventsend } from '../../../Config/analyticsFunctions';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Typography from '@mui/material/Typography';
import SpinnerLoader from '../../../Components/SpinnerLoader';
import {
  Button,
} from '@mui/material';
const NewApiKeyModal = ({ apiKey, openNewModal, setNewModal, onClose }) => {
  const downloadJSON = () => {
    if (apiKey) {
      // console.log('return policy',res);
      const data = {
        key: apiKey,
      };

      const url = window.URL.createObjectURL(new Blob([JSON.stringify(data)]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `apiKey.json`);
      document.body.appendChild(link);
      link.click();
      toast.success('File downloaded', { position: 'top-center', autoClose: true });
    } else {
      toast.error('error downloading apiKey');
    }
  };

  const handleCloseModal = () => {
    setNewModal(false);
    if (onClose) onClose();
  };

  return (
    <>
      <Modal dialogClassName="NewApiKeyModal" show={openNewModal}>
        <Modal.Body>
          <div className="d-flex justify-content-between">
            <p style={{ fontSize: '16px', fontWeight: '600' }}>New API Key</p>
            <img
              src={images.CMClose}
              onClick={handleCloseModal}
              style={{
                cursor: 'pointer',
                height: 22,
                width: 22,
                marginInlineEnd: 8,
              }}
            ></img>
          </div>
          <div>
            <div class="form-group mt-4">
              <label
                for="exampleFormControlInput1"
                style={{ fontWeight: '600', fontSize: '16px', color: 'black' }}
              >
                API Key
              </label>
              <div className="d-flex" style={{ border: '1px solid #666666', borderRadius: '8px' }}>
                <input
                  value={apiKey}
                  class="form-control shadow-none"
                  readOnly
                  placeholder="Generate API Key"
                  style={{ marginTop: '2px', border: 'none' }}
                />
                <img
                  style={{ marginRight: '10px', marginTop: '10px', cursor: 'pointer' }}
                  height={'22px'}
                  onClick={() => {
                    navigator.clipboard.writeText(apiKey);
                    toast.success('Copied to clipboard');
                  }}
                  src={images.Copy}
                ></img>
              </div>
            </div>

            {/* <div class="form-group mt-4">
          <label
            for="exampleFormControlInput1"
            style={{ fontWeight: '600', fontSize: '16px', color: 'black' }}
          >
            Key Secret
          </label>
          <input
            value={""}
            onChange={(e) => {

            }}
            class="form-control shadow-none"
            placeholder="Enter here"
            style={{marginTop:"2px",border:"1px solid #666666" }}
          />
                  </div> */}

            <CheerioButton
              borderStyle={{ marginTop: '60px', borderRadius: '8px', marginBottom: '20px',borderWidth: 0, }}
              textStyle={{ fontSize: 16, fontWeight: 600, color: 'white' }}
              btnText={'Download Key Details'}
              backColor={colors.primary03}
              onclick={() => {
                downloadJSON();
              }}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export const APIKey = () => {
  const [openNewModal, setNewModal] = useState(false);
  const [keyData, setKeyData] = useState(null);
  const [apiKeyResponse, setApiKeyResponse] = useState(null);
  const token = useSelector((state) => state.main.auth_token);
  const [loading, setLoading] = useState(true);


    const fetchAPIKeys = async () => {
      setLoading(true);

      try {
        const response = await getApiKey(token);
        setApiKeyResponse(response);       
        if (response?.flag && response?.data?.length > 0) {
          setKeyData(response.data[0]);
        } else {
          setKeyData(null);
        }
      } catch (error) {
        console.error("Error fetching API keys:", error);
        setKeyData(null);
      }

      setLoading(false);
    };

  useEffect(() => {
    fetchAPIKeys();
  }, [token]);

  const updateAPIKey = () => {
    const data = {};
    updateApiKey(token, data).then((res) => {
      if (res?.flag) {
        setKeyData(res?.data);
        setNewModal(true);

        //setKeyData(res?.data);
      }
    });
  };

  const createAPIKey = () => {
    const data = {};
    createApiKey(data, token).then((res) => {
      if (res?.flag) {
        setKeyData(res?.data);
        setNewModal(true);

        //setKeyData(res?.data);
      }
    });
  };
  console.log('key', keyData);
  const handleModalClose = () => {
    fetchAPIKeys();
  };

  const hasNoApiKeys = apiKeyResponse?.flag === false && apiKeyResponse?.message === "No API keys found";
  return (
    <>
      <div style={{ borderBottom: '1px solid #CCCCCC', width: '100%', display: 'flex' }}>
        <p style={{ fontSize: '22px', fontWeight: '700', marginTop: '8px', marginLeft: '17px' }}>
          Cheerio API Key
        </p>
        <Button
            href="https://documenter.getpostman.com/view/13841235/2s9Y5Zvh9y#71a0c1c4-2066-41f2-ae42-48e758d320a0/"
            target="_blank"
            style={{
              textDecoration: 'none',
              color: 'inherit',
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              marginLeft:'70%'
            }}
          >
            <Typography
              variant="body1"
              style={{
                fontSize: '1rem',
                textTransform: 'none',
              }}
            >
              Postman Link
            </Typography>
            <OpenInNewIcon
              style={{
                fontSize: '1.3rem',
                verticalAlign: 'middle',
              }}
            />
          </Button>
      </div>

      {loading ? (
        <div className="d-flex align-items-center justify-content-center" style={{ height: '400px' }}>
          <SpinnerLoader />
        </div>
      ) : hasNoApiKeys || !keyData ? (
        <div style={{ width: '100%', padding: '10px' }}>
          <div
            className="d-flex flex-column align-items-center"
            style={{
              border: '1px solid #CCCCCC',
              backgroundColor: '#FFFFFF',
              borderRadius: '16px',
              paddingTop: '100px',
              paddingBottom: '100px',
            }}
          >
            <p style={{ fontSize: '16px', fontWeight: '600' }}>No API Keys generated yet</p>
            <p style={{ fontSize: '16px', fontWeight: '400', color: '#666666', marginTop: '15px' }}>
            You can generate an API key by clicking on the button below
            </p>
            <CheerioButton
              borderStyle={{ width: '30vw', marginTop: '20px',borderWidth: 0,borderRadius: '8px'  }}
              textStyle={{ fontSize: 16, fontWeight: 600, color: 'white' }}
              btnText={'Generate API key'}
              backColor={colors.primary03}
              onclick={() => {
                eventsend('Generate_API_key_Manage_profile_page_clicked');
                createAPIKey();
              }}
            />
          </div>
          {openNewModal && (
            <NewApiKeyModal
              apiKey={keyData?.apiKey}
              openNewModal={openNewModal}
              setNewModal={setNewModal}
            ></NewApiKeyModal>
          )}
        </div>
      ) : (
        <div style={{ width: '100%', padding: '10px' }}>
          <div
            className=""
            style={{
              border: '1px solid #CCCCCC',
              backgroundColor: '#FFFFFF',
              borderRadius: '16px',
              padding: '20px',
            }}
          >
            <div className="form-group mt-4">
              <label
                htmlFor="exampleFormControlInput1"
                style={{ fontWeight: '600', fontSize: '16px', color: 'black' }}
              >
                Key ID
              </label>
              <div
                className="d-flex"
                style={{ width: '30vw', border: '1px solid #666666', borderRadius: '8px',marginTop:10 }}
              >
                <input
                  value={keyData?.apiKey}
                  readOnly
                  className="form-control shadow-none"
                  placeholder="Generate API Key"
                  style={{ marginTop: '2px', border: 'none' }}
                />
                <img
                  style={{ marginRight: '10px', marginTop: '10px', cursor: 'pointer' }}
                  height={'22px'}
                  onClick={() => {
                    navigator.clipboard.writeText(keyData?.apiKey);
                    toast.success('Copied to clipboard');
                  }}
                  src={images.Copy}
                ></img>
              </div>
            </div>

            <div style={{ marginTop: '20px' }}>
              <p style={{ fontWeight: '600' }}>Generated on</p>
              <p style={{ color: '#666666', marginTop: '10px' }}>{keyData?.updatedAt}</p>
            </div>
            <CheerioButton
              borderStyle={{ borderWidth: 0, marginTop:10, borderRadius: 8, width: '15vw', marginTop:10 }}
              textStyle={{ fontSize: 16, fontWeight: 600, color: 'white' }}
              btnText={'Regenerate API key'}
              backColor={colors.primary03}
              onclick={() => {
                eventsend('Regenerate_API_key_Manage_profile_page_clicked');
                updateAPIKey();
              }}
            />
          </div>
        </div>
      )}
          {openNewModal && (
            <NewApiKeyModal
              apiKey={keyData?.apiKey}
              openNewModal={openNewModal}
              setNewModal={setNewModal}
              onClose={handleModalClose}
            />
      )}

    </>
  );
};
