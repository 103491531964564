import React from 'react'
import colors from '../../../Utils/colors'
import images from '../../../Utils/images'
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import CheerioButton from '../../../Components/CheerioButton';
import { eventsend } from '../../../Config/analyticsFunctions';

const CampaignSettingsModal = ({ 
    setIsModalOpen, 
    campaignName, 
    setCampaignName, 
    receiveOn, 
    setReceiveOn,
}) => {

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

  return (
    <>
    <div className='DarkBg' onClick={()=>{}}>
        <div className='Center' style={{transform:'translate(calc(-50% + 40px),-50%)'}}>
            <div className='Column' style={{
                justifyContent:'flex-start',
                width: 600,
                backgroundColor: colors.white01,
                borderRadius:16,
                zIndex:0,
                border:`1px solid ${colors.borderwhite}`,
                paddingInline:40,
                paddingBlock:24,
            }}>
                <div className='Row justify-content-between w-100' style={{
                    paddingBlockEnd:20,
                    borderBottom:`1px solid ${colors.borderwhite}`
                }}>
                    <h4 style={{fontSize:16,fontWeight:600}}>
                        {'Campaign Settings'}
                    </h4>
                    <img 
                        src={images.CMClose} 
                        style={{height:20,width:20,objectFit:'contain',cursor:'pointer'}} 
                        onClick={()=>{setIsModalOpen(false)}}
                    >
                    </img>
                </div>
                <InputGroup className="my-2" style={{width:'100%'}}>
                    <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
                        {'Campaign Name'}
                        <span style={{color:colors.error03}}>
                            {'*'}
                        </span>
                    </Form.Label>
                    <Form.Control
                        type="text" 
                        required
                        placeholder='Enter campaign name'
                        className="w-100 btncustom"
                        style={{ borderRadius: 8 }}
                        value={campaignName}
                        onChange={(e) => {
                            setCampaignName(e.target.value)
                        }}
                    />
                </InputGroup>
                <InputGroup className="my-2" style={{width:'100%'}}>
                    <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
                        {'Receive replies on'}
                    </Form.Label>
                    <Form.Control
                        type="email" 
                        placeholder='Add email id'
                        className="w-100 btncustom"
                        style={{ borderRadius: 8 }}
                        value={receiveOn}
                        onChange={(e) => {
                            setReceiveOn(e.target.value)
                            eventsend("receive-replies-on_modify-campaign-settings_added");
                        }}
                    />
                    {receiveOn && !isValidEmail(receiveOn) &&
                        <p className='w-100' style={{color:colors.error03}}>
                            {'Invalid email id'}
                        </p>
                    }
                </InputGroup>
                <CheerioButton 
                    btnText={'Save'}
                    textStyle={{color:colors.white01}}
                    backColor={colors.primary03}
                    borderStyle={{borderColor:colors.primary03,width:'98%',marginBlock:16}} 
                    onclick={()=>{setIsModalOpen(false)}} 
                    disabled={(campaignName && (isValidEmail(receiveOn) || !receiveOn)) ? false : true}
                />
            </div>
        </div>
    </div>
    </>
  )
}

export default CampaignSettingsModal