import React from 'react'
import colors from '../../../Utils/colors';
import CustomHandle from '../../WorkFlow/Nodes/CustomHandle';
import { Position } from 'reactflow';
import images from '../../../Utils/images';

const handleStyle = { backgroundColor: colors.primary03, height: 8, width: 8, borderRadius: 4 };

const ConditionNode = ({ data, isConnectable, selected }) => {
  return (
    <>
    <div className='Row justify-content-center' style={{
        border: selected ? `2px solid ${colors.primary03}` : `1px solid ${colors.borderwhite}`,
        borderRadius: 16,
        backgroundColor: colors.white01,
        paddingBlock: 16,
        paddingInline: 18,
        width: 160,
    }}>
        <CustomHandle type='target' position={Position.Top} isConnectable={1} />
        <CustomHandle type='source' position={Position.Bottom} isConnectable={2} />
        <img
            src={images.SeqSidebarCondition}
            style={{
                height: 32, width: 32, objectFit: 'contain'
            }}
        />
        <p style={{ fontSize: 16, fontWeight: 600, marginInline: 16 }}>
            {'Condition'}
        </p>
    </div>
    </>
  )
}

export default ConditionNode