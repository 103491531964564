import React from 'react';
import colors from '../../../Utils/colors';
import images from '../../../Utils/images';
import '../styles.css';

export const OptionsDropdown = ({ positionStyling, setSendAddressModal,setAddresstext,setShowDropdown }) => {
  return (
    <div className="address" style={{ ...positionStyling }}>
            
      <div 
        onClick={() => {
            setSendAddressModal(true)
            setShowDropdown(false)
            setAddresstext('Request Address')

        }}
        style={{
          padding: '10px 16px',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <img 
          src={images.MapMarker} 
          style={{ 
            marginRight: '8px', 
            width: '20px', 
            height:  '20px' 
          }} 
        />
        Request Address
      </div>
      
      <div 
        onClick={() => {
            setSendAddressModal(true)
            setShowDropdown(false)
            setAddresstext('Request Location')
        }}
        style={{
          padding: '10px 16px',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <img 
          src={images.MapIcon} 
          style={{ 
            marginRight: '8px', 
            width: '20px', 
            height:  '20px' 
          }} 
        />
        Request Location
      </div>
    </div>
          
  );
};
