import React, { useState, useRef } from 'react'
import CheerioButton from '../../../Components/CheerioButton';
import { Autocomplete, TextField } from '@mui/material';
import DropzoneCustom from '../../Chatbot/Dropzone';
import colors from '../../../Utils/colors';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { Link } from 'react-router-dom';
import { contactlabellistV2API, fetchCSVMap, fetchExcelMap } from '../../../Services';
import { useSelector } from 'react-redux';
import images from '../../../Utils/images';
import { ToastContainer, toast } from 'react-toastify';
import { Form } from 'react-bootstrap';
import ExcelUpload from '../../ExcelUpload';
import { eventsend } from '../../../Config/analyticsFunctions';

const UploadingFile = ({
    setExisting,
    setLabelName,
    labelName,
    labelList,
    existing,
    setError,
    error,
    fileUpload,
    setFileUpload,
    CSVfile,
    setCSVfile,
    countOfProgress,
    setCountOfProgress,
    setStep,
    setFetchedCSV,
    excelLabel, 
    vendorsList,
}) => {

    const [dragActive, setDragActive] = useState(false);
    const [disableNextBtn, setDisableNextBtn] = useState(false);
    const csvref = useRef();
    const authtoken = useSelector((state) => state.main.auth_token);

    const handleDrop = function (e) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);

        if (
            e.dataTransfer.files &&
            e.dataTransfer.files[0] &&
            e.dataTransfer.files[0].type == excelLabel ? 'application/vnd. ms-excel' : 'text/csv'
        ) {
            setCSVfile(e.dataTransfer.files[0]);
        } else {
            setShowAlert(true);
            setTimeout(() => {
                // setShowAlert(false);
            }, 2000);
        }
    };

    const handleDrag = function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === 'dragenter' || e.type === 'dragover') {
            setDragActive(true);
        } else if (e.type === 'dragleave') {
            setDragActive(false);
        }
    };

    const handleUploadCSV = async () => {
        setDisableNextBtn(true);
        const uploadingToast = toast.loading('Uploading file, Please wait...');
        let form = new FormData();
        form.append('files', CSVfile);
        form.append('label', labelName);
        let data = {
            file: CSVfile,
            label: labelName,
        };
        let token = authtoken;
        // importContactsCSVApi(data, token).then((res) => {
        if(excelLabel){
            const response = await fetchExcelMap(form,token);
            console.log('success ==> ',response);
            if(response?.flag){
                if(response?.data?.firstFiveRows?.length > 0){
                    toast.update(uploadingToast, {
                        render: 'File uploaded',
                        type: 'success',
                        autoClose: true,
                        isLoading: false,
                    });
                    setFetchedCSV(response?.data);
                    setStep(2)
                    eventsend("Map_column_dropdown_clicked_Mapping_page");
                }else{
                    toast.update(uploadingToast, {
                        render: 'No contacts found in the file',
                        type: 'error',
                        autoClose: true,
                        isLoading: false,
                    });
                }
            }else{
                toast.update(uploadingToast, {
                    render: response?.message ? response?.message : 'Unexpected error occurred, please try again later',
                    type: 'error',
                    autoClose: true,
                    isLoading: false,
                });
                console.log('err===>', response);
            }
        } else {
            const response = await fetchCSVMap(data, token);
            console.log('response===>', response);
            if (response?.flag) {
                console.log('response===>', response);
                if (response?.data?.contacts?.length > 0) {
                    toast.update(uploadingToast, {
                        render: 'File uploaded',
                        type: 'success',
                        autoClose: true,
                        isLoading: false,
                    });
                    setFetchedCSV(response?.data);
                    setStep(2)
                    eventsend("Map_column_dropdown_clicked_Mapping_page");
                } else {
                    toast.update(uploadingToast, {
                        render: 'No contacts found in the file',
                        type: 'error',
                        autoClose: true,
                        isLoading: false,
                    });
                }
            } else {
                toast.update(uploadingToast, {
                    render: 'Unexpected error occurred, please try again later',
                    type: 'error',
                    autoClose: true,
                    isLoading: false,
                });
            }
        }
        setDisableNextBtn(false);
    }

    return (
        <div style={{ padding: '4% 5%', width: '91%' }}>
            {!vendorsList && 
            <div style={{}}>
                <div className="Row justify-content-start" style={{}}>
                    <input
                        type="radio"
                        checked={!existing}
                        onChange={() => {
                            setExisting(!existing);
                            setLabelName('');
                            eventsend("Add_contact_to_new_list_selected_Upload_contacts_page");
                        }}
                        style={{ width: '16px', height: '16px', marginRight: '6px', marginTop: '4px' }}
                    />
                    <label
                        style={{
                            fontSize: '16px',
                        }}
                    >
                        Add contacts to new list
                    </label>
                    <span
                        className="mx-3"
                        style={{
                            color: labelName.length > 30 ? colors.error04 : colors.greys04,
                            fontSize: 12,
                            fontWeight: 400,
                            padding: 0,
                            margin: 0,
                        }}
                    >
                        {`${labelName.length}/30`}
                    </span>
                </div>
                {!existing && (
                    <TextField
                        id="standard-basic"
                        size="small"
                        placeholder={'Enter label name'}
                        style={{ marginBlock: '10px', marginInlineStart: '20px', }}
                        value={labelName}
                        inputProps={{
                            maxLength: 30,
                        }}
                        onChange={(e) => {
                            const newValue = e.target.value;
                            // if (!newValue.match(/[%<>\\$'"\[\]]/)) {
                            // if (
                            //   !newValue.match(
                            //     /[\!\@\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\-]/g
                            //     ///^([A-Za-z0-9 _]+)$/
                            //   )
                            if (/^([A-Za-z0-9 _]+)$/.test(newValue)) {
                                let matchedLabel = labelList.filter((item) => {
                                    return item.label === newValue;
                                });
                                console.log('matched', matchedLabel);
                                if (matchedLabel.length > 0) {
                                    setError('Label already exist!');
                                } else {
                                    matchedLabel = false;
                                    setError('');
                                }
                            } else {
                                setError('Special characters are not allowed');
                            }
                            setLabelName(newValue);
                        }}
                        helperText={error}
                        error={!!error}
                    />
                )}
                {!excelLabel && 
                <>
                <div className="d-flex " style={{ marginTop: '2%' }}>
                    <input
                        type="radio"
                        checked={existing}
                        onChange={() => {
                            setExisting(!existing);
                            setLabelName('');
                            eventsend("Add_contacts_to_existing_list_selected_Upload_contacts_page");
                        }}
                        style={{ width: '16px', height: '16px', marginRight: '6px', marginTop: '4px' }}
                    />
                    <label
                        style={{
                            fontSize: '16px',
                        }}
                    >
                        Add contacts to existing list
                    </label>
                </div>
                </>
                }
                {existing && (
                    <Autocomplete
                        disableCloseOnSelect
                        size="small"
                        id="combo-box-demo"
                        style={{ marginBlock: '10px', marginInlineStart: '20px  ', width: '25%' }}
                        onChange={(e, newValue) => {
                            if (newValue) {
                                setLabelName(newValue.label);
                                setError('');
                            }
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder="Labels"
                                size="small"
                                inputProps={{ ...params.inputProps, style: { fontSize: '14px' } }}
                            />
                        )}
                        options={labelList}
                    />
                )}
            </div>
            }
            {excelLabel && (
                <>
                <div className='Column justify-content-start align-items-start w-100 mt-4 mb-2'>
                    <div className='Row justify-content-start w-100'>
                        <img src={images.InfoCircleGrey} style={{height:20,width:20,objectFit:'contain',marginInlineEnd:8}}></img>
                        <h4 style={{fontSize:16}}>
                            {'Guidelines'}
                        </h4>
                    </div>
                    <p>
                        {`1. Delete extra columns in both sheets`}<br/>
                        {`2. Upload excel with 2 sheets only (Sheet 1: Recipient List | Sheet 2: Data list)`}<br/>
                        {`3. Keep index column in first column of both sheets`}<br/>
                        {`4. Upload up to 7 columns in Sheet 2 in addition to index column`}<br/>
                        {`5. Recipient data can be fetched from Sheet 1 to replace template variables`}
                    </p>
                </div>
                </>
            )}
            
            <div className="upload_50" style={{ marginTop: '10%' }}>
                {!(excelLabel || vendorsList) && 
                <p style={{ marginBottom: '1%' }}>
                    <strong>Need Help? </strong>
                    <Link to="/Sample-newContact-list.csv" target="_blank" download>
                        Download our CSV template{' '}
                    </Link>{' '}
                    to make sure your CSV is formatted correctly
                </p>
                }
                
                {/* Upload drag and drop zone */}
                {!fileUpload && (
                    <div
                        className="d-flex flex-column justify-content-start align-items-center"
                        style={{
                            width: '100%',
                            backgroundColor: colors.white02,
                            border: `1px dashed ${colors.greys03}`,
                            borderRadius: 16,
                        }}
                        onDragEnter={handleDrag}
                        onDragOver={handleDrag}
                        onDragExit={handleDrag}
                        onDrop={handleDrop}
                        onClick={() => {
                            if (!CSVfile) {
                                csvref.current.click();
                            }
                        }}
                    >
                        <input
                            type="file"
                            style={{ display: 'none' }}
                            ref={csvref}
                            // accept=".csv"
                            accept={excelLabel ? ".xlsx" : ".csv"} 
                            value=""
                            onChange={(e) => {
                                const file = e.target.files[0];
                                if (file) {
                                    setCSVfile(file);
                                } else {
                                    setCSVfile(null);
                                }
                            }}
                        />
                        <img
                            src={images.ImgUpIcon}
                            style={{
                                width: 140,
                                height: 100,
                                objectFit: 'contain',
                                marginBlock: 24,
                            }}
                        ></img>
                        <p
                            style={{
                                padding: 0,
                                margin: 0,
                                fontSize: 12,
                                fontWeight: 600,
                                color: colors.black,
                            }}
                        >
                            {'Drag & drop the file here'}
                        </p>
                        <p
                            style={{
                                padding: 0,
                                margin: 0,
                                fontSize: 12,
                                fontWeight: 600,
                                color: colors.black,
                                marginBlock: 8,
                            }}
                        >
                            {'OR'}
                        </p>
                        <CheerioButton
                            borderStyle={{ paddingBlock: 8, marginBlockEnd: 24, border: "none" }}
                            textStyle={{
                                fontSize: 12,
                                fontWeight: 600,
                                color: colors.white,
                            }}
                            btnText={'Browse Files'}
                            backColor={colors.darkPurple}
                            onclick={() => {eventsend("Browse_file_button_clicked_Upload_contacts_page") }}
                        />
                    </div>
                )}
                {fileUpload && (
                    <div
                        className="d-flex flex-column justify-content-start align-items-center"
                        style={{
                            width: '100%',
                            marginBlock: 24,
                            backgroundColor: colors.white02,
                            border: `1px solid ${colors.greys03}`,
                            borderRadius: 16,
                            padding: 20,
                        }}
                    >
                        <div
                            className="d-flex flex-row justify-content-between align-items-center w-100"
                            style={{ marginBlock: 8 }}
                        >
                            <div
                                className="d-flex flex-row justify-content-start align-items-center"
                                style={{ flex: 1 }}
                            >
                                <img
                                    src={images.CFile}
                                    style={{
                                        height: 24,
                                        width: 24,
                                        objectFit: 'contain',
                                        marginInlineEnd: 8,
                                    }}
                                ></img>
                                <p
                                    style={{
                                        padding: 0,
                                        margin: 0,
                                        fontSize: 16,
                                        fontWeight: 600,
                                        color: colors.black,
                                        overflowWrap: 'break-word',
                                    }}
                                >
                                    {`${CSVfile.name}`}
                                </p>
                            </div>
                            <img
                                src={images.CDelete}
                                style={{
                                    height: 24,
                                    width: 24,
                                    objectFit: 'contain',
                                    cursor: 'pointer',
                                    marginInlineStart: 8,
                                }}
                                onClick={() => {
                                    setCSVfile(null);
                                    setCountOfProgress(0);
                                    setFileUpload(!fileUpload);
                                }}
                            />
                        </div>
                        <ProgressBar
                            variant="customcolor"
                            style={{
                                marginBlock: 4,
                                height: 8,
                                width: 'calc( 100% - 30px)',
                                borderRadius: 4,
                                alignSelf: 'flex-start',
                            }}
                            now={countOfProgress}
                        />
                        {/* <div style={{marginBlock:4,height:4,width:'calc( 100% - 30px)',backgroundColor:colors.primary03,borderRadius:2,alignSelf:'flex-start'}}></div> */}
                        {countOfProgress == 100 && (
                            <>
                                <div
                                    className="d-flex flex-row justify-content-start align-items-center w-100"
                                    style={{
                                        marginBlockStart: 4,
                                        marginBlockEnd: 24,
                                    }}
                                >
                                    <img
                                        src={images.HNProfileSetup}
                                        style={{
                                            height: 20,
                                            width: 20,
                                            objectFit: 'contain',
                                            marginInlineEnd: 8,
                                        }}
                                    ></img>
                                    <p
                                        style={{
                                            padding: 0,
                                            margin: 0,
                                            fontSize: 14,
                                            fontWeight: 400,
                                            color: colors.black04,
                                        }}
                                    >
                                        {'Upload completed, click on next to continue.'}
                                    </p>
                                </div>
                                <CheerioButton
                                    borderStyle={{ border: "none", width: "10%" }}
                                    textStyle={{
                                        fontSize: 12,
                                        fontWeight: 600,
                                        color: colors.white,

                                    }}
                                    btnText={'Next'}
                                    backColor={colors.darkPurple}
                                    disabled={countOfProgress !== 100 || labelName === '' || disableNextBtn}
                                    onclick={async () => {
                                        if(error){
                                            toast.error(error,{position:'top-center'})
                                        }else{
                                           await handleUploadCSV() 
                                        }
                                    }}
                                />
                            </>
                        )}
                    </div>
                )}
            </div>
            <ToastContainer />
        </div>
    )
}

export default UploadingFile