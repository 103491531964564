import React, { useCallback } from 'react'
import colors from '../../../Utils/colors';
import { Handle, Position } from 'reactflow';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import images from '../../../Utils/images';

const handleStyle = { backgroundColor: colors.linkblue, height: 15, width: 15, borderRadius: "100%" };

const SendSMSNode = ({ data, isConnectable }) => {
    return (
        <>
            <div className='d-flex flex-column justify-content-between align-items-center' style={{ border: `1px solid ${colors.borderwhite}`, borderRadius: 16, backgroundColor: colors.white01, width: 250 }}>
                <Handle type='target' position={Position.Left} isConnectable={isConnectable} style={handleStyle} />
                <div className='w-100 d-flex flex-row justify-content-between align-items-center' style={{ paddingInline: 16, paddingBlock: 8, backgroundColor: colors.linkblue, borderTopLeftRadius: 16, borderTopRightRadius: 16 }}>
                    <p style={{ padding: 0, margin: 0, fontSize: 16, fontWeight: 700, color: colors.white }}>
                        {'Send SMS'}
                    </p>
                    <img src={images.Edit} style={{ height: 20, width: 20, objectFit: 'contain' }}></img>
                </div>
                <div className='w-100 d-flex flex-column justify-content-start align-items-center' style={{ paddingInline: 16, paddingBlockStart: 8, paddingBlockEnd: 16 }}>
                    {/* <p style={{padding:0,margin:0,marginBlock:4,fontSize:10,fontWeight:600,color:colors.black,width:'100%'}}>
                {'Subject'}
            </p>
            <InputGroup className="my-1">
                <Form.Control type='text' placeholder={'Enter here'} className='w-100 btncustom'
                style={{borderRadius:8,fontSize:10,fontWeight:400,color:colors.black}}
                // isInvalid={(renewalText.length >= 640)}
                maxLength={640}
                // value={drBodyText}
                onChange={onChangeEmail}
                />
            </InputGroup> */}
                    <p style={{ padding: 0, margin: 0, marginBlock: 4, fontSize: 10, fontWeight: 600, color: colors.black, width: '100%' }}>
                        {'Template'}
                    </p>
                    <InputGroup className="my-1">
                        <Form.Select className='w-100 btncustom' style={{ borderRadius: 8, fontSize: 10, fontWeight: 400, color: colors.black }}>
                            <option>Select template</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                        </Form.Select>
                    </InputGroup>
                    <div className='d-flex justify-content-center align-items-center w-100' style={{ paddingBlock: 16, paddingInline: 16, borderRadius: 8, backgroundColor: '#393939', marginBlockStart: 16 }}>
                        <p style={{ padding: 0, margin: 0, marginBlock: 4, fontSize: 12, fontWeight: 400, color: colors.white, width: '100%' }}>
                            {'Message Preview here'}
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SendSMSNode