import React, { useEffect, useState } from 'react';
import colors from '../../../Utils/colors';
import { Modal } from 'react-bootstrap';
import '../styles.css';
import images from '../../../Utils/images';
import { VariableBody } from '../../../Pages/CustomTemplate/Body';
import { SendAddressPreview } from './SendAddressPreview';
import { SendMetamessages } from '../../../Services';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

export const SendLocationModal = ({
  show,
  setShow,
  text,
  mobile
}) => {
  const mobileWidth = window.innerWidth < 900 ? true : false;
  const Text = text ?? 'Address Location';
  const token = useSelector((state) => state.main.auth_token);
  const Para = Text === 'Request Address' ? 'For requesting an address on WhatsApp. Meta has their own pre-determined form template for requesting an address and is not editable for senders but you can edit body message for the same' : 'For requesting a location on WhatsApp. Meta has their own pre-determined template and is not editable for senders but you can edit body message for the same'
  const [bodyData, setBodyData] = useState({
    type: 'BODY',
    text: '',
    example: {
      body_text: [], // Array of string with length 1
    },
  });

  const [headerData, setHeaderData] = useState({
    type: 'HEADER',
    format: 'NONE',
    text: '',
    example: {
      header_text: [], 
      header_handle: [],
    },
  });

  const [formFields, setFormFields] = useState({
    name: '',
    phone: '',
    pincode: '',
    address: '',
    landmark: '',
    flatNumber: '',
    floorNumber: '',
    towerNumber: '',
    buildingName: '',
    city: ''
  });

  useEffect(() => {
    setBodyData({
      ...bodyData,
    });
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormFields({
      ...formFields,
      [name]: value
    });
  };

  const handleSendMessage = async () => {
    // Implement send message functionality
    const authtoken = token;
    const data = {
      to: mobile,
      type: Text === 'Request Address' ? 'address_message' : 'location_request_message',
      msgBody: bodyData.text,
    }
    const res = await SendMetamessages(data, authtoken);
    if (res.flag) {
      toast.success('location sent successfully');
    }
    console.log("Sending message with form data:", formFields);
    setShow(false);
  };

  return (
    <>
      <Modal
        show={show}
        aria-labelledby="example-custom-modal-styling-title"
        dialogClassName="sendAddressModal"
        contentClassName="sendAddressModal"
      >
        <Modal.Body
          style={{
            width: '100%',
            overflowY: 'auto',
            padding: '0px',
            borderRadius: '22px',
          }}
        >
          <div
            className="d-flex justify-content-between px-3 py-3"
            style={{ alignItems: 'center', borderBottom: '1px solid #CCCCCC' }}
          >
            <p style={{ fontWeight: '700', fontSize: '20px', margin: 0 }}>
              {Text}
            </p>
            <div
              style={{ alignItems: 'center' }}
            >
              <img
                src={images.CMClose}
                onClick={() => {
                  setShow(false);
                }}
                style={{
                  cursor: 'pointer',
                  height: 22,
                  width: 22,
                }}
                alt="Close"
              />
            </div>
          </div>
          <div className="d-flex flex-row" style={{ width: '100%' }}>
            {/* Left Section with Form */}
            <div
              className="px-3"
              style={{
                width: '60%',
                padding: '16px',
                maxHeight: '70vh',
                overflowY: 'auto',
                scrollbarWidth: 'thin',
              }}
            >
              {/* Info Message */}
              <div
                style={{
                  color: colors.greys01,
                  padding: '10px',
                  marginBottom: '20px',
                  borderRadius: '4px',
                  display: 'flex',
                  alignItems: 'flex-start',
                  gap: '8px'
                }}
              >
                <img
                  src={images.InfoCircle}
                  style={{ height: 20, width: 20, objectFit: 'contain', marginTop: '2px' }}
                  alt="Info"
                />
                <p
                  style={{
                    margin: 0,
                    fontSize: '16px',
                    fontFamily: 'Segoe UI',
                    fontWeight: 400,
                    fontStyle: 'italic',
                    lineHeight: '21.28px',
                  }}
                >
                  {Para}
                </p>
              </div>

              {/* Body Message Input */}
              <div style={{ marginBottom: '20px' }}>
                <label style={{ fontWeight: 'bold', marginBottom: '8px', display: 'block' }}>
                  Body Message<span style={{ color: 'red' }}>*</span>
                </label>
                <textarea
                  placeholder="Type a message"
                  style={{
                    width: '100%',
                    minHeight: '100px',
                    padding: '10px',
                    borderRadius: '8px',
                    border: '1px solid #ccc',
                    resize: 'none'
                  }}
                  value={bodyData.text}
                  onChange={(e) => setBodyData({ ...bodyData, text: e.target.value })}
                />
              </div>

              {/* Form Fields Section */}
              {/* Form Fields Section */}
              {Text === 'Request Address' && (
                <div style={{ overflowY: 'auto' }}>
                  <h4 style={{ fontWeight: 'bold', marginBottom: '15px', fontSize: '16px' }}>Form Fields</h4>
                  {/* Name Field */}
                  <div style={{ marginBottom: '15px' }}>
                    <input
                      type="text"
                      name="name"
                      placeholder="Name"
                      disabled
                      style={{
                        width: '100%',
                        padding: '12px',
                        borderRadius: '8px',
                        border: '1px solid #e1e1e1',
                        backgroundColor: 'transparent',
                        color: '#808080'
                      }}
                      value={formFields.name}
                      onChange={handleInputChange}
                    />
                  </div>

                  {/* Phone Field */}
                  <div style={{ marginBottom: '15px' }}>
                    <input
                      type="text"
                      name="phone"
                      placeholder="Phone"
                      disabled
                      style={{
                        width: '100%',
                        padding: '12px',
                        borderRadius: '8px',
                        border: '1px solid #e1e1e1',
                        backgroundColor: 'transparent',
                        color: '#808080'
                      }}
                      value={formFields.phone}
                      onChange={handleInputChange}
                    />
                  </div>
                  {/* Pincode Field */}
                  <div style={{ marginBottom: '15px' }}>
                    <input
                      type="text"
                      name="pincode"
                      placeholder="Pincode"
                      disabled
                      style={{
                        width: '100%',
                        padding: '12px',
                        borderRadius: '8px',
                        border: '1px solid #e1e1e1',
                        backgroundColor: 'transparent',
                        color: '#808080'
                      }}
                      value={formFields.pincode}
                      onChange={handleInputChange}
                    />
                  </div>

                  {/* Address Field */}
                  <div style={{ marginBottom: '15px' }}>
                    <input
                      type="text"
                      name="address"
                      placeholder="Address"
                      disabled
                      style={{
                        width: '100%',
                        padding: '12px',
                        borderRadius: '8px',
                        border: '1px solid #e1e1e1',
                        backgroundColor: 'transparent',
                        color: '#808080'
                      }}
                      value={formFields.address}
                      onChange={handleInputChange}
                    />
                  </div>

                  {/* Landmark / Area Field */}
                  <div style={{ marginBottom: '15px' }}>
                    <input
                      type="text"
                      name="landmark"
                      placeholder="Landmark / Area"
                      disabled
                      style={{
                        width: '100%',
                        padding: '12px',
                        borderRadius: '8px',
                        border: '1px solid #e1e1e1',
                        backgroundColor: 'transparent',
                        color: '#808080'
                      }}
                      value={formFields.landmark}
                      onChange={handleInputChange}
                    />
                  </div>

                  {/* Flat / House Number Field */}
                  <div style={{ marginBottom: '15px' }}>
                    <input
                      type="text"
                      name="flatNumber"
                      placeholder="Flat / House Number (Optional)"
                      disabled
                      style={{
                        width: '100%',
                        padding: '12px',
                        borderRadius: '8px',
                        border: '1px solid #e1e1e1',
                        backgroundColor: 'transparent',
                        color: '#808080'
                      }}
                      value={formFields.flatNumber}
                      onChange={handleInputChange}
                    />
                  </div>

                  {/* Floor Number Field */}
                  <div style={{ marginBottom: '15px' }}>
                    <input
                      type="text"
                      name="floorNumber"
                      placeholder="Floor Number (Optional)"
                      disabled
                      style={{
                        width: '100%',
                        padding: '12px',
                        borderRadius: '8px',
                        border: '1px solid #e1e1e1',
                        backgroundColor: 'transparent',
                        color: '#808080'
                      }}
                      value={formFields.floorNumber}
                      onChange={handleInputChange}
                    />
                  </div>

                  {/* Tower Number Field */}
                  <div style={{ marginBottom: '15px' }}>
                    <input
                      type="text"
                      name="towerNumber"
                      placeholder="Tower Number (Optional)"
                      disabled
                      style={{
                        width: '100%',
                        padding: '12px',
                        borderRadius: '8px',
                        border: '1px solid #e1e1e1',
                        backgroundColor: 'transparent',
                        color: '#808080'
                      }}
                      value={formFields.towerNumber}
                      onChange={handleInputChange}
                    />
                  </div>

                  {/* Building / Apartment Name Field */}
                  <div style={{ marginBottom: '15px' }}>
                    <input
                      type="text"
                      name="buildingName"
                      placeholder="Building / Apartment Name (Optional)"
                      disabled
                      style={{
                        width: '100%',
                        padding: '12px',
                        borderRadius: '8px',
                        border: '1px solid #e1e1e1',
                        backgroundColor: 'transparent',
                        color: '#808080'
                      }}
                      value={formFields.buildingName}
                      onChange={handleInputChange}
                    />
                  </div>

                  {/* City Field */}
                  <div style={{ marginBottom: '15px' }}>
                    <input
                      type="text"
                      name="city"
                      placeholder="City"
                      disabled
                      style={{
                        width: '100%',
                        padding: '12px',
                        borderRadius: '8px',
                        border: '1px solid #e1e1e1',
                        backgroundColor: 'transparent',
                        color: '#808080'
                      }}
                      value={formFields.city}
                      onChange={handleInputChange}
                    />
                  </div>


                </div>
              )}
            </div>

            {/* Right Section with Preview */}
            <div
              style={{
                width: '40%',
                padding: '16px',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <div
                style={{
                  border: `1px solid ${colors.borderwhite || '#e1e1e1'}`,
                  borderRadius: '16px',
                  padding: '16px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  height: 'calc(100% - 60px)',
                  backgroundColor: '#f9f9f9'
                }}
              >
                <h4 style={{
                  fontSize: '16px',
                  fontWeight: '700',
                  color: colors.black || '#000',
                  marginBottom: '16px'
                }}>
                  Message Preview
                </h4>
                <SendAddressPreview
                  body={bodyData}
                  header={headerData}
                  text={text}
                />
              </div>

              {/* Send Message Button */}
              <button
                onClick={handleSendMessage}
                style={{
                  width: '100%',
                  padding: '12px',
                  borderRadius: '8px',
                  border: 'none',
                  backgroundColor: '#B388FF',
                  color: 'white',
                  fontWeight: '500',
                  marginTop: '16px',
                  cursor: 'pointer'
                }}
              >
                Send message
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};