import { useEffect, useState } from 'react';
import './index.scss';
import '../../sass/pages/_contacts.scss';
import CheerioButton from '../../Components/CheerioButton';
import images from '../../Utils/images';
import colors from '../../Utils/colors';
import { AiOutlineSearch } from 'react-icons/ai';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import { FiEdit2, FiTrash2 } from 'react-icons/fi';
import { Tooltip } from '@mui/material';
import AddKeywordAction from './Components/AddKeywordAction';
import { useSelector } from 'react-redux';
import { deleteKeywordsAction, getKeywordsByID, getKeywordsList, ExportKeywordFile, ImportKeywordfile } from '../../Services';
import { toast } from 'react-toastify';
import Helper from '../../Utils/Helper';
import { trackPages } from '../../Utils/brevo';
import { eventsend } from '../../Config/analyticsFunctions';
import { useNavigate } from 'react-router-dom';
import ImportWorkflow from '../WorkflowTabels/ImportWorkFlow'
import SpinnerLoader from '../../Components/SpinnerLoader';

const columns = [
  { id: 'keywords', label: 'Keyword', align: 'left', minWidth: 100, width: 200 },
  // { id: 'Triggered', label: 'Triggered', align: 'center', minWidth: 100, width: 120 },
  { id: 'keywordCriteria', label: 'Matching', align: 'center', minWidth: 100, width: 120 },
  { id: 'action', label: 'Reply Action', align: 'left', maxWidth: 100, width: 150 },
  { id: 'Action', label: 'Action', align: 'center', maxWidth: 100, width: 120 },
];

// const rows = [
//     {
//         Keyword: ['Hello',],
//         Triggered: 2,
//         Matching: 'Exact',
//         ReplyAction: ['Send Message',],
//     },
//     {
//         Keyword: ['GoodbyeGoodbye', 'Bye', 'Goodbye', 'ByeBye', 'Goodbye', 'Bye'],
//         Triggered: 95,
//         Matching: 'Partial',
//         ReplyAction: ['Send'],
//     },
//     {
//         Keyword: ['HelpHelpHelp'],
//         Triggered: 2525,
//         Matching: 'Exact',
//         ReplyAction: ['GoodbyeGoodbye', 'Bye', 'Goodbye', 'ByeBye', 'Goodbye', 'Bye'],
//     },
// ];

const Keywords = () => {
  const [actionButtons, setActionButtons] = useState([<FiEdit2 />, <FiTrash2 />]); // [edit, delete
  const [searchKeyword, setSearchKeyword] = useState('');
  const [addKeywordAction, setAddKeywordAction] = useState(false);
  const [rows, setRows] = useState([]);
  const authtoken = useSelector((state) => state.main.auth_token);
  const [existingDataKA, setExistingDataKA] = useState({});
  const localdata = useSelector((state) => state.main.localdata);
  const isPremiumUser = localdata?.premium;
  const agentdata = useSelector((state) => state.main.agentdata);
  const navigate = useNavigate();
  const [isLoading,setisLoading] = useState(false);
  const [openKeywordModal, setopenKeywordModal] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);


  useEffect(() => {
    trackPages('automation_keywords_page', localdata);
    if (agentdata && !agentdata?.permission?.keywordAutomation) {
      toast.error(
        'Feature access denied. Kindly request the owner to grant you permission to access this feature'
      );
      navigate('/creator');
    }
    // if (!isPremiumUser) {
    //   const defaultData = [
    //     {
    //       _id: '6662970755a55bbac6d4d8f7',
    //       action: 'assignWorkflow',
    //       keywords: ['Efyd1'],
    //       keywordCriteria: 'exact match',
    //       data: {
    //         key: 'Workflow',
    //         workflowId: '656864b2dbd034fe17c5bf8c',
    //       },
    //       status: true,
    //       createdAt: '2024-06-07T05:13:43.470Z',
    //       updatedAt: '2024-06-07T05:25:01.928Z',
    //       __v: 0,
    //     },
    //   ];
    //   setRows(defaultData);
    // }
    getKeywordsListAPICall(1, rowsPerPage);
  }, []);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getKeywordsListAPICall(newPage + 1, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    getKeywordsListAPICall(1, newRowsPerPage);
  };

  const getKeywordsListAPICall = (pageNum = 1, limit = rowsPerPage, search = '') => {
    setisLoading(true);
    let token = authtoken;
    getKeywordsList(token, pageNum, limit, search)
      .then((res) => {
        if (res?.flag) {
          setRows(res?.data);
          setTotalRows(res?.totalRecords);
        } else {
          toast.error(res, { position: 'top-right' });
        }
      })
      .catch((error) => {
        console.error("Error fetching keywords:", error);
        toast.error("Failed to fetch keywords. Please try again.", { position: 'top-right' });
      })
      .finally(() => {
        setisLoading(false);
      });
};

  const DownloadFile = async () => {
    try {
      let Response = await ExportKeywordFile(authtoken);
      if (Response.status === 200) {
        const response = Response.data;

        if (!response || !(response instanceof Blob)) {
          console.error('Invalid response received from API.');
          return;
        }
        const url = window.URL.createObjectURL(response);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Keyword_export.json');

        document.body.appendChild(link);
        link.click();
        link.remove();

        window.URL.revokeObjectURL(url);
        toast.success('File downloaded successfully!');
      } else if (Response.status === 500) {
        toast.error(Response.statusText || 'Failed to download file. Please try again');
      } else {
        console.error(`Unexpected status code: ${Response.status}`);
      }
    } catch (error) {
      console.error('Error downloading file:', error);
      toast.error('Failed to download file. Please try again.');
    }
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchKeyword(value);
    setPage(0);
    getKeywordsListAPICall(1, rowsPerPage, value);
  };
  const filteredRows =
    searchKeyword.trim() === ''
      ? rows
      : rows?.filter((row) => {
        const keywords = row?.keywords?.map((kw) => kw?.toLowerCase()) || [];
        return keywords.some((keyword) =>
          keyword?.toLowerCase()?.includes(searchKeyword?.toLowerCase())
        );
      });

  const onclickAction = (i, row) => {
    if (i == 0) {
      handleEditClick(row);
      eventsend("Edit_keyword_Keywords_page_clicked");
    } else if (i == 1) {
      handleDeleteClick(row);
      eventsend("Delete_keyword_Keywords_page_clicked");
    }
  };

  const handleEditClick = (row) => {
    // Handle edit click for the row
    console.log('Edit clicked for row:', row);
    Helper.HelperKeywordActionID = row?._id;
    Helper.HelperEditKeywordAction = true;
    setExistingDataKA(row);
    setAddKeywordAction(true);
    // let data = {
    //     _id: row?._id,
    // }
    // getKeywordsByID(authtoken,data).then((res)=>{
    //     if(res?.flag){
    //         console.log("success by ID ----> ",res);
    //     }else{
    //         console.log("error by ID -----> ",res);
    //     }
    // })
  };

  const handleDeleteClick = (row) => {
    let data = {
      _id: row?._id,
    };
    deleteKeywordsAction(authtoken, data).then((res) => {
      if (res?.flag) {
        toast.success('Keyword action deleted successfully', {

          position: 'top-right',
        });
        getKeywordsListAPICall();
      } else {
        toast.error('Failed to delete keyword action, please try again later', {

          position: 'top-right',
        });
      }
    });
  };

  return (
    <>
      {openKeywordModal && (
        <ImportWorkflow
          show={openKeywordModal}
          setShow={setopenKeywordModal}
          apiAction={(payload, token) => ImportKeywordfile(payload, token)}
          title="Import Keywords"
        />
      )}
      {addKeywordAction ? (
        <>
          <AddKeywordAction
            setAddKeywordAction={setAddKeywordAction}
            saveSuccessAction={() => {
              setTimeout(() => {
                getKeywordsListAPICall();
                setAddKeywordAction(false);
              }, 1500);
            }}
            existingDataKA={existingDataKA}
          />
        </>
      ) : (
        <>
          <div className="KeywordsDiv">
          {isLoading ? (
                <div className="d-flex align-items-center justify-content-center" style={{ height: '400px' }}>
                    <SpinnerLoader />
                </div>
            ) : (
              <>
            <div className="flex">
              <div className="pt-2">
                <h1 className= 'mb-2'>Keyword Triggers</h1>
                <p className="note">
                  Note: when a message matches keyword template/flow gets triggered and sends
                  a message.
                </p>
              </div>

              <div className="search_container">
                <div
                  className="search_upload_row flex"
                  style={{ flexWrap: 'wrap', paddingBlock: 4, gap: '16px' }}
                >
                  <div
                  style={{
                    display: 'flex',
                    gap: '8px',
                  }}>
                  <div className="logged_input">
                    <AiOutlineSearch size={18} />
                    <input
                      type={'text'}
                      className="search_input"
                      placeholder={`Search Keyword`}
                      value={searchKeyword}
                      onChange={handleSearchChange}
                    />
                  </div>
                    <CheerioButton
                    borderStyle={{
                      borderRadius: '8px',
                      padding: '5px 10px',
                    }}
                      textStyle={{
                        color: '#000000',
                        fontWeight: 600,
                        fontSize: '14.67px',
                      }}
                      btnText="Import"
                      icon={images.IMPORTICON}
                      iconStyle={{
                        height: 24,
                        width: 24,
                      }}
                      onclick={() => setopenKeywordModal(true)}
                    />

                    <CheerioButton
                    borderStyle={{
                      borderRadius: '8px',
                    padding: '5px 10px',
                    }}
                      textStyle={{
                        color: '#000000',
                        fontSize: '14.67px',
                        fontWeight: 600,
                      }}
                      btnText="Export"
                      icon={images.FILEEXPORT}
                      iconStyle={{
                        height: 24,
                        width: 24,
                      }}
                      onclick={() => {
                        DownloadFile();
                      }}
                    />
                    <CheerioButton
                      borderStyle={{
                        paddingInline: 8,
                        paddingBlock: 6,
                        borderRadius: 8,
                        border: 'none',
                      }}
                      textStyle={{
                        fontWeight: 600,
                        color: colors.white01,
                        fontSize: '12px',
                      }}
                      btnText="Add Keyword"
                      icon={images.AddIcon}
                      backColor={colors.darkPurple}
                      onclick={() => {
                        Helper.HelperEditKeywordAction = false;
                        setExistingDataKA({});
                        eventsend("Add_keyword_Keywords_page_clicked");
                        setAddKeywordAction(true);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="table" style={{ display: 'flex', flexDirection: 'column' }}>
            <TableContainer>
                {/* <TableContainer sx={{ flex: 1, height: "520px", overflow: "auto" }}> */}
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            minWidth: column.minWidth,
                            width: column.width,
                            fontSize: 16,
                            fontWeight: 600,
                            color: colors.black,
                            backgroundColor: colors.white04,
                          }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredRows.map((row, index) => (
                      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <>
                              <TableCell
                                key={column.id}
                                align={column.align}
                                style={{
                                  minWidth: column.minWidth,
                                  width: column.width,
                                }}
                              >
                                {column.id === 'keywords' ? (
                                  <div
                                    style={{
                                      color: colors.black,
                                      fontSize: 14,
                                      display: 'flex',
                                      flexWrap: 'wrap',
                                    }}
                                    className="keywordData"
                                  >
                                    {value.map((v, i) => (
                                      <Tooltip title={v} key={i}>
                                        <span className={'keyword'}>
                                          {v.length > 20 ? v.slice(0, 18) + '...' : v}
                                        </span>
                                      </Tooltip>
                                    ))}
                                  </div>
                                ) : column.id === 'action' ? (
                                  <span className="replyAction">{value}</span>
                                ) : column.id === 'Action' ? (
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      gap: 15,
                                    }}
                                  >
                                    {actionButtons.map((btn, i) => (
                                      <Tooltip title={i === 0 ? 'Edit' : 'Delete'} key={i}>
                                        <div
                                          style={{ cursor: 'pointer' }}
                                          onClick={() => onclickAction(i, row)}
                                        >
                                          {btn}
                                        </div>
                                      </Tooltip>
                                    ))}
                                  </div>
                                ) : (
                                  <p style={{ color: colors.black, fontSize: 14 }}>{value}</p>
                                )}
                              </TableCell>
                            </>
                          );
                        })}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <div style={{ width :'100%',marginTop: '2px', borderTop: '1px solid rgba(224, 224, 224, 1)' ,marginRight:50,}}>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  component="div"
                  count={totalRows}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  style={{
                    width:'100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginRight:50,
                  }}
                />
              </div>
            </div>
            </>
             )}
          </div>
        </>
      )}
    </>
  );
};

export default Keywords;
