import React, { useEffect } from 'react'
import Home from './Pages/Home'
import { Route, Routes } from 'react-router-dom'
import CreateSequence from './Pages/CreateSequence'
import SequenceAnalytics from './Pages/SequenceAnalytics'
import { eventsend } from '../../Config/analyticsFunctions'

const Sequences = () => {
  useEffect(()=>{
    eventsend("Sequences-page_view");
  },[])
  return (
    <>
      {/* {location.pathname === '/creator/sequences' && (
        <Home />
    )} */}
      <Routes>
        <Route path='/' element={<Home />} ></Route>
        <Route path='create' element={<CreateSequence />} ></Route>
        <Route path='create/:id' element={<CreateSequence />} ></Route>
        <Route path='analytics/:id' element={<SequenceAnalytics />} ></Route>
      </Routes>
    </>
  )
}

export default Sequences
