import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getEmbeddedFormsResponseById, downloadFormResponses, deleteEmbeddedFormResponse } from '../../Services';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { gettime } from '../../Utils/localdate';
import SpinnerLoader from '../../Components/SpinnerLoader';
import colors from '../../Utils/colors';
import images from '../../Utils/images';
import CheerioButton from '../../Components/CheerioButton';
import { toast } from 'react-toastify';
import Pagination from '@mui/material/Pagination';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

export const DeleteModal = ({ setDeleteModalOpen, deleteAction }) => {
    return (
        <div style={{
            width: '100%',
            height: "100%",
            zIndex: 1000,
            top: 0,
            left: 0,
            position: 'fixed',
            background: 'rgba(0,0,0,0.5)',
        }}>
            <div style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%,-50%)',
            }}>
                <div
                    className="d-flex flex-column justify-content-start align-items-center"
                    style={{
                        width: 450,
                        backgroundColor: colors.white01,
                        borderRadius: 16,
                        zIndex: 0,
                        border: `1px solid ${colors.borderwhite}`,
                        paddingBlock: 20,
                    }}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    <div
                        className="d-flex flex-row justify-content-between align-items-center w-100"
                        style={{
                            paddingBlockEnd: 8,
                            borderBottom: `1px solid ${colors.borderwhite}`,
                            paddingInline: 16,
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: '4px',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                            }}
                        >
                            <img src={images.CDelete} style={{ height: 24, width: 'auto' }} alt="Delete" />
                            <p
                                style={{
                                    padding: 0,
                                    margin: 0,
                                    fontSize: 18,
                                    fontWeight: 700,
                                    color: colors.black,
                                }}
                            >
                                Delete Response
                            </p>
                        </div>
                        <img
                            src={images.CMClose}
                            style={{
                                height: 24,
                                width: 24,
                                objectFit: 'contain',
                                cursor: 'pointer',
                            }}
                            onClick={() => setDeleteModalOpen(false)}
                            alt="Close"
                        />
                    </div>
                    <p
                        style={{
                            margin: 12,
                            fontSize: 16,
                            fontWeight: 500,
                            color: colors.black,
                            marginInline: 16,
                        }}
                    >
                        Are you sure you want to delete this form response? This action cannot be undone.
                    </p>
                    <div
                        className="d-flex flex-row justify-content-center align-items-center w-100"
                        style={{ marginTop: 12, gap: '10px' }}
                    >
                        <CheerioButton
                            borderStyle={{
                                width: '40%',
                                marginInlineStart: 8,
                                border: `1px solid ${colors.primary03}`,
                            }}
                            textStyle={{
                                fontSize: 14,
                                fontWeight: 600,
                                color: colors.primary03,
                            }}
                            btnText={'Cancel'}
                            backColor={colors.white}
                            onclick={() => setDeleteModalOpen(false)}
                        />
                        <CheerioButton
                            borderStyle={{
                                borderColor: "#EA2727",
                                width: '40%',
                                marginInlineEnd: 8,
                            }}
                            textStyle={{
                                fontSize: 14,
                                fontWeight: 600,
                                color: colors.white,
                            }}
                            btnText={'Delete'}
                            backColor={colors.red49}
                            onclick={deleteAction}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};



const EFResponses = () => {
    const { id, formName } = useParams();
    const navigate = useNavigate();
    const authtoken = useSelector((state) => state.main.auth_token);
    const [responses, setResponses] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const [totalDocs, setTotalDocs] = useState(0);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [responseToDelete, setResponseToDelete] = useState(null);
    const [allFormFields, setAllFormFields] = useState([]);


    const handleDeleteClick = (responseId) => {
        setResponseToDelete(responseId);
        setDeleteModalOpen(true);
    };

    const deleteAction = async () => {
        if (responseToDelete) {
            await handleDeleteResponse(responseToDelete);
            setDeleteModalOpen(false);
            setResponseToDelete(null);
        }
    };

    useEffect(() => {
        fetchFormResponses(page, limit);
    }, [page, limit]);

    const fetchFormResponses = async (currentPage, currentLimit) => {
        try {
            const response = await getEmbeddedFormsResponseById(authtoken, id, currentPage, currentLimit);
            if (response?.flag) {
                setResponses(response.data.docs);
                setTotalPages(response.data.totalPages);
                setTotalDocs(response.data.totalDocs);

                // Extract all unique field titles across all responses
                const fieldSet = new Set();
                response?.data?.docs?.forEach(doc => {
                    doc?.formResponses?.forEach(field => {
                        fieldSet.add(field.title);
                    });
                });
                setAllFormFields(Array.from(fieldSet));
            }
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching responses:', error);
            setIsLoading(false);
        }
    };

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    const handleLimitChange = (event) => {
        setLimit(parseInt(event.target.value));
        setPage(1);
    };

    const handleDeleteResponse = async (responseId) => {
        try {
            const data = {
                id: id,
                responseId: responseId
            }
            const response = await deleteEmbeddedFormResponse(authtoken, data);
            if (response?.flag) {
                toast.success('Response deleted successfully!', { position: 'top-right', autoClose: true });
                // Refresh the responses after deletion
                fetchFormResponses();
            }
        } catch (error) {
            console.error('Error deleting response:', error);
            toast.error('Failed to delete response', { position: 'top-right', autoClose: true });
        }
    };

    const handleExport = async () => {
        try {
            const response = await downloadFormResponses(id, authtoken);
            const csvData = response;
            const blob = new Blob([csvData], { type: 'text/csv' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${formName}_responses.csv`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
            toast.success('Responses exported successfully!', { position: 'top-right', autoClose: true });
        } catch (error) {
            console.error('Error exporting responses:', error);
            toast.error('Failed to export responses', { position: 'top-right', autoClose: true });
        }
    };


    const getColumns = () => {
        if (responses.length === 0) return [];

        return [
            {
                id: 'actions',
                label: 'Actions',
                width: '100px',
                align: 'center'
            },
            ...allFormFields.map(fieldTitle => ({
                id: fieldTitle,
                label: fieldTitle,
                width: '200px',
                align: 'left'
            })),
            {
                id: 'submittedAt',
                label: 'Submitted At',
                width: '200px',
                align: 'left'
            },
        ];
    };

    const getFieldResponse = (formResponses, fieldTitle) => {
        const field = formResponses.find(f => f.title === fieldTitle);
        return field ? field.response : "";
    };

    return (
        <div className="d-flex flex-column justify-content-start align-items-center"
            style={{ width: '100%', padding: '24px 30px' }}>
            <div className="d-flex flex-row justify-content-between align-items-center w-100">
                <div className="d-flex flex-row align-items-center">
                    <img
                        src={images.BackArrowBlack}
                        alt="Back"
                        style={{
                            height: 24,
                            width: 24,
                            objectFit: 'contain',
                            cursor: 'pointer',
                            marginRight: '16px'
                        }}
                        onClick={() => navigate(-1)}
                    />
                    <h3>{decodeURIComponent(formName)} </h3>
                </div>
                <CheerioButton
                    btnText="Export"
                    backColor={colors.darkPurple}
                    textStyle={{ color: colors.white }}
                    borderStyle={{ borderColor: colors.darkPurple }}
                    icon={images.EXPORT_ICON}
                    iconStyle={{
                        height: 20,
                        width: 20,
                        marginRight: '8px',
                        filter: 'brightness(0) invert(1)' // This will make the icon white
                    }}
                    onclick={handleExport}
                />
            </div>

            {isLoading ? (
                <div className="d-flex flex-column justify-content-center align-items-center w-100 h-100">
                    <SpinnerLoader />
                </div>
            ) : responses.length === 0 ? (
                <div className="d-flex flex-column justify-content-center align-items-center"
                    style={{ marginTop: '150px' }}>
                    <img
                        src={images.FormResponses}
                        alt="No responses"
                        style={{ width: '300px', marginBottom: '20px' }}
                    />
                    <p style={{ fontSize: '20px', color: colors.black }}>
                        No response received
                    </p>
                </div>
            ) : (
                <>
                    <TableContainer sx={{ marginTop: 2 }}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    {getColumns().map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{
                                                width: column.width,
                                                fontSize: 16,
                                                fontWeight: 500,
                                                color: colors.black,
                                                backgroundColor: colors.white04,
                                            }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {responses.map((response, index) => (
                                    <TableRow hover key={index}>
                                        <TableCell align="center">
                                            <img
                                                src={images.DeleteBlue}
                                                alt="Delete"
                                                style={{
                                                    height: 20,
                                                    width: 20,
                                                    cursor: 'pointer'
                                                }}
                                                onClick={() => handleDeleteClick(response?._id)}
                                            />
                                        </TableCell>
                                        {/* Map through all possible fields instead of just the ones in this response */}
                                        {allFormFields.map((fieldTitle) => (
                                            <TableCell key={fieldTitle}>
                                                <p
                                                    style={{
                                                        fontSize: 14,
                                                        color: colors.black,
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap',
                                                        maxWidth: '200px'
                                                    }}
                                                    title={getFieldResponse(response?.formResponses, fieldTitle)}
                                                >
                                                    {getFieldResponse(response?.formResponses, fieldTitle)}
                                                </p>
                                            </TableCell>
                                        ))}
                                        <TableCell>
                                            <p style={{ fontSize: 14, color: colors.black }}>
                                                {gettime(response.createdAt)}
                                            </p>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div className="d-flex justify-content-end align-items-center w-100"
                        style={{ marginTop: '20px', padding: '0 16px', gap: '40%' }}>
                        <Pagination
                            count={totalPages}
                            page={page}
                            onChange={handlePageChange}
                            color="primary"
                            shape="rounded"
                            showFirstButton
                            showLastButton
                        />

                        <div className="d-flex align-items-center">
                            <span style={{ marginRight: '10px', color: colors.black }}>Rows per page:</span>
                            <Select
                                value={limit}
                                onChange={handleLimitChange}
                                size="small"
                                sx={{
                                    minWidth: 80,
                                    '& .MuiSelect-select': {
                                        padding: '5px 14px',
                                    }
                                }}
                            >
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={25}>25</MenuItem>
                                <MenuItem value={50}>50</MenuItem>
                            </Select>
                        </div>
                    </div>
                </>
            )}
            {deleteModalOpen && (
                <DeleteModal
                    setDeleteModalOpen={setDeleteModalOpen}
                    deleteAction={deleteAction}
                />
            )}
        </div>
    );
};

export default EFResponses;