import React, { useEffect, useRef, useState } from 'react';
import { Parser } from 'html-to-react';
import colors from '../../Utils/colors';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import './index.css';
import images from '../../Utils/images';
import ProgressBar from 'react-bootstrap/esm/ProgressBar';
import {
  contactlabellistAPI,
  contactlabellistWithoutCountAPI,
  customHTMLsendEmailApi,
  getlabelbalanceAPI,
} from '../../Services';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Modal from '../../Components/Modal/Modal';
import Alert from 'react-bootstrap/Alert';

const CreatorHTMLUpload = () => {
  const [proceedNext, setProceedNext] = useState(false);
  const [HTMLFile, setHTMLFile] = useState();
  const [rhtml, setrhtml] = useState();
  const htmlref = useRef();
  const [nameC, setNameC] = useState('');
  const [subjectC, setSubjectC] = useState('');
  const [selectedLabel, setSelectedLabel] = useState('');
  const [countOfProgress, setCountOfProgress] = useState(0);
  const [fileUploaded, setFileUploaded] = useState(false);

  const [labelList, setLabelList] = useState();
  const [balance, setBalance] = useState();

  const [isOpen, setIsOpen] = useState(false);
  const [TRtype, setTRtype] = useState('');
  const [dragActive, setDragActive] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const navigate = useNavigate();
  const _id = useSelector((state) => state.main._id);
  const auth_token = useSelector((state) => state.main.auth_token);
  const localdata = useSelector((state) => state.main.localdata);
  const isPremiumUser = localdata?.premium;

  useEffect(() => {
    console.log('************************************************');
    console.log('******* HTML file updated**********************');
    console.log('************************************************');
    console.log('*******', HTMLFile);
    console.log('************************************************');
  }, [HTMLFile]);

  useEffect(() => {
    if (HTMLFile) {
      // convertToRead(HTMLFile);
      setFileUploaded(true);
      const timer = setInterval(() => {
        setCountOfProgress((oldProgress) => {
          if (oldProgress == 100) clearInterval(timer);
          return Math.min(oldProgress + Math.random() * 10, 100);
        });
      }, 200);
    }
  }, [HTMLFile]);

  const handlehtmlupload = async (e) => {
    const file = e.target.files[0];
    setHTMLFile(file);
    const readhtml = await convertToRead(file);
    setrhtml(readhtml);
  };
  const handlehtmluploadDND = async (e) => {
    const file = e;
    setHTMLFile(file);
    const readhtml = await convertToRead(file);
    setrhtml(readhtml);
  };

  const convertToRead = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsText(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  useEffect(() => {
    if (rhtml) {
      console.log('///////////////////////////////////////////');
      console.log('///////', rhtml);
      console.log('///////////////////////////////////////////');
    }
  }, [rhtml]);

  useEffect(() => {
    if (proceedNext) {
      getlabellistapicall();
    }
  }, [proceedNext]);

  const getlabellistapicall = () => {
    let token = auth_token;
    // contactlabellistAPI(token).then((res) => {
    contactlabellistWithoutCountAPI(token).then((res) => {
      if (res?.flag) {
        setLabelList(res.data.labelList);
      } else {
        console.log('err ===> ', res);
      }
    });
  };

  useEffect(() => {
    if (selectedLabel) {
      getlabelbalanceapicall();
    }
  }, [selectedLabel]);

  const getlabelbalanceapicall = () => {
    let data = {
      user_id: _id,
      label: selectedLabel,
    };
    let token = auth_token;
    getlabelbalanceAPI(data, token).then((res) => {
      if (res.flag) {
        setBalance(res.data.templateList);
      }
    });
  };

  const makeCustomAnnouncement = () => {
    let data = {
      email: JSON.stringify(balance.emailArr),
      user_id: _id,
      label: selectedLabel,
      campaign_name: nameC,
      file: HTMLFile,
      subject: subjectC,
    };
    let token = auth_token;
    console.log('data -------------> ', data);
    customHTMLsendEmailApi(data, token).then((res) => {
      if (res.flag) {
        console.log(res);
        startTransitionAction();
      } else {
        console.log('err ---------> ', res);
      }
    });
  };

  const startTransitionAction = () => {
    setTRtype('TRE');
    setTimeout(() => {
      setTRtype('success');
      setTimeout(() => {
        setTRtype(null);
        navigate('/creator');
      }, 2000);
    }, 2000);
  };

  useEffect(() => {
    if (TRtype) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [TRtype]);

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (
      e.dataTransfer.files &&
      e.dataTransfer.files[0] &&
      e.dataTransfer.files[0].type == 'text/html'
    ) {
      handlehtmluploadDND(e.dataTransfer.files[0]);
    } else {
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 2000);
    }
  };

  return (
    <>
      <div
        className="w-100 d-flex flex-column justify-content-around align-items-center"
        style={{ paddingBlock: 18, paddingInline: 128, height: 'calc(100% - 80px)' }}
      >
        {showAlert && (
          <>
            <Alert
              variant="danger"
              onClose={() => setShowAlert(false)}
              dismissible
              style={{ position: 'absolute', top: 20 }}
            >
              <Alert.Heading>Wrong file format uploaded</Alert.Heading>
              <p>Please upload only .html files.</p>
            </Alert>
          </>
        )}
        {isOpen && (
          <Modal setIsOpen={setIsOpen} settype={setTRtype} type={TRtype} header={`${nameC}`} />
        )}
        {proceedNext ? (
          <>
            <div
              className="d-flex flex-column justify-content-between align-items-center w-100 py-2 px-4"
              style={{ ...Styles.itemborder, overflowY: 'scroll', height: '100%' }}
            >
              <text style={{ ...Styles.TxtHeader, textAlign: 'center', paddingBlock: 8 }}>
                {'Upload HTML'}
              </text>
              <text style={{ ...Styles.TxtBody, textAlign: 'center', paddingBlock: 8 }}>
                {'You can upload your custom HTML for Emails only'}
              </text>
              <InputGroup className="my-3 w-50">
                <Form.Label style={{ ...Styles.TxtBody, fontSize: 24, marginBlock: 8 }}>
                  {'Name your Campaign'}
                </Form.Label>
                <span
                  className="ms-auto"
                  style={{
                    color: nameC.length > 30 ? colors.error04 : colors.greys04,
                    fontSize: 12,
                    fontWeight: 400,
                    padding: 0,
                    margin: 0,
                  }}
                >
                  {`${nameC.length}/30`}
                </span>
                <Form.Control
                  type="text"
                  placeholder="Type here"
                  className="w-100 btncustom"
                  style={{ borderRadius: 8, marginBlock: 8 }}
                  value={nameC} 
                  maxLength={30}
                  isInvalid={nameC.length > 30}
                  onChange={(e) => {
                    setNameC(e.target.value);
                  }}
                />
                <text
                  style={{ ...Styles.TxtBody2, marginBlock: 8, flexWrap: 'wrap', fontWeight: 400 }}
                >
                  {'Enter campaign name'}
                </text>
              </InputGroup>
              <InputGroup className="mt-2 mb-3 w-50">
                <Form.Label style={{ ...Styles.TxtBody, fontSize: 24, marginBlock: 8 }}>
                  {'Add a Subject line'}
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Add Email subject here"
                  className="w-100 btncustom"
                  style={{ borderRadius: 8, marginBlock: 8 }}
                  value={subjectC}
                  onChange={(e) => {
                    setSubjectC(e.target.value);
                  }}
                />
                {/* <text style={{...Styles.TxtBody2,marginBlock:8,flexWrap:'wrap',fontWeight:400}}>
                                {'Enter campaign name'}
                            </text> */}
              </InputGroup>
              <InputGroup className="mb-3 w-50">
                <Form.Label style={{ ...Styles.TxtBody, fontSize: 24, marginBlock: 8 }}>
                  {'Select a Label'}
                </Form.Label>
                <Form.Select
                  type="text"
                  placeholder="Select"
                  className="w-100 btncustom"
                  style={{ borderRadius: 8, marginBlock: 8 }}
                  value={selectedLabel}
                  onChange={(e) => {
                    setSelectedLabel(e.target.value);
                  }}
                >
                  <option
                    style={{ width: '100%', marginBlock: 4, borderRadius: 8, ...Styles.TxtBody }}
                    key="blankchoice"
                    hidden
                    value
                  >
                    {'Select label'}
                  </option>
                  {labelList &&
                    labelList.map((e) => {
                      return (
                        <option
                          style={{
                            width: '100%',
                            marginBlock: 4,
                            borderRadius: 8,
                            ...Styles.TxtBody2,
                          }}
                          value={e.label}
                        >
                          {e.label}
                        </option>
                      );
                    })}
                </Form.Select>

                <text
                  style={{ ...Styles.TxtBody2, marginBlock: 8, flexWrap: 'wrap', fontWeight: 400 }}
                >
                  {'“select pre-existing label”'}
                </text>
              </InputGroup>
              <div className="d-flex flex-row justify-content-between align-items-center w-50 mb-4">
                <text style={{ ...Styles.TxtBody, fontSize: 24 }}>{'Email balance'}</text>
                <text style={{ ...Styles.TxtBody, fontSize: 24, color: colors.primary03 }}>
                  {balance ? balance.balance.email : ''}
                  <span style={{ color: colors.error04 }}>
                    {balance ? `-(${balance.emailArr.length})` : ''}
                  </span>
                </text>
              </div>
              <div className="w-50 my-2" style={{ height: 'auto' }}>
                {Parser().parse(rhtml)}
              </div>
              <Button
                variant="primary"
                className="mx-2 py-2 btncustom"
                style={{
                  width: 'auto',
                  backgroundColor: colors.primary03,
                  borderWidth: 0,
                  marginBlock: 60,
                  ...Styles.TxtBody,
                  color: colors.white,
                  paddingInline: 100,
                }}
                onClick={() => {
                  if (!isPremiumUser) return;
                  makeCustomAnnouncement();
                }}
                disabled={nameC && subjectC && selectedLabel && isPremiumUser ? false : true}
              >
                {'Make announcement'}
              </Button>
            </div>
          </>
        ) : (
          <>
            <div
              className="d-flex flex-column justify-content-between align-items-center w-100 py-2 px-4"
              style={{ height: 600, ...Styles.itemborder, position: 'relative' }}
            >
              <img
                src={images.CreatorClose}
                style={{
                  height: 24,
                  width: 24,
                  objectFit: 'contain',
                  position: 'absolute',
                  right: 20,
                  top: 20,
                  cursor: 'pointer',
                }}
                onClick={() => {
                  navigate('/creator/announcements');
                }}
              ></img>
              <text style={{ ...Styles.TxtHeader, textAlign: 'center', paddingBlock: 8 }}>
                {'Upload HTML'}
              </text>
              <text style={{ ...Styles.TxtBody, textAlign: 'center', paddingBlock: 8 }}>
                {'You can upload your custom HTML for Emails only'}
              </text>
              <div
                className="d-flex flex-column justify-content-center align-items-center"
                style={{
                  width: '70%',
                  height: 250,
                  backgroundColor: colors.primary01,
                  ...Styles.borderDashed,
                }}
                onDragEnter={handleDrag}
                onDragOver={handleDrag}
                onDragExit={handleDrag}
                onDrop={handleDrop}
                onClick={() => {
                  if (!HTMLFile) {
                    console.log('------------upload clicked--------');
                    htmlref.current.click();
                  }
                }}
              >
                {fileUploaded ? (
                  <>
                    <div
                      className="d-flex flex-row justify-content-center align-items-center"
                      style={{ width: '70%' }}
                    >
                      <text
                        style={{ ...Styles.TxtBody, marginLeft: 40, marginRight: 20 }}
                      >{`${HTMLFile.name}`}</text>
                      <img
                        onClick={() => {
                          setHTMLFile(null);
                          setrhtml(null);
                          setCountOfProgress(0);
                          setFileUploaded(!fileUploaded);
                        }}
                        src={images.CreatorClose}
                        style={{ height: 30, width: 30, objectFit: 'contain' }}
                      ></img>
                    </div>
                    <div
                      className="w-75 py-3 px-2 d-block flex-column justify-content-center align-items-center"
                      style={{ height: 'auto' }}
                    >
                      <ProgressBar
                        variant="customcolor"
                        style={{ color: colors.primary03 }}
                        now={countOfProgress}
                      />
                    </div>
                    <text className="my-3" style={Styles.TxtBody}>{`File uploaded (${parseInt(
                      countOfProgress
                    )}%)`}</text>
                  </>
                ) : (
                  <>
                    <text style={{ ...Styles.TxtBody, textAlign: 'center' }}>
                      <text style={{ color: colors.linkblue }}>{'Upload '}</text>
                      {'or Drag & drop here'}
                    </text>
                  </>
                )}
              </div>
              <Button
                variant="primary"
                className="mx-2 py-2 btncustom"
                style={{
                  width: 'auto',
                  backgroundColor: colors.primary03,
                  borderWidth: 0,
                  marginBlock: 60,
                  ...Styles.TxtBody,
                  color: colors.white,
                  paddingInline: 100,
                }}
                disabled={countOfProgress == 100 ? false : true}
                onClick={() => {
                  setProceedNext(true);
                }}
              >
                {'Next'}
              </Button>

              <input
                type="file"
                style={{ display: 'none' }}
                ref={htmlref}
                accept=".html"
                value=""
                onChange={(e) => {
                  console.log('--------input ref onchange------');
                  handlehtmlupload(e);
                  // const file = e.target.files[0];
                  // if (file){
                  // setHTMLFile(file)
                  // }else{
                  // setHTMLFile(null)
                  // }
                }}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default CreatorHTMLUpload;

const Styles = {
  itemborder: {
    backgroundColor: colors.white,
    borderColor: colors.borderwhite,
    borderWidth: 1,
    borderRadius: 16,
    boxShadow: '2px 2px 10px 0 rgba(0, 0, 0, 0.15),-2px -2px 10px 0 rgba(0, 0, 0, 0.15)',
  },
  TxtHeader: {
    fontSize: 24,
    fontWeight: 700,
    color: colors.black,
  },
  TxtBody: {
    fontSize: 16,
    fontWeight: 600,
    color: colors.black04,
  },
  borderDashed: {
    borderStyle: 'dashed',
    borderWidth: 1,
    borderColor: colors.primary04,
    borderRadius: 40,
  },
  TxtBody2: {
    fontSize: 14,
    fontWeight: 600,
    color: colors.greys04,
  },
};
