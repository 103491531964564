import React, { useEffect, useRef, useState } from 'react'
import colors from '../../../Utils/colors';
import { TextField } from '@mui/material';
import ToggleButtons from './ToggleButton';
import images from '../../../Utils/images';
import UploadValidator from '../../../Components/UploadValidator';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { V2WorkflowS3Link } from '../../../Services';

const CFHeader = ({ headerProps, updateHeaderProps }) => {
    const authtoken = useSelector((state) => state.main.auth_token);

    const [upFile64, setUpFile64] = useState('');
    const [upFile, setUpFile] = useState();
    const upMediaRef = useRef();
    const [mediaType, setMediaType] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        console.log("dsfsdfsdf ", name, value)
        updateHeaderProps({ [name]: value });
    };

    const handlefileupload = async (e) => {
        const file = e.target.files[0];
        if (file) {
            const fileType = file?.type?.split('/')[0];
            if (fileType === 'video' || fileType === 'image') {
                if (UploadValidator(file, fileType)) {
                    setUpFile(file);
                    const base64 = await convertToBase64(file);
                    setUpFile64(base64);
                }
            } else {
                toast.error('Please upload only image or video files', {
                    position: 'top-center',
                    autoClose: true,
                });
            }
        } else {
            toast.error('Unexpected error occurred, please try again later.', {
                position: 'top-center',
                autoClose: true,
            });
        }
    }



    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    useEffect(() => {
        if (upFile64) {
            getS3Link();
        }
    }, [upFile64]);

    const getS3Link = () => {
        const loadingToast = toast.loading('Uploading file. Please wait.');
        let data = {
            image: upFile64,
            type: upFile?.type?.split('/')[0],
            format: upFile?.type?.split('/')[1],
        };
        let token = authtoken;
        V2WorkflowS3Link(data, token).then((res) => {
            if (res) {
                toast.update(loadingToast, {
                    render: 'File uploaded.',
                    type: 'success',
                    autoClose: true,
                    isLoading: false,

                });
                console.log('resp s3 ---> ', res);
                updateHeaderProps({ ['url']: res?.data?.url })
                // setMediaFile({
                //   format: upFile?.type?.split('/')[1],
                //   s3URL: res?.data?.url,
                //   type: upFile?.type?.split('/')[0],
                //   fileName: upFile?.name ? upFile?.name : res?.data?.fileName,
                // });
            } else {
                toast.update(loadingToast, {
                    render: 'Error occurred while uploading file, please try again later',
                    type: 'error',
                    autoClose: true,
                    isLoading: false,

                });
                // toast.error("Error occurred while uploading media file, please try again later",{autoClose:true});
            }
        });
    };

    return (
        <>
            <div className='d-flex flex-column justify-content-start align-items-center w-100' style={{
                backgroundColor: colors.white01,
                borderRadius: 12,
                border: `1px solid ${colors.borderwhite}`,
                paddingInline: 20,
                paddingBlock: 16,
                marginBlock: 8,
            }}>
                <div className='d-flex flex-row justify-content-between align-items-center w-100'>
                    <h4 className='mb-2' style={{
                        fontSize: 18,
                        fontWeight: 700,
                    }}>
                        {'Header'}
                    </h4>
                    {/* <ToggleButtons 
                alignment={headerProps.alignment}
                handleChange={(value)=>{
                    const name = 'alignment'
                    console.log("dsfsdfsdf ",name,value)
                    updateHeaderProps({ [name]: value });
                }}
            /> */}
                </div>

                <div className='my-2 d-flex flex-column justify-content-center align-items-center' style={{
                    backgroundColor: colors.white02,
                    border: `1px solid ${colors.borderwhite}`,
                    borderRadius: 12,
                    height: 100,
                    width: '100%'
                }}>
                    {headerProps.url ? (
                        <>
                            {headerProps.url.match(/\.(jpg|jpeg|png|gif)$/i) ? (
                                <img src={headerProps.url} style={{
                                    height: 50,
                                    width: 'auto',
                                    objectFit: 'contain',
                                }} alt="Header" />
                            ) : (
                                <video
                                    src={headerProps.url}
                                    style={{
                                        height: 50,
                                        width: 'auto',
                                        objectFit: 'contain',
                                    }}
                                    muted
                                    playsInline
                                    autoPlay
                                    loop
                                />
                            )}
                            <div className='d-flex flex-row justify-content-center align-items-center mt-2'>
                                <p style={{ fontSize: 14, color: colors.greys04 }}>
                                    {`Header ${headerProps.url.match(/\.(jpg|jpeg|png|gif)$/i) ? 'image' : 'video'} uploaded`}
                                </p>
                                <img src={images.CMClose} style={{
                                    height: 18,
                                    width: 18,
                                    objectFit: 'contain',
                                    cursor: 'pointer'
                                }}
                                    onClick={() => {
                                        setUpFile(null);
                                        setUpFile64(null);
                                        updateHeaderProps({ ['url']: '' });
                                        console.log("reset media clicked")
                                    }}
                                />
                            </div>
                        </>
                    ) : (
                        <div className='d-flex flex-column justify-content-center align-items-center w-100'
                            onClick={() => {
                                if (!headerProps.url) {
                                    upMediaRef.current.click();
                                }
                            }}>
                            <img src={images.UploadIcon} style={{
                                height: 30,
                                width: 'auto',
                                objectFit: 'contain',
                            }}></img>
                            <div className='d-flex flex-row justify-content-center align-items-center mt-2'>
                                <p style={{ fontSize: 14, color: colors.greys04 }}>
                                    {'Add a header image or video'}
                                </p>
                            </div>
                            <input
                                type="file"
                                id="MediaUpload"
                                style={{ display: 'none' }}
                                ref={upMediaRef}
                                accept="image/*,video/*"  // Modified to accept both image and video
                                onChange={(e) => {
                                    handlefileupload(e);
                                }}
                            />
                        </div>
                    )}
                </div>

                <div className='d-flex flex-row justify-content-between align-items-cneter w-100 my-2'>
                    <div className='d-flex flex-column justify-content-stretch align-items-start px-2 py-2' style={{ flex: 1 }}>
                        <p style={{ fontWeight: 500 }}>
                            {'Header Width (in %)'}
                        </p>
                        <TextField
                            name="headerWidth"
                            value={headerProps.headerWidth}
                            onChange={handleChange}
                            type='number'
                            size='small'
                            style={{ marginBlock: 8, width: '100%' }}
                            className='btncustom'
                            InputProps={{ inputProps: { max: 100 }, readOnly: true }}
                        />
                    </div>
                    <div className='d-flex flex-column justify-content-stretch align-items-start px-2 py-2' style={{ flex: 1 }}>
                        <p style={{ fontWeight: 500 }}>
                            {'Padding (in px)'}
                        </p>
                        <TextField
                            name="padding"
                            value={headerProps.padding}
                            // onChange={handleChange}
                            onChange={(e) => {
                                const value = e.target.value;
                                if (value === '') {
                                    handleChange(e)
                                } else {
                                    const clampedValue = Math.max(0, Math.min(24, Number(value)))
                                    updateHeaderProps({ ["padding"]: clampedValue })
                                }
                            }}
                            type='number'
                            size='small'
                            style={{ marginBlock: 8, width: '100%' }}
                            className='btncustom'
                            inputProps={{ min: 0, max: 24 }}
                        />
                    </div>
                    <div className='d-flex flex-column justify-content-stretch align-items-start px-2 py-2' style={{ flex: 1 }}>
                        <p style={{ fontWeight: 500 }}>
                            {'Corner Radius (in px)'}
                        </p>
                        <TextField
                            name="cornerRadius"
                            value={headerProps.cornerRadius}
                            // onChange={handleChange}
                            onChange={(e) => {
                                const value = e.target.value;
                                if (value === '') {
                                    handleChange(e)
                                } else {
                                    const clampedValue = Math.max(0, Math.min(30, Number(value)))
                                    updateHeaderProps({ ["cornerRadius"]: clampedValue })
                                }
                            }}
                            type='number'
                            size='small'
                            style={{ marginBlock: 8, width: '100%' }}
                            className='btncustom'
                            inputProps={{ min: 0, max: 30 }}
                        />
                    </div>

                </div>
            </div>
        </>
    )
}

export default CFHeader