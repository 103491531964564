import React, { useEffect, useState, useRef } from 'react';
import images from '../../../Utils/images';
import { FiEdit2 } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { getImagePath } from '../../../Utils';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import colors from '../../../Utils/colors';
import { useNavigate } from 'react-router-dom';
import {
  V2AgentProfileImage,
  userSignupV2Api,
  getUserApi,
  sendSingleOtp,
  checkSingleOtp,
  changeTwoFactorLogin,
} from '../../../Services';
import CheerioButton from '../../../Components/CheerioButton';
import { toast } from 'react-toastify';
import HEImages from '../../CreatorNew/Components/HEImages';
import { BsToggleOff, BsToggleOn } from 'react-icons/bs';
import VerifyTwoFactorAuth from './VerifyTwoFactorAuth';
import NDAModal from '../../CreatorNew/Components/NDAModal';
import CheckOtp from './CheckOtp';
import SpinnerLoader from '../../../Components/SpinnerLoader';

const InputItems = ({ title, type, placeholder, value, onChange, disabled = false }) => {
  return (
    <>
      <InputGroup className="" style={{ width: '100%', marginBlock: 4 }}>
        <Form.Label style={{ fontSize: 16, fontWeight: 700, color: colors.black, marginBlock: 4 }}>
          {title}
        </Form.Label>
        <Form.Control
          type={type}
          disabled={disabled}
          placeholder={placeholder}
          className="w-100 btncustom"
          style={{ borderRadius: 8, fontSize: 16, fontWeight: 400, color: colors.black }}
          value={value}
          onChange={(e) => {
            onChange(e.target.value);
          }}
        />
      </InputGroup>
    </>
  );
};

const AccountDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fileinputRef = useRef();

  const localdata = useSelector((state) => state.main.localdata);
  const agentdata = useSelector((state) => state.main.agentdata);
  const facebook_id = useSelector((state) => state.main.facebook_id);
  const user_pic = useSelector((state) => state.main?.user_pic);
  const username = useSelector((state) => state.main?.username);
  const _email = useSelector((state) => state.main?.email);
  const mobile = useSelector((state) => state.main.mobile);
  const company_name = useSelector((state) => state.main.companyname);
  const auth_token = useSelector((state) => state.main?.auth_token);
  const [otp, setOtp] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // const [profilePicture, setProfilePicture] = useState('');
  const [ownerName, setOwnerName] = useState(
    username ? username : localdata?.username ? localdata?.username : ''
  );
  const [number, setNumber] = useState(
    mobile ? mobile : localdata?.mobile ? localdata?.mobile : ''
  );
  const [email, setEmail] = useState(_email ? _email : localdata?.email ? localdata?.email : '');
  const [companyName, setCompanyName] = useState(
    company_name ? company_name : localdata?.company_name ? localdata?.company_name : ''
  );
  const [website, setWebsite] = useState(localdata?.website_url ? localdata?.website_url : '');
  const [GSTIN, setGSTIN] = useState(localdata?.gst_no ? localdata?.gst_no : '');
  const [PAN, setPAN] = useState(localdata?.pan_no ? localdata?.pan_no : '');
  const [profilepic, setProfilepic] = useState();
  const [preview, setPreview] = useState('');
  const [profileImg64, setProfileImg64] = useState('');
  const [changeTwoFactorStarted, setChangeTwoFactorStarted] = useState(false);
  const [password, setPassword] = useState('');
  const [isCheck, setIsCheck] = useState(localdata.isTwoFactorEnabled);
  const [changeState, setChangeState] = useState(false);
  const [saveChangesStarted, setSaveChangesStarted] = useState(false);
  const OKtoProceed = ownerName && number && email;

  const [isNDAModalOpen, setIsNDAModalOpen] = useState(false);

  useEffect(() => {
    if (agentdata) {
      console.log('agent data ====> ', agentdata);
    }
  }, []);

  useEffect(() => {
    if (profilepic) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
        // s3UpImageApiCall();
        // console.log(reader.result);
      };
      reader.readAsDataURL(profilepic);
    } else {
      setPreview(null);
    }
  }, [profilepic]);

  useEffect(() => {
    if (changeState) {
      setTimeout(() => {
        toast(
          isCheck
            ? '2 Factor Authentication has been enabled successfully'
            : '2 Factor Authentication has been disabled successfully',
          { toastId: 2, position: 'top-center' }
        );
      }, 500);
      setChangeState(false);
    }
  }, [isCheck]);
  const proceedtosignup = () => {
    //adminLogin();
    callSignupV2api();
  };

  // useEffect(() => {
  //     if (auth_token.length > 0) {
  //     //   dispatch({ type: 'addAuthToken', payload: authToken });
  //     //   console.log('redux authtoken added');
  //       callSignupV2api();
  //     }
  // }, [auth_token]);

  const getuserapicall = () => {
    let token = auth_token;
    getUserApi(token).then((res) => {
      if (res?.flag) {
        dispatch({ type: 'addLocaldata', payload: res?.data });
      } else {
        console.log('error get user api ====> ', res);
      }
    });
  };

  const handleAgentImageUpload = async (e) => {
    const file = e.target.files[0];
    console.log('file uploaded ---> ', file);
    if (file?.type === 'image/jpeg' || file?.type === 'image/png' || file?.type === 'image/jpg') {
      if (file.size < 5242880) {
        setProfilepic(file);
        // setupimgname(file.name);
        const base64 = await convertToBase64(file);
        setProfileImg64(base64);
      } else {
        alert('File size limit exceeded. Please check the size of the file to be uploaded.');
      }
    } else {
      setProfilepic(null);
      toast.error('Wrong file format uploaded. Only jpeg, png, and jpg are supported', {
        position: 'top-center',
      });
    }
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const sendSingleOtpApi = () => {
    setIsLoading(true);
    let token = auth_token;
    sendSingleOtp(token).then((res) => {
      if (res.flag) {
        setIsLoading(false);
        setSaveChangesStarted(true);
        toast.success("Successfully sent OTP to your email");
      } else {
        setIsLoading(false);
        console.log(res);
        toast.success(res?.message, { position: 'top-right' });
      }
    });
  }

  // useEffect(() => {
  //   if(saveChangesStarted){
  //     toast.success("saveChangesStarted")
  //   }else{
  //     toast.error("moye moye");
  //   }
  // }, [saveChangesStarted])

  const checkSingleOtpApi = (otp) => {
    setIsLoading(true);
    let token = auth_token;
    const data = {
      otp: otp
    }
    checkSingleOtp(token, data).then((res) => {
      if (res.data.otpMatched) {
        setIsLoading(false);
        // toast.success("Successfully verified OTP to your email");
        setSaveChangesStarted(false);
        callSignupV2api();
        setOtp("")
      } else {
        setIsLoading(false);
        toast.error("OTP Doesn't match", { position: 'top-right' });
        setOtp("")
      }
    });
  }

  const callSignupV2api = () => {
    const current = localdata?.facebook_id ? localdata?.facebook_id : facebook_id;
    let data = {
      facebook_id: facebook_id ? facebook_id : email,
      old_facebook_id: current === number ? number : current,
      picture: preview ? preview : '',
      name: ownerName,
      nickname: ownerName,
      mobile: number,
      email: email,
      company_name: companyName,
      pan_no: PAN ? PAN : '',
      gst_no: GSTIN ? GSTIN : '',
      website_url: website ? website : '',
      // is_v2_user: Helper.EmbeddedAI == true ? "true" : "false",
    };
    console.log('//////////////////////////////// 127');
    console.log('******************************** 127');
    console.log('data sign up api NEW ======> ', data);
    console.log('******************************** 127');
    console.log('//////////////////////////////// 127');
    let token = auth_token;
    userSignupV2Api(data, token).then((res) => {
      if (res.flag) {
        dispatch({ type: 'addUserId', payload: res?.data?._id });
        dispatch({ type: 'addFacebookId', payload: res?.data?.facebook_id });
        dispatch({ type: 'addCompanyName', payload: res?.data?.company_name });
        dispatch({ type: 'addMobile', payload: res?.data?.mobile });
        dispatch({ type: 'addUsername', payload: res?.data?.name });
        dispatch({ type: 'addNumberVerified', payload: res?.data?.number_verified });
        dispatch({ type: 'addAuthToken', payload: res.token });
        dispatch({ type: 'addLocaldata', payload: res?.data });
        // ReactGA.set({UserId : res?.data?._id});
        // setuserid(res?.data?._id);
        dispatch({ type: 'addLogin', payload: true });
        console.log(res);
        console.log('signupv2 success Updated Creator');
        toast.success(res?.message, { position: 'top-right' });

        getuserapicall();
        // userInterestsCall(res?.data?._id);
        setTimeout(() => {
          navigate('/manageprofile');
        }, 500);
      } else {
        console.log(res);
        toast.error(res?.message, { position: 'top-right' });
      }
    });
  };

  const SaveChangesAction = () => {
    if (agentdata?._id) {
      let data = {
        agentId: agentdata?._id,
        profileImg: profileImg64,
      };
      let token = auth_token;
      V2AgentProfileImage(data, token).then((res) => {
        if (res?.flag) {
          console.log('agent image success response: ' + res);
          toast.success(res?.message, { position: 'top-right' });
        } else {
          console.log('agent image error response: ' + res);
          toast.error(res?.message, {
            position: 'top-right',
          });
        }
      });
    } else {
      sendSingleOtpApi();
    }
  };

  const changeTwoFactorToggle = () => {
    setChangeTwoFactorStarted(true);
  };

  const changeTwoFactorAction = () => {
    toast('Changing 2 Factor Authentication...', {
      toastId: 1,

      position: 'top-center',
    });
    let data = {
      password: password,
    };
    let token = auth_token;
    changeTwoFactorLogin(data, token)
      .then((res) => {
        if (res?.flag) {
          toast.dismiss(1);
          setChangeTwoFactorStarted(false);
          setPassword('');
          setChangeState(true);
          setIsCheck(res.data);
          localdata.isTwoFactorEnabled = res.data;
          dispatch({ type: 'addLocaldata', payload: localdata });
        } else {
          toast.dismiss(1);
          setTimeout(() => {
            toast.error('Incorrect Password', {
              toastId: 2,
              position: 'top-center',
            });
          }, 500);
        }
      })
      .catch((error) => {
        toast.dismiss(1);
        toast.error(error, { toastId: 2, position: 'top-center' });
      });
  };

  const getRemainingDays = (e) => {
    const d = new Date(e);
    const date = new Date();
    let dd = d.toDateString();
    let cd = date.toDateString();
    let diff = Math.floor((Date.parse(dd) - Date.parse(cd)) / 86400000);
    return diff;
  };

  const getDate = (e) => {
    const d = new Date(e);
    let dd = d.getDate();
    let mm = d.toLocaleString('en-EN', { month: 'short' });
    let yy = d.getFullYear();
    return dd + '-' + mm + '-' + yy;
  };

  return (
    <>
      {isLoading ? (
        <>
          <div className="d-flex justify-content-center align-items-center w-100 h-100">
            <SpinnerLoader />
          </div>
        </>
      ) : (
        <>
          {changeTwoFactorStarted && (
            <VerifyTwoFactorAuth
              closeModalAction={() => {
                setChangeTwoFactorStarted(false);
                setPassword('');
              }}
              password={password}
              setPassword={setPassword}
              changeTwoFactorAction={changeTwoFactorAction}
            />
          )}
          {isNDAModalOpen && (
            <NDAModal
              setIsNDAModalOpen={setIsNDAModalOpen}
              updateLocalDataAction={() => {
                getuserapicall();
              }}
            />
          )}
          {saveChangesStarted && (
            <CheckOtp
              setSaveChangesStarted={setSaveChangesStarted}
              resendOtp={sendSingleOtpApi}
              checkOtp={checkSingleOtpApi}
              otp={otp}
              setOtp={setOtp}
            />
          )}
          <div className={'AccountDetailsHeader Row'}>
            <p className={'ADHeaderText'}>{'Account Details'}</p>

            {localdata?.premium ? (
              <>
                <div
                  className="validity_container"
                  style={{ marginInlineStart: 24, marginInlineEnd: 'auto' }}
                >
                  <h4>
                    {`${localdata?.premium_details?.plan_type} plan: Valid till ${getDate(
                      localdata?.premium_details?.plan_end
                    )}`}
                  </h4>
                </div>
              </>
            ) : localdata?.free_trail?.status ? (
              <>
                <h4 className="invalid_text" style={{ marginInlineStart: 24, marginInlineEnd: 'auto' }}>
                  {`Trial expiring in ${getRemainingDays(localdata.free_trail.end_date)} days`}
                </h4>
              </>
            ) : (
              localdata?.free_trail?.used_status == true && (
                <>
                  <h4
                    className="invalid_text"
                    style={{ marginInlineStart: 24, marginInlineEnd: 'auto' }}
                  >
                    {`Free trial expired`}
                  </h4>
                </>
              )
            )}

            <CheerioButton
              borderStyle={{}}
              textStyle={{ fontSize: 16, fontWeight: 600, color: colors.white }}
              btnText={'Save Changes'}
              backColor={colors.black}
              onclick={() => {
                console.log('save changes clicked');
                // if (number?.startsWith('0')) {
                if (String(number).startsWith('0')) {
                  toast.error('Please enter a valid number that does not start with 0!');
                } else {
                  SaveChangesAction();
                }
              }}
              // icon={images.MPPlus}
              // iconStyle={{height}}
              disabled={OKtoProceed ? false : true}
            />
          </div>
          <div className={'ADScrollable verythinscrollnew Column'}>
            <div className={'ProfilePictureContainer Row'}>
              {preview ? (
                <>
                  <img
                    src={preview}
                    className="ProfilePicStyle"
                    style={{ objectFit: 'cover' }}
                    onClick={() => {
                      setProfilepic(null);
                    }}
                  ></img>
                </>
              ) : (
                <>
                  {user_pic || agentdata?.profileImg || localdata?.picture ? (
                    <>
                      {/* <img src={agentdata?.profileImg ? agentdata?.profileImg : getImagePath(user_pic)} className='ProfilePicStyle' style={{ objectFit: 'cover' }}></img> */}
                      <HEImages
                        userGeneratedSrc={
                          agentdata?.profileImg
                            ? agentdata?.profileImg
                            : localdata?.picture
                              ? getImagePath(localdata.picture)
                              : getImagePath(user_pic)
                        }
                        classname={'ProfilePicStyle'}
                        style={{ objectFit: 'cover' }}
                      />
                      {/* <img src={agentdata?.profileImg ? agentdata?.profileImg : localdata?.picture ? getImagePath(localdata.picture) : getImagePath(user_pic)} className='ProfilePicStyle' style={{objectFit:'cover'}}></img> */}
                    </>
                  ) : (
                    <>
                      <img src={images.ProfilePicPlaceholder} className="ProfilePicStyle"></img>
                    </>
                  )}
                </>
              )}
              {/* <img src={localdata?.picture && profilePicture ? getImagePath(profilePicture) : profilePicture ? profilePicture : images.ProfilePicPlaceholder} 
            className={'ProfilePicStyle'}></img> */}

              <FiEdit2
                size={20}
                color="#000"
                style={{ marginInline: 30, cursor: 'pointer' }}
                onClick={() => {
                  fileinputRef.current.click();
                }}
              />

              <input
                type="file"
                required
                style={{ display: 'none' }}
                ref={fileinputRef}
                accept="image/png, image/jpeg"
                onChange={(e) => {
                  handleAgentImageUpload(e);
                  // const file = e.target.files[0];
                  // if (file) {
                  //     setProfilepic(file);
                  // } else {
                  //     setProfilepic(null);
                  // }
                }}
              />
            </div>

            {agentdata?.role && (
              <div
                className="RoleContainer Row"
                style={{
                  backgroundColor: agentdata?.role === 'admin' ? colors.success03 : colors.warning04,
                }}
              >
                <p className="RoleText">{agentdata?.role.toUpperCase()}</p>
              </div>
            )}

            <div
              className="d-flex flex-row justify-content-start align-items-start w-100"
              style={{ paddingInline: 50, marginBlock: 20 }}
            >
              <div
                className="d-flex flex-column justify-content-start align-items-center"
                style={{ width: '40%' }}
              >
                <InputItems
                  title={'Account User Name'}
                  type={'text'}
                  placeholder={'Enter name here'}
                  value={ownerName}
                  onChange={setOwnerName}
                  disabled={agentdata?._id ? true : false}
                />
                <InputItems
                  title={'Phone'}
                  type={'text'}
                  placeholder={'Enter mobile number here'}
                  value={number}
                  onChange={setNumber}
                  disabled={agentdata?._id ? true : false}
                />
                <InputItems
                  title={'Email'}
                  type={'email'}
                  placeholder={'Enter email id here'}
                  value={email}
                  onChange={setEmail}
                  disabled={agentdata?._id ? true : false}
                />
                <InputItems
                  title={'Company Name'}
                  type={'text'}
                  placeholder={'Enter company name here'}
                  value={companyName}
                  onChange={setCompanyName}
                  disabled={agentdata?._id ? true : false}
                />
              </div>
              {!agentdata?._id && (
                <div
                  className="d-flex flex-column justify-content-start align-items-center"
                  style={{ width: '40%', marginInlineStart: 40 }}
                >
                  <InputItems
                    title={'Website'}
                    type={'text'}
                    placeholder={'Enter company website here'}
                    value={website}
                    onChange={setWebsite}
                  />
                  <InputItems
                    title={'GSTIN'}
                    type={'text'}
                    placeholder={'Enter here'}
                    value={GSTIN}
                    onChange={setGSTIN}
                  />
                  <InputItems
                    title={'PAN'}
                    type={'text'}
                    placeholder={'Enter here'}
                    value={PAN}
                    onChange={setPAN}
                  />
                </div>
              )}
            </div>
            <div
              className="d-flex flex-row justify-content-start align-items-center w-100"
              style={{ paddingInline: 50 }}
            >
              <CheerioButton
                borderStyle={{ borderColor: colors.primary03, marginBlock: 16 }}
                textStyle={{ color: colors.white }}
                btnText={localdata?.isNDASigned ? 'View NDA' : 'Sign NDA'}
                backColor={colors.primary03}
                // icon={images.WhatsAppWhite}
                onclick={() => {
                  setIsNDAModalOpen(true);
                }}
              // disabled={agreeCheck ? false : true }
              />
            </div>

            <hr style={{ width: '100%', height: '0.5px', backgroundColor: '#E6E6E6' }} />
            {localdata.number_verified && (
              <div className='d-flex flex-column justify-content-start align-items-start w-100' style={{ paddingInline: 50 }}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                  <div>
                    <h3 style={{ color: colors.black, fontSize: 16, fontWeight: 700 }}>
                      {'2 Factor Authentication'}
                    </h3>
                  </div>
                  <div class="form-check form-switch">
                    <input
                      class="form-check-input shadow-none"
                      style={{}}
                      type="checkbox"
                      role="switch"
                      checked={isCheck}
                      id="flexSwitchCheckDefault"
                      onChange={() => changeTwoFactorToggle()}
                    ></input>
                  </div>
                </div>
                <div
                  style={{
                    fontSize: '14px',
                    fontWeight: '400',
                    marginBottom: '20px',
                    marginTop: '5px',
                  }}
                >
                  <p>
                    Enable 2 factor authentication for increased security for your Cheerio Dashboard.
                    You will be asked to enter your password & OTP (sent to registered no) each time you
                    will login
                  </p>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default AccountDetails;
