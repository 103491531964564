import images from '../../Utils/images'
import CheerioButton from '../../Components/CheerioButton'
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import colors from '../../Utils/colors';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { trackPages } from '../../Utils/brevo';
import WooCommerce from '../WooCommerce';
import ShopifyStore from '../ShopifyStore';
import Kylas from './KylasIntegration';
import OpenAi from './OpenAiIntegration';
import { AmazonSES } from './AmazonSESIntegreation/AmazonSES';
import { useLocation } from 'react-router-dom';

import { Pixel } from './PixelIntegration/Pixel';


const servicesData = [
    {
        serviceName: 'Shopify',
        serviceDesc: 'Manage your customers, orders & product data to automate and target customers',
        image: images.ShopifyService,
        industry: 'Ecommerce',
        type: 'MARKETING',
        url: 'shopify',
    },
    {
        serviceName: 'WooCommerce',
        serviceDesc: 'Manage your customers, orders & product data to automate and target customers',
        image: images.WooCommService,
        industry: 'Ecommerce',
        type: 'MARKETING',
        url: 'woocommerce',
    },
    {
        serviceName: 'Kylas',
        serviceDesc: 'Manage & Streamline your Kylas CRM data for automations & marketing.',
        image: images.KylasService,
        industry: 'Ecommerce',
        type: 'MARKETING',
        url: 'kylas',
    },
    {
        serviceName: 'Chat GPT',
        serviceDesc: 'Manage your Open AI account to use ChatGPT for automations & marketing.',
        image: images.GPTService,
        industry: 'Ecommerce',
        type: 'MARKETING',
        url: 'chatGpt',
    },
    {
        serviceName: 'Amazon SES',
        serviceDesc: 'Manage & Streamline your email marketing process with Amazon’s simple emailing services',
        image: images.AmazonSES,
        industry: 'Ecommerce',
        type: 'MARKETING',
        url: 'setupAmazonSES',
    },
    {
        serviceName: 'Facebook',
        serviceDesc: 'Manage your leads, customers from all your Facebook accounts (Ads, Marketing, Business)',
        image: images.Facebook,
        industry: 'Ecommerce',
        type: 'MARKETING',
        url: 'facebookPixel',
    },
];


const MenuItem = ({ title, setSelectedIndustry }) => {

    return (
        <>
            <div
                className="Row justify-content-start w-100"
                style={{
                    paddingBlock: 8,
                    paddingInlineStart: 15,
                    marginBlock: 4,
                    backgroundColor: title == 'All' ? '#F5F0FF' : '',
                    cursor: 'pointer',
                    borderRadius: 8,
                }}
                onClick={() => {
                    setSelectedIndustry(title);
                }}
            >
                <p
                    style={{
                        padding: 0,
                        margin: 0,
                        marginInline: 5,
                        fontSize: 14,
                        fontWeight: 600,
                        color: title == 'All' ? colors.primary03 : colors.darkgrey,
                    }}
                >
                    {title}
                </p>
            </div>
        </>
    );
};
const Platforms = ({ showPremiumModal, setShowPremiumModal }) => {
    const [selectedIndustry, setSelectedIndustry] = useState('All');
    const [filteredData, setFilteredData] = useState([]);
    const navigate = useNavigate();
    const localdata = useSelector((state) => state.main.localdata);

    const isBasic = localdata?.premiumPlanName === 'Basic' && localdata?.premiumSubscriptionId;
    const isStartup = localdata?.premiumPlanName === 'Startup' && localdata?.premiumSubscriptionId;

    const IntegrationItem = ({ item }) => {
        const handleClick = () => {
            if (item?.serviceName === 'Shopify' && isBasic) {
                setShowPremiumModal(true);
            } else if (item?.serviceName === 'WooCommerce' && isBasic) {
                setShowPremiumModal(true);
            } else if (item?.serviceName === 'Facebook' && (isBasic || isStartup)) {
                setShowPremiumModal(true);
            } else {
                navigate(item?.url);
            }
        };

        return (
            <div className="px-2 py-2" style={{ width: '33%' }}>
                <div
                    className="d-flex flex-column justify-content-around align-items-start w-100 h-100 shadow_on_hover"
                    style={{
                        borderRadius: 16,
                        border: `1px solid ${colors.borderwhite}`,
                        backgroundColor: colors.white01,
                        cursor: 'pointer',
                        paddingInline: 24,
                        paddingBlock: 20,
                    }}
                    onClick={handleClick}
                >
                    <div
                        className="d-flex flex-column justify-content-center align-items-center"
                        style={{ height: 50, width: 50, borderRadius: 8, backgroundColor: '#F8F9FD' }}
                    >
                        <img style={{ width: 30, height: 30, objectFit: 'contain' }} src={item?.image}></img>
                    </div>
                    <p style={{ paddingBlock: 16, fontWeight: '700', fontSize: '20px' }}>{item?.serviceName}</p>
                    <p style={{ fontSize: '16px', color: '#666666' }}>{item?.serviceDesc}</p>
                </div>
            </div>
        );
    };


    useEffect(() => {
        if (selectedIndustry === 'All') {
            setFilteredData(
                servicesData?.filter((item) => localdata?.integratedApp?.includes(item?.serviceName))
            );
        } else if (selectedIndustry) {
            setFilteredData(
                servicesData.filter(
                    (item) =>
                        item?.industry === selectedIndustry &&
                        localdata?.integratedApp?.includes(item?.serviceName)
                )
            );
        }
    }, [selectedIndustry, localdata]);

    return (
        <div
            className="d-flex flex-row justify-content-between align-items-center w-100 h-100"
        >
            <div
                id="WTSidemenu"
                className="d-flex flex-column justify-content-start align-items-center"
                style={{
                    width: '20%',
                    height: '100%',
                    overflowY: 'auto',
                    border: `1px solid ${colors.borderwhite}`,
                    backgroundColor: 'white',
                    padding: '10px',
                }}
            >
                <MenuItem title="All" setSelectedIndustry={setSelectedIndustry} />
            </div>
            <div className="w-100 h-100" style={{ overflowY: 'auto' }}>
                <Routes>
                    <Route
                        path="/"
                        element={
                            <div
                                id="WTWorkspace"
                                className="h-100 py-2 px-2 d-flex flex-row justify-content-start align-items-stretch"
                                style={{
                                    flexWrap: 'wrap',
                                    overflowY: 'auto',
                                    alignContent: 'flex-start',
                                }}
                            >
                                {filteredData?.map((item) => (
                                    <IntegrationItem key={item?.serviceName} item={item} />
                                ))}
                            </div>
                        }
                    />
                </Routes>
            </div>
        </div>
    );
};

const ManageIntegrationsPage = () => {
    const localdata = useSelector((state) => state.main.localdata);
    const authtoken = useSelector((state) => state.main.auth_token);
    const dispatch = useDispatch();
    const location = useLocation();
    const specialID =
        localdata?._id === '636be67d42c9a5cb33f60d98' ||
        localdata?._id === '660e878b6d6015c4e49b0434' ||
        localdata?._id === '6489675b4f598e055ed74e34' ||
        localdata?._id === '65f7d39aecb11f98652fffcd' ||
        localdata?._id === '65f7d42aecb11f98652fffd2' ||
        localdata?.integratedApp.some(app => app.includes("Chat GPT"));

    const navigate = useNavigate();
    const [showPremiumModal, setShowPremiumModal] = useState(false);
    const shouldShowHeader = location.pathname !== '/creator/manageIntegrations';
    return (
        <>
    <div style={{ width: '100%', height: '100%' }}>
    <div style={{ borderBottom: '1px solid #CDCDCD', padding: '1%', backgroundColor: 'white' }}>
        <p style={{ fontWeight: '700', fontSize: '20px' }}>Manage Integrations</p>
      </div>
            {shouldShowHeader && (
                <div
                    className="d-flex"
                    style={{
                        borderBottom: '1px solid #CDCDCD',
                        padding: '1%',
                        width: '100%',
                        backgroundColor: 'white',
                        alignItems: 'center',
                    }}
                >
                    <img
                        onClick={() => {
                            navigate('/creator/manageIntegrations');
                        }}
                        height={20}
                        src={images.BackArrowBlack}
                        style={{ cursor: 'pointer' }}
                    ></img>
                    <p style={{ fontSize: '16px', fontWeight: 600, marginLeft: '1%' }}>

                        Manage Integrations</p>
                </div>
            )}

            <Routes>
                <Route
                    path="/"
                    element={
                        <Platforms
                            showPremiumModal={showPremiumModal}
                            setShowPremiumModal={setShowPremiumModal}
                        />
                    }
                ></Route>
                <Route path="/" element={<ManageIntegrationsPage />}></Route>
                <Route path="shopify/*" element={<ShopifyStore></ShopifyStore>}></Route>
                <Route path="kylas" element={<Kylas></Kylas>}></Route>
                <Route path="woocommerce" element={<WooCommerce />}></Route>
                {specialID && <Route path="chatGpt" element={<OpenAi></OpenAi>}></Route>}
                <Route path="setupAmazonSES" element={<AmazonSES />}></Route>
                <Route path="faceBookPixel" element={<Pixel />}></Route>
            </Routes>
      </div>
        </>
    );
};


export default ManageIntegrationsPage