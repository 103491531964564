import { Modal } from 'react-bootstrap';
import images from '../../../../../Utils/images';
import CheerioButton from '../../../../../Components/CheerioButton';
import React, { useState } from 'react';
import './index.scss';
import { mailLabelCsv, mailContactsCsv } from '../../../../../Services';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { eventsend } from '../../../../../Config/analyticsFunctions';
import { ValidateEmail } from '../../../../Chatbot/Setupbot';
import {getUserDateTimeSettings} from '../../../../../Utils/localdate'
import SpinnerLoader from '../../../../../Components/SpinnerLoader';

const EmailModal = ({ show, setShow, type, reqId, isLabel }) => {
  const { timeZone } = getUserDateTimeSettings();
  const auth_token = useSelector((state) => state.main.auth_token);
  const localdata = useSelector((state) => state.main.localdata);
  const [email, setEmail] = useState(localdata?.email ? localdata?.email : '');
  const [finalState, setFinalState] = useState(false);
  const [loading, setLoading] = useState(false);

    const handleSendCsv = async () => {
    if (!ValidateEmail(email)) {
      toast.error('Invalid Email Address', { position: 'top-center' });
      return;
    }
    setLoading(true);
    try {
      eventsend('Send_CSV_Export_popup_clicked');
      const data = { email, reqId, type, timeZone };

      const res = isLabel
        ? await mailLabelCsv(data, auth_token)
        : await mailContactsCsv(data, auth_token);

      if (res?.flag) {
        setFinalState(true);
      } else {
        toast.error(res.message || 'Failed to send report. Please try again.');
      }
    } catch (err) {
      console.error('Error:', err);
      toast.error(err || 'Failed to send report. Please try again.',);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <Modal
        show={show}
        aria-labelledby="example-custom-modal-styling-title"
        dialogClassName="my-modal"
        contentClassName="mailCsv"
      >
         {loading ? (
                <div className="d-flex align-items-center justify-content-center" style={{ height: '400px' }}>
                    <SpinnerLoader />
                </div>
            ) :(
        <Modal.Body className='email_modal_body' style={{ paddingX: '20px' }}>
          <div className="d-flex justify-content-between ">
            <p style={{ fontWeight: '700', fontSize: '24px' }}>Export {type}</p>

            <div className="d-flex flex-row">
              <img
                src={images.CMClose}
                onClick={() => setShow(false)}
                style={{
                  cursor: 'pointer',
                  height: 22,
                  width: 22,
                  marginInlineEnd: 8,
                }}
              ></img>
            </div>
          </div>
          <hr style={{ marginTop: '-2px' }}></hr>
          {finalState ? (
            <>
              <div className="d-flex align-items-center flex-column" style={{ marginTop: '50px' }}>
                <img
                  src={images.CircleCheckGreen}
                  style={{
                    alignItems: 'center',
                    marginInlineEnd: 8,
                  }}
                ></img>
                <p style={{ fontWeight: '700', fontSize: '16px', marginTop: '20px' }}>
                  CSV Shared Successfully
                </p>
                <p style={{ fontSize: '14px', color: 'grey', marginTop: '-10px' }}>
                  Kindly check your email.
                </p>
              </div>

              <div style={{ marginLeft: '10px', marginTop: '30px' }}>
                <p style={{ fontSize: '12px', color: 'grey' }}>
                  <i>
                    Please anticipate a potential delay in the delivery of the email,
                    especially in the case of a substantial report. We kindly request your patience
                    during this period. If not received try again.
                  </i>
                </p>
                <CheerioButton
                  className="button"
                  borderStyle={{
                    height: '36px',
                    borderRadius: '9px',
                    width: '95%',
                    marginBlockStart: 12,
                    marginBlockEnd: 8,
                    marginBottom: '20px',
                  }}
                  textStyle={{ color: 'black', fontSize: 16, fontWeight: 600 }}
                  btnText={'Resend Email'}
                  backColor={'white'}
                  onclick={() => {
                    const data = {
                      email: email,
                      reqId,
                      type,
                      timeZone,
                    };
                    isLabel ? mailLabelCsv(data, auth_token).then((res) => { }) : mailContactsCsv(data, auth_token).then((res) => { });
                    setShow(false);
                    toast.success('Report Sent', { position: 'top-center' });
                  }}
                />
              </div>
            </>
          ) : (
            <div>
              <div className="form-group mt-4">
                <label
                  htmlFor="exampleFormControlInput1"
                  style={{ fontWeight: '700', fontSize: '14px', color: 'black' }}
                >
                  Email Id
                </label>
                <input
                  className="form-control shadow-none"
                  placeholder="john@cheerio.in"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  autoComplete={true}
                />
                <label
                  style={{ color: 'grey', fontSize: '14px', fontWeight: '400', marginLeft: '10px' }}
                >
                  You will receive your CSV on this email
                </label>
              </div>
              <div style={{ marginLeft: '10px', marginTop: '10rem' }}>
                <CheerioButton
                  className="button"
                  borderStyle={{
                    height: '36px',
                    borderRadius: '9px',

                    marginBlockStart: 12,
                    marginBlockEnd: 8,
                    marginBottom: '20px',
                    border: 'none',
                  }}
                  textStyle={{ color: 'white', fontSize: 16, fontWeight: 600 }}
                  btnText={'Send CSV'}
                  backColor={'#8E53F7'}
                  onclick={() => {
                    if(ValidateEmail(email)){
                      handleSendCsv()
                    }
                  }}
                />
              </div>
            </div>
          )}

        </Modal.Body>
            )}
      </Modal>
    </>
  );
};

export default EmailModal;
