import React, { useEffect, useState } from 'react';
import images from '../../../Utils/images';
import CheerioButton from '../../../Components/CheerioButton';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { getLinkedinConfiguration, editLinkedinConfiguration } from '../../../Services';
import { Modal, ModalBody } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import colors from '../../../Utils/colors';
import { saveLinkedinSetup } from '../../../Services';

export const Linkedin = ({ setSelectedMenuItem }) => {
    const [apiKey, setApiKey] = useState('');
    const [success, setSuccess] = useState(false);
    const navigate = useNavigate();
    const [agentNumber, setAgentNumber] = useState('0');
    const [userExist, setUserExist] = useState(false);
    const authtoken = useSelector((state) => state.main.auth_token);
    const [isLoading, setIsLoading] = useState(false);
    const [isCredentials, setIsCredentials] = useState(false);
    const [isEditMode, setIsEditMode] = useState(true);
    const [agreedToTerms, setAgreedToTerms] = useState(false);
    const [emailError, setEmailError] = useState('');
    const [has2FA, setHas2FA] = useState(false);
    const [willNotCloseTab, setWillNotCloseTab] = useState(false);
    const [otpTimeConfirmed, setOtpTimeConfirmed] = useState(false);
    const [showOtpInput, setShowOtpInput] = useState(false);
    const [otp, setOtp] = useState('');
    const [credentials, setCredentials] = useState({
        linkedinEmail: '',
        linkedinPassword: '',
    });

    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const handleChange = (e) => {
        const { id, value } = e.target;
        setCredentials(prevState => ({
            ...prevState,
            [id]: value
        }));

        // Email validation
        if (id === 'linkedinEmail') {
            if (!value) {
                setEmailError('Email is required');
            } else if (!validateEmail(value)) {
                setEmailError('Please enter a valid email address');
            } else {
                setEmailError('');
            }
        }
    };

    const handleOtpChange = (e) => {
        setOtp(e.target.value);
    };

    const handleTermsChange = (e) => {
        setAgreedToTerms(e.target.checked);
    };

    const handle2FAChange = (e) => {
        setHas2FA(e.target.checked);
    };

    const handleTabCloseChange = (e) => {
        setWillNotCloseTab(e.target.checked);
    };

    const handleOtpTimeChange = (e) => {
        setOtpTimeConfirmed(e.target.checked);
    };

    const handleSubmitOrEdit = () => {
        if (!isEditMode) {
            // Enter edit mode
            setIsEditMode(true);
            setShowOtpInput(false);
            setCredentials(prev => ({
                linkedinEmail: '',
                linkedinPassword: '',
            }));
            // Reset checkboxes when entering edit mode
            setHas2FA(false);
            setWillNotCloseTab(false);
            if (!isCredentials) {
                setAgreedToTerms(false);
            }
            return;
        }

        // Check validation before submission
        if (!validateEmail(credentials.linkedinEmail)) {
            setEmailError('Please enter a valid email address');
            return;
        }

        let token = authtoken;
        setIsLoading(true);

        const data = {
            email: credentials.linkedinEmail,
            password: credentials.linkedinPassword
        };

        // If OTP is being shown and OTP is entered
        if (showOtpInput) {
            // Include OTP in data
            data.otp = otp;
        }

        // If credentials already exist, use editLinkedinConfiguration
        if (isCredentials) {
            editLinkedinConfiguration(data, token).then((res) => {
                setIsLoading(false);
                if (res?.flag) {
                    toast.success(res?.message);
                    setIsEditMode(false);
                    setShowOtpInput(false);
                    setCredentials(prev => ({
                        linkedinEmail: '********',
                        linkedinPassword: '********',
                    }));
                } else {
                    // Check if 2FA error
                    if (res?.message?.includes('2FA') || res?.errorType === '2FA_REQUIRED') {
                        toast.info('Two-factor authentication required. Please enter OTP.');
                        setShowOtpInput(true);
                    } else {
                        toast.error(res?.message);
                    }
                }
            }).catch((error) => {
                setIsLoading(false);
                toast.error('An error occurred. Please try again.');
            });
        } else {
            // For new credentials, use saveLinkedinSetup
            saveLinkedinSetup(token, data).then((res) => {
                setIsLoading(false);
                if (res?.flag) {
                    toast.success(res?.message);
                    setIsEditMode(false);
                    setShowOtpInput(false);
                    setIsCredentials(true);
                    setCredentials(prev => ({
                        linkedinEmail: '********',
                        linkedinPassword: '********',
                    }));
                } else {
                    // Check if 2FA error
                    if (res?.message?.includes('2FA') || res?.errorType === '2FA_REQUIRED') {
                        toast.info('Two-factor authentication required. Please enter OTP.');
                        setShowOtpInput(true);
                    } else {
                        toast.error(res?.message);
                    }
                }
            }).catch((error) => {
                setIsLoading(false);
                toast.error('An error occurred. Please try again.');
            });
        }
    };

    useEffect(() => {
        let token = authtoken;
        getLinkedinConfiguration(token).then((res) => {
            if (res?.flag) {
                if (res?.data) {
                    // Data exists
                    setIsCredentials(true);
                    setCredentials({
                        linkedinEmail: res?.data?.email,
                        linkedinPassword: '********',
                    });
                    setIsEditMode(false);
                } else {
                    // No data, but not an error
                    setIsCredentials(false);
                    setIsEditMode(true);
                    setCredentials({
                        linkedinEmail: '',
                        linkedinPassword: '',
                    });
                }
            } else if (res?.message && res?.message !== "No linkedin user exists") {
                // Actual error occurred
                toast.error(res.message);
                setIsCredentials(false);
                setIsEditMode(true);
            } else {
                // No data found, not treated as an error
                setIsCredentials(false);
                setIsEditMode(true);
                setCredentials({
                    linkedinEmail: '',
                    linkedinPassword: '',
                });
            }
        }).catch(error => {
            console.error("Error fetching SES configuration:", error);
            toast.error("An unexpected error occurred. Please try again.");
            setIsCredentials(false);
            setIsEditMode(true);
        });
    }, [authtoken]);

    // Check if all required checkboxes are selected
    const areRequiredCheckboxesSelected = () => {
        if (showOtpInput) {
            return otpTimeConfirmed;
        }

        // Only Microsoft Authenticator is required for existing users
        if (isCredentials) {
            return has2FA;
        }
        // For new users, require both Microsoft Authenticator and terms agreement
        return has2FA && agreedToTerms;
    };

    // Button should be enabled when not in edit mode or when all required checkboxes are selected
    const isButtonEnabled = !isLoading && (!isEditMode || areRequiredCheckboxesSelected());

    const formStyle = {
        width: '100%',
        padding: '20px',
        borderRadius: '8px',
        background: '#FFFFFF',
    };

    const formGroupStyle = {
        paddingTop: 10,
        marginBottom: '15px',
    };

    const labelStyle = {
        display: 'block',
        marginBottom: '5px',
        fontWeight: 'bold',
        fontSize: '14px',
    };

    const inputStyle = {
        width: 'calc(100% - 16px)',
        maxWidth: '400px',
        padding: '8px',
        border: '1px solid #ccc',
        borderRadius: '4px',
        boxSizing: 'border-box',
        fontSize: '14px',
    };

    const errorStyle = {
        color: 'red',
        fontSize: '12px',
        marginTop: '5px',
    };

    const checkboxLabelStyle = {
        display: 'flex',
        alignItems: 'center',
        marginTop: '10px',
        marginBottom: '14px',
        fontSize: '16px',
    };

    const checkboxStyle = {
        width: 16,
        height: 16,
        cursor: 'pointer',
        marginRight: '10px',
    };

    return (
        <div style={{ width: '100%', padding: '2%' }}>
            {
                <>
                    <div className="d-flex" style={{ alignItems: 'center', paddingBottom: '2%' }}>
                        <img height={47} width={47} src={images.LinkedinLogo} style={{ marginRight: '1%' }}></img>
                        <div>
                            <p style={{ fontWeight: '700', fontSize: '20px' }}>LinkedIn</p>
                            <p style={{ color: '#666666', fontSize: '16px' }}>
                                Connect and automate follow-ups and connections with ease
                            </p>
                        </div>
                    </div>

                    <form onSubmit={(e) => e.preventDefault()} style={formStyle}>
                        <div>
                            <p style={{ fontWeight: '700', fontSize: '16px' }}>Login to LinkedIn</p>
                            <p style={{ color: '#666666', fontSize: '16px' }}>Please log in to your LinkedIn account using your <span style={{ fontWeight: 700 }}>registered email address and password</span>. Rest assured, <span style={{ fontWeight: 700 }}>your LinkedIn credentials are securely encrypted</span> and are necessary for executing automated actions in the background.<span style={{ fontWeight: 700 }}> We do not have direct access or control over your LinkedIn account</span>, and you have the option to disconnect this connection at any time.</p>
                        </div>
                        <div style={formGroupStyle}>
                            <label htmlFor="linkedinEmail" style={labelStyle}>Linkedin email*</label>
                            <input
                                type="email"
                                id="linkedinEmail"
                                placeholder="john@sample.in"
                                style={inputStyle}
                                value={credentials.linkedinEmail}
                                onChange={handleChange}
                                disabled={!isEditMode || showOtpInput}
                            />
                            {emailError && <div style={errorStyle}>{emailError}</div>}
                        </div>
                        <div style={formGroupStyle}>
                            <label htmlFor="linkedinPassword" style={labelStyle}>Password</label>
                            <input
                                type="password"
                                id="linkedinPassword"
                                placeholder="********"
                                style={inputStyle}
                                value={credentials.linkedinPassword}
                                onChange={handleChange}
                                disabled={!isEditMode || showOtpInput}
                            />
                        </div>

                        {showOtpInput && (
                            <>
                                <div style={formGroupStyle}>
                                    <label htmlFor="otp" style={labelStyle}>Enter OTP</label>
                                    <input
                                        type="text"
                                        id="otp"
                                        placeholder="Enter OTP"
                                        style={inputStyle}
                                        value={otp}
                                        onChange={handleOtpChange}
                                    />
                                </div>
                                <div style={checkboxLabelStyle}>
                                    <input
                                        type="checkbox"
                                        id="otpTimeCheckbox"
                                        checked={otpTimeConfirmed}
                                        onChange={handleOtpTimeChange}
                                        style={checkboxStyle}
                                    />
                                    <label htmlFor="otpTimeCheckbox">
                                        OTP: I confirm there is more than 25 seconds to enter OTP
                                    </label>
                                </div>
                            </>
                        )}

                        {isEditMode && !showOtpInput && (
                            <>
                                {/* <div style={checkboxLabelStyle}>
                                    <input
                                        type="checkbox"
                                        id="has2FACheckbox"
                                        checked={has2FA}
                                        onChange={handle2FAChange}
                                        style={checkboxStyle}
                                    />
                                    <label htmlFor="has2FACheckbox">
                                        I have enabled Microsoft Authenticator as 2FA
                                    </label>
                                </div> */}

                                <div style={checkboxLabelStyle}>
                                    <input
                                        type="checkbox"
                                        id="has2FACheckbox"
                                        checked={has2FA}
                                        onChange={handle2FAChange}
                                        style={checkboxStyle}
                                    />
                                    <label htmlFor="has2FACheckbox">
                                        I have enabled <a href="/microsoft-authenticator.pdf" target="_blank" rel="noopener noreferrer" style={{ color: colors.primary03 }}>Microsoft Authenticator</a> as 2FA
                                    </label>
                                </div>

                                {!isCredentials && (
                                    <div style={checkboxLabelStyle}>
                                        <input
                                            type="checkbox"
                                            id="termsCheckbox"
                                            checked={agreedToTerms}
                                            onChange={handleTermsChange}
                                            style={checkboxStyle}
                                        />
                                        <label htmlFor="termsCheckbox">
                                            I agree to the <span style={{ color: colors.primary03 }}>terms of service</span>
                                        </label>
                                    </div>
                                )}
                            </>
                        )}

                        <CheerioButton
                            borderStyle={{
                                borderColor: colors.primary03,
                                width: '15%',
                                marginInlineEnd: 16,
                                opacity: isButtonEnabled ? 1 : 0.5,
                            }}
                            textStyle={{ fontSize: 14, fontWeight: 600, color: colors.white }}
                            btnText={
                                isLoading ? 'Loading...' :
                                    showOtpInput ? 'Submit OTP' :
                                        !isEditMode ? 'Edit' : 'Login to Linkedin'
                            }
                            backColor={colors.primary03}
                            disabled={!isButtonEnabled}
                            onclick={handleSubmitOrEdit}
                        />
                    </form>
                </>
            }
        </div>
    );
};