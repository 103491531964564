// SequencesPage.jsx
import React from 'react';
import images from '../../../Utils/images';
import colors from '../../../Utils/colors';
import CheerioButton from '../../../Components/CheerioButton';
import { useState } from 'react';
import { FaLinkedin } from "react-icons/fa";
import { MdOutlineMail } from "react-icons/md";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useNavigate } from 'react-router-dom';
import SequencesTable from './SequencesTable';

const SequencesPage = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const navigate = useNavigate();
  const [hasSequences, setHasSequences] = useState(true);


  const handleDropdownToggle = () => {
    setShowDropdown(!showDropdown);
  };

  const handleLinkedInClick = () => {
    setShowDropdown(false);
    navigate('/creator/linkedinsequences/create');
  };

  return (
    <div style={{
      width: '100%'
    }}>

      <header style={{
        paddingLeft: '2%',
        paddingTop: '1%',
        paddingBottom: '2%',
        paddingRight: '2%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start'
      }}>
        <div>
          <h1 style={{
            fontSize: 24,
            fontWeight: 700,
            paddingBottom: '0.5%',
            color: '#333'
          }}>
            Sequences
          </h1>
          <p style={{
            fontSize: 16,
            fontWeight: 400,
            color: '#666666',
          }}>
            Sequences allows you to design and execute campaigns over a specified period, setting intervals that suit your strategy. Engage your audience consistently and effectively by scheduling your messages to go out at just the right times.
          </p>
        </div>
        <CheerioButton
          borderStyle={{
            borderColor: colors.primary03,
            width: '15%',
            minWidth: '150px',
            marginInlineEnd: 4,
            paddingBlock: 8,
            marginInlineStart: 80,
          }}
          textStyle={{ fontSize: 14, fontWeight: 600, color: colors.white }}
          btnText='+ New Sequence'
          backColor={colors.primary03}
          onclick={handleDropdownToggle}
        />
        {showDropdown && (
          <div style={{
            position: 'absolute',
            top: '6%',
            right: 40,
            marginTop: '0.5rem',
            backgroundColor: 'white',
            borderRadius: '0.75rem',
            boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
            width: 300,
            padding: '0.5rem',
            zIndex: 10
          }}>
            {/* <button
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '0.75rem',
                width: '100%',
                padding: '0.75rem',
                border: 'none',
                backgroundColor: 'transparent',
                cursor: 'pointer',
                borderRadius: '0.5rem',
                transition: 'background-color 0.2s ease'
              }}
              onMouseOver={(e) => e.target.style.backgroundColor = '#f3f4f6'}
              onMouseOut={(e) => e.target.style.backgroundColor = 'transparent'}
            >
              <MdOutlineMail />
              Email Sequence
            </button> */}

            <button
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '0.75rem',
                width: '100%',
                padding: '0.75rem',
                border: 'none',
                backgroundColor: 'transparent',
                cursor: 'pointer',
                borderRadius: '0.5rem',
                transition: 'background-color 0.2s ease'
              }}
              onMouseOver={(e) => e.target.style.backgroundColor = '#f3f4f6'}
              onMouseOut={(e) => e.target.style.backgroundColor = 'transparent'}
              onClick={handleLinkedInClick}
            >
              <FaLinkedin />
              LinkedIn Sequence
            </button>
          </div>
        )}
      </header>


      <SequencesTable hasSequences={hasSequences} setHasSequences={setHasSequences} />
      {!hasSequences &&
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '400px',
          paddingTop: '5%'
        }}>
          <div style={{
            textAlign: 'center'
          }}>
            <img
              src={images.Sequences}
              alt="Sequences illustration"
              style={{
                width: '300px',
                height: 'auto',
                marginBottom: '1.5rem'
              }}
            />
            <p style={{
              color: '#666',
              marginBottom: '1.5rem'
            }}>
              All your sequences will be shown here
            </p>
            {/* <CheerioButton
                    borderStyle={{
                      marginBlock: 8,
                      width: '60%',
                      paddingBlock: 10,
                      marginInlineStart: 60,
                      border: `1px solid ${colors.primary03}`,
                    }}
                    textStyle={{ fontSize: 14, fontWeight: 600, color: colors.white }}
                    btnText={'+ New Sequence'}
                    backColor={colors.primary03}
                  /> */}
          </div>
        </div>
      }
    </div>
  );
};

export default SequencesPage;