import React, { useEffect, useRef, useState } from 'react'
import colors from '../../../Utils/colors';
import { TextField } from '@mui/material';
import ToggleButtons from './ToggleButton';
import images from '../../../Utils/images';
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Switch from '@mui/material/Switch';
import { MuiColorInput, matchIsValidColor } from 'mui-color-input'

const CFButton = ({ buttonProps, updateButtonProps }) => {

    const handleChange = (e) => {
        const { name, value } = e.target;
        console.log("dsfsdfsdf ", name, value)
        updateButtonProps({ [name]: value });
    };

    return (
        <>
            <div className='d-flex flex-column justify-content-start align-items-center w-100' style={{
                backgroundColor: colors.white01,
                borderRadius: 12,
                border: `1px solid ${colors.borderwhite}`,
                paddingInline: 20,
                paddingBlock: 16,
                marginBlock: 8,
            }}>
                <div className='d-flex flex-row justify-content-between align-items-center w-100'>
                    <h4 className='mb-2' style={{
                        fontSize: 18,
                        fontWeight: 700,
                    }}>
                        {'Button'}
                    </h4>
                    <div className='d-flex flex-row justify-content-end align-items-center'>
                        <div className='d-flex flex-row justify-content-center align-items-center' style={{ marginInline: 8 }}>
                            <p>
                                {'Fixed to bottom'}
                            </p>
                            <Switch
                                checked={buttonProps.mandatory}
                                onChange={(e) => {
                                    let name = 'fixToBottom';
                                    updateButtonProps({ [name]: e.target.checked })
                                }}
                                sx={{ color: colors.primary03 }}
                                size='small'
                            />
                        </div>
                        <ToggleButtons
                            alignment={buttonProps.alignment}
                            handleChange={(value) => {
                                const name = 'alignment'
                                console.log("dsfsdfsdf ", name, value)
                                updateButtonProps({ [name]: value });
                            }}
                        />
                    </div>
                </div>

                {/* <div className='d-flex flex-column justify-content-stretch align-items-center w-100'>
            <TextField 
                name="title"
                value={titleProps.title}
                onChange={handleChange}
                type='text'
                size='small'
                style={{marginBlock:8,width:'100%'}}
                className='btncustom'
            />
        </div> */}

                <div className='d-flex flex-row justify-content-between align-items-cneter w-100 my-2'>
                    <div className='d-flex flex-column justify-content-stretch align-items-start px-2 py-2' style={{ flex: 1 }}>
                        <p style={{ fontWeight: 500 }}>
                            {'Button text'}
                        </p>
                        <TextField
                            name="buttonText"
                            placeholder='Enter form button text here'
                            value={buttonProps.buttonText}
                            onChange={handleChange}
                            type='text'
                            size='small'
                            style={{ marginBlock: 8, width: '100%' }}
                            className='btncustom'
                        />
                        {/* <Select
                    name='font'
                    value={titleProps.font}
                    onChange={handleChange}
                    style={{marginBlock:8,width:'100%'}}
                    size='small'
                >
                    {fonts.map((font) => (
                        <MenuItem key={font} value={font}>
                            {font}
                        </MenuItem>
                    ))}
                </Select> */}
                    </div>

                    <div className='d-flex flex-column justify-content-stretch align-items-start px-2 py-2' style={{ flex: 1 }}>
                        <p style={{ fontWeight: 500 }}>
                            {'Button Color'}
                        </p>
                        <MuiColorInput
                            value={buttonProps.buttonColor}
                            format={'hex'}
                            onChange={(color) => {
                                updateButtonProps({ ['buttonColor']: color })
                            }}
                            size='small'
                            style={{ marginBlock: 8, width: '100%' }}
                        />
                    </div>

                    {/* <div className='d-flex flex-column justify-content-stretch align-items-start px-2 py-2' style={{flex:1}}>
                <p style={{fontWeight:500}}>
                    {'Font Weight'}
                </p>
                <Select
                    name='fontWeigth'
                    value={titleProps.fontWeigth}
                    onChange={handleChange}
                    style={{marginBlock:8,width:'100%'}}
                    size='small'
                >
                    {fontWeight.map((fontweight) => (
                        <MenuItem key={fontweight} value={fontweight}>
                            {fontweight}
                        </MenuItem>
                    ))}
                </Select>
            </div> */}

                    <div className='d-flex flex-column justify-content-stretch align-items-start px-2 py-2' style={{ flex: 1 }}>
                        <p style={{ fontWeight: 500 }}>
                            {'Width (in %)'}
                        </p>
                        <TextField
                            name="width"
                            placeholder=''
                            value={buttonProps.width ? buttonProps.width.replace('%', '') : ''}
                            onChange={(e) => {
                                const value = e.target.value;
                                // Use empty string check instead of falsy check
                                if (value === '' || (!isNaN(value) && parseInt(value, 10) >= 0 && parseInt(value, 10) <= 100)) {
                                    updateButtonProps({ width: value === '' ? '' : `${value}%` });
                                }
                            }}
                            type='number'
                            inputProps={{ min: 0, max: 100 }}
                            size='small'
                            style={{ marginBlock: 8, width: '100%' }}
                            className='btncustom'
                        />
                    </div>

                    <div className='d-flex flex-column justify-content-stretch align-items-start px-2 py-2' style={{ flex: 1 }}>
                        <p style={{ fontWeight: 500 }}>
                            {'Corner Radius (in px)'}
                        </p>
                        <TextField
                            name="cornerRadius"
                            value={buttonProps.cornerRadius ?? ''}
                            onChange={(e) => {
                                const value = e.target.value;
                                if (value === '') {
                                    updateButtonProps({ cornerRadius: '' });
                                } else {
                                    const numValue = parseInt(value, 10);
                                    if (!isNaN(numValue)) {
                                        const clampedValue = Math.max(0, Math.min(30, numValue));
                                        updateButtonProps({ cornerRadius: clampedValue.toString() });
                                    }
                                }
                            }}
                            type='number'
                            inputProps={{ min: 0, max: 30 }}
                            size='small'
                            style={{ marginBlock: 8, width: '100%' }}
                            className='btncustom'
                        />
                    </div>
                </div>

                <div className='d-flex flex-column justify-content-stretch align-items-center w-100 px-2'>
                    <p style={{ fontWeight: 500, width: '100%' }}>
                        {'Message after click (Optional)'}
                    </p>
                    <TextField
                        name="message"
                        placeholder='Enter message to be displayed after form submission here'
                        value={buttonProps.message}
                        onChange={handleChange}
                        type='text'
                        size='small'
                        style={{ marginBlock: 8, width: '100%' }}
                        className='btncustom'
                    />
                </div>
            </div>
        </>
    )
}

export default CFButton