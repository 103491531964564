import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DeleteDraftTemplate, GetAllDraftTemplate } from '../../../Services';
import colors from '../../../Utils/colors';
import moment from 'moment';
import images from '../../../Utils/images';
import { languageList } from '../../CustomTemplate/CustomTemplate';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import SpinnerLoader from '../../../Components/SpinnerLoader';

const MyDrafts = () => {
  const authtoken = useSelector((state) => state.main.auth_token);
  const localdata = useSelector((state) => state.main.localdata);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const [allDraftsData, setAllDraftsData] = useState([]);

  useEffect(() => {
    getAllDraftsAPICall();
  }, []);

  const getAllDraftsAPICall = () => {
    setIsLoading(true);
    let token = authtoken;
    GetAllDraftTemplate(token).then((res) => {
      if (res?.flag) {
        setAllDraftsData(res?.data);
        setIsLoading(false);
        console.log('success all drafts ---> ', res);
      } else {
        setAllDraftsData([]);
        setIsLoading(false);
        console.log('failure all drafts ---> ', res);
      }
    });
  };

  const getFormattedDateTime = (item) => {
    let hhmm = moment(item).format('LT');
    let new_date = new Date(item);
    let year = new_date.getFullYear();
    let month = String(new_date.getMonth() + 1).padStart(2, '0');
    let day = String(new_date.getDate()).padStart(2, '0');
    return `${day}-${month}-${year}, ${hhmm}`;
  };

  const onclickdeleteAction = (_id) => {
    setIsLoading(true);
    let data = {
      _id: _id,
    };
    let token = authtoken;
    DeleteDraftTemplate(data, token).then((res) => {
      if (res) {
        toast.success('Draft deleted successfully', { position: 'top-right' });
        getAllDraftsAPICall();
        setIsLoading(false);
      } else {
        setIsLoading(false);
        toast.error('Failed to delete draft, please try again later', {
          position: 'top-right',
          
        });
      }
    });
  };

  return (
    <>
      <div
        className=""
        style={{
          width: '100%',
          height: '100%',
          overflow: 'hidden',
          paddingBlock: 24,
          paddingInline: 30,
        }}
      >
        {isLoading ? (
          <>
            <div className="d-flex justify-content-center align-items-center w-100 h-100">
              <SpinnerLoader />
            </div>
          </>
        ) : (
          <div
            style={{
              width: '100%',
              height: 'calc(100vh - 150px)', 
              overflowY: 'auto', 
              border: `1px solid ${colors.greys03}`, 
            }}
          >
          <table style={{ width: '100%' }}>
            <thead>
              <tr style={{ backgroundColor: colors.white02 }}>
                <th
                  style={{
                    paddingBlock: 10,
                    paddingInline: 16,
                    // border: `1px solid ${colors.greys03}`,
                    width: '40%',
                  }}
                >
                  {'Template Name'}
                </th>
                <th
                  style={{
                    paddingBlock: 10,
                    paddingInline: 16,
                    // border: `1px solid ${colors.greys03}`,
                    width: '20%',
                  }}
                >
                  {'Category'}
                </th>
                <th
                  style={{
                    paddingBlock: 10,
                    paddingInline: 16,
                    // border: `1px solid ${colors.greys03}`,
                    width: '15%',
                  }}
                >
                  {'Language'}
                </th>
                <th
                  style={{
                    paddingBlock: 10,
                    paddingInline: 16,
                    // border: `1px solid ${colors.greys03}`,
                    width: '15%',
                  }}
                >
                  {'Last Updated'}
                </th>
                <th
                  style={{
                    paddingBlock: 10,
                    paddingInline: 16,
                    // border: `1px solid ${colors.greys03}`,
                    textAlign: 'center',
                    width: '10%',
                  }}
                >
                  {'Action'}
                </th>
              </tr>
            </thead>
            <tbody>
              {allDraftsData?.length > 0 &&
                allDraftsData
                  .filter((item, index) => item?._id)
                  .map((item, index) => {
                    return (
                      <tr key={item?._id} style={{ backgroundColor: colors.white01 }}>
                        <td
                          style={{
                            paddingBlock: 10,
                            paddingInline: 16,
                            border: `1px solid ${colors.greys03}`,
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            wordBreak: 'break-all',
                            fontSize: 12,
                            fontWeight: 400,
                            color: colors.black04,
                          }}
                        >
                          {item?.data?.name}
                        </td>
                        <td
                          style={{
                            paddingBlock: 10,
                            paddingInline: 16,
                            border: `1px solid ${colors.greys03}`,
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            wordBreak: 'break-all',
                            fontSize: 12,
                            fontWeight: 400,
                            color: colors.black04,
                          }}
                        >
                          {item?.data?.category}
                        </td>
                        <td
                          style={{
                            paddingBlock: 10,
                            paddingInline: 16,
                            border: `1px solid ${colors.greys03}`,
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            wordBreak: 'break-all',
                            fontSize: 12,
                            fontWeight: 400,
                            color: colors.black04,
                          }}
                        >
                          {languageList.find((lang) => lang.key === item?.data?.language)?.value}
                        </td>
                        <td
                          style={{
                            paddingBlock: 10,
                            paddingInline: 16,
                            border: `1px solid ${colors.greys03}`,
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            wordBreak: 'break-all',
                            fontSize: 12,
                            fontWeight: 400,
                            color: colors.black04,
                          }}
                        >
                          {getFormattedDateTime(item?.updatedAt)}
                        </td>
                        <td
                          style={{
                            paddingBlock: 10,
                            paddingInline: 16,
                            border: `1px solid ${colors.greys03}`,
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            wordBreak: 'break-all',
                            fontSize: 12,
                            fontWeight: 400,
                            color: colors.black04,
                          }}
                        >
                          <div className="d-flex flex-row justify-content-center align-items-center">
                            <img
                              style={{
                                height: 20,
                                width: 20,
                                objectFit: 'contain',
                                cursor: 'pointer',
                                marginInline: 10,
                              }}
                              src={images.EditBlack}
                              onClick={() => {
                                navigate('/creator/templates/create', {
                                  state: { draftData: item },
                                });
                              }}
                            ></img>
                            <img
                              style={{
                                height: 20,
                                width: 20,
                                objectFit: 'contain',
                                cursor: 'pointer',
                                marginInline: 10,
                              }}
                              src={images.DeleteBlack}
                              onClick={() => {
                                onclickdeleteAction(item?._id);
                              }}
                            ></img>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
            </tbody>
          </table>
        </div>
        )}
      </div>
    </>
  );
};

export default MyDrafts;
