import React, { useEffect, useState, useRef } from 'react';
import colors from '../../../Utils/colors';
import images from '../../../Utils/images';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import styles from './../styles.module.css';
import '../styles.css';
import CheerioButton from '../../../Components/CheerioButton';
import { toast } from 'react-toastify';
// import { position } from 'stylis';
import { PremiumOptionModal } from '../../../Components/Modal/PremiumOptionModal';
import { ReactComponent as Badge } from '../../../Images/creatorNew/badge.svg';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import HEImages from './HEImages';
import { getImagePath } from '../../../Utils';
import CheerioWidget from './CheerioWidget';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { handleTrackEvent } from '../../../Utils/brevo';
import { eventsend } from '../../../Config/analyticsFunctions';
import PremiumFeatureModal from './UpdatePremiumModal';
import { getShopifyDetailsApi } from '../../../Services';

const SidemenuNew = ({
  notPremiumUser,
  sidemenuCollapse,
  setSidemenuCollapse,
  openInviteMembersAction,
  setProfilePopup,
  openProfileAction,
  openReferralModalAction,
  uploadHTMLAction,
}) => {
  const [selectedItem, setSelectedItem] = useState('Home');
  const [subSelectedItem, setSubSelectedItem] = useState('');
  const [isWidgetModalOpen, setIsWidgetModalOpen] = useState(false);
  //const [openPremiumModal, setOpenPremiumModal] = useState(false);
  const navigate = useNavigate();
  const localdata = useSelector((state) => state.main.localdata);
  const agentdata = useSelector((state) => state.main.agentdata);
  const location = useLocation();
  const isPremiumUser = localdata?.premium;
  const isTrialUser = localdata?.free_trail?.status;

  const MobileWidth = window.innerWidth < 900 ? true : false;

  const isBasic = localdata?.premiumPlanName === 'Basic' && localdata?.premiumSubscriptionId;
  const isStartup = localdata?.premiumPlanName === 'Startup' && localdata?.premiumSubscriptionId;
  const isPlanNameEmpty = !localdata?.premiumPlanName || localdata?.premiumPlanName === "";
  const [showPremiumModal, setShowPremiumModal] = useState(false);
  const sidebarRef = useRef(null);
  const releaseOnlyForProd = localdata?._id == '6489675b4f598e055ed74e34';
  const ExcelException = localdata?._id == '667575d0cdd6a6c7da92fab9' || localdata?._id == '6489675b4f598e055ed74e34';
  const VendorListException = localdata?._id == '6489675b4f598e055ed74e34' || localdata?._id === "66f2929e5c505ea5fa000674";
  const [ShopifyDetails, setShopifyDetails] = useState();

  //const [current, setCurrent] = useState('');

  //  const specialStatus = localdata?._id == '636be67d42c9a5cb33f60d98' ||
  //  localdata?._id == '635147d9eca6a13b38a7f258' ||
  //  localdata?._id == '646207b0eeb8e2e99b6ea39d'  ||
  //  localdata?._id == '649d17fa1396999a00d772b8' ||

  //  localdata?._id == '62ecc46bd11c49bbcd0e1ee8'
  // localdata?._id == '635147d9eca6a13b38a7f258';

  const templateUsers =
    localdata?._id === '6489675b4f598e055ed74e34' || localdata?._id === '636be67d42c9a5cb33f60d98' || localdata?.id === '660e878b6d6015c4e49b0434';
  const premiumOnly = [
    'WhatsApp Inbox',
    'Shopify Store',
    'ChatBot',
    'WhatsApp templates',
    'WooCommerce',
    'Email Builder',
    'Manage Sender Id',
    'Inbox',
    'Instagram',
    'WA Commerce',
  ];
  const authtoken = useSelector((state) => state.main.auth_token);
  const getShopifyDetailsAPIcall = () => {
    let token = authtoken;
    getShopifyDetailsApi(token).then((res) => {
      if (res?.flag) {
        setShopifyDetails(res?.data?.result);
      } else {
        toast.error(res?.message, { position: 'top-center' });
      }
    });
  };
  useEffect(() => {
    console.log('showPremiumModal changed:', showPremiumModal);
  }, [showPremiumModal]);
  useEffect(() => {
    if (location.pathname === '/creator') {
      setSelectedItem('Home');
      eventsend("Dashboard_nav_bar_clicked");
    }
    else if (location.pathname === '/creator/manageIntegrations/shopify/Analytics') {
      setSelectedItem('Shopify Analytics')
    }
    else if (location.pathname === '/creator/importcontacts') {
      setSelectedItem('Contacts');
      eventsend("Contacts_Navigation_bar_clicked");
    } else if (location.pathname === '/creator/contacts/labels') {
      setSelectedItem('Contacts');
      setSubSelectedItem('Labels');
      eventsend("Label_selected_drop_down");
    } else if (location.pathname === '/creator/excelLabel') {
      setSelectedItem('Contacts');
      setSubSelectedItem('Excel Labels');
      eventsend("Label_selected_drop_down");
    } else if (location.pathname === '/creator/vendorsList') {
      setSelectedItem('Contacts');
      setSubSelectedItem('Vendors List');
      eventsend("vendors_list_clicked");
    } else if (location.pathname === '/creator/contacts/contacts') {
      setSelectedItem('Contacts');
      setSubSelectedItem('All Contacts');
      eventsend("Contacts_Navigation_bar_clicked");
    } else if (location.pathname === '/creator/announcements') {
      setSelectedItem('Campaigns');
      eventsend("Campaign_Navigation_bar_clicked");
    } else if (location.pathname === '/creator/analytics') {
      setSelectedItem('Analytics');
      setSubSelectedItem('Campaign Analytics');
      eventsend("Campaign_Analytics_Navigation_bar_clicked");
    } else if (location.pathname === '/creator/discoverPlatforms') {
      setSubSelectedItem(MobileWidth ? 'Discover Platforms' : 'Discover Platforms');
      setSelectedItem('Integrations');
    } else if (
      location.pathname === '/creator/manageIntegrations' ||
      location.pathname === '/creator/manageIntegrations/shopify' ||
      location.pathname === '/creator/manageIntegrations/kylas' ||
      location.pathname === '/creator/manageIntegrations/woocommerce' ||
      location.pathname === '/creator/manageIntegrations/chatGpt'
    ) {
      setSubSelectedItem(MobileWidth ? 'Manage' : 'Manage');
      setSelectedItem('Integrations');
    } else if (location.pathname === '/creator/whatsappreplies') {
      setSubSelectedItem('WhatsApp Inbox');
      eventsend("Inbox_page_Navigation_bar_selected ");
      eventsend("Inbox_nav_bar_clicked");
      console.log('correct path triggered');
      setSelectedItem(MobileWidth ? 'WhatsApp Inbox' : 'Team Inbox');
    } else if (location.pathname === '/creator/templates') {
      setSubSelectedItem('WhatsApp templates');
      console.log('Incorrect path triggered');
      setSelectedItem(MobileWidth ? 'WhatsApp' : 'Template Library');
    } else if (location.pathname === '/creator/manageSender') {
      setSelectedItem('Email Manager');
      setSubSelectedItem('Manage Sender Id');
      eventsend("Manage_sender_id_Side_bar_clicked");
    } else if (location.pathname === '/creator/design') {
      setSelectedItem('Email Manager');
      setSubSelectedItem('Email Builder');
      eventsend("Email_builder_Navigation_bar_clicked");
    } else if (location.pathname === '/creator/instagramReplies') {
      setSubSelectedItem('Instagram Inbox');
      setSelectedItem('Team Inbox');
      eventsend("Instagram_inbox_nav_bar_clicked");
    } else if (location.pathname === '/creator/messengerReplies') {
      setSelectedItem('Messenger Inbox');
    }
    else if (location.pathname === '/creator/globalAnalytics') {
      setSelectedItem('Analytics');
      setSubSelectedItem('Global Analytics');
      eventsend("Global_Analytics_Navigation_bar_clicked");
    } else if (location.pathname === '/creator/agentAnalytics') {
      setSelectedItem('Analytics');
      setSubSelectedItem('Agent Analytics');
      eventsend("Agent_analytics_Navigation_bar_clicked");
    } else if (location.pathname === '/creator/keywords') {
      setSelectedItem('Automation');
      eventsend("Keywords_Navigation_bar_clicked");
      setSubSelectedItem('Keywords');
    } else if (location.pathname === '/creator/workflow') {
      setSelectedItem('Automation');
      setSubSelectedItem('Workflows');
      eventsend("Workflows_Navigation_bar_clicked");
    } else if (location.pathname === '/creator/timeTriggers') {
      setSelectedItem('Automation');
      setSubSelectedItem('Time Triggers');
    } else if (location.pathname === '/creator/chatbot') {
      setSelectedItem('Automation');
      eventsend("Chatbot_Automations_navigation_bar_clicked");
      setSubSelectedItem('ChatBot');
    } else if (location.pathname === '/creator/catalog') {
      setSelectedItem('WA Commerce')
    } else if (location.pathname === '/creator/sequences') {
      setSelectedItem('Sequences')
      eventsend("Sequences_Nav-bar_clicked");
    } else if (location.pathname === 'creator/smartForms') {
      setSelectedItem('Smart Forms')
      eventsend("Smart_forms_nav_bar_clicked");
    } else if (location.pathname === 'creator/embeddableForms') {
      setSelectedItem('Embeddable Forms')
    }
    else if (location.pathname === 'creator/linkedinsequences') {
      setSelectedItem('Linkedin Sequences')
      eventsend("LinkedIn-sequence_Sequences-page_clicked")
    }
  }, [location.pathname]);

  useEffect(() => {
    if (selectedItem) {
      console.log('current selected item: ', selectedItem);
    }
  }, [selectedItem]);

  useEffect(() => {
    getShopifyDetailsAPIcall();

    console.log('///////////////////// ', agentdata);
    console.log('///////////////////// ', agentdata?.permission?.contact);
    console.log('///////////////////// ', agentdata);
  }, []);

  const SidemenuItem = ({
    premiumBadge = false,
    ItemImage,
    ItemImageSel,
    ItemText,
    onclick,
    subMenu,
    openPremiumModal,
    setOpenPremiumModal,
  }) => {
    return (
      <>
        <div
          className={`${styles['Row']} ${styles['SidemenuItem']}`}
          style={{
            backgroundColor: selectedItem == ItemText ? '#F5F0FF' : colors.white,
            paddingInline: sidemenuCollapse ? 10 : 16,
            justifyContent: sidemenuCollapse ? 'flex-start' : 'flex-start',
            display: 'flex',
            justifyContent: 'space-between',
          }}
          onClick={() => {
            onclick();
          }}
        >
          <div className="d-flex flex-row align-items-center h-100">
            <img
              className={`${styles['Image']}`}
              src={selectedItem == ItemText ? ItemImageSel : ItemImage}
            ></img>
            {!sidemenuCollapse && (
              <h4
                className={`${styles['SidemenuItem_Text']}`}
                style={{
                  color: selectedItem == ItemText ? colors.themeDarkBlue : colors.black04,
                  // marginTop: '3px',
                }}
              >
                {ItemText}
              </h4>
            )}
          </div>

          <div>
            {' '}
            {premiumBadge && !sidemenuCollapse && <Badge></Badge>}
            <div style={{ width: '40px' }}></div>
          </div>

          <div>
            {' '}
            {premiumBadge && !sidemenuCollapse && <Badge></Badge>}
            <div style={{ width: '40px' }}></div>
          </div>

          {/* {openPremiumModal && <PremiumOptionModal option={ItemText} openPremiumModal={openPremiumModal} setOpenPremiumModal={setOpenPremiumModal}></PremiumOptionModal>} */}

          {/* <div class="dropdown" style={{}}>

  <div class="dropdown-content" style={sidemenuCollapse? {marginLeft:"0"}:{}}>
    <a href="#">Link 1</a>
    <a href="#">Link 2</a>
    <a href="#">Link 3</a>
  </div>
</div> */}
        </div>
      </>
    );
  };

  const SubSidemenuItem = ({
    ItemImage,
    ItemImageSel,
    ItemText,
    onclick,
    options,
    optionsImage,
    optionSelectedImage,
    endpoints,
    premiumBadge = false,
  }) => {
    return (
      <div class="dropdown" style={{ width: '100%' }}>
        <div
          className={`${styles['Row']} ${styles['SidemenuItem']}`}
          style={{
            backgroundColor: selectedItem == ItemText ? '#F5F0FF' : colors.white,
            paddingInline: sidemenuCollapse ? 10 : 16,
            justifyContent: sidemenuCollapse ? 'flex-start' : 'flex-start',
            display: 'flex',
            justifyContent: 'space-between',
          }}
          onClick={() => {
            onclick();
          }}
        >
          <div className="d-flex flex-row align-items-center h-100">
            <img
              className={`${styles['Image']}`}
              src={selectedItem == ItemText ? ItemImageSel : ItemImage}
            ></img>
            {!sidemenuCollapse && (
              <h4
                className={`${styles['SidemenuItem_Text']}`}
                style={{
                  color: selectedItem == ItemText ? colors.themeDarkBlue : colors.black04,
                  // marginTop: '3px',
                }}
              >
                {ItemText}
              </h4>
            )}
          </div>
          {/* //<button class="dropbtn">Dropdown</button> */}
          <div>
            {' '}
            {premiumBadge && !sidemenuCollapse && <Badge></Badge>}
            <img src={images.RightArrow} className={`${styles['Image']}`}></img>
          </div>

          <div
            class="dropdown-content"
            style={sidemenuCollapse ? { marginLeft: '0', width: '20vw' } : {}}
          >
            {options.map((option, index) => (
              <div className="d-flex justify-content-between" style={{ width: '100%' }}>
                <div
                  style={{
                    fontSize: 14,
                    fontWeight: '500',
                    backgroundColor: subSelectedItem == option ? '#F5F0FF' : '',
                    borderRadius: '8px',
                    height: '40px',
                    whiteSpace: 'nowrap',
                    color: subSelectedItem == option ? colors.themeDarkBlue : colors.black04,
                    width: '130%',
                  }}
                  key={index}
                  onClick={() => {
                    if (isPremiumUser) {
                      setSelectedItem(ItemText);
                      setSubSelectedItem(option);
                      navigate(`/creator/${endpoints[index]}`);
                      if (endpoints[index] === 'whatsappreplies') {
                        //window.location.reload();
                      }
                    } else if (isTrialUser) {
                      if (premiumOnly.includes(option)) {
                        setSelectedItem(ItemText);
                        setSubSelectedItem(option);
                        navigate(`/creator/${endpoints[index]}`);
                        if (endpoints[index] === 'whatsappreplies') {
                          //window.location.reload();
                        }
                      } else {
                        setSelectedItem(ItemText);
                        setSubSelectedItem(option);
                        navigate(`/creator/${endpoints[index]}`);
                        if (endpoints[index] === 'whatsappreplies') {
                          //window.location.reload();
                        }
                      }
                    } else {
                      notPremiumUser();
                    }
                  }}
                >
                  <img
                    className={`${styles['Image']}`}
                    style={{ width: '22px', height: '22px', marginRight: '5px' }}
                    src={
                      subSelectedItem == option ? optionSelectedImage[index] : optionsImage[index]
                    }
                  ></img>
                  {option}
                </div>

                {isTrialUser && !isPremiumUser && premiumOnly.includes(option) && (
                  <div
                    style={{
                      marginTop: '-2px',
                      width: '20%',
                      border: '2px solid red',
                      marginLeft:
                        option === 'Manage Sender Id' || 'WhatsApp templates' ? '-13px' : '0px',
                    }}
                  >
                    {/* {!sidemenuCollapse ? (
                      <Badge></Badge>
                    ) : ( */}
                    <img src={images.Premium} height={15}></img>
                  </div>
                )}
              </div>
            ))}
            {/* <a href="#">Link 1</a>
    <a href="#">Link 2</a>
    <a href="#">Link 3</a> */}
          </div>
        </div>
      </div>
    );
  };

  const specialID =
    localdata?._id === '636be67d42c9a5cb33f60d98' ||
    localdata?._id === '660e878b6d6015c4e49b0434' ||
    localdata?._id === '6489675b4f598e055ed74e34' ||
    localdata?._id === '65f7d39aecb11f98652fffcd' ||
    localdata?._id === '65f7d42aecb11f98652fffd2';

  const hideAnalytics =
    localdata?._id === "671ce3adf954d69eaef89be1" ||
    localdata?._id === "671cdedcf954d69eaef494cf" ||
    localdata?._id === "671ccb67f954d69eaeeba49f" ||
    localdata?._id === "6719309f3beb1c2c7b154326" ||
    localdata?._id === "66d94723e94b89869e2c46af" ||
    localdata?._id === "67319ae99fb52a43f93b45cb" ||
    localdata?._id === "672f45779fb52a43f998cfa7";

  const SidemenuItem2 = ({
    SItext,
    SIicon,
    SIiconSel,
    onclickAction,
    premiumBadge = false,
    webOnly = false,
  }) => {
    return (
      <>
        <div
          className="sidemenu_item_2"
          style={{
            justifyContent: sidemenuCollapse ? 'center' : 'flex-start',
            backgroundColor: selectedItem == SItext ? '#D9C3FF' : '#FFFF',
            opacity: webOnly ? '0.6' : '1',
          }}
          onClick={() => {
            if (!webOnly) {
              onclickAction();
            }
          }}
        >
          <img
            className="sidemenu_item_2__icon"
            src={selectedItem == SItext ? SIiconSel : SIicon}
            style={{ marginInlineEnd: sidemenuCollapse ? 0 : 8 }}
          ></img>
          {!sidemenuCollapse && (
            <h4
              style={{
                color: selectedItem == SItext ? '#8E53F7' : '#404040',
              }}
            >
              {SItext}
            </h4>
          )}

          {premiumBadge && !sidemenuCollapse && (
            <div className="badge">
              <Badge></Badge>
            </div>
          )}
        </div>
      </>
    );
  };

  const SidemenuLgItem = ({ ItemImage, ItemImageSel, Itemtext, onclickAction }) => {
    const tooltip = (
      <Tooltip id="tooltip" style={{ position: 'fixed' }}>
        {Itemtext}
      </Tooltip>
    );

    return (
      <>
        {/* <OverlayTrigger
          placement="right"
          // overlay={<Tooltip>{Itemtext}</Tooltip>}
        > */}
        <div className="menu-item" onClick={onclickAction}>
          <div
            className="inner-container"
            style={{
              padding: 20,
              // marginTop: Itemtext === 'Home' ? 4 : 0,
              backgroundColor: selectedItem === Itemtext ? colors.primary01 : colors.white01,
            }}
          >
            <div className="d-flex">
              <img
                src={selectedItem === Itemtext ? ItemImageSel : ItemImage}
                style={{
                  height: Itemtext === 'WA Commerce' ? 22 : 24,
                  width: Itemtext === 'WA Commerce' ? 22 : 24,
                }}
              />
              <p style={{ color: selectedItem === Itemtext ? colors.primary03 : colors.black }}>
                {Itemtext}
              </p>
            </div>

            {/* {(Itemtext === 'Sequences' && sidemenuCollapse && 
           <img 
                               src={images.NEWICON} 
                               style={{height:25,width:45}} 
                              
                           />
            //  <img src={images.NEWICON}></img>
           )} */}
          </div>
        </div>
        {/* </OverlayTrigger> */}
      </>
    );
  };

  const SidemenuLgItemSub = ({
    ItemImage,
    ItemImageSel,
    Itemtext,
    options = [],
    OImages = [],
    OImagesSel = [],
    endpoints = [],
    sidebarRef,
  }) => {
    const [submenuPosition, setSubmenuPosition] = useState({ top: 0, left: 0 });
    const menuItemRef = useRef(null);



    useEffect(() => {
      const handleScroll = () => {
        if (menuItemRef.current && sidebarRef.current) {
          const menuItemRect = menuItemRef.current.getBoundingClientRect();
          const sidebarRect = sidebarRef.current.getBoundingClientRect();
          setSubmenuPosition({
            top: menuItemRect.bottom - sidebarRect.top, // Adjust for the scroll position
            left: menuItemRect.left - sidebarRect.left
          });
        }
      };

      const sidebarElement = sidebarRef.current;
      sidebarElement.addEventListener('scroll', handleScroll);

      return () => {
        sidebarElement.removeEventListener('scroll', handleScroll);
      };
    }, [sidebarRef]);

    const SubMenu = ({ position }) => {
      return (
        <div
          className="submenu_super_container"
          style={{
            height: options.length.toString() * 47 + 47,
            position: 'fixed',
            top: position.top !== 0 && `${position.top - ((options.length.toString() * 60) / 2)}px`,
            zIndex: 2
          }}
        >
          <div
            className="submenu_container"
            style={{
              height: options.length.toString() * 50 + 50,
              width: 240,
              position: 'relative',
              top: Itemtext == 'Automation' ? -70 : 0,

              zIndex: 10,
              backgroundColor: colors.white01,
              border: `1px solid ${colors.borderwhite}`,
              borderRadius: 10,
              boxShadow: `1px 2px 9px #B2BEB5`,
            }}
          >
            <div
              className="w-100 d-flex flex-row justify-content-start align-items-center"
              style={{ paddingInline: 20, paddingBlock: 0, height: 45, marginBottom: -6 }}
            >
              <p
                style={{
                  width: '100%',
                  fontSize: 14,
                  fontWeight: 600,
                }}
              >
                {Itemtext}
              </p>
            </div>
            {options.map((item, index) => {
              return (
                <div
                  className="w-100 options_container"
                  style={{ paddingInline: 10, paddingBlock: 10, height: 51 }}
                >
                  <div
                    className="w-100 h-100 d-flex flex-row justify-content-start align-items-center"
                    style={{
                      borderRadius: 8,
                      padding: 10,
                      backgroundColor: subSelectedItem === item ? colors.primary01 : '',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      handleTrackEvent('sidebar_' + item + '_button_clicked', localdata);
                      if (endpoints[index] === 'uploadHTML') {
                        uploadHTMLAction();
                      }
                      if ((endpoints[index] === 'instagramReplies' || endpoints[index] == 'whatsappreplies') && isPremiumUser && isBasic) {
                        setShowPremiumModal(true);
                      }
                      else if ((endpoints[index] === 'instagramReplies' || endpoints[index] == 'whatsappreplies') && isPremiumUser || isTrialUser) {
                        setSelectedItem(Itemtext);
                        setSubSelectedItem(item);
                        navigate(`/creator/${endpoints[index]}`);
                      }
                      else if (isPremiumUser) {
                        setSelectedItem(Itemtext);
                        setSubSelectedItem(item);
                        navigate(`/creator/${endpoints[index]}`);
                      } else if (isTrialUser) {
                        if (premiumOnly.includes(item)) {
                          setSelectedItem(Itemtext);
                          setSubSelectedItem(item);
                          navigate(`/creator/${endpoints[index]}`);
                        } else {
                          setSelectedItem(Itemtext);
                          setSubSelectedItem(item);
                          navigate(`/creator/${endpoints[index]}`);
                        }
                      } else {
                        notPremiumUser();
                      }
                    }}
                  >
                    <img
                      src={subSelectedItem === item ? OImagesSel[index] : OImages[index]}
                      style={{
                        height: 24,
                        width: 24,
                        objectFit: 'contain',
                        marginInline: 10,
                      }}
                    ></img>
                    <p
                      style={{
                        width: '100%',
                        fontSize: 14,
                        fontWeight: 500,
                        color: subSelectedItem === item ? colors.primary03 : colors.black,
                      }}
                    >
                      {item}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      );
    };

    return (
      <>
        {/* <OverlayTrigger placement='right' overlay={customTip}> */}
        <div
          ref={menuItemRef}
          className="d-flex justify-content-center align-items-center w-100 sidemenu_lg_sub_container"
          style={{ paddingBlock: 10, height: 40, position: 'relative' }}
          onClick={() => {
            handleTrackEvent('sidebar_' + Itemtext + '_button_clicked', localdata);
          }}
        >
          <SubMenu position={submenuPosition} />
          <div className="menu-item ">
            <div
              className="inner-container"
              style={{
                padding: 20,
                backgroundColor: selectedItem === Itemtext ? colors.primary01 : colors.white01,
              }}
            >
              <div className="d-flex">
                <img src={selectedItem === Itemtext ? ItemImageSel : ItemImage} />
                <p style={{ color: selectedItem == Itemtext ? colors.primary03 : colors.black }}>
                  {Itemtext}
                </p>
              </div>
              <MdKeyboardArrowRight
                class="arrow-image"
                style={{ color: selectedItem == Itemtext ? colors.primary03 : colors.black }}
              />
              {/* <img  src={images.RightArrow}></img> */}
            </div>
          </div>
        </div>
        {/* </OverlayTrigger> */}
      </>
    );
  };

  return (
    <>
      {MobileWidth ? (
        <>
          <div
            className="creator_sidemenu"
            style={{
              display: sidemenuCollapse && window.innerWidth < 700 ? 'none' : 'flex',
              width: sidemenuCollapse ? 75 : 240,
              paddingInline: sidemenuCollapse ? 8 : MobileWidth ? 10 : 16,
            }}
          >
            {MobileWidth ? (
              <>
                <div className="creator_sidemenu__top">
                  <SidemenuItem2
                    SItext={'Home'}
                    SIicon={images.SNHome}
                    SIiconSel={images.SNHomeSel}
                    onclickAction={() => {
                      handleTrackEvent('sidebar_home_button_clicked', localdata);
                      setSelectedItem('Home');
                      navigate('/creator');
                      setTimeout(() => {
                        setSidemenuCollapse(true);
                      }, 500);
                    }}
                  />
                  {(agentdata?.permission?.contact || !agentdata) && (
                    <SidemenuItem2
                      SItext={'Contacts'}
                      SIicon={images.SNContacts}
                      SIiconSel={images.SNContactsSel}
                      premiumBadge={isPremiumUser ? false : true}
                      onclickAction={() => {
                        handleTrackEvent('sidebar_contacts_button_clicked', localdata);
                        if (isPremiumUser || isTrialUser) {
                          setSelectedItem('Contacts');
                          navigate('/creator/contacts/labels');
                        } else {
                          notPremiumUser();
                        }
                        setTimeout(() => {
                          setSidemenuCollapse(true);
                        }, 500);
                      }}
                    />
                  )}
                  {(agentdata?.permission?.campaign || (!agentdata && !hideAnalytics)) && (
                    <SidemenuItem2
                      SItext={'Campaigns'}
                      SIicon={images.SNCampaigns}
                      SIiconSel={images.SNCampaignsSel}
                      premiumBadge={isPremiumUser ? false : true}
                      onclickAction={() => {
                        handleTrackEvent('sidebar_campaigns_button_clicked', localdata);
                        if (isPremiumUser) {
                          setSelectedItem('Campaigns');
                          navigate('/creator/announcements');
                        } else if (isTrialUser) {
                          setSelectedItem('Campaigns');
                          navigate('/creator/announcements');
                        } else {
                          notPremiumUser();
                        }
                        setTimeout(() => {
                          setSidemenuCollapse(true);
                        }, 500);
                      }}
                    />
                  )}
                  {(agentdata?.permission?.analytics || !agentdata) && (
                    <SidemenuItem2
                      SItext={'Analytics'}
                      SIicon={images.SNAnalytics}
                      SIiconSel={images.SNAnalyticsSel}
                      onclickAction={() => {
                        handleTrackEvent('sidebar_analytics_button_clicked', localdata);
                        if (isPremiumUser || isTrialUser) {
                          setSelectedItem('Analytics');
                          navigate('/creator/analytics');
                        } else {
                          notPremiumUser();
                        }
                        setTimeout(() => {
                          setSidemenuCollapse(true);
                        }, 500);
                      }}
                    />
                  )}
                  {(agentdata?.permission?.campaign ||
                    agentdata?.permission?.chatReplies ||
                    !agentdata) && (
                      <SidemenuItem2
                        SItext={'WhatsApp Inbox'}
                        SIicon={images.SNChatReply}
                        SIiconSel={images.SNChatReplySel}
                        onclickAction={() => {
                          handleTrackEvent('sidebar_whatsapp_inbox_button_clicked', localdata);
                          if (isPremiumUser || isTrialUser) {
                            setSelectedItem('WhatsApp Inbox');
                            navigate('/creator/whatsappreplies');
                          } else {
                            notPremiumUser();
                          }
                          setTimeout(() => {
                            setSidemenuCollapse(true);
                          }, 500);
                        }}
                      />
                    )}

                  {MobileWidth && (
                    <>
                      <div className="web_only">
                        {!sidemenuCollapse && (
                          <div className="web_only__title">
                            <h4>{'Available on desktop'}</h4>
                          </div>
                        )}

                        <SidemenuItem2
                          SItext={'Templates'}
                          SIicon={images.SNChatReply}
                          webOnly={true}
                        />
                        <SidemenuItem2 SItext={'Email'} SIicon={images.IconMail} webOnly={true} />
                        <SidemenuItem2
                          SItext={'Integrations'}
                          SIicon={images.IconIntegration}
                          webOnly={true}
                        />
                        {/* <SidemenuItem2
                          SItext={'Integrations'}
                          SIicon={images.IconIntegration}
                          webOnly={true}
                        /> */}
                      </div>
                    </>
                  )}
                </div>
                {!sidemenuCollapse && (
                  <div className="creator_sidemenu__bottom">
                    <div
                      className="raise_ticket"
                      onClick={() => {
                        window.open('mailto:support@cheerio.freshdesk.com?subject=Contact Us');
                        setTimeout(() => {
                          setSidemenuCollapse(true);
                        }, 500);
                      }}
                    >
                      <h4>{'Raise a Ticket'}</h4>
                    </div>
                  </div>
                )}
              </>
            ) : (
              // <>
              //   <div className={`${styles['Column']} ${styles['SidemenuInnerContainer']}`}>
              //     <SidemenuItem
              //       ItemImage={images.SNHome}
              //       ItemImageSel={images.SNHomeSel}
              //       ItemText={'Home'}
              //       onclick={() => {
              //         setSelectedItem('Home');
              //         navigate('/creator');
              //       }}
              //     />

              //     {(agentdata?.permission?.contact || !agentdata) && (
              //       <SidemenuItem
              //         ItemImage={images.SNContacts}
              //         ItemImageSel={images.SNContactsSel}
              //         ItemText={'Contacts'}
              //         premiumBadge={isPremiumUser ? false : true}
              //         onclick={() => {
              //           if (isPremiumUser || isTrialUser) {
              //             setSelectedItem('Contacts');
              //             navigate('/creator/contacts/labels');
              //           } else {
              //             notPremiumUser();
              //           }
              //         }}
              //       />
              //     )}

              //     {(agentdata?.permission?.campaign || !agentdata) && (
              //       <SidemenuItem
              //         ItemImage={images.SNCampaigns}
              //         ItemImageSel={images.SNCampaignsSel}
              //         ItemText={'Campaigns'}
              //         premiumBadge={isPremiumUser ? false : true}
              //         onclick={() => {
              //           if (isPremiumUser) {
              //             setSelectedItem('Campaigns');
              //             navigate('/creator/announcements');
              //           } else if (isTrialUser) {
              //             setSelectedItem('Campaigns');
              //             navigate('/creator/announcements');
              //           } else {
              //             notPremiumUser();
              //           }
              //         }}
              //       />
              //     )}

              //     {(agentdata?.permission?.analytics || !agentdata) && (
              //       <SidemenuItem
              //         ItemImage={images.SNAnalytics}
              //         ItemImageSel={images.SNAnalyticsSel}
              //         ItemText={'Analytics'}
              //         onclick={() => {
              //           if (isPremiumUser || isTrialUser) {
              //             setSelectedItem('Analytics');
              //             navigate('/creator/analytics');
              //           } else {
              //             notPremiumUser();
              //           }
              //         }}
              //       />
              //     )}

              //     {(agentdata?.permission?.campaign ||
              //       agentdata?.permission?.chatReplies ||
              //       !agentdata) && (
              //       <SubSidemenuItem
              //         ItemImage={images.SNChatReply}
              //         ItemImageSel={images.SNChatReplySel}
              //         ItemText={'WhatsApp'}
              //         premiumBadge={isPremiumUser ? false : true}
              //         options={
              //           agentdata?.permission?.campaign &&
              //           agentdata?.permission?.chatReplies &&
              //           localdata?.whatsapp_config == 'Active'
              //             ? ['WhatsApp Inbox', 'WhatsApp templates']
              //             : agentdata?.permission?.campaign &&
              //               agentdata?.permission?.chatReplies &&
              //               localdata?.whatsapp_config !== 'Active'
              //             ? ['WhatsApp Inbox']
              //             : agentdata?.permission?.campaign &&
              //               !agentdata?.permission?.chatReplies &&
              //               localdata?.whatsapp_config == 'Active'
              //             ? ['WhatsApp templates']
              //             : !agentdata?.permission?.campaign && agentdata?.permission?.chatReplies
              //             ? ['WhatsApp Inbox']
              //             : !agentdata && localdata?.whatsapp_config == 'Active'
              //             ? ['WhatsApp Inbox', 'WhatsApp templates']
              //             : ['WhatsApp Inbox']
              //         }
              //         endpoints={
              //           agentdata?.permission?.campaign &&
              //           agentdata?.permission?.chatReplies &&
              //           localdata?.whatsapp_config == 'Active'
              //             ? ['whatsappreplies', 'templates']
              //             : agentdata?.permission?.campaign &&
              //               agentdata?.permission?.chatReplies &&
              //               localdata?.whatsapp_config !== 'Active'
              //             ? ['whatsappreplies']
              //             : agentdata?.permission?.campaign &&
              //               !agentdata?.permission?.chatReplies &&
              //               localdata?.whatsapp_config == 'Active'
              //             ? ['templates']
              //             : !agentdata?.permission?.campaign && agentdata?.permission?.chatReplies
              //             ? ['whatsappreplies']
              //             : !agentdata && localdata?.whatsapp_config == 'Active'
              //             ? ['whatsappreplies', 'templates']
              //             : ['whatsappreplies']
              //         }
              //         optionsImage={[images.IconTalk, images.Templates]}
              //         optionSelectedImage={[images.SelIconTalk, images.TemplatesSel]}
              //         onclick={() => {
              //           if (isPremiumUser) {
              //           } else {
              //           }
              //         }}
              //       />
              //     )}

              //     {(agentdata?.permission?.email || !agentdata) && (
              //       <SubSidemenuItem
              //         ItemImage={images.IconMail}
              //         ItemImageSel={images.IconMailSel}
              //         ItemText={'Email'}
              //         premiumBadge={isPremiumUser ? false : true}
              //         options={['Email Builder', 'Manage SenderId']}
              //         endpoints={['design', 'manageSender']}
              //         optionsImage={[images.SNDesign, images.ManageSender]}
              //         optionSelectedImage={[images.SNDesignSel, images.SelManageSender]}
              //         onclick={() => {
              //           if (isPremiumUser) {
              //           } else {
              //           }
              //         }}
              //       />
              //     )}

              //     {(agentdata?.permission?.integrations ||
              //       !agentdata) && (
              //       <SubSidemenuItem
              //         ItemImage={images.IconIntegration}
              //         ItemImageSel={images.IconIntegrationSel}
              //         ItemText={'Integrations'}
              //         premiumBadge={isPremiumUser ? false : true}
              //         options={
              //           ['Shopify Store', 'WooCommerce']
              //         }
              //         endpoints={
              //           ['shopifystore', 'woocommerce']
              //         }
              //         optionsImage={[images.SNShopify, images.wooComShopLogo]}
              //         optionSelectedImage={[images.SNShopifySel, images.WooCommerceSel]}
              //         onclick={() => {
              //           if (isPremiumUser) {
              //           } else {
              //           }
              //         }}
              //       />
              //     )}

              //     {(agentdata?.permission?.automation || !agentdata) && (
              //       <SubSidemenuItem
              //         ItemImage={images.IconAutomation}
              //         ItemImageSel={images.IconAutomationSel}
              //         ItemText={'Automation'}
              //         premiumBadge={isPremiumUser ? false : true}
              //         options={
              //           ['ChatBot', 'Workflows']
              //         }
              //         endpoints={['chatbot', 'workflow']}
              //         optionsImage={[images.IconChatBot, images.IconWorkFlow]}
              //         optionSelectedImage={[images.IconChatBotSel, images.IconWorkFlowSel]}
              //         onclick={() => {
              //           if (isPremiumUser) {
              //           } else {
              //           }
              //         }}
              //       />
              //     )}
              //   </div>

              //   {!sidemenuCollapse && (
              //     <>
              //       <div className="w-100 d-flex flex-column justify-content-between align-items-center">
              //         {!agentdata && (
              //           <CheerioButton
              //             borderStyle={{ marginBlockEnd: 16 }}
              //             textStyle={{ color: colors.black }}
              //             btnText={'Invite Members'}
              //             backColor={colors.white}
              //             onclick={() => {
              //               if (isPremiumUser) {
              //                 openInviteMembersAction();
              //               } else {
              //                 toast.error(
              //                   'You are trying to access a premium feature. Kindly buy a subscription of your preference to enjoy this and many other amazing features.',
              //                   { position: 'top-center' }
              //                 );
              //               }
              //             }}
              //             icon={images.MPPlus}
              //           />
              //         )}
              //         <p
              //           className={`${styles['Sidemenu_ContactUs']}`}
              //           style={{
              //             color: colors.black,
              //           }}
              //           onClick={() => {
              //             window.open('mailto:support@cheerio.freshdesk.com?subject=Contact Us');
              //           }}
              //         >
              //           {'Raise a Ticket'}
              //         </p>
              //       </div>
              //     </>
              //   )}
              // </>
              <></>
            )}
          </div>
        </>
      ) : (
        <>
          <div className="sidemenu_lg_container" style={{}}>
            <div className="logo-container" style={{ borderBottom: '1px solid #E6E6E6' }}>
              <img className="logo_img default-logo" src={images.SNLogo}></img>
              <img className="logo_img hover-logo" src={images.LOGONEW}></img>
            </div>
            <div
              ref={sidebarRef}
              className="verythinscrollnew"
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'center',
                width: '100%',
                overflowX: 'hidden',
                margin: 0,
                flex: 1,
                overflowY: 'auto',
              }}
            >
              <SidemenuLgItem
                ItemImage={images.SNHome}
                ItemImageSel={images.SNHomeSel}
                Itemtext={'Home'}
                onclickAction={() => {
                  handleTrackEvent('sidebar_home_button_clicked', localdata);
                  setSelectedItem('Home');
                  navigate('/creator');
                }}
              />
              {(agentdata?.permission?.contact || !agentdata) && (
                <SidemenuLgItemSub
                  sidebarRef={sidebarRef}
                  ItemImage={images.SNContacts}
                  ItemImageSel={images.SNContactsSel}
                  Itemtext={'Contacts'}
                  options={[
                    ...(agentdata?.permission?.allContact || !agentdata ? ['All Contacts'] : []),
                    ...(agentdata?.permission?.labels || !agentdata ? ['Labels'] : []),
                    ...(ExcelException ? ['Excel Labels'] : []),
                    ...(VendorListException ? ['Vendors List'] : []),
                  ]}
                  OImages={[
                    ...(agentdata?.permission?.allContact || !agentdata ? [images.SNContactsAll] : []),
                    ...(agentdata?.permission?.labels || !agentdata ? [images.SNLabels] : []),
                    ...(ExcelException ? [images.SNExcelUnsel] : []),
                    ...(VendorListException ? [images.SNContacts] : []),
                  ]}
                  OImagesSel={[
                    ...(agentdata?.permission?.allContact || !agentdata ? [images.SNContactsAllSel] : []),
                    ...(agentdata?.permission?.labels || !agentdata ? [images.SNLabelsSel] : []),
                    ...(ExcelException ? [images.SNExcelSel] : []),
                    ...(VendorListException ? [images.SNContactsSel] : []),
                  ]}
                  endpoints={[
                    ...(agentdata?.permission?.allContact || !agentdata ? ['contacts/contacts'] : []),
                    ...(agentdata?.permission?.labels || !agentdata ? ['contacts/labels'] : []),
                    ...(ExcelException ? ['excelLabel'] : []),
                    ...(VendorListException ? ['vendorsList'] : []),
                  ]}
                />
              )}
              {(agentdata?.permission?.campaign || (!agentdata && !hideAnalytics)) && (
                <SidemenuLgItem
                  ItemImage={images.SNCampaigns}
                  ItemImageSel={images.SNCampaignsSel}
                  Itemtext={'Campaigns'}
                  onclickAction={() => {
                    handleTrackEvent('sidebar_campaigns_button_clicked', localdata);
                    if (isPremiumUser) {
                      setSelectedItem('Campaigns');
                      navigate('/creator/announcements');
                    } else if (isTrialUser) {
                      setSelectedItem('Campaigns');
                      navigate('/creator/announcements');
                    } else {
                      notPremiumUser();
                    }
                  }}
                />
              )}
              {(agentdata?.permission?.analytics || !agentdata) && (
                <SidemenuLgItemSub
                  sidebarRef={sidebarRef}
                  ItemImage={images.SNAnalytics}
                  ItemImageSel={images.SNAnalyticsSel}
                  Itemtext={'Analytics'}
                  options={[
                    ...(agentdata?.permission?.globalAnalytics || (!hideAnalytics && !agentdata) ? ['Global Analytics'] : []),
                    ...(agentdata?.permission?.campaignAnalytics || !agentdata ? ['Campaign Analytics'] : []),
                    ...(agentdata?.permission?.agentAnalytics || !agentdata ? ['Agent Analytics'] : []),
                  ]}
                  OImages={[
                    ...(agentdata?.permission?.globalAnalytics || (!hideAnalytics && !agentdata) ? [images.GlobalAnalyticsIcon] : []),
                    ...(agentdata?.permission?.campaignAnalytics || !agentdata ? [images.CampaignAnalyticsIcon] : []),
                    ...(agentdata?.permission?.agentAnalytics || !agentdata ? [images.KWAgent] : []),
                  ]}
                  OImagesSel={[
                    ...(agentdata?.permission?.globalAnalytics || (!hideAnalytics && !agentdata) ? [images.GlobalAnalyticsIconSel] : []),
                    ...(agentdata?.permission?.campaignAnalytics || !agentdata ? [images.CampaignAnalyticsIconSel] : []),
                    ...(agentdata?.permission?.agentAnalytics || !agentdata ? [images.KWAgentSel] : []),
                  ]}
                  endpoints={[
                    ...(agentdata?.permission?.globalAnalytics || (!hideAnalytics && !agentdata) ? ['globalAnalytics'] : []),
                    ...(agentdata?.permission?.campaignAnalytics || !agentdata ? ['analytics'] : []),
                    ...(agentdata?.permission?.agentAnalytics || !agentdata ? ['agentAnalytics'] : []),
                  ]}
                />
              )}

              {/* {(agentdata?.permission?.chatReplies ||agentdata?.permission?.instaReplies || !agentdata) && (
                <SidemenuLgItem
                  ItemImage={images.SNInbox}
                  ItemImageSel={images.SNInboxSel}
                  Itemtext={'Team Inbox'}
                  onclickAction={() => {
                    handleTrackEvent('sidebar_whatsapp_inbox_button_clicked', localdata);

                    if (isPremiumUser && isBasic) {
                      setShowPremiumModal(true);
                    }
                    else if (isPremiumUser || isTrialUser) {
                      setSelectedItem('Inbox');
                      navigate('/creator/whatsappreplies');
                      // window.location.reload();
                    } else {
                      notPremiumUser();
                    }
                  }}
                />
              )} */}

              {(agentdata?.permission?.chatReplies || agentdata?.permission?.instaReplies || !agentdata) && (
                <SidemenuLgItemSub
                  sidebarRef={sidebarRef}
                  ItemImage={images.SNInbox}
                  ItemImageSel={images.SNInboxSel}
                  Itemtext={'Team Inbox'}
                  options={[
                    ...(agentdata?.permission?.chatReplies || !agentdata ? ['WhatsApp Inbox'] : []),
                    ...(agentdata?.permission?.instaReplies || !agentdata ? ['Instagram Inbox'] : []),
                  ]}
                  OImages={[
                    ...(agentdata?.permission?.chatReplies || !agentdata ? [images.CANwhatsapp] : []),
                    ...(agentdata?.permission?.instaReplies || !agentdata ? [images.InstagramInbox] : []),
                  ]}
                  OImagesSel={[
                    ...(agentdata?.permission?.chatReplies || !agentdata ? [images.WhatsAppSel] : []),
                    ...(agentdata?.permission?.instaReplies || !agentdata ? [images.InstagramInboxSel] : []),
                  ]}
                  endpoints={[
                    ...(agentdata?.permission?.chatReplies || !agentdata ? ['whatsappreplies'] : []),
                    ...(agentdata?.permission?.instaReplies || !agentdata ? ['instagramReplies'] : []),
                  ]}
                />
              )}
              {(agentdata?.permission?.automation || !agentdata) && (
                <SidemenuLgItemSub
                  sidebarRef={sidebarRef}
                  ItemImage={images.IconAutomation}
                  ItemImageSel={images.IconAutomationSel}
                  Itemtext={'Automation'}
                  options={
                    localdata?.premiumPlanName === "Enterprise" ?
                      [
                        ...(agentdata?.permission?.keywordAutomation || !agentdata ? ['Keywords'] : []),
                        ...(agentdata?.permission?.workflow || !agentdata ? ['Workflows'] : []),
                        ...(agentdata?.permission?.chatbot || !agentdata ? ['ChatBot'] : []),
                        ...(agentdata?.permission?.timeTrigger || !agentdata ? ['Time Triggers'] : [])
                      ] :
                      isPremiumUser
                        ? [
                          ...(agentdata?.permission?.keywordAutomation || !agentdata ? ['Keywords'] : []),
                          ...(agentdata?.permission?.workflow || !agentdata ? ['Workflows'] : []),
                          ...(agentdata?.permission?.timeTrigger || !agentdata ? ['Time Triggers'] : [])
                        ]
                        : [
                          ...(agentdata?.permission?.keywordAutomation || !agentdata ? ['Keywords'] : []),
                          ...(agentdata?.permission?.workflow || !agentdata ? ['Workflows'] : []),
                        ]
                  }
                  OImages={
                    localdata?.premiumPlanName === "Enterprise" ? [
                      ...(agentdata?.permission?.keywordAutomation || !agentdata ? [images.IconKeywords] : []),
                      ...(agentdata?.permission?.workflow || !agentdata ? [images.IconWorkFlow] : []),
                      ...(agentdata?.permission?.chatbot || !agentdata ? [images.IconChatBot] : []),
                      ...(agentdata?.permission?.timeTrigger || !agentdata ? [images.WFTime2] : [])
                    ] :
                      isPremiumUser
                        ? [
                          ...(agentdata?.permission?.keywordAutomation || !agentdata ? [images.IconKeywords] : []),
                          ...(agentdata?.permission?.workflow || !agentdata ? [images.IconWorkFlow] : []),
                          ...(agentdata?.permission?.timeTrigger || !agentdata ? [images.WFTime2] : [])
                        ]
                        : [
                          ...(agentdata?.permission?.keywordAutomation || !agentdata ? [images.IconKeywords] : []),
                          ...(agentdata?.permission?.workflow || !agentdata ? [images.IconWorkFlow] : []),
                        ]
                  }

                  OImagesSel={
                    localdata?.premiumPlanName === "Enterprise" ? [
                      ...(agentdata?.permission?.keywordAutomation || !agentdata ? [images.IconKeywordsSel] : []),
                      ...(agentdata?.permission?.workflow || !agentdata ? [images.IconWorkFlowSel] : []),
                      ...(agentdata?.permission?.chatbot || !agentdata ? [images.IconChatBotSel] : []),
                      ...(agentdata?.permission?.timeTrigger || !agentdata ? [images.WFTime2Sel] : [])
                    ] : isPremiumUser
                      ? [
                        ...(agentdata?.permission?.keywordAutomation || !agentdata ? [images.IconKeywordsSel] : []),
                        ...(agentdata?.permission?.workflow || !agentdata ? [images.IconWorkFlowSel] : []),
                        ...(agentdata?.permission?.timeTrigger || !agentdata ? [images.WFTime2Sel] : [])
                      ]
                      : [
                        ...(agentdata?.permission?.keywordAutomation || !agentdata ? [images.IconKeywordsSel] : []),
                        ...(agentdata?.permission?.workflow || !agentdata ? [images.IconWorkFlowSel] : []),
                      ]
                  }

                  endpoints={
                    localdata?.premiumPlanName === "Enterprise" ? [
                      ...(agentdata?.permission?.keywordAutomation || !agentdata ? ['keywords'] : []),
                      ...(agentdata?.permission?.workflow || !agentdata ? ['workflow'] : []),
                      ...(agentdata?.permission?.chatbot || !agentdata ? ['chatbot'] : []),
                      ...(agentdata?.permission?.timeTrigger || !agentdata ? ['timeTriggers'] : [])
                    ] :
                      isPremiumUser
                        ? [
                          ...(agentdata?.permission?.keywordAutomation || !agentdata ? ['keywords'] : []),
                          ...(agentdata?.permission?.workflow || !agentdata ? ['workflow'] : []),
                          ...(agentdata?.permission?.timeTrigger || !agentdata ? ['timeTriggers'] : [])
                        ]
                        : [
                          ...(agentdata?.permission?.keywordAutomation || !agentdata ? ['keywords'] : []),
                          ...(agentdata?.permission?.workflow || !agentdata ? ['workflow'] : []),
                        ]
                  }

                />
              )}

              {(agentdata?.permission?.email || !agentdata) && !localdata?.isAppsumoUser &&(
                <SidemenuLgItem
                  ItemImage={images.SequnecesIcon}
                  ItemImageSel={images.SNSequencesSel}
                  Itemtext={'Sequences'}
                  onclickAction={() => {
                    handleTrackEvent('sidebar_sequences_button_clicked', localdata);

                    if (isPremiumUser && isBasic) {
                      setShowPremiumModal(true);
                    }
                    else if (isPremiumUser || isTrialUser) {
                      setSelectedItem('Sequences');
                      navigate('/creator/sequences');
                      // window.location.reload();
                    } else {
                      notPremiumUser();
                    }
                  }}
                />
              )}
              {(agentdata?.permission?.email || !agentdata) && (
                <SidemenuLgItemSub
                  sidebarRef={sidebarRef}
                  ItemImage={images.IconMail}
                  ItemImageSel={images.IconMailSel}
                  Itemtext={'Email Manager'}
                  options={[
                    ...(agentdata?.permission?.emailBuilder || !agentdata ? ['Email Builder'] : []),
                    ...(agentdata?.permission?.labels || !agentdata ? ['Email Verifier'] : []),
                    ...(agentdata?.permission?.uploadCustomHtml || !agentdata ? ['Upload HTML'] : []),
                    ...(agentdata?.permission?.manageSenderId || !agentdata ? ['Manage Sender Id'] : []),
                  ]}
                  OImages={[
                    ...(agentdata?.permission?.emailBuilder || !agentdata ? [images.SNDesign] : []),
                    ...(agentdata?.permission?.labels || !agentdata ? [images.SNEmailVerify] : []),
                    ...(agentdata?.permission?.uploadCustomHtml || !agentdata ? [images.SNCampaigns] : []),
                    ...(agentdata?.permission?.manageSenderId || !agentdata ? [images.ManageSender] : []),
                  ]}
                  OImagesSel={[
                    ...(agentdata?.permission?.emailBuilder || !agentdata ? [images.SNDesignSel] : []),
                    ...(agentdata?.permission?.labels || !agentdata ? [images.SNEmailVerifySel] : []),
                    ...(agentdata?.permission?.uploadCustomHtml || !agentdata ? [images.SNCampaignsSel] : []),
                    ...(agentdata?.permission?.manageSenderId || !agentdata ? [images.SelManageSender] : []),
                  ]}
                  endpoints={[
                    ...(agentdata?.permission?.emailBuilder || !agentdata ? ['design'] : []),
                    ...(agentdata?.permission?.emailBuilder || !agentdata ? ['emailVerifier'] : []),
                    ...(agentdata?.permission?.uploadCustomHtml || !agentdata ? ['uploadHTML'] : []),
                    ...(agentdata?.permission?.manageSenderId || !agentdata ? ['manageSender'] : []),
                  ]}
                />
              )}
              {(agentdata?.permission?.embeddedForms || !agentdata) &&
                (
                  <SidemenuLgItem
                    ItemImage={images.SNForms}
                    ItemImageSel={images.SNFormsSel}
                    Itemtext={'Smart Forms'}
                    onclickAction={() => {
                      handleTrackEvent('sidebar_embeddable_forms_button_clicked', localdata);
                      if (isPremiumUser) {
                        setSelectedItem('Smart Forms');
                        navigate('/creator/smartForms');
                        setSubSelectedItem('Smart Forms');
                      } else {
                        notPremiumUser();
                      }
                    }}
                  />
                )}
              {(templateUsers) && 
                (
                  <SidemenuLgItem
                    ItemImage={images.LinkedinIcon}
                    ItemImageSel={images.LinkedinIconSel}
                    Itemtext={'Linkedin Sequences'}
                    onclickAction={() => {
                      handleTrackEvent('sidebar_embeddable_forms_button_clicked', localdata);
                      if (isPremiumUser) {
                        setSelectedItem('Linkedin Sequences');
                        navigate('/creator/linkedinsequences');
                        setSubSelectedItem('Linkedin Sequences');
                      } else {
                        notPremiumUser();
                      }
                    }}
                  /> 
                )
              }
              
              {/* {(agentdata?.permission?.instaReplies || !agentdata) &&
                (
                  <SidemenuLgItem
                    ItemImage={images.InstagramInbox}
                    ItemImageSel={images.InstagramInboxSel}
                    Itemtext={'Instagram Inbox'}
                    onclickAction={() => {
                      handleTrackEvent('sidebar_instagram_inbox_button_clicked', localdata);
                      if (isPremiumUser && isBasic) {
                        setShowPremiumModal(true);
                      }
                      else if (isPremiumUser || isTrialUser) {
                        setSelectedItem('Instagram Inbox');
                        navigate('/creator/instagramReplies');
                        setSubSelectedItem('Instagram Inbox');
                        // window.location.reload();
                      } else {
                        notPremiumUser();
                      }
                    }}
                  />
                )} */}
              {(agentdata?.permission?.instaReplies || !agentdata) &&
                (
                  <SidemenuLgItem
                    ItemImage={images.CatalogIcon}
                    ItemImageSel={images.CatalogIconSelected}
                    Itemtext={'WA Commerce'}
                    onclickAction={() => {
                      handleTrackEvent('sidebar_catalog_button_clicked', localdata);
                      if (isPremiumUser || isTrialUser) {
                        setSelectedItem('WA Commerce');
                        navigate('/creator/catalog');
                        // window.location.reload();
                      } else {
                        notPremiumUser();
                      }
                    }}
                  />
                )}

              {(agentdata?.permission?.templateLibrary || !agentdata) && (
                <SidemenuLgItem
                  ItemImage={images.SNExploreT}
                  ItemImageSel={images.SNExploreTSel}
                  Itemtext={'Template Library'}
                  onclickAction={() => {
                    handleTrackEvent('sidebar_template_library_button_clicked', localdata);

                    if (isPremiumUser && isBasic) {
                      setShowPremiumModal(true);
                    }
                    else if (isPremiumUser || isTrialUser) {
                      setSelectedItem('Template Library');
                      navigate('/creator/templates');
                      // window.location.reload();
                    } else {
                      notPremiumUser();
                    }
                  }}
                />
              )}
              {(agentdata?.permission?.templateLibrary || !agentdata) && ShopifyDetails && (
                <SidemenuLgItem
                  ItemImage={images.SNShopify}
                  ItemImageSel={images.SNShopifySel}
                  Itemtext={'Shopify Analytics'}
                  onclickAction={() => {
                    handleTrackEvent('sidebar_template_library_button_clicked', localdata);

                    if (isPremiumUser && isBasic) {
                      setShowPremiumModal(true);
                    }
                    else if (isPremiumUser || isTrialUser) {
                      setSelectedItem('Shopify Analytics');
                      navigate('/creator/manageIntegrations/shopify/Analytics');
                      // window.location.reload();
                    } else {
                      notPremiumUser();
                    }
                  }}
                />
              )}

              {(agentdata?.permission?.integrations || !agentdata) && (
                <SidemenuLgItemSub
                  sidebarRef={sidebarRef}
                  ItemImage={images.Integration}
                  ItemImageSel={images.IntegrationSel}
                  Itemtext={'Integrations'}
                  options={['Discover Platforms', 'Manage']}
                  OImages={[images.DiscoverIcon, images.ManageIcon]}
                  OImagesSel={[images.DiscoverIconSel, images.ManageIconSel]}
                  endpoints={['discoverPlatforms', 'manageIntegrations']}
                />
              )}
              {localdata?._id === '65df0d11a97e5ce53cbd8a6d' && (
                <SidemenuLgItem
                  ItemImage={images.MessengerInbox}
                  ItemImageSel={images.MessengerInbox}
                  Itemtext={'Messenger Inbox'}
                  onclickAction={() => {
                    handleTrackEvent('sidebar_messenger_inbox_button_clicked', localdata);
                    if (isPremiumUser || isTrialUser) {
                      setSelectedItem('Messenger Inbox');
                      navigate('/creator/messengerReplies');
                      // window.location.reload();
                    } else {
                      notPremiumUser();
                    }
                  }}
                />
              )}
            </div>
            <div
              className="sidemenu_lg_container__lower"
              style={{ borderTop: '1px solid #E6E6E6', margin: '-6px' }}
            >
              <div className="lower_content">
                {isWidgetModalOpen && (
                  <CheerioWidget
                    openReferralModalAction={openReferralModalAction}
                    setIsModalOpen={setIsWidgetModalOpen}
                  />
                )}
                <div
                  className="lower_element"
                  style={{ cursor: 'pointer', height: 40 }}
                  onClick={() => {
                    setIsWidgetModalOpen(true);
                  }}
                >
                  <img
                    src={images.SNHelp}
                    style={{
                      height: 24,
                      width: 24,
                      objectFit: 'contain',
                      cursor: 'pointer',
                      paddingTop: '1px',
                    }}
                  />
                  <p>Cheerio Support</p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {
        showPremiumModal && (
          <PremiumFeatureModal onClose={() => setShowPremiumModal(false)} />
        )
      }
    </>
  );
};

export default SidemenuNew;
