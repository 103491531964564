import React, { useEffect, useState, useCallback } from 'react';
import colors from '../../../Utils/colors';
import images from '../../../Utils/images';
import Button from 'react-bootstrap/Button';
import { useSelector } from 'react-redux';
import { freeTrialApi } from '../../../Services';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';
import '../../../Components/Modal/Modal.css';
import { eventsend } from '../../../Config/analyticsFunctions';
import CheerioButton from '../../../Components/CheerioButton';

const OTPVerifyForSignUp = ({
  incorrectPhoneOTP,
  incorrectEmailOTP,
  closeOtpAction,
  phoneOTPvalue,
  setPhoneOTPvalue,
  emailOTPvalue,
  setEmailOTPvalue,
  verifyOtpAction,
  number,
  countryCode,
  email,
  resendEmailOtpAction,
  resendPhoneOtpAction,
}) => {
  const [emailTimer, setEmailTimer] = useState(10);
  const [phoneTimer, setPhoneTimer] = useState(10);

  const emailTimeOutCallback = useCallback(() => setEmailTimer(currTimer => currTimer - 1), []);

  useEffect(() => {
    emailTimer > 0 && setTimeout(emailTimeOutCallback, 1000);
  }, [emailTimer, emailTimeOutCallback]);

  const resetEmailTimer = function () {
    if (!emailTimer) {
      setEmailTimer(30);
    }
  };

  const phoneTimeOutCallback = useCallback(() => setPhoneTimer(currTimer => currTimer - 1), []);

  useEffect(() => {
    phoneTimer > 0 && setTimeout(phoneTimeOutCallback, 1000);
  }, [phoneTimer, phoneTimeOutCallback]);

  const resetPhoneTimer = function () {
    if (!phoneTimer) {
      setPhoneTimer(30);
    }
  }

  const isButtonDisabled = !((emailOTPvalue.length === 6) && (countryCode == '91' ? phoneOTPvalue.length === 6 : true));

  const handleResendEmailOTP = () => {
    resendEmailOtpAction();
    eventsend("Resend_EmailOTP_OTP_popup_clicked");
    resetEmailTimer();
  };

  const handleResendPhoneOTP = () => {
    resendPhoneOtpAction();
    eventsend("Resend_OTP_OTP_popup_clicked");
    resetPhoneTimer();
  };

  return (
    <>
      <div className='ModalDarkBg'>
        <div className="Center">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              verifyOtpAction();
            }}
          >
            <div className='otp_verify_modal'>
              <div className='otp_verify_modal__header'>
                <img src={images.CMClose} onClick={closeOtpAction}></img>
              </div>

              <div className='otp_verify_modal__body'>
                <h6 className='enter_otp_text'>
                  Enter Email OTP to verify
                </h6>
                <input
                  type="password"
                  className="form-control"
                  id="exampleFormControlInput1"
                  placeholder="x-x-x-x-x-x"
                  value={emailOTPvalue}
                  onChange={(e) => setEmailOTPvalue(e.target.value)}
                />
                <h4 className='otp_sent_text'>
                  OTP sent to {`“${email}”`}
                </h4>
                <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                <img
                  src={images.InfoCircle}
                  style={{ height: 22, width: 22, objectFit: 'contain' }}
                />
                <h4 className='my-2'>
                Please make sure to check your spam for the OTP
                </h4>
                </div>
                

                <button
                  onClick={handleResendEmailOTP}
                  type="button"
                  className={`btn btn-outline-dark mt-3`}
                  disabled={emailTimer != 0}
                  style={{ marginBottom: '2%', marginTop: '1%' }}
                >
                  Resend Email OTP
                </button>

                {emailTimer > 0 && (
                  <p style={{ fontSize: 12, color: colors.greys04 }} className="form-label">
                    {`Retry in ${emailTimer} seconds`}
                  </p>
                )}

                {(countryCode == '91' && number !== "") && (
                  <div style={{ width: '100%', marginTop: 20 }}>
                    <div className="mb-3 mt-1 form-group" style={{ width: '100%' }}>
                      <h6 className='enter_otp_text'>
                        Enter Phone OTP to verify
                      </h6>
                      <input
                        type="password"
                        className="form-control"
                        id="exampleFormControlInput2"
                        placeholder="x-x-x-x-x-x"
                        value={phoneOTPvalue}
                        onChange={(e) => setPhoneOTPvalue(e.target.value)}
                      />
                    </div>
                    <h4 className='otp_sent_text'>
                      OTP sent to {`“${number}”`}
                    </h4>

                    <button
                      onClick={handleResendPhoneOTP}
                      type="button"
                      className={`btn btn-outline-dark mt-3`}
                      disabled={phoneTimer != 0}
                      style={{ marginBottom: '2%', marginTop: '1%' }}
                    >
                      Resend Phone OTP
                    </button>

                    {phoneTimer > 0 && (
                      <p style={{ fontSize: 12, color: colors.greys04 }} className="form-label">
                        {`Retry in ${phoneTimer} seconds`}
                      </p>
                    )}

                    {(incorrectPhoneOTP || incorrectEmailOTP) && (
                      <>
                        <div className='incorrect_otp_text'>
                          <text
                            style={{
                              fontSize: 12,
                              fontWeight: 400,
                            }}
                          >
                            {'Incorrect OTP entered'}
                          </text>
                        </div>
                      </>
                    )}
                  </div>
                )}

                <div style={{ width: '100%', marginTop: 'auto', marginBottom: '5%' }}>
                  <button
                    style={{ width: '100%' }}
                    type="submit"
                    className="btn btn-dark"
                    disabled={isButtonDisabled}
                  >
                    Verify OTP
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div >
      </div >
    </>
  );
};

export default OTPVerifyForSignUp;

const Styles = {
  DarkBg: {
    backgroundColor: 'rgba(0,0,0,0.4)',
    width: '100vw',
    height: '100vh',
    // flex:1,
    zIndex: 0,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    position: 'fixed',
    zIndex: '1231423',
  },
  Center: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
  },
  TxtHeading: {
    fontSize: 42,
    fontWeight: 900,
    color: colors.primary03,
  },
  TxtBody: {
    fontSize: 20,
    fontWeight: 600,
    color: colors.black03,
  },
  TxtBody2: {
    fontSize: 16,
    fontWeight: 600,
    color: colors.black03,
  },
  itemborder: {
    backgroundColor: colors.white,
    borderRadius: 16,
    boxShadow: '2px 2px 10px 0 rgba(0, 0, 0, 0.15),-2px -2px 10px 0 rgba(0, 0, 0, 0.15)',
  },
};