import colors from "../../../Utils/colors";
import { Tooltip } from "@mui/material";
import images from "../../../Utils/images";
import SpinnerLoader from "../../../Components/SpinnerLoader";

const Card = ({ imageSrc, itemText, itemValue, info = "", isShopify = false, isLoading, showInfo = true, isAlert = false }) => {
    const screenWidth = window.innerWidth;
    const isMobile = screenWidth < 700;



    return (
        <div
            className='py-1 px-2 m-1'
            style={{
                width: isMobile ? '100%' : 'auto',
                maxWidth: isMobile ? 300 : 450,  // Increase max width on larger screens
                flex: screenWidth > 1200 ? "1 1 350px" : "1 1 280px", // Adjust card width dynamically
            }}
        >
            <div
                className='px-4 py-4 d-flex flex-column justify-content-start align-items-start'
                style={{
                    backgroundColor: isShopify ? "#4653F3" : colors.white02,
                    borderRadius: 16,
                    boxShadow: '0px 0px 10px 0 rgba(0, 0, 0, 0.08)',
                    height: 'auto',
                    maxWidth: '100%',
                    minHeight: screenWidth > 1200 ? 180 : 150,
                }}
            >

                {isLoading ? (
                    <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ height: "100%", width: "100%" }}
                    >
                        <SpinnerLoader />
                    </div>
                ) : (<><div
                    className='d-flex flex-column justify-content-center align-items-center'
                    style={{
                        height: isMobile ? 40 : 60,
                        width: isMobile ? 40 : 60,
                        borderRadius: 12,
                        backgroundColor: colors.white01,
                    }}
                >
                    <img
                        style={{
                            height: isMobile ? 20 : 30,
                            width: isMobile ? 20 : 30,
                            objectFit: 'contain',
                        }}
                        src={imageSrc}
                        alt="icon"
                    />
                </div>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 8,
                            flexWrap: 'nowrap',
                            width: '100%',
                        }}
                    >
                        <p
                            className='my-3'
                            style={{
                                flex: 1,
                                fontSize: isMobile ? "1rem" : "1.2rem",
                                fontWeight: 600,
                                color: isShopify ? colors.white : colors.black,
                                width: "100%",
                                height: isMobile ? "3.0rem" : "2rem",
                                overflow: "hidden",
                                display: "-webkit-box",
                                WebkitLineClamp: 2,
                                WebkitBoxOrient: "vertical",
                            }}
                        >
                            {itemText}
                        </p>
                        {!isMobile && showInfo && (
                            <Tooltip title={info} arrow>
                                <img
                                    style={{
                                        height: 22,
                                        width: 22,
                                        objectFit: 'contain',
                                        cursor: 'pointer',
                                    }}
                                    src={isAlert ? images.NOTIFICATIONALERT : images.InfoCircle}
                                    alt="info"
                                />
                            </Tooltip>
                        )}
                    </div>
                    <p
                        style={{
                            width: '100%',
                            fontSize: isMobile ? "1.2rem" : "2rem", // Adjust font size dynamically
                            fontWeight: 700,
                            color: isShopify ? colors.white : colors.black,
                            wordBreak: 'break-word',
                            textAlign: 'start',
                        }}
                    >
                        {itemValue}
                    </p></>)
                }
            </div >
        </div >
    );
};
export default Card;